import './HListLayoutGallery.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNodeData } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import {
  HListLayoutGalleryItem,
  HListLayoutGalleryItemRef,
  HListLayoutGalleryItemProps,
} from './HListLayoutGalleryItem';
import { mergeVNodeData } from '~/helpers';

export interface HListLayoutGalleryItemInput
  extends Omit<HListLayoutGalleryItemProps, 'index'> {
  /** サムネイルレベルの画像URL */
  thumbUrl?: string;
  /** 画像のアスペクト比。現場Listは4:3のみ */
  aspectRatio?: string;
}

export interface HListLayoutGalleryProps {
  items: HListLayoutGalleryItemInput[];
}

export interface HListLayoutGalleryEmits {}

export interface HListLayoutGalleryScopedSlots {}

@Component<HListLayoutGalleryRef>({
  name: 'HListLayoutGallery',
  provide() {
    return {
      gallery: this,
    };
  },
  render() {
    const data: VNodeData = {};
    const { computedItems } = this;
    /** 画像数に応じてcssでスタイルを変更できるように画像数を取得 */
    const itemLength = computedItems.length;

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: `h-list-layout-gallery`,
        })}>
        <div
          staticClass={`h-list-layout-gallery__items h-list-layout-gallery__items__${itemLength}`}>
          {computedItems.map((item, index) => (
            <HListLayoutGalleryItem
              ref="items"
              class={[
                `h-list-layout-gallery__items__${itemLength}--image${
                  index + 1
                }`,
              ]}
              refInFor
              images={item.images}
              aspectRatio={item.aspectRatio}
            />
          ))}
        </div>
      </div>
    );
  },
})
export class HListLayoutGalleryRef
  extends Vue
  implements HListLayoutGalleryProps {
  $refs!: {
    items: HListLayoutGalleryItemRef[];
  };

  @Prop({ type: Array, required: true })
  readonly items!: HListLayoutGalleryItemInput[];

  get computedItems(): HListLayoutGalleryItemInput[] {
    return this.items.map((item) => {
      const { images, aspectRatio } = item;
      return {
        images,
        aspectRatio,
      };
    });
  }
}

/** レイアウトが画像登録数によって変化するギャラリーコンポーネント */
export const HListLayoutGallery = tsx
  .ofType<
    HListLayoutGalleryProps,
    HListLayoutGalleryEmits,
    HListLayoutGalleryScopedSlots
  >()
  .convert(HListLayoutGalleryRef);
