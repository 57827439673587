import './HWysiwyg.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';

// export interface HWysiwygComponentOptions
//   extends FunctionalComponentOptions<{
//     value?: string | null;
//     tag?: string;
//   }> {}

export const HWysiwyg = tsx.component({
  name: 'HWysiwyg',
  functional: true,

  props: {
    value: String as PropType<string | null>,
    tag: String,
  },

  render(h, { data, props }) {
    const { tag, value } = props;
    const staticClasses = data.staticClass ? [data.staticClass] : [];
    staticClasses.push('h-wysiwyg');
    const staticClass = staticClasses.join(' ').trim();

    return h(tag || 'div', {
      ...data,
      staticClass,
      directives: [
        {
          name: 'wysiwyg',
          value,
        },
      ],
    });
  },
});
