import './HPortalFooterSpacer.scss';

import * as tsx from 'vue-tsx-support';

export const HPortalFooterSpacer = tsx.component({
  name: 'HPortalFooterSpacer',
  functional: true,
  render(h, { parent }) {
    const styles = {
      height: parent.$ui.portalFooterHeight + 'px',
    };
    return h('div', {
      staticClass: 'h-portal-footer-spacer',
      style: styles,
    });
  },
});
