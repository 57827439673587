import Vue from 'vue';
import { Context, Plugin, NuxtAppOptions } from '@nuxt/types';

export interface NuxtGTMModule {
  ctx: Context;
  options: {
    layer: string;
  };
  init(): void;
  initPageTracking(): void;
  pushEvent(obj: object): void;
  hasDNT(): boolean;
}

declare module 'vue/types/vue' {
  export interface Vue {
    $gtm: NuxtGTMModule;
    $gfev: GFEventService;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $gtm: NuxtGTMModule;
    $gfev: GFEventService;
  }
}

declare module '@nuxt/types' {
  export interface NuxtAppOptions {
    $gtm: NuxtGTMModule;
    $gfev: GFEventService;
  }

  export interface Context {
    $gfev: GFEventService;
  }
}

/**
 * GTMへイベント送信する際のイベント名
 */
// export const GFEventName = 'GF_EV';

export enum GFEventCategory {
  /**
   * ボタン等押下した時（リンクは覗く）
   */
  Push = 'push',

  /**
   * リンク遷移する時
   */
  Link = 'link',

  /**
   * アンカージャンプする時
   */
  Move = 'move',
}

export interface GFEvent {
  /**
   * イベントの種別
   */
  category: GFEventCategory;

  /**
   * 具体的なアクション内容
   */
  action: string;

  /**
   * 現在地URL
   */
  label?: string;

  /**
   * ID
   */
  id?: string;

  /**
   * イベントに関連するパラメータ（あれば）
   */
  params?: string;
}

const HAS_WINDOW = typeof window !== 'undefined';

/**
 * GlobalFormatのユーザー画面で発生した各種イベントをGTMのdataLayerに送信するサービス
 * 最終的にGTM側でGAに連携される
 */
export class GFEventService {
  readonly context: Context;

  get gtm() {
    return this.context.app.$gtm;
  }

  get ga(): Function | undefined {
    return HAS_WINDOW ? (window as any).gfga : undefined;
  }

  get layer(): any[] | undefined {
    return HAS_WINDOW ? (window[this.gtm.options.layer] as any[]) : undefined;
  }

  get Category() {
    return GFEventCategory;
  }

  constructor(context: Context) {
    this.context = context;
    this.init();
  }

  init() {
    if (process.server) return;

    this.gtm.pushEvent({
      event: 'gf-init',
    });

    const { app } = this.context;
    if (!app) return;
    const { router } = app;
    if (!router) return;

    router.afterEach((to, from) => {
      this.pageViewTick();
    });
  }

  private paveViewBounceId: number | null = null;

  private clearPaveViewBounce() {
    if (this.paveViewBounceId !== null) {
      clearTimeout(this.paveViewBounceId);
      this.paveViewBounceId = null;
    }
  }

  private pageViewTick() {
    this.clearPaveViewBounce();
    this.paveViewBounceId = window.setTimeout(() => {
      this.sendPageView();
    }, 500);
  }

  updateTracker() {
    const { ga, context } = this;
    if (ga) {
      const { route } = context;
      ga('set', {
        page: route.fullPath,
        title: document.title,
      });
    }
  }

  sendPageView() {
    if (process.server) return;

    const { ga } = this;
    if (!ga) {
      this.context.$logger.warn('[gfev] missing window.gfga(GA)');
      return;
    }
    this.updateTracker();
    ga('send', 'pageview');
  }

  // push(ev: GFEvent, eventTimeout = 3000) {
  //   return new Promise((resolve, reject) => {
  //     const timeoutId = window.setTimeout(() => {
  //       this.context.$logger.warn(`[gfev] timeout.`, ev);
  //     }, eventTimeout);

  //     const sendData = {
  //       event: GFEventName,
  //       label: location.href,
  //       ...ev,
  //       eventCallback: () => {
  //         clearTimeout(timeoutId);
  //         resolve();
  //       },
  //       eventTimeout,
  //     };

  //     this.gtm.pushEvent(sendData);
  //   });
  // }

  /**
   * GTMのDataLayerを経由した方が将来的にはいろいろトリガーできて良いのだが、
   * 現状はイベント解析のしくみとか暫定決定状態なので、
   * windowオブジェクトに存在するはずのGAオブジェクト（GTM配信）にで直接
   * gaへイベントを送信する
   */
  push(ev: GFEvent) {
    if (process.server) return Promise.resolve();

    return new Promise((resolve, reject) => {
      const { ga } = this;
      if (typeof ga !== 'function') {
        this.context.$logger.warn('[gfev] missing window.gfga(GA)', ev);
        return resolve(undefined);
      }

      const {
        category: eventCategory,
        action: eventAction,
        label: eventLabel = location.href,
        id: dimension1,
        params: dimension2,
      } = ev;

      const sendData = {
        hitType: 'event',
        eventCategory,
        eventAction,
        eventLabel,
        dimension1,
        dimension2,
        hitCallback: resolve,
        hitCallbackFail: reject,
      };

      if (!this.context.$env.isProduction) {
        // eslint-disable-next-line no-console
        console.group(`[gfev] Pushd GA Event.`);
        // eslint-disable-next-line no-console
        console.log(sendData);
        // eslint-disable-next-line no-console
        !this.context.$env.isDevelop && console.groupEnd();
      }

      if (this.context.$env.isDevelop) {
        // eslint-disable-next-line no-console
        console.log('[gfev] Cancel because it is a development environment.');

        // eslint-disable-next-line no-console
        console.groupEnd();
        return resolve(undefined);
      }

      this.updateTracker();

      ga('send', sendData);
    });
  }
}

const plugin: Plugin = (context, inject) => {
  const gfev = new GFEventService(context);
  context.$gfev = gfev;
  inject('gfev', gfev);
};

export default plugin;
