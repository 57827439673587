import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MyHotelGuestroomItem } from './MyHotelGuestroomItem';
import { MyHotelGuestroomGallery } from './MyHotelGuestroomGallery';
import { HotelDetail } from '~/schemes';
import { HBtn } from '~/components';

export interface MyHotelGuestroomProps {
  hotel: HotelDetail;
}

export interface MyHotelGuestroomEmits {}

export interface MyHotelGuestroomScopedSlots {}

@Component<MyHotelGuestroomRef>({
  name: 'MyHotelGuestroom',
  render() {
    const {
      gallery,
      rooms,
      hotel,
      description,
      link,
      displayFormat,
      isHoshinoya,
      isKai,
    } = this;
    return (
      <div staticClass="my-hotel-guestroom">
        {/* 通常の説明文 */}
        {!!description && !isKai && (
          <div
            staticClass="my-hotel-guestroom__description"
            v-wysiwyg={description}
          />
        )}

        {/* ギャラリー表示 */}
        {!!gallery && displayFormat === 'gallery' && (
          <div staticClass="my-hotel-guestroom__gallery-wrapper">
            <MyHotelGuestroomGallery
              staticClass="my-hotel-guestroom__gallery"
              gallery={gallery}
            />
          </div>
        )}

        {/* 界の説明文表示 */}
        {!!description && isKai && (
          <div staticClass="my-hotel-guestroom__description-wrapper">
            <div
              staticClass="my-hotel-guestroom__description"
              v-wysiwyg={description}
            />
          </div>
        )}

        {displayFormat === 'guestrooms' &&
          rooms.map((room, index) => (
            <MyHotelGuestroomItem
              id={`guestroom-${room.id}`}
              key={index}
              eventId={`room-${index + 1}`}
              staticClass="my-hotel-guestroom__item"
              hotel={hotel}
              roomIndex={index}
              data={room}
              // @TODO これなんだっけ
              // @click:gallery="$emit('click:gallery', $event)"
            />
          ))}

        {!!link && (
          <div staticClass="my-hotel-guestroom__footer">
            <HBtn
              staticClass="my-hotel-guestroom__footer__action"
              props={this.$navigation.resolveHrefTo(link.url)}
              color={isHoshinoya ? 'skelton' : 'primary-wrap'}>
              {link.text}
            </HBtn>
          </div>
        )}
      </div>
    );
  },
})
export default class MyHotelGuestroomRef
  extends Vue
  implements MyHotelGuestroomProps {
  @Prop({ type: Object, required: true }) readonly hotel!: HotelDetail;

  get rooms() {
    return this.hotel.rooms;
  }

  get gallery() {
    return this.hotel.roomGallery;
  }

  get description() {
    const description =
      this.hotel.roomSettings && this.hotel.roomSettings.description;
    return description;
  }

  get link() {
    const link = this.hotel.roomSettings && this.hotel.roomSettings.link;
    return link;
  }

  get displayFormat() {
    return this.hotel.roomSettings && this.hotel.roomSettings.displayFormat;
  }

  get isKai() {
    return this.$theme.is('kai');
  }

  get isHoshinoya() {
    return this.$theme.is('hoshinoya');
  }
}

export const TypedMyHotelGuestroom = tsx
  .ofType<
    MyHotelGuestroomProps,
    MyHotelGuestroomEmits,
    MyHotelGuestroomScopedSlots
  >()
  .convert(MyHotelGuestroomRef);
