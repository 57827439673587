import './HPathIcon.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';

export const PathIconNames = [
  'close',
  'calendar-green',
  'calendar-warn',
  'calendar-empty',
  'calendar-closed',
  'circle-outline',
  'x-mark',
] as const;

export type PathIconName = typeof PathIconNames[number];

const PATH_SETTINGS: { [K in PathIconName]?: number } = {
  close: 2,
  'calendar-warn': 1,
};

export const HPathIconRaw = tsx.component({
  name: 'HPathIcon',
  functional: true,

  props: {
    name: {
      type: String as PropType<PathIconName>,
      required: true,
      validator: (name) => PathIconNames.includes(name),
    },
  },

  render(h, { data, props }): VNode {
    const { name } = props;

    const staticClasses = data.staticClass ? [data.staticClass] : [];
    staticClasses.push('h-path-icon', `h-path-icon--${name}`);

    const staticClass = staticClasses.join(' ').trim();
    const myChildren: VNode[] = [];
    const PATH_SETTING = PATH_SETTINGS[name] || 0;

    for (let i = 0; i < PATH_SETTING; i++) {
      myChildren.push(
        h('span', {
          staticClass: 'h-path-icon__path',
          class: `h-path-icon__path--${i + 1}`,
        }),
      );
    }

    return h(
      'i',
      {
        ...data,
        staticClass,
      },
      myChildren,
    );
  },
});

export type HPathIconComponent = typeof HPathIconRaw & {
  names: typeof PathIconNames;
};

export const HPathIcon = HPathIconRaw as HPathIconComponent;
HPathIcon.names = PathIconNames;
