import './HClockIcon.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { mergeVNodeData, hour12 } from '~/helpers';

function parseTime(
  time: string | Date,
): {
  /**
   * 0 - 23
   */
  H: number;

  /**
   * 0 - 11
   */
  K: number;

  /**
   * 0 - 59
   */
  m: number;
} {
  let H: number;
  let m: number;
  if (typeof time === 'string') {
    const tmp = time.split(':');
    H = parseInt(tmp[0], 10);
    m = parseInt(tmp[1], 10);
  } else {
    H = time.getHours();
    m = time.getMinutes();
  }
  const K = H % 12;
  return { H, K, m };
}

export const HClockIcon = tsx.component({
  name: 'HClockIcon',

  functional: true,

  props: {
    time: [String, Date] as PropType<string | Date>,
    tag: String,
    responsive: {
      type: Boolean,
      default: true,
    },
  },

  render(h, { data, props }) {
    const { tag = 'time', time = new Date(), responsive } = props;
    const { K, m } = parseTime(time);
    // const label = `${H}:${String(m).padStart(2, '0')}`;
    const label = hour12(time);
    const mPer = m / 60;
    const rotateM = mPer * 360;
    const rotateH = (K / 12) * 360 + mPer * 30;

    const TagName = tag as 'div';

    return (
      <TagName
        {...mergeVNodeData(data, {
          staticClass: 'h-clock-icon',
          class: {
            'h-clock-icon--responsive': responsive,
          },
        })}>
        <span staticClass="h-clock-icon__faux">
          <span
            staticClass="h-clock-icon__needle h-clock-icon__needle--hours"
            style={{
              transform: `rotate(${rotateH}deg)`,
            }}
            key="hours"
          />
          <span
            staticClass="h-clock-icon__needle h-clock-icon__needle--minutes"
            style={{
              transform: `rotate(${rotateM}deg)`,
            }}
            key="minutes"
          />
        </span>
        <span staticClass="h-clock-icon__label" key="label">
          {label}
        </span>
      </TagName>
    );
  },
});
