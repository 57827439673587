import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import type { MyHotelSectionData } from './-MyHotelSection';
import { HThemeDecoratedTitle } from '~/components';

export interface MyHotelSectionHeaderProps {
  data: MyHotelSectionData;
  inviewed?: boolean;
}

export interface MyHotelSectionHeaderEmits {}

export interface MyHotelSectionHeaderScopedSlots {}

@Component<MyHotelSectionHeaderRef>({
  name: 'MyHotelSectionHeader',
  render() {
    const { portal, headRotate, fullWidth, label } = this;
    if (this.$theme.is('risonare'))
      return (
        <HThemeDecoratedTitle
          staticClass="my-hotel-section-header my-hotel-section-header--decorated"
          class={this.classes}
          tag="h2">
          <span
            staticClass="my-hotel-section-header__label"
            domPropsInnerHTML={label || ''}
          />
        </HThemeDecoratedTitle>
      );
    return (
      <h2 staticClass="my-hotel-section-header" class={this.classes}>
        <i staticClass="my-hotel-section-header__deco" />
        {!!portal && (
          <div
            staticClass="my-hotel-section-header__portal"
            class={portal.className}
            domPropsInnerHTML={portal.html}
          />
        )}

        <span staticClass="my-hotel-section-header__inner">
          <span
            staticClass="my-hotel-section-header__img-wrap"
            style={{
              transform:
                headRotate !== 0 ? `rotate(${headRotate}deg)` : undefined,
              ...(fullWidth
                ? this.photoGalleryHeadStyles
                : this.stickyHeadStyles),
            }}>
            <span
              staticClass="my-hotel-section-header__label"
              domPropsInnerHTML={label || ''}
            />
          </span>
        </span>
      </h2>
    );
  },
  mounted() {
    this.$theme.applyEffect(this);
  },
  beforeDestroy() {
    this.$theme.removeEffect(this);
  },
})
export class MyHotelSectionHeaderRef
  extends Vue
  implements MyHotelSectionHeaderProps {
  @Prop({ type: Object, required: true }) readonly data!: MyHotelSectionData;
  @Prop(Boolean) readonly inviewed!: boolean;

  get id() {
    return this.data.id;
  }

  get portal() {
    return this.data.portal;
  }

  get headRotate() {
    return this.data.headRotate;
  }

  get fullWidth() {
    return this.data.fullWidth;
  }

  get label() {
    return this.data.label;
  }

  get headerHeight() {
    return this.$ui.headerHeight;
  }

  get stickyHeadStyles() {
    return {
      top: this.headerHeight + 40 + 'px',
    };
  }

  get photoGalleryHeadStyles() {
    return {
      top: this.headerHeight - 1 + 'px',
    };
  }

  get classes() {
    return {
      [`my-hotel-section-header--${this.id}`]: true,
      'my-hotel-section-header--inviewed': this.inviewed,
    };
  }
}

export const MyHotelSectionHeader = tsx
  .ofType<
    MyHotelSectionHeaderProps,
    MyHotelSectionHeaderEmits,
    MyHotelSectionHeaderScopedSlots
  >()
  .convert(MyHotelSectionHeaderRef);
