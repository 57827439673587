import Vue from 'vue';
import { Context, Plugin } from '@nuxt/types';

declare module 'vue/types/vue' {
  export interface Vue {
    $res: ResourceService;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $res: ResourceService;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $res: ResourceService;
  }
}

const HTTP_RE = /^https?:\/\//;

const IMG_EXT_REPLACE_RE = /\.(jpg|png|gif)($|\?)/;

export class ResourceService {
  readonly context: Context;
  readonly emptyGif =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  get rootPath() {
    return this.context.$env.resourcePath;
  }

  get buildTime() {
    return this.context.env.buildTime;
  }

  constructor(context: Context) {
    this.context = context;
  }

  img(path: string): string {
    if (!path) return '';
    if (HTTP_RE.test(path)) return path;
    if (typeof path === 'number') {
      path = String(path);
    }
    path = path.replace(/^\//, '');
    let result = `${this.rootPath}/${path}`;
    if (this.context.$env.isDevelop) {
      result = `${this.context.$navigation.developRequestOrigin}${result}`;
    }
    return result;
  }

  webp(path: string) {
    if (this.context.$ua.webpSupported) {
      path = path.replace(IMG_EXT_REPLACE_RE, '.webp$2');
    }
    return this.img(path);
  }
}

const plugin: Plugin = (context, inject) => {
  const res = new ResourceService(context);
  context.$res = res;
  inject('res', res);
};

export default plugin;
