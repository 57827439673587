import './HCuisineContent.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HCuisineSpecs } from './HCuisineSpecs';
import { RestaurantCommons, CuisineContent } from '~/schemes';
import {
  HSimpleGallery,
  HBtn,
  HPrecautionList,
  HThemeDecoration,
  HExpandButton,
} from '~/components';

export interface HCuisineContentProps {
  commons: RestaurantCommons;
  data: CuisineContent;
  special?: boolean;
  index: number;
}

export interface HCuisineContentEmits {}

export interface HCuisineContentScopedSlots {}

export interface MySpec {
  label: string;
  description: string;
  precautions: string[];
}

@Component<HCuisineContentRef>({
  name: 'HCuisineContent',

  render() {
    const {
      images,
      title,
      ribbon,
      description,
      links,
      precautions,
      isHoshinoyaSpecialLayoutSecondContents,
      isHoshinoya,
      isHoshinoyaSideLayout,
    } = this;

    const normalImages = (
      <HSimpleGallery
        staticClass="h-cuisine-content__image__gallery"
        items={images}
      />
    );

    //  星のやのテーマで特別レイアウトの2つ目 ３つのタイル画像
    const hoshinoyaSpecialLayoutSecondContentsImages = (
      <div class="h-cuisine-content__image__gallery--tile">
        {images.map((image) => {
          return (
            <img
              class="h-cuisine-content__image__gallery__item"
              src={image.url}
              alt=""
              width={'100%'}
              height={'100%'}
            />
          );
        })}
      </div>
    );

    const hoshinoyaImages =
      images.length > 0 &&
      (isHoshinoyaSpecialLayoutSecondContents
        ? hoshinoyaSpecialLayoutSecondContentsImages
        : normalImages);

    // 表
    const specsAndPrecautions =
      this.data.specs.length || precautions.length ? (
        <div>
          {this.data.specs.length > 0 && (
            <HCuisineSpecs
              class="h-cuisine-content__specs"
              data={this.data}
              commons={this.commons}
            />
          )}
          {precautions.length > 0 && (
            <HPrecautionList
              staticClass="h-cuisine-content__precautions"
              items={precautions}
            />
          )}
        </div>
      ) : null;

    const contentBodyInner = (
      <div staticClass="h-cuisine-content__body__inner">
        {description && (
          <div
            staticClass="h-cuisine-content__description h-theme-lead"
            v-wysiwyg={description}
          />
        )}
        {links.length > 0 && (
          <nav staticClass="h-cuisine-content__links">
            {links.map(({ url, text }, index) => {
              const loc = this.$navigation.resolveHrefTo(url);
              return (
                <HBtn
                  staticClass="h-cuisine-content__links__link"
                  color="primary-wrap"
                  props={loc}>
                  {text}
                </HBtn>
              );
            })}
          </nav>
        )}
        {specsAndPrecautions &&
          (isHoshinoya ? (
            <HExpandButton staticClass="h-cuisine-content__expand-button">
              {specsAndPrecautions}
            </HExpandButton>
          ) : (
            specsAndPrecautions
          ))}
      </div>
    );

    const contentHeader = (
      <div staticClass="h-cuisine-content__header">
        {ribbon && (
          <span staticClass="h-cuisine-content__ribbon">{ribbon}</span>
        )}
        <h3 staticClass="h-cuisine-content__title">{title}</h3>
      </div>
    );

    return (
      <div staticClass="h-cuisine-content" class={this.classes}>
        {isHoshinoya &&
          // 1つ目2つ目の特別レイアウト
          (this.special ? (
            <div staticClass="h-cuisine-content__inner">
              <div staticClass="h-cuisine-content__image">
                {hoshinoyaImages}
              </div>
              <div staticClass="h-cuisine-content__body">
                {contentHeader}
                {contentBodyInner}
              </div>
            </div>
          ) : (
            <div staticClass="h-cuisine-content__inner">
              <div staticClass="h-cuisine-content__sideLayout">
                <div staticClass="h-cuisine-content__image h-cuisine-content__sideLayout__image">
                  {hoshinoyaImages}
                </div>
                {contentHeader}
              </div>
              <div staticClass="h-cuisine-content__body">
                {contentBodyInner}
              </div>
            </div>
          ))}

        {!isHoshinoya && (
          <div staticClass="h-cuisine-content__inner">
            <div staticClass="h-cuisine-content__image">{normalImages}</div>
            <div staticClass="h-cuisine-content__body">
              {contentHeader}
              {contentBodyInner}
            </div>
          </div>
        )}
      </div>
    );
  },
})
export class HCuisineContentRef extends Vue implements HCuisineContentProps {
  @Prop({ type: Object, required: true }) readonly commons!: RestaurantCommons;
  @Prop({ type: Object, required: true }) readonly data!: CuisineContent;
  @Prop(Boolean) readonly special!: boolean;
  @Prop(Number) readonly index!: number;

  private isOpened: boolean = false;
  toggle() {
    this.isOpened = !this.isOpened;
  }

  get isHoshinoya() {
    return this.$theme.is('hoshinoya');
  }

  get id() {
    return this.data.id;
  }

  get title() {
    return this.data.title;
  }

  get ribbon() {
    return this.data.ribbon;
  }

  get description() {
    return this.data.description;
  }

  get links() {
    return this.data.links.map((link) => {
      // urlは記載済みでtextは空欄の場合、「宿泊プランを予約する」の文言表示
      if (!!link.url && link.text === '') {
        link.text = this.viewOffers;
      }
      return link;
    });
  }

  get viewOffers() {
    return this.$t('label.viewOffers') as string;
  }

  get precautions() {
    return this.data.precautions;
  }

  get images() {
    return this.data.images.map((image) => {
      return {
        ...image,
        url: this.$res.img(image.url),
      };
    });
  }

  // 星のやのテーマで特別レイアウトの2つ目
  get isHoshinoyaSpecialLayoutSecondContents() {
    const isHoshinoya = this.$theme.current.name === 'hoshinoya';
    return !!isHoshinoya && !!this.special && this.index === 1;
  }

  get isHoshinoyaSideLayout() {
    return !this.special && this.$mq.match.narrow;
  }

  get classes() {
    return {
      'h-cuisine-content--special': this.special,
    };
  }
}

export const HCuisineContent = tsx
  .ofType<
    HCuisineContentProps,
    HCuisineContentEmits,
    HCuisineContentScopedSlots
  >()
  .convert(HCuisineContentRef);
