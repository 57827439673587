import Vue from 'vue';
import {
  SpecialPageModuleFilterCategory,
  createCategoriesByBuiltinCategories,
  createCategoriesByCommonCategories,
  createCategoriesByOriginalCategories,
} from './category';
import {
  SearchTagCategoryForGF,
  SearchableSpecialPageModuleProperties,
} from '~/schemes';

export interface SpecialPageModuleFilterInput<
  Props extends SearchableSpecialPageModuleProperties,
  ChildKey extends keyof Props
> {
  properties: Props;
  childKey: ChildKey;
  searchTagCategories: SearchTagCategoryForGF[];
}

/**
 * 特集モジュールフィルター
 */
export class SpecialPageModuleFilter<
  Props extends SearchableSpecialPageModuleProperties,
  ChildKey extends keyof Props
> {
  /**
   * 絞り込みラベル（CMSで登録された値か、デフォルト値）
   *
   * @example "地域・エリアで絞り込む"
   *
   * @default `i18n.xxxx`
   */
  controlHeading: string;

  /**
   * 特集モジュールフィルターカテゴリのリスト
   *
   * モジュールに紐づいている分だけ初期化される
   *
   * @see {@link SpecialPageModuleFilterCategory}
   */
  categories: SpecialPageModuleFilterCategory[];

  /**
   * フィルタ前の全子モジュールのリスト
   */
  initialChildren: Props[ChildKey];

  constructor(input: SpecialPageModuleFilterInput<Props, ChildKey>, vm: Vue) {
    const { properties, childKey, searchTagCategories } = input;
    this.initialChildren = (properties as any)[childKey];
    const {
      tagSearchSettings = {} as NonNullable<
        typeof properties.tagSearchSettings
      >,
    } = properties;
    this.controlHeading =
      tagSearchSettings.filterControlHeading ||
      (vm.$t('label.filters') as string);

    // 子モジュールに登録された組み込み検索タグスラッグを取得
    const childBuiltInSlugs: string[] = (
      tagSearchSettings.builtInCategoryIds || []
    ).flatMap((builtInCategoryId) => {
      return (properties as any)[childKey].flatMap(
        (child) => child.properties.searchTags.builtInTags[builtInCategoryId],
      );
    });

    // 組み込み取得
    const builtinCategories = createCategoriesByBuiltinCategories(
      tagSearchSettings.builtInCategoryIds || [],
      Array.from(new Set(childBuiltInSlugs)),
      vm,
    );

    // 子モジュールに登録された共通検索タグスラッグを取得
    const childCommonSlugs: string[] = (
      tagSearchSettings.commonCategoryIds || []
    ).flatMap((commonCategoryId) => {
      return (properties as any)[childKey].flatMap(
        (child) => child.properties.searchTags.commonTags[commonCategoryId],
      );
    });

    // 共通
    const commonCategories = createCategoriesByCommonCategories(
      tagSearchSettings.commonCategoryIds || [],
      searchTagCategories,
      Array.from(new Set(childCommonSlugs)),
      vm,
    );

    // 独自検索タグが設定されているタグのみ取得
    const hasOriginalSearchTagCategories = (
      tagSearchSettings.originalSearchTagCategories || []
    ).filter(({ tags }) => tags && tags.length);

    // 子モジュールに登録された独自検索タグスラッグを取得
    const childOriginalSlugs: string[] = hasOriginalSearchTagCategories.flatMap(
      (originalCategory) => {
        return (properties as any)[childKey].flatMap((child) => {
          if (
            child.properties.searchTags &&
            child.properties.searchTags.originalTags
          ) {
            return child.properties.searchTags.originalTags[
              originalCategory.key
            ];
          }
          return [];
        });
      },
    );

    // 独自
    const originalSearchTagCategories: SearchTagCategoryForGF[] = hasOriginalSearchTagCategories.map(
      (origin) => {
        return {
          ...origin,
          name: origin.name || '',
          // 子モジュールに登録されている検索タグのみ取得する
          tags: (origin.tags || [])
            .filter((tag) =>
              Array.from(new Set(childOriginalSlugs)).includes(tag.slug),
            )
            .map((tag) => {
              return {
                ...tag,
                label: tag.label || '',
              };
            }),
        };
      },
    );
    const originalCategories = createCategoriesByOriginalCategories(
      originalSearchTagCategories,
    );

    this.categories = [
      ...builtinCategories,
      ...commonCategories,
      ...originalCategories,
    ];
  }

  /**
   * フィルタ済みの子モジュールのリスト
   *
   * @remarks 自身が保有する全てのカテゴリのフィルタ条件にマッチしたもの
   */
  get filteredChildren(): Props[ChildKey] {
    return (this.initialChildren as any).filter((child) =>
      this.categories.every((category) =>
        category.filter(child.properties.searchTags),
      ),
    );
  }

  /** 全タグの選択状態をクリアする */
  clear(): void {
    this.categories.forEach((category) => category.clear());
  }
}
