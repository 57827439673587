import * as tsx from 'vue-tsx-support';
import { navigationItemProps } from '~/schemes';
import { createNavigationItemSettings } from '~/helpers';

export const HNavigationItem = tsx.component({
  name: 'HNavigationItem',
  functional: true,

  props: navigationItemProps(),

  render(h, context) {
    const { tag, data, children } = createNavigationItemSettings(context);
    return h(tag, data, children);
  },
});
