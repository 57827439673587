import './simple.scss';

import { Component, mixins } from 'nuxt-property-decorator';
import { HLayoutMixin } from './-layout-mixin';
import { HFooterCopy } from '~/components';

@Component<HSimpleLayout>({
  name: 'HSimpleLayout',
  render() {
    return (
      <div staticClass="h-app h-simple-layout">
        <main staticClass="h-main">
          <nuxt />
        </main>
        <HFooterCopy />
      </div>
    );
  },
})
export default class HSimpleLayout extends mixins<HLayoutMixin>(HLayoutMixin) {
  htmlClass = 'h-simple-layout-view';

  get viewportSetting() {
    return 'width=device-width, initial-scale=1, viewport-fit=cover';
  }
}
