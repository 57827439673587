import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  HFormNode,
  HFormNodeProps,
  HFormNodeEmits,
  HFormNodeScopedSlots,
} from './mixins/HFormNode';
import { createErrorMessageNode } from './utils';

export interface HFormErrorCaptureProps extends HFormNodeProps {
  ensureDetailsHeight?: boolean;
}

export interface HFormErrorCaptureEmits extends HFormNodeEmits {}

export interface HFormErrorCaptureScopedSlots extends HFormNodeScopedSlots {}

@Component<HFormErrorCaptureRef>({
  name: 'HFormErrorCapture',
  provide() {
    return {
      formErrorCapture: this,
    };
  },
  render() {
    const { firstError } = this;
    const errorNode =
      firstError &&
      createErrorMessageNode(this, firstError.error, firstError.name);
    const messages = errorNode ? [errorNode] : [];

    return (
      <div staticClass="h-form-error-capture" class={this.classes}>
        {this.$slots.default}
        {messages}
      </div>
    );
  },
})
export default class HFormErrorCaptureRef
  extends Vue
  implements HFormErrorCaptureProps {
  @Prop(Boolean) readonly ensureDetailsHeight!: boolean;

  private nodes: HFormNode[] = [];

  get classes() {
    return {
      'h-form-control--ensure-details-height': this.ensureDetailsHeight,
    };
  }

  join(node: HFormNode) {
    if (!this.nodes.includes(node)) {
      this.nodes.push(node);
    }
  }

  leave(node: HFormNode) {
    const index = this.nodes.indexOf(node);
    if (index !== -1) {
      this.nodes.splice(index, 1);
    }
  }

  get firstError() {
    for (const node of this.nodes) {
      const { firstError, name } = node;
      if (!name) continue;
      if (firstError) {
        return {
          name,
          error: firstError,
        };
      }
    }
  }
}

export const HFormErrorCapture = tsx
  .ofType<
    HFormErrorCaptureProps,
    HFormErrorCaptureEmits,
    HFormErrorCaptureScopedSlots
  >()
  .convert(HFormErrorCaptureRef);
