function cyrb53(str: string, seed = 0) {
  // eslint-disable-next-line prettier/prettier
    let h1 = 0xDEADBEEF ^ seed;
  // eslint-disable-next-line prettier/prettier
    let h2 = 0x41C6CE57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export function tinyObjectToHash(obj: any) {
  const jsonStr = JSON.stringify(obj);
  return String(cyrb53(jsonStr));
}
