import { LanguageDataMap } from '../schemes';

export interface SimpleTableTitle {
  /**
   * 表項目名のマスタ情報
   */
  name: LanguageDataMap<string>;

  /**
   * 識別子
   */
  value: string;
}

/**
 * 選択式の表項目名リスト
 *
 * @TODO 将来的にCMSマスタ基本設定で登録できるようにする。CMS化する時に、Tableに絞って使うもので良いのか、もっと汎用的な使い方をするのか検討する。
 * 現状のCMS基本設定が、valueに連番を入れているので合わせておく
 *
 * 項目リストの原本
 * https://docs.google.com/spreadsheets/d/1tIL6IGcDB_dQ6Mg0qqAKf2LeGlPUnwlZ1C_Y0vpYqM8/edit#gid=0
 */
export const simpleTableTitles: SimpleTableTitle[] = [
  {
    value: '1',
    name: {
      _$: true,
      ja: '予約',
      en: 'Reservation',
      zh_cn: '预订',
      zh_tw: '預訂',
      ko: '예약',
    },
  },
  {
    value: '2',
    name: {
      _$: true,
      ja: '電話',
      en: 'TEL',
      zh_cn: '电话',
      zh_tw: '電話',
      ko: '텔',
    },
  },
  {
    value: '3',
    name: {
      _$: true,
      ja: '営業時間',
      en: 'Hours',
      zh_cn: '营业时间',
      zh_tw: '營業時間',
      ko: '영업 시간',
    },
  },
  {
    value: '4',
    name: {
      _$: true,
      ja: '所要時間',
      en: 'Duration',
      zh_cn: '时长',
      zh_tw: '體驗時間',
      ko: '진행 시간',
    },
  },
  {
    value: '5',
    name: {
      _$: true,
      ja: '開催時間',
      en: 'Time',
      zh_cn: '时间',
      zh_tw: '時間',
      ko: '시간',
    },
  },
  {
    value: '6',
    name: {
      _$: true,
      ja: '期間',
      en: 'Period',
      zh_cn: '期间',
      zh_tw: '期間',
      ko: '기간',
    },
  },
  {
    value: '7',
    name: {
      _$: true,
      ja: '開催言語',
      en: 'Hosted in',
      zh_cn: '对应语言',
      zh_tw: '體驗語言',
      ko: '진행 언어',
    },
  },
  {
    value: '8',
    name: {
      _$: true,
      ja: '集合場所',
      en: 'Meeting spot',
      zh_cn: '集合地点',
      zh_tw: '集合地點',
      ko: '집합 장소',
    },
  },
  {
    value: '9',
    name: {
      _$: true,
      ja: '料金',
      en: 'Prices',
      zh_cn: '价格',
      zh_tw: '價格',
      ko: '요금',
    },
  },
  {
    value: '10',
    name: {
      _$: true,
      ja: '含まれる内容',
      en: 'Contents',
      zh_cn: '包括',
      zh_tw: '包括',
      ko: '포함사항',
    },
  },
  {
    value: '11',
    name: {
      _$: true,
      ja: '対象年齢',
      en: 'Appropriate age',
      zh_cn: '对象年龄',
      zh_tw: '對象年齡',
      ko: '대상 연령',
    },
  },
  {
    value: '12',
    name: {
      _$: true,
      ja: '持ち物',
      en: 'Belongings',
      zh_cn: '携行物品',
      zh_tw: '攜行物品',
      ko: '준비하실 것',
    },
  },
  {
    value: '13',
    name: {
      _$: true,
      ja: '注意事項',
      en: 'Notes',
      zh_cn: '注意事项',
      zh_tw: '注意事項',
      ko: '주의 사항',
    },
  },
  {
    value: '14',
    name: {
      _$: true,
      ja: '提供スタイル',
      en: 'Menu',
      zh_cn: '提供方式',
      zh_tw: '提供方式',
      ko: '제공하는 스타일',
    },
  },
  {
    value: '15',
    name: {
      _$: true,
      ja: '子供',
      en: 'Kids',
      zh_cn: '儿童',
      zh_tw: '兒童',
      ko: '아이',
    },
  },
  {
    value: '16',
    name: {
      _$: true,
      ja: 'ペット',
      en: 'Pets',
      zh_cn: '宠物',
      zh_tw: '寵物',
      ko: '애완 동물',
    },
  },
  {
    value: '17',
    name: {
      _$: true,
      ja: '平均予算',
      en: 'Average price',
      zh_cn: '平均价格',
      zh_tw: '平均價格',
      ko: '평균 가격',
    },
  },
  {
    value: '18',
    name: {
      _$: true,
      ja: 'ドレスコード',
      en: 'Dress code',
      zh_cn: '着装规范',
      zh_tw: '著裝規範',
      ko: '복장 규정',
    },
  },
];
