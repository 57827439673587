import './HBanner.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, BannerModule, BannerWithProps } from '~/schemes';
import { HBannerLink, toBannerLinkProps } from '~/components/HBannerLink';

export const HBanner = tsx.component({
  name: 'HBanner',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<BannerModule['properties'], AvailableLanguage>
      >,
      required: true,
    },
  },

  render(h, { props }): VNode {
    const { layout, banners } = props.value;
    const maxBannerCount = layout === 'landscape' ? 4 : 5;

    /**
     * バナーリンクとコンポーネントpropsのセットのリスト
     *
     * @see {@link BannerWithProps}
     */
    const bannerWithProps = (): BannerWithProps[] => {
      return banners
        .map((banner) => {
          const sm = toBannerLinkProps(banner, 'sm').src;
          const md = toBannerLinkProps(banner, 'md').src;
          const lg = toBannerLinkProps(banner, 'lg').src;
          const landscape = toBannerLinkProps(banner, '670x170').src;

          return {
            banner,
            media:
              sm || md || lg || landscape
                ? {
                    sm,
                    md,
                    lg,
                    '670x170': landscape,
                  }
                : null,
          };
        })
        .filter((_, index) => index < maxBannerCount);
    };

    return (
      <div class="v-banner-module">
        <div
          class={`v-banner-module-${layout}`}
          staticClass={`v-banner-module-${layout}--${
            bannerWithProps().length
          }`}>
          {bannerWithProps().map(({ banner, media }) => (
            <HBannerLink
              key={banner.key}
              props={{
                ...banner,
                excludeDecoration: true,
              }}
              class={`v-banner-module-${layout}__banner`}
              staticClass={`v-banner-module-${layout}__banner-${
                bannerWithProps().length
              }`}
              style={
                media && layout === 'normal'
                  ? {
                      '--image-lg': `url(${media.lg})`,
                      '--image-md': `url(${media.md})`,
                      '--image-sm': `url(${media.sm})`,
                      '--banner-image': 'gray',
                    }
                  : media && layout === 'landscape'
                  ? { '--image-335x85': `url(${media['670x170']})` }
                  : { '--banner-image': 'gray' }
              }
            />
          ))}
        </div>
      </div>
    );
  },
});
