import Vue from 'vue';
import { Context, Plugin } from '@nuxt/types';
import MediaQueryService from './MediaQueryService';

declare module 'vue/types/vue' {
  interface Vue {
    $mq: MediaQueryService;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $mq: MediaQueryService;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $mq: MediaQueryService;
  }
}

const plugin: Plugin = (context, inject) => {
  const service = new MediaQueryService();
  context.$mq = service;
  inject('mq', service);
};

export default plugin;
