import './HPeoplesForm.scss';

import * as tsx from 'vue-tsx-support';
import { VNode } from 'vue';
import { Component, Model, Prop, Watch, Mixins } from 'vue-property-decorator';
import { HBackdrop, HBtn, HSelect, HSelectItem } from '~/components';
import { VacancySearchConditionsMixin } from '~/mixins/vacancy-search-conditions';
import { VacancySearchConditions, YgetsableHotelDetail } from '~/schemes';

type RowType = 'adult' | 'underTwelve' | 'underSeven' | 'underFour';
const ROW_TYPES: RowType[] = [
  'adult',
  'underTwelve',
  'underSeven',
  'underFour',
];

export interface HPeoplesFormProps {
  hotel: YgetsableHotelDetail;
  value?: VacancySearchConditions;
  active?: boolean;
}

export interface HPeoplesFormEmits {
  onChangeActive: boolean;
  onInput: VacancySearchConditions;
}

export interface HPeoplesFormScopedSlots {}

@Component<HPeoplesFormRef>({
  name: 'HPeoplesForm',

  render() {
    return (
      <div staticClass="h-peoples-form">
        <div staticClass="h-peoples-form__form">
          <div staticClass="h-peoples-form__form__body">
            <div staticClass="h-peoples-form__form__rows">
              {ROW_TYPES.filter(
                (type) => this.computedUseSearch[type],
              ).map((type) => this.genFormRow(type))}
            </div>
          </div>
        </div>
      </div>
    );
  },
})
export class HPeoplesFormRef
  extends Mixins<VacancySearchConditionsMixin>(VacancySearchConditionsMixin)
  implements HPeoplesFormProps {
  @Model('input', { type: Object, required: true })
  readonly value!: VacancySearchConditions;

  @Prop({ type: Object, required: true }) readonly hotel!: YgetsableHotelDetail;
  @Prop({ type: Boolean }) readonly active!: boolean;

  private internalValue: VacancySearchConditions = this.value;
  private internalActive: boolean = this.active;

  get useSearch() {
    return this.hotel.ygets.useSearch;
  }

  get maxReservationNumber() {
    return this.hotel.ygets.maxReservationNumber;
  }

  get vacancySearchConditions() {
    return this.internalValue;
  }

  get isActive() {
    return this.internalActive;
  }

  set isActive(isActive) {
    if (this.internalActive !== isActive) {
      this.internalActive = isActive;
      this.$emit('changeActive', isActive);
    }
  }

  get conditions() {
    return this.internalValue;
  }

  get stayLength() {
    return this.conditions.stayLength;
  }

  set stayLength(stayLength) {
    if (this.conditions.stayLength !== stayLength) {
      this.conditions.stayLength = stayLength;
      this.$emit('input', this.conditions);
    }
  }

  get adult() {
    return this.conditions.adult;
  }

  set adult(adult) {
    if (this.conditions.adult !== adult) {
      this.conditions.adult = adult;
      this.$emit('input', this.conditions);
    }
  }

  get underTwelve() {
    return this.conditions.underTwelve;
  }

  set underTwelve(underTwelve) {
    if (this.conditions.underTwelve !== underTwelve) {
      this.conditions.underTwelve = underTwelve;
      this.$emit('input', this.conditions);
    }
  }

  get underSeven() {
    return this.conditions.underSeven;
  }

  set underSeven(underSeven) {
    if (this.conditions.underSeven !== underSeven) {
      this.conditions.underSeven = underSeven;
      this.$emit('input', this.conditions);
    }
  }

  get underFour() {
    return this.conditions.underFour;
  }

  set underFour(underFour) {
    if (this.conditions.underFour !== underFour) {
      this.conditions.underFour = underFour;
      this.$emit('input', this.conditions);
    }
  }

  @Watch('value', { deep: true })
  protected valueChangeHandler() {
    this.internalValue = this.value;
  }

  protected genFormRow(type: RowType): VNode {
    const { hotel } = this;
    // let { peoplesItems } = this;
    // if (type === 'adult') {
    //   peoplesItems = peoplesItems.filter((i) => i.value !== 0);
    // }
    let min: number = 0;
    let max: number = hotel.ygets.maxReservationNumber;

    if (type === 'adult') {
      min = hotel.ygets.adultNumMin;
      max = hotel.ygets.adultNumMax;
    } else if (type === 'underTwelve') {
      min = hotel.ygets.childNumMin;
      max = hotel.ygets.childNumMax;
    } else if (type === 'underSeven') {
      min = hotel.ygets.infantNumMin;
      max = hotel.ygets.infantNumMax;
    } else if (type === 'underFour') {
      min = hotel.ygets.babyNumMin;
      max = hotel.ygets.babyNumMax;
    }

    const label = hotel.ygets.customerType[type];
    return (
      <div staticClass="h-peoples-form__form__row" key={type}>
        <label staticClass="h-peoples-form__form__label">{label}</label>

        <HSelect
          staticClass="h-peoples-form__form__select"
          items={this.createOptions(min, max)}
          inputValue={this[type]}
          hideDetails
          size="md"
          onInput={(e) => {
            this[type] = e;
          }}
        />
      </div>
    );
  }

  private createOptions(min: number, max: number): HSelectItem[] {
    const items: HSelectItem[] = [];
    for (let i = min, l = max + 1; i < l; i++) {
      const item: HSelectItem = {
        label: this.$tc('value.numOfGuests', i),
        value: i,
      };
      items.push(item);
    }
    return items;
  }

  protected genActivator(type: 'show' | 'close'): VNode {
    return (
      <HBtn
        staticClass="h-peoples-form__activator"
        class={{
          [`h-peoples-form__activator--${type}`]: true,
        }}
        type="button"
        toggleIcon
        left
        size="lg"
        depressed={type === 'close'}
        color="plain"
        onClick={(ev) => {
          this[type]();
        }}>
        {this.conditionWords.map((word) => {
          return (
            <span staticClass="h-peoples-form__activator__word">{word}</span>
          );
        })}
      </HBtn>
    );
  }

  show() {
    this.isActive = true;
  }

  close() {
    this.isActive = false;
  }
}

export const HPeoplesForm = tsx
  .ofType<HPeoplesFormProps, HPeoplesFormEmits, HPeoplesFormScopedSlots>()
  .convert(HPeoplesFormRef);
