import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_1cnswny } from './brands/overviews/_brandSlug@string';
import type { Methods as Methods_1q2mzsf } from './brands/summaries';
import type { Methods as Methods_saq98i } from './hotels/overviews/_hotelSlug@string';
import type { Methods as Methods_eop7qq } from './hotels/summaries';
import type { Methods as Methods_le7gmx } from './settings/basics/gallery-categories';
import type { Methods as Methods_pl9tuj } from './settings/currencies';
import type { Methods as Methods_1os4x0t } from './settings/global-site-settings';
import type { Methods as Methods_rdms38 } from './special-pages';
import type { Methods as Methods_1a35v2c } from './special-pages/get-by-space';
import type { Methods as Methods_2ectt2 } from './special-pages/search-by-space';
import type { Methods as Methods_j4h5r0 } from './ygets/exchange/all';
import type { Methods as Methods_1th6n54 } from './ygets/hotels/_hotelId@string';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'https://test.hoshinoresorts.com/.gf-api' : baseURL).replace(/\/$/, '');
  const PATH0 = '/brands/overviews';
  const PATH1 = '/brands/summaries';
  const PATH2 = '/hotels/overviews';
  const PATH3 = '/hotels/summaries';
  const PATH4 = '/settings/basics/gallery-categories';
  const PATH5 = '/settings/currencies';
  const PATH6 = '/settings/global-site-settings';
  const PATH7 = '/special-pages';
  const PATH8 = '/special-pages/get-by-space';
  const PATH9 = '/special-pages/search-by-space';
  const PATH10 = '/ygets/exchange/all';
  const PATH11 = '/ygets/hotels';
  const GET = 'GET';

  return {
    brands: {
      overviews: {
        _brandSlug: (val2: string) => {
          const prefix2 = `${PATH0}/${val2}`;

          return {
            /**
             * 指定のブランドのブランド概要を取得する
             * @returns ブランド概要
             */
            get: (option?: { query?: Methods_1cnswny['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1cnswny['get']['resBody'], BasicHeaders, Methods_1cnswny['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 指定のブランドのブランド概要を取得する
             * @returns ブランド概要
             */
            $get: (option?: { query?: Methods_1cnswny['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1cnswny['get']['resBody'], BasicHeaders, Methods_1cnswny['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1cnswny['get']['query'] } | undefined) =>
              `${prefix}${prefix2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          };
        },
      },
      summaries: {
        /**
         * 全てのブランド概略リストを取得する
         * @returns ブランド概略リスト
         */
        get: (option?: { query?: Methods_1q2mzsf['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1q2mzsf['get']['resBody'], BasicHeaders, Methods_1q2mzsf['get']['status']>(prefix, PATH1, GET, option).json(),
        /**
         * 全てのブランド概略リストを取得する
         * @returns ブランド概略リスト
         */
        $get: (option?: { query?: Methods_1q2mzsf['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1q2mzsf['get']['resBody'], BasicHeaders, Methods_1q2mzsf['get']['status']>(prefix, PATH1, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1q2mzsf['get']['query'] } | undefined) =>
          `${prefix}${PATH1}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    hotels: {
      overviews: {
        _hotelSlug: (val2: string) => {
          const prefix2 = `${PATH2}/${val2}`;

          return {
            /**
             * 指定の施設の施設概要を取得する
             * @returns 施設概要
             */
            get: (option?: { query?: Methods_saq98i['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_saq98i['get']['resBody'], BasicHeaders, Methods_saq98i['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 指定の施設の施設概要を取得する
             * @returns 施設概要
             */
            $get: (option?: { query?: Methods_saq98i['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_saq98i['get']['resBody'], BasicHeaders, Methods_saq98i['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_saq98i['get']['query'] } | undefined) =>
              `${prefix}${prefix2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          };
        },
      },
      summaries: {
        /**
         * 全ての施設概略リストを取得する
         * @returns 施設概略リスト
         */
        get: (option?: { query?: Methods_eop7qq['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_eop7qq['get']['resBody'], BasicHeaders, Methods_eop7qq['get']['status']>(prefix, PATH3, GET, option).json(),
        /**
         * 全ての施設概略リストを取得する
         * @returns 施設概略リスト
         */
        $get: (option?: { query?: Methods_eop7qq['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_eop7qq['get']['resBody'], BasicHeaders, Methods_eop7qq['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_eop7qq['get']['query'] } | undefined) =>
          `${prefix}${PATH3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    settings: {
      basics: {
        gallery_categories: {
          /**
           * ギャラリーカテゴリリスト取得
           * @returns ギャラリーカテゴリリスト
           */
          get: (option?: { query?: Methods_le7gmx['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_le7gmx['get']['resBody'], BasicHeaders, Methods_le7gmx['get']['status']>(prefix, PATH4, GET, option).json(),
          /**
           * ギャラリーカテゴリリスト取得
           * @returns ギャラリーカテゴリリスト
           */
          $get: (option?: { query?: Methods_le7gmx['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_le7gmx['get']['resBody'], BasicHeaders, Methods_le7gmx['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_le7gmx['get']['query'] } | undefined) =>
            `${prefix}${PATH4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      currencies: {
        /**
         * 通貨情報リストを取得する
         * @returns 通貨情報リスト
         */
        get: (option?: { query?: Methods_pl9tuj['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_pl9tuj['get']['resBody'], BasicHeaders, Methods_pl9tuj['get']['status']>(prefix, PATH5, GET, option).json(),
        /**
         * 通貨情報リストを取得する
         * @returns 通貨情報リスト
         */
        $get: (option?: { query?: Methods_pl9tuj['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_pl9tuj['get']['resBody'], BasicHeaders, Methods_pl9tuj['get']['status']>(prefix, PATH5, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_pl9tuj['get']['query'] } | undefined) =>
          `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      global_site_settings: {
        /**
         * サイト共通設定を取得する
         * @returns サイト共通設定
         */
        get: (option?: { query?: Methods_1os4x0t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1os4x0t['get']['resBody'], BasicHeaders, Methods_1os4x0t['get']['status']>(prefix, PATH6, GET, option).json(),
        /**
         * サイト共通設定を取得する
         * @returns サイト共通設定
         */
        $get: (option?: { query?: Methods_1os4x0t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1os4x0t['get']['resBody'], BasicHeaders, Methods_1os4x0t['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1os4x0t['get']['query'] } | undefined) =>
          `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    special_pages: {
      get_by_space: {
        /**
         * 指定のスペース＆スラッグの特集ページを取得する
         * @returns 特集ページ
         */
        get: (option: { query: Methods_1a35v2c['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1a35v2c['get']['resBody'], BasicHeaders, Methods_1a35v2c['get']['status']>(prefix, PATH8, GET, option).json(),
        /**
         * 指定のスペース＆スラッグの特集ページを取得する
         * @returns 特集ページ
         */
        $get: (option: { query: Methods_1a35v2c['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1a35v2c['get']['resBody'], BasicHeaders, Methods_1a35v2c['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1a35v2c['get']['query'] } | undefined) =>
          `${prefix}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      search_by_space: {
        /**
         * 設置スペース別の特集ページを検索する
         * @returns 設置スペース別の特集ページ検索結果
         */
        get: (option?: { query?: Methods_2ectt2['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_2ectt2['get']['resBody'], BasicHeaders, Methods_2ectt2['get']['status']>(prefix, PATH9, GET, option).json(),
        /**
         * 設置スペース別の特集ページを検索する
         * @returns 設置スペース別の特集ページ検索結果
         */
        $get: (option?: { query?: Methods_2ectt2['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_2ectt2['get']['resBody'], BasicHeaders, Methods_2ectt2['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_2ectt2['get']['query'] } | undefined) =>
          `${prefix}${PATH9}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      /**
       * 特集ページ検索を検索する
       * @returns 特集ページ検索結果
       */
      get: (option?: { query?: Methods_rdms38['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods_rdms38['get']['resBody'], BasicHeaders, Methods_rdms38['get']['status']>(prefix, PATH7, GET, option).json(),
      /**
       * 特集ページ検索を検索する
       * @returns 特集ページ検索結果
       */
      $get: (option?: { query?: Methods_rdms38['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods_rdms38['get']['resBody'], BasicHeaders, Methods_rdms38['get']['status']>(prefix, PATH7, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods_rdms38['get']['query'] } | undefined) =>
        `${prefix}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
    ygets: {
      exchange: {
        all: {
          /**
           * 全為替情報を取得する
           * @returns 全為替情報
           */
          get: (option?: { query?: Methods_j4h5r0['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_j4h5r0['get']['resBody'], BasicHeaders, Methods_j4h5r0['get']['status']>(prefix, PATH10, GET, option).json(),
          /**
           * 全為替情報を取得する
           * @returns 全為替情報
           */
          $get: (option?: { query?: Methods_j4h5r0['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_j4h5r0['get']['resBody'], BasicHeaders, Methods_j4h5r0['get']['status']>(prefix, PATH10, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_j4h5r0['get']['query'] } | undefined) =>
            `${prefix}${PATH10}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      hotels: {
        _hotelId: (val2: string) => {
          const prefix2 = `${PATH11}/${val2}`;

          return {
            /**
             * 宿GETSの施設詳細情報を取得する
             * @returns 施設概略リスト
             */
            get: (option?: { query?: Methods_1th6n54['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1th6n54['get']['resBody'], BasicHeaders, Methods_1th6n54['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 宿GETSの施設詳細情報を取得する
             * @returns 施設概略リスト
             */
            $get: (option?: { query?: Methods_1th6n54['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1th6n54['get']['resBody'], BasicHeaders, Methods_1th6n54['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1th6n54['get']['query'] } | undefined) =>
              `${prefix}${prefix2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          };
        },
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
