import './HSimpleDotCarousel.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Swiper from 'swiper';
import type { SwiperOptions } from 'swiper';

export interface HSimpleDotCarouselItem {
  image: string;
}

export interface HSimpleDotCarouselProps {
  items: HSimpleDotCarouselItem[];
  index: number;
}

export interface HSimpleDotCarouselEmits {}

export interface HSimpleDotCarouselScopedSlots {}

interface SwiperElement extends HTMLElement {
  swiper: Swiper;
}
/**
 * ページネーション(ドット)カルーセル
 */
@Component<HSimpleDotCarouselRef>({
  name: 'HSimpleDotCarousel',

  beforeDestroy() {
    const { mainSwiper } = this;
    mainSwiper && mainSwiper.destroy(true, true);
  },

  render() {
    const { items } = this;
    return (
      <div staticClass="h-simple-dot-carousel">
        <div
          staticClass="swiper-container"
          key="main"
          ref="main"
          {...{
            directives: [
              {
                name: 'swiper',
                arg: 'mainSwiper',
                value: this.mainOptions,
              },
            ],
          }}>
          <div staticClass="swiper-wrapper">
            {items.map((item) => (
              <div staticClass="swiper-slide">
                <img data-src={item.image} alt="" class="swiper-lazy" />
                <div class="swiper-lazy-preloader"></div>
              </div>
            ))}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    );
  },
})
export class HSimpleDotCarouselRef
  extends Vue
  implements HSimpleDotCarouselProps {
  $refs!: {
    main: SwiperElement;
  };

  mainSwiper?: Swiper;

  @Prop({ type: Array, required: true })
  readonly items!: HSimpleDotCarouselItem[];

  @Prop({ type: Number, required: true })
  readonly index!: number;

  /**
   * スライドのdelay基準値
   */
  private readonly delayTime = 3600;

  get mainOptions(): SwiperOptions {
    return {
      // 自動再生
      autoplay: {
        delay:
          this.index === 1 ? this.delayTime : this.getDelayTime(this.index),
        disableOnInteraction: false,
      },
      // 表示されるスライド数
      slidesPerView: 'auto',
      // スライド間の余白
      spaceBetween: 80,
      breakpoints: {
        // 767px以下の場合
        767: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
      },
      // アクティブスライドを中央配置
      centeredSlides: true,
      // 遅延読込
      lazy: {
        // 前後の画像のみ事前読み込み
        loadPrevNext: true,
        loadPrevNextAmount: 3,
      },
      // 繰り返し
      loop: true,
      // ループの後に、いくつのクローンを用意するか
      loopAdditionalSlides: 1,
      // ページネーション（ドット）
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      // 事前画像読み込み
      preloadImages: false,
      slideToClickedSlide: true,
      // スライド周期速度
      speed: 3000,
      on: {
        touchMove: () => {
          this.changeSlideSpeed(2);
        },
        slideChangeTransitionEnd: () => {
          this.changeSlideSpeed(3000);
        },
      },
    };
  }

  /**
   * 各スワイパーの動作間隔を返却
   * - 現在は0.6秒毎
   */
  private getDelayTime(index: number): number {
    if (!index) return 0;
    return (index - 1) * 600 + this.delayTime;
  }

  private changeSlideSpeed(speed: number) {
    const { mainSwiper } = this;
    if (!mainSwiper) {
      // eslint-disable-next-line no-useless-return
      return;
    }
    mainSwiper.params.speed = speed;
  }
}

export const HSimpleDotCarousel = tsx
  .ofType<
    HSimpleDotCarouselProps,
    HSimpleDotCarouselEmits,
    HSimpleDotCarouselScopedSlots
  >()
  .convert(HSimpleDotCarouselRef);
