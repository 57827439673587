import './HPartition.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, PartitionModule } from '~/schemes';

export const HPartition = tsx.component({
  name: 'HPartition',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<PartitionModule['properties'], AvailableLanguage>
      >,
      required: true,
    },
  },

  render(h, { props }): VNode {
    return (
      <div
        role="separator"
        class={[
          'v-partition-module',
          `v-partition-module--${props.value.size || 'md'}`,
        ]}></div>
    );
  },
});
