import './HPhotogalleryBtn.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HBtn } from '../HBtn';
import { HIcon } from '../HIcon';
import HotelViewRoot from '~/pages/_lang/hotels/_hotel_slug/-index';

export interface HPhotogalleryBtnProps {
  eventId: string;
}

export interface HPhotogalleryBtnEmits {}

export interface HPhotogalleryBtnScopedSlots {}

/**
 * フォトギャラリーボタンコンポーネント
 */
@Component<HPhotogalleryBtnRef>({
  inject: ['hotelViewRoot'],
  name: 'HPhotogalleryBtn',

  render() {
    const { hotel } = this.hotelViewRoot;
    const { galleries } = hotel;
    const { eventId } = this;
    return (
      <HBtn
        v-ev={{
          category: 'push',
          action: 'opengallery',
          id: eventId,
        }}
        staticClass="h-photogallery-btn"
        color="plain"
        size="sm"
        width="sm"
        onClick={(ev) => {
          ev.stopPropagation();
          this.hotelViewRoot.showGallery();
        }}>
        <HIcon name="image-alt-double" staticClass="h-photogallery-btn__icon" />
        <span staticClass="h-photogallery-btn__count">+{galleries.length}</span>
      </HBtn>
    );
  },
})
export class HPhotogalleryBtnRef extends Vue implements HPhotogalleryBtnProps {
  @Prop({ type: String, required: true })
  readonly eventId!: string;

  readonly hotelViewRoot!: HotelViewRoot;
}

export const HPhotogalleryBtn = tsx
  .ofType<
    HPhotogalleryBtnProps,
    HPhotogalleryBtnEmits,
    HPhotogalleryBtnScopedSlots
  >()
  .convert(HPhotogalleryBtnRef);
