import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { State as RootState } from './';

import { HDrawerMenuData } from '~/components/HDrawer/HDrawerMenu';

export interface NavigationStack {
  id: number | string;
  local?: HDrawerMenuData[];
}

export interface State {
  stacks: NavigationStack[];
}

export const state = (): State => ({
  stacks: [],
});

export const getters: GetterTree<State, RootState> = {
  locals(state): HDrawerMenuData[] {
    const locals: HDrawerMenuData[] = [];
    state.stacks.forEach(({ local }, index) => {
      local &&
        locals.push(
          ...local.map((row) => ({
            ...row,
            key: index + '-' + row.key,
          })),
        );
    });
    return locals;
  },
};

export const actions: ActionTree<State, RootState> = {};

export const mutations: MutationTree<State> = {
  ADD_STACK(state, stack: NavigationStack) {
    if (!state.stacks.some((s) => s.id === stack.id)) {
      state.stacks.push(stack);
    }
  },

  REMOVE_STACK(state, stack: NavigationStack) {
    const index = state.stacks.findIndex((s) => s.id === stack.id);
    if (index !== -1) {
      state.stacks.splice(index, 1);
    }
  },
};
