import './HLocationMapSpotBaloon.scss';

import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as tsx from 'vue-tsx-support';
import { HImg } from '../HImg';
import { BaloonDisplayPosition, calcBaloonDisplayPosition } from './helpers';
import type { HLocationMapRef } from './HLocationMap';
import { LocationMapSpot } from '~/schemes';
import { ResizeDirectivePayload } from '~/directives/resize';
import { URLParsedResult } from '~/plugins/navigation';

export interface HLocationMapSpotBaloonProps {
  /** ロケーションマップのスポット */
  value: LocationMapSpot;

  /** スポットがアクティブか */
  active?: boolean;

  /** スポットアイコン要素取得 */
  iconRef: () => HTMLElement | null;
}

/**
 *
 */
export interface HLocationMapSpotBaloonEmits {
  onClickClose: MouseEvent;
}

/**
 *
 */
export interface HLocationMapSpotBaloonScopedSlots {}

interface RenderBodySettings {
  name?: string;
  catchCopy?: string;
  description?: string;
  link?: URLParsedResult;
  imageURL?: string;
}

@Component<HLocationMapSpotBaloonRef>({
  name: 'HLocationMapSpotBaloon',
  inject: ['map'],
  watch: {
    isActive() {
      this.isActive && this.calcPosition();
    },
  },
  render(h) {
    const { baloonPosition, bodySettings } = this;
    const body = this.renderBaloonBody(bodySettings);
    const sizer = this.renderBaloonBody(bodySettings, true);
    const { imageURL, link } = bodySettings;
    const classes = [
      'h-location-map-spot-baloon',
      { 'h-location-map-spot-baloon--has-image': !!imageURL },
      { 'h-location-map-spot-baloon--has-link': !!link },
    ];

    return (
      <div staticClass="h-location-map-spot-baloon-wrapper">
        <div class={[...classes, 'h-location-map-spot-baloon--sizer']}>
          {sizer}
        </div>
        <transition
          name={`h-location-map-spot-baloon-${baloonPosition.vertical}`}>
          <div
            class={[
              ...classes,
              `h-location-map-spot-baloon--${baloonPosition.vertical}`,
            ]}
            style={{
              '--adjustment-x': `${baloonPosition.x}px`,
            }}
            v-show={this.isActive}>
            {body}
            <i staticClass="h-location-map-spot-baloon__chip" />
          </div>
        </transition>
      </div>
    );
  },
})
export class HLocationMapSpotBaloonRef
  extends Vue
  implements HLocationMapSpotBaloonProps {
  $refs!: {};

  readonly map!: HLocationMapRef;

  @Prop({ type: Object, required: true }) readonly value!: LocationMapSpot;
  @Prop(Boolean) readonly active!: boolean;

  @Prop({ type: Function, required: true })
  readonly iconRef!: () => HTMLElement | null;

  private size: ResizeDirectivePayload = { width: 0, height: 0 };
  private baloonPosition: BaloonDisplayPosition = {
    vertical: 'top',
    x: 0,
  };

  get isBooted() {
    const { width, height } = this.size;
    return width + height > 0;
  }

  get isActive() {
    return this.isBooted && this.active;
  }

  get bodySettings(): RenderBodySettings {
    const { name, catchCopy, description, link, image } = this.value;
    const imageURL = image && image.derived.mini && image.derived.mini.url;
    const linkURL = link && link.url;
    const settings: RenderBodySettings = {
      name,
      catchCopy,
      description,
      link: (linkURL && this.$navigation.parseURL(linkURL)) || undefined,
      imageURL,
    };
    return settings;
  }

  private handleClickClose(ev: MouseEvent) {
    this.$emit('clickClose', ev);
  }

  private renderBaloonBody(
    { name, description, imageURL, link }: RenderBodySettings,
    isSizer?: boolean,
  ) {
    return (
      <div
        staticClass="h-location-map-spot-baloon__inner"
        v-resize={isSizer ? this.handleResize : undefined}>
        <div staticClass="h-location-map-spot-baloon__padding">
          {!!imageURL && (
            <HImg
              staticClass="h-location-map-spot-baloon__image"
              src={imageURL}
              width="64"
              height="64"
              liquid
              loading
            />
          )}
          <div class="v-location-map-spot-baloon__info">
            <h4 staticClass="h-location-map-spot-baloon__header">
              <span staticClass="h-location-map-spot-baloon__header__body">
                <span staticClass="h-location-map-spot-baloon__name">
                  <span staticClass="h-location-map-spot-baloon__name__text">
                    {name}
                  </span>
                </span>
              </span>
            </h4>
            {!!description && (
              <div staticClass="h-location-map-spot-baloon__description">
                {description}
              </div>
            )}
            {!!link && (
              <div staticClass="h-location-map-spot-baloon__footer">
                {this.$createElement(
                  link.TagName,
                  {
                    staticClass: 'h-location-map-spot-baloon__link',
                    props: link.props,
                    attrs: link.attrs,
                  },
                  this.$t('locationMaps.seeMore') as string,
                )}
              </div>
            )}
          </div>
          <button
            staticClass="h-location-map-spot-baloon__close"
            type="button"
            onClick={isSizer ? () => {} : this.handleClickClose}
          />
        </div>
      </div>
    );
  }

  private handleResize(ev: ResizeDirectivePayload) {
    this.size.width = ev.width;
    this.size.height = ev.height;
  }

  /**
   * バルーンを表示すべきポジションを計算してステートを更新する
   *
   * * isActiveのwatcherからのみ呼び出される
   */
  private calcPosition() {
    // 自身のゴースト要素のサイズが未検出の場合何もしない
    const { width, height } = this.size;
    if (!width || !height) return;

    // 画像ルーペのステージがない場合何もしない
    const { map } = this;
    const viewport = map && map.getStageElement();
    if (!viewport) return;

    // アイコン要素がない場合何もしない
    const icon = this.iconRef();
    if (!icon) return;

    const position = calcBaloonDisplayPosition(viewport, icon, width, height);

    // ステート更新
    this.baloonPosition = position;
  }
}

export const HLocationMapSpotBaloon = tsx
  .ofType<
    HLocationMapSpotBaloonProps,
    HLocationMapSpotBaloonEmits,
    HLocationMapSpotBaloonScopedSlots
  >()
  .convert(HLocationMapSpotBaloonRef);
