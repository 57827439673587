import type Vue from 'vue';
import { normalizeViewerURL } from './link';
import { trimHTML } from './trim';

const linkMatchRe = /<a(?: .+?)?>.*?<\/a>/g;
const hrefRe = / href="(.*?)"/;
const targetRe = / target="(.*?)"/;

export const ROUTABLE_ATTR = 'data-routable-to';

function normalizeLink(source: string, vm: Vue) {
  const linksMtach = source.match(linkMatchRe);
  if (linksMtach) {
    const { $navigation } = vm;
    linksMtach.forEach((link) => {
      let result = link;
      const hrefMatch = link.match(hrefRe);
      const href = hrefMatch && hrefMatch[1];
      if (!href) return;

      /**
       * GFっぽいオリジンの場合、オリジンが削除されルート相対パスに変換されたhref
       */
      const normalizedHref = normalizeViewerURL(href);

      const resolved = $navigation.resolveHrefTo(normalizedHref);
      if (resolved.target) {
        const targetReplacement = ` target="${resolved.target}"`;
        const targetMatch = link.match(targetRe);
        if (targetMatch) {
          result = result.replace(targetMatch[0], targetReplacement);
        } else {
          result = result.replace('<a ', `<a ${targetReplacement}`);
        }
      }
      const resolvedHref = resolved._href;
      if (resolvedHref) {
        result = result.replace(normalizedHref, resolvedHref);
      }
      if (resolved.to) {
        result = result.replace('<a ', `<a ${ROUTABLE_ATTR}="${resolvedHref}"`);
      }
      source = source.replace(link, result);
    });
  }
  return source;
}

export function wysiwygFormat(source?: string | null, vm?: Vue): string {
  if (source == null) source = '';
  if (vm) {
    source = normalizeLink(source, vm);
  }
  return source;
}

/**
 * Wysiwygなエディタで入力された入力内容を正規化する。
 *
 * @param source - wysiwygな入力
 */
export function normalizeWysiwygInput(html: string | null | undefined): string {
  if (html == null) {
    html = '';
  }

  html = trimHTML(html);

  return html;
}
