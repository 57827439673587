import { ButtonColorTheme } from '../server-middleware/data-server/adapter/ACCOAdapter/@types/index';

// コンポーネント化されたボタンなどのテーマを管理する
// @see https://uikit-storybook.test.hoshinoresorts.systems/?path=/docs/home--docs
export interface ButtonSettings {
  color: string;
  bgColor: string;
  borderRadius: string;
  boxShadow: string;
}

/** CMSで選択された配色テーマに紐づく文字色と背景色のマッピング */
export const BUTTON_THEME_SETTINGS_MAP: Record<
  ButtonColorTheme,
  ButtonSettings
> = {
  plain: {
    color: '--color-bg-invert',
    bgColor: '--color-button-plain',
    borderRadius: `--button-radius`,
    boxShadow: '--button-shadow',
  },
  darken: {
    color: '--color-text-invert',
    bgColor: '--color-button-darken',
    borderRadius: `--button-radius`,
    boxShadow: '--button-shadow',
  },
  reservation: {
    color: '--color-text-invert',
    bgColor: '--color-primary',
    borderRadius: `--button-radius`,
    boxShadow: '--button-shadow',
  },
};
