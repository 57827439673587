import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelDetail } from '~/schemes';
import {
  HBackdrop,
  HBackdropRef,
  HIcon,
  HExpandTransition,
  HResponsiveMap,
  HResponsiveMapProps,
  HLocationMapsViewer,
} from '~/components';

export interface MyHotelLocationProps {
  hotel: HotelDetail;
}

export interface MyHotelLocationEmits {}

export interface MyHotelLocationScopedSlots {}

@Component<MyHotelLocationRef>({
  name: 'MyHotelLocation',
  render() {
    const { location, computedMap } = this;
    if (!location) return undefined as any;

    const { description, items, maps } = location;

    return (
      <div
        v-inview={
          this.inviewed
            ? false
            : {
                in: this.inviewHandler,
              }
        }
        staticClass="my-hotel-location">
        {!!description && (
          <div
            staticClass="my-hotel-location__description h-theme-lead"
            v-wysiwyg={description}
          />
        )}

        {!!maps && (
          <HLocationMapsViewer
            staticClass="my-hotel-location__maps"
            value={maps}
          />
        )}

        {/** 以下は新マップデータがない時だけ表示する。完全移行後には削除する */}
        {!maps && !!computedMap && (
          <HResponsiveMap
            staticClass="my-hotel-location__map"
            props={computedMap}
          />
        )}

        {!maps && !!items && items.length > 0 && (
          <div staticClass="my-expand">
            <div staticClass="my-expand__header">
              <button
                staticClass="my-expand__activator my-expand__activator--toggle"
                class={{
                  'my-expand__activator--active': this.listIsActive,
                }}
                type="button"
                onClick={this.toggleList}>
                {this.listIsActive
                  ? this.$t('action.closeNearbySpots')
                  : this.$t('action.viewNearbySpotsAslist')}
                <HIcon
                  staticClass="my-expand__activator__icon"
                  name="keyboard-arrow-down"
                />
              </button>
            </div>

            <HExpandTransition>
              <div v-show={this.listIsActive} staticClass="my-expand__body">
                <ul staticClass="my-expand__list">
                  {items.map((item, index) => (
                    <li
                      key={index}
                      staticClass="my-expand__list__item"
                      onClick={() => {
                        this.itemClickHandler(index);
                      }}>
                      <div staticClass="my-expand__list__item__image">
                        <div
                          staticClass="my-expand__list__item__image__node"
                          style={{
                            backgroundImage: `url(${this.$res.img(
                              item.thumb,
                            )})`,
                          }}
                        />
                      </div>
                      <div staticClass="my-expand__list__item__info">
                        <h3 staticClass="my-expand__list__item__name">
                          {item.name}
                        </h3>
                        <p staticClass="my-expand__list__item__sub-title">
                          {item.subTitle}
                        </p>
                      </div>
                      <HBackdrop ref="modals" refInFor hideClose>
                        <div staticClass="my-modal">
                          <div staticClass="my-modal__image">
                            <div
                              staticClass="my-modal__image__node"
                              style={{
                                backgroundImage: `url(${this.$res.img(
                                  item.image,
                                )})`,
                              }}
                            />
                          </div>
                          <div staticClass="my-modal__body">
                            <h3 staticClass="my-modal__name">{item.name}</h3>
                            <p staticClass="my-modal__sub-title">
                              {item.subTitle}
                            </p>

                            {!!item.decription && (
                              <div
                                v-wysiwyg={item.decription}
                                staticClass="my-modal__decription"
                              />
                            )}
                          </div>
                        </div>
                      </HBackdrop>
                    </li>
                  ))}
                </ul>
                <div staticClass="my-expand__list__footer">
                  <button
                    staticClass="my-expand__activator my-expand__activator--close"
                    type="button"
                    onClick={this.closeList}>
                    {this.$t('action.closeList')}
                    <HIcon
                      staticClass="my-expand__activator__icon"
                      name="keyboard-arrow-up"
                    />
                  </button>
                </div>
              </div>
            </HExpandTransition>
          </div>
        )}
      </div>
    );
  },
})
export default class MyHotelLocationRef
  extends Vue
  implements MyHotelLocationProps {
  $refs!: {
    modals: HBackdropRef[];
  };

  @Prop({ type: Object, required: true }) readonly hotel!: HotelDetail;

  listIsActive: boolean = false;

  private mouseOveredIndex: number | null = null;
  private inviewed: boolean = false;

  get location() {
    return this.hotel.location;
  }

  get computedMap(): HResponsiveMapProps | undefined {
    const { location } = this.hotel;
    if (!location) return;
    const { _generate: generated, items } = location;
    if (!generated || !items) return;
    const { map, items: _items = {} } = generated;

    if (!map) return;

    const areas: HResponsiveMapProps['areas'] = [];
    items.forEach(({ id, name }, index) => {
      const hit = _items[id];
      if (!hit) return;
      areas.push({
        ...hit,
        title: name,
        alt: name,
        onClick: (ev) => {
          ev.preventDefault();
          this.showModalByIndex(index);
        },
      });
    });
    return {
      src: this.$res.img(map.src),
      width: map.width,
      height: map.height,
      areas,
    };
  }

  openList() {
    this.listIsActive = true;
  }

  toggleList() {
    return this.listIsActive ? this.closeList() : this.openList();
  }

  closeList() {
    this.listIsActive = false;
  }

  showModalByIndex(itemIndex: number) {
    const modal = this.$refs.modals[itemIndex];
    if (modal) {
      modal.show();
      this.$gfev.push({
        category: this.$gfev.Category.Push,
        action: 'openlocation',
        id: `location-${itemIndex + 1}`,
      });
    }
  }

  itemClickHandler(itemIndex: number) {
    this.showModalByIndex(itemIndex);
  }

  protected inviewHandler() {
    this.inviewed = true;
  }

  protected mapItemMouseenterHandler(itemIndex: number) {
    this.mouseOveredIndex = itemIndex;
  }

  protected mapItemMouseleaveHandler(itemIndex: number) {
    if (this.mouseOveredIndex === itemIndex) {
      this.mouseOveredIndex = null;
    }
  }
}

export const TypedMyHotelLocation = tsx
  .ofType<
    MyHotelLocationProps,
    MyHotelLocationEmits,
    MyHotelLocationScopedSlots
  >()
  .convert(MyHotelLocationRef);
