import './HSpriteAnimation.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { loadImageSize, ImageDimension, cheepUid } from '~/helpers';

export interface HSpriteAnimationImageInfo extends ImageDimension {
  src: string;
}

export interface HSpriteAnimationProps {
  src: string | string[];
  fallback?: string;
  forceFallback?: boolean;
  width: number;
  height: number;
  fps?: number;
  duration?: number;
  loop?: boolean;
}

export interface HSpriteAnimationEmits {
  onAnimationend: HSpriteAnimationRef;
  onError: any;
}

export interface HSpriteAnimationScopedSlots {}

export interface HSpriteAnimationInfo {
  id: string;
  width: number;
  height: number;
  frames: number;
  fps: number;
  duration: number;
  loop: boolean;
  positions: number[];
  style: string;
}

@Component<HSpriteAnimationRef>({
  name: 'HSpriteAnimation',
  mounted() {
    this.load();
  },
  render() {
    const { info, classes, styles, fallbackImage } = this;
    return (
      <span staticClass="h-sprite-animation" class={classes} style={styles}>
        {this.$slots.default}
        {!!info && [
          <style domPropsInnerHTML={info.style}></style>,
          <span
            id={info.id}
            staticClass="h-sprite-animation__node"
            onAnimationend={(ev) => {
              this.$emit('animationend', this);
            }}
          />,
        ]}
        {!!fallbackImage && (
          <span staticClass="h-sprite-animation__node" style={fallbackImage} />
        )}
      </span>
    );
  },
})
export class HSpriteAnimationRef extends Vue implements HSpriteAnimationProps {
  @Prop({ type: [String, Array], required: true }) readonly src!:
    | string
    | string[];

  @Prop(String) readonly fallback?: string;
  @Prop(Boolean) readonly forceFallback!: boolean;
  @Prop({ type: Number, required: true }) readonly width!: number;

  @Prop({ type: Number, required: true }) readonly height!: number;
  @Prop(Number) readonly fps?: number;
  @Prop(Number) readonly duration?: number;
  @Prop(Boolean) readonly loop!: boolean;

  private isLoading: boolean = false;
  private fallbackIsLoaded: boolean = false;
  private imageInfo: HSpriteAnimationImageInfo | null = null;

  private error: any = null;

  get classes() {
    const { fallbackImageUrl, fallbackIsLoaded } = this;

    return {
      'h-sprite-animation--fallback': !!fallbackImageUrl,
      'h-sprite-animation--fallback-loaded': !!fallbackIsLoaded,
    };
  }

  get fallbackImageUrl() {
    const { error, forceFallback, fallback } = this;
    if ((error || forceFallback) && fallback) {
      return fallback;
    }
  }

  get fallbackImage() {
    const { fallbackImageUrl } = this;
    if (fallbackImageUrl) {
      return {
        backgroundImage: `url(${fallbackImageUrl})`,
      };
    }
  }

  private async loadFallback() {
    const { fallbackImageUrl } = this;
    if (fallbackImageUrl) {
      await loadImageSize(fallbackImageUrl);
      this.fallbackIsLoaded = true;
    }
  }

  get sources() {
    const { src } = this;
    return Array.isArray(src) ? src : [src];
  }

  get styles() {
    const { width, height } = this;
    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  }

  get info(): HSpriteAnimationInfo | undefined {
    const { imageInfo } = this;
    if (!imageInfo) return;

    const { width, height, loop } = this;
    let { fps, duration } = this;

    const frames = Math.floor(imageInfo.height / height);

    if (!duration) {
      if (!fps) {
        fps = 24;
      }
      const frameTickMS = 1000 / fps;
      duration = frames * frameTickMS;
    }

    if (!fps) {
      const diff = 1000 / duration;
      fps = frames / diff;
    }

    const id = `__HSpriteAnimation__${cheepUid()}`;

    const positions: number[] = [];

    for (let i = 0; i < frames; i++) {
      positions.push(i * height);
    }

    const prefixes = ['', '-webkit-'];
    const prefixedKeyframes = prefixes.map(
      (prefix) => `
        @${prefix}keyframes ${id}-animation {
          to { background-position: 0 -${imageInfo.height - height}px; }
        }
      `,
    );
    const prefixedAnimations = prefixes.map(
      (prefix) => `
        ${prefix}animation: ${id}-animation ${duration}ms forwards steps(${
        frames - 1
      })${loop ? ' infinite' : ' 1'};
      `,
    );

    const style = `
      #${id} {
        background: url(${imageInfo.src}) no-repeat;
        ${prefixedAnimations}
      }
      ${prefixedKeyframes}
    `;

    const info: HSpriteAnimationInfo = {
      id,
      width,
      height,
      frames,
      fps,
      duration,
      loop,
      positions,
      style,
    };

    return info;
  }

  private async loadSrc(src: string): Promise<HSpriteAnimationImageInfo> {
    const { width, height } = await loadImageSize(src);
    return {
      src,
      width,
      height,
    };
  }

  async load() {
    if (this.isLoading) return;

    this.isLoading = true;

    if (this.forceFallback) {
      await this.loadFallback();
      this.isLoading = false;
      return;
    }

    let info: HSpriteAnimationImageInfo | undefined;
    let error: any;
    for (const src of this.sources) {
      try {
        info = await this.loadSrc(src);
        break;
      } catch (_err) {
        error = _err;
        this.error = _err;
      }
    }
    if (info) {
      this.imageInfo = info;
    } else if (error) {
      this.error = error;
      this.$emit('error', error);
      await this.loadFallback();
    }
    this.isLoading = false;
  }
}

export const HSpriteAnimation = tsx
  .ofType<
    HSpriteAnimationProps,
    HSpriteAnimationEmits,
    HSpriteAnimationScopedSlots
  >()
  .convert(HSpriteAnimationRef);
