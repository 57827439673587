import {
  AsyncCombineResolver,
  AsyncCombineRequestState,
  AsyncFn,
} from './schemes';
import type { AsyncCombineRequestBucket } from './bucket';

/**
 * 非同期処理リクエスト
 */
export class AsyncCombineRequest {
  /**
   * 親バケット
   */
  readonly bucket: AsyncCombineRequestBucket;

  /**
   * 実行時引数リストをハッシュ文字列にしたもの
   */
  readonly hash: string;

  /**
   * 非同期処理のリソルバリスト
   */
  readonly resolvers: AsyncCombineResolver[] = [];

  /**
   * 実行状態
   */
  private _state: AsyncCombineRequestState = 'pending';

  /**
   * 処理本体
   */
  private _func: AsyncFn;

  /**
   * 実行状態
   */
  get state() {
    return this._state;
  }

  /**
   * まだ処理がはじまっていないにtrue
   */
  get isPending() {
    return this.state === 'pending';
  }

  /**
   * 処理中の場合にtrue
   */
  get isRunning() {
    return this.state === 'running';
  }

  /**
   * 解決済みの場合にtrue
   */
  get isResolved() {
    return this.state === 'resolved';
  }

  /**
   * 却下済みの場合にtrue
   */
  get isRejected() {
    return this.state === 'rejected';
  }

  /**
   * @param bucket - 親バケット
   * @param hash - 実行引数のハッシュ
   * @param func - 処理本体
   */
  constructor(bucket: AsyncCombineRequestBucket, hash: string, func: AsyncFn) {
    this.bucket = bucket;
    this.hash = hash;
    this._func = func;
  }

  /**
   * リゾルバを追加する
   *
   * @param resolver - 非同期処理のリソルバ
   * @param autoRun - pushと同時に自動実行する場合 true（すでに実行が始まっている場合には何もしない）
   */
  push(resolver: AsyncCombineResolver, autoRun?: boolean) {
    this.resolvers.push(resolver);
    autoRun && this.run();
  }

  /**
   * 処理を開始する
   *
   * ※ すでに実行が始まっている場合には何もしない
   */
  run() {
    if (!this.isPending) return;

    this._state = 'running';
    return this._func()
      .then((payload) => this.resolve('resolve', payload))
      .catch((err) => this.resolve('reject', err));
  }

  /**
   * 全てのリゾルバを解決する
   *
   * @param type - 解決か却下か
   * @param payload - 実行結果
   */
  resolve(type: 'resolve' | 'reject', payload: any) {
    this._state = type === 'resolve' ? 'resolved' : 'rejected';
    this.resolvers.forEach((resolver) => {
      resolver[type](payload);
    });
    this.resolvers.length = 0;
    this.bucket.removeByHash(this.hash);
  }
}
