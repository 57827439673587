import Vue from 'vue';
import { Context, Plugin, NuxtAppOptions } from '@nuxt/types';
import { HeaderHeightAppend } from '~/store/ui';

declare module 'vue/types/vue' {
  export interface Vue {
    $ui: UIService;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $ui: UIService;
  }
}

declare module '@nuxt/types' {
  export interface NuxtAppOptions {
    $ui: UIService;
  }

  export interface Context {
    $ui: UIService;
  }
}

export interface UIHeaderHeightAppend extends HeaderHeightAppend {}

export class UIService {
  readonly context: Context;

  get drawerIsActive(): boolean {
    return this.context.store.state.ui.drawerIsActive;
  }

  get currentSubDrawer(): string | null {
    return this.context.store.state.ui.currentSubDrawer;
  }

  /**
   * ヘッダーのみの高さ（お知らせ含む）
   */
  get internalHeaderHeight(): number {
    return this.context.store.state.ui.internalHeaderHeight;
  }

  get portalFooterHeight(): number {
    return this.context.store.state.ui.portalFooterHeight;
  }

  /**
   * ヘッダーのみの高さ（お知らせ含む）
   * ※「internalHeaderHeight」と何が違うんだ説
   */
  get originalHeaderHeight(): number {
    return this.context.store.getters['ui/originalHeaderHeight'];
  }

  /**
   * ヘッダー（お知らせ含む）＋ヘッダー追加要素の高さ
   */
  get headerHeight(): number {
    return this.context.store.getters['ui/headerHeight'];
  }

  get totalHeaderHeightAppends(): number {
    return this.context.store.getters['ui/totalHeaderHeightAppends'];
  }

  /**
   * 総合ヘッダ＋ポータルフッタの高さ
   * ※なんか名前おかしい
   */
  get totalHeaderHeight(): number {
    return this.context.store.getters['ui/totalHeaderHeight'];
  }

  get activatedFonts() {
    return this.context.store.state.ui.activatedFonts as string[];
  }

  get hasNotification() {
    return this.context.store.state.ui.hasNotification;
  }

  get notificationOffset() {
    if (!this.hasNotification) return 0;
    const { narrow, wide } = (this.context as any).$mq.match;
    if (narrow) return 42;
    if (wide) return 46;
    return 0;
  }

  constructor(context: Context) {
    this.context = context;
  }

  setHeaderHeight(height: number) {
    this.context.store.commit('ui/SET_HEADER_HEIGHT', height);
  }

  setHeaderHeightAppends(append: UIHeaderHeightAppend) {
    this.context.store.commit('ui/REMOVE_HEADER_HEIGHT_APPENDS', append.key);
    this.context.store.commit('ui/SET_HEADER_HEIGHT_APPENDS', append);
  }

  removeHeaderHeightAppends(key: string) {
    this.context.store.commit('ui/REMOVE_HEADER_HEIGHT_APPENDS', key);
  }

  setPortalFooterHeight(height: number) {
    this.context.store.commit('ui/SET_PORTAL_FOOTER_HEIGHT', height);
  }

  setCurrentSubDrawer(currentSubDrawer: string | null) {
    this.context.store.dispatch('ui/setCurrentSubDrawer', currentSubDrawer);
  }

  toggleDrawer(): Promise<void> {
    return this.context.store.dispatch('ui/toggleDrawer');
  }

  softToggleDrawer(): Promise<void> {
    return this.context.store.dispatch('ui/softToggleDrawer');
  }

  setDrawerIsActive(drawerIsActive: boolean): Promise<void> {
    return this.context.store.dispatch('ui/setDrawerIsActive', drawerIsActive);
  }

  showDrawer(): Promise<void> {
    return this.context.store.dispatch('ui/showDrawer');
  }

  closeDrawer(): Promise<void> {
    return this.context.store.dispatch('ui/closeDrawer');
  }

  setHasNotification(hasNotification: boolean) {
    this.context.store.commit('ui/SET_HAS_NOTIFICATION', hasNotification);
  }

  seeElementTop(
    el: HTMLElement | Vue,
    setting: { offset?: number; duration?: number } = {},
  ) {
    el = '$el' in el ? (el.$el as HTMLElement) : el;
    if ('getBoundingClientRect' in el) {
      const { top } = el.getBoundingClientRect();
      const { headerHeight } = this;
      const { containerHeight } = this.context.$window;
      let needScroll: number = 0;
      if (top < headerHeight) {
        needScroll = top - headerHeight;
      } else if (top > containerHeight) {
        needScroll = top - containerHeight;
      }
      if (Math.abs(needScroll) > 0) {
        const { offset = 0, duration } = setting;
        return this.context.$window.by(0, needScroll + offset, { duration });
      }
    }
  }

  pushActivatedFont(font: string) {
    return this.context.store.commit('ui/PUSH_ACTIVATED_FONT', font);
  }

  showLoadingCover() {
    this.hideLoadingCover();
    const el = document.createElement('div');
    el.className = 'h-loading-cover';
    el.innerHTML = '<div class="h-loading-cover__content"></div>';
    document.body.appendChild(el);
  }

  hideLoadingCover() {
    const el = document.querySelector('.h-loading-cover');
    if (el) {
      document.body.removeChild(el);
    }
  }
}

const plugin: Plugin = (context, inject) => {
  const service = new UIService(context);
  context.$ui = service;
  inject('ui', service);
};

export default plugin;
