import './HDrawerMenu.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNodeChildren } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { HProgressCircular } from '~/components';
import { NavigationItem } from '~/schemes';

export interface HDrawerMenuData extends NavigationItem {
  key: string;
  label: VNodeChildren;
  value?: VNodeChildren;
  hidden?: boolean;
  loading?: boolean;
  mini?: boolean;
  invert?: boolean;
}

export interface HDrawerMenuProps {
  to?: RawLocation;
  href?: string;
  target?: string;
  rel?: string;
  label?: VNodeChildren;
  value?: VNodeChildren;
  active?: boolean;
  loading?: boolean;
  mini?: boolean;
  invert?: boolean;
}

export interface HDrawerMenuEmits {
  onClick: MouseEvent;
}

export interface HDrawerMenuScopedSlots {}

@Component<HDrawerMenuRef>({
  name: 'HDrawerMenu',
  render(h) {
    const { to, href, target, value, loading, label, $listeners } = this;
    const hasClickListener = !!$listeners.click;
    const isNuxt = !!to;
    const isAnchor = !!href;
    const isLink = isNuxt || isAnchor;
    const clickable = !loading && (isLink || hasClickListener);

    const internalWrapper = (
      <span staticClass="h-drawer-menu__action__inner">
        <span
          staticClass="h-drawer-menu__label"
          domProps={
            label
              ? {
                  innerHTML: label,
                }
              : undefined
          }>
          {!label && this.$slots.default}
        </span>

        {!!value && <span staticClass="h-drawer-menu__value">{value}</span>}
      </span>
    );

    let { rel } = this;
    if (target === '_blank' && !rel) {
      rel = 'noopener';
    }
    const attrs =
      isLink && isAnchor
        ? {
            href,
            target,
            rel,
          }
        : undefined;

    const anchorId = this.$navigation.getHashByRawLocation(to || href);
    const directives = anchorId
      ? [
          {
            name: 'ev',
            value: {
              category: 'move',
              action: `to-${anchorId}`,
              id: `humberger_to-${anchorId}`,
            },
          },
        ]
      : undefined;

    const child = isLink ? (
      h(
        isNuxt ? 'nuxt-link' : 'a',
        {
          staticClass: 'h-drawer-menu__action',
          props: {
            to,
            event: '',
          },
          directives,
          [isNuxt ? 'nativeOn' : 'on']: {
            click: (e: MouseEvent) => {
              if (hasClickListener) {
                e.stopPropagation();
                this.$emit('click', e);
              }
              if (isNuxt || !e.defaultPrevented) {
                this.$navigation.resolveRoutableAction(e, to);
              }
            },
          },
          attrs,
        },
        [internalWrapper],
      )
    ) : (
      <button
        staticClass="h-drawer-menu__action"
        class={{
          'h-drawer-menu__action--noaction': !clickable,
        }}
        onClick={(ev) => {
          if (!hasClickListener) {
            return;
          }
          ev.stopPropagation();
          this.$emit('click', ev);
        }}>
        {internalWrapper}
      </button>
    );

    return (
      <li staticClass="h-drawer-menu" class={this.classes}>
        {child}
        {loading && (
          <HProgressCircular
            staticClass="h-drawer-menu__loading"
            indeterminate
            size={20}
            width={2}
          />
        )}
      </li>
    );
  },
})
export class HDrawerMenuRef extends Vue implements HDrawerMenuProps {
  @Prop() readonly to?: RawLocation;
  @Prop() readonly href?: string;
  @Prop() readonly target?: string;
  @Prop() readonly rel?: string;
  @Prop({ type: String }) readonly label?: VNodeChildren;
  @Prop({ type: String }) readonly value?: VNodeChildren;
  @Prop({ type: Boolean }) readonly active!: boolean;
  @Prop({ type: Boolean }) readonly loading!: boolean;
  @Prop({ type: Boolean }) readonly mini!: boolean;
  @Prop({ type: Boolean }) readonly invert!: boolean;

  get classes() {
    return {
      'h-drawer-menu--mini': this.mini,
      'h-drawer-menu--invert': this.invert,
      'h-drawer-menu--active': this.active,
      'h-drawer-menu--loading': this.loading,
    };
  }
}

export const HDrawerMenu = tsx
  .ofType<HDrawerMenuProps, HDrawerMenuEmits, HDrawerMenuScopedSlots>()
  .convert(HDrawerMenuRef);
