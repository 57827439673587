import './HGlobalsDialog.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { bodyScrollLock, clickOutside } from '@dadajam4/vue-stack';
import {
  HDialog,
  HListCurrencySelector,
  HListLanguageSelector,
  HPathIcon,
} from '~/components';

export interface HGlobalsDialogProps {
  value?: boolean;
}

export interface HGlobalsDialogEmits {
  onInput: boolean;
}

export interface HGlobalsDialogScopedSlots {}

/**
 * 言語/通貨設定ダイアログ(PC用)
 */
@Component<HGlobalsDialogRef>({
  name: 'HGlobalsDialog',
  directives: {
    bodyScrollLock,
    clickOutside,
  },
  render() {
    return (
      <HDialog
        contentClass="h-globals-dialog"
        active={this.modalIsActive}
        backdrop
        onChange={(active) => {
          this.modalIsActive = active;
        }}
        scopedSlots={{
          header: () => [
            <span domPropsInnerHTML="言語と表示通貨" />,
            <button
              staticClass="h-globals-dialog__close"
              type="button"
              onClick={this.close}>
              <HPathIcon
                staticClass="h-globals-dialog__close__icon"
                name="close"
              />
            </button>,
          ],
        }}>
        <div staticClass="h-globals-dialog__global-menu-set">
          <HListLanguageSelector
            staticClass="h-globals-dialog__global-menu-set__item h-globals-dialog__global-menu-set__item--language"
            narrowDump
          />
          <HListCurrencySelector
            staticClass="h-globals-dialog__global-menu-set__item h-globals-dialog__global-menu-set__item--currency"
            narrowDump
            wide
          />
        </div>
      </HDialog>
    );
  },
  watch: {
    value(value: boolean) {
      this.internalModalActive = value;
    },
  },
})
export class HGlobalsDialogRef extends Vue implements HGlobalsDialogProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;

  private internalModalActive: boolean = this.value;

  get modalIsActive() {
    return this.internalModalActive;
  }

  set modalIsActive(modalIsActive) {
    if (this.internalModalActive !== modalIsActive) {
      this.internalModalActive = modalIsActive;
      this.$emit('input', modalIsActive);
    }
  }

  show() {
    this.modalIsActive = true;
  }

  close() {
    this.modalIsActive = false;
  }
}

export const HGlobalsDialog = tsx
  .ofType<HGlobalsDialogProps, HGlobalsDialogEmits, HGlobalsDialogScopedSlots>()
  .convert(HGlobalsDialogRef);
