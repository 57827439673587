import './HListMedia.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MediaModuleProps, MediaModuleProperties } from './schemes';
import { createMediaModuleDerivedName } from './helpers';
import { getMediaDerived } from '~/schemes';
import { HListGalleryItemInput, HListGallery } from '~/components';

export interface HListMediaProps extends MediaModuleProps {}

export interface HListMediaEmits {}

export interface HListMediaScopedSlots {}

/** リストタイプが利用する派生メディア。現状 4:5 のmd（768px）しか利用しない */
const DRIVED_NAME = createMediaModuleDerivedName('md', '4/5');

@Component<HListMediaRef>({
  name: 'HListMedia',

  render(h) {
    const { items } = this;
    if (!items.length) {
      return h();
    }

    return (
      <div staticClass="h-list-media">
        <HListGallery staticClass="h-list-media__gallery" items={items} />
      </div>
    );
  },
})
export class HListMediaRef extends Vue implements HListMediaProps {
  @Prop({ type: Object, required: true })
  readonly value!: MediaModuleProperties;

  /** アスペクト比 */
  get aspectRatio() {
    return this.value.aspectRatio;
  }

  get items(): HListGalleryItemInput[] {
    return this.value.items.map((item) => {
      return {
        images: getMediaDerived(DRIVED_NAME, item, ''),
        aspectRatio: this.aspectRatio,
      };
    });
  }
}

export const HListMedia = tsx
  .ofType<HListMediaProps, HListMediaEmits, HListMediaScopedSlots>()
  .convert(HListMediaRef);
