import './HHotelGalleriesCarousel.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import Swiper from 'swiper';
import type { SwiperOptions } from 'swiper';
import { Component, Prop } from 'vue-property-decorator';
import { HVideoPlayer } from '../HVideoPlayer';
import { HMediaOverlayCaption } from '../HMediaOverlayCaption/HMediaOverlayCaption';
import { MediaInfoMeta } from '~/schemes';

export interface HHotelGalleriesCarouselItem {
  id: string;
  main: string;
  thumb: string;
  video?: string;
  caption: string;
  meta?: MediaInfoMeta;
}

interface SwiperElement extends HTMLElement {
  swiper: Swiper;
}

export interface HHotelGalleriesCarouselProps {
  items: HHotelGalleriesCarouselItem[];
  initialSlide?: number;
  loop?: boolean;
}

export interface HHotelGalleriesCarouselEmits {
  onChangeIndex: number;
}

export interface HHotelGalleriesCarouselScopedSlots {}

@Component<HHotelGalleriesCarouselRef>({
  name: 'HHotelGalleriesCarousel',

  render() {
    const { items } = this;

    const { currentItem } = this;

    return (
      <div staticClass="h-hotel-galleries-carousel">
        <p staticClass="h-hotel-galleries-carousel__caption">
          {(currentItem && currentItem.caption) || ''}
        </p>

        <div staticClass="h-hotel-galleries-carousel__main-wrapper">
          <div
            staticClass="swiper-container h-hotel-galleries-carousel__main"
            key="main"
            ref="main"
            {...{
              directives: [
                {
                  name: 'swiper',
                  arg: 'mainSwiper',
                  value: this.mainOptions,
                },
              ],
            }}>
            <div staticClass="swiper-wrapper">
              {items.map((item) => (
                <div staticClass="swiper-slide" key={item.id}>
                  {item.video ? (
                    <div staticClass="h-hotel-galleries-carousel__video">
                      <HVideoPlayer
                        src={item.video}
                        staticClass="h-hotel-galleries-carousel__video__player"
                        loop
                        muted
                        backgroundMode
                        fit
                      />
                    </div>
                  ) : (
                    <div staticClass="h-hotel-galleries-carousel__image">
                      <img
                        staticClass="swiper-lazy"
                        data-src={this.$res.img(item.main)}
                      />
                      <div staticClass="swiper-lazy-preloader" />
                      {item.meta &&
                        item.meta.caption &&
                        this.$language.current === 'ja' && (
                          <HMediaOverlayCaption
                            modelValue={{
                              caption: item.meta.caption,
                              position: 'left',
                            }}
                          />
                        )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {this.useThumbnail && (
          <div
            staticClass="swiper-container h-hotel-galleries-carousel__thumb"
            {...{
              directives: [
                {
                  name: 'swiper',
                  arg: 'thumbSwiper',
                  value: this.thumbOptions,
                },
              ],
            }}
            ref="thumb">
            <div staticClass="swiper-wrapper">
              {items.map((item) => (
                <div staticClass="swiper-slide" key={item.id}>
                  <img src={item.thumb} alt="" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  },

  mounted() {
    const { mainSwiper, thumbSwiper } = this;
    if (
      mainSwiper &&
      mainSwiper.controller &&
      thumbSwiper &&
      thumbSwiper.controller
    ) {
      mainSwiper.controller.control = thumbSwiper;
      thumbSwiper.controller.control = mainSwiper;
    }
    this.syncIndex();
  },

  beforeDestroy() {
    const { mainSwiper, thumbSwiper } = this;
    mainSwiper && mainSwiper.destroy(true, true);
    thumbSwiper && thumbSwiper.destroy(true, true);
  },
})
export class HHotelGalleriesCarouselRef
  extends Vue
  implements HHotelGalleriesCarouselProps {
  $refs!: {
    main: SwiperElement;
    thumb: SwiperElement;
  };

  mainSwiper?: Swiper;
  thumbSwiper?: Swiper;

  @Prop({ type: Array, required: true })
  readonly items!: HHotelGalleriesCarouselItem[];

  @Prop({ type: Number, default: 0 }) readonly initialSlide!: number;
  @Prop({ type: Boolean, default: true }) readonly loop!: boolean;

  private internalIndex: number = this.initialSlide;

  get useThumbnail() {
    return this.items.length > 1;
  }

  get currentItem() {
    return this.items[this.currentIndex];
  }

  get loopedSlides() {
    return this.items.length;
  }

  get currentIndex() {
    return this.internalIndex;
  }

  set currentIndex(currentIndex) {
    if (this.internalIndex !== currentIndex) {
      this.internalIndex = currentIndex;
      this.$emit('changeIndex', currentIndex);
    }
  }

  private syncIndex() {
    const { mainSwiper } = this;
    if (!mainSwiper) {
      // eslint-disable-next-line no-useless-return
      return;
    }
    this.currentIndex = mainSwiper.realIndex;
  }

  get mainOptions(): SwiperOptions {
    return {
      initialSlide: this.initialSlide,
      spaceBetween: 0,
      loop: this.loop,
      loopedSlides: this.loopedSlides,
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 3,
      },
      on: {
        slideChange: () => {
          this.syncIndex();
        },
      },
    };
  }

  get thumbOptions(): SwiperOptions {
    return {
      initialSlide: this.initialSlide,
      spaceBetween: 4,
      slidesPerView: 'auto',
      loop: this.loop,
      loopedSlides: this.loopedSlides,
      slideToClickedSlide: true,
      preloadImages: true,
      centeredSlides: true,
      watchSlidesVisibility: true,
    };
  }
}

export const HHotelGalleriesCarousel = tsx
  .ofType<
    HHotelGalleriesCarouselProps,
    HHotelGalleriesCarouselEmits,
    HHotelGalleriesCarouselScopedSlots
  >()
  .convert(HHotelGalleriesCarouselRef);
