import './HCheckbox.scss';

import * as tsx from 'vue-tsx-support';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  HFormChoice,
  HFormChoiceProps,
  HFormChoiceEmits,
  HFormChoiceScopedSlots,
} from './mixins/HFormChoice';

export const HCheckboxSizes = ['sm', 'md'] as const;

export type HCheckboxSize = typeof HCheckboxSizes[number];

export interface HCheckboxProps extends HFormChoiceProps {
  indeterminate?: boolean;
  checkHandler?: (ev: Event) => any;
  size?: HCheckboxSize;
}

export interface HCheckboxEmits extends HFormChoiceEmits {}

export interface HCheckboxScopedSlots extends HFormChoiceScopedSlots {
  default: HCheckboxRef;
}

@Component<HCheckboxRef>({
  name: 'HCheckbox',
  render() {
    const defaultSlot = this.$scopedSlots.default;
    return (
      <span
        staticClass="h-checkbox"
        class={this.classes}
        tabindex={this.computedTabIndex}
        onClick={this.internalCheckHandler}
        onKeydown={(ev) => {
          if (ev.which !== 13 && ev.which !== 32) {
            return;
          }
          this.internalCheckHandler(ev);
        }}>
        <i staticClass="h-checkbox__faux">
          <span staticClass="h-checkbox__faux__symbol">
            <span staticClass="h-checkbox__faux__path h-checkbox__faux__path--1" />
            <span staticClass="h-checkbox__faux__path h-checkbox__faux__path--2" />
          </span>
        </i>
        {defaultSlot && (
          <span staticClass="h-checkbox__label">{defaultSlot(this)}</span>
        )}
      </span>
    );
  },
})
export class HCheckboxRef
  extends Mixins(HFormChoice)
  implements HFormChoiceProps {
  @Prop(Boolean) readonly indeterminate!: boolean;
  @Prop({ type: Function }) readonly checkHandler!: (ev: Event) => any;
  @Prop({ type: String, default: 'md' }) readonly size!: HCheckboxSize;

  get classes() {
    return {
      'h-checkbox--choiced': this.choiced,
      'h-checkbox--disabled': this.isDisabled,
      'h-checkbox--noaction': !this.canOperation,
      'h-checkbox--has-error': this.hasError,
      'h-checkbox--indeterminate': this.indeterminate,
      [`h-checkbox--${this.size}`]: true,
    };
  }

  private internalCheckHandler(e: Event) {
    if (!this.canOperation) {
      return;
    }
    if (this.checkHandler) {
      this.checkHandler(e);
      return;
    }
    this.toggle();
    this.validationValueHasChanged = true;
  }
}

export const HCheckbox = tsx
  .ofType<HCheckboxProps, HCheckboxEmits, HCheckboxScopedSlots>()
  .convert(HCheckboxRef);
