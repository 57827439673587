import './HSpecialPageLayout.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { HSpecialPageModules } from './HSpecialPageModules';
import { SpecialPage as GfSpecialPage } from '~/schemes';

export const HSpecialPageLayout = tsx.component({
  name: 'HSpecialPageLayout',
  functional: true,

  props: {
    layout: {
      type: Object as PropType<GfSpecialPage['layout']>,
      required: true,
    },
    updatedAt: {
      type: String,
    },
  },

  render(h, { props, parent }) {
    const { updatedAt } = props;

    return (
      <div class="h-special-page-layout">
        <HSpecialPageModules modules={props.layout.modules} />
        {updatedAt && (
          <small staticClass="h-special-page-layout__updatedAt">
            {`${new Date(props.updatedAt)
              .toLocaleDateString('sv-SE')
              .split('-')
              .join('.')} ${parent.$t('label.published')}`}
          </small>
        )}
      </div>
    );
  },
});
