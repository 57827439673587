export * from './vue';
export * from './date';
export * from './image';
export * from './link';
export * from './trim';
export * from './wysiwyg';
export * from './dom';
export * from './array';
export * from './head';
export * from './language';

export function toStr(source?: string | number | null) {
  if (source == null) return '';
  if (typeof source === 'number') source = source.toString();
  return source;
}

export const toInt = (source: string | number, radix?: 10): number => {
  return parseInt(source as string, radix);
};

export const toFloat = (source: string | number): number => {
  return parseFloat(source as string);
};

export function isPromise<T = any>(source: any): source is Promise<T> {
  return (
    !!source &&
    (typeof source === 'object' || typeof source === 'function') &&
    typeof source.then === 'function'
  );
}

/**
 * Makes the first character of a string uppercase
 */
export function upperFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * 半角を1、全角を2でlengthを算出する
 */
export function widthedStringLength(str: string) {
  let len = 0;
  str = escape(str);
  for (let i = 0, l = str.length; i < l; i++, len++) {
    if (str.charAt(i) === '%') {
      if (str.charAt(++i) === 'u') {
        i += 3;
        len++;
      }
      i++;
    }
  }
  return len;
}

export function urlJoin(...paths: string[]) {
  const resultArray: string[] = [];
  if (paths.length === 0) {
    return '';
  }

  if (typeof paths[0] !== 'string') {
    throw new TypeError('Url must be a string. Received ' + paths[0]);
  }

  // If the first part is a plain protocol, we combine it with the next part.
  if (paths[0].match(/^[^/:]+:\/*$/) && paths.length > 1) {
    const first = paths.shift();
    paths[0] = first + paths[0];
  }

  // There must be two or three slashes in the file protocol, two slashes in anything else.
  if (paths[0].match(/^file:\/\/\//)) {
    paths[0] = paths[0].replace(/^([^/:]+):\/*/, '$1:///');
  } else {
    paths[0] = paths[0].replace(/^([^/:]+):\/*/, '$1://');
  }

  for (let i = 0; i < paths.length; i++) {
    let component = paths[i];

    if (typeof component !== 'string') {
      throw new TypeError('Url must be a string. Received ' + component);
    }

    if (component === '') {
      continue;
    }

    if (i > 0) {
      // Removing the starting slashes for each component but the first.
      component = component.replace(/^[/]+/, '');
    }
    if (i < paths.length - 1) {
      // Removing the ending slashes for each component but the last.
      component = component.replace(/[/]+$/, '');
    } else {
      // For the last component we will combine multiple slashes to a single one.
      component = component.replace(/[/]+$/, '/');
    }

    resultArray.push(component);
  }

  let str = resultArray.join('/');
  // Each input component is now separated by a single slash except the possible first plain protocol part.

  // remove trailing slash before parameters or hash
  str = str.replace(/\/(\?|&|#[^!])/g, '$1');

  // replace ? in parameters with &
  const parts = str.split('?');
  str = parts.shift() + (parts.length > 0 ? '?' : '') + parts.join('&');

  return str;
}

export function nl2br(str: string) {
  str = str == null ? '' : str.trim();
  return str.replace(/\n/g, '<br />');
}

export const TRAILING_SLASH_RE = /\/($|\?|#)/;
export const TRAILING_SLASH_REPLACE_RE = /($|\?|#)/;

export function trailingSlash(source: string = '') {
  if (TRAILING_SLASH_RE.test(source)) return source;
  return source.replace(TRAILING_SLASH_REPLACE_RE, '/$1');
}

export function paramsSerialize(a: object) {
  const s: string[] = [];
  const add = function (k: string, v: any) {
    v = typeof v === 'function' ? v() : v;
    v = v === null ? '' : v === undefined ? '' : v;
    s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
  };
  const buildParams = function (prefix: string, obj: object) {
    let i, len, key;

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(
            prefix +
              '[' +
              (typeof obj[i] === 'object' && obj[i] ? i : '') +
              ']',
            obj[i],
          );
        }
      } else if (String(obj) === '[object Object]') {
        for (key in obj) {
          buildParams(prefix + '[' + key + ']', obj[key]);
        }
      } else {
        add(prefix, obj);
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      const keys = Object.keys(obj).sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      for (key of keys) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  };

  return buildParams('', a).join('&');
}

export function toHalfWidth(input?: string, withNobasibou?: boolean) {
  let converted = (input + '').replace(/[！-～]/g, (input) => {
    // eslint-disable-next-line unicorn/number-literal-case
    return String.fromCharCode(input.charCodeAt(0) - 0xfee0);
  });
  if (withNobasibou) {
    converted = converted.replace(/ー/g, '-');
  }
  return converted;
}

export function extractHtmlText(source?: string | null | number) {
  return toStr(source)
    .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
    .replace(/\n/g, '');
}

export function isObject<
  T extends { [key: string]: any } = { [key: string]: any }
>(source: unknown): source is T {
  return !!source && typeof source === 'object' && !Array.isArray(source);
}

/**
 * 現在時刻＋乱数なチープなuidを生成する
 */
export function cheepUid(strong = 1000) {
  return (
    new Date().getTime().toString(16) +
    Math.floor(strong * Math.random()).toString(16)
  );
}

// HTMLのタグ（ブラケットの開始から終了まで）部分にマッチする正規表現
const HTML_TAG_MATCH_RE = /(<([^>]+)>)/gi;

/**
 * 指定の文字列からHTMLのタグ（ブラケットの開始から終了まで）部分を除去する
 * @param str - 変換元の文字列
 * @returns HTMLタグ除去済みの文字列
 */
export function removeHTMLTag(str: string) {
  return str.replace(HTML_TAG_MATCH_RE, '');
}
