import './HRestaurantListItem.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HNotification } from '../HNotification';
import { HSimpleGallery, HSimpleGalleryItemInput } from '../HSimpleGallery';
import { HThemeDecoration } from '../HThemeDecoration';
import { HExpandTransition } from '../transitions';
import { HIcon } from '../HIcon';
import { HBtn } from '../HBtn';
import { HExpandButton } from '../HExpandButton';
import { HRestaurantInformation } from './HRestaurantInformation';
import {
  Restaurant,
  RestaurantCommons,
  HotelBasicInfo,
  RestaurantLayoutType,
} from '~/schemes';

export interface HRestaurantListItemProps {
  /** 食事処共通設定 */
  commons: RestaurantCommons;

  /** 食事処 */
  data: Restaurant;

  /** 施設基本情報 */
  hotel: HotelBasicInfo;

  /** 表示させる種類（メイン or サブ） */
  layoutType: RestaurantLayoutType;
}

export interface HRestaurantListItemEmits {}

export interface HRestaurantListItemScopedSlots {}

@Component<HRestaurantListItemRef>({
  name: 'HRestaurantListItem',

  render(h) {
    const { data, commons, images, notifications, layoutType } = this;
    const { name, notice, description, subCaption, ruby, hasPage, id } = data;

    return (
      <li
        staticClass="h-restaurant-list-item"
        class={`h-restaurant-list-item--${layoutType}`}
        attrs={this.attrs}>
        {images.length > 0 && (
          <div staticClass="h-restaurant-list-item__image">
            <HSimpleGallery
              staticClass={`h-restaurant-list-item__image__gallery`}
              items={images}
            />
          </div>
        )}
        <div staticClass="h-restaurant-list-item__inner">
          <div staticClass="h-restaurant-list-item__header">
            {!!subCaption && (
              <div staticClass="h-restaurant-list-item__sub-name">
                {subCaption}
              </div>
            )}

            <h3 staticClass="h-restaurant-list-item__name">{name}</h3>

            {!!ruby && (
              <div staticClass="h-restaurant-list-item__ruby">{ruby}</div>
            )}

            {this.$theme.is('risonare') && (
              <HThemeDecoration
                staticClass="h-restaurant-list-item__deco"
                size="sm"
              />
            )}
          </div>

          {!!notice && (
            <div
              staticClass="h-restaurant-list-item__notice"
              v-wysiwyg={notice}
            />
          )}

          {!!description && (
            <div
              staticClass="h-restaurant-list-item__description h-theme-lead"
              v-wysiwyg={description}
            />
          )}

          {notifications.length > 0 && (
            <div staticClass="h-restaurant-list-item__notifications">
              {notifications.map((notification) => {
                return (
                  <HNotification
                    staticClass="h-restaurant-list-item__notifications__item"
                    data={notification}
                    dense
                    scopedSlots={{
                      title: () => {
                        return notification.title;
                      },
                    }}
                  />
                );
              })}
            </div>
          )}

          <div staticClass="h-restaurant-list-item__info">
            <HExpandButton label={this.$t('label.information') as string}>
              <HRestaurantInformation
                staticClass="h-restaurant-list-item__info__table"
                commons={commons}
                value={data}
              />
            </HExpandButton>
          </div>

          {!!hasPage && (
            <div staticClass="h-restaurant-list-item__links">
              <HBtn
                staticClass="h-restaurant-list-item__link"
                color="plain"
                to={this.$language.link(
                  `/hotels/${this.hotel.slug}/dining/${id}/`,
                )}>
                {this.$t('label.details')}
              </HBtn>
            </div>
          )}
        </div>
      </li>
    );
  },
})
export class HRestaurantListItemRef
  extends Vue
  implements HRestaurantListItemProps {
  @Prop({ type: Object, required: true }) readonly commons!: RestaurantCommons;
  @Prop({ type: Object, required: true }) readonly data!: Restaurant;
  @Prop({ type: Object, required: true }) readonly hotel!: HotelBasicInfo;
  @Prop({ type: String, required: true })
  readonly layoutType!: RestaurantLayoutType;

  @Prop(String) readonly id?: string;

  private isOpened: boolean = false;

  get attrs() {
    const { id } = this;
    return id == null ? undefined : { id };
  }

  /** 画像リスト */
  get images(): HSimpleGalleryItemInput[] {
    return this.data.images.map((image) => {
      const main = image.main;
      const lg = image['lg_1/1'];
      // const md = image['md_4/3']; // サブレストラン用のmdはまだ利用しない
      const url = lg || main || '';
      return {
        url,
      };
    });
  }

  /** お知らせリスト */
  get notifications() {
    return this.data.notifications;
  }

  toggle() {
    this.isOpened = !this.isOpened;
  }
}

export const HRestaurantListItem = tsx
  .ofType<
    HRestaurantListItemProps,
    HRestaurantListItemEmits,
    HRestaurantListItemScopedSlots
  >()
  .convert(HRestaurantListItemRef);
