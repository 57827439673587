import './HSimpleGallery.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode, VNodeData, VNodeChildren } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HBtn } from '../HBtn';
import { HHotelGalleriesModal } from '../HHotelGalleries';
import {
  HSimpleGalleryItem,
  HSimpleGalleryItemRef,
  HSimpleGalleryItemProps,
} from './HSimpleGalleryItem';
import { mergeVNodeData } from '~/helpers';
import { Gallery, GalleryCategory } from '~/schemes';

export interface HSimpleGalleryItemInput
  extends Omit<HSimpleGalleryItemProps, 'index'> {
  key?: string;

  /** 拡大した時の画像URL */
  enlargeUrl?: string;

  /** サムネイルレベルの画像URL */
  thumbUrl?: string;
}

export interface HSimpleGalleryProps {
  items: HSimpleGalleryItemInput[];
  interval?: number;

  /**
   * 拡大可能か
   *
   * * この設定を行なった場合は、モーダル拡大のためのボタンが表示され、自動切り替えは行われません
   */
  enlargeable?: boolean;
}

export interface HSimpleGalleryEmits {}

export interface HSimpleGalleryScopedSlotsPayload {
  gallery: HSimpleGalleryRef;
  renderGallery: (data?: VNodeData) => VNode;
  renderPagination: () => VNode | false;
}

export interface HSimpleGalleryScopedSlots {
  custom: HSimpleGalleryScopedSlotsPayload;
}

/** 拡大可能な時の設定 */
interface EnlargeSettings {
  label: VNodeChildren;
  galleryCategories: GalleryCategory[];
  galleryItems: Gallery[];
}

@Component<HSimpleGalleryRef>({
  name: 'HSimpleGallery',
  provide() {
    return {
      gallery: this,
    };
  },
  render() {
    const { custom: customSlot } = this.$scopedSlots;
    if (customSlot) {
      let paginationRendered = false;
      const payload: HSimpleGalleryScopedSlotsPayload = {
        gallery: this,
        renderGallery: (data?: VNodeData) =>
          this.renderGallery(paginationRendered, data),
        renderPagination: () => {
          paginationRendered = true;
          return this.renderDots(true);
        },
      };

      return (
        <div staticClass="h-simple-gallery-wrapper">{customSlot(payload)}</div>
      );
    }
    return this.renderGallery();
  },
  watch: {
    enlargeable() {
      // 拡大有効状態が無効に変化した時は切り替えタイマーを起動しておく
      if (!this.enlargeable) {
        this.startTimer();
      }
    },
  },
  beforeDestroy() {
    this.clearTimer();
  },
})
export class HSimpleGalleryRef extends Vue implements HSimpleGalleryProps {
  $refs!: {
    items: HSimpleGalleryItemRef[];
  };

  @Prop({ type: Array, required: true })
  readonly items!: HSimpleGalleryItemInput[];

  @Prop({ type: Number, default: 7000 }) readonly interval!: number;
  @Prop(Boolean) readonly enlargeable!: boolean;

  private internalIndex: number = 0;
  private intervalTimerId: number | null = null;

  /** 拡大モーダルの起動状態 */
  private modalIsActive: boolean = false;

  get currentIndex() {
    return this.internalIndex;
  }

  get computedItems(): HSimpleGalleryItemInput[] {
    return this.items.map((item, index) => {
      const {
        url,
        narrowURL,
        key = 'na',
        thumbUrl,
        enlargeUrl,
        meta,
        isEven,
      } = item;
      return {
        key: `${key}-${index}-${url}`,
        url,
        narrowURL,
        thumbUrl,
        enlargeUrl,
        meta,
        isEven,
      };
    });
  }

  get count() {
    return this.items.length;
  }

  /** 拡大可能な時、その設定 */
  get enlargeSettings(): EnlargeSettings | void {
    const { enlargeable, count, computedItems } = this;
    if (!enlargeable) return;

    // 本当はカテゴリなんて概念はないのだけど、仕方なくやる
    const categoryId = 'default';

    return {
      label: (count > 1
        ? this.$t('value.viewPhotos', { count })
        : this.$t('action.enlargePhoto')) as string,
      galleryCategories: [
        {
          id: categoryId,
          name: '',
        },
      ],
      galleryItems: computedItems.map(
        ({ key, thumbUrl, enlargeUrl, url }, index) => ({
          id: key || String(index),
          categoryId,
          s: thumbUrl || url,
          m: url,
          l: url,
          xl: enlargeUrl || url,
        }),
      ),
    };
  }

  /**
   * 次のアイテムをアクティブにする
   */
  next() {
    this.setIndex(this.getNextIndex());
  }

  /**
   * 拡大モーダルを表示する
   */
  showModal() {
    this.modalIsActive = true;
  }

  /**
   * アクティブインデックスをセットする
   *
   * @param index - インデックス
   */
  setIndex(index) {
    if (this.internalIndex === index) {
      return;
    }
    this.internalIndex = index;
    this.loadNearbyItems();
  }

  /**
   * 現在セットされているインデックスに対して直近のアイテム（現在＆1つ次）をロードする
   */
  loadNearbyItems() {
    const { currentIndex } = this;
    const nextIndex = this.getNextIndex();
    const { items = [] } = this.$refs;
    const targets: HSimpleGalleryItemRef[] = [];
    const currentItem = items[currentIndex];
    currentItem && targets.push(currentItem);
    if (currentIndex !== nextIndex) {
      const nextItem = items[nextIndex];
      nextItem && targets.push(nextItem);
    }
    return Promise.all(targets.map((target) => target.load()));
  }

  /**
   * 次のアイテムのインデックスを取得する
   * @returns 現在のインデックスに対して次のインデックス
   */
  private getNextIndex() {
    const { currentIndex, count } = this;
    return (currentIndex + 1) % count;
  }

  private clearTimer() {
    if (this.intervalTimerId !== null) {
      clearTimeout(this.intervalTimerId);
      this.intervalTimerId = null;
    }
  }

  private startTimer() {
    this.clearTimer();
    if (this.enlargeable) return;

    this.intervalTimerId = window.setTimeout(() => {
      this.next();
      this.startTimer();
    }, this.interval);
  }

  private renderDots(customized = false) {
    const { currentIndex, computedItems: items, enlargeable } = this;
    return (
      !enlargeable &&
      items.length >= 2 && (
        <nav
          staticClass="h-simple-gallery__dots"
          class={{
            'h-simple-gallery__dots--customized': customized,
          }}>
          {items.map((item, itemIndex) => (
            <button
              type="button"
              staticClass="h-simple-gallery__dot"
              class={
                currentIndex === itemIndex && 'h-simple-gallery__dot--active'
              }
              key={item.key}
              value={itemIndex}
              onClick={(ev) => {
                this.setIndex(itemIndex);
                this.startTimer();
              }}
            />
          ))}
        </nav>
      )
    );
  }

  private onInview() {
    this.startTimer();
    this.loadNearbyItems();
  }

  private onOutview() {
    this.clearTimer();
  }

  private renderGallery(skipDots?: boolean, data: VNodeData = {}) {
    const { computedItems, enlargeable, enlargeSettings } = this;
    const items = enlargeable ? [computedItems[0]] : computedItems;
    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'h-simple-gallery',
          directives: [
            {
              name: 'inview',
              value: {
                in: this.onInview,
                out: this.onOutview,
              },
            },
          ],
        })}>
        <div staticClass="h-simple-gallery__items">
          {items.map((item, itemIndex) => (
            <HSimpleGalleryItem
              key={item.key}
              ref="items"
              refInFor
              index={itemIndex}
              url={item.url}
              narrowURL={item.narrowURL}
              meta={item.meta}
              isEven={item.isEven}
            />
          ))}
        </div>
        {!skipDots && this.renderDots()}

        {/** 拡大設定有効時のモーダルとか */}
        {enlargeSettings && [
          <HBtn
            staticClass="h-simple-gallery__enlarge"
            type="button"
            color="glass"
            onClick={this.showModal}>
            {enlargeSettings.label}
          </HBtn>,
          <HHotelGalleriesModal
            v-model={this.modalIsActive}
            galleryCategories={enlargeSettings.galleryCategories}
            galleries={enlargeSettings.galleryItems}
            loop={false}
          />,
        ]}
      </div>
    );
  }
}

export const HSimpleGallery = tsx
  .ofType<HSimpleGalleryProps, HSimpleGalleryEmits, HSimpleGalleryScopedSlots>()
  .convert(HSimpleGalleryRef);
