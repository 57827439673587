import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { watch } from 'fs-extra';
import { HotelBasicInfo, HotelDetail } from '~/schemes';
import { HPrice } from '~/components';

/**
 * ブランドの最低料金を算出する際に、除外する宿GETS施設IDのリスト
 *
 * @see https://hoshinoresorts-dev.slack.com/archives/C02GV534RUY/p1700805741299859?thread_ts=1700647506.447919&cid=C02GV534RUY
 */
const EXCLUDE_BRAND_HOTELS = [
  '0000000503', // 星のやグーグァン
  '0000000502', // 星のやバリ
  '2000001002', // リゾナーレグアム
  '0000000505', // 嘉助天台
  '0000000504', // サーフジャック ハワイ
];

const NOT_USING_PAIDY = [
  '0000000503', // 星のやグーグァン
  '0000000502', // 星のやバリ
  '2000001002', // リゾナーレグアム
  '0000000505', // 嘉助天台
  '0000000504', // サーフジャック ハワイ
  '0000000002', // ホテルブレストンコート
  '0000000019', // リゾナーレ大阪
];

// Paidyの分割払いの回数
const paymentCount = 6;

export interface HHotelPriceInfoProps {
  hotel?: HotelDetail;
  brandSlug?: string;
  selectedHotelInfo?: HotelBasicInfo;
}

export interface HHotelPriceInfoEmits {}

export interface HHotelPriceInfoScopedSlots {}

@Component<HHotelPriceInfoRef>({
  name: 'HHotelPriceInfo',
  render() {
    const { selectedHotelInfo, _brand, _hotel, isJapanese, canUsePaidy } = this;

    /** 最低価格(1泊1室) */
    let lowestPrice: number | undefined;
    // Paidy用の「1泊1名を6で割った金額」
    let paidyPrice: number | undefined;
    /** 宿GETS管理画面で設定されている基準通貨 */
    let baseRate: string;

    // 施設サイト
    if (_hotel) {
      lowestPrice = _hotel.lowestPrice || 0;
      if (lowestPrice === 0) lowestPrice = undefined;
      // Paidy利用可能施設、かつ、lowestPriceに数値が入っている時paidyPriceを設定する（小数点以下は切り上げ）
      if (canUsePaidy && lowestPrice) {
        paidyPrice = Math.ceil(lowestPrice / paymentCount);
      }
      const { ygets } = _hotel;
      baseRate = ygets ? ygets.baseRate : 'JPY';
    }
    // ブランドサイトのマップのカードで使用するとき
    else if (selectedHotelInfo && _brand) {
      baseRate = selectedHotelInfo.baseRate
        ? selectedHotelInfo.baseRate
        : 'JPY';

      if (selectedHotelInfo.lowestPrice) {
        lowestPrice = selectedHotelInfo.lowestPrice;
      }

      const isInvalid =
        !!selectedHotelInfo.ygetsId &&
        NOT_USING_PAIDY.includes(selectedHotelInfo.ygetsId);

      if (lowestPrice && !isInvalid && isJapanese) {
        paidyPrice = Math.ceil(lowestPrice / paymentCount);
      }
    }
    // ブランドサイト
    else if (_brand) {
      baseRate = 'JPY'; // 暫定版CMSでは仕方なくJPY固定。本番CMSの時にちゃんとする
      const hotels = this.$hotel.hotelsByBrandId(_brand.id);
      hotels.forEach(({ lowestPrice: _lowestPrice, ygetsId }) => {
        if (
          _lowestPrice == null ||
          _lowestPrice === 0 ||
          (!!ygetsId && EXCLUDE_BRAND_HOTELS.includes(ygetsId))
        )
          return;
        if (lowestPrice === undefined || lowestPrice > _lowestPrice) {
          lowestPrice = _lowestPrice;
        }
        if (canUsePaidy && lowestPrice) {
          paidyPrice = Math.ceil(lowestPrice / paymentCount);
        }
      });
    } else {
      throw new Error('missing hotel and brand');
    }

    return (
      <div staticClass="h-hotel-price-info">
        <div staticClass="h-hotel-price-info__condition">
          {this.$t('label.hotelLowestPricePrefix')}
        </div>
        <HPrice staticClass="h-hotel-price-info__amount" base={baseRate}>
          {`${lowestPrice}${this.$t('chore.dash')}`}
        </HPrice>
        {paidyPrice && (
          <div staticClass="h-hotel-price-info__paidy">
            <span staticClass="h-hotel-price-info__unit">
              {this.$t('value.count', { count: paymentCount })}
            </span>
            {this.$t('label.afterPay')}
            <HPrice base={baseRate}>{paidyPrice}</HPrice>
            <span staticClass="h-hotel-price-info__unit">
              {this.$t('chore.pricePerMonth')}
            </span>
            {this.$t('chore.dash')}
          </div>
        )}
      </div>
    );
  },
})
export default class HHotelPriceInfoRef
  extends Vue
  implements HHotelPriceInfoProps {
  @Prop({ type: Object }) readonly hotel!: HotelDetail;
  @Prop({ type: String }) readonly brandSlug!: string;
  @Prop({ type: Object }) readonly selectedHotelInfo!: HotelBasicInfo;

  get _brand() {
    return this.brandSlug
      ? this.$hotel.brandBasicBySlug(this.brandSlug)
      : undefined;
  }

  get _hotel() {
    return this._brand ? null : this.hotel || this.$hotel.current;
  }

  get isJapanese() {
    return this.$language.current === 'ja';
  }

  /** Paidyが利用可能な施設か、かつ現在の表示言語が日本語であるかどうか */
  get canUsePaidy() {
    return (
      this._hotel &&
      this._hotel.ygets &&
      this._hotel.ygets.paidyEnableFlg &&
      this.isJapanese
    );
  }
}

export const TypedHHotelPriceInfo = tsx
  .ofType<
    HHotelPriceInfoProps,
    HHotelPriceInfoEmits,
    HHotelPriceInfoScopedSlots
  >()
  .convert(HHotelPriceInfoRef);
