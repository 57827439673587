import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelFeatureItemImage } from '../MyHotelFeature/MyHotelFeatureItem';
import { HotelDetail, MediaInfoMeta } from '~/schemes';
import {
  HInfiniteCarousel,
  HInfiniteCarouselItem,
  HVideoPlayer,
  HPhotogalleryBtn,
  HMediaOverlayCaption,
} from '~/components';

interface MyItemDefine {
  image?: string;
  video?: string;
  index: number;
  meta?: MediaInfoMeta;
}

interface MyItem extends HInfiniteCarouselItem {
  defines: MyItemDefine[];
}

export interface MyHotelPhotogalleryProps {
  hotel: HotelDetail;
  needPauseCarousel?: boolean;
}

export interface MyHotelPhotogalleryEmits {
  onRequestGallery: void;
  onClickPhotogallery: string;
}

export interface MyHotelPhotogalleryScopedSlots {}

@Component<MyHotelPhotogalleryRef>({
  name: 'MyHotelPhotogallery',
  mounted() {
    this.isMounted = true;
  },
  render() {
    const { carouselItems, needPauseCarousel, staticRows, isJapanese } = this;
    const photogalleryButton: VNode = (
      <HPhotogalleryBtn
        eventId="topgallery"
        staticClass="my-hotel-photogallery__btn"
      />
    );

    return (
      <div
        staticClass="my-hotel-photogallery"
        v-inview={
          this.inviewed
            ? false
            : {
                in: () => {
                  this.inviewed = true;
                },
              }
        }>
        {this.mqDetected && this.$mq.match.wide && (
          <div staticClass="my-hotel-photogallery__carousel__wrapper">
            <HInfiniteCarousel
              key="wide-carousel"
              staticClass="my-hotel-photogallery__carousel"
              items={carouselItems}
              paused={needPauseCarousel}
              onClickKey={this.imageClickHandler}
              scopedSlots={{
                item: ({ item: _item, carousel }) => {
                  const item = (_item as unknown) as MyItem;
                  return (
                    <div staticClass="my-hotel-photogallery__carousel__item">
                      {item.defines.map((define, index) => (
                        <div
                          key={index}
                          staticClass="my-hotel-photogallery__carousel__item__img"
                          class={`my-hotel-photogallery__carousel__item__img--${
                            index + 1
                          }`}
                          data-h-infinite-carousel-click={`${item.index}:${index}`}>
                          {item.images &&
                            item.images[index].meta &&
                            isJapanese && (
                              <HMediaOverlayCaption
                                staticClass="my-hotel-photogallery__carousel__item__img__caption"
                                modelValue={{
                                  caption: item.images[index].meta.caption,
                                  position: 'left',
                                }}
                              />
                            )}
                          <div
                            staticClass="my-hotel-photogallery__carousel__item__img__node"
                            style={
                              carousel.loaded && define.image
                                ? {
                                    backgroundImage: `url(${define.image})`,
                                  }
                                : undefined
                            }>
                            {!!define.video && this.inviewed && (
                              <HVideoPlayer
                                staticClass="my-hotel-photogallery__carousel__item__img__node__video"
                                src={define.video}
                                loop
                                muted
                                backgroundMode
                                fade
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                },
              }}
            />
            {photogalleryButton}
          </div>
        )}

        {this.mqDetected && this.$mq.match.narrow && staticRows && (
          <div staticClass="my-hotel-photogallery__static">
            {staticRows.map((defines, rowIndex) => (
              <div
                staticClass="my-hotel-photogallery__static__row"
                class={`my-hotel-photogallery__static__row--${rowIndex + 1}`}>
                {defines.map(({ image, video, index, meta }) => (
                  <div
                    key={index}
                    staticClass="my-hotel-photogallery__static__item"
                    class={`my-hotel-photogallery__static__item--${index + 1}`}
                    onClick={() => {
                      this.imageClickHandler(`0:${index}`);
                    }}>
                    <div
                      staticClass="my-hotel-photogallery__static__item__image"
                      style={
                        image
                          ? {
                              backgroundImage: `url(${image})`,
                            }
                          : undefined
                      }>
                      {meta && meta.caption && isJapanese && (
                        <HMediaOverlayCaption
                          modelValue={{
                            caption: meta.caption,
                          }}
                        />
                      )}
                    </div>

                    {!!video && (
                      <HVideoPlayer
                        staticClass="my-hotel-photogallery__static__item__video"
                        src={video}
                        loop
                        muted
                        backgroundMode
                        fade
                        lazy
                        playOnInview
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
            {photogalleryButton}
          </div>
        )}
      </div>
    );
  },
})
export default class MyHotelPhotogalleryRef
  extends Vue
  implements MyHotelPhotogalleryProps {
  @Prop({ type: Object, required: true }) readonly hotel!: HotelDetail;
  @Prop({ type: Boolean }) readonly needPauseCarousel!: boolean;

  protected isMounted: boolean = false;
  private inviewed: boolean = false;

  get galleryCount() {
    return this.hotel.galleries.length;
  }

  get mqDetected() {
    return this.isMounted && (this.$mq.match.wide || this.$mq.match.narrow);
  }

  get carouselItems(): HInfiniteCarouselItem[] {
    return this.hotel.photoGroups.map((group) => {
      const images: HotelFeatureItemImage[] = [];
      const defines: MyItemDefine[] = [];
      group.forEach((photo, index) => {
        const define: MyItemDefine = { index };
        const { url, videoUrl, meta } = photo;
        if (url) {
          const _url = this.$res.img(url);
          images.push({
            image: _url,
            meta,
          });
          define.image = _url;
          define.meta = meta;
        }
        if (videoUrl) {
          define.video = videoUrl;
        }
        defines.push(define);
      });

      return {
        images,
        defines,
      };
    });
  }

  get staticRows() {
    const firstItem = this.carouselItems[0];

    if (!firstItem) return;
    const { defines } = firstItem;
    const rows: MyItemDefine[][] = [
      [defines[0]],
      [defines[1], defines[2]],
      [defines[3], defines[4]],
      [defines[5]],
    ];
    if (rows.some((row) => row.some((define) => !define))) {
      return;
    }
    return rows;
  }

  get isJapanese(): boolean {
    return this.$language.current === 'ja';
  }

  protected imageClickHandler(target: string) {
    const tmp = target.split(':');
    const groupIndex = parseInt(tmp[0], 10);
    const photoIndex = parseInt(tmp[1], 10);
    const { photoGroups } = this.hotel;
    const group = photoGroups[groupIndex];
    const photo = group && group[photoIndex];

    if (!photo) {
      return undefined;
    }

    this.$emit('clickPhotogallery', photo.galleryId);
  }
}

export const TypedMyHotelPhotogallery = tsx
  .ofType<
    MyHotelPhotogalleryProps,
    MyHotelPhotogalleryEmits,
    MyHotelPhotogalleryScopedSlots
  >()
  .convert(MyHotelPhotogalleryRef);
