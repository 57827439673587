import './HListLayoutGalleryItem.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import type { HListLayoutGalleryRef } from './HListLayoutGallery';

export interface HListLayoutGalleryItemProps {
  /** 画像のURL */
  images?: string | string[];
  /** 画像のアスペクト比。現状Listは4:3のみ */
  aspectRatio?: string;
}

export interface HListLayoutGalleryItemEmits {}

export interface HListLayoutGalleryItemScopedSlots {}

@Component<HListLayoutGalleryItemRef>({
  name: 'HListLayoutGalleryItem',
  inject: ['gallery'],
  render() {
    return (
      <div style={this.styles} staticClass="h-list-layout-gallery-item">
        <div staticClass="h-list-layout-gallery-item__node" />
      </div>
    );
  },
})
export class HListLayoutGalleryItemRef
  extends Vue
  implements HListLayoutGalleryItemProps {
  readonly gallery!: HListLayoutGalleryRef;
  @Prop({ type: String, required: true }) readonly images!: string;
  @Prop(String) readonly aspectRatio?: string;

  get styles() {
    const { images, aspectRatio } = this;
    return {
      '--image': `url(${images})`,
      '--aspect-ratio': aspectRatio,
    };
  }
}

/** 画像の登録数に応じてレイアウトが自動的に変わるギャラリーコンポーネントのアイテム部分 */
export const HListLayoutGalleryItem = tsx
  .ofType<
    HListLayoutGalleryItemProps,
    HListLayoutGalleryItemEmits,
    HListLayoutGalleryItemScopedSlots
  >()
  .convert(HListLayoutGalleryItemRef);
