import Vue from 'vue';
import { HotelBrandDetail } from '../schemes/brand';
import { HotelDetail } from '~/schemes/hotel';
import { AvailableLanguage } from '~/schemes';

interface Options {
  brand?: HotelBrandDetail;
  hotelSource?: HotelDetail;
  availableLanguages?: AvailableLanguage[];
}

/**
 * ページのローカライズ版についてGoogleに知らせるhreflangの設定
 * @memo https://developers.google.com/search/docs/specialty/international/localized-versions?hl=ja
 */
export const generateHreflang = (vm: Vue, options?: Options) => {
  const { brand, hotelSource, availableLanguages } = options || {};
  const langMap = vm.$language.currentInformation;

  // 特集ページの時
  if (availableLanguages) {
    const hreflangLinkTags = langMap
      .filter((i) => availableLanguages.includes(i.id))
      .map((i) => {
        const availableLangLinkTag = {
          hid: `hreflang:${i.id}`,
          rel: 'alternate',
          hreflang: i.bcp47,
          href: generateHref(vm, i.id),
        };

        return availableLangLinkTag;
      });

    // 英語が存在する時、x-defaultも設定
    if (availableLanguages.includes('en')) {
      hreflangLinkTags.push({
        hid: 'hreflang:default',
        rel: 'alternate',
        hreflang: 'x-default',
        href: generateHref(vm, 'en'), // 追加するオブジェクトの設定
      });
    }

    return hreflangLinkTags;
  }

  // 特集ページ以外は存在しない言語の場合はリダイレクト先が決まっているのでfilterはしない
  return langMap
    .map((i) => {
      return {
        hid: `hreflang:${i.id}`,
        rel: 'alternate',
        hreflang: i.bcp47,
        href: generateHref(vm, i.id, {
          hotelSource,
          brand,
        }),
      };
    })
    .concat({
      hid: 'hreflang:default',
      rel: 'alternate',
      hreflang: 'x-default',
      href: generateHref(vm, 'en', { hotelSource, brand }),
    });
};

/**
 * 言語に合わせたURLの生成
 * @return 現在のサイトのURL
 */
export const generateHref = (
  vm: Vue,
  lang: AvailableLanguage,
  options?: Options,
) => {
  const { brand, hotelSource } = options || {};

  // 言語の取得のための正規表現
  const regex = /^\/[a-z]{2}(_[a-z]{2})?\//;
  const origin = vm.$navigation.origin;
  // 表示されているページのURL
  const currentUrl = `${origin}/${lang}/${vm.$route.fullPath.replace(
    regex,
    '',
  )}`;

  // ブランドサイトの場合
  if (brand) {
    return brand.availableLanguageUrl !== undefined &&
      brand.availableLanguageUrl[lang]
      ? brand.availableLanguageUrl[lang]
      : currentUrl;
  }

  // 施設サイトの場合
  if (hotelSource) {
    const availableLanguage = hotelSource.availableLanguages.find(
      (i) => i.id === lang,
    );
    return availableLanguage?.external || currentUrl;
  }

  // 特集ページ
  return currentUrl;
};
