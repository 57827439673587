import { EasingFunction } from 'bezier-easing';
import type { EasingName, EasingValues } from './easings';
import type { Transition } from './transition';

export type { EasingFunction } from 'bezier-easing';
export type { EasingName, EasingValues } from './easings';

/**
 * イージング設定
 */
export type EasingSource = EasingName | EasingValues | EasingFunction;

/**
 * デフォルトトランジションイージング
 */
export const DEFAULT_EASING: EasingSource = 'ease';

/**
 * デフォルトトランジション時間（ms）
 */
export const DEFAULT_DURATION = 500;

/**
 * トランジションオプション
 */
export interface TransitionOptions {
  /**
   * イージング設定
   *
   * @default DEFAULT_EASING
   */
  easing?: EasingSource;

  /**
   * トランジション時間（ms）
   *
   * @default DEFAULT_DURATION
   */
  duration?: number;
}

/**
 * トランジション設定
 */
export interface TransitionSettings {
  /** イージング関数 */
  easing: EasingFunction;

  /** トランジション時間（ms） */
  duration: number;
}

/**
 * トランジション状態
 *
 * - "idling" アイドリング中
 * - "running" [遷移中] トランジションが開始されたことを意味します。一時停止または停止できます。
 * - "paused" [一時停止中] トランジションが一時停止状態であることを意味します。再開できます。
 * - "aborted" [破棄済み] トランジションが破棄されたことを意味します。これ以降、開始も再開もできません。
 */
export type TransitionState = 'idling' | 'running' | 'paused' | 'aborted';

/**
 * トランジション状態更新時のハンドラ
 */
export type TransitionChangeHandler = (transition: Transition) => any;

/**
 * トランジションクラスの設定
 */
export interface TransitionConfig extends TransitionOptions {
  /** トランジション状態更新時のハンドラ */
  onChange?: TransitionChangeHandler;
}
