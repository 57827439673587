// import ResizeObserver from 'resize-observer-polyfill';
import visibilityManager from '@dadajam4/visibility';

export type SnowCanvasFlakeSize = number | ((canvas: SnowCanvas) => number);
export interface SnowCanvasSettings {
  el?: HTMLCanvasElement;

  /**
   * 100x100の矩形内に何個の結晶が存在するか
   */
  numOfHandred?: number;

  /**
   * 目標FPS
   */
  targetFPS?: number;

  flakeSize?: SnowCanvasFlakeSize;

  minAlpha?: number;
  maxAlpha?: number;
}

// const FPS_60 = 1000 / 60;
const RESIZE_DEBOUNCE = 250;
const DEFAULT_FLEAK_SIZE: SnowCanvasFlakeSize = (canvas) => {
  const { height } = canvas;
  const minSize = height * 0.0025;
  const maxSize = height * 0.00625;
  const size = Math.round(Math.random() * (maxSize - minSize) + minSize);
  return size < 1 ? 1 : size;
};

export default class SnowCanvas {
  el: HTMLCanvasElement | null = null;
  parent: HTMLElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;
  active = false;
  paused = false;
  readonly flakes: SnowFlake[] = [];
  private resizeObserver: ResizeObserver | null = null;
  width: number = 0;
  height: number = 0;
  numOfHandred: number = 0.25;
  maxCount: number = 0;
  beforeTime: number = 0;
  progressTime: number = 0;
  targetFPS: number = 40;
  computedTargetFPSDuration: number = 0;
  visibilityPaused: boolean = false;
  isVisible: boolean = visibilityManager.isVisible;
  flakeSize: SnowCanvasFlakeSize = DEFAULT_FLEAK_SIZE;
  private resizeDobounceId: number | null = null;
  private visibilityUnWatcher: Function | null = null;
  minAlpha: number = 0.3;
  maxAlpha: number = 0.6;

  get isPaused() {
    return this.paused || this.visibilityPaused;
  }

  constructor(settingOrCanvas: SnowCanvasSettings | HTMLCanvasElement = {}) {
    const settinng =
      settingOrCanvas instanceof HTMLCanvasElement
        ? { el: settingOrCanvas }
        : settingOrCanvas;

    const {
      el = document.createElement('canvas'),
      numOfHandred = this.numOfHandred,
      targetFPS = this.targetFPS,
      minAlpha = this.minAlpha,
      maxAlpha = this.maxAlpha,
      flakeSize = this.flakeSize,
    } = settinng;

    const ctx = el.getContext('2d');
    if (!ctx) throw new Error(`The context could not be created.`);

    this.el = el;
    el.style.display = 'block';
    this.ctx = ctx;
    this.numOfHandred = numOfHandred;
    this.targetFPS = targetFPS;
    this.computedTargetFPSDuration = 1000 / targetFPS;
    this.visibilityUnWatcher = visibilityManager.change(() => {
      this.isVisible = visibilityManager.isVisible;
      if (this.isVisible) {
        this.visibilityPaused = false;
        this.resetTimesAndTick();
      } else {
        this.visibilityPaused = true;
      }
    });
    this.flakeSize = flakeSize;
    this.minAlpha = minAlpha;
    this.maxAlpha = maxAlpha;
  }

  createFlakeSize(): number {
    let size = this.flakeSize;
    if (typeof size === 'function') size = size(this);
    return size;
  }

  private clearResizeDobounce() {
    if (this.resizeDobounceId !== null) {
      clearTimeout(this.resizeDobounceId);
      this.resizeDobounceId = null;
    }
  }

  private resizeHandler(rect: { width: number; height: number }) {
    this.clearResizeDobounce();
    this.resizeDobounceId = window.setTimeout(() => {
      this.clearResizeDobounce();
      const { el } = this;
      if (!el) return;
      const { width: beforeWidth, height: beforeHeight, numOfHandred } = this;
      const { width: newWidth, height: newHeight } = rect;
      this.width = newWidth;
      this.height = newHeight;
      this.maxCount = Math.floor(
        ((newWidth * newHeight) / 1000) * numOfHandred,
      );
      el.style.width = newWidth + 'px';
      el.style.height = newHeight + 'px';
      el.setAttribute('width', String(newWidth));
      el.setAttribute('height', String(newHeight));
      const addWidth = newWidth - beforeWidth;
      const addHeight = newHeight - beforeHeight;
      addHeight > 0 &&
        beforeWidth > 0 &&
        this.createFlakes(0, beforeHeight, beforeWidth, addHeight);
      addWidth > 0 && this.createFlakes(beforeWidth, 0, addWidth, newHeight);

      for (const flake of this.flakes) {
        flake.updateSize();
      }
      this.resetTimes();
    }, RESIZE_DEBOUNCE);
  }

  private createFlakes(x: number, y: number, width: number, height: number) {
    const { numOfHandred } = this;
    const area = width * height;
    const count = Math.min(
      (area / 1000) * numOfHandred,
      this.maxCount - this.flakes.length,
    );
    for (let i = 0; i < count; i++) {
      const flakeX = x + Math.floor(Math.random() * width);
      const flakeY = y + Math.floor(Math.random() * height);
      this.flakes.push(new SnowFlake(this, flakeX, flakeY));
    }
  }

  mount(parent: HTMLElement) {
    const { el } = this;
    if (el && this.parent !== parent) {
      this.detach();
      parent.style.margin = '0';
      parent.style.padding = '0';
      parent.style.display = 'block';
      this.parent = parent;
      this.resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          this.resizeHandler(entry.contentRect);
        }
      });
      this.resizeObserver.observe(parent);
      this.resizeHandler({
        width: parent.offsetWidth,
        height: parent.offsetHeight,
      });
      parent.appendChild(el);
    }
  }

  detach() {
    this.stop();
    const { parent, el } = this;
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }

    if (parent && el) {
      parent.removeChild(el);
      this.parent = null;
    }
  }

  destroy() {
    this.detach();
    this.el = null;
    this.ctx = null;
    this.visibilityUnWatcher && this.visibilityUnWatcher();
    this.visibilityUnWatcher = null;
  }

  clear() {
    const { ctx } = this;
    ctx && ctx.clearRect(0, 0, this.width, this.height);
  }

  pause() {
    this.paused = true;
  }

  resume() {
    this.paused = false;
    this.resetTimesAndTick();
  }

  run() {
    if (!this.active) {
      this.active = true;
      this.resetTimesAndTick();
    }
  }

  private resetTimes() {
    this.beforeTime = performance.now();
    this.progressTime = 0;
  }

  private resetTimesAndTick() {
    this.resetTimes();
    this.tick();
  }

  stop() {
    this.active = false;
    this.paused = false;
  }

  tick() {
    if (!this.active || this.isPaused) return;
    const currentTime = performance.now();
    const tickTime = currentTime - this.beforeTime;
    this.progressTime += tickTime;

    const { progressTime, computedTargetFPSDuration } = this;

    if (progressTime >= computedTargetFPSDuration) {
      this.clear();
      // const fpsCount = Math.floor(progressTime / FPS_60);
      this.progressTime -= computedTargetFPSDuration;

      const { flakes } = this;
      for (let i = 0, l = flakes.length; i < l; i++) {
        const flake = flakes[i];
        let removed: boolean | undefined = false;
        if (flake) {
          removed = flake.tick();
          // if (fpsCount === 1) {
          //   removed = flake.tick();
          // } else {
          //   for (let j = 0; j < fpsCount; j++) {
          //     removed = flake.tick();
          //     if (removed) break;
          //   }
          // }
          !removed && this.drawFlake(flake);
        }
      }
    }
    this.beforeTime = currentTime;
    requestAnimationFrame(() => {
      this.tick();
    });
  }

  removeFlake(flake: SnowFlake) {
    const index = this.flakes.indexOf(flake);
    if (index !== -1) {
      this.flakes.splice(index, 1);
    }
  }

  drawFlake(flake: SnowFlake) {
    const { ctx } = this;
    if (!ctx) {
      return;
    }

    ctx.fillStyle = 'rgba(255,255,255,' + flake.opacity + ')';

    ctx.beginPath();
    ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2);
    ctx.fill();
  }
}

export class SnowFlake {
  readonly canvas: SnowCanvas;
  x: number = 0;
  y: number = 0;
  size: number = 0;
  private speed: number = 0;
  private velY: number = 0;
  private velX: number = 0;
  readonly stepSize: number = Math.random() / 30;
  private step: number = 0;
  opacity: number = 0;
  // opacity: number = Math.random() * 0.5 + 0.3;

  constructor(canvas: SnowCanvas, x: number, y: number) {
    this.canvas = canvas;
    this.x = x;
    this.reset(y);
  }

  reset(y: number = 0) {
    const { width: canvasWidth, minAlpha, maxAlpha } = this.canvas;

    this.x = Math.floor(Math.random() * canvasWidth);
    this.y = y;

    this.speed = Math.random() * 1 + 0.5;
    this.velY = this.speed;
    this.velX = 0;
    this.opacity = Math.random() * (maxAlpha - minAlpha) + minAlpha;

    this.updateSize();
  }

  updateSize() {
    this.size = this.canvas.createFlakeSize();
  }

  remove() {
    this.canvas.removeFlake(this);
  }

  tick() {
    this.velX *= 0.98;
    if (this.velY <= this.speed) {
      this.velY = this.speed;
    }
    this.velX += Math.cos((this.step += 0.05)) * this.stepSize;

    this.y += this.velY;
    this.x += this.velX;

    if (
      this.y >= this.canvas.height ||
      this.y <= 0 ||
      this.x >= this.canvas.width ||
      this.x <= 0
    ) {
      if (this.canvas.flakes.length <= this.canvas.maxCount) {
        this.reset();
      } else {
        this.remove();
        return true;
      }
    }
  }
}
