import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<HLayoutMixin>({
  head() {
    const langInfo = this.$language.info;
    const { lowerBrowserName } = this.$ua;
    const { activatedFonts } = this.$ui;
    const htmlAttrs: MetaInfo['htmlAttrs'] = {
      lang: langInfo.attr,
      class:
        `h-theme--${this.theme.name} ${this.htmlClass || ''}`.trim() ||
        <any>undefined,
      'data-gf-build': this.$env.buildTime,
    };

    activatedFonts.forEach((font) => {
      htmlAttrs[`data-wf-${font}-active`] = '1';
    });

    if (lowerBrowserName) {
      htmlAttrs['data-ua-browser'] = lowerBrowserName;
    }

    const link: MetaInfo['link'] = [];
    const meta: MetaInfo['meta'] = [
      {
        hid: 'viewport',
        name: 'viewport',
        content: this.viewportSetting,
      },
      { hid: 'og:type', property: 'og:type', content: 'website' },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      },
    ];

    const title = this.$t('name.site') as string;
    const favicon = `/common/favicon.ico`;

    link.push({
      hid: 'favicon',
      rel: 'icon',
      type: 'image/x-icon',
      href: this.$res.img(favicon),
    });

    return {
      titleTemplate: (titleChunk) => {
        if (!titleChunk) {
          return title;
        }
        return `${titleChunk} | ${title}`;
      },
      meta,
      link,
      htmlAttrs,
    };
  },
  mounted() {
    this.isMounted = true;
  },
})
export class HLayoutMixin extends Vue {
  /**
   * レイアウトコンポーネントがDOMツリーにマウント済みか
   */
  isMounted: boolean = false;

  get theme() {
    return this.$theme.current;
  }

  get viewportSetting(): string {
    return this.$device.isTablet
      ? 'width=1024, viewport-fit=cover'
      : 'width=device-width, initial-scale=1, viewport-fit=cover';
  }

  htmlClass: string = '';
}
