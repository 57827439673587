import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { State as RootState } from './';

export interface HeaderHeightAppend {
  key: string;
  height: number;
}

export interface State {
  drawerIsActive: boolean;
  currentSubDrawer: string | null;
  internalHeaderHeight: number;
  portalFooterHeight: number;
  headerHeightAppends: { key: string; height: number }[];
  activatedFonts: string[];
  inActivatedFonts: string[];
  hasNotification: boolean;
}

export const state = (): State => ({
  drawerIsActive: false,
  currentSubDrawer: null,
  internalHeaderHeight: 0,
  portalFooterHeight: 0,
  headerHeightAppends: [],
  activatedFonts: [],
  inActivatedFonts: [],
  hasNotification: false,
});

export const getters: GetterTree<State, RootState> = {
  totalHeaderHeightAppends(state) {
    let total = 0;
    state.headerHeightAppends.forEach(({ height }) => {
      total += height;
    });
    return total;
  },

  /**
   * ヘッダーのみの高さ（お知らせ含む）
   */
  originalHeaderHeight(state) {
    return state.internalHeaderHeight;
  },

  /**
   * ヘッダー（お知らせ含む）＋ヘッダー追加要素の高さ
   */
  headerHeight(state, getters) {
    return getters.originalHeaderHeight + getters.totalHeaderHeightAppends;
  },

  /**
   * 総合ヘッダ＋ポータルフッタの高さ
   * ※なんか名前おかしい
   */
  totalHeaderHeight(state, getters) {
    return getters.headerHeight + state.portalFooterHeight;
  },
};

export const actions: ActionTree<State, RootState> = {
  // eslint-disable-next-line require-await
  async showDrawer({ commit }) {
    commit('SET_DRAWER_IS_ACTIVE', true);
  },

  // eslint-disable-next-line require-await
  async closeDrawer({ commit }) {
    commit('SET_DRAWER_IS_ACTIVE', false);
  },

  // eslint-disable-next-line require-await
  async closeSubDrawer({ commit }) {
    commit('SET_CURRENT_SUB_DRAWER', null);
  },

  softCloseDrawer({ state, dispatch }) {
    if (state.currentSubDrawer) {
      return dispatch('closeSubDrawer');
    } else {
      return dispatch('closeDrawer');
    }
  },

  // eslint-disable-next-line require-await
  async setDrawerIsActive({ commit }, drawerIsActive: boolean) {
    commit('SET_DRAWER_IS_ACTIVE', drawerIsActive);
  },

  toggleDrawer({ state, dispatch }) {
    return state.drawerIsActive
      ? dispatch('closeDrawer')
      : dispatch('showDrawer');
  },

  softToggleDrawer({ state, dispatch }) {
    return state.drawerIsActive
      ? dispatch('softCloseDrawer')
      : dispatch('showDrawer');
  },

  setCurrentSubDrawer({ state, commit }, currentSubDrawer: string | null) {
    commit('SET_CURRENT_SUB_DRAWER', currentSubDrawer);
  },
};

export const mutations: MutationTree<State> = {
  SET_DRAWER_IS_ACTIVE(state, drawerIsActive: boolean) {
    state.drawerIsActive = drawerIsActive;
    if (!drawerIsActive) {
      state.currentSubDrawer = null;
    }
  },

  SET_CURRENT_SUB_DRAWER(state, currentSubDrawer: string | null) {
    state.currentSubDrawer = currentSubDrawer;
  },

  SET_HEADER_HEIGHT(state, headerHeight: number) {
    state.internalHeaderHeight = headerHeight;
  },

  SET_PORTAL_FOOTER_HEIGHT(state, portalFooterHeight: number) {
    state.portalFooterHeight = portalFooterHeight;
  },

  SET_HEADER_HEIGHT_APPENDS(state, append: HeaderHeightAppend) {
    state.headerHeightAppends.push(append);
  },

  REMOVE_HEADER_HEIGHT_APPENDS(state, key: string) {
    const index = state.headerHeightAppends.findIndex((a) => a.key === key);
    if (index !== -1) {
      state.headerHeightAppends.splice(index, 1);
    }
  },
  PUSH_ACTIVATED_FONT(state, font: string) {
    if (!state.activatedFonts.includes(font)) {
      state.activatedFonts.push(font);
    }
  },
  SET_HAS_NOTIFICATION(state, hasNotification: boolean) {
    state.hasNotification = hasNotification;
  },
};
