import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a23425b = () => interopDefault(import('../src/pages/gf-hooks/_hook.ts' /* webpackChunkName: "pages/gf-hooks/_hook" */))
const _9211d910 = () => interopDefault(import('../src/pages/index.ts' /* webpackChunkName: "pages/index" */))
const _39b76b9d = () => interopDefault(import('../src/pages/_lang.ts' /* webpackChunkName: "pages/_lang" */))
const _7e51d80f = () => interopDefault(import('../src/pages/_lang/hr-tools.vue' /* webpackChunkName: "pages/_lang/hr-tools" */))
const _3bfc8602 = () => interopDefault(import('../src/pages/_lang/hr-tools/cache.vue' /* webpackChunkName: "pages/_lang/hr-tools/cache" */))
const _795b7b57 = () => interopDefault(import('../src/pages/_lang/sp/index.vue' /* webpackChunkName: "pages/_lang/sp/index" */))
const _afa5528e = () => interopDefault(import('../src/pages/_lang/brands/_brand_slug/index.vue' /* webpackChunkName: "pages/_lang/brands/_brand_slug/index" */))
const _8efba308 = () => interopDefault(import('../src/pages/_lang/brands/_brand_slug/index/index.vue' /* webpackChunkName: "pages/_lang/brands/_brand_slug/index/index" */))
const _89f73476 = () => interopDefault(import('../src/views/sp/_sp_slug.vue' /* webpackChunkName: "views/sp/brands" */))
const _5e5defc2 = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index" */))
const _44ce893c = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/index.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/index" */))
const _2ff5e0f2 = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/access/index.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/access/index" */))
const _23a50a60 = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/activities/index.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/activities/index" */))
const _740ef79a = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/contact/index.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/contact/index" */))
const _157b62c8 = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/dining/index.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/dining/index" */))
const _7d8261a6 = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/roomsearch/index.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/roomsearch/index" */))
const _59be4dbc = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/todo/index.ts' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/todo/index" */))
const _e78e3804 = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/activities/_activity_id.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/activities/_activity_id" */))
const _0d01d9f8 = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/dining/_restaurant_id.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/dining/_restaurant_id" */))
const _23eb3d04 = () => interopDefault(import('../src/pages/_lang/hotels/_hotel_slug/index/todo/_todo_slug.vue' /* webpackChunkName: "pages/_lang/hotels/_hotel_slug/index/todo/_todo_slug" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/gf-hooks/:hook?",
    component: _2a23425b,
    name: "gf-hooks-hook"
  }, {
    path: "/",
    component: _9211d910,
    name: "index"
  }, {
    path: "/:lang",
    component: _39b76b9d,
    name: "lang",
    children: [{
      path: "hr-tools",
      component: _7e51d80f,
      name: "lang-hr-tools",
      children: [{
        path: "cache",
        component: _3bfc8602,
        name: "lang-hr-tools-cache"
      }]
    }, {
      path: "sp",
      component: _795b7b57,
      name: "lang-sp"
    }, {
      path: "brands/:brand_slug",
      component: _afa5528e,
      children: [{
        path: "",
        component: _8efba308,
        name: "lang-brands-brand_slug-index"
      }, {
        path: "sp/*",
        component: _89f73476,
        name: "lang-brands-brand_slug-index-sp-sp_slug"
      }]
    }, {
      path: "hotels/:hotel_slug",
      component: _5e5defc2,
      children: [{
        path: "",
        component: _44ce893c,
        name: "lang-hotels-hotel_slug-index"
      }, {
        path: "access",
        component: _2ff5e0f2,
        name: "lang-hotels-hotel_slug-index-access"
      }, {
        path: "activities",
        component: _23a50a60,
        name: "lang-hotels-hotel_slug-index-activities"
      }, {
        path: "contact",
        component: _740ef79a,
        name: "lang-hotels-hotel_slug-index-contact"
      }, {
        path: "dining",
        component: _157b62c8,
        name: "lang-hotels-hotel_slug-index-dining"
      }, {
        path: "roomsearch",
        component: _7d8261a6,
        name: "lang-hotels-hotel_slug-index-roomsearch"
      }, {
        path: "todo",
        component: _59be4dbc,
        name: "lang-hotels-hotel_slug-index-todo"
      }, {
        path: "activities/:activity_id?",
        component: _e78e3804,
        name: "lang-hotels-hotel_slug-index-activities-activity_id"
      }, {
        path: "dining/:restaurant_id?",
        component: _0d01d9f8,
        name: "lang-hotels-hotel_slug-index-dining-restaurant_id"
      }, {
        path: "todo/:todo_slug?",
        component: _23eb3d04,
        name: "lang-hotels-hotel_slug-index-todo-todo_slug"
      }, {
        path: "sp/*",
        component: _89f73476,
        name: "lang-hotels-hotel_slug-index-sp-sp_slug"
      }]
    }, {
      path: "sp/*",
      component: _89f73476,
      name: "lang-sp-sp_slug"
    }]
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
