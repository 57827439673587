/* eslint-disable no-undef */
import {
  DirectiveFunction,
  DirectiveOptions,
  VNode,
  VNodeDirective,
} from 'vue/types';
import { wysiwygFormat, ROUTABLE_ATTR } from '../helpers/wysiwyg';

export const WysiwygElementSymbol = '__wysiwyg_ctx__';

export class WysiwygContext {
  el?: HTMLElement;
  vnode?: VNode;
  lastValue?: string | null;
  computedValue?: string;
  routables: HTMLAnchorElement[] = [];

  constructor(el: HTMLElement, vnode?: VNode, value?: string | null) {
    this.el = el;
    this.vnode = vnode;
    this.handleClickRoutableLink = this.handleClickRoutableLink.bind(this);
    this.update(value);
  }

  private getParent() {
    return this.vnode && this.vnode.context;
  }

  private destroyRoutables() {
    this.routables.forEach((link) => {
      link.removeEventListener('click', this.handleClickRoutableLink, false);
    });
    this.routables = [];
  }

  private handleClickRoutableLink(ev: MouseEvent) {
    const vm = this.getParent();
    if (!vm) {
      return;
    }
    const link = ev.target as HTMLAnchorElement;
    const to = link.getAttribute(ROUTABLE_ATTR);
    if (!to) {
      return;
    }

    const mailto = /^mailto:/;
    const tel = /^tel:/;

    // @comment preventDefaultでデフォルトの挙動が防がれているので、デフォルト機能使いたいものはreturn処理で回避する
    if (mailto.test(to) || tel.test(to)) {
      return;
    }
    if (link.getAttribute('target') === '_blank') {
      return;
    }

    ev.preventDefault();
    const hash = to.split('#')[1];
    if (hash && vm.$navigation.tryScrollToHash(hash)) {
      return;
    }
    vm.$router.push({
      path: to,
    });
  }

  update(value?: string | null) {
    if (this.lastValue === value || !this.el) return;
    this.lastValue = value;
    this.destroyRoutables();
    const computedValue = wysiwygFormat(
      value,
      this.vnode ? this.vnode.context : undefined,
    );
    if (this.computedValue !== computedValue) {
      this.computedValue = computedValue;
      this.el.innerHTML = `<div class="h-wysiwyg-content">${computedValue}</div>`;
    }
    const routables = Array.from(
      this.el.querySelectorAll<HTMLAnchorElement>(`a[${ROUTABLE_ATTR}]`),
    );
    routables.forEach((link) => {
      link.addEventListener('click', this.handleClickRoutableLink, false);
    });

    // テーマエフェクトを反映しておく
    const vm = this.getParent();
    vm && vm.$theme.applyEffect(this.el);

    this.routables = routables;
  }

  destroy() {
    const vm = this.getParent();
    this.destroyRoutables();
    if (this.el) {
      const { el } = this;
      vm && vm.$theme.removeEffect(el);
      delete this.el;
      delete el.__wysiwyg_ctx__;
    }
    if (this.vnode) {
      delete this.vnode;
    }
  }
}

declare global {
  interface Element {
    __wysiwyg_ctx__?: WysiwygContext;
  }
}

const bind: DirectiveFunction = function bind(el, binding, vnode) {
  if (!el.__wysiwyg_ctx__) {
    el.__wysiwyg_ctx__ = new WysiwygContext(el, vnode, binding.value);
  } else {
    const { value, oldValue } = binding;
    if (value !== oldValue) {
      el.__wysiwyg_ctx__.update(value);
    }
  }
};

const unbind: DirectiveFunction = function unbind(el) {
  if (el.__wysiwyg_ctx__) {
    el.__wysiwyg_ctx__.destroy();
  }
};

export function wysiwygServerRenderer(vnode: VNode, dir: VNodeDirective) {
  const computedValue = wysiwygFormat(dir.value, vnode.context);
  vnode.data = vnode.data || {};
  vnode.data.domProps = vnode.data.domProps || {};
  vnode.data.domProps.innerHTML = `<div class="h-wysiwyg-content">${computedValue}</div>`;
}

export default {
  name: 'wysiwyg',
  bind,
  componentUpdated: bind,
  unbind,
} as DirectiveOptions;
