import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import HotelViewRoot from '../../../-index';
import {
  MyHotelFeature,
  MyHotelGuestroom,
  MyHotelLocation,
  MyHotelPhotogallery,
  MyHotelDetail,
  MyHotelAccess,
  MyHotelFlowLine,
} from '../../-components';
import { MyHotelSectionHeader } from './MyHotelSectionHeader';

export interface MyHotelSectionPortal {
  target: string;
  html: string;
  className?: string;
}

export interface MyHotelSectionData {
  id: string;
  label: string | null;
  headRotate: number;
  fullWidth: boolean;
  plain: boolean;
  /** 界の表示テーマでh2見出しを自動で左右交互に表示するために仕方なく追加 */
  evenSection?: boolean;
  portal?: MyHotelSectionPortal;
  // appends?: string;
}

export interface MyHotelSectionProps {
  data: MyHotelSectionData;
}

export interface MyHotelSectionEmits {}

export interface MyHotelSectionScopedSlots {}

const SECTION_ID = [
  'default',
  'first-view',
  'feature',
  'guestroom',
  'hotelDetail',
] as const;

export type sectionId = typeof SECTION_ID[number];

@Component<MyHotelSectionRef>({
  name: 'MyHotelSection',
  inject: ['hotelViewRoot'],
  mounted() {
    if (this.isHoshinoya) {
      if (process.client) gsap.registerPlugin(ScrollTrigger);

      ScrollTrigger.create({
        trigger: '#feature',
        start: 'top-=20% center',
        end: 'bottom center',
        onEnter: () => {
          this.hotelViewRoot.pageTopScrollActiveSectionId = 'feature';
        },
      });
    }
  },
  beforeDestroy() {
    if (this.isHoshinoya) ScrollTrigger.killAll();
  },
  render() {
    const {
      hotel,
      data,
      id,
      plain,
      needPauseAnimations,
      isHoshinoyaFeature,
    } = this;

    return (
      <section
        id={id}
        key={`${hotel.id}-${id}`}
        staticClass="my-hotel-section"
        class={this.classes}>
        {id !== 'feature' && this.$theme.current.name === 'hoshinoya' && (
          <div staticClass="my-hotel-section__logo">
            <img
              staticClass="my-hotel-section__logo__img"
              src={this.$res.img(`hotels/${hotel.slug}/section-logo-white.svg`)}
            />
          </div>
        )}
        {(() => {
          // 星のやのみ3つの特徴のタイトルを表示しない
          if (!plain && !isHoshinoyaFeature) {
            return (
              <MyHotelSectionHeader
                staticClass="my-hotel-section__header"
                data={data}
                inviewed={this.inviewed}
              />
            );
          }
        })()}
        <div staticClass="my-hotel-section__body">
          <div staticClass="my-hotel-section__body__inner">
            {(() => {
              if (id === 'feature') {
                return (
                  <MyHotelFeature
                    hotel={hotel}
                    needPauseCarousel={needPauseAnimations}
                    staticClass="my-hotel-section__body--feature"
                  />
                );
              }
              if (id === 'flow-line') {
                return (
                  <MyHotelFlowLine
                    hotel={hotel}
                    settings={this.hotelViewRoot.normalizedFlowLineSettings}
                  />
                );
              }
              if (id === 'guestroom') {
                return (
                  <MyHotelGuestroom
                    hotel={hotel}
                    staticClass="my-hotel-section__body--guestroom"
                  />
                );
              }
              if (id === 'location') {
                return <MyHotelLocation hotel={hotel} />;
              }
              if (id === 'photogallery') {
                return (
                  <MyHotelPhotogallery
                    hotel={hotel}
                    needPauseCarousel={needPauseAnimations}
                    onRequestGallery={this.hotelViewRoot.onRequestGallery}
                    onClickPhotogallery={this.hotelViewRoot.onRequestGalleryId}
                  />
                );
              }
              if (id === 'hoteldetail') {
                return <MyHotelDetail hotel={hotel} />;
              }
              if (id === 'access') {
                return <MyHotelAccess hotel={hotel} />;
              }
            })()}
          </div>
        </div>
      </section>
    );
  },
})
export default class MyHotelSectionRef
  extends Vue
  implements MyHotelSectionProps {
  readonly hotelViewRoot!: HotelViewRoot;

  @Prop({ type: Object, required: true }) readonly data!: MyHotelSectionData;

  private inviewed: boolean = false;

  get hotel() {
    return this.hotelViewRoot.hotel;
  }

  get needPauseAnimations() {
    return this.hotelViewRoot.needPauseAnimations;
  }

  get id() {
    return this.data.id;
  }

  get portal() {
    return this.data.portal;
  }

  get headRotate() {
    return this.data.headRotate;
  }

  get fullWidth() {
    return this.data.fullWidth;
  }

  get plain() {
    return this.data.plain;
  }

  get label() {
    return this.data.label;
  }

  get headerHeight() {
    return this.$ui.headerHeight;
  }

  get stickyHeadStyles() {
    return {
      top: this.headerHeight + 40 + 'px',
    };
  }

  get evenSection() {
    return this.data.evenSection;
  }

  get photoGalleryHeadStyles() {
    return {
      top: this.headerHeight - 1 + 'px',
    };
  }

  get isHoshinoya() {
    return this.$theme.current.name === 'hoshinoya';
  }

  get isHoshinoyaFeature() {
    return this.id === 'feature' && this.isHoshinoya;
  }

  get classes() {
    const { id, fullWidth, plain, inviewed, evenSection } = this;
    return {
      [`my-hotel-section--${id}`]: true,
      'my-hotel-section--full-width': fullWidth,
      'my-hotel-section--plain': plain,
      'my-hotel-section--inviewed': inviewed,
      'my-hotel-section--even': evenSection,
    };
  }
}

export const TypedMyHotelSection = tsx
  .ofType<MyHotelSectionProps, MyHotelSectionEmits, MyHotelSectionScopedSlots>()
  .convert(MyHotelSectionRef);
