import { Plugin } from '@nuxt/types';
// import _ResizeObserver from 'resize-observer-polyfill';

import webFontPlugin from './webfont';

import './vue-awesome-swiper';

// const hoge: ResizeObserver =
// declare global {
//   // eslint-disable-next-line no-redeclare, no-undef
//   export const ResizeObserver: typeof _ResizeObserver;
// }

// if (typeof window !== 'undefined' && !(window as any).ResizeObserver) {
//   (window as any).ResizeObserver = _ResizeObserver;
// }

// eslint-disable-next-line require-await
const plugin: Plugin = async (ctx, inject) => {
  webFontPlugin(ctx, inject);
};

export default plugin;
