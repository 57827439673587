import * as tsx from 'vue-tsx-support';

import { VNodeChildren } from 'vue';
import { Component, Mixins } from 'vue-property-decorator';
import {
  HFormGroup,
  HFormGroupProps,
  HFormGroupEmits,
  HFormGroupScopedSlots,
} from './HFormGroup';

export interface HFormProps extends HFormGroupProps {}

export interface HFormEmits extends HFormGroupEmits {
  onSubmit: Event;
}

export interface HFormScopedSlots extends HFormGroupScopedSlots {}

@Component<HFormRef>({
  name: 'HForm',
  render() {
    const children = this.genChildren();
    return (
      <form staticClass={this.staticClasses} onSubmit={this.submitHandler}>
        {children || this.$slots.default}
      </form>
    );
  },
})
export class HFormRef extends Mixins(HFormGroup) implements HFormGroupProps {
  protected staticClass?: string;

  get staticClasses() {
    const classes: string[] = ['h-form'];
    const { staticClass } = this;
    if (staticClass != null) classes.push(staticClass);
    return classes.join(' ');
  }

  protected genChildren(): VNodeChildren | void {}

  protected submitHandler(ev: Event) {
    ev.preventDefault();
    this.$emit('submit', ev);
  }
}

export const HForm = tsx
  .ofType<HFormProps, HFormEmits, HFormScopedSlots>()
  .convert(HFormRef);
