import type Vue from 'vue';
import type { ThemeService } from '../theme';

/**
 * テーマのエフェクト処理を行うメソッド
 *
 * * 第二引数にテーマサービスが渡されるので、特定のテーマでのみ処理を行いたい時などはサービスの状態を見て判別する
 */
export type ThemeEffectFn = (target: HTMLElement, service: ThemeService) => any;

/**
 * テーマエフェクト
 */
export interface ThemeEffect {
  /**
   * 指定の要素にテーマのエフェクト処理を反映する
   *
   * @see ThemeEffectFn
   */
  apply: ThemeEffectFn;

  /**
   * 指定の要素からテーマのエフェクト処理を解放する
   *
   * * 解放処理等を行いたい時にだけ設定する
   *
   * @see ThemeEffectFn
   */
  remove?: ThemeEffectFn;
}

/**
 * テーマのエフェクト設定オブジェクトか、反映するための単体メソッド
 */
export type RawThemeEffect = ThemeEffectFn | ThemeEffect;

/**
 * テーマエフェクトを定義する
 *
 * * typeセーフにエフェクトを生成するためのヘルパの役割です
 *
 * @param effect - テーマのエフェクト設定
 * @returns 指定したエフェクト設定をそのまま返却する
 */
export function defineThemeEffect<T extends RawThemeEffect>(effect: T): T {
  return effect;
}

/**
 * テーマのエフェクト設定オブジェクトか、反映するための単体メソッドをエフェクト設定オブジェクトに正規化する
 * @param rawEffect - テーマのエフェクト設定か、反映するための単体メソッド
 * @returns テーマのエフェクト設定オブジェクトか、反映するための単体メソッド
 */
export function resolveRawThemeEffect(rawEffect: RawThemeEffect): ThemeEffect {
  return typeof rawEffect === 'function' ? { apply: rawEffect } : rawEffect;
}

/**
 * テーマエフェクトを適用する対象
 */
export type ThemeEffectTarget = Element | Vue | string;

/**
 * テーマエフェクトを適用する対象をHTML要素に正規化する
 * @param target - テーマエフェクトを適用する対象
 * @returns 要素が見つかった場合HTML要素
 */
export function resolveThemeEffectTarget(
  target: ThemeEffectTarget,
): HTMLElement | null {
  let el: HTMLElement | null;
  if (target instanceof Element) {
    el = target as HTMLElement;
  } else if (typeof target === 'string') {
    el = document.querySelector(target);
  } else {
    el = target.$el as HTMLElement;
  }
  return el || null;
}

/** テーマエフェクトを反映済みかどうかのシンボル */
export const THEME_EFFECT_APPLYED_SYMBOL = '__teapd__';

/**
 * 指定の要素にテーマエフェクトを反映済みか
 * @param target - 検査したい要素
 * @returns 反映済みの場合true
 */
export function isThemeEffectApplyed(target: Element): boolean {
  return (target as any)[THEME_EFFECT_APPLYED_SYMBOL] === true;
}

/**
 * 指定の要素にテーマエフェクトの適用状況を設定する
 * @param target - 設定したい要素
 * @param applyed - 反映済みか
 */
export function setThemeEffectApplyed(target: Element, applyed: boolean) {
  if (applyed) {
    (target as any)[THEME_EFFECT_APPLYED_SYMBOL] = true;
  } else {
    delete (target as any)[THEME_EFFECT_APPLYED_SYMBOL];
  }
}
