const DEFAULT_STATUS = typeof window !== 'undefined' ? 600 : 500;

/**
 * HTTPステータスコードとメッセージの対応表
 *
 * ※ 600番以降はカスタム
 */
export const STATUS_ERROR_MESSAGES: {
  [status: number]: string;
} = {
  400: 'Bad Request',
  401: 'Unauthorized',
  // 402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  421: 'Misdirected Request',
  422: 'Validation Failed',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  511: 'Network Authentication Required',

  // Clients only...
  600: 'Error',
  601: 'Bad network',
};

/**
 * HTTPステータスコードに対応するエラーメッセージを取得する
 *
 * @param status - ステータスコード
 * @param defaultMessage - メッセージが見つからなかった時のデフォルト値
 * @returns
 */
export function resolveStatusMessage(
  status: number | undefined = DEFAULT_STATUS,
  defaultMessage = 'Error',
): {
  status: number;
  message: string;
} {
  const message = STATUS_ERROR_MESSAGES[status] || defaultMessage;
  return {
    status,
    message,
  };
}
