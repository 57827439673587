import { Component, Mixins, Model, Prop, Watch } from 'vue-property-decorator';
import {
  HFormValue,
  HFormValueProps,
  HFormValueEmits,
  HFormValueScopedSlots,
} from './HFormValue';

export interface HFormChoiceProps extends HFormValueProps {
  inputValue?: boolean;
  value?: any;
}

export interface HFormChoiceEmits extends HFormValueEmits {
  onInput: boolean;
}

export interface HFormChoiceScopedSlots extends HFormValueScopedSlots {}

@Component({
  name: 'HFormChoice',
})
export class HFormChoice
  extends Mixins(HFormValue)
  implements HFormChoiceProps {
  @Model('input', { type: Boolean }) readonly inputValue!: boolean;
  @Prop({ default: true }) readonly value!: any;

  private internalValue: boolean = this.inputValue;
  protected validateProp: string = 'choiced';

  get trueValue(): any {
    return this.value;
  }

  get falseValue(): any {
    const { trueValue } = this;
    return trueValue === true ? false : undefined;
  }

  get choiced() {
    return this.internalValue;
  }

  set choiced(choiced) {
    this.setInternalValue(choiced, true);
  }

  toggle() {
    this.choiced = !this.choiced;
  }

  protected _getValue() {
    return this.choiced ? this.trueValue : this.falseValue;
  }

  private setInternalValue(internalValue: boolean, withEmit: boolean = false) {
    if (this.internalValue !== internalValue) {
      this.internalValue = internalValue;
      withEmit && this.$emit('input', internalValue);
      this.touched = true;
    }
  }

  @Watch('inputValue', { immediate: true })
  protected onChangeInputValueHandler(inputValue: boolean) {
    this.setInternalValue(inputValue);
  }
}
