import { Context, Plugin } from '@nuxt/types';
import { Vue, Component } from 'vue-property-decorator';
import { VPortalItemRef } from './components';

declare module 'vue/types/vue' {
  export interface Vue {
    $portal: PortalService;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $portal: PortalService;
  }
}

@Component({
  name: 'PortalService',
})
export class PortalService extends Vue {
  private incrementForId: number = 0;

  readonly items: VPortalItemRef[] = [];

  private newId(): number {
    this.incrementForId = this.incrementForId + 1;
    return this.incrementForId;
  }

  pushItem(vm: VPortalItemRef) {
    if (vm.portalItemId === null) {
      vm.portalItemId = this.newId();
    }
    if (this.items.find((item) => item.portalItemId === vm.portalItemId)) {
      return;
    }
    this.items.push(vm);
  }

  removeItem(vm: VPortalItemRef) {
    const item = this.items.find(
      (item) => item.portalItemId === vm.portalItemId,
    );
    if (!item) {
      return;
    }
    const index = this.items.indexOf(item);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }
}

const plugin: Plugin = (context, inject) => {
  const service = new PortalService();
  context.$portal = service;
  inject('portal', service);
};

export default plugin;
