import './HCarousel.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

export interface HCarouselProps {
  items: any[];
}

export interface HCarouselEmits {}

export interface HCarouselScopedSlots {
  item: { item: any; carousel: HCarouselRef };
}

@Component<HCarouselRef>({
  name: 'HCarousel',

  render() {
    const { $scopedSlots, items } = this;
    const { item: itemSlot } = $scopedSlots;
    if (!itemSlot) {
      throw new Error(`missing item slot`);
    }

    return (
      <div staticClass="h-carousel">
        <div
          staticClass="h-carousel__swiper swiper-container"
          ref="mySwiper"
          {...{
            directives: [
              {
                name: 'swiper',
                modifiers: {
                  mySwiper: true,
                },
                value: this.swiperOptions,
              },
            ],
          }}>
          <div staticClass="h-carousel__swiper-wrapper swiper-wrapper">
            {items.map((item) => (
              <div staticClass="h-carousel__swiper-slide swiper-slide">
                {itemSlot({ item, carousel: this })}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
})
export class HCarouselRef extends Vue implements HCarouselProps {
  $refs!: {
    mySwiper: any;
  };

  @Prop({ type: Array, required: true }) readonly items!: any[];

  get swiperOptions() {
    return {
      autoplay: {
        delay: 0,
        // disableOnInteraction: false,
        // waitForTransition: false,
      },
      speed: 20000,
      slidesPerView: 'auto',
      spaceBetween: 0,
      loop: true,
      // freeMode: true,
      // freeModeMomentum: false,
      // freeModeSticky: false,
      // disableOnInteraction: false,
      // waitForTransition: false,
    };
  }
}

export const HCarousel = tsx
  .ofType<HCarouselProps, HCarouselEmits, HCarouselScopedSlots>()
  .convert(HCarouselRef);
