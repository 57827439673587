export const UnAuthorized = 'UnAuthorized';
export interface YGApiErrorInfo {
  code: string;
  content: string;
}

export interface YGApiValidationInfo {
  itemName: string;
  code: string;
  content: string;
}

export interface YGApiError {
  errorInfo?: YGApiErrorInfo;
  validatioErrors?: YGApiValidationInfo[];
  validationInfo?: YGApiValidationInfo[];
}

export const ygApiErrorToMessage = (err: YGApiError): string => {
  if (!err || typeof err !== 'object') return '';
  const rows: string[] = [];
  if (err.errorInfo && err.errorInfo.content) {
    rows.push(err.errorInfo.content);
  }
  if (err.validationInfo) {
    err.validationInfo.forEach((info) => {
      if (info.content) {
        rows.push(info.content);
      }
    });
  }
  if (err.validatioErrors) {
    err.validatioErrors.forEach((info) => {
      if (info.content) {
        rows.push(info.content);
      }
    });
  }
  return rows.join('\n\n');
};
