import './HPrecautionList.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { mergeVNodeData } from '~/helpers';

export const HPrecautionList = tsx.component({
  name: 'HPrecautionList',
  functional: true,
  props: {
    items: {
      type: Array as PropType<string[]>,
    },
    dense: Boolean,
  },
  render(h, { data, props }) {
    const { items, dense } = props;

    return (
      <ul
        {...mergeVNodeData(data, {
          staticClass: 'h-precaution-list',
          class: dense && `h-precaution-list--dense`,
        })}>
        {items.map((item, index) => (
          <li staticClass="h-precaution-list__item" key={index}>
            ※{item}
          </li>
        ))}
      </ul>
    );
  },
});
