import './HVimeoPlayer.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Player, loadPlayer, Options, VimeoVideoQuality } from './sdk';
import { loadImageSize } from '~/helpers';

export interface HVimeoPlayerProps {
  /**
   * 動画のIDまたはURLのいずれかは必須です。
   */
  videoId?: number;

  /**
   * 動画のIDまたはURLのいずれかは必須です。
   */
  url?: string;

  /**
   * サムネイル画像URL
   */
  thumb?: string;

  /**
   * 別の動画が再生されると、この動画を自動的に一時停止します。
   * @default true
   */
  autopause?: boolean;

  /**
   * ビデオの再生を自動的に開始します。これは一部のデバイスでは機能しないことに注意してください。
   * @default false
   */
  autoplay?: boolean;

  /**
   * コントロールを非表示にし、ビデオを自動再生してループするプレーヤーのバックグラウンドモードを有効にします（Plus、PRO、またはBusinessメンバーが利用できます）。
   * @default false
   */
  background?: boolean;

  /**
   * ビデオに署名欄を表示します。
   * @default true
   */
  byline?: boolean;

  /**
   * ビデオコントロールの色を指定します。ビデオの埋め込み設定によって色が上書きされる場合があります。
   * @default '00adef'
   */
  color?: string;

  /**
   * このパラメーターは、クロムレスエクスペリエンスのために、プレーヤー内のすべての要素（再生バー、共有ボタンなど）を非表示にします。 ⚠️警告：このパラメーターを使用すると、再生バーとUIが非表示になります。視聴者の再生を開始するには、自動再生を有効にするか、プレーヤーSDKを使用して再生を開始および制御する必要があります。 （Plus、PRO、またはビジネスメンバーが利用可能）
   * @default true
   */
  controls?: boolean;

  /**
   * プレーヤーがCookieを含むセッションデータを追跡しないようにブロックします。
   * @default false
   */
  dnt?: boolean;

  /**
   * ビデオが最後に達したら、もう一度再生します。
   * @default false
   */
  loop?: boolean;

  /**
   * ロード時にこのビデオをミュートします。特定のブラウザで自動再生するために必要です。
   * @default false
   */
  muted?: boolean;

  /**
   * モバイルデバイスでビデオをインラインで再生します。再生時に自動的に全画面表示にするには、このパラメーターをfalseに設定します。
   * @default true
   */
  playsinline?: boolean;

  /**
   * ビデオにポートレートを表示します。
   * @default true
   */
  portrait?: boolean;

  /**
   * 親要素に応じてサイズを変更します（実験的）
   * @default false
   */
  responsive?: boolean;

  /**
   * 環境設定メニューに速度コントロールを表示し、再生速度API（PROおよびビジネスアカウントで使用可能）を有効にします。
   * @default false
   */
  speed?: boolean;

  /**
   * Vimeo Plus、PRO、およびBusinessのメンバーは、デスクトップで埋め込みビデオを特定の品質にデフォルト設定できます。可能な値：4K、2K、1080p、720p、540p、360p、240p
   * {@link https://help.vimeo.com/hc/en-us/articles/224983008-Setting-default-quality-for-embedded-videos}
   */
  quality?: VimeoVideoQuality;

  /**
   * デフォルトでは、特定の言語のキャプション/字幕をオンにします。特定のビデオにまだアップロードされていない言語設定を入力すると、テキストトラックパラメータは無視され、埋め込みビデオはデフォルトでCCまたは字幕が無効になっている状態で読み込まれる場合があります。小文字の言語コード（fr、es、de、enなど）をサポートします。人気のある言語コードの完全なリストはここにあります。
   * {@link https://www.andiamo.co.uk/resources/iso-language-codes/}
   */
  texttrack?: string;

  /**
   * ビデオにタイトルを表示します。
   * @default true
   */
  title?: boolean;

  /**
   * レスポンシブプレーヤーと透明な背景はデフォルトで有効になっており、このパラメーターをfalseに設定すると無効になります。
   * @default true
   */
  transparent?: boolean;

  /**
   * ロード完了後にフェードインさせる場合にtrue
   */
  fade?: boolean;

  /**
   * 領域全てを動画で覆う場合にtrue
   */
  cover?: boolean;

  /**
   * グラデーションマスクを利用する場合true
   */
  gradientMask?: boolean;
}

export interface HVimeoPlayerEmits {
  onPlay: any;
  onPause: any;
  onEnded: any;
  onTimeupdate: any;
  onProgress: any;
  onSeeked: any;
  onSeeking: any;
  onTexttrackchange: any;
  onCuechange: any;
  onCuepoint: any;
  onVolumechange: any;
  onPlaybackratechange: any;
  onBufferstart: any;
  onBufferend: any;
  onError: any;
  onLoaded: any;
  onThumbLoaded: any;
  onSizeDetected: any;
  onSuccessAutoplay: any;
  onMaybeAutoplayFailed: any;
  onAutoplayTimeout: any;
}

export interface HVimeoPlayerScopedSlots {
  default: HVimeoPlayerRef;
}

export type HVimeoPlayerLoadState = 'pending' | 'loaded';

export type HVimeoPlayerScriptLoadState =
  | 'pending'
  | 'loading'
  | 'loaded'
  | 'error';

@Component<HVimeoPlayerRef>({
  name: 'HVimeoPlayer',
  created() {
    if (process.browser) {
      this.loadThumb();
      this.init();
    }
  },
  watch: {
    thumb() {
      this.thumbLoaded = false;
      this.loadThumb();
    },
  },
  beforeDestroy() {
    this.destroy();
  },
  render() {
    const { thumbStyles } = this;
    const { default: defaultSlot } = this.$scopedSlots;
    const children = defaultSlot && defaultSlot(this);

    return (
      <div
        staticClass="h-vimeo-player"
        class={this.classes}
        v-resize={(e) => {
          this.width = e.width;
          this.height = e.height;
        }}>
        {!!thumbStyles && (
          <div staticClass="h-vimeo-player__thumb" style={thumbStyles} />
        )}
        <div
          staticClass="h-vimeo-player__node"
          style={this.nodeStyles}
          ref="node"
        />
        {children}
      </div>
    );
  },
})
export class HVimeoPlayerRef
  extends Vue
  implements
    Omit<HVimeoPlayerProps, 'videoId' | 'url' | 'quality' | 'texttrack'> {
  $refs!: {
    node: HTMLElement;
  };

  @Prop({ type: Number, default: null }) readonly videoId!: number | null;
  @Prop({ type: String, default: null }) readonly url!: string | null;
  @Prop(String) readonly thumb?: string;
  @Prop({ type: Boolean, default: true }) readonly autopause!: boolean;
  @Prop(Boolean) readonly autoplay!: boolean;
  @Prop(Boolean) readonly background!: boolean;
  @Prop({ type: Boolean, default: true }) readonly byline!: boolean;
  @Prop({ type: String, default: '#00adef' }) readonly color!: string;
  @Prop({ type: Boolean, default: true }) readonly controls!: boolean;
  @Prop(Boolean) readonly dnt!: boolean;
  @Prop(Boolean) readonly loop!: boolean;
  @Prop(Boolean) readonly muted!: boolean;
  @Prop({ type: Boolean, default: true }) readonly playsinline!: boolean;
  @Prop({ type: Boolean, default: true }) readonly portrait!: boolean;
  @Prop(Boolean) readonly responsive!: boolean;
  @Prop(Boolean) readonly speed!: boolean;
  @Prop(Boolean) readonly gradientMask!: boolean;
  @Prop({ type: Object, default: null })
  readonly quality!: VimeoVideoQuality | null;

  @Prop({ type: String, default: null }) readonly texttrack!: string | null;
  @Prop({ type: Boolean, default: true }) readonly title!: boolean;
  @Prop({ type: Boolean, default: true }) readonly transparent!: boolean;
  @Prop(Boolean) readonly fade!: boolean;
  @Prop(Boolean) readonly cover!: boolean;

  private _player?: Player;
  private internalScriptLoadState: HVimeoPlayerScriptLoadState = 'pending';
  private internalLoadState: HVimeoPlayerLoadState = 'pending';
  private width: number = 0;
  private height: number = 0;
  private videoWidth: number = 0;
  private videoHeight: number = 0;
  private thumbLoaded: boolean = false;
  private _autoplayDetectTimer?: number;
  private _autoplayTimeoutTimer?: number;
  // private playStarted: boolean = false;

  private loadThumb() {
    const { thumb } = this;
    if (thumb) {
      loadImageSize(thumb).then(() => {
        if (this.thumb === thumb) {
          this.thumbLoaded = true;
          this.$emit('thumbLoaded');
        }
      });
    }
  }

  get classes() {
    return {
      'h-vimeo-player--fade': this.fade,
      'h-vimeo-player--thumb-loaded': this.thumbLoaded,
      'h-vimeo-player--loaded': this.loaded,
      'h-vimeo-player--cover': this.cover,
      'h-vimeo-player--gradient-mask': this.gradientMask,
      'h-vimeo-player--thumb-seemless': this.thumbSeemless,
      // 'h-vimeo-player--started': this.playStarted,
    };
  }

  get loading() {
    return this.internalLoadState !== 'loaded';
  }

  get loaded() {
    return this.internalLoadState === 'loaded';
  }

  get scriptLoadState() {
    return this.internalScriptLoadState;
  }

  get playerOptions(): Options {
    const {
      videoId,
      url,
      autopause,
      autoplay,
      background,
      byline,
      color,
      controls,
      dnt,
      loop,
      muted,
      playsinline,
      portrait,
      responsive,
      speed,
      quality,
      texttrack,
      title,
      transparent,
    } = this;
    const options: Options = {
      autopause,
      autoplay,
      background,
      byline,
      color,
      controls,
      dnt,
      loop,
      muted,
      playsinline,
      portrait,
      responsive,
      speed,
      title,
      transparent,
    };

    if (videoId != null) {
      options.id = videoId;
    } else if (url != null) {
      // なんか、動画URLによってはエンコードしないといけないっぽい
      // https://stackoverflow.com/questions/63992013/getting-404-for-vimeo-api-even-though-the-video-is-available
      options.url = encodeURI(url);
    }
    if (quality != null) {
      options.quality = quality;
    }
    if (texttrack != null) {
      options.texttrack = texttrack;
    }
    return options;
  }

  get thumbStyles() {
    const { thumb } = this;
    if (!thumb) return;
    return {
      backgroundImage: `url(${thumb})`,
    };
  }

  get nodeStyles() {
    const { cover, width, height, videoWidth, videoHeight } = this;
    if (!cover || !width || !height || !videoWidth || !videoHeight) return;

    let diff = width / videoWidth;
    let _width = videoWidth * diff;
    let _height = videoHeight * diff;
    if (_height < height) {
      diff = height / _height;
      _height = height;
      _width *= diff;
    }

    return {
      width: `${_width}px`,
      height: `${_height}px`,
    };
  }

  get thumbSeemless() {
    return !!this.thumb && !this.controls;
  }

  private removeAutoplayDetect() {
    if (this._autoplayDetectTimer != null) {
      clearTimeout(this._autoplayDetectTimer);
      delete this._autoplayDetectTimer;
    }
  }

  private removeAutoplayTimeout() {
    if (this._autoplayTimeoutTimer != null) {
      clearTimeout(this._autoplayTimeoutTimer);
      delete this._autoplayTimeoutTimer;
    }
  }

  private setAutoplayDetect() {
    if (!this.autoplay) return;
    this.removeAutoplayDetect();
    this._autoplayDetectTimer = window.setTimeout(() => {
      this.removeAutoplayDetect();
      this.$emit('maybeAutoplayFailed');
    }, 1000);
  }

  async init(): Promise<Player> {
    if (this._player) return this._player;
    try {
      this.internalScriptLoadState = 'loading';
      const VimeoPlayer = await loadPlayer();
      const player = new VimeoPlayer(this.$refs.node, this.playerOptions);

      player.on('play', (data) => {
        this.$emit('play', this, data);
        // this.playStarted = true;
      });
      player.on('pause', (data) => {
        this.$emit('pause', this, data);
      });
      player.on('ended', (data) => {
        this.$emit('ended', this, data);
      });
      player.on('timeupdate', (data) => {
        if (this._autoplayDetectTimer != null) {
          // this.playStarted = true;
          this.removeAutoplayDetect();
          this.removeAutoplayTimeout();
          this.$emit('successAutoplay');
        }
        this.$emit('timeupdate', this, data);
      });
      player.on('progress', (data) => {
        this.$emit('progress', this, data);
      });
      player.on('seeked', (data) => {
        this.$emit('seeked', this, data);
      });
      player.on('seeking', (data) => {
        this.$emit('seeking', this, data);
      });
      player.on('texttrackchange', (data) => {
        this.$emit('texttrackchange', this, data);
      });
      player.on('cuechange', (data) => {
        this.$emit('cuechange', this, data);
      });
      player.on('cuepoint', (data) => {
        this.$emit('cuepoint', this, data);
      });
      player.on('volumechange', (data) => {
        this.$emit('volumechange', this, data);
      });
      player.on('playbackratechange', (data) => {
        this.$emit('playbackratechange', this, data);
      });
      player.on('bufferstart', (data) => {
        this.$emit('bufferstart', this, data);
      });
      player.on('bufferend', (data) => {
        this.$emit('bufferend', this, data);
      });
      player.on('error', (data) => {
        this.$emit('error', this, data);
      });
      player.on('loaded', async (data) => {
        // await new Promise((resolve) => setTimeout(resolve, 50000));
        this.setAutoplayDetect();
        this.internalLoadState = 'loaded';
        this.$emit('loaded', this, data);
        const [width, height] = await Promise.all([
          player.getVideoWidth(),
          player.getVideoHeight(),
        ]);
        this.videoWidth = width;
        this.videoHeight = height;
        this.$nextTick(() => {
          this.$emit('sizeDetected', this, { width, height });
        });
      });

      this._autoplayTimeoutTimer = window.setTimeout(() => {
        this.removeAutoplayTimeout();
        this.$emit('autoplayTimeout');
      }, 3000);

      this._player = player;
      this.internalScriptLoadState = 'loaded';

      return player;
    } catch (err) {
      this.internalScriptLoadState = 'error';
      throw err;
    }
  }

  // async detectSize() {
  //   const [] =
  // }

  player() {
    return this.init();
  }

  pause() {
    return this.init().then((player) => player.pause());
  }

  play() {
    return this.init().then((player) => player.play());
  }

  unload() {
    return this.init().then((player) => {
      this.removeAutoplayDetect();
      this.removeAutoplayTimeout();
      return player.unload();
    });
  }

  destroy() {
    this.removeAutoplayDetect();
    this.removeAutoplayTimeout();
    return this.init().then((player) =>
      player.destroy().then(() => {
        if (this._player) {
          delete this._player;
        }
      }),
    );
  }
  // destroy(): VimeoPromise<void, Error>;

  // on(event: EventName, callback: EventCallback): void;
  // off(event: EventName, callback?: EventCallback): void;
  // loadVideo(id: number): VimeoPromise<number, TypeError | PasswordError | PrivacyError | Error>;
  // ready(): VimeoPromise<void, Error>;
  // enableTextTrack(language: string, kind?: string): VimeoPromise<VimeoTextTrack, InvalidTrackLanguageError | InvalidTrackError | Error>;
  // disableTextTrack(): VimeoPromise<void, Error>;
  // getAutopause(): VimeoPromise<boolean, UnsupportedError | Error>;
  // setAutopause(autopause: boolean): VimeoPromise<boolean, UnsupportedError | Error>;
  // getColor(): VimeoPromise<string, Error>;
  // setColor(color: string): VimeoPromise<string, ContrastError | TypeError | Error>;
  // addCuePoint(time: number, data: VimeoCuePointData): VimeoPromise<string, UnsupportedError | RangeError | Error>;
  // removeCuePoint(id: string): VimeoPromise<string, UnsupportedError | InvalidCuePoint | Error>;
  // getCuePoints(): VimeoPromise<VimeoCuePoint[], UnsupportedError | Error>;
  // getBuffered(): VimeoPromise<VimeoTimeRange[], Error>;
  // getCurrentTime(): VimeoPromise<number, Error>;
  // setCurrentTime(seconds: number): VimeoPromise<number, RangeError | Error>;
  // getDuration(): VimeoPromise<number, Error>;
  // getEnded(): VimeoPromise<boolean, Error>;
  // getLoop(): VimeoPromise<boolean, Error>;
  // setLoop(loop: boolean): VimeoPromise<boolean, Error>;
  // getMuted(): VimeoPromise<boolean, Error>;
  // setMuted(muted: boolean): VimeoPromise<boolean, Error>;
  // getPaused(): VimeoPromise<boolean, Error>;
  // getPlayed(): VimeoPromise<VimeoTimeRange[], Error>;
  // getSeekable(): VimeoPromise<VimeoTimeRange[], Error>;
  // getSeeking(): VimeoPromise<boolean, Error>;
  // getPlaybackRate(): VimeoPromise<number, Error>;
  // setPlaybackRate(playbackRate: number): VimeoPromise<number, RangeError | Error>;
  // getTextTracks(): VimeoPromise<VimeoTextTrack[], Error>;
  // getVideoEmbedCode(): VimeoPromise<string, Error>;
  // getVideoId(): VimeoPromise<number, Error>;
  // getVideoTitle(): VimeoPromise<string, Error>;
  // getVideoWidth(): VimeoPromise<number, Error>;
  // getVideoHeight(): VimeoPromise<number, Error>;
  // getVideoUrl(): VimeoPromise<string, PrivacyError | Error>;
  // getVolume(): VimeoPromise<number, Error>;
  // setVolume(volume: number): VimeoPromise<number, RangeError | Error>;
}

export const HVimeoPlayer = tsx
  .ofType<HVimeoPlayerProps, HVimeoPlayerEmits, HVimeoPlayerScopedSlots>()
  .convert(HVimeoPlayerRef);
