import axios from 'axios';
import { GFErrorSourceObject, GFErrorTarget, isGFErrorJSON } from '../schemes';
import { ygApiErrorToMessage } from '../../schemes/error';

/**
 * 指定した例外がaxiosのエラーだった場合、エラー情報を抽出する
 *
 * @param source - 例外
 * @returns
 */
export function extractAxiosErrorResponse(
  source: unknown,
): GFErrorSourceObject | undefined {
  if (!axios.isAxiosError(source)) return;

  let name: string = source.name;
  let message: string = source.message;
  let stack: string | undefined = source.stack;
  let target: GFErrorTarget | undefined;

  const { response } = source;
  const data = response && response.data;
  let status = (response && response.status) || 500;

  const ygMessage = ygApiErrorToMessage(data);
  if (ygMessage) {
    message = ygMessage;
  }

  if (isGFErrorJSON(data)) {
    name = data.name;
    message = data.message;
    stack = data.stack;
    status = data.status;
    target = data.target;
  } else {
    const { method, url, params } = source.config;

    target = {
      path: url,
      params,
    };

    if (method) {
      target.method = method.toLowerCase();
    }
  }

  return {
    name,
    status,
    message,
    stack,
    target,
  };
}
