import './HCuisineSpecs.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CuisineColumn, CuisineContent, RestaurantCommons } from '~/schemes';
import { HPrecautionList, MySpec } from '~/components';

export interface HCuisineSpecsProps {
  data: CuisineColumn | CuisineContent;
  commons: RestaurantCommons;
}

export interface HCuisineSpecsEmits {}

export interface HCuisineSpecsScopedSlots {}

@Component<HCuisineSpecsRef>({
  name: 'HCuisineSpecs',

  render() {
    const { specs } = this;

    return (
      <table staticClass="h-cuisine-specs">
        <tbody>
          {specs.map(({ label, description, precautions }, index) => (
            <tr key={index}>
              <th>{label}</th>
              <td>
                <div v-wysiwyg={description} />
                {precautions.length > 0 && (
                  <HPrecautionList
                    items={precautions}
                    dense
                    staticClass="h-cuisine-specs__precaution"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  },
})
export class HCuisineSpecsRef extends Vue implements HCuisineSpecsProps {
  @Prop({ type: Object, required: true }) readonly commons!: RestaurantCommons;
  @Prop({ type: Object, required: true }) readonly data!: CuisineColumn;

  /** 表設定取得 */
  get specs() {
    const specs: MySpec[] = [];

    if (!this.data.specs) return specs;
    const { specOptions } = this.commons;
    const filterSpecs = this.data.specs.filter((item) => item.description);
    filterSpecs.forEach(({ specId, description, precautions }) => {
      const opt = specOptions.find((o) => o.value === specId);
      if (opt) {
        specs.push({
          label: opt.name,
          description,
          precautions,
        });
      }
    });
    return specs;
  }
}

export const HCuisineSpecs = tsx
  .ofType<HCuisineSpecsProps, HCuisineSpecsEmits, HCuisineSpecsScopedSlots>()
  .convert(HCuisineSpecsRef);
