import {
  DirectiveFunction,
  DirectiveOptions,
  DirectiveBinding,
} from 'vue/types/options';
import { ImageLoupe, ImageLoupeSettings } from '~/libs/image-loupe';

/**
 * 画像ルーペディレクティブのバインド値オブジェクト
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ImageLoupeDirectiveBindingValue extends ImageLoupeSettings {}

export interface ImageLoupeDirectiveBinding extends DirectiveBinding {
  value?: ImageLoupeDirectiveBindingValue;
}

const IMAGE_LOUPE_SYMBOL = Symbol('IMAGE_LOUPE_SYMBOL');

/**
 * 画像ルーペディレクティブの処理対象要素
 */
export interface ImageLoupeDirectiveElement extends HTMLElement {
  [IMAGE_LOUPE_SYMBOL]?: ImageLoupe;
}

/**
 * 指定の要素に画像ルーペインスタンスをマウントする
 *
 * * すでにマウント済みの際はそのインスタンスをリターンする
 *
 * @param el - マウントする要素
 * @param settings - 画像ルーペの初期化設定
 * @returns 画像ルーペインスタンス
 */
const applyLoupe = (
  el: ImageLoupeDirectiveElement,
  settings?: ImageLoupeDirectiveBindingValue,
) => {
  if (!settings) {
    return disposeLoupe(el);
  }
  const currentLoupe = el[IMAGE_LOUPE_SYMBOL];
  if (currentLoupe) {
    if (currentLoupe.src !== settings.src) {
      currentLoupe.load(settings.src);
    }
    return currentLoupe;
  }

  const loupe = new ImageLoupe(settings);

  el[IMAGE_LOUPE_SYMBOL] = loupe;
  loupe.mount(el);
  return loupe;
};

/**
 * 指定の要素の画像ルーペインスタンスを破棄する
 *
 * * インスタンスがマウントされていない時は何もしない
 *
 * @param el - 破棄対象の要素
 */
const disposeLoupe = (el: ImageLoupeDirectiveElement) => {
  const loupe = el[IMAGE_LOUPE_SYMBOL];
  if (loupe) {
    loupe.destroy();
    delete el[IMAGE_LOUPE_SYMBOL];
  }
};

const apply: DirectiveFunction = (
  el: ImageLoupeDirectiveElement,
  binding: ImageLoupeDirectiveBinding,
) => {
  applyLoupe(el, binding.value);
};

const unbind: DirectiveFunction = (
  el: ImageLoupeDirectiveElement,
  binding: ImageLoupeDirectiveBinding,
) => {
  disposeLoupe(el);
};

export default {
  name: 'image-loupe',
  inserted: apply,
  update: apply,
  unbind,
} as DirectiveOptions;
