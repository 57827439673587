import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HTabsRef } from './HTabs';

export interface HTabProps {
  id: string;
}

export interface HTabEmits {
  onClick: MouseEvent;
}

export interface HTabScopedSlots {}

@Component<HTabRef>({
  name: 'h-tab',

  inject: ['tabs'],

  created() {
    this.tabs.addTab(this);
  },

  beforeDestroy() {
    this.tabs.removeTab(this);
  },

  render(h) {
    return (
      <button
        staticClass="h-tab"
        class={this.classes}
        type="button"
        onClick={(ev) => {
          this.$emit('click', ev);
          if (!ev.defaultPrevented) {
            this.tabs.to(this.id);
          }
        }}>
        <span staticClass="h-tab__content">{this.$slots.default}</span>
      </button>
    );
  },
})
export class HTabRef extends Vue implements HTabProps {
  readonly $el!: HTMLElement;
  readonly tabs!: HTabsRef;

  @Prop({ type: String, required: true }) id!: string;

  get tabsCurrent() {
    return this.tabs.current;
  }

  get isActive() {
    return this.tabsCurrent === this.id;
  }

  get classes() {
    return {
      'h-tab--active': this.isActive,
    };
  }
}

export const HTab = tsx
  .ofType<HTabProps, HTabEmits, HTabScopedSlots>()
  .convert(HTabRef);
