import './HMenu.scss';

import * as tsx from 'vue-tsx-support';
import { Component, Mixins } from 'vue-property-decorator';
import {
  VStackMenu,
  VStackMenuProps,
  VStackMenuEmits,
  VStackMenuScopedSlots,
} from '@dadajam4/vue-stack';

export interface HMenuProps extends VStackMenuProps {}

export interface HMenuEmits extends VStackMenuEmits {}

export interface HMenuScopedSlots extends VStackMenuScopedSlots {}

@Component<HMenuRef>({
  name: 'HMenu',
})
export class HMenuRef extends Mixins(VStackMenu) {}

export const HMenu = tsx
  .ofType<HMenuProps, HMenuEmits, HMenuScopedSlots>()
  .convert(HMenuRef);
