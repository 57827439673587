import './HDrawerMenuGroup.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HDrawerMenu, HDrawerMenuData } from './HDrawerMenu';

export interface HDrawerMenuGroupData {
  menus: HDrawerMenuData[];
  mini?: boolean;
  invert?: boolean;
}

export interface HDrawerMenuGroupProps {
  menus?: HDrawerMenuData[];
  mini?: boolean;
  invert?: boolean;
  removeEdgeDivider?: boolean;
}

export interface HDrawerMenuGroupEmits {
  onAnchor: any;
}

export interface HDrawerMenuGroupScopedSlots {}

@Component<HDrawerMenuGroupRef>({
  name: 'HDrawerMenuGroup',
  render() {
    const { computedMenus: menus } = this;
    if (menus.length === 0) {
      return undefined as any;
    }
    return (
      <ul staticClass="h-drawer-menu-group" class={this.classes}>
        {menus.map((menu) => {
          return (
            <HDrawerMenu
              key={menu.key}
              staticClass="h-drawer-menu-group__item"
              invert={menu.invert === undefined ? this.invert : menu.invert}
              mini={menu.mini === undefined ? this.mini : menu.mini}
              to={menu.to}
              href={menu.href}
              target={menu.target}
              rel={menu.rel}
              value={menu.value}
              loading={menu.loading}
              onClick={menu.click || []}
              label={menu.label}
              on={{
                anchor: (payload) => {
                  this.$emit('anchor', payload);
                },
              }}
            />
          );
        })}
      </ul>
    );
  },
})
export class HDrawerMenuGroupRef extends Vue implements HDrawerMenuGroupProps {
  @Prop({ type: Array, default: () => [] }) readonly menus!: HDrawerMenuData[];
  @Prop({ type: Boolean }) readonly mini!: boolean;
  @Prop({ type: Boolean }) readonly invert!: boolean;
  @Prop({ type: Boolean }) readonly removeEdgeDivider!: boolean;

  get classes() {
    return {
      'h-drawer-menu-group--mini': this.mini,
      'h-drawer-menu-group--invert': this.invert,
      'h-drawer-menu-group--remove-edge-divider': this.removeEdgeDivider,
    };
  }

  get computedMenus() {
    return this.menus.filter((menu) => !menu.hidden);
  }
}

export const HDrawerMenuGroup = tsx
  .ofType<
    HDrawerMenuGroupProps,
    HDrawerMenuGroupEmits,
    HDrawerMenuGroupScopedSlots
  >()
  .convert(HDrawerMenuGroupRef);
