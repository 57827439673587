import './HListCurrencySelector.scss';

import * as tsx from 'vue-tsx-support';
import { FunctionalComponentOptions } from 'vue';
import {
  HListSelector,
  HListSelectorProps,
  HListSelectorEmits,
  HListSelectorScopedSlots,
  HListSelectorItem,
} from './HListSelector';

export interface HListCurrencySelectorProps
  extends Omit<
    HListSelectorProps,
    'items' | 'inputValue' | 'title' | 'guide'
  > {}

export interface HListCurrencySelectorEmits extends HListSelectorEmits {}

export interface HListCurrencySelectorScopedSlots
  extends HListSelectorScopedSlots {}

export const HListCurrencySelectorSelectorOptions: FunctionalComponentOptions<HListCurrencySelectorProps> = {
  name: 'HListCurrencySelector',
  functional: true,
  render(h, { props, listeners, scopedSlots, parent, data }) {
    const { $commons, $hotel } = parent;
    const hotelRate =
      $hotel.current && $hotel.current.ygets && $hotel.current.ygets.baseRate;
    const inputValue = $commons.currency;
    const items: HListSelectorItem[] = $commons.exchangeRates.map(
      ({ base }, index) => {
        const baseRate = $commons.exchangeRateAt(base);
        const toRate =
          hotelRate &&
          baseRate &&
          baseRate.rateList.find((row) => row.quote === hotelRate);
        return {
          value: base,
          label: base,
          sub:
            baseRate && toRate
              ? () => {
                  return [
                    <span staticClass="h-list-currency-selector__sub">
                      <span staticClass="h-list-currency-selector__sub__base">
                        {base} 1
                      </span>
                      {toRate.quote !== base && (
                        <span staticClass="h-list-currency-selector__sub__calc">
                          <span staticClass="h-list-currency-selector__sub__calc__divider">
                            /
                          </span>
                          <span>
                            {hotelRate}{' '}
                            {String(
                              Math.round(parseFloat(toRate.rate) * 1000000) /
                                1000000,
                            )}
                          </span>
                        </span>
                      )}
                    </span>,
                  ];
                }
              : undefined,
        };
      },
    );

    return (
      <HListSelector
        {...{
          staticClass: 'h-list-currency-selector',
          class: [data.staticClass, data.class],
          props: {
            ...props,
            title: '表示通貨 / Currency',
            guide: 'レート換算は最新の取引を元に表示した、参考価格になります。',
            items,
            inputValue,
          },
          on: {
            ...listeners,
            input: (value) => {
              $commons.changeCurrency(value);
              let fns = listeners.input;
              if (!fns) return;
              if (!Array.isArray(fns)) fns = [fns];
              fns.forEach((fn) => fn(value));
            },
          },
          scopedSlots,
        }}
      />
    );
  },
};

export const HListCurrencySelector = tsx
  .ofType<
    HListCurrencySelectorProps,
    HListCurrencySelectorEmits,
    HListCurrencySelectorScopedSlots
  >()
  .convert(HListCurrencySelectorSelectorOptions as any);
