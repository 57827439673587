import './HCard.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode, VNodeChildren } from 'vue';
import { mergeVNodeData } from '~/helpers';
import { HImg, HIcon } from '~/components';

export interface HCardAction {
  text?: string | null;
  url: string;
}

export interface HCardProps {
  tag?: string;
  image?: string | null;
  name?: string | null;
  action?: HCardAction | string | null;
  block?: boolean;
}

export const HCard = tsx.component({
  name: 'HCard',
  functional: true,
  props: {
    tag: {
      type: String,
    },
    image: {
      type: [String, Object] as PropType<string | null>,
      default: null,
    },
    name: {
      type: [String, Object] as PropType<string | null>,
      default: null,
    },
    action: {
      type: [String, Object] as PropType<HCardAction | string | null>,
    },
    block: Boolean,
  },

  render(h, { data, props, children, scopedSlots, parent }) {
    const { tag = 'div', image, name, action, block } = props;
    const { name: nameSlot, footer: footerSlot } = scopedSlots;
    const $children: VNode[] = [];
    const classes: { [key: string]: boolean } = {
      'h-card--has-image': !!image,
      'h-card--block': !!block,
    };

    if (image) {
      $children.push(<HImg staticClass="h-card__image" liquid src={image} />);
    }

    if (name != null || nameSlot) {
      $children.push(
        <h4 staticClass="h-card__name">{nameSlot ? nameSlot(props) : name}</h4>,
      );
    }

    $children.push(<div staticClass="h-card__body">{children}</div>);

    if (action != null || footerSlot) {
      let footerChildren: VNodeChildren;
      if (footerSlot) {
        footerChildren = footerSlot(props);
      } else if (action) {
        const _action = typeof action === 'string' ? { url: action } : action;
        const url = _action.url;
        const text = _action.text || url;
        const props = parent.$navigation.resolveHrefTo(url);
        const tag = props.to ? 'nuxt-link' : 'a';
        const attrs = props.href
          ? {
              href: props.href,
              target: '_blank',
              rel: 'noopener',
            }
          : undefined;
        footerChildren = [
          h(
            tag,
            {
              staticClass: 'h-card__footer__link',
              attrs,
              props,
            },
            [
              <HIcon
                staticClass="h-card__footer__link__icon"
                name="arrow-right"
              />,
              <span staticClass="h-card__footer__link__label">{text}</span>,
            ],
          ),
        ];
      }
      if (!footerChildren) throw new Error('missing footer children');
      classes['h-card--has-footer'] = true;

      $children.push(<div staticClass="h-card__footer">{footerChildren}</div>);
    }

    return h(
      tag,
      mergeVNodeData(data, {
        staticClass: 'h-card',
        class: classes,
      }),
      $children,
    );
  },
});
