import './HAllBrandsNavigation.scss';

import Vue from 'vue';
import * as tsx from 'vue-tsx-support';
import { Component } from 'vue-property-decorator';
import { HBrandNavigation } from './HBrandNavigation';
import { ComputedBrand } from './schemes';
import { createBrandLinks } from './helpers';

export interface HAllBrandsNavigationProps {}

export interface HAllBrandsNavigationEmits {}

export interface HAllBrandsNavigationScopedSlots {}

@Component<HAllBrandsNavigationRef>({
  name: 'HAllBrandsNavigation',
  render() {
    const { brands, columns } = this;

    return (
      <div staticClass="h-all-brands-navigation">
        {/** PC用のワイドなブランドリスト */}
        <div staticClass="h-all-brands-navigation__wide">
          {brands.map((brand) => (
            <HBrandNavigation
              key={brand.id}
              staticClass="h-all-brands-navigation__wide__item"
              brand={brand}
            />
          ))}
        </div>

        {/** SP用の左右カラムにセパレートされたブランドリスト */}
        <div staticClass="h-all-brands-navigation__columns">
          {columns.map((brands, i) => {
            const key = i === 0 ? 'left' : 'right';

            return (
              <div
                staticClass="h-all-brands-navigation__column"
                class={`h-all-brands-navigation__column--${key}`}
                key={key}>
                {brands.map((brand) => (
                  <HBrandNavigation
                    key={brand.id}
                    staticClass="h-all-brands-navigation__column__item"
                    brand={brand}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  },
})
export class HAllBrandsNavigationRef
  extends Vue
  implements HAllBrandsNavigationProps {
  /**
   * 全ブランド情報
   *
   * * ブランド基本情報にブランド内リンクリストを追加したオブジェクトのリスト
   */
  get brands(): ComputedBrand[] {
    return this.$hotel.brands
      .map((brand) => {
        return {
          ...brand,
          links: createBrandLinks(this, brand),
        };
      })
      .filter((brand) => brand.links.length > 0); // リンクが0件のブランドは表示しない
  }

  /**
   * SP表示用に左右のカラムに分離したブランド情報のリスト
   */
  get columns(): [ComputedBrand[], ComputedBrand[]] {
    const columns: [ComputedBrand[], ComputedBrand[]] = [[], []];
    this.brands.forEach((brand, i) => {
      const index = (i % 2) as 0 | 1;
      columns[index].push(brand);
    });
    return columns;
  }
}

export const HAllBrandsNavigation = tsx
  .ofType<
    HAllBrandsNavigationProps,
    HAllBrandsNavigationEmits,
    HAllBrandsNavigationScopedSlots
  >()
  .convert(HAllBrandsNavigationRef);
