import Vue from 'vue';
import { SearchTagCategoryForGF, TagForGF } from '~/schemes';
import {
  BuiltInSearchCategory,
  ChildTagSearchSettings,
} from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

/**
 * フィルターカテゴリの種別のリスト
 *
 * - `builtIn` 組み込み
 * - `common` 共通マスタ
 * - `original` 独自設定
 */
const FILTER_CATEGORY_TYPES = ['builtIn', 'common', 'original'] as const;

/**
 * フィルターカテゴリの種別
 *
 * @see {@link FILTER_CATEGORY_TYPES}
 */
type FilterCategoryType = typeof FILTER_CATEGORY_TYPES[number];

export interface SpecialPageModuleFilterCategoryInput {
  _id: string;
  /**
   * フィルターカテゴリの種別
   *
   * @see {@link FilterCategoryTypes}
   */
  type: FilterCategoryType;
  /** カテゴリ名 */
  name: string;
  /** カテゴリスラッグ */
  slug: string;
  /**
   * タグのリスト
   *
   * @see {@link TagForGF}
   */
  tags: TagForGF[];
}

/**
 * 特集モジュールフィルターカテゴリ
 *
 * 特集検索（フィルタ）における、カテゴリ1つ分のタグ（ラベル＋スラッグ）のリストと、ユーザーの選択状況を保持する。
 */
export class SpecialPageModuleFilterCategory {
  private _state = Vue.observable({
    selectedSlugs: [] as string[],
  });

  _id: string;
  /**
   * フィルターカテゴリの種別
   *
   * @see {@link FilterCategoryTypes}
   */
  type: FilterCategoryType;
  /**
   * カテゴリ名
   *
   * @example "地域"
   */
  name: string;
  /**
   * カテゴリスラッグ
   */
  slug: string;
  /**
   * タグのリスト
   *
   * @see {@link TagForGF}
   */
  tags: TagForGF[];
  /**
   * 選択中のタグのスラッグのリスト
   */
  get selectedSlugs() {
    return this._state.selectedSlugs;
  }

  set selectedSlugs(selectedSlugs) {
    this._state.selectedSlugs = selectedSlugs;
  }

  /**
   * 選択中のタグのリスト
   */
  get selectedTags(): TagForGF[] {
    return this.tags.filter((tag) => this.selectedSlugs.includes(tag.slug));
  }

  /**
   * 選択状態をクリアする
   */
  clear(): void {
    this.selectedSlugs = [];
  }

  /**
   * 検索（フィルタ）可能な特集ページ子モジュールの検索マッチ条件と、現在のカテゴリのフィルタ選択状態がマッチしている場合に true を返却する
   */
  filter(itemMatchSettings: ChildTagSearchSettings | undefined): boolean {
    // 1. 選択値（画面で選択している検索条件）が空っぽの時は強制的にヒット
    if (this.selectedSlugs.length === 0) {
      return true;
    }

    // 2. アイテムに検索マッチ条件が設定されていなかった場合はすぐにアンマッチでreturn
    if (!itemMatchSettings) {
      return false;
    }

    // 3. 1つ以上選択している場合、アイテムの検索設定に選択中の項目（のどれか）が含まれていればヒット
    const target = itemMatchSettings[`${this.type}Tags` as const];
    if (!target) {
      return false;
    }

    const targetSlugs = (target as Record<string, string[] | undefined>)[
      this._id
    ];

    if (!targetSlugs) {
      return false;
    }

    return this.selectedSlugs.some((slug) => targetSlugs.includes(slug));
  }

  constructor(input: SpecialPageModuleFilterCategoryInput) {
    const { _id, type, name, slug, tags } = input;
    this._id = _id;
    this.type = type;
    this.name = name;
    this.slug = slug;
    this.tags = tags;
  }
}

export function createCategoriesByBuiltinCategories(
  builtInSearchCategories: BuiltInSearchCategory[],
  childBuiltInSlugs: string[],
  vm: Vue,
): SpecialPageModuleFilterCategory[] {
  const result: SpecialPageModuleFilterCategory[] = [];
  builtInSearchCategories.forEach((slug) => {
    // 現在の組み込みはブランドしか存在しない
    if (slug === 'brand') {
      const name = vm.$t('label.brand') as string;

      // 子モジュールに登録されている検索タグのみ取得する
      const tags: TagForGF[] = [];
      vm.$hotel.brands.forEach((brand) => {
        if (childBuiltInSlugs.includes(brand.slug)) {
          tags.push({
            _id: brand.slug,
            slug: brand.slug,
            label: brand.name,
          });
        }
      });

      if (tags.length) {
        result.push(
          new SpecialPageModuleFilterCategory({
            _id: slug,
            type: 'builtIn',
            name,
            slug,
            tags,
          }),
        );
      }
    } // 組み込みが追加されるまで無視する
  });
  return result;
}

export function createCategoriesByCommonCategories(
  commonCategoryIds: string[],
  searchTagCategories: SearchTagCategoryForGF[],
  childCommonSlugs: string[],
  vm: Vue,
): SpecialPageModuleFilterCategory[] {
  const result: SpecialPageModuleFilterCategory[] = [];
  commonCategoryIds.forEach((commonCategoryId) => {
    let searchTagCategory = searchTagCategories.find(
      (searchTagCategory) => searchTagCategory._id === commonCategoryId,
    );
    if (!searchTagCategory) {
      vm.$logger.warn(
        '共通のカテゴリマスタを検索しましたが存在しないため処理をスキップします',
        commonCategoryId,
      );
      return;
    }

    const _id = searchTagCategory._id;
    if (!_id) {
      throw new Error('共通カテゴリのIDが存在しません');
    }

    // 子モジュールに登録されている検索タグのみ取得する
    searchTagCategory = {
      ...searchTagCategory,
      tags: searchTagCategory.tags.filter(({ slug }) =>
        childCommonSlugs.includes(slug),
      ),
    };

    if (searchTagCategory.tags.length) {
      result.push(
        new SpecialPageModuleFilterCategory({
          ...searchTagCategory,
          _id,
          type: 'common',
        }),
      );
    }
  });

  return result;
}

export function createCategoriesByOriginalCategories(
  originalSearchTagCategories: SearchTagCategoryForGF[],
): SpecialPageModuleFilterCategory[] {
  const result: SpecialPageModuleFilterCategory[] = [];

  originalSearchTagCategories.forEach((originalSearchTagCategory) => {
    const key = originalSearchTagCategory.key;
    if (!key) {
      throw new Error('独自カテゴリのKeyが存在しません');
    }
    if (originalSearchTagCategory.tags.length) {
      result.push(
        new SpecialPageModuleFilterCategory({
          ...originalSearchTagCategory,
          _id: key,
          type: 'original',
        }),
      );
    }
  });
  return result;
}
