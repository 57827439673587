import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

export interface VPortalTargetProps {
  name: string;
  activeClass?: string;
}

export interface VPortalTargetEmits {}

export interface VPortalTargetScopedSlots {}

@Component<VPortalTargetRef>({
  name: 'VPortalTarget',

  render() {
    const { renderedItems: items } = this;
    return (
      <div staticClass="v-portal-target" class={this.classes}>
        {items.map((item) => item.rendered).filter((r) => r !== null)}
      </div>
    );
  },
})
export class VPortalTargetRef extends Vue implements VPortalTargetProps {
  @Prop({ type: String, required: true }) readonly name!: string;
  @Prop({ type: String, default: 'v-portal-target--active' })
  readonly activeClass!: string;

  get items() {
    const { name } = this;
    return this.$portal.items.filter((item) => item.to === name);
  }

  get renderedItems() {
    return this.items.filter((item) => !!item.rendered);
  }

  get classes() {
    return {
      [this.activeClass]: this.renderedItems.length > 0,
    };
  }
}

export const VPortalTarget = tsx
  .ofType<VPortalTargetProps, VPortalTargetEmits, VPortalTargetScopedSlots>()
  .convert(VPortalTargetRef);
