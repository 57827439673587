import Vue from 'vue';
import { Context, Plugin, NuxtAppOptions, NuxtError } from '@nuxt/types';
import { GFError, isGFErrorJSON } from '~/error';

type GFErrorStatic = typeof GFError;

declare module 'vue/types/vue' {
  export interface Vue {
    $error: ErrorService;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $error: ErrorService;
  }
}

declare module '@nuxt/types' {
  export interface NuxtAppOptions {
    $error: ErrorService;
  }

  export interface Context {
    $error: ErrorService;
  }
}

export class ErrorService {
  readonly context: Context;

  constructor(context: Context) {
    this.context = context;
  }

  create(...args: Parameters<GFErrorStatic['create']>) {
    return GFError.create(...args);
  }

  from(...args: Parameters<GFErrorStatic['from']>) {
    return GFError.from(...args);
  }

  throw(source: any) {
    const err = this.from(source);
    this.context.$logger.error(err);

    return this.context.error({
      statusCode: err.status,
      message: JSON.stringify(err),
    });
  }

  /**
   * Nuxtのエラーページコンポーネントに渡されたerror propから、GFErrorを復元する
   *
   * @param source - エラーページに渡されたerror prop
   * @returns
   */
  deserializeGFErrorByNuxtError(nuxtError: NuxtError) {
    const { statusCode = 500, message } = nuxtError;
    try {
      const work = JSON.parse(message || '');
      if (isGFErrorJSON(work)) {
        return this.create(work);
      }
    } catch (_err) {
      // noop
    }

    return this.create({ status: statusCode, message });
  }
}

const plugin: Plugin = (context, inject) => {
  const error = new ErrorService(context);
  context.$error = error;
  inject('error', error);
};

export default plugin;
