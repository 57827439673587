import './HPlainBanner.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { RawLocation } from 'vue-router';
import { mergeVNodeData } from '~/helpers';

export type HPlainBannerColor = 'primary' | 'success' | 'warn';

export const HPlainBannerColors: HPlainBannerColor[] = [
  'primary',
  'success',
  'warn',
];

export const HPlainBannerRaw = tsx.component({
  name: 'HPlainBanner',
  functional: true,
  props: {
    tag: String,
    type: String,
    href: String as PropType<string | null>,
    target: String,
    rel: String,
    color: String as PropType<HPlainBannerColor>,
    to: (null as unknown) as PropType<RawLocation>,
  },

  render(h, { data, children, listeners, props }) {
    const { color, tag, type, href, to, target } = props;
    const defaultTag = tag || 'div';
    const TagName =
      (href && 'a') || (to && 'nuxt-link') || (type && 'button') || defaultTag;
    const isClickable = TagName !== defaultTag;
    const classes = ['h-plain-banner'];
    color && classes.push(`h-plain-banner--${color}`);
    isClickable && classes.push(`h-plain-banner--clickable`);

    const _data = mergeVNodeData(data, {
      staticClass: classes.join(' '),
      on: listeners,
      props,
      attrs: href
        ? {
            href,
            target,
          }
        : undefined,
    });
    return h(TagName, _data, children);
  },
});

export type HPlainBannerComponent = typeof HPlainBannerRaw & {
  colors: HPlainBannerColor[];
};

export const HPlainBanner = HPlainBannerRaw as HPlainBannerComponent;
HPlainBanner.colors = HPlainBannerColors;
