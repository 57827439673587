import { AVAILABLE_LANGUAGES } from '../schemes/language';

// ==================================================
// ↓↓↓ こっから先はacco-cms実装のほぼコピペ
// ↓↓↓ https://github.com/HoshinoResort/acco-cms/blob/ac8f871a7e9212a60b13b522e85cd5ac0d5a0fa2/packages/shared/helpers/link.ts
// ==================================================

/**
 * 下書きプレビューっぽいホスト名（本番以外含む）にマッチする正規表現パターン文字列。
 *
 * @memo
 *   このパターンは他のパターンと合わせて使う想定なので、先頭マッチのチェックとかはしていない。
 *
 * ### マッチする対象
 * - `"preview.hr-acco-cms.hoshinoresorts.systems"`
 * - `"preview.test-hr-acco-cms.hoshinoresorts.systems"`
 * - `"preview.hoge1-hr-acco-cms.hoshinoresorts.systems"`
 */
export const PREVIEW_HOST_PATTERN = `preview.([a-z\\d]+-)?hr-acco-cms.hoshinoresorts.systems`;

/**
 * GF公開URLぽいホスト名（本番以外含む）にマッチする正規表現パターン文字列
 *
 * @memo
 *   このパターンは他のパターンと合わせて使う想定なので、先頭マッチのチェックとかはしていない。
 *
 * ### マッチする対象
 * - `"gf-acco-cms.hoshinoresorts.com"`
 * - `"hoshinoresorts.com"`
 * - `"gf-test.hoshinoresorts.com"`
 * - `"test.hoshinoresorts.com"`
 * - `"www.hoshinoresorts.com"` ※これはマッチさせたくないけど、このパターンではマッチする。他パターンと組み合わせて利用するのでそれでOKとした
 *
 * ### マッチしない対象
 * - `"preview.hr-acco-cms.hoshinoresorts.systems"`
 */
export const GF_HOST_PATTERN = `(gf-[a-z\\-]+.|test.)?hoshinoresorts.com`;

/**
 * GFビューアっぽいホスト名（本番以外含む）にマッチする正規表現パターン文字列
 *
 * @memo
 *   localhost:[数字] 的なやつは強制的にマッチさせる
 *
 * @see {PREVIEW_HOST_PATTERN}
 * @see {GF_HOST_PATTERN}
 */
export const VIEWER_HOST_PATTERN = `(localhost:\\d+|${PREVIEW_HOST_PATTERN}|${GF_HOST_PATTERN})`;

/**
 * GFビューアっぽいURL（本番以外含む）にマッチする正規表現パターン文字列
 *
 * ### マッチする対象
 * - `"https://preview.hr-acco-cms.hoshinoresorts.systems/ja/"`
 * - `"https://preview.test-hr-acco-cms.hoshinoresorts.systems/en/a/b/"`
 * - `"https://preview.hoge1-hr-acco-cms.hoshinoresorts.systems/ja/a/b/"`
 * - `"https://gf-acco-cms.hoshinoresorts.com/ja/a/b/"`
 * - `"https://hoshinoresorts.com/ja/a/b/#hoge-123"`
 * - `"https://gf-test.hoshinoresorts.com/ja/a/b/"`
 * - `"https://test.hoshinoresorts.com/ja/a/b/"`
 *
 * ### マッチしない対象
 * - `"https://www.hoshinoresorts.com/ja/"`
 * - `"https://preview.hr-acco-cms.hoshinoresorts.systems/jaa/"`
 * - `"https://hoshinoresorts.com/JA/hotels/0000000003"`
 *
 * [0: URL文字列, 1: ホスト文字列, 2: xxx, 3: xxx, 4: 言語, 5: パス文字列]
 *
 * @see {VIEWER_HOST_PATTERN}
 * @see {AVAILABLE_LANGUAGES}
 */
export const VIEWER_URL_PATTERN = `https?://${VIEWER_HOST_PATTERN}/(${AVAILABLE_LANGUAGES.join(
  '|',
)})(/([\\w- ./?%&#=]*))?`;

/**
 * GFの公開URLっぽい文字列（下書きプレビュー含む）にマッチする正規表現
 *
 * @see {VIEWER_URL_PATTERN}
 */
export const VIEWER_URL_RE = new RegExp(`^${VIEWER_URL_PATTERN}$`);

/**
 * 指定したURLが、ビューアっぽいURL（GFや下書きプレビュー）なURLの場合、そのURLを正規化する
 *
 * ### 変換内容
 * - いちおう、トリムする
 * - ビューアっぽいURLの場合、オリジン部分を削除
 *
 * @param textOrURL - 何かしらのURLっぽい文字列
 * @returns 処理済みの文字列
 */
export function normalizeViewerURL(url: string | null | undefined) {
  return (url || '').trim().replace(VIEWER_URL_RE, `/$4$5`);
}
