import { NuxtAxiosInstance } from '@nuxtjs/axios';
import axios from 'axios';
import { Vue } from 'vue/types/vue';
import { Plugin } from '@nuxt/types';
import qs from 'qs';

import CommonsRoute from './routes/commons';
import HotelsRoute from './routes/hotels';
import CrossSearchRoute from './routes/cross-search';
import { LanguageInfo } from '~/schemes';

export const getErrorStatus = (source: any): number | undefined => {
  if (!axios.isAxiosError(source)) {
    return;
  }
  const { response } = source;
  if (!response) {
    return;
  }
  return response.status;
};

export interface AppApi extends NuxtAxiosInstance {
  commons: CommonsRoute;
  hotels: HotelsRoute;
  crossSearch: CrossSearchRoute;
  // isAxiosError: typeof isAxiosError;
  getErrorStatus: typeof getErrorStatus;
}

declare module 'vue/types/vue' {
  export interface Vue {
    $api: AppApi;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $api: AppApi;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $api: AppApi;
  }
}

const YGETS_PROFILE_CHECK_RE = /\/member\/profile\/?/;

/**
 * @memo: 宿GETS API（Spring） のデフォルトはカンマ区切り
 */
const paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'comma' });
};

// eslint-disable-next-line require-await
const plugin: Plugin = async (ctx, inject) => {
  const prefix = process.server ? ctx.$env.ygetsOrigin : '';
  const api = ctx.$axios.create({
    baseURL: `${prefix}/${ctx.$env.ygetsApiProxyPrefix}`,
    baseErrorHandler: (err) => {
      const { config, response } = err;
      const { url, method } = config;
      const status = response && response.status;
      if (
        method &&
        method.toUpperCase() === 'GET' &&
        status === 403 &&
        url &&
        YGETS_PROFILE_CHECK_RE.test(url)
      ) {
        return Promise.resolve(null);
      }
    },
  }) as AppApi;

  api.getErrorStatus = getErrorStatus;
  api.commons = new CommonsRoute(api, ctx);
  api.hotels = new HotelsRoute(api, ctx);
  api.crossSearch = new CrossSearchRoute(api, ctx);
  api.isAxiosError = axios.isAxiosError.bind(axios);

  api.onRequest((config) => {
    const langInfo: LanguageInfo | null =
      ctx.store.getters['language/currentInfo'];
    config.params = {
      ...(config.params || {}),
    };

    if (!config.params.lang && langInfo) {
      config.params.lang = langInfo.ygetsKey;
    }

    config.paramsSerializer = paramsSerializer;
  });

  ctx.$api = api;
  inject('api', api);
};

export default plugin;
