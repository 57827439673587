import './HHotelWithAttractions.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { HHotelIntroduction } from '../HHotelIntroduction';
import { HAttractiveIntroductionList } from '../HAttractiveIntroduction';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, HotelWithAttractionsModule } from '~/schemes';
import { mergeVNodeData } from '~/helpers';

export const HHotelWithAttractions = tsx.component({
  name: 'HHotelWithAttractions',
  functional: true,
  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<
          HotelWithAttractionsModule['properties'],
          AvailableLanguage
        >
      >,
      required: true,
    },
  },

  render(h, { props, parent, data }): VNode {
    const { hotel, attractions } = props.value;
    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'h-hotel-with-attractions',
        })}>
        <HHotelIntroduction value={hotel.properties} disableContainer />
        {attractions.properties.children.length > 0 && (
          <HAttractiveIntroductionList
            staticClass="h-hotel-with-attractions__attractions"
            value={attractions.properties}
            disableContainer
          />
        )}
      </div>
    );
  },
});
