import './HFooter.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { HotelBrandBasicInfo } from '~/schemes';
import { HBtn, HIcon } from '~/components';

interface HFooterBrand extends HotelBrandBasicInfo {
  logoSrc: string;
}

export interface HFooterProps {}

export interface HFooterEmits {}

export interface HFooterScopedSlots {}

@Component<HFooterRef>({
  name: 'HFooter',
  render() {
    return (
      <footer
        staticClass="h-footer"
        v-inview={
          this.inviewed
            ? false
            : {
                in: () => {
                  this.inviewed = true;
                },
              }
        }>
        <div staticClass="h-footer__inner">
          <a href={this.$t('url.globalTop') as string}>
            <img
              staticClass="h-footer__logo-hr"
              src={this.logoSrc}
              alt={this.$t('name.hr') as string}
            />
          </a>
          <div staticClass="h-footer__brands">
            {this.brandRows.map((row, index) => {
              return (
                <ul
                  staticClass="h-footer__brands__row"
                  key={`brand-row-${index}`}>
                  {row.map((brand) => {
                    let { url } = brand;
                    if (!url) {
                      url = `/${this.$language.current}/brands/${brand.slug}/`;
                    }
                    const loc = this.$navigation.resolveHrefTo(url);
                    const isRouterLink = !!loc.to;
                    const TagName: any = isRouterLink ? 'nuxt-link' : 'a';
                    const props = isRouterLink ? loc : undefined;
                    const attrs = !isRouterLink
                      ? {
                          href: url,
                          title: brand.name,
                          target: '_blank',
                          rel: 'noopener',
                        }
                      : {
                          title: brand.name,
                        };

                    const $brand = (
                      <li
                        staticClass="h-footer__brands__brand"
                        key={brand.slug}>
                        <TagName
                          staticClass="h-footer__brands__brand__link"
                          props={props}
                          attrs={attrs}>
                          <img
                            staticClass="h-footer__brands__brand__logo"
                            alt={brand.name}
                            src={
                              this.inviewed ? brand.logoSrc : this.$res.emptyGif
                            }
                          />
                        </TagName>
                      </li>
                    );
                    return $brand;
                  })}
                </ul>
              );
            })}
          </div>
          <HBtn
            staticClass="h-footer__btn-to-search"
            size="lg"
            href={this.crossSearchUrl}
            target="_blank"
            rel="noopener"
            color="plain"
            v-ev={{
              category: this.$gfev.Category.Link,
              action: this.crossSearchUrl,
              id: 'research_vacancy_footer',
            }}>
            <HIcon
              staticClass="h-footer__btn-to-search__icon"
              name="arrow-right"
            />
            {this.$t('name.roomFinder')}
          </HBtn>
        </div>
      </footer>
    );
  },
})
export class HFooterRef extends Vue implements HFooterProps {
  private inviewed: boolean = false;

  get hotel() {
    return this.$hotel.current;
  }

  get logoSrc() {
    return this.$res.img(`/common/logo-hr-${this.$language.current}.svg`);
  }

  get brands(): HFooterBrand[] {
    return this.$hotel.brands.map((brand) => {
      return {
        ...brand,
        logoSrc: this.$res.img(`/brands/${brand.slug}/logo.svg`),
      };
    });
  }

  get crossSearchUrl() {
    return this.$language.crossSearchUrl();
  }

  get brandRows(): HFooterBrand[][] {
    const COLUMN = 3;
    const rows: HFooterBrand[][] = [];
    let rowIndex = -1;
    this.brands.forEach((brand, index) => {
      if (index % COLUMN === 0) {
        rowIndex++;
        rows.push([]);
      }
      const row = rows[rowIndex];
      row.push(brand);
    });
    return rows;
  }
}

export const HFooter = tsx
  .ofType<HFooterProps, HFooterEmits, HFooterScopedSlots>()
  .convert(HFooterRef);
