import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelFeatureConceptMovie } from '~/schemes';
import { HBackdrop, HVideoPlayer } from '~/components';
export interface MyHotelFeatureConceptMovieProps {
  conceptMovie: HotelFeatureConceptMovie;
}

/** コンセプトムービーのコンポーネント */
@Component<MyHotelFeatureConceptMovieRef>({
  name: 'MyHotelFeatureConceptMovie',
  render() {
    const { title } = this;
    return (
      <div staticClass="my-hotel-feature-concept-movie">
        {/* HCoverModalを使えそうだけどロジックは自分でも書く */}
        {!this.showVideo && (
          <div
            staticClass="my-hotel-feature-concept-movie__button"
            onClick={this.toggle}>
            <div staticClass="my-hotel-feature-concept-movie__thumbnail">
              {/* 動画のposter */}
              <img
                src={this.conceptMovie.videoPoster!}
                class="my-hotel-feature-concept-movie__thumbnail__poster"
                alt={title}
              />
              {/* プレイヤーマーク */}
              <img
                class="my-hotel-feature-concept-movie__thumbnail__play_circle"
                src={this.$res.img('common/play_circle.svg')}
              />
            </div>
            {/* タイトル */}
            {title && (
              <div staticClass="my-hotel-feature-concept-movie__title">
                {title}
              </div>
            )}
          </div>
        )}
        {/* 動画 */}
        <HBackdrop
          v-model={this.showVideo}
          darken
          scopedSlots={{
            default: () => (
              <HVideoPlayer
                staticClass="my-hotel-feature-concept-movie__video"
                controls
                src={this.conceptMovie.video}
                muted
                autoplay
                loop
              />
            ),
          }}
        />
      </div>
    );
  },
})
export class MyHotelFeatureConceptMovieRef
  extends Vue
  implements MyHotelFeatureConceptMovieProps {
  @Prop({ type: Object, required: true })
  readonly conceptMovie!: HotelFeatureConceptMovie;

  /** コンセプトムービーの表示制御 */
  private showVideo = false;

  toggle() {
    this.showVideo = !this.showVideo;
  }

  /**
   * コンセプトムービータイトル
   */
  get title(): string {
    const { title } = this.conceptMovie;
    if (!title) return '';
    return this.$language.current === 'ja' ? `${title}を見る` : title;
  }
}
export interface MyHotelFeatureConceptMovieEmits {}
export interface MyHotelFeatureConceptMovieScopedSlots {}
export const MyHotelFeatureConceptMovie = tsx
  .ofType<
    MyHotelFeatureConceptMovieProps,
    MyHotelFeatureConceptMovieEmits,
    MyHotelFeatureConceptMovieScopedSlots
  >()
  .convert(MyHotelFeatureConceptMovieRef);
