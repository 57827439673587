import './HLocationMap.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNodeChildren, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  HImageLoupe,
  HImageLoupeRef,
  ImageLoupeState,
  ImageLoupeInitialPosition,
  ImageLoupeModel,
  ApplyChangeOpts,
} from '../HImageLoupe';
import { HLocationMapSpot } from './HLocationMapSpot';
import {
  LocationMap,
  LocationMapSpot,
  extractLocationMapImageURL,
} from '~/schemes';
import { PinchView, IncrementScaleOpts } from '~/libs/pinch-view';
import { waitAnimaitonFrame } from '~/helpers';

/**
 * マップ切り替え時の遷移方向
 *
 * * `<transition-group>` に適用する
 *
 * - `same` 同階層のマップ切り替え
 * - `in` 現在のマップより狭域に入る
 * - `same` 現在のマップより広域に出る
 */
export type LocationMapTransitionVector = 'same' | 'in' | 'out';

/** ロケーションマップの初期表示位置 */
export interface LocationMapInitialPosition extends ImageLoupeInitialPosition {}

/** ガイドメッセージソース */
type GuideSource = string | (() => VNodeChildren | VNode) | null;

/**
 * ガイドメッセージソースをVNodeChildrenに正規化する
 * @param source - ガイドメッセージソース
 */
const resolveGuideSource = (source: GuideSource): VNodeChildren | VNode => {
  if (typeof source === 'function') return source();
  return source;
};

/** ロケーションマップのモデル値 */
export interface LocationMapModel {
  /** 最小スケール */
  minScale: number;
  /** 最大スケール */
  maxScale: number;
  /** スケール率 */
  scale: number;
  /** 現在のビューポートの幅1pxがオーバーレイに対して何px相当かの相対値 */
  widthDiff: number;
  /** 現在のビューポートの高さ1pxがオーバーレイに対して何px相当かの相対値 */
  heightDiff: number;
  /** ビューポートの幅 */
  viewportWidth: number;
  /** ビューポートの高さ */
  viewportHeight: number;
  /** 画像の幅 */
  originalWidth: number;
  /** 画像の高さ */
  originalHeight: number;
  /** 内方要素の幅（例外が発生しない限り画像の幅と同一です） */
  bodyWidth: number;
  /** 内方要素の高さ（例外が発生しない限り画像の高さと同一です） */
  bodyHeight: number;
  /** x座標（画像に対する相対値：0〜1） */
  x: number;
  /** y座標（画像に対する相対値：0〜1） */
  y: number;
}

export interface HLocationMapProps {
  /** ロケーションマップ */
  value: LocationMap;

  /** マップの初期位置 */
  initialPosition?: LocationMapInitialPosition;
}

export interface HLocationMapEmits {
  onInit: LocationMapModel;
  onUpdate: LocationMapModel;
  onLoad: HLocationMapRef;
  onError: any;
  onClickMap: MouseEvent;
  onChangeActivatedSpot: string | null;
}

export interface HLocationMapScopedSlots {}

@Component<HLocationMapRef>({
  name: 'HLocationMap',
  provide() {
    return {
      map: this,
    };
  },
  render(h) {
    const { imageURL, sortedSpots } = this;

    return (
      <div
        staticClass="h-location-map"
        class={[`h-location-map--${this.state}`]}
        onClick={this.handleClickHost}>
        {!!imageURL && (
          <HImageLoupe
            staticClass="h-location-map__loupe"
            ref="loupe"
            src={imageURL}
            initialPosition={this.initialPosition}
            onInit={this.handleLoupeInit}
            onLoad={this.handleLoupeLoad}
            onError={this.handleLoupeLoadError}
            onChangeState={this.handleChangeLoupeState}
            onUpdate={this.handleLoupeUpdate}
            onClickImage={this.handleClickLoupeImage}
            onBlockScale={this.handleBlockScale}
            onBlockMove={this.handleBlockMove}
            onMoveActivated={this.handleMoveActivated}
            onPointerMove={this.handlePointerMove}
            scopedSlots={{
              overlay: () => (
                <div class="h-location-map__overlay">
                  {sortedSpots.map((spot) => (
                    <HLocationMapSpot
                      staticClass="h-location-map__overlay__spot"
                      value={spot}
                      onClickMarker={(ev) => this.handleClickSpot(spot, ev)}
                      onClickClose={(ev) => this.handleClickSpotClose(spot, ev)}
                      active={this.activatedSpotKey === spot.key}
                    />
                  ))}
                </div>
              ),
              cover: (loupe) => {
                const message = this.resolvedGuideMessage;
                return (
                  <div>
                    <transition name="fade">
                      <div class="h-location-map__guide" v-show={!!message}>
                        {message}
                      </div>
                    </transition>
                  </div>
                );
              },
            }}
          />
        )}
      </div>
    );
  },
})
export class HLocationMapRef extends Vue implements HLocationMapProps {
  $refs!: {
    loupe: HImageLoupeRef;
  };

  @Prop({ type: Object, required: true }) readonly value!: LocationMap;
  @Prop(Object) readonly initialPosition?: LocationMapInitialPosition;

  /** 状態（ImageLoupeと同期している） */
  private internalState: ImageLoupeState = 'pending';

  /** アクティブになっているスポットのキー */
  private internalActivatedSpotKey: string | null = null;

  /** ロケーションマップのモデル値 */
  private internalModel: LocationMapModel | null = null;

  /** ガイドメッセージ */
  private guideMessage: GuideSource = null;

  /** ガイドメッセージ非表示タイマーID */
  private guideCloseTimerId: number | null = null;

  /** 状態（ImageLoupeと同期している） */
  get state() {
    return this.internalState;
  }

  /** 読み込み中か */
  get isLoading() {
    return this.state === 'loading';
  }

  /** アクティブになっているスポットのキー */
  get activatedSpotKey() {
    return this.internalActivatedSpotKey;
  }

  /** 画像ソース */
  get imageURL() {
    return extractLocationMapImageURL(this.value);
  }

  /** 逆順にソート済みのスポットリスト */
  get sortedSpots() {
    const spots = this.value.spots.slice();
    return spots.reverse();
  }

  /** 有効なスポットキーのリスト */
  get avairableSpotKeys() {
    return this.sortedSpots.map((spot) => spot.key);
  }

  /**  VNodeChildrenに解決済みのガイドメッセージ */
  get resolvedGuideMessage() {
    return resolveGuideSource(this.guideMessage);
  }

  /** ロケーションマップのモデル値 */
  get model() {
    return this.internalModel;
  }

  /**
   * 指定にスポットキーが有効なスポットキーかチェックする
   *
   * @param spotKey - スポットキー
   * @returns 有効な場合true
   */
  isAvairableSpotKey(spotKey: string) {
    return this.avairableSpotKeys.includes(spotKey);
  }

  /**
   * 指定のスポットキーに対応するスポットをアクティブにする
   * @param spotKey - スポットキー
   * @returns
   */
  activateSpot(spotKey: string) {
    if (
      this.internalActivatedSpotKey === spotKey ||
      !this.isAvairableSpotKey(spotKey)
    )
      return;
    this.internalActivatedSpotKey = spotKey;
    this.$emit('changeActivatedSpot', spotKey);
  }

  /**
   * アクティブになっているかもしれないスポットを削除する
   *
   * @param spotKey - スポットキーを指定した場合、現在のアクティブなスポットキーがこれと一致していなければ解除をキャンセルする
   */
  deactivateSpot(spotKey?: string) {
    if (spotKey && this.internalActivatedSpotKey !== spotKey) return;
    this.internalActivatedSpotKey = null;
    this.$emit('changeActivatedSpot', null);
  }

  /**
   * スポットのアクティブ状態をトグルする
   *
   * @param spotKey - スポットキー
   */
  toggleSpot(spotKey: string) {
    if (spotKey && this.activatedSpotKey === spotKey) {
      this.deactivateSpot();
    } else {
      this.activateSpot(spotKey);
    }
  }

  /**
   * マップ遷移トランジションを適用する
   *
   * @param type - 遷移方向
   * @param mode - アニメーション方向
   */
  transitionTo(type: LocationMapTransitionVector, mode: 'enter' | 'leave') {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      try {
        const $el = this.$el as HTMLElement & { _teh_?: () => void };
        $el._teh_ && $el._teh_();
        $el._teh_ = handleTransitionEnd;

        const removeListener = () => {
          $el.removeEventListener('transitionend', listener, false);
        };

        function handleTransitionEnd() {
          delete $el._teh_;
          removeListener();
          resolve();
        }

        function listener(ev: TransitionEvent) {
          if ($el._teh_ === handleTransitionEnd) {
            handleTransitionEnd();
          } else {
            removeListener();
          }
        }

        const isEnter = mode === 'enter';
        const DURATION = 500;

        const opacityFrom = isEnter ? 0 : 1;
        const opacityTo = mode === 'enter' ? 1 : 0;
        let scaleFrom = 1;
        let scaleTo = 1;

        if (type === 'in') {
          scaleFrom = isEnter ? 0.5 : 1;
          scaleTo = isEnter ? 1 : 1.5;
        } else if (type === 'out') {
          scaleFrom = isEnter ? 1.5 : 1;
          scaleTo = isEnter ? 1 : 0.5;
        }

        $el.addEventListener('transitionend', listener, false);
        $el.style.opacity = String(opacityFrom);
        $el.style.transform = `scale(${scaleFrom})`;

        await waitAnimaitonFrame(2);

        $el.style.transition = `transform ${DURATION}ms, opacity ${DURATION}ms`;

        await waitAnimaitonFrame(2);
        $el.style.opacity = String(opacityTo);
        $el.style.transform = `scale(${scaleTo})`;
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * スケール率をインクリメントする
   * @param opts - スケールインクリメント（デクリメント）オプション
   */
  incrementScale(opts?: IncrementScaleOpts) {
    const { loupe } = this.$refs;
    if (!loupe) return;
    loupe.incrementScale({
      transition: true,
      allowChangeEvent: true,
      ...opts,
    });
  }

  /**
   * スケール率をデクリメントする
   * @param opts - スケールインクリメント（デクリメント）オプション
   */
  decrementScale(opts?: IncrementScaleOpts) {
    const { loupe } = this.$refs;
    if (!loupe) return;
    loupe.decrementScale({
      allowChangeEvent: true,
      transition: true,
      ...opts,
    });
  }

  /**
   * ルーペコンポーネントのステージ要素を取得する
   *
   * @returns ステージ要素
   */
  getStageElement(): HTMLElement | undefined {
    const { loupe } = this.$refs;
    return loupe && loupe.getStageElement();
  }

  /**
   * ガイドメッセージを非表示にする
   */
  closeGuide() {
    this.clearCloseGuideTimer();
    this.guideMessage = null;
  }

  /**
   * ガイドメッセージを表示する
   *
   * @param source - ガイドメッセージソース
   */
  showGuide(source: GuideSource) {
    this.clearCloseGuideTimer();
    this.guideMessage = source;
    this.guideCloseTimerId = window.setTimeout(this.closeGuide, 2000);
  }

  /**
   * ホスト要素クリック時のハンドラ
   *
   * ひとまずガイドメッセージを非表示にする
   *
   * @param ev - マウスイベント
   */
  private handleClickHost(ev: MouseEvent) {
    this.closeGuide();
  }

  /**
   * ガイドメッセージ非表示タイマーIDをクリアする
   */
  private clearCloseGuideTimer() {
    if (this.guideCloseTimerId !== null) {
      window.clearTimeout(this.guideCloseTimerId);
      this.guideCloseTimerId = null;
    }
  }

  /**
   * スポットマーカークリック時のハンドラ
   *
   * @param spot - スポット
   * @param ev - マウスイベント
   */
  private handleClickSpot(spot: LocationMapSpot, ev: MouseEvent) {
    this.toggleSpot(spot.key);
  }

  /**
   * スポットクローズクリック時のハンドラ
   *
   * @param spot - スポット
   * @param ev - マウスイベント
   */
  private handleClickSpotClose(spot: LocationMapSpot, ev: MouseEvent) {
    this.deactivateSpot(spot.key);
  }

  /**
   * 画像ルーペの状態が変更された時のハンドラ
   * @param loupeState - 画像ルーペの状態
   */
  private handleChangeLoupeState(loupeState: ImageLoupeState) {
    this.internalState = loupeState;
  }

  /**
   * 指定のルーペモデルを元に内部モデルを更新する
   * @param loupeModel - 画像ルーペモデル
   */
  private updateInternalModel(loupeModel: ImageLoupeModel) {
    this.internalModel = loupeModelToLocationMapModel(loupeModel);
  }

  /**
   * 画像ルーペ初期化時のハンドラ
   * @param loupeModel - 画像ルーペのモデル
   */
  private handleLoupeInit(loupeModel: ImageLoupeModel) {
    this.updateInternalModel(loupeModel);
    this.$emit('init', this.model);
    this.$emit('update', this.model);
  }

  /**
   * 画像ルーペロード時のハンドラ
   * @param loupeModel - 画像ルーペのモデル
   */
  private handleLoupeLoad(loupe: HImageLoupeRef) {
    this.$emit('load', this);
  }

  /**
   * 画像ルーペロード失敗時のハンドラ
   * @param loupeModel - 画像ルーペのモデル
   */
  private handleLoupeLoadError(err: any) {
    this.$emit('error', err);
  }

  /**
   * 画像ルーペモデル更新時のハンドラ
   * @param loupeModel - 画像ルーペのモデル
   */
  private handleLoupeUpdate(loupeModel: ImageLoupeModel) {
    this.updateInternalModel(loupeModel);
    this.$emit('update', this.model);
  }

  /**
   * 画像ルーペ内の画像クリック時のハンドラ
   * @param ev - マウスイベント
   */
  private handleClickLoupeImage(ev: MouseEvent) {
    this.$emit('clickMap', ev);
  }

  /**
   * ピンチビューでスケールがブロックされた時のハンドラ
   * @param pinchView - ピンチビュー
   */
  private handleBlockScale(pinchView: PinchView) {
    const isMac = navigator.userAgent.includes('Mac');
    const metaKey = isMac
      ? this.$t('locationMaps.scaleMetaKeyForMac')
      : // <VIcon name="mdi-apple-keyboard-command" />
        this.$t('locationMaps.scaleMetaKey');
    this.showGuide(() => (
      <div>{this.$t('locationMaps.zoomGuide', { metaKey })}</div>
    ));
  }

  /**
   * ピンチビューでポインター移動がブロックされた時のハンドラ
   * @param pinchView - ピンチビュー
   */
  private handleBlockMove(pinchView: PinchView) {
    this.showGuide(this.$t('locationMaps.touchDevicePanMap') as string);
  }

  /**
   * ピンチビューでタッチデバイスのジェスチャー移動が許可された時のハンドラ
   * @param pinchView - ピンチビュー
   */
  private handleMoveActivated(pinchView: PinchView) {
    this.closeGuide();
  }

  /**
   * ピンチビューで内方要素（マップ画像）の座標移動が行われた時のハンドラ
   * @param pinchView - ピンチビュー
   */
  private handlePointerMove(ev: ApplyChangeOpts) {
    this.deactivateSpot();
  }
}

export const HLocationMap = tsx
  .ofType<HLocationMapProps, HLocationMapEmits, HLocationMapScopedSlots>()
  .convert(HLocationMapRef);

/**
 * 画像ルーペモデルからビューポート上での1pxの移動量のパーセンテージを生成する
 *
 * @param model - 画像ルーペモデル
 */
function calcSacleDiff(model: ImageLoupeModel) {
  const { overlayWidth, overlayHeight, viewportWidth, viewportHeight } = model;
  return {
    widthDiff: viewportWidth / overlayWidth,
    heightDiff: viewportHeight / overlayHeight,
  };
}

/**
 * 画像ルーペモデルからロケーションマップのモデル値を生成する
 */
function loupeModelToLocationMapModel(
  loupeModel: ImageLoupeModel,
): LocationMapModel {
  const {
    minScale,
    maxScale,
    scale,
    centerX,
    centerY,
    viewportWidth,
    viewportHeight,
    bodyWidth,
    bodyHeight,
    originalWidth,
    originalHeight,
  } = loupeModel;

  return {
    minScale,
    maxScale,
    scale,
    ...calcSacleDiff(loupeModel),
    x: centerX,
    y: centerY,
    viewportWidth,
    viewportHeight,
    bodyWidth,
    bodyHeight,
    originalWidth,
    originalHeight,
  };
}
