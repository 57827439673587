import Vue from 'vue';
import { ComputedBrandLinkItem } from './schemes';
import { HotelBrandBasicInfo } from '~/schemes';

/**
 * ブランドのリンクリストを生成する
 *
 * @param vm - Vueインスタンス
 * @param brand - ブランド基本情報
 * @returns - リンクのリスト
 */
export function createBrandLinks(
  vm: Vue,
  brand: HotelBrandBasicInfo,
): ComputedBrandLinkItem[] {
  const links: ComputedBrandLinkItem[] = [];
  if (brand.urlText) {
    const brandURL =
      brand.url || `/${vm.$language.current}/brands/${brand.slug}/`;
    const parsed = vm.$navigation.parseURL(brandURL, {
      title: brand.urlText,
    });
    links.push({
      key: '_brandSite',
      ...parsed,
      children: brand.urlText,
    });
  }

  /** ブランド設定で並び替えされたあとの後の施設リスト */
  const sortedHotels = vm.$hotel.hotelsByBrandId(brand.id);

  // 施設のリストから、リンクをどんどこ抽出する
  sortedHotels.forEach((hotel) => {
    // まず有効言語設定を探す
    const langSettings = hotel.availableLanguages.find(
      (lang) => lang.id === vm.$language.current,
    );

    if (!langSettings) {
      // 現在の言語で公開されていなければリンクを表示しない
      return;
    }

    const hotelURL =
      langSettings.external || `/${vm.$language.current}/hotels/${hotel.slug}/`;
    const parsed = vm.$navigation.parseURL(hotelURL, {
      title: hotel.name,
    });
    links.push({
      key: hotel.slug,
      ...parsed,
      children: hotel.name,
    });
  });

  return links;
}
