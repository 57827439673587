import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  HFormNode,
  HFormNodeProps,
  HFormNodeEmits,
  HFormNodeScopedSlots,
} from './HFormNode';
import { toInt } from '~/helpers';

export interface HFormValueProps extends HFormNodeProps {
  label?: string;
  tabindex?: string | number;
}

export interface HFormValueEmits extends HFormNodeEmits {}

export interface HFormValueScopedSlots extends HFormNodeScopedSlots {}

@Component({
  name: 'HFormValue',
})
export class HFormValue extends Mixins(HFormNode) implements HFormValueProps {
  @Prop({ type: String }) readonly label?: string;
  @Prop({ type: [String, Number], default: 0 }) readonly tabindex!:
    | string
    | number;

  private internalFocused: boolean = false;

  get computedTabIndex() {
    return toInt(this.isDisabled || this.isReadonly ? '-1' : this.tabindex);
  }

  get focused(): boolean {
    return this.internalFocused;
  }

  protected onNodeFocus() {
    this.internalFocused = true;
  }

  protected onNodeBlur() {
    this.internalFocused = false;
    if (this.isDisabled || this.isReadonly) return;
    if (this.validateTimingIsTouch || this.validateTimingIsBlur) {
      this.shouldValidate = true;
      this.validateSelf();
    }
  }

  protected focusNode() {}
  protected blurNode() {}
  protected getValue() {}

  focus() {
    this.focusNode();
    this.internalFocused = true;
  }

  blur() {
    this.blurNode();
    this.internalFocused = false;
  }
}
