import './HHeading.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, HeadingModule } from '~/schemes';
import { mergeVNodeData } from '~/helpers';
import { DecorationSize, HThemeDecoration } from '~/components';
import { HeadingLevel } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

type DotsSizeMap = Record<HeadingLevel, DecorationSize>;
const DOTS_SIZE_MAP: DotsSizeMap = { '1': 'lg', '2': 'md', '3': 'sm' };

export const HHeading = tsx.component({
  name: 'HHeading',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<HeadingModule['properties'], AvailableLanguage>
      >,
      required: true,
    },

    /**
     * コンテナクラスの付与を無効にする場合に指定する
     */
    disableContainer: Boolean,
  },

  render(h, { props, data }): VNode {
    const { body, level, align } = props.value;
    const TagName = `h${level}` as const;

    return (
      <div
        {...mergeVNodeData(data, {
          class: [
            !props.disableContainer && 'h-container',
            `v-heading-module`,
            `v-heading-module--${align}`,
            `v-heading-module--level-${level}`,
          ],
        })}>
        <TagName class="v-heading-module__text">
          {(body && body.text) || ''}
        </TagName>
        {align === 'center' && (
          <HThemeDecoration
            class="v-heading-module--theme-decoration"
            size={DOTS_SIZE_MAP[level]}></HThemeDecoration>
        )}
      </div>
    );
  },
});
