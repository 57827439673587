import { AsyncFn } from './schemes';
import { AsyncCombineRequest } from './request';
import { tinyObjectToHash } from './utils';

/**
 * 非同期処理リクエストバケット
 */
export class AsyncCombineRequestBucket {
  private _requestMap: Record<string, AsyncCombineRequest> = {};

  /**
   * 指定のハッシュに対応する非同期処理リクエストを取得する
   *
   * @param hash - 実行時引数ハッシュ
   * @param func - 非同期処理本体
   * @returns 非同期処理リクエスト
   */
  getByHash(hash: string, func: AsyncFn) {
    let request = this._requestMap[hash];
    if (!request) {
      request = new AsyncCombineRequest(this, hash, func);
      this._requestMap[hash] = request;
    }
    return request;
  }

  /**
   * 指定の実行時引数リストに対応する非同期処理リクエストを取得する
   *
   * @param args - 実行時引数リスト
   * @param func - 非同期処理本体
   * @returns 非同期処理リクエスト
   */
  getByArgs(args: any[], func: AsyncFn) {
    const hash = tinyObjectToHash(args);

    let request = this._requestMap[hash];
    if (!request) {
      request = new AsyncCombineRequest(this, hash, () => func(...args));
      this._requestMap[hash] = request;
    }
    return request;
  }

  removeByHash(hash: string) {
    delete this._requestMap[hash];
  }
}
