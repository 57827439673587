import { APP_RESOURCE_PATH } from '../src/.dynamic/commons';

export const GTM_LAYER_NAME = 'GF_dataLayer';

// eslint-disable-next-line no-undef
export interface AppProcessEnv extends NodeJS.ProcessEnv {
  GF_ORIGIN?: string;
  YGETS_ORIGIN?: string;
  WEBPACK_WATCH_POLL?: string;
  WEBPACK_WATCH_AGGREGATE_TIMEOUT?: string;
  GF_DEVELOPMENT?: string;
  DATA_CACHE_S3_BUCKET_NAME?: string;
}

export const YGETS_API_PROXY_PREFIX = 'api';

export enum AppEnvName {
  Test = 'test',
  Staging = 'staging',
  Production = 'production',
}

export const GTM_SETTINGS = {
  [AppEnvName.Test]: 'GTM-WSMN96V',
  [AppEnvName.Staging]: 'GTM-WJVLF8B',
  [AppEnvName.Production]: 'GTM-MDGZBP',
};

/**
 * nuxt.config で設定するenvオブジェクト
 */
export interface NuxtConfigEnv {
  name: AppEnvName;
  origin: string;
  ygetsOrigin: string;
  ygetsApiProxyPrefix: string;
  isDevelop: string;
  isTest: string;
  isStaging: string;
  isProduction: string;
  resourcePath: string;
  gtmId: string;
  gtmLayerName: string;
  buildTime: string;
  dataBucket: string;
  API_ALB_AUTH_TOKEN: string;
  GF_API_ORIGIN: string;
  GF_API_ALB_AUTH_TOKEN: string;
}

/**
 * アプリケーション実装内で参照する環境設定のオブジェクト
 */
export interface AppEnvObject {
  name: AppEnvName;
  origin: string;
  ygetsOrigin: string;
  gfAPIOrigin: string;
  ygetsApiProxyPrefix: string;
  isDevelop: boolean;
  isTest: boolean;
  isStaging: boolean;
  isProduction: boolean;
  resourcePath: string;
  gtmId: string;
  gtmLayerName: string;
  buildTime: string;
  dataBucket: string;
  dataVersion: string;
  renderingDateTime: string;
}

/**
 * Webpackのwatch設定
 */
export interface WebpackWatchOptions {
  aggregateTimeout?: number;
  poll?: boolean;
  ignored?: any;
}

export interface NuxtConfigSetting {
  env: NuxtConfigEnv;
  webpackWatchOptions: WebpackWatchOptions | undefined;
  IS_DEVELOPMENT: boolean;
  IS_PRODUCTION: boolean;
  YGETS_API_PROXY_PREFIX: string;
}

function appProcessEnvToWebpackWatchOptions(
  processEnv: AppProcessEnv,
): WebpackWatchOptions | undefined {
  const {
    WEBPACK_WATCH_POLL: _poll,
    WEBPACK_WATCH_AGGREGATE_TIMEOUT: _timeout,
  } = processEnv;
  const poll = boolableStringToBool(_poll);
  if (!poll) {
    return undefined;
  }
  const aggregateTimeout =
    typeof _timeout === 'string' ? parseInt(_timeout, 10) : 300;
  return {
    poll,
    aggregateTimeout,
    ignored: /node_modules/,
  };
}

export function appProcessEnvToNuxtConfigEnv(
  processEnv: AppProcessEnv,
): NuxtConfigEnv {
  const {
    GF_ORIGIN = '/',
    // YGETS_ORIGIN = 'http://booking-test.hoshinoresort.com',
    // YGETS_ORIGIN = 'http://booking-staging.hoshinoresort.com',
    YGETS_ORIGIN = 'http://booking.hoshinoresort.com',
    buildTime = String(Date.now()),
    API_ALB_AUTH_TOKEN,
    GF_API_ORIGIN,
    GF_API_ALB_AUTH_TOKEN,
  } = processEnv;

  let name: AppEnvName;
  if (GF_ORIGIN === 'https://hoshinoresorts.com') {
    name = AppEnvName.Production;
  } else if (GF_ORIGIN === 'https://stg.hoshinoresorts.com') {
    name = AppEnvName.Staging;
  } else {
    name = AppEnvName.Test;
  }

  return {
    name,
    origin: GF_ORIGIN,
    ygetsOrigin: YGETS_ORIGIN,
    ygetsApiProxyPrefix: YGETS_API_PROXY_PREFIX,
    isDevelop: processEnv.GF_DEVELOPMENT || '0',
    isTest: boolToBoolableString(name === AppEnvName.Test),
    isStaging: boolToBoolableString(name === AppEnvName.Staging),
    isProduction: boolToBoolableString(name === AppEnvName.Production),
    resourcePath: APP_RESOURCE_PATH,
    gtmId: GTM_SETTINGS[name],
    gtmLayerName: GTM_LAYER_NAME,
    buildTime,
    dataBucket: '.gf-data',
    API_ALB_AUTH_TOKEN: API_ALB_AUTH_TOKEN || '',
    GF_API_ORIGIN: GF_API_ORIGIN || '',
    GF_API_ALB_AUTH_TOKEN: GF_API_ALB_AUTH_TOKEN || '',
  };
}

export function appProcessEnvToNuxtConfigSetting(
  processEnv: AppProcessEnv,
): NuxtConfigSetting {
  const { NODE_ENV } = processEnv;
  const IS_DEVELOPMENT = NODE_ENV === 'development';
  const IS_PRODUCTION = NODE_ENV === 'production';
  return {
    env: appProcessEnvToNuxtConfigEnv(processEnv),
    webpackWatchOptions: appProcessEnvToWebpackWatchOptions(processEnv),
    IS_DEVELOPMENT,
    IS_PRODUCTION,
    YGETS_API_PROXY_PREFIX,
  };
}

function boolableStringToBool(str: string | null = '0'): boolean {
  if (str == null) str = '0';
  const lower = str.toLowerCase();
  return lower === '1' || lower === 'true';
}

function boolToBoolableString(bool: boolean): string {
  return bool ? '1' : '0';
}

export function nuxtConfigEnvToAppEnvObject(
  _env?: NuxtConfigEnv,
): AppEnvObject {
  const name = _env ? _env.name : process.env.name;
  const origin = _env ? _env.origin : process.env.origin;
  const gfAPIOrigin = _env ? _env.GF_API_ORIGIN : process.env.GF_API_ORIGIN;
  const ygetsOrigin = _env ? _env.ygetsOrigin : process.env.ygetsOrigin;
  const ygetsApiProxyPrefix = _env
    ? _env.ygetsApiProxyPrefix
    : process.env.ygetsApiProxyPrefix;
  const isDevelop = _env ? _env.isDevelop : process.env.isDevelop;
  const isTest = _env ? _env.isTest : process.env.isTest;
  const isStaging = _env ? _env.isStaging : process.env.isStaging;
  const isProduction = _env ? _env.isProduction : process.env.isProduction;
  const resourcePath = _env ? _env.resourcePath : process.env.resourcePath;
  const buildTime =
    (_env ? _env.buildTime : process.env.buildTime) || String(Date.now());

  return {
    name: name as any,
    origin: origin as any,
    gfAPIOrigin: gfAPIOrigin as any,
    ygetsOrigin: ygetsOrigin as any,
    ygetsApiProxyPrefix: ygetsApiProxyPrefix as any,
    isDevelop: isDevelop === '1',
    isTest: boolableStringToBool(isTest),
    isStaging: boolableStringToBool(isStaging),
    isProduction: boolableStringToBool(isProduction),
    resourcePath: resourcePath as any,
    gtmId: GTM_SETTINGS[name as any],
    gtmLayerName: GTM_LAYER_NAME,
    buildTime,
    dataBucket: '.gf-data',
    /** この時点でstoreが初期化できていないため空 */
    dataVersion: '',
    renderingDateTime: '',
  };
}
