import './HThemeDecoration.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { mergeVNodeData } from '~/helpers';

/**
 * デコレーションのサイズ型
 *
 * * `"lg"` を指定した場合、グラデーションが自動採用されます。このグラデーションにはclip-pathを利用していて、そのパスのsvgは app.html に実装されています
 */
export type DecorationSize = 'sm' | 'md' | 'lg';

/**
 * テーマの飾り
 *
 * 現状リゾナーレの3点ドットのみ
 */
export const HThemeDecoration = tsx.component({
  name: 'HThemeDecoration',
  functional: true,
  props: {
    size: {
      type: String as PropType<DecorationSize>,
      default: 'md',
    },
  },
  render(h, { props, data, parent }) {
    if (!parent.$theme.is('risonare')) return h(undefined, { key: 'empty ' });

    const { size } = props;

    /** ドットの色にグラデーションを使用するか */
    const useGradient = size === 'lg';

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'h-theme-decoration',
          class: [
            `h-theme-decoration--${size}`,
            {
              'h-theme-decoration--gradient': useGradient,
            },
          ],
        })}>
        {!useGradient && [
          <span staticClass="h-theme-decoration__item"></span>,
          <span staticClass="h-theme-decoration__item"></span>,
          <span staticClass="h-theme-decoration__item"></span>,
        ]}
      </div>
    );
  },
});
