import './HBannerLink.scss';
import * as tsx from 'vue-tsx-support';
import { VNode } from 'vue';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  HBannerLinkProps,
  HBannerLinkEmits,
  HBannerLinkScopedSlots,
} from './schemes';
import { RoutableMixin } from '~/mixins/routeable';
import { normalizeAnyMediaSource } from '~/schemes';

/**
 * シンプルなバナーリンク
 */
@Component<HBannerLinkRef>({
  name: 'HBannerLink',

  render(h) {
    const { generatedRouteLink, titleSettings } = this;
    const { tag, data } = generatedRouteLink;
    const { domProps, children } = titleSettings;

    return h(tag, data, [
      <span staticClass="h-banner-link__title" domProps={domProps}>
        {children}
      </span>,
    ]);
  },
})
export class HBannerLinkRef
  extends Mixins(RoutableMixin)
  implements HBannerLinkProps {
  @Prop([Object, String]) readonly src!: HBannerLinkProps['src'];
  @Prop([Object, String]) readonly title!: HBannerLinkProps['title'];
  @Prop(Boolean)
  readonly excludeDecoration?: HBannerLinkProps['excludeDecoration'];

  /**
   * 画像URL
   */
  get imageURL() {
    return (this.src && normalizeAnyMediaSource(this.src)) || null;
  }

  /**
   * 生成済みのリンク用のデータ
   */
  get generatedRouteLink() {
    const { imageURL } = this;
    return this.generateRouteLink({
      staticClass: 'h-banner-link',
      class: { 'h-banner-link--excludeDecoration': this.excludeDecoration },
      style: imageURL
        ? {
            '--bannerImage': `url(${imageURL})`,
          }
        : undefined,
    });
  }

  /**
   * タイトルを描画するためのVNodeの設定を取得する
   *
   * * stringだった場合は、innerHTMLに食わせるためのアトリビュートに変換する
   */
  get titleSettings(): {
    domProps?: {
      innerHTML: string;
    };
    children?: VNode[];
  } {
    const title = this.getNormalizedTitle();
    return typeof title === 'string'
      ? {
          domProps: {
            innerHTML: title,
          },
        }
      : {
          children: title,
        };
  }

  /**
   * スロットで渡されたタイトルか、propで渡されたタイトルを返却する
   */
  private getNormalizedTitle() {
    const { title } = this;
    const titleSlot = this.$scopedSlots.title;
    return (titleSlot && titleSlot(this)) || title || undefined;
  }
}

export const HBannerLink = tsx
  .ofType<HBannerLinkProps, HBannerLinkEmits, HBannerLinkScopedSlots>()
  .convert(HBannerLinkRef);
