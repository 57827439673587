import { BannerLink } from './general';
import { HotelDetail } from './hotel';
import { AnyMediaSource } from './media';
import { BannerSettingPages } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';
export type { CacheJSONBase } from '~/server-middleware/data-server/cache';

/**
 * 金額フォーマット
 */
export interface ChargeFormat {
  /**
   * 接頭辞
   * 金額フォーマットの接頭辞を設定する。(未設定時は空文字を設定する。）
   * (例) ￥
   */
  prefix: string;

  /**
   * 末尾辞
   * 金額フォーマットの末尾辞を設定する。(未設定時は空文字を設定する。）
   * (例) 円
   */
  suffix: string;

  /**
   * フォーマット
   * クライアントが金額表示変更可能なフォーマットを設定する。
   * (例) 0,0
   * @see: http://numeraljs.com/#format
   */
  format: string;
}

/**
 * レート情報
 */
export interface RateInfo {
  /**
   * 対象為替
   * (例) USD
   */
  quote: string;

  /**
   * レート
   * (例) 0.00812
   */
  rate: string;
}

export interface ExchangeRate {
  /**
   * 為替
   */
  base: string;

  /**
   * 表示名
   * (例) USドル
   */
  name: string;

  /**
   * 金額フォーマット
   */
  chargeFormat: ChargeFormat;

  /**
   * レートリスト
   */
  rateList: RateInfo[];
}

/**
 * 空室検索条件
 * @memo
 *   横断検索金額付き月間カレンダー取得
 *   /crosssearch/vacancies/monthly
 */
export interface VacancySearchConditions {
  /**
   * 拍数
   */
  stayLength: number;

  /**
   * 大人人数
   */
  adult: number;

  /**
   * 11歳以下
   */
  underTwelve: number;

  /**
   * 6歳以下
   */
  underSeven: number;

  /**
   * 3歳以下
   */
  underFour: number;
}

export function createDefaultVacancySearchConditions(
  hotel?: HotelDetail,
): VacancySearchConditions {
  const defaultValues: VacancySearchConditions =
    hotel && hotel.ygets
      ? {
          stayLength: hotel.ygets.hotelNightDefault,
          adult: hotel.ygets.adultNumDefault,
          underTwelve: hotel.ygets.childNumDefault,
          underSeven: hotel.ygets.infantNumDefault,
          underFour: hotel.ygets.babyNumDefault,
        }
      : {
          stayLength: 2,
          adult: 2,
          underTwelve: 0,
          underSeven: 0,
          underFour: 0,
        };
  return defaultValues;
}

/**
 * 空室情報1日分
 */
export interface VacancyInfo {
  /**
   * yyyy/MM/dd
   */
  date: string;

  /**
   * 空室数
   */
  vacancy: number;

  /**
   * 祝日フラグ
   */
  holiday: boolean;

  /**
   * 休館日フラグ
   */
  closedDay: boolean;

  /**
   * 料金情報
   */
  charge: {
    /**
     * 検索料金情報
     */
    searchChargeDetail: {
      /**
       * 明細
       * e.g. 7350
       */
      unitCharge: number;

      /**
       * 明細文字列
       * e.g. ￥7,350
       */
      unitChargeStr: string;

      /**
       * 明細プレフィックス
       * e.g. 1名初日
       */
      unitPrefix: string;

      /**
       * 明細サフィックス
       * e.g. （税別）
       */
      unitSuffix: string;
    };
  };
}

/**
 * HBannerLinkをレンダリングするために事前計算した情報
 */
export interface BannerWithProps {
  /** バナーリンクコンポーネントに渡すプロパティ */
  banner: BannerLink;

  /**
   * 計算済みのブレイクポイント毎のsrc
   *
   * * lg、md、sm、横長バナーのどれもがなければnull（画像表示なし）とする
   */
  media: {
    lg?: AnyMediaSource | null | undefined;
    md?: AnyMediaSource | null | undefined;
    sm?: AnyMediaSource | null | undefined;
    '670x170'?: AnyMediaSource | null | undefined;
  } | null;
}

/**
 * 最大表示バナー数
 * @remarks
 * - TOP: 5
 * - 連絡先ページ: 2
 * - ハンバーガーメニュー: 2
 */
export const MAX_BANNER_COUNT_MAP: Record<BannerSettingPages, number> = {
  top: 5,
  contact: 2,
  menu: 2,
};
