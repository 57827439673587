import Vue, { RenderContext } from 'vue';
import { Store } from 'vuex/types';
import { Plugin } from '@nuxt/types';

import { nuxtConfigEnvToAppEnvObject, AppEnvObject } from '../../config/env';

declare module 'vue/types/vue' {
  export interface Vue {
    $env: AppEnvObject;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $env: AppEnvObject;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $env: AppEnvObject;
  }
}

const plugin: Plugin = (context, inject) => {
  const env = {
    ...nuxtConfigEnvToAppEnvObject(),
    dataVersion: process.env.dataVersion as string,
    renderingDateTime: process.browser
      ? context.store.state.renderingDateTime
      : new Date().toISOString(),
  };
  context.$env = env;
  inject('env', env);
};

export default plugin;
