import './HVideoPlayer.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  createHlsController,
  HlsController,
  canPlayNativeHls,
  VIDEO_EVENTS,
  HlsErrorData,
} from './hlsjs';
import { VideoPlayerManager } from './manager';
import {
  InviewDirectiveSettings,
  InviewDirectiveValues,
} from '~/directives/inview';
import { loadImageSize, ImageDimension } from '~/helpers';

export type HVideoPlayerFit =
  | 'fill' // video要素をボックスサイズに合わせて縦横比を維持しないでリサイズして、全体が見えるようにはめ込む
  | 'contain' // video要素をボックスサイズに合わせて縦横比を維持しながらリサイズして、全体が見えるようにはめ込む
  | 'cover' // video要素をボックスサイズに合わせて縦横比を維持しながらリサイズして、トリミングしてはめ込む（デフォルト）
  | 'none' // videoをリサイズしないで、ボックスサイズでトリミングしてはめ込む
  | 'scale-down'; // video要素のサイズとボックスサイズの小さい方に合わせて、縦横比を維持しながらリサイズして、全体が見えるようにはめ込む。 言い換えれば、指定するボックスサイズと置換要素の実寸サイズの大小関係に応じて contain または none を指定したときと同じ表示となる

export type HVideoPlayerLoadState = 'pending' | 'loading' | 'loaded';

export interface HVideoPlayerProps {
  /**
   * 動画のソースURL
   * hls.js
   */
  src?: string | null;

  /**
   * コントロールを表示する場合true
   */
  controls?: boolean;

  /**
   * 自動再生を行う場合true
   * ※ この属性をtrueに指定した場合、ブラウザ制限を回避するため、`muted` 属性が自動的にtrueに設定されます
   * ※ この属性がtrueに指定されている時は、他動画再生検知による自動停止は行われません
   */
  autoplay?: boolean;

  /**
   * 音声をミュートするばあいtrue
   * ※ `autoplay` をtrueが設定されている場合、ブラウザ制限を回避するため、この属性も自動的にtrueに設定されます
   */
  muted?: boolean;

  /**
   * ループ再生を行う場合true
   */
  loop?: boolean;

  /**
   * 映像のダウンロード中に表示される画像の URL です。この属性が指定されない場合、最初のフレームが利用可能になるまで何も表示されず、その後、最初のフレームをポスターフレームとして表示します。
   */
  poster?: string;

  /**
   * video要素をフィットさせるルール
   * object-fit準拠。デフォルトは `"cover"`
   * この設定を行う場合は、このコンポーネント自身にwidth、heightを設定しておく必要がある
   */
  fit?: HVideoPlayerFit | boolean;

  /**
   * 自動停止を無効にする場合true
   *
   * `HVideoPlayer` は通常、自身以外の `HVideoPlayer` がユーザーの操作（つまりautoplayを除く）によって再生開始された場合に自動で停止します。（backgroundModeが指定されている場合を除きます）
   *
   * この属性をtrueに設定しておくことで、この自動停止をスキップして再生し続けることが可能です
   */
  disableAutoPause?: boolean;

  /**
   * 本コンポーネントの要素がビューポート領域で見えるようになるまで初期化を遅延させる場合trueか、 `InviewDirectiveSettings` を設定する
   */
  lazy?: boolean;

  /**
   * ロード完了後にフェードインさせる場合にtrueを設定する。
   */
  fade?: boolean;

  /**
   * 本コンポーネントの要素がビューポート領域で見えるようになったら自動で再生を開始する場合true
   */
  playOnInview?: boolean;

  /**
   * 本コンポーネントの要素がビューポート領域から見えなくなったら自動で停止する場合true
   */
  pauseOnOutview?: boolean;

  /**
   * `lazy`, `playOnInview`, `pauseOnOutview` でビューポート交差をチェックする際のオプション
   */
  inviewSettings?: InviewDirectiveSettings;

  /**
   * バックグラウンドモードとして設定する場合true
   *
   * この属性を有効にすると、以下の属性が強制で有効になります。
   *
   * - autoplay
   * - loop
   * - lazy
   * - playOnInview
   * - pauseOnOutview
   */
  backgroundMode?: boolean;

  /**
   * 動画の再生を一時的に停止したい時にtrueに設定します
   * この属性は pause() と似ていますが、この属性が設定されている限り、videoは再生されることはありません。
   * suspend中に発生した再生要求は待機され、suspendを解放した際に、最終再生要求があれば再生が再開されます
   */
  suspended?: boolean;

  /** プログレスバーを表示したい場合、trueに設定します */
  progressBar?: boolean;
}

export interface HVideoPlayerEmits {
  onAbort: Event;
  onCanplay: Event;
  onCanplaythrough: Event;
  onDurationchange: Event;
  onEmptied: Event;
  onEncrypted: Event;
  onEnded: Event;
  onLoadeddata: Event;
  onLoadedmetadata: Event;
  onLoadstart: Event;
  onPause: Event;
  onPlay: Event;
  onPlaying: Event;
  onProgress: Event;
  onRatechange: Event;
  onSeeked: Event;
  onSeeking: Event;
  onStalled: Event;
  onSuspend: Event;
  onTimeupdate: Event;
  onVolumechange: Event;
  onWaiting: Event;
  onHlsError: HlsErrorData;
  onPosterLoaded: ImageDimension;
  onSuccessAutoplay: any;
  onMaybeAutoplayFailed: any;
  onAutoplayTimeout: any;
}

export interface HVideoPlayerScopedSlots {}

@Component<HVideoPlayerRef>({
  name: 'HVideoPlayer',
  render() {
    const { computedSrc } = this;
    return (
      <div
        staticClass="h-video-player"
        class={this.classes}
        v-inview={this.computedInviewSettings}>
        <video
          ref="node"
          staticClass="h-video-player__node"
          class="h-video-player__node"
          src={computedSrc || undefined}
          controls={!this.isSuspended && this.controls}
          autoplay={!this.isSuspended && this.isAutoplay}
          muted={this.isMuted}
          loop={this.isLoop}
          playsinline
          poster={this.poster}
          on={this._mediaEventListeners}
          // @ts-ignore vueのtsxサポートがonFullscreenchangeに対応していない
          onFullscreenchange={this.handleFullscreenchange}
        />
        {this.progressBar && (
          <div ref="progressBar" class="h-video-player-progress-bar">
            <div
              ref="progress"
              class="h-video-player-progress-bar__progress"></div>
          </div>
        )}
      </div>
    );
  },
  watch: {
    src: {
      async handler(current, before) {
        current = current || null;
        before = before || null;
        if (current === before) return;

        // videoのsrcとhlsのattach状態を全てunloadしておく
        await this.unload();

        if (this.isLazy && !this.inviewed) {
          // lazy設定がされていて、かつviewport交差していなかった場合は、inviewになった時に再度setupを実行するので何もしない
          return;
        }

        this.setup(current);
      },
      immediate: true,
    },
    poster: {
      handler(current) {
        this.internalPosterLoaded = false;
        if (process.browser && current && this.$listeners.posterLoaded) {
          loadImageSize(current).then((dimension) => {
            this.internalPosterLoaded = true;
            this.$emit('posterLoaded', dimension);
          });
        }
      },
      immediate: true,
    },
    isAutoplay(current, before) {
      if (current && !before) {
        this.play();
      }
    },
    isSuspended() {
      this.onSuspendedChange();
    },
  },
  created() {
    this._setupId = 0;
    this.setupMediaEventListeners();
    VideoPlayerManager.join(this);
  },
  beforeDestroy() {
    VideoPlayerManager.leave(this);
    delete this._mediaEventListeners;
    this.unload();
  },
})
export class HVideoPlayerRef extends Vue implements HVideoPlayerProps {
  readonly $refs!: {
    node: HTMLVideoElement;
    progressBar: HTMLElement;
    progress: HTMLElement;
  };

  @Prop(String) readonly src!: HVideoPlayerProps['src'];
  @Prop(Boolean) readonly controls!: boolean;
  @Prop(Boolean) readonly autoplay!: boolean;
  @Prop(Boolean) readonly muted!: boolean;
  @Prop(Boolean) readonly loop!: boolean;
  @Prop(Boolean) readonly fade!: boolean;
  @Prop(String) readonly poster!: HVideoPlayerProps['poster'];
  @Prop(Boolean) readonly disableAutoPause!: boolean;
  @Prop([String, Boolean]) readonly fit!: HVideoPlayerProps['fit'];
  @Prop(Boolean) readonly lazy!: boolean;
  @Prop(Boolean) readonly playOnInview!: boolean;
  @Prop(Boolean) readonly pauseOnOutview!: boolean;
  @Prop(Boolean) readonly backgroundMode!: boolean;
  @Prop(Boolean) readonly suspended!: boolean;
  @Prop(Boolean) readonly progressBar!: boolean;
  @Prop({ type: Object, default: () => ({}) })
  readonly inviewSettings!: InviewDirectiveSettings;

  private internalLoadState: HVideoPlayerLoadState = 'pending';
  private _mediaEventListeners!: any;
  private computedSrc: string | null = null;
  private internalSetuped: boolean = false;
  private _setupPromise?: Promise<void>;
  private _hls?: HlsController;
  private _setupId: number = 0;
  private internalInviewed: boolean = false;
  private internalPaused: boolean = true;
  private resumeOnPlay: boolean = false;
  private _setupAfterQueue?: () => any;
  private _autoplayDetectTimer?: number;
  private _autoplayTimeoutTimer?: number;
  private internalPosterLoaded: boolean = false;
  /**
   * 全画面表示状態
   * - 将来的に必要になる可能性があるので実装しておく
   */
  private isFullScreen: boolean = false;

  get isSuspended() {
    return this.suspended;
  }

  get posterLoaded() {
    return this.internalPosterLoaded;
  }

  get setuped() {
    return this.internalSetuped;
  }

  get inviewed() {
    return this.internalInviewed;
  }

  get isMuted() {
    return this.autoplay || this.muted;
  }

  get paused() {
    return this.internalPaused;
  }

  get classes() {
    let { fit } = this;
    if (fit === true) {
      fit = 'cover';
    }
    const classes: { [key: string]: any } = {
      'h-video-player--fade': this.fade,
      [`h-video-player--${this.internalLoadState}`]: true,
      'h-video-player--poster-loaded': this.fade,
    };
    if (typeof fit === 'string') {
      Object.assign(classes, {
        'h-video-player--fit': true,
        [`h-video-player--fit--${fit}`]: true,
      });
    }
    return classes;
  }

  get isAutoplay() {
    return this.autoplay || this.backgroundMode;
  }

  get isLoop() {
    return this.loop || this.backgroundMode;
  }

  get isLazy() {
    return this.lazy || this.backgroundMode;
  }

  get isPlayOnInview() {
    return this.playOnInview || this.backgroundMode;
  }

  get isPauseOnOutview() {
    return this.pauseOnOutview || this.backgroundMode;
  }

  get computedInviewSettings(): InviewDirectiveValues | undefined {
    const { isLazy, isPlayOnInview, isPauseOnOutview } = this;
    if (!isLazy && !isPlayOnInview && !isPauseOnOutview) return;

    return {
      ...this.inviewSettings,
      in: () => {
        this.setInviewed(true);
      },
      out: () => {
        this.setInviewed(false);
      },
    };
  }

  get isDisableAutoPause() {
    return this.disableAutoPause || this.backgroundMode;
  }

  /**
   * 本コンポーネントのvideo要素を取得します
   *
   * @param force `true` を指定した場合、参照がなかった場合に例外を吐きます。そのためreturnされる値は必ず `HTMLVideoElement` となります。
   */
  getNode<Force extends boolean>(
    force?: Force,
  ): Force extends true ? HTMLVideoElement : HTMLVideoElement | undefined {
    const node = this.$refs && this.$refs.node;
    if (force && !node) {
      throw new Error('missing video element.');
    }
    return node as any;
  }

  /**
   * 動画を再生します
   * まだsetupが完了していない場合は、それの完了まで待機してから再生されます
   */
  play() {
    return this.ensureSetup(() => {
      if (this.isSuspended) {
        this.resumeOnPlay = true;
        return;
      }
      const node = this.getNode();
      if (!node) return Promise.resolve();
      return node.play();
    });
  }

  /**
   * 動画を一時停止します
   */
  pause() {
    const node = this.getNode();
    if (!node) return;

    if (this.isSuspended) {
      this.resumeOnPlay = false;
      return;
    }

    return node.pause();
  }

  private handleFullscreenchange(event: Event) {
    const { node } = this.$refs;
    this.isFullScreen = node === document.fullscreenElement;
  }

  /**
   * ビューポート交差状態が変化した時に呼び出すコールバック
   */
  private setInviewed(inviewed: boolean) {
    if (this.internalInviewed === inviewed) {
      return;
    }

    this.internalInviewed = inviewed;

    if (inviewed) {
      if (this.src && !this.setuped && !this._setupPromise) {
        this.setup(this.src);
      }

      if (this.isPlayOnInview) {
        this.play();
      }
    } else if (this.isPauseOnOutview && !this.paused) {
      this.pause();
    }
  }

  /**
   * 指定のコールバックメソッドを、setup()の完了を待ってから実行します。以下の通り状況によって実行タイミングは異なります。
   *
   * - すでにsetup済みであった場合 → 即時実行します
   * - setup中であった場合 → setup完了後に実行します
   * - まだsetupが開始されていない場合 → 次回のsetup完了後に実行されますが、setupがキャンセルされる可能性があるので実行完了は保証されません
   */
  private ensureSetup(fn: () => any): Promise<void> {
    const { _setupId } = this;

    if (this.setuped) {
      // セットアップが完了していたら即時実行して終了
      fn();
      return Promise.resolve();
    }

    const exec = async () => {
      if (_setupId === this._setupId) {
        // ensureSetup実行時と_setupIdが変わっていなかったら実行する
        await fn();
      }
    };

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      const { _setupPromise } = this;

      if (!_setupPromise) {
        this._setupAfterQueue = () => {
          exec().then(resolve).catch(reject);
        };
        return;
      }

      await _setupPromise;

      exec().then(resolve).catch(reject);
    });
  }

  private onSuspendedChange() {
    if (this.isSuspended) {
      // >>> suspend
      this.resumeOnPlay = !this.paused;

      const node = this.getNode();
      node && node.pause(); // this.pause() だとすでにsuspend状態になっているのでスキップされちゃう
    } else if (this.resumeOnPlay || this.isAutoplay) {
      // >>> resume
      if (this.isPauseOnOutview && !this.inviewed) {
        return;
      }

      this.play();
    }
  }

  /**
   * video要素のsrc属性を完全に破棄します
   * vueの動的バインディングだけでは完全に破棄されないので、ごびょっています
   */
  private unloadVideoElementSrc() {
    const node = this.getNode();
    if (!node) return;

    try {
      node.pause();
      node.removeAttribute('src');
      node.load();
    } catch (err) {
      // noop
    }
    return node;
  }

  /**
   * hls.jsインスタンスを含む全ての状態を破棄します
   * これは、srcの変更検知時にも呼び出されます
   */
  private async unload() {
    await this.detachHls();
    await this.unloadVideoElementSrc();
    this.removeAutoplayDetect();
    this.removeAutoplayTimeout();
    this.computedSrc = null;
    this.internalSetuped = false;
    this.internalPaused = true;
    this._setupId++;
    this.resumeOnPlay = false;
    this.internalLoadState = 'pending';
    delete this._setupPromise;
    delete this._setupAfterQueue;
  }

  private startAutoplayDetect() {
    if (!this.autoplay) return;

    this.removeAutoplayTimeout();
    this._autoplayTimeoutTimer = window.setTimeout(() => {
      this.removeAutoplayTimeout();
      this.$emit('autoplayTimeout');
    }, 3000);
  }

  /**
   * 指定のsrcで初期化を行います
   * コンポーネント初期化時や、srcの変更検知時に実行されます
   * lazy指定がされている時はこのメソッドの呼び出しはwatcher側でスキップされ、
   * inview判定時に呼び出されます
   */
  private setup(src: string) {
    this._setupId++;

    // eslint-disable-next-line no-async-promise-executor
    this._setupPromise = new Promise(async (resolve, reject) => {
      try {
        this.internalLoadState = 'loading';

        if (canPlayNativeHls() || !src.endsWith('.m3u8')) {
          // NativeにHLSを再生可能か、もしくはhls URLではない場合、
          // そのまま計算済みのソースとしてセットして終了
          this.computedSrc = src;
          this.internalSetuped = true;
          this.startAutoplayDetect();

          return;
        }

        // ここに到達した場合、hls.jsのサポートが必要なので、hls.jsインスタンスをアタッチする
        // SSR時にはこれは実行できないので、実行しない
        if (process.server) {
          return;
        }

        await this.attachHls(src);
        this.internalSetuped = true;
        delete this._setupPromise;

        this.startAutoplayDetect();

        if (this._setupAfterQueue) {
          this._setupAfterQueue();
        }
        resolve();
      } catch (err) {
        delete this._setupPromise;
        reject(err);
      }
    });
  }

  /**
   * hls.jsインスタンスを生成してvideo要素にアタッチする
   * ※srcのロードも行います
   */
  private async attachHls(src: string) {
    const currentHls = this._hls;
    if (currentHls && currentHls.src === src) return;

    this._hls = await createHlsController(this, src, {
      // @see: https://stackoverflow.com/questions/44960944/hls-js-cors-using-aws-cloudfront-issues-with-cookies
      // xhrSetup: (xhr, url) => {
      //   xhr.withCredentials = true;
      //   xhr.setRequestHeader(
      //     'Access-Control-Allow-Headers',
      //     'Content-Type, Accept, X-Requested-With',
      //   );
      //   xhr.setRequestHeader(
      //     'Access-Control-Allow-Origin',
      //     '*',
      //     // 'http://sybdomain.domain.com:8080',
      //   );
      //   xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true');
      // },
    });

    await this._hls.attach();
  }

  /**
   * hls.jsインスタンスを破棄する
   */
  private async detachHls() {
    const hls = this._hls;
    if (!hls) return;
    await hls.detach();
    delete this._hls;
  }

  /**
   * video要素にバインドするためのリスナーオブジェクトを生成する
   */
  private setupMediaEventListeners() {
    const listeners: any = {};
    VIDEO_EVENTS.forEach((eventName) => {
      listeners[eventName] = (ev: Event) => {
        switch (eventName) {
          case 'canplay':
            if (this.internalLoadState === 'loading') {
              this.internalLoadState = 'loaded';
            }
            break;
          case 'play':
            this.internalPaused = false;
            break;
          case 'pause':
            this.internalPaused = true;
            break;
          case 'timeupdate':
            if (this._autoplayDetectTimer != null) {
              this.removeAutoplayDetect();
              this.removeAutoplayTimeout();
              this.$emit('successAutoplay');
            }
            if (this.progressBar) {
              this.setProgressBar();
            }
            break;
        }
        this.$emit(eventName, ev);
      };
    });
    this._mediaEventListeners = listeners;
  }

  private removeAutoplayDetect() {
    if (this._autoplayDetectTimer != null) {
      clearTimeout(this._autoplayDetectTimer);
      delete this._autoplayDetectTimer;
    }
  }

  private removeAutoplayTimeout() {
    if (this._autoplayTimeoutTimer != null) {
      clearTimeout(this._autoplayTimeoutTimer);
      delete this._autoplayTimeoutTimer;
    }
  }

  private setAutoplayDetect() {
    if (!this.autoplay) return;
    this.removeAutoplayDetect();
    this._autoplayDetectTimer = window.setTimeout(() => {
      this.removeAutoplayDetect();
      this.$emit('maybeAutoplayFailed');
    }, 1000);
  }

  private setProgressBar() {
    const { node, progress } = this.$refs;
    if (!node || !progress) return;

    const { currentTime, duration } = node;
    const progressPercent = (currentTime / duration) * 100;
    progress.style.width = `${progressPercent}%`;
    // アニメーションフレームのリクエスト
    requestAnimationFrame(this.setProgressBar);
  }
}

export const HVideoPlayer = tsx
  .ofType<HVideoPlayerProps, HVideoPlayerEmits, HVideoPlayerScopedSlots>()
  .convert(HVideoPlayerRef);
