import './HPortalFooter.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { HPortalTarget } from '~/components';

export interface HPortalFooterProps {}

export interface HPortalFooterEmits {}

export interface HPortalFooterScopedSlots {}

@Component<HPortalFooterRef>({
  name: 'HPortalFooter',
  render() {
    return (
      <div
        staticClass="h-portal-footer"
        v-resize={(dimension: { width: number; height: number }) => {
          this.height = dimension.height;
        }}>
        <HPortalTarget
          staticClass="h-portal-footer__target"
          name="portal-footer"
          activeClass="h-portal-footer__target--active"
        />
      </div>
    );
  },
})
export class HPortalFooterRef extends Vue implements HPortalFooterProps {
  get height() {
    return this.$ui.portalFooterHeight;
  }

  set height(height) {
    this.$ui.setPortalFooterHeight(height);
  }
}

export const HPortalFooter = tsx
  .ofType<HPortalFooterProps, HPortalFooterEmits, HPortalFooterScopedSlots>()
  .convert(HPortalFooterRef);
