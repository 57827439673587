import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';

@Component<HPlainLayout>({
  name: 'HPlainLayout',
  render(h) {
    return h('nuxt-child');
  },
})
export default class HPlainLayout extends Vue {}
