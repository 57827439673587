import './HPrice.scss';

import * as tsx from 'vue-tsx-support';
import { VNode, PropType, VNodeChildren } from 'vue/types';
import numeral from 'numeral';
import { ExchangeRate } from '~/schemes';

const BEFORE_MATCH_RE = /^(\D+)\d+/;
const AFTER_MATCH_RE = /\d+(\D+)/;

export interface HPriceProps {
  /**
   * HTMLタグ名
   * デフォルト: span
   */
  tag?: string;

  /**
   * ExchangeRate.chargeFormat.prefix を上書きしたい時に指定してください
   */
  prefix?: string;

  /**
   * ExchangeRate.chargeFormat.suffix を上書きしたい時に指定してください
   */
  suffix?: string;

  /**
   * ExchangeRate.chargeFormat.format を上書きしたい時に指定してください
   */
  format?: string;

  /**
   * 換算元となる通貨設定です。
   * 未設定の場合、 to と同様のものが設定されます（つまり換算は行われません）
   */
  base?: string | ExchangeRate;

  /**
   * 換算先となる通貨設定です。
   * 未設定の場合、 storeで保持されている表示通貨がデフォルトで設定されます
   */
  to?: string | ExchangeRate;

  /**
   * 換算元の金額です。
   * stringの場合にも数値だけで表現されている必要があります。
   * 未設定の場合、context.childrenのHTML表現の方から参照します。
   */
  amount?: string | number;

  /**
   * 数字以外のデフォルトchildrenをamountのタグに入れるか
   */
  includeAmount?: boolean;

  /**
   * 数字を「k」表記で省略するか
   * number指定の場合は省略のトリガとする桁数
   * ※デフォルトは100万（1,000,000 = 7桁）
   */
  kilo?: boolean | number;

  /**
   * プレフィクスとサフィックスを利用するか
   * ※使わない場合、通過記号がプレフィクスになる
   */
  usefix?: boolean;
}

/**
 * 料金表示を行う際は、まずこのコンポーネントの利用を検討してください。
 * 宿GETSに準じた仕様で、任意の通貨からその他の通貨へ換算した上でフォーマット表示します
 * propsで「to」を指定していない場合、デフォルトでstoreで保持している表示通貨が設定されます。
 */
export const HPrice = tsx.component({
  name: 'HPrice',

  functional: true,

  props: {
    tag: {
      type: String,
      default: 'span',
    },
    prefix: String,
    suffix: String,
    format: String,
    base: [String, Object] as PropType<string | ExchangeRate>,
    to: [String, Object] as PropType<string | ExchangeRate>,
    amount: [String, Number],
    includeAmount: Boolean,
    kilo: [Boolean, Number],
    usefix: Boolean,
  },

  render(h, { data, children = [], props, parent }) {
    const { $commons } = parent;
    const { tag, includeAmount, kilo, usefix } = props;
    const kiloDigits = typeof kilo === 'number' ? kilo : kilo ? 7 : 0;
    const currentExchangeRate = $commons.currentExchangeRate as
      | ExchangeRate
      | undefined;

    let base: ExchangeRate | string | undefined = props.base;
    let to: ExchangeRate | string | undefined = props.to;
    let { amount } = props;

    if (typeof base === 'string') {
      base = $commons.exchangeRateAt(base);
    }

    if (typeof to === 'string') {
      to = $commons.exchangeRateAt(to);
    }

    if (!base) {
      base = currentExchangeRate;
    }

    if (!to) {
      to = currentExchangeRate;
    }

    let { staticClass } = data;
    staticClass = 'h-price' + (staticClass ? ` ${staticClass}` : '');

    if (!base || !to) {
      // 通過設定ロード中なので、ブランクな状態で子要素をそのままレンダリングする
      staticClass = `${staticClass} h-price--placeholder`;

      return h(
        tag,
        {
          ...data,
          staticClass,
          key: 'placeholder',
        },
        children,
      );
    }

    const baseCurrency = base.base;
    const toCurrency = to.base;
    const { chargeFormat } = to;
    const { format } = chargeFormat;
    let { prefix = chargeFormat.prefix, suffix = chargeFormat.suffix } = props;

    if (!usefix) {
      prefix = toCurrency;
      suffix = '';
      if (prefix === 'JPY' && parent.$language.current === 'ja') {
        prefix = '¥';
      }
    }

    if (amount === undefined) {
      if (children.length) {
        amount = (children.pop() as VNode).text || '';
      } else if (data.domProps && data.domProps.textContent) {
        amount = data.domProps.textContent;
        delete data.domProps.textContent;
      } else if (data.domProps && data.domProps.innerHTML) {
        amount = data.domProps.innerHTML;
        delete data.domProps.innerHTML;
      }

      if (amount === undefined) {
        amount = '';
      }
    }

    amount = String(amount);

    let beforeMatchStr: string | undefined;
    const beforeMatch = amount.match(BEFORE_MATCH_RE);
    if (beforeMatch) {
      beforeMatchStr = beforeMatch[1];
      amount = amount.replace(beforeMatchStr, '');
    }

    let afterMatchStr: string | undefined;
    const afterMatch = amount.match(AFTER_MATCH_RE);
    if (afterMatch) {
      afterMatchStr = afterMatch[1];
      amount = amount.replace(afterMatchStr, '');
    }

    if (amount !== '') {
      const numberMatch = String(amount).match(/[\d.]+/);
      if (numberMatch) {
        const stringValue = numberMatch[0];
        const numberValue = parseFloat(stringValue);
        const targetRate =
          to.base === base.base
            ? { rate: '1' }
            : base.rateList.find((row) => row.quote === toCurrency);

        if (!targetRate) {
          throw new Error(
            `missing rate info in ${baseCurrency} at ${toCurrency}`,
          );
        }

        let ratedValue = numberValue * parseFloat(targetRate.rate);
        let kiloSuffix = '';
        if (kiloDigits > 0) {
          const stringAmount = String(Math.floor(ratedValue));
          if (stringAmount.length >= kiloDigits) {
            kiloSuffix = 'K';
            ratedValue = Math.floor(ratedValue / 1000);
          }
        }
        const formatedValue = numeral(ratedValue).format(format) + kiloSuffix;
        amount = String(amount).replace(stringValue, formatedValue);
      }
    }

    const myChildren: VNodeChildren = [
      h(
        'span',
        {
          staticClass: 'h-price__amount',
        },
        String(amount).trim() +
          ((includeAmount && suffix) || '') +
          ((includeAmount && afterMatchStr) || ''),
      ),
    ];

    if (beforeMatchStr) {
      myChildren.unshift(beforeMatchStr);
    }

    const amountIsEmpty = amount === '';

    if (prefix && !amountIsEmpty) {
      myChildren.unshift(
        h(
          'span',
          {
            staticClass: 'h-price__prefix',
          },
          prefix,
        ),
      );
      staticClass += ` h-price--prefix-${prefix.length}`;
    }

    if (suffix && !amountIsEmpty && !includeAmount) {
      myChildren.push(
        h(
          'span',
          {
            staticClass: 'h-price__suffix',
          },
          suffix,
        ),
      );
    }

    if (afterMatchStr && !includeAmount) {
      myChildren.push(afterMatchStr);
    }

    return h(
      tag,
      {
        ...data,
        staticClass,
      },
      myChildren,
    );
  },
});
