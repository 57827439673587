import Vue, { VNodeChildren, VNode } from 'vue';
import { ValidationErrors } from '~/validators';
import { HIcon } from '~/components';

type RawError = ValidationErrors | string;

export function createErrorMessageNode(
  vm: Vue,
  source?: RawError | null,
  nodeName?: string,
  // slotPrefix = '',
): VNode | undefined {
  const { $scopedSlots, $createElement: h } = vm;
  let errorMessage: VNodeChildren | undefined;
  // if (slotPrefix) slotPrefix = `${slotPrefix}:`;
  if (source != null) {
    if (typeof source === 'string') {
      errorMessage = source;
    } else {
      const name = Object.keys(source)[0];
      const slot = $scopedSlots.error;
      // const slot = $scopedSlots[`error:${slotPrefix}${key}`];
      if (slot) {
        errorMessage = slot({
          name,
          source,
          nodeName,
        });
      }
    }
  }
  if (errorMessage && typeof errorMessage !== 'boolean') {
    return (
      <div staticClass="h-form-control__message h-form-control__message--error">
        <HIcon
          staticClass="h-form-control__message__icon"
          name="info-circle-outline"
        />
        <div staticClass="h-form-control__message__content">{errorMessage}</div>
      </div>
    );
  }
}
