import { Context } from '@nuxt/types';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { State as RootState } from './';

import {
  AvailableLanguage,
  DEFAULT_LANGUAGE,
  AVAILABLE_LANGUAGES,
  LanguageInfo,
  languageInfoMap,
  LANGUAGE_INFORMATION,
  isAvailableLanguage,
  matchedAvairableLanguage,
} from '~/schemes';

import { urlJoin, roundLanguage, normalizedGFLanguage } from '~/helpers';

export interface State {
  default: AvailableLanguage;
  availableLanguages: AvailableLanguage[];
  current: AvailableLanguage;

  /**
   * サーバーが検出したクライアントの有効言語
   */
  acceptLanguage: string;

  /**
   * ブラウザーが保持している有効言語
   */
  browserLanguage: string;
  infoMap: typeof languageInfoMap;
  informations: LanguageInfo[];
  defaultMessages: any;
  localeMessages: any;
}

export const state = (): State => ({
  default: DEFAULT_LANGUAGE,
  availableLanguages: AVAILABLE_LANGUAGES,
  current: DEFAULT_LANGUAGE,
  acceptLanguage: '',
  browserLanguage: '',
  infoMap: languageInfoMap,
  informations: LANGUAGE_INFORMATION,
  defaultMessages: {},
  localeMessages: {},
});

export const getters: GetterTree<State, RootState> = {
  currentInfo(state): LanguageInfo {
    const { current } = state;
    const info = languageInfoMap.get(current);
    if (!info) {
      throw new Error(`missing language info at ${current}`);
    }
    return info;
  },
  /**
   * 検出したクライアントの有効言語
   */
  detectedLanguage(state) {
    return state.browserLanguage || state.acceptLanguage || null;
  },
  /**
   * 検出したクライアントの有効言語から導き出した、
   * GFの有効言語
   * ヒットしなかったらとりあえずEN
   */
  detectedAvailableLanguage(state, getters): AvailableLanguage {
    const { detectedLanguage } = getters;
    if (!detectedLanguage) return 'en';
    let hit: AvailableLanguage | undefined;
    const rounded = detectedLanguage.toLocaleLowerCase().replace(/-/g, '_');
    const re = new RegExp(`^${rounded}(_|$)`);
    for (const lang of AVAILABLE_LANGUAGES) {
      const lower = lang.toLocaleLowerCase().replace(/-/g, '_');
      if (lower === rounded) {
        hit = lang;
        break;
      }
      if (re.test(lang)) {
        hit = lang;
      }
    }
    return hit || 'en';
  },
  redirectLanguage(state, getters) {
    const { detectedLanguage } = getters;
    return matchedAvairableLanguage(detectedLanguage) || DEFAULT_LANGUAGE;
  },
  crossSearchUrl(state, getters) {
    const { current } = state;

    /**
     * @todo
     *    横断検索はまだJA、EN以外リリースされていないので、
     *    JA以外は全てENに飛ばす
     */
    const lang = current === 'ja' ? 'JA' : 'EN';
    return (...paths: string[]) => {
      return urlJoin(
        process.env.origin || '',
        `/crosssearch/${lang}`,
        ...paths,
      );
    };
  },
};

export const actions: ActionTree<State, RootState> = {
  async serverInit({ commit, state }, ctx: Context) {
    const { req, route, $i18n } = ctx;
    const defaultMessagesModule = await import(`~/i18n/${DEFAULT_LANGUAGE}`);
    const defaultMessages = defaultMessagesModule.default;
    commit('SET_DEFAULT_MESSAGES', defaultMessages);
    $i18n.setLocaleMessage(DEFAULT_LANGUAGE, defaultMessages);
    const { lang } = route.params;
    if (lang) {
      if (isAvailableLanguage(lang)) {
        commit('SET_CURRENT', lang);
        if (lang !== DEFAULT_LANGUAGE) {
          const localeMessagesModule = await import(`~/i18n/${lang}`);
          const localeMessages = localeMessagesModule.default;
          commit('SET_LOCALE_MESSAGES', localeMessages);
          $i18n.setLocaleMessage(lang, localeMessages);
          $i18n.locale = lang;
        }
      } else {
        ctx.error({
          statusCode: 404,
          message: 'This page could not be found.',
        });
      }
    }
    if (req && typeof req.headers['accept-language'] !== 'undefined') {
      const acceptLanguage = req.headers['accept-language']
        .split(',')[0]
        .toLocaleLowerCase()
        .substring(0, 2);
      commit('SET_ACCEPT_LANGUAGE', roundLanguage(acceptLanguage));
    }
  },

  // eslint-disable-next-line require-await
  async clientInit({ state, commit }, ctx: Context) {
    const { $i18n } = ctx;
    const locale = state.current;
    $i18n.setLocaleMessage(DEFAULT_LANGUAGE, state.defaultMessages);
    if (locale !== DEFAULT_LANGUAGE) {
      $i18n.setLocaleMessage(locale, state.localeMessages);
    }
    $i18n.locale = locale;
    let browserLanguage: string;
    const { navigator } = window;
    if (navigator.languages) {
      browserLanguage = navigator.languages[0];
    } else if (navigator.language) {
      browserLanguage = navigator.language;
    } else {
      const n: any = navigator;
      browserLanguage = n.userLanguage || n.browserLanguage;
    }

    browserLanguage = normalizedGFLanguage(browserLanguage);

    // ブラウザ言語がURLの言語と異なるときに、ブラウザ言語のi18nの情報を保存する
    if (browserLanguage !== DEFAULT_LANGUAGE && browserLanguage !== locale) {
      const localeMessagesModule = await import(`~/i18n/${browserLanguage}`);
      const localeMessages = localeMessagesModule.default;
      commit('SET_LOCALE_MESSAGES', localeMessages);
      $i18n.setLocaleMessage(browserLanguage, localeMessages);
    }

    commit('SET_BROWSER_LANGUAGE', roundLanguage(browserLanguage));
  },
};

export const mutations: MutationTree<State> = {
  SET_DEFAULT_MESSAGES(state, messages: any) {
    state.defaultMessages = messages;
  },

  SET_LOCALE_MESSAGES(state, messages: any) {
    state.localeMessages = messages;
  },

  SET_CURRENT(state, current: AvailableLanguage) {
    state.current = current;
  },

  SET_ACCEPT_LANGUAGE(state, acceptLanguage: string) {
    state.acceptLanguage = acceptLanguage;
  },

  SET_BROWSER_LANGUAGE(state, browserLanguage: string) {
    state.browserLanguage = browserLanguage;
  },
};
