import './HIconLabel.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { HIcon } from './HIcon';
import { IconName } from '~/.dynamic/icons';

export const HIconLabel = tsx.component({
  name: 'HIconLabel',
  functional: true,

  props: {
    name: {
      type: String as PropType<IconName>,
      required: true,
    },
    right: Boolean,
    baseline: Boolean,
    tag: {
      type: String,
      default: 'span',
    },
  },

  render(h, { data, children, props }): VNode {
    const { name, right, baseline, tag } = props;

    const staticClasses = data.staticClass ? [data.staticClass] : [];
    staticClasses.push('h-icon-label');
    baseline && staticClasses.push('h-icon-label--baseline');

    const myChildren: VNode[] = [
      h(
        tag,
        {
          staticClass: 'h-icon-label__label',
        },
        children,
      ),
    ];

    const icon = (
      <HIcon
        staticClass="h-icon-label__icon"
        name={name}
        left={!right}
        right={right}
      />
    );

    if (right) {
      myChildren.push(icon);
    } else {
      myChildren.unshift(icon);
    }

    const staticClass = staticClasses.join(' ').trim();

    return h(
      tag,
      {
        ...data,
        staticClass,
      },
      myChildren,
    );
  },
});
