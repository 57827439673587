/* eslint-disable no-undef */
import { SUPPORTS_PASSIVE } from '@dadajam4/scroller';

export enum PointerType {
  Touch = 'touch',
  Mouse = 'mouse',
}

export type MouseOrTouchEvent = MouseEvent | TouchEvent;

export interface PointerActions {
  start: 'mousedown' | 'touchstart';
  move: 'mousemove' | 'touchmove';
  end: 'mouseup' | 'touchend';
}

export const createAddEventListenerOptions = (
  source?: boolean | AddEventListenerOptions,
) => {
  const options: AddEventListenerOptions =
    typeof source === 'object' ? { ...source } : { capture: !!source };
  if (SUPPORTS_PASSIVE) {
    options.passive = options.passive === undefined ? true : options.passive;
  } else {
    delete options.passive;
  }
  options.capture = options.capture || false;
  return options;
};

export type PointerPoint = MouseEvent | Touch;
export interface PointAndType {
  point: PointerPoint;
  type: PointerType;
}

export interface PointerActionsMap extends Map<PointerType, PointerActions> {
  get(key: PointerType): PointerActions;
}

export const pointerActionsMap = new Map() as PointerActionsMap;
pointerActionsMap.set(PointerType.Touch, {
  start: 'touchstart',
  move: 'touchmove',
  end: 'touchend',
});

pointerActionsMap.set(PointerType.Mouse, {
  start: 'mousedown',
  move: 'mousemove',
  end: 'mouseup',
});

export const pointerStartEvents: string[] = [];
pointerActionsMap.forEach((actions) => {
  pointerStartEvents.push(actions.start);
});

export type PointerEventListener = (
  this: Document,
  ev: MouseOrTouchEvent,
) => any;

export const addStartListener = (
  listener: PointerEventListener,
  options: boolean | AddEventListenerOptions = false,
  target: HTMLElement | Document = document,
): Function => {
  const createdOptions = createAddEventListenerOptions(options);
  pointerStartEvents.forEach((name) => {
    target.addEventListener(name, listener as EventListener, createdOptions);
  }, options);
  const remover = () => {
    removeStartListener(listener, options, target);
  };
  return remover;
};

export const removeStartListener = (
  listener: PointerEventListener,
  options: boolean | AddEventListenerOptions = false,
  target: HTMLElement | Document = document,
) => {
  const createdOptions = createAddEventListenerOptions(options);
  pointerStartEvents.forEach((name) => {
    target.removeEventListener(name, listener as EventListener, createdOptions);
  }, options);
};

export const isMouseEvent = (ev: Event): ev is MouseEvent => {
  return (ev as any).which !== undefined;
};

export const isTouchEvent = (ev: Event): ev is TouchEvent => {
  return (ev as any).touches !== undefined;
};

export const isRightClickEvent = (ev: Event): ev is MouseEvent => {
  return (ev as any).which === 3;
};

export function getPointAndTypeByMouseOrTouch(
  ev: MouseOrTouchEvent,
): PointAndType {
  let point: PointerPoint;
  let type: PointerType;

  if (isTouchEvent(ev)) {
    point = ev.touches[0];
    type = PointerType.Touch;
  } else {
    point = ev;
    type = PointerType.Mouse;
  }
  return {
    point,
    type,
  };
}
