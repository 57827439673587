import { Context } from '@nuxt/types';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { State as RootState } from './';
import { AccountProfile } from '~/schemes';

export interface State {
  initialized: boolean;
  me: AccountProfile | null;
}

export const state = (): State => ({
  initialized: false,
  me: null,
});

export const getters: GetterTree<State, RootState> = {
  current(state): AccountProfile | null {
    return state.me;
  },
};

export const actions: ActionTree<State, RootState> = {
  async clientInit(actionCtx, ctx: Context) {
    await ctx.$account.load();
  },
};

export const mutations: MutationTree<State> = {
  SET_ME(state, me: AccountProfile | null) {
    state.me = me;
    state.initialized = true;
  },
};
