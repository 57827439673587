import {
  isEmptyInputValue,
  getInputValueLength,
  isEmailValue,
  isJPZipCode,
  isNumeric,
  isInteger,
  isEqual,
  isNotEqual,
  isStrictEqual,
  isNotStrictEqual,
  isNotLessThan,
  isGreaterThan,
  isNotGreaterThan,
  isLessThan,
  isBetween,
  isAlpha,
  isAlphaDash,
  isAlphaNumeric,
  isAlphaSpaces,
} from './util';

export interface ValidationErrors {
  [key: string]: any;
}

export type ValidationResult = ValidationErrors | null;

export type Validator = (
  value: any,
  ...args: any[]
) => ValidationResult | Promise<ValidationResult>;

export type ValidatorFactory = (...args: any[]) => Validator;

/**
 * 必ず合格するバリデーター
 */
export function nullValidator(value: any): ValidationResult {
  return null;
}

/**
 * 入力が空であるか
 */
export const empty: Validator = function empty(value: any): ValidationResult {
  return isEmptyInputValue(value) ? null : { empty: true };
};

/**
 * 入力がされているか
 */
export const required: Validator = function required(
  value: any,
): ValidationResult {
  return isEmptyInputValue(value) ? { required: true } : null;
};

/**
 * 入力値がemailアドレスか
 */
export const email: Validator = function email(value: any): ValidationResult {
  return isEmptyInputValue(value) || isEmailValue(value)
    ? null
    : { email: true };
};

export const jpZip: Validator = function jpZip(value: any): ValidationResult {
  return isEmptyInputValue(value) || isJPZipCode(value)
    ? null
    : { jpZip: true };
};

/**
 * 数値のみであるか
 * allowed: ... 0, 1, 2 ...
 */
export const numeric: Validator = function numeric(
  value: any,
): ValidationResult {
  return isEmptyInputValue(value) || isNumeric(value)
    ? null
    : { integer: true };
};

/**
 * 整数であるか
 * allowed: ... -1, 0, 1 ...
 */
export const integer: Validator = function integer(
  value: any,
): ValidationResult {
  return isEmptyInputValue(value) || isInteger(value)
    ? null
    : { integer: true };
};

export const equal: ValidatorFactory = function equal(compared: any) {
  return function equal(value: any): ValidationResult {
    return isEmptyInputValue(value) || isEqual(value, compared)
      ? null
      : { equal: { compared, actual: value } };
  };
};

export const notEqual: ValidatorFactory = function notEqual(compared: any) {
  return function notEqual(value: any): ValidationResult {
    return isEmptyInputValue(value) || isNotEqual(value, compared)
      ? null
      : { notEqual: { compared, actual: value } };
  };
};

export const is: ValidatorFactory = function is(compared: any) {
  return function is(value: any): ValidationResult {
    return isEmptyInputValue(value) || isStrictEqual(value, compared)
      ? null
      : { is: { compared, actual: value } };
  };
};

export const isNot: ValidatorFactory = function isNot(compared: any) {
  return function isNot(value: any): ValidationResult {
    return isEmptyInputValue(value) || isNotStrictEqual(value, compared)
      ? null
      : { is: { compared, actual: value } };
  };
};

export const min: ValidatorFactory = function min(compared: number) {
  return function min(value: any): ValidationResult {
    return isEmptyInputValue(value) || isNotLessThan(value, compared)
      ? null
      : { min: { min: compared, actual: value } };
  };
};

export const greater: ValidatorFactory = function greater(compared: number) {
  return function greater(value: any): ValidationResult {
    return isEmptyInputValue(value) || isGreaterThan(value, compared)
      ? null
      : { greater: { min: compared, actual: value } };
  };
};

export const max: ValidatorFactory = function max(compared: number) {
  return function max(value: any): ValidationErrors | null {
    return isEmptyInputValue(value) || isNotGreaterThan(value, compared)
      ? null
      : { max: { max: compared, actual: value } };
  };
};

export const less: ValidatorFactory = function less(compared: number) {
  return function less(value: any): ValidationErrors | null {
    return isEmptyInputValue(value) || isLessThan(value, compared)
      ? null
      : { less: { max: compared, actual: value } };
  };
};

export const between: ValidatorFactory = function between(
  minValue: number,
  maxValue: number,
) {
  return function between(value: any): ValidationErrors | null {
    return isEmptyInputValue(value) || isBetween(value, minValue, maxValue)
      ? null
      : { between: { min: minValue, max: maxValue, actual: value } };
  };
};

export const alpha: ValidatorFactory = function alpha(locale?: string) {
  return function alpha(value: any): ValidationErrors | null {
    return isEmptyInputValue(value) || isAlpha(value, locale)
      ? null
      : { alpha: true };
  };
};

export const alphaDash: ValidatorFactory = function alphaDash(locale?: string) {
  return function alphaDash(value: any): ValidationErrors | null {
    return isEmptyInputValue(value) || isAlphaDash(value, locale)
      ? null
      : { alphaDash: true };
  };
};

export const alphaNumeric: ValidatorFactory = function alphaNumeric(
  locale?: string,
) {
  return function alphaNumeric(value: any): ValidationErrors | null {
    return isEmptyInputValue(value) || isAlphaNumeric(value, locale)
      ? null
      : { alphaNumeric: true };
  };
};

export const alphaSpaces: ValidatorFactory = function alphaSpaces(
  locale?: string,
) {
  return function alphaSpaces(value: any): ValidationErrors | null {
    return isEmptyInputValue(value) || isAlphaSpaces(value, locale)
      ? null
      : { alphaSpaces: true };
  };
};

export const length: ValidatorFactory = function length(
  requiredLength: number,
) {
  return function length(value: any): ValidationErrors | null {
    if (isEmptyInputValue(value)) {
      return null;
    }
    const length = getInputValueLength(value);
    return length === requiredLength
      ? null
      : { length: { requiredLength, actualLength: length } };
  };
};

export const minLength: ValidatorFactory = function minLength(
  requiredLength: number,
) {
  return function minLength(value: any): ValidationErrors | null {
    if (isEmptyInputValue(value)) {
      return null;
    }
    const length = getInputValueLength(value);
    return length >= requiredLength
      ? null
      : { minLength: { requiredLength, actualLength: length } };
  };
};

export const maxLength: ValidatorFactory = function maxLength(
  requiredLength: number,
) {
  return function maxLength(value: any): ValidationErrors | null {
    if (isEmptyInputValue(value)) {
      return null;
    }
    const length = getInputValueLength(value);
    return length <= requiredLength
      ? null
      : { maxLength: { requiredLength, actualLength: length } };
  };
};

export const betweenLength: ValidatorFactory = function betweenLength(
  minLength: number,
  maxLength: number,
) {
  return function betweenLength(value: any): ValidationErrors | null {
    if (isEmptyInputValue(value)) {
      return null;
    }
    const length = getInputValueLength(value);
    return length >= minLength && length <= maxLength
      ? null
      : { betweenLength: { minLength, maxLength, actualLength: length } };
  };
};

export const pattern: ValidatorFactory = function pattern(
  requiredPattern: string | RegExp,
) {
  if (!requiredPattern) {
    return nullValidator;
  }
  let regex: RegExp;
  let regexStr: string;
  if (typeof requiredPattern === 'string') {
    regexStr = '';

    if (requiredPattern.charAt(0) !== '^') {
      regexStr += '^';
    }

    regexStr += requiredPattern;

    if (requiredPattern.charAt(requiredPattern.length - 1) !== '$') {
      regexStr += '$';
    }

    regex = new RegExp(regexStr);
  } else {
    regexStr = requiredPattern.toString();
    regex = requiredPattern;
  }
  return function pattern(value: any): ValidationErrors | null {
    if (isEmptyInputValue(value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return regex.test(value)
      ? null
      : { pattern: { requiredPattern: regexStr, actualValue: value } };
  };
};

export const include: ValidatorFactory = function include(...includes: any[]) {
  return function include(value: any): ValidationErrors | null {
    if (isEmptyInputValue(value)) {
      return null;
    }
    const isMultiple = Array.isArray(value);
    const errorItems: any = includes.filter((i: any) => {
      return isMultiple ? !value.includes(i) : value !== i;
    });
    return errorItems.length === 0
      ? null
      : { include: { includes, actualValue: value } };
  };
};

export const exclude: ValidatorFactory = function exclude(...excludes: any[]) {
  return function include(value: any): ValidationErrors | null {
    if (isEmptyInputValue(value)) {
      return null;
    }
    const isMultiple = Array.isArray(value);
    const errorItems: any = excludes.filter((i: any) => {
      return isMultiple ? value.includes(i) : value === i;
    });
    return errorItems.length === 0
      ? null
      : { include: { excludes, actualValue: value } };
  };
};
