import './HSimpleCoverTitle.scss';

import * as tsx from 'vue-tsx-support';
import { VNode, PropType } from 'vue';
import { HThemeDecoration } from '../HThemeDecoration';

export const HSimpleCoverTitle = tsx.component({
  name: 'HSimpleCoverTitle',
  functional: true,

  props: {
    /**
     * タイトル
     */
    title: String as PropType<string | null>,

    /**
     * サブタイトル
     */
    subTitle: String as PropType<string | null>,

    /**
     * 飾りテキスト
     *
     * * 名前悩んだのでFIX ME PLESE
     */
    deco: String as PropType<string | null>,

    /**
     * タグ名
     */
    tag: String as PropType<string | null>,

    /**
     * 画像
     */
    image: [String, Boolean] as PropType<string | null>,
  },

  render(h, { data, props, parent }): VNode {
    const { tag, title, subTitle, image, deco } = props;
    const _tag = tag || 'h1';
    const myChildren: VNode[] = [];

    if (subTitle) {
      myChildren.push(
        <div
          staticClass="h-simple-cover-title__sub-ttl"
          v-wysiwyg={subTitle}
        />,
      );
    }

    if (title) {
      myChildren.push(
        h(_tag, {
          staticClass: 'h-simple-cover-title__ttl',
          domProps: {
            innerHTML: title,
          },
        }),
      );
    }

    if (deco) {
      myChildren.push(
        h(
          'span',
          {
            staticClass: 'h-simple-cover-title__deco-txt',
          },
          deco,
        ),
      );
    }

    if (parent.$theme.is('risonare')) {
      myChildren.push(
        <HThemeDecoration staticClass="h-simple-cover-title__deco" size="lg" />,
      );
    }

    const staticClasses = data.staticClass ? [data.staticClass] : [];
    staticClasses.push('h-simple-cover-title');
    if (image) {
      staticClasses.push('h-simple-cover-title--has-image');
    }
    const staticClass = staticClasses.join(' ').trim();

    return (
      <div
        {...{
          ...data,
          staticClass,
          class: {
            'h-simple-cover-title--without-deco-txt': !deco,
          },
          style: image
            ? {
                backgroundImage: `url(${parent.$res.img(image)})`,
              }
            : undefined,
        }}>
        <div staticClass="h-simple-cover-title__body">{myChildren}</div>
      </div>
    );
  },
});
