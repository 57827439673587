import './HBrandNavigation.scss';

import Vue from 'vue';
import * as tsx from 'vue-tsx-support';
import { Component, Prop } from 'vue-property-decorator';
import { BRAND_NAME_DISPLAY_SETTINGS_MAP } from './settings';
import { createBrandLinks } from './helpers';
import {
  ComputedBrand,
  BrandNameDisplaySettings,
  BrandNameDisplaySettingsPayload,
} from './schemes';
import { ThemeValue } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types/';
import { HExpandTransition } from '~/components';
import { HotelBrandBasicInfo } from '~/schemes';

export interface HBrandNavigationProps {
  /**
   * ブランド基本情報か、それにリンクを追加したオブジェクト
   *
   * * リンクを渡さなかった場合はこのコンポーネントの中で取得する
   */
  brand: HotelBrandBasicInfo | ComputedBrand;
}

export interface HBrandNavigationEmits {}

export interface HBrandNavigationScopedSlots {}

@Component<HBrandNavigationRef>({
  name: 'HBrandNavigation',
  render() {
    const { header, computedLinks: links } = this;
    const { logo } = header;

    return (
      <div staticClass="h-brand-navigation">
        <h2 staticClass="h-brand-navigation__header" onClick={this.toggle}>
          <span staticClass="h-brand-navigation__header__brand-category">
            {this.displayBrandCategory}
          </span>
          {logo ? (
            <span staticClass="h-brand-navigation__header__logo">
              <img
                staticClass="h-brand-navigation__header__logo__img"
                src={logo.src}
                alt={logo.alt}
              />
              {logo.text && (
                <span staticClass="h-brand-navigation__header__logo__text">
                  {logo.text}
                </span>
              )}
            </span>
          ) : (
            <span
              staticClass="h-brand-navigation__header__text"
              domPropsInnerHTML={header.text}
            />
          )}
          <div staticClass="h-brand-navigation__header__expand">
            <span>{this.isActive ? '-' : '+'}</span>
          </div>
        </h2>
        <HExpandTransition>
          <div class="h-brand-navigation__body" v-show={this.isActive}>
            <ul staticClass="h-brand-navigation__hotels">
              {links.map(({ TagName, key, props, attrs, children }) => (
                <li key={key} staticClass="h-brand-navigation__hotels__item">
                  <TagName
                    props={props}
                    attrs={attrs}
                    staticClass="h-brand-navigation__hotels__item__link">
                    {children}
                  </TagName>
                </li>
              ))}
            </ul>
          </div>
        </HExpandTransition>
      </div>
    );
  },
})
export class HBrandNavigationRef extends Vue implements HBrandNavigationProps {
  /**
   * ブランド基本情報か、それにリンクを追加したオブジェクト
   *
   * * リンクを渡さなかった場合はこのコンポーネントの中で取得する
   */
  @Prop({ type: Object, required: true }) readonly brand!:
    | HotelBrandBasicInfo
    | ComputedBrand;

  /** アコーディオンの開閉状態 */
  private isActive: boolean = false;

  /** ロゴの上に表示するブランドカテゴリーは変更が頻繁にあるものではないのでCMSはなくここで管理 */
  private slugMap: Partial<Record<ThemeValue, string>> = {
    hoshinoya: `[${this.$tc('label.brands.categories.hoshinoya')}]`,
    kai: `[${this.$tc('label.brands.categories.kai')}]`,
    risonare: `[${this.$tc('label.brands.categories.risonare')}]`,
    omo: `[${this.$tc('label.brands.categories.omo')}]`,
    beb: `[${this.$tc('label.brands.categories.beb')}]`,
  };

  /** ロゴの上に表示するブランドのカテゴリー */
  get displayBrandCategory() {
    return this.slugMap[this.brand.slug] || '';
  }

  /** ブランドスラッグ */
  get slug() {
    return this.brand.slug;
  }

  /** ブランド名 */
  get brandName() {
    return this.brand.name;
  }

  /** ブランド名表示設定 */
  get displaySettings(): BrandNameDisplaySettings {
    return BRAND_NAME_DISPLAY_SETTINGS_MAP[this.slug] || {};
  }

  /** ブランド名表示設定の各メソッドに渡す引数オブジェクト */
  get displaySettingsPayload(): BrandNameDisplaySettingsPayload {
    return {
      lang: this.$language.current,
      res: this.$res,
      slug: this.slug,
      brandName: this.brandName,
    };
  }

  /** ブランド名表示設定に情報をいろいろ渡して解決した設定オブジェクト */
  get resolvedDisplaySettings() {
    const { displaySettings, displaySettingsPayload, brandName } = this;
    const { logo, logoWithText, text } = displaySettings;
    const _logo = logo ? logo(displaySettingsPayload) : undefined;
    const _logoWithText = logoWithText
      ? logoWithText(displaySettingsPayload)
      : undefined;
    const _text =
      text && text[displaySettingsPayload.lang]
        ? text[displaySettingsPayload.lang]!(displaySettingsPayload)
        : brandName;

    return {
      logo: _logo,
      logoWithText: _logoWithText,
      text: _text,
    };
  }

  /** ヘッダー表示設定 */
  get header() {
    const { resolvedDisplaySettings, brandName } = this;
    const { logo, logoWithText, text } = resolvedDisplaySettings;

    return {
      /** ロゴの表示設定 */
      logo: logo && {
        src: logo,
        text: logo && logoWithText ? brandName : undefined,
        alt: logoWithText ? '' : brandName,
      },
      /** ロゴじゃなくテキストで表示する時のテキストの内容 */
      text,
    };
  }

  /** このブランドに紐づくリンクのリスト */
  get computedLinks() {
    return (
      (this.brand as ComputedBrand).links || createBrandLinks(this, this.brand)
    ).map((link) => {
      // @TODO このmap処理は、以下の問題に対する残体対応です。
      // ページバックした場合、この対応だけでは問題解決にならずですが、緩和のために一時的にやってます
      // 根本原因を修正したら、この処理は削除してください
      if (!link.props) return link;
      if (link.attrs && link.attrs.href) return link;
      const { props, attrs } = link;
      const propsHref = props._href;
      if (!propsHref) return link;
      const _link = {
        ...link,
        TagName: 'a',
      };

      delete _link.props;
      _link.attrs = {
        ...attrs,
        href: propsHref,
      };
      return _link;
    });
  }

  /** アコーディオンの開閉状態をトグルする */
  toggle() {
    this.isActive = !this.isActive;
  }
}

export const HBrandNavigation = tsx
  .ofType<
    HBrandNavigationProps,
    HBrandNavigationEmits,
    HBrandNavigationScopedSlots
  >()
  .convert(HBrandNavigationRef);
