import './HFullModal.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode, VNodeChildren } from 'vue';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';
import { bodyScrollLock } from '@dadajam4/vue-stack';
import { HPathIcon } from '~/components';

export type HFullModalGuard = (modal: HFullModalRef) => boolean;

export interface HFullModalProps {
  value?: boolean;
  closeGuard?: HFullModalGuard;
}

export interface HFullModalEmits {
  onAfterLeave: void;
  onInput: boolean;
}

export interface HFullModalScopedSlots {
  header?: HFullModalRef;
  footer?: HFullModalRef;
  headerAppend?: HFullModalRef;
}

@Component<HFullModalRef>({
  name: 'HFullModal',
  directives: {
    bodyScrollLock,
  },

  mounted() {
    this.isMounted = true;
  },

  render(): VNode {
    const transitionChildren: VNode[] = [];

    if (this.isMounted && this.isActive) {
      const {
        header: headerSlot,
        footer: footerSlot,
        headerAppend: headerAppendSlot,
      } = this.$scopedSlots;

      const headerBodychildren: VNodeChildren =
        (headerSlot && headerSlot(this)) || [];

      if (headerAppendSlot) {
        headerBodychildren.push(
          <div staticClass="h-full-modal__header__body__append">
            {headerAppendSlot(this)}
          </div>,
        );
      }

      const $modal = (
        <div staticClass="h-full-modal">
          <header staticClass="h-full-modal__header">
            <button
              staticClass="h-full-modal__close"
              type="button"
              onClick={(ev) => {
                ev.stopPropagation();
                this.close();
              }}>
              <HPathIcon name="close" />
            </button>
            <div staticClass="h-full-modal__header__body">
              {headerBodychildren}
            </div>
          </header>
          <div staticClass="h-full-modal__scroller" style={this.scrollerStyles}>
            <div staticClass="h-full-modal__body" v-body-scroll-lock={true}>
              {this.$slots.default}
            </div>
          </div>
          <footer
            staticClass="h-full-modal__footer"
            v-resize={(rect: { height: number }) => {
              this.internalFooterHeight = rect.height;
            }}>
            <div staticClass="h-full-modal__footer__inner">
              {footerSlot ? footerSlot(this) : undefined}
            </div>
          </footer>
        </div>
      );
      transitionChildren.push($modal);
    }

    return (
      <transition
        name="fade"
        onAfterLeave={() => {
          this.$emit('afterLeave');
        }}>
        {transitionChildren}
      </transition>
    );
  },
})
export class HFullModalRef extends Vue implements HFullModalProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;
  @Prop({ type: Function }) readonly closeGuard?: HFullModalGuard;

  private isMounted: boolean = false;
  private internalFooterHeight: number = 0;
  private internalValue: boolean = this.value;

  get isActive() {
    return this.internalValue;
  }

  set isActive(isActive) {
    if (!isActive) {
      const { closeGuard } = this;
      if (closeGuard && closeGuard(this) === false) {
        return;
      }
    }
    if (this.internalValue !== isActive) {
      this.internalValue = isActive;
      this.$emit('input', isActive);
    }
  }

  get footerHeight() {
    return this.internalFooterHeight;
  }

  get scrollerStyles() {
    return {
      bottom: this.footerHeight + 'px',
    };
  }

  @Watch('value')
  protected valueChangeHandler() {
    this.internalValue = this.value;
  }

  show() {
    this.isActive = true;
  }

  close() {
    this.isActive = false;
  }
}

export const HFullModal = tsx
  .ofType<HFullModalProps, HFullModalEmits, HFullModalScopedSlots>()
  .convert(HFullModalRef);
