/* eslint-disable no-console */
// @ts-nocheck
import './pinch-view.scss';
import {
  SetTransformOpts,
  ApplyChangeOpts,
  ScaleToOpts,
  Mode,
  DEFAULT_MODE,
  PinchViewOptions,
  PinchViewEventHandlerMap,
  PinchViewEventPayloadMap,
  INITIAL_MIN_SCALE,
  DEFAULT_MAX_SCALE,
  PinchViewObservableObject,
  DEFAULT_SCALE_INCREMENT_AMOUNT,
  resolveRawTransitionOptions,
  IncrementScaleOpts,
  ChangeOptions,
  SetPositionOpts,
} from './schemes';
import {
  createMatrix,
  getAbsoluteValue,
  createPoint,
  getMidpoint,
  getDistance,
} from './helpers';
import {
  PointerTracker,
  Pointer,
  InputEvent as PointerTrackerInputEvent,
} from '~/libs/pointer-tracker';
import { Transition } from '~/libs/transition';

/**
 * ピンチビュー
 */
export class PinchView {
  /** ビューポート要素 */
  private _viewport: HTMLElement;

  /**
   * ジェスチャーターゲット要素
   *
   * * viewport指定の要素とは異なる要素でジェスチャーをハンドルしたい場合に指定する
   */
  private _gesutureTarget?: HTMLElement;

  /**
   * 変形させる内包要素
   *
   * * ビューポート要素の直下の要素がセットされる
   * * 理想的には shadow DOM だけど、まだブラウザのサポートがない
   */
  private _body?: Element;

  /** 動作モード */
  private _mode: Mode;

  /** イベントハンドラーマップ */
  private _on: PinchViewEventHandlerMap;

  /** 現在の変形 */
  private _transform: SVGMatrix = createMatrix();

  /**
   * 最小スケール
   *
   * * 初期化後にモードによって計算されるまで利用される
   */
  private _minScale: number = INITIAL_MIN_SCALE;

  /** 最大スケール */
  private _originalMaxScale: number;

  /** 計算済みの最大スケール */
  private _maxScale: number;

  /**
   * スケールを段階インクリメントする際の増加率（0〜1）
   *
   * * 今見えている領域に対しての割合
   * * 0.5を指定した場合、今見えている領域の50%がビューポート全体に広がる
   * * 0.25を指定した場合、今見えている領域の75%がビューポート全体に広がる
   *
   * @default DEFAULT_SCALE_INCREMENT_AMOUNT
   */
  private _scaleIncrementAmount: number;

  /** リサイズ監視用オブザーバ */
  private _resizeObserver?: ResizeObserver;

  /** 内方要素間使用オブザーバ */
  private _mutationObserver?: MutationObserver;

  /** ポインター操作トラッカー */
  private _pointerTracker?: PointerTracker;

  /** ビューポートの幅 */
  private _viewportWidth = 0;

  /** ビューポートの高さ */
  private _viewportHeight = 0;

  /** ビューポートのアスペクト比 */
  private _viewportRatio = 1;

  /** 内包要素の幅 */
  private _bodyWidth = 0;

  /** 内包要素の高さ */
  private _bodyHeight = 0;

  /** 内包要素のアスペクト比 */
  private _bodyRatio = 1;

  /** ビューポートの中心が内方要素の幅に対してどの位置を表示しているかの相対値（0 - 1） */
  private _centerX = 0;

  /** ビューポートの中心が内方要素の高さに対してどの位置を表示しているかの相対値（0 - 1） */
  private _centerY = 0;

  /**
   * 状態購読オブジェクト
   *
   * * 任意のオブジェクトで値の購読を行うことが可能です
   * * 主にReactやVueなどのリアクティブオブジェクトを指定することでUIフレームワークとシームレスに連携が可能です
   */
  private _observeObj?: PinchViewObservableObject;

  /**
   * 実行中のトランジションと到達地点
   */
  private _currentTransition?: Transition;

  /** 動作モード */
  get mode() {
    return this._mode;
  }

  /** ビューポート要素 */
  get viewport() {
    return this._viewport;
  }

  /** ビューポートの幅 */
  get viewportWidth() {
    return this._viewportWidth;
  }

  /** ビューポートの高さ */
  get viewportHeight() {
    return this._viewportHeight;
  }

  /** ビューポートのアスペクト比 */
  get viewportRatio() {
    return this._viewportRatio;
  }

  /** 内包要素の幅 */
  get bodyWidth() {
    return this._bodyWidth;
  }

  /** 内包要素の高さ */
  get bodyHeight() {
    return this._bodyHeight;
  }

  /** 内包要素のアスペクト比 */
  get bodyRatio() {
    return this._bodyRatio;
  }

  /** ビューポートの中心が内方要素の幅に対してどの位置を表示しているかの相対値（0 - 1） */
  get centerX() {
    return this._centerX;
  }

  /** ビューポートの中心が内方要素の高さに対してどの位置を表示しているかの相対値（0 - 1） */
  get centerY() {
    return this._centerY;
  }

  /**
   * 最小スケール
   *
   * * 初期化後にモードによって計算されるまで利用される
   */
  get minScale() {
    return this._minScale;
  }

  /** 最大スケール */
  get maxScale() {
    return this._maxScale;
  }

  /**
   * スケールを段階インクリメントする際の増加率（0〜1）
   *
   * * 今見えている領域に対しての割合
   * * 0.5を指定した場合、今見えている領域の50%がビューポート全体に広がる
   *
   * @default DEFAULT_SCALE_INCREMENT_AMOUNT
   */
  get scaleIncrementAmount() {
    return this._scaleIncrementAmount;
  }

  /** 現在のx座標 */
  get x() {
    return this._transform.e;
  }

  /** 現在のy座標 */
  get y() {
    return this._transform.f;
  }

  /** 現在のスケール */
  get scale() {
    return this._transform.a;
  }

  /**
   * 指定のオプションでピンチビューを初期化する
   *
   * @param options - ピンチビューオプション
   */
  constructor(options?: PinchViewOptions);

  /**
   * 指定のビューポート＆オプションでピンチビューを初期化する
   *
   * @param viewport - ビューポート要素
   * @param options - ピンチビューオプション
   */
  constructor(
    viewport?: HTMLElement,
    options?: Omit<PinchViewOptions, 'viewport'>,
  );

  /**
   * 指定のビューポート＆オプションでピンチビューを初期化する
   *
   * @param viewportOrOptions - ビューポート要素 or ピンチビューオプション
   * @param opts - ピンチビューオプション
   */
  constructor(
    viewportOrOptions?: HTMLElement | PinchViewOptions,
    opts?: Omit<PinchViewOptions, 'viewport'>,
  ) {
    // ビューポート要素＆オプションを整理する
    const options: PinchViewOptions = { ...opts };
    const viewport: HTMLElement =
      viewportOrOptions instanceof HTMLElement
        ? viewportOrOptions
        : options.viewport || document.createElement('div');

    const {
      gesutureTarget = viewport,
      mode = DEFAULT_MODE,
      on,
      maxScale = DEFAULT_MAX_SCALE,
      scaleIncrementAmount = DEFAULT_SCALE_INCREMENT_AMOUNT,
      observe,
    } = options;

    // オプションの保持
    this._gesutureTarget = gesutureTarget;
    this._mode = mode;
    this._on = { ...on };
    this._originalMaxScale = maxScale;
    this._maxScale = maxScale;
    this._scaleIncrementAmount = scaleIncrementAmount;
    this._observeObj = observe;

    if (observe) {
      observe.viewport = viewport;
      observe.maxScale = maxScale;
    }

    // ビューポートの初期化
    this._viewport = viewport;
    this._viewport.setAttribute('data-pinch-view', '');
    this._viewport.setAttribute('data-pinch-view-mode', mode);

    // リサイズ監視オブザーバのセットアップ
    // このオブザーバをビューポートと内方要素で共用する
    this._resizeObserver = new ResizeObserver((entries) => {
      const body = this._body;
      for (const entry of entries) {
        const { target, contentRect } = entry;
        const resizeTarget =
          target === viewport
            ? 'viewport'
            : target === body
            ? 'body'
            : undefined;
        if (resizeTarget) {
          // リサイズされたターゲットがこのインスタンスの管理対象であればリサイズ情報を処理する
          this._handleResize(resizeTarget, contentRect);
        }
      }
    });

    // コンストラクタ内ではビューポートのみ監視を開始しておく
    this._resizeObserver.observe(viewport);

    // ビューポート内要素の変更を監視する
    // このコールバックは初期化時にはトリガーされない
    this._mutationObserver = new MutationObserver(() => this._stageElChange());
    this._mutationObserver.observe(viewport, {
      childList: true,
    });

    // ↑のコールバックは初期化時にはトリガーされないので、初期常態を即時にチェックしておく
    this._stageElChange();

    // ポインター入力を監視する
    this._onPointerStart = this._onPointerStart.bind(this);
    this._onPointerMove = this._onPointerMove.bind(this);

    const pointerTracker: PointerTracker = new PointerTracker(gesutureTarget, {
      avoidPointerEvents: true,
      passive: false,
      start: this._onPointerStart,
      move: this._onPointerMove,
    });

    this._pointerTracker = pointerTracker;

    // トラックパッドのジェスチャーやマウスのホイールイベントを購読する
    this._onWheel = this._onWheel.bind(this);
    gesutureTarget.addEventListener('wheel', this._onWheel);
  }

  /**
   * イベントを通知する
   *
   * @param type - イベント種別
   * @param payload - ペイロード
   */
  emit<Type extends keyof PinchViewEventPayloadMap>(
    type: Type,
    payload: PinchViewEventPayloadMap[Type],
  ) {
    const handler = this._on[type];
    handler && handler(payload);
  }

  /**
   * 現在のモードが指定のモードかチェックする
   *
   * @param mode - モード
   * @returns 指定のモードであった場合true
   */
  modeIs(mode: Mode) {
    return this.mode === mode;
  }

  /**
   * スケールインクリメント（デクリメント）用のベース値を取得する
   *
   * @FIXME
   *   常にビューポートに見えている中央に座標を寄せたいけどうまくいかないので、
   *   現状スケール量だけを計算している
   */
  private _calcIncrementScale(
    vector: 'increment' | 'decrement' = 'increment',
  ): {
    nextScale: number;
  } {
    let nextScale = 0;
    const {
      scaleIncrementAmount,
      viewportWidth,
      viewportHeight,
      bodyWidth,
      bodyHeight,
      minScale,
      maxScale,
    } = this;

    if (!viewportWidth || !viewportHeight || !bodyWidth || !bodyHeight) {
      return {
        nextScale,
      };
    }
    const incrementAmount = 1 - scaleIncrementAmount;
    const baseAmount =
      vector === 'increment' ? 1 / incrementAmount : incrementAmount;
    nextScale = baseAmount * this.scale;

    if (nextScale < minScale) {
      nextScale = minScale;
    } else if (nextScale > maxScale) {
      nextScale = maxScale;
    }

    return {
      nextScale,
    };
  }

  /**
   * スケール率をインクリメントする
   */
  incrementScale(opts?: IncrementScaleOpts) {
    const { nextScale } = this._calcIncrementScale();
    if (!nextScale) return;

    this.scaleTo(nextScale, {
      relativeTo: 'container',
      allowChangeEvent: true,
      originX: '50%',
      originY: '50%',
      ...opts,
    });
  }

  /**
   * スケール率をデクリメントする
   */
  decrementScale(opts?: IncrementScaleOpts) {
    const { nextScale } = this._calcIncrementScale('decrement');
    if (!nextScale) return;

    this.scaleTo(nextScale, {
      relativeTo: 'container',
      allowChangeEvent: true,
      originX: '50%',
      originY: '50%',
      ...opts,
    });
  }

  /**
   * 指定の変形の原点でx/yを調整し、スケールを変更する
   */
  scaleTo(scale: number, opts: ScaleToOpts = {}) {
    let { originX = 0, originY = 0 } = opts;

    const { relativeTo = 'content', allowChangeEvent = false } = opts;

    let { transition } = opts;

    const relativeToEl = relativeTo === 'content' ? this._body : this.viewport;

    // コンテンツエレメントがない場合、スケールを設定するだけでフォールバックする
    if (!relativeToEl || !this._body) {
      this.setTransform({ scale, allowChangeEvent });
      return;
    }

    const { width, height } = relativeToEl.getBoundingClientRect();

    // 要素のサイズがとれない場合、スケールを設定するだけでフォールバックする
    if (!width || !height) {
      this.setTransform({ scale, allowChangeEvent });
      return;
    }

    originX = getAbsoluteValue(originX, width);
    originY = getAbsoluteValue(originY, height);

    if (relativeTo === 'content') {
      originX += this.x;
      originY += this.y;
    } else {
      const currentRect = this._body.getBoundingClientRect();
      originX -= currentRect.left;
      originY -= currentRect.top;
    }

    transition = resolveRawTransitionOptions(transition);
    if (transition) {
      const onDone = transition.onDone;
      transition.onDone = () => {
        this.setTransform({ scale, allowChangeEvent });
        onDone && onDone();
      };
    }

    this._applyChange({
      allowChangeEvent,
      transition,
      originX,
      originY,
      scaleDiff: scale / this.scale,
    });
  }

  /**
   * 指定の座標＆スケールに移動する
   */
  setPosition(opts: SetPositionOpts = {}) {
    const { allowChangeEvent, transition } = opts;

    const { scale = this.scale, x, y } = opts;

    // もろもろのサイズが判定できていない時は何もしない
    const { viewportWidth, viewportHeight, bodyWidth, bodyHeight } = this;
    if (!viewportWidth || !viewportHeight || !bodyWidth || !bodyHeight) {
      return;
    }

    let newX: number;
    let newY: number;

    if (x === undefined) {
      newX = this.x;
    } else {
      const scaledWidth = bodyWidth * scale;
      const position = scaledWidth * x;
      const halfWidth = viewportWidth * 0.5;
      newX = position * -1 + halfWidth;
    }

    if (y === undefined) {
      newY = this.y;
    } else {
      const scaledHeight = bodyHeight * scale;
      const position = scaledHeight * y;
      const halfHeight = viewportHeight * 0.5;
      newY = position * -1 + halfHeight;
    }

    return this.setTransform({
      allowChangeEvent,
      transition,
      scale,
      x: newX,
      y: newY,
    });
  }

  /**
   * 指定のscale/x/yでステージを更新する
   */
  setTransform(opts: SetTransformOpts, withoutScaleDiff?: boolean) {
    const { allowChangeEvent = false, transition } = opts;

    let { scale = this.scale, x = this.x, y = this.y } = opts;

    // 最小、最大スケールを正規化する
    const originalScale = scale;
    if (scale < this.minScale) {
      scale = this.minScale;
    } else if (scale > this.maxScale) {
      scale = this.maxScale;
    }

    // オーバーフロー補正した際は座標の移動を抑制する
    if (originalScale !== scale && !withoutScaleDiff) {
      x = this.x;
      y = this.y;
    }

    // 位置決めする要素がない場合は、与えられた値を設定するだけ
    // 境界のチェックは後で行う
    if (!this._body) {
      this._applyTransform(scale, x, y, { allowChangeEvent });
      return;
    }

    // 現在のレイアウトを取得する
    /** ビューポートのBounds */
    const thisBounds = this.viewport.getBoundingClientRect();
    /** 内方要素のBounds */
    const bodyBounds = this._body.getBoundingClientRect();

    // 表示されていない時や切断済みの時、（display:noneとか）
    // 値を取得するだけで何もしない。あとで境界をチェックする。
    if (!thisBounds.width || !thisBounds.height) {
      this._applyTransform(scale, x, y, { allowChangeEvent });
      return;
    }

    // 内包要素用のポイントを作成する
    let topLeft = createPoint();
    topLeft.x = bodyBounds.left - thisBounds.left;
    topLeft.y = bodyBounds.top - thisBounds.top;
    let bottomRight = createPoint();
    bottomRight.x = bodyBounds.width + topLeft.x;
    bottomRight.y = bodyBounds.height + topLeft.y;

    // 内包要素用の意図する位置を計算する
    const matrix = createMatrix()
      .translate(x, y)
      .scale(scale)
      // 現在の変形を元に戻す
      .multiply(this._transform.inverse());

    topLeft = topLeft.matrixTransform(matrix);
    bottomRight = bottomRight.matrixTransform(matrix);

    // 内包要素の移動可能範囲の補正を行う
    if (this.modeIs('embed')) {
      // 埋め込み型の場合は、内包要素の境界がビューポートの境界から離れない範囲に制限する
      // x軸を修正する
      if (bottomRight.x < thisBounds.width) {
        x += thisBounds.width - bottomRight.x;
      } else if (topLeft.x > 0) {
        x += -topLeft.x;
      }

      // y軸を修正する
      if (bottomRight.y < thisBounds.height) {
        y += thisBounds.height - bottomRight.y;
      } else if (topLeft.y > 0) {
        y += -topLeft.y;
      }
    } else if (this.modeIs('gallery')) {
      // ギャラリー型の場合は、内包要素が外部境界を超えない範囲に制限する
      // x軸を修正する
      if (topLeft.x > thisBounds.width) {
        x += thisBounds.width - topLeft.x;
      } else if (bottomRight.x < 0) {
        x += -bottomRight.x;
      }

      // y軸を修正する
      if (topLeft.y > thisBounds.height) {
        y += thisBounds.height - topLeft.y;
      } else if (bottomRight.y < 0) {
        y += -bottomRight.y;
      }
    }

    this._applyTransform(scale, x, y, { allowChangeEvent, transition });
  }

  /**
   * インスタンスの動作を終了する
   *
   * - 全てのオブザーバ＆トラッカーを停止して破棄する
   * - ビューポート要素に設定した属性＆スタイルを除去する
   */
  destroy() {
    this._abortCurrentTransition();

    const { viewport, _gesutureTarget } = this;

    viewport.removeAttribute('data-pinch-view');
    viewport.removeAttribute('data-pinch-view-mode');

    _gesutureTarget &&
      _gesutureTarget.removeEventListener('wheel', this._onWheel);

    const { style } = viewport;
    style.removeProperty('--x');
    style.removeProperty('--y');
    style.removeProperty('--scale');

    this._disposeResizeObserver();
    this._disposeMutationObserver();
    this._disposePointerTracker();
  }

  /**
   * 境界をチェックせずに変形の値を更新する
   *
   * * これは setTransform > _applyTransform の中でのみ呼び出される
   */
  private _setTransform(
    scale: number,
    x: number,
    y: number,
    allowChangeEvent?: boolean,
  ) {
    // 変化がない場合はリターン
    if (scale === this.scale && x === this.x && y === this.y) return;

    if (scale > this.maxScale) {
      scale = this.maxScale;
    } else if (scale < this.minScale) {
      scale = this.minScale;
    }

    this._transform.e = x;
    this._transform.f = y;
    this._transform.d = this._transform.a = scale;

    // 中心座標の再計算
    const { viewportWidth, viewportHeight, bodyWidth, bodyHeight } = this;
    const scaledWidth = bodyWidth * scale;
    const scaledHeight = bodyHeight * scale;
    const halfX = viewportWidth * 0.5;
    const halfY = viewportHeight * 0.5;
    const cx = halfX + x * -1;
    const cy = halfY + y * -1;
    this._centerX = cx / scaledWidth;
    this._centerY = cy / scaledHeight;

    const { style } = this.viewport;
    style.setProperty('--x', this.x + 'px');
    style.setProperty('--y', this.y + 'px');
    style.setProperty('--scale', this.scale + '');

    if (this._observeObj) {
      this._observeObj.x = this.x;
      this._observeObj.y = this.y;
      this._observeObj.scale = this.scale;
      this._observeObj.centerX = this._centerX;
      this._observeObj.centerY = this._centerY;
    }

    if (allowChangeEvent) {
      this.emit('change', this);
    }
  }

  /**
   * 境界をチェックせずに変形の値を更新する
   *
   * * これは setTransform の中でのみ呼び出される
   */
  private _applyTransform(
    scale: number,
    x: number,
    y: number,
    opts: ChangeOptions = {},
  ) {
    this._abortCurrentTransition();

    const { allowChangeEvent, transition: rawTransition } = opts;

    const transitionOptions = resolveRawTransitionOptions(rawTransition);

    // トランジション設定がない場合は値をセットして即時終了
    if (!transitionOptions) {
      return this._setTransform(scale, x, y, allowChangeEvent);
    }

    // トランジションを生成する
    const { x: beforeX, y: beforeY, scale: beforeScale } = this;
    const xDiff = x - beforeX;
    const yDiff = y - beforeY;
    const scaleDiff = scale - beforeScale;

    const { onDone } = transitionOptions;

    const transition = new Transition({
      ...transitionOptions,
      onChange: () => {
        const { isAborted, isDone } = transition;
        if (!isAborted) {
          if (isDone && onDone) {
            onDone();
            return;
          }
          const { computedProgress } = transition;
          const _x = isDone ? x : beforeX + computedProgress * xDiff;
          const _y = isDone ? y : beforeY + computedProgress * yDiff;
          const _scale = isDone
            ? scale
            : beforeScale + computedProgress * scaleDiff;
          this._setTransform(_scale, _x, _y, allowChangeEvent);
        }
        if (isAborted || isDone) {
          this._abortCurrentTransition();
        }
      },
    });

    this._currentTransition = transition;
    transition.start();
  }

  /**
   * この要素の直接の子要素が変化したときに呼び出されるハンドラ
   *
   * * 全面的に shadow dom をサポートするようになるまでは、ビューポート内の子要素は1つである必要があり、それがパン/スケールを行う要素になる。
   */
  private _stageElChange() {
    const { viewport, _resizeObserver, _body: beforeBody } = this;

    this._body = undefined;

    if (_resizeObserver && beforeBody) {
      _resizeObserver.unobserve(beforeBody);
    }

    const { children } = viewport;

    if (children.length === 0) return;

    this._body = children[0];

    if (children.length > 1) {
      console.warn(
        '[PinchView] viewport element must not have more than one child.',
      );
    }

    this._resizeObserver && this._resizeObserver.observe(this._body);

    // 境界チェックをする
    this.setTransform({ allowChangeEvent: true });
  }

  /**
   * ビューポート内でホイール操作が発生した時のハンドラ
   *
   * @param event - ホイールイベント
   */
  private _onWheel(event: WheelEvent) {
    // 内方要素がなければ何もしない
    if (!this._body) return;

    const { ctrlKey, metaKey } = event;

    // モードに関わらず、コントロールキーやメタキーを押下していなければ何もしない
    // コントロールキーはトラックパッドでピンチズームするときにtrueになる。
    if (!ctrlKey && !metaKey) {
      if (this.modeIs('embed')) {
        this.emit('blockScale', this);
      }
      return;
    }

    // スクロールなどのデフォルトの挙動をキャンセルしておく
    event.preventDefault();

    const currentRect = this._body.getBoundingClientRect();
    let { deltaY } = event;
    const { deltaMode } = event;

    if (deltaMode === 1) {
      // 1 は "行", 0 は "ピクセル"
      // Firefoxはマウスの種類によっては "ライン" を使用する。
      deltaY *= 15;
    }

    // コントロールキーはトラックパッドでピンチズームするときにtrueになる。
    const divisor = ctrlKey ? 100 : 300;
    const scaleDiff = 1 - deltaY / divisor;

    this._applyChange({
      scaleDiff,
      originX: event.clientX - currentRect.left,
      originY: event.clientY - currentRect.top,
      allowChangeEvent: true,
    });
  }

  /**
   * ポイント開始時のハンドラ
   *
   * @param pointer - ポインター
   * @param event - イベントオブジェクト
   */
  private _onPointerStart(
    pointer: Pointer,
    event: PointerTrackerInputEvent,
  ): boolean {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { length } = this._pointerTracker!.currentPointers;

    // 追跡したいのはせいぜい2つのポインタだけ
    if (length === 2 || !this._body) return false;

    this._moveActivated = false;

    return true;
  }

  /** ポインター移動を開始済みか */
  private _moveActivated = false;

  /**
   * ポインター移動時のハンドラ
   *
   * @param previousPointers - 前回のポインターリスト
   * @param changedPointers  - 変更の発生したポインターリスト
   * @param event - イベントオブジェクト
   */
  private _onPointerMove(
    previousPointers: Pointer[],
    changedPointers: Pointer[],
    event: PointerTrackerInputEvent,
  ) {
    const { _pointerTracker, _body } = this;

    if (!_pointerTracker || !_body) return;

    const { currentPointers } = _pointerTracker;

    const isTouch = currentPointers[0].isTouch;

    if (!this._moveActivated) {
      if (isTouch && currentPointers.length < 2) {
        this.emit('blockMove', this);
        return;
      }
      this._moveActivated = true;
      this.emit('moveActivated', this);

      if (isTouch) {
        event.preventDefault();
      }
    }

    // 次のポイントと前のポイントを組み合わせる
    const currentRect = _body.getBoundingClientRect();

    // パンニング動作の計算用
    const prevMidpoint = getMidpoint(previousPointers[0], previousPointers[1]);
    const newMidpoint = getMidpoint(currentPointers[0], currentPointers[1]);

    // 要素内の中間点
    const originX = prevMidpoint.clientX - currentRect.left;
    const originY = prevMidpoint.clientY - currentRect.top;

    // 期待しているスケールの変化を計算する
    const prevDistance = getDistance(previousPointers[0], previousPointers[1]);
    const newDistance = getDistance(currentPointers[0], currentPointers[1]);
    const scaleDiff = prevDistance ? newDistance / prevDistance : 1;

    const opts: ApplyChangeOpts = {
      originX,
      originY,
      scaleDiff,
      panX: newMidpoint.clientX - prevMidpoint.clientX,
      panY: newMidpoint.clientY - prevMidpoint.clientY,
      allowChangeEvent: true,
    };

    this._applyChange(opts);
    this.emit('pointerMove', opts);
  }

  /**
   * 内包要素を変形し、更新イベントをトリガーする
   */
  private _applyChange(opts: ApplyChangeOpts = {}) {
    const {
      panX = 0,
      panY = 0,
      originX = 0,
      originY = 0,
      scaleDiff = 1,
      allowChangeEvent = false,
      transition,
    } = opts;

    const matrix = createMatrix()
      // パンニングに従って移動する
      .translate(panX, panY)
      // 原点を中心としスケールする
      .translate(originX, originY)
      // 現在の移動を適用する
      .translate(this.x, this.y)
      .scale(scaleDiff)
      .translate(-originX, -originY)
      // 現在のスケールを適用する
      .scale(this.scale);

    // 基本的な変形とスケールに変換する
    this.setTransform(
      {
        transition,
        allowChangeEvent,
        scale: matrix.a,
        x: matrix.e,
        y: matrix.f,
      },
      scaleDiff === 1,
    );
  }

  /**
   * 最小スケールを設定する
   *
   * * 最小スケールより現在のスケールが小さければ持ち上げる
   *
   * @param minScale - 最小スケール
   */
  private _setMinScale(minScale: number) {
    if (this._minScale !== minScale) {
      this._minScale = minScale;

      // maxScaleの補正をしておく
      let maxScale = this._originalMaxScale;
      if (minScale > maxScale) {
        maxScale = minScale;
      }

      if (this._maxScale !== maxScale) {
        this._maxScale = maxScale;
        if (this._observeObj) {
          this._observeObj.maxScale = maxScale;
        }
      }

      if (this.scale < minScale) {
        // 最小スケールより現在のスケールが小さければ持ち上げておく
        this.setTransform({ allowChangeEvent: true, scale: minScale });
      } else if (this.scale > maxScale) {
        // 最大スケールより現在のスケールが大きければ下げておく
        this.setTransform({ allowChangeEvent: true, scale: maxScale });
      }

      this.emit('changeMinScale', this);

      if (this._observeObj) {
        this._observeObj.minScale = minScale;
      }
    }
  }

  /**
   * 最小スケールを再計算する
   */
  private _recalcMinScale() {
    const {
      bodyWidth,
      bodyHeight,
      bodyRatio,
      viewportWidth,
      viewportHeight,
      viewportRatio,
    } = this;

    if (!bodyWidth || !bodyHeight) return;

    let minScale = 1;

    if (this.modeIs('embed')) {
      if (viewportRatio > bodyRatio) {
        minScale = viewportHeight / bodyHeight;
      } else {
        minScale = viewportWidth / bodyWidth;
      }
    }

    this._setMinScale(minScale);
  }

  /**
   * 要素のリサイズハンドラ
   *
   * * ビューポートのリサイズ検知時には現在のスケールを極力保つための補正処理を行う
   *
   * @param rect - リサイズ後のサイズ
   */
  private _handleResize(
    target: 'viewport' | 'body',
    rect: { width: number; height: number },
  ) {
    const { width, height } = rect;
    const widthProp = `_${target}Width` as const;
    const heightProp = `_${target}Height` as const;
    const beforeWidth = this[widthProp];
    const beforeHeight = this[heightProp];
    this[widthProp] = width;
    this[heightProp] = height;
    this[`_${target}Ratio`] = height / width;
    this._recalcMinScale();

    const isBody = target === 'body';

    if (isBody) {
      // 内方要素のサイズ変更直後は最小スケールにセットしておく
      // ピンチビュー利用側で初期スケールとかの設定が必要な場合は、そちらでどうぞってことにしておく
      this.scaleTo(this.minScale, { allowChangeEvent: true });
    }

    if (!isBody) {
      // ↓ ターゲットがビューポートなのでスケールの補正処理をする

      /** 幅のスケール率 */
      const widthScale = width / beforeWidth;

      /** 幅スケールの変化量 */
      const widthScaleDiff = Math.abs(1 - widthScale);

      /** 高さのスケール率 */
      const heightScale = height / beforeHeight;

      /** 高さスケールの変化量 */
      const heightScaleDiff = Math.abs(1 - heightScale);

      /** 幅、高さのうち変化量の大きい方のスケール率 */
      const maxScale =
        widthScaleDiff > heightScaleDiff ? widthScale : heightScale;

      this.scaleTo(this.scale * maxScale, {
        allowChangeEvent: true,
      });
    }

    if (this._observeObj) {
      this._observeObj[`${target}Width`] = width;
      this._observeObj[`${target}Height`] = height;
    }

    this.emit(isBody ? 'resizeBody' : 'resizeViewport', this);
  }

  /**
   * リサイズ監視用オブザーバを破棄する
   */
  private _disposeResizeObserver() {
    if (this._resizeObserver) {
      this._resizeObserver.disconnect();
      delete this._resizeObserver;
    }
  }

  /**
   * 内方要素監視用オブザーバを破棄する
   */
  private _disposeMutationObserver() {
    if (this._mutationObserver) {
      this._mutationObserver.disconnect();
      delete this._mutationObserver;
    }
  }

  /**
   * ポイント操作トラッカーを破棄する
   */
  private _disposePointerTracker() {
    if (this._pointerTracker) {
      this._pointerTracker.stop();
      delete this._pointerTracker;
    }
  }

  /**
   * 現在進行中のトランジションがあれば破棄する
   */
  private _abortCurrentTransition() {
    if (!this._currentTransition) return;
    this._currentTransition.abort();
    delete this._currentTransition;
  }
}
