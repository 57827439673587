import { DirectiveFunction, DirectiveOptions } from 'vue/types';

export interface ResizeDirectivePayload {
  width: number;
  height: number;
}

export type ResizeDirectiveHandler = (payload: ResizeDirectivePayload) => any;

export interface ResizeDirectiveValues {
  debounce: number;
  debounceTimerId?: number | null;
  value: ResizeDirectiveHandler;
}

interface ResizableElementOnResize {
  bindingValues: ResizeDirectiveValues;
  callback: ResizeDirectiveHandler;
}

interface ResizableElement extends HTMLElement {
  _onResize?: ResizableElementOnResize;
  _resizeObserver?: ResizeObserver;
}

const IS_BROWSER = typeof document !== 'undefined';

const getDefaults = () => {
  return {
    debounce: 0,
    debounceTimerId: null,
  };
};

const setupValues = (
  values: ResizeDirectiveHandler | ResizeDirectiveValues,
): ResizeDirectiveValues => {
  const defaults = getDefaults();
  if (typeof values === 'function') {
    return {
      ...defaults,
      value: values,
    };
  }

  return {
    ...defaults,
    ...values,
  };
};

const inserted: DirectiveFunction = function inserted(
  el: ResizableElement,
  binding,
) {
  if (!IS_BROWSER || !binding.value) {
    return;
  }
  const bindingValues = setupValues(binding.value);

  const cb = bindingValues.value;
  const callback =
    bindingValues.debounce === 0
      ? (e: ResizeDirectivePayload) => cb(e)
      : (e: ResizeDirectivePayload) => {
          if (bindingValues.debounceTimerId !== null) {
            clearTimeout(bindingValues.debounceTimerId);
          }
          bindingValues.debounceTimerId = window.setTimeout(
            () => cb(e),
            bindingValues.debounce,
          );
        };

  el._onResize = {
    bindingValues,
    callback,
  };

  el._resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const { width, height } = entry.contentRect;
      // eslint-disable-next-line node/no-callback-literal
      callback({ width, height });
    }
  });
  el._resizeObserver.observe(el);
};

const unbind: DirectiveFunction = function unbind(el: ResizableElement) {
  if (!IS_BROWSER) {
    return;
  }
  const { _onResize } = <any>el;
  if (!_onResize) {
    return;
  }
  const { bindingValues } = <ResizableElementOnResize>el._onResize;

  if (bindingValues.debounceTimerId !== null) {
    clearTimeout(bindingValues.debounceTimerId);
  }

  if (el._resizeObserver) {
    el._resizeObserver.unobserve(el);
    el._resizeObserver.disconnect();
    delete el._resizeObserver;
  }

  delete el._onResize;
};

export default {
  name: 'resize',
  inserted,
  unbind,
} as DirectiveOptions;
