import './HBackdrop.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Prop, Model, Watch } from 'vue-property-decorator';
import { VStackPanel } from '@dadajam4/vue-stack';

export interface HBackdropProps {
  value?: boolean;
  transition?: string;
  hideClose?: boolean;
  fullscreen?: boolean;
  persistant?: boolean;
  transparent?: boolean;
  /**
   * 背景透過を初期値より濃くする
   * - rgba(0, 0, 0, 0.8)
   */
  darken?: boolean;
}

export interface HBackdropEmits {
  onBeforeEnter: VStackPanel;
  onInput: boolean;
}

export interface HBackdropScopedSlots {
  default?: HBackdropRef;
  controls?: HBackdropRef;
}

@Component<HBackdropRef>({
  name: 'HBackdrop',

  render() {
    const defaultSlot = this.$scopedSlots.default;
    return (
      <VStackPanel
        {...{
          props: {
            panelClasses:
              'h-backdrop' +
              (this.transparent ? ' h-backdrop--transparent' : '') +
              (this.fullscreen ? ' h-backdrop--fullscreen' : '') +
              (this.darken ? ' h-backdrop--darken' : ''),
            active: this.isActive,
            closeOnClick: !this.persistant,
            closeOnEsc: !this.persistant,
          },
          on: {
            change: (active: boolean) => {
              this.isActive = active;
            },
            beforeEnter: (panel: VStackPanel, el: HTMLElement) => {
              this.$emit('beforeEnter', panel /*, el */);
            },
          },
          scopedSlots: {
            controls: (panel: VStackPanel) => {
              const children: VNode[] = [];
              if (this.$scopedSlots.controls) {
                const controls = this.$scopedSlots.controls(this);
                if (controls) {
                  children.push(...controls);
                }
              }
              if (!this.persistant && !this.hideClose) {
                children.push(
                  <button
                    staticClass="h-backdrop__close"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      this.close();
                    }}>
                    close
                  </button>,
                );
              }
              return children;
            },
          },
        }}>
        <div staticClass="h-backdrop__content">
          {defaultSlot ? defaultSlot(this) : undefined}
        </div>
      </VStackPanel>
    );
  },
})
export class HBackdropRef extends Vue implements HBackdropProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;
  @Prop({ type: String, default: 'fade' }) readonly transition!: string;
  @Prop({ type: Boolean }) readonly hideClose!: boolean;
  @Prop({ type: Boolean }) readonly fullscreen!: boolean;
  @Prop({ type: Boolean }) readonly persistant!: boolean;
  @Prop({ type: Boolean }) readonly transparent!: boolean;
  @Prop({ type: Boolean }) readonly darken!: boolean;

  private internalValue: boolean = this.value;

  get isActive() {
    return this.internalValue;
  }

  set isActive(isActive) {
    if (this.internalValue !== isActive) {
      this.internalValue = isActive;
      this.$emit('input', isActive);
    }
  }

  @Watch('value')
  protected valueChangeHandler() {
    this.internalValue = this.value;
  }

  show() {
    this.isActive = true;
  }

  close() {
    this.isActive = false;
  }
}

export const HBackdrop = tsx
  .ofType<HBackdropProps, HBackdropEmits, HBackdropScopedSlots>()
  .convert(HBackdropRef);
