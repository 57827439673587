import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import HotelViewRoot from '../../../-index';
import { HDrawerMenuData } from '~/components/HDrawer/HDrawerMenu';
import { HNavigationItem } from '~/components';

const HEADER_APPENDS_KEY = 'lang-hotels-hotel-slug-local-navi';

export interface MyHotelLocalNaviProps {}

export interface MyHotelLocalNaviEmits {}

export interface MyHotelLocalNaviScopedSlots {}

@Component<MyHotelLocalNaviRef>({
  inject: ['hotelViewRoot'],
  name: 'MyHotelLocalNavi',

  beforeDestroy() {
    this.hotelViewRoot.localNaviIsReached = false;
    this.$ui.removeHeaderHeightAppends(HEADER_APPENDS_KEY);
  },

  watch: {
    isReached: {
      handler(isReached: boolean) {
        this.hotelViewRoot.localNaviIsReached = isReached;
      },
    },
  },

  mounted() {
    this.isMounted = true;
  },

  render() {
    return (
      <div ref="wrapper" staticClass="my-hotel-local-navi-wrapper">
        <div
          staticClass="my-hotel-local-navi-spacer"
          style={this.spacerStyles}
        />
        <nav
          v-resize={this.handleResize}
          staticClass="my-hotel-local-navi"
          class={this.classes}
          style={this.styles}>
          <ul staticClass="my-hotel-local-navi__list">
            {this.menus.map((menu) => (
              <li key={menu.key} staticClass="my-hotel-local-navi__item">
                <HNavigationItem
                  v-ev={{
                    category: 'move',
                    action: `to-${menu.key}`,
                    id: `headnavi_to-${menu.key}`,
                  }}
                  staticClass="my-hotel-local-navi__link"
                  props={{
                    ...menu,
                    html: true,
                  }}
                />
              </li>
            ))}
          </ul>
        </nav>
      </div>
    );
  },
})
export default class MyHotelLocalNaviRef
  extends Vue
  implements MyHotelLocalNaviProps {
  readonly hotelViewRoot!: HotelViewRoot;

  $refs!: {
    wrapper: HTMLElement;
  };

  private isMounted: boolean = false;
  protected internalHeight: number = 0;

  get classes() {
    return {
      'my-hotel-local-navi--fixed': this.isFixed,
    };
  }

  get navigationStack() {
    return this.hotelViewRoot.navigationStack;
  }

  get menus(): HDrawerMenuData[] {
    return [
      {
        key: 'top',
        label: this.$t('label.pageTopShort') as string,
        to: this.$hotel.location(),
      },
      ...(this.navigationStack.local || []).filter((nav) => !nav.hidden),
    ];
  }

  get headerHeight() {
    return this.$ui.originalHeaderHeight;
  }

  get scrollTop() {
    return this.$window.scrollTop;
  }

  get isReached() {
    const { isMounted } = this;
    const { scrollTop } = this.$window;
    const { wrapper } = this.$refs;
    if (!isMounted || !wrapper) {
      return false;
    }
    const { offsetTop } = wrapper;
    return scrollTop >= offsetTop;
  }

  get isFixed() {
    const { isReached } = this;
    const height = this.internalHeight;

    if (isReached && height > 0) {
      this.$ui.setHeaderHeightAppends({
        key: HEADER_APPENDS_KEY,
        height,
      });
    } else {
      this.$ui.removeHeaderHeightAppends(HEADER_APPENDS_KEY);
    }

    return isReached;
  }

  get styles() {
    const { headerHeight } = this;
    if (!headerHeight) return;
    return {
      top: `${headerHeight}px`,
    };
  }

  get spacerStyles() {
    if (!this.isFixed) return;
    return {
      width: '1px',
      height: `${this.internalHeight}px`,
    };
  }

  protected handleResize(dimention: { width: number; height: number }) {
    this.internalHeight = dimention.height;
  }
}

export const TypedMyHotelLocalNavi = tsx
  .ofType<
    MyHotelLocalNaviProps,
    MyHotelLocalNaviEmits,
    MyHotelLocalNaviScopedSlots
  >()
  .convert(MyHotelLocalNaviRef);
