import './HHotelGalleries.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  crateMergedGalleryCategories,
  Gallery,
  GalleryCategory,
} from '~/schemes';
import { HImg } from '~/components';

export interface HHotelGalleriesProps {
  galleryCategories?: GalleryCategory[];
  galleries?: Gallery[];
}

export interface HHotelGalleriesEmits {
  onClickGallery: Gallery;
}

export interface HHotelGalleriesScopedSlots {}

@Component<HHotelGalleriesRef>({
  name: 'HHotelGalleries',
  render() {
    return (
      <div staticClass="h-hotel-galleries">
        <div staticClass="h-hotel-galleries__categories">
          {this.computedCategories.map((category) => (
            <li staticClass="h-hotel-galleries__category" key={category.id}>
              <h3 staticClass="h-hotel-galleries__category__name">
                {category.name}
              </h3>
              <ul staticClass="h-hotel-galleries__category__images">
                {category.galleries.map((gallery) => (
                  <li
                    staticClass="h-hotel-galleries__category__image"
                    key={gallery.id}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      this.$emit('clickGallery', gallery);
                    }}>
                    <HImg
                      staticClass="h-hotel-galleries__category__image__node"
                      src={this.$res.img(gallery.m)}
                      fitHeight
                      // @memo 一部ブラウザでinview効かない。あきらめる
                      // inview
                    ></HImg>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </div>
      </div>
    );
  },
})
export class HHotelGalleriesRef extends Vue implements HHotelGalleriesProps {
  @Prop({ type: Array, default: () => [] })
  readonly galleryCategories!: GalleryCategory[];

  @Prop({ type: Array, default: () => [] }) readonly galleries!: Gallery[];

  get computedCategories() {
    return crateMergedGalleryCategories(this.galleryCategories, this.galleries);
  }
}

export const HHotelGalleries = tsx
  .ofType<
    HHotelGalleriesProps,
    HHotelGalleriesEmits,
    HHotelGalleriesScopedSlots
  >()
  .convert(HHotelGalleriesRef);
