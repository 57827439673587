import './HCurrencyMenu.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ExchangeRate } from '~/schemes';
import { HBtn, HBtnRef, HIcon, HMenu, HMenuRef } from '~/components';

export interface HCurrencyMenuProps {}

export interface HCurrencyMenuEmits {}

export interface HCurrencyMenuScopedSlots {}

@Component<HCurrencyMenuRef>({
  name: 'HCurrencyMenu',

  render() {
    const { currentExchangeRate, exchangeRates } = this;

    return (
      <div staticClass="h-currency-menu">
        <HBtn
          staticClass="h-currency-menu__activator"
          left
          loading={!currentExchangeRate}
          prependStack
          stack={this.$t('label.currencyDisplay') as string}
          color="plain"
          onClick={(ev) => {
            this.$refs.menu.show(this.$refs.activator.$el);
          }}
          ref="activator">
          {this.label}
          <HIcon name="keyboard-arrow-down" />
        </HBtn>
        <HMenu
          contentClass="h-select__menu"
          distance={0}
          alwaysRender
          width="fit"
          ref="menu">
          {exchangeRates.map((info) => (
            <button
              key={info.base}
              staticClass="h-currency-menu__option"
              class={{
                'h-currency-menu__option--active':
                  !!currentExchangeRate &&
                  info.base === currentExchangeRate.base,
              }}
              type="button"
              value={info.base}
              onClick={(ev) => {
                ev.stopPropagation();
                this.$commons.changeCurrency(info.base);
                this.$refs.menu.close();
              }}>
              <span staticClass="h-currency-menu__option__content">
                <span staticClass="h-currency-menu__option__base">
                  {info.base}
                </span>
                <span staticClass="h-currency-menu__option__name">
                  {info.name}
                </span>
              </span>
            </button>
          ))}
        </HMenu>
      </div>
    );
  },
})
export class HCurrencyMenuRef extends Vue implements HCurrencyMenuProps {
  $refs!: {
    activator: HBtnRef;
    menu: HMenuRef;
  };

  get exchangeRates(): ExchangeRate[] {
    return this.$commons.exchangeRates;
  }

  get currentExchangeRate(): ExchangeRate | undefined {
    return this.$commons.currentExchangeRate;
  }

  get label() {
    const { currentExchangeRate } = this;

    // 通過設定ロード中はラベルはなし！
    if (!currentExchangeRate) return '';

    let label: string = '';
    const { base, chargeFormat } = currentExchangeRate;
    const { prefix, suffix } = chargeFormat;
    const mark = prefix || suffix;
    if (base !== mark) {
      label += `${mark} / `;
    }
    label += base;
    return label;
  }
}

export const HCurrencyMenu = tsx
  .ofType<HCurrencyMenuProps, HCurrencyMenuEmits, HCurrencyMenuScopedSlots>()
  .convert(HCurrencyMenuRef);
