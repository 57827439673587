/* eslint-disable no-undef */
import type { ImageLoupe } from './image-loupe';
import {
  Mode,
  PinchViewPositionAndScale,
  PinchViewEventHandlerMap,
  PinchViewObservableObject,
  createPinchViewObservableSource,
} from '~/libs/pinch-view';

/** 画像ルーペの初期表示（座標＆スケール）設定 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ImageLoupeInitialPosition extends PinchViewPositionAndScale {}

/**
 * 画像ルーペの内包要素
 */
export interface ImageLoupeInternalElement extends HTMLElement {
  $loupe: ImageLoupe;
}

/**
 * 読み込み用プロミスインスタンス
 */
export interface ImageLoupeLoadPromise extends Promise<void> {
  /** 読み込み中の画像src */
  src: string;
}

/**
 * 画像ルーペの常態
 *
 * - `"pending"` 初期化前
 * - `"loading"` 読み込み中
 * - `"loaded"` 読み込み済み
 * - `"error"` 読み込み失敗
 */
export type ImageLoupeState = 'pending' | 'loading' | 'loaded' | 'error';

/**
 * 画像ルーペのイベント毎ペイロードマッピング
 */
export interface ImageLoupeEventPayloadMap {
  /** 常態が変わった時 */
  changeState: ImageLoupeState;

  /** ロードが完了した時 */
  load: ImageLoupe;

  /** 読み込みエラーが発生した時 */
  error: any;

  /** マウントが完了した時 */
  mounted: ImageLoupe;

  /** マウントが解除される前 */
  beforeUnmount: ImageLoupe;

  /** 画像をクリックした時 */
  clickImage: MouseEvent;
}

/**
 * 画像ルーペのイベント種別
 */
export type ImageLoupeEventType = keyof ImageLoupeEventPayloadMap;

/**
 * 画像ルーペのイベントハンドラのマッピング
 */
export type ImageLoupeEventHandlerMap = {
  [Type in ImageLoupeEventType]?: (
    payload: ImageLoupeEventPayloadMap[Type],
  ) => any;
} &
  PinchViewEventHandlerMap;

/**
 * 画像ルーペにおける状態購読オブジェクト
 */
export interface ImageLoupeObservableObject extends PinchViewObservableObject {
  /** 現在の画像ソース */
  src: string;

  /** 状態 */
  state: ImageLoupeState;

  /** 画像の幅 */
  originalWidth: number;

  /** 画像の高さ */
  originalHeight: number;

  /**
   * 内包要素に対するオーバーレイ要素
   *
   * * 地図のように、スケールされたベース部分の上に、レイヤとしてピンなどを重ねたい時に利用する
   */
  overlay: ImageLoupeInternalElement | null;

  /** オーバーレイ要素のx座標 */
  overlayX: number;

  /** オーバーレイ要素のy座標 */
  overlayY: number;

  /** オーバーレイ要素の幅 */
  overlayWidth: number;

  /** オーバーレイ要素の高さ */
  overlayHeight: number;

  /**
   * カバー要素
   *
   * * 内方要素と、インフォメーション要素の間に挿入されている要素
   * * ガイドメッセージの表示などに利用する
   */
  cover: ImageLoupeInternalElement | null;

  /**
   * インフォメーション要素
   *
   * * 全ての要素の上に重なる要素
   * * ビューポートに対して常に相対的に100%の座標になっている
   * * インフォーメーションウィンドウなどに利用する
   */
  info: ImageLoupeInternalElement | null;
}

/** 状態購読オブジェクトをマージする */
function mergeObservable(
  base: ImageLoupeObservableObject,
  overrides?: Partial<ImageLoupeObservableObject>,
) {
  if (!overrides) return base;

  Object.keys(base).forEach((key) => {
    if (key in overrides) {
      (base as any)[key] = (overrides as any)[key];
    }
  });

  return base;
}

/**
 * 画像ルーペにおける状態購読オブジェクトのソースオブジェクトを生成する
 *
 * @returns 画像ルーペにおける状態購読オブジェクトのソース
 */
export function createImageLoupeObservableSource(
  overrides?: Partial<ImageLoupeObservableObject>,
): ImageLoupeObservableObject {
  return mergeObservable(
    {
      ...createPinchViewObservableSource(overrides),
      src: '',
      state: 'pending',
      originalWidth: 0,
      originalHeight: 0,
      overlay: null,
      overlayX: 0,
      overlayY: 0,
      overlayWidth: 0,
      overlayHeight: 0,
      info: null,
      cover: null,
    },
    overrides,
  );
}

/**
 * 画像ルーペの初期化設定
 */
export interface ImageLoupeSettings {
  /** 画像URL */
  src: string;

  /**
   * 画像ロード用関数
   *
   * * 未指定の場合組み込みのローダーを使用します
   */
  imageLoader?: (src: string) => Promise<HTMLImageElement>;

  /** 動作モード */
  mode?: Mode;

  /**
   * 初期変形（位置）情報
   *
   * * この情報は初期化設定で指定されたsrcが一度でも変更された場合破棄されます。
   * * この指定がない、もしくは破棄された以降の画像変更時には `mode` によって指定された初期化処理に準じたスケール＆位置情報が設定されます
   */
  initialPosition?: ImageLoupeInitialPosition;

  /** 最大スケール */
  maxScale?: number;

  /** イベントハンドラ */
  on?: ImageLoupeEventHandlerMap;

  /** 遅延ロードをする場合 */
  lazy?: boolean | IntersectionObserverInit;

  /**
   * 状態購読オブジェクト
   *
   * * 任意のオブジェクトで値の購読を行うことが可能です
   * * 主にReactやVueなどのリアクティブオブジェクトを指定することでUIフレームワークとシームレスに連携が可能です
   */
  observe?: ImageLoupeObservableObject;
}
