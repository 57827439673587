import {
  SpecialPage as RawSpecialPage,
  SpecialPageModule,
} from '../server-middleware/data-server/adapter/ACCOAdapter/@types/index';
import { ResolvedLanugageData } from '../server-middleware/data-server/adapter/ACCOAdapter/schemes/language';
import { AvailableLanguage, LanguageDataMap } from './language';
import { SearchTagCategoryCommons } from '.';

export {
  SpecialPageSpace,
  SpecialPageModule,
  CustomHTMLModule,
  KeyVisualModule,
  ResponsiveBreakpoint,
  TextOrImageAsTextWithOption,
  HeadingModule,
  LeadTextModule,
  HotelIntroductionListModule,
  HotelIntroductionModule,
  AttractiveIntroductionModule,
  AttractiveIntroductionListModule,
  HotelWithAttractionsModule,
  HotelWithAttractionsListModule,
  PartitionModule,
  MediaModule,
  MediaModuleAspectRatio,
  MediaModuleDerivedSize,
  MediaModuleSize,
  MediaModuleType,
  MediaModuleDerivedName,
  SimpleTableModule,
  SimpleTableRow,
  TextModule,
  EmbedModule,
  EmbedFormModule,
  NavigationModule,
  LinkButtonModule,
  LinkButtonListModule,
  ProfileModule,
  BannerModule,
  EmbedMapModule,
  RichTableModule,
  AccordionModule,
} from '../server-middleware/data-server/adapter/ACCOAdapter/@types/index';

/**
 * 特集ページのGF表示で必要なプロパティのみを取得した型
 */
export type SpecialPage = Omit<
  ResolvedLanugageData<RawSpecialPage, AvailableLanguage>,
  | 'createdAt'
  | 'adminName'
  | 'publishedFrom'
  | 'publishedUntil'
  | 'publishedAt'
  | 'published'
> &
  Partial<SearchTagCategoryCommons>;

export interface SpecialPagePriority extends SpecialPage {
  /** 遷移先URL */
  link: string;
}

export interface SearchSpCondition {
  page?: number;
  brandSlug?: string;
}
export interface SpecialPageListMetaInfoLS {
  title: LanguageDataMap<string>;
  siteName: LanguageDataMap<string>;
  description: LanguageDataMap<string>;
}

// パンくずリスト
export interface Breadcrumb {
  title: LanguageDataMap<string>;
  slug: string;
}

/** 内部リンク接頭語 */
export const NAVIGATION_PREFIX = 'sp-';

/** 特集ページの階層構造内区切り文字 */
export const SPECIAL_PAGE_PATH_DELIMITER = '@';

/** 検索可能なモジュールIDリスト */
export const SEARCHABLE_SPECIAL_PAGE_MODULE_IDS = [
  'AttractiveIntroductionList',
  'HotelIntroductionList',
] as const;
export type SearchableSpecialPageModuleId = typeof SEARCHABLE_SPECIAL_PAGE_MODULE_IDS[number];

export type SpecialPageModuleId = SpecialPageModule['moduleId'];

export type SpecialPageModuleById<
  Id extends SpecialPageModuleId
> = ResolvedLanugageData<Extract<SpecialPageModule, { moduleId: Id }>, 'ja'>;

export type SearchableSpecialPageModule<
  Id extends SearchableSpecialPageModuleId = SearchableSpecialPageModuleId
> = SpecialPageModuleById<Id>;

export type SearchableSpecialPageModuleProperties = SearchableSpecialPageModule['properties'];

export function isSearchableSpecialPageModule(
  specialPageModule: ResolvedLanugageData<SpecialPageModule, 'ja'>,
): specialPageModule is SearchableSpecialPageModule {
  return SEARCHABLE_SPECIAL_PAGE_MODULE_IDS.includes(
    specialPageModule.moduleId as SearchableSpecialPageModuleId,
  );
}
