import Vue from 'vue';
import { Context, NuxtAppOptions, Plugin } from '@nuxt/types';
import { Store } from 'vuex/types';
import VueI18n from 'vue-i18n';
import { DEFAULT_LANGUAGE } from '~/schemes';

import dateTimeFormats from '~/i18n/dateTimeFormats';

declare module 'vuex/types' {
  export interface Store<S> {
    $i18n: VueI18n;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $i18n: VueI18n;
  }

  export interface NuxtAppOptions {
    i18n: VueI18n;
  }
}

Vue.use(VueI18n);

const plugin: Plugin = (context, inject) => {
  const { app } = context;
  const i18n = new VueI18n({
    locale: DEFAULT_LANGUAGE,
    fallbackLocale: DEFAULT_LANGUAGE,
    messages: {},
    silentFallbackWarn: true,
    dateTimeFormats,
  });

  context.$i18n = i18n;
  app.i18n = i18n;
};

export default plugin;
