/* eslint-disable no-undef */
import './image-loupe.scss';

import {
  ImageLoupeSettings,
  ImageLoupeState,
  ImageLoupeEventHandlerMap,
  ImageLoupeEventType,
  ImageLoupeEventPayloadMap,
  ImageLoupeLoadPromise,
  ImageLoupeObservableObject,
  ImageLoupeInternalElement,
} from './schemes';
import { createElement as $, loadImage } from './helpers';
import {
  PinchView,
  PinchViewTransform,
  IncrementScaleOpts,
  SetPositionOpts,
} from '~/libs/pinch-view';
export type { ApplyChangeOpts } from '~/libs/pinch-view';

/**
 * 画像ルーペ
 */
export class ImageLoupe {
  /** 初期設定 */
  readonly settings: ImageLoupeSettings;

  /** ホスト要素 */
  readonly $el: HTMLElement;

  /** ジェスチャーをハンドルする要素 */
  readonly $handler: HTMLElement;

  /** ピンチ操作ステージ */
  readonly $stage: HTMLElement;

  /** 画像要素 */
  readonly $image: HTMLElement;

  /**
   * 内包要素に対するオーバーレイ要素
   *
   * * 地図のように、スケールされたベース部分の上に、レイヤとしてピンなどを重ねたい時に利用する
   */
  readonly $overlay: ImageLoupeInternalElement;

  /**
   * カバー要素
   *
   * * 内方要素と、インフォメーション要素の間に挿入されている要素
   * * ガイドメッセージの表示などに利用する
   */
  readonly $cover: ImageLoupeInternalElement;

  /**
   * インフォメーション要素
   *
   * * 全ての要素の上に重なる要素
   * * ビューポートに対して常に相対的に100%の座標になっている
   * * インフォーメーションウィンドウなどに利用する
   */
  readonly $info: ImageLoupeInternalElement;

  /** 現在の画像ソース */
  private _src: string;

  /** 画像ロード用関数 */
  private _imageLoader?: (src: string) => Promise<HTMLImageElement>;

  /**
   * 初期変形（位置）情報
   *
   * * この情報は初期化設定で指定されたsrcが一度でも変更された場合破棄されます。
   * * この指定がない、もしくは破棄された以降の画像変更時には `mode` によって指定された初期化処理に準じたスケール＆位置情報が設定されます
   */
  private _initialPosition?: PinchViewTransform;

  /** マウントしている親要素 */
  private _mountedEl: HTMLElement | null = null;

  /** ピンチビュー */
  private _pinchView: PinchView;

  /** 状態 */
  private _state: ImageLoupeState = 'pending';

  /** イベントハンドラマッピング */
  private _on: ImageLoupeEventHandlerMap;

  /** ビューポート交差監視用のオプション */
  private _lazyOptions?: IntersectionObserverInit;

  /** ビューポート交差監視用のオブザーバ */
  private _intersectionObserver?: IntersectionObserver;

  /** 画像の幅 */
  private _originalWidth = 0;

  /** 画像の高さ */
  private _originalHeight = 0;

  /** 画像読み込み中のプロミスインスタンス */
  private _loadPromise?: ImageLoupeLoadPromise;

  /**
   * 状態購読オブジェクト
   *
   * * 任意のオブジェクトで値の購読を行うことが可能です
   * * 主にReactやVueなどのリアクティブオブジェクトを指定することでUIフレームワークとシームレスに連携が可能です
   */
  private _observeObj?: ImageLoupeObservableObject;

  /** オーバーレイ要素のx座標 */
  private _overlayX = 0;

  /** オーバーレイ要素のy座標 */
  private _overlayY = 0;

  /** オーバーレイ要素の幅 */
  private _overlayWidth = 0;

  /** オーバーレイ要素の高さ */
  private _overlayHeight = 0;

  /** 現在の画像ソース */
  get src() {
    return this._src;
  }

  /** 動作モード */
  get mode() {
    return this._pinchView.mode;
  }

  /** マウントしている親要素 */
  get mountedEl() {
    return this._mountedEl;
  }

  /** 状態 */
  get state() {
    return this._state;
  }

  /** 現在のスケール */
  get scale() {
    return this._pinchView.scale;
  }

  /** 現在の最小スケール */
  get minScale() {
    return this._pinchView.minScale;
  }

  /** 最大スケール */
  get maxScale() {
    return this._pinchView.maxScale;
  }

  /** 現在のx座標 */
  get x() {
    return this._pinchView.x;
  }

  /** 現在のy座標 */
  get y() {
    return this._pinchView.y;
  }

  /** ビューポートの幅 */
  get viewportWidth() {
    return this._pinchView.viewportWidth;
  }

  /** ビューポートの高さ */
  get viewportHeight() {
    return this._pinchView.viewportHeight;
  }

  /** 内方要素の幅 */
  get bodyWidth() {
    return this._pinchView.bodyWidth;
  }

  /** 内方要素の高さ */
  get bodyHeight() {
    return this._pinchView.bodyHeight;
  }

  /** 画像の幅 */
  get originalWidth() {
    return this._originalWidth;
  }

  /** 画像の高さ */
  get originalHeight() {
    return this._originalHeight;
  }

  /** 初期化前 */
  get isPending() {
    return this.state === 'pending';
  }

  /** 読み込み中 */
  get isLoading() {
    return this.state === 'loading';
  }

  /** 読み込み済み */
  get isLoaded() {
    return this.state === 'loaded';
  }

  /** 読み込みエラー中 */
  get isError() {
    return this.state === 'error';
  }

  /** オーバーレイ要素のx座標 */
  get overlayX() {
    return this._overlayX;
  }

  /** オーバーレイ要素のy座標 */
  get overlayY() {
    return this._overlayY;
  }

  /** オーバーレイ要素の幅 */
  get overlayWidth() {
    return this._overlayWidth;
  }

  /** オーバーレイ要素の高さ */
  get overlayHeight() {
    return this._overlayHeight;
  }

  constructor(settings: ImageLoupeSettings) {
    this.settings = settings;

    const {
      src,
      on,
      lazy,
      maxScale,
      mode,
      observe,
      initialPosition,
      imageLoader,
    } = settings;

    this._src = src;
    this._imageLoader = imageLoader;

    this._on = {
      ...on,
    };
    if (observe) {
      observe.src = src;
    }
    this._observeObj = observe;

    if (initialPosition) {
      this._initialPosition = { ...initialPosition };
    }

    if (lazy) {
      this._lazyOptions = lazy === true ? {} : { ...lazy };
    }

    // 画像（変形対象要素）
    this.$image = $('div', {
      class: 'il__img',
      on: {
        click: this._on.clickImage,
      },
    });

    // ステージ
    this.$stage = $('div', { class: 'il__stage' }, [this.$image]);

    // オーバーレイ
    this.$overlay = ($('div', {
      class: 'il__overlay',
    }) as unknown) as ImageLoupeInternalElement;
    this.$overlay.$loupe = this;

    // カバー要素
    this.$info = ($('div', {
      class: 'il__info',
    }) as unknown) as ImageLoupeInternalElement;
    this.$info.$loupe = this;

    // カバー要素
    this.$cover = ($('div', {
      class: 'il__cover',
    }) as unknown) as ImageLoupeInternalElement;
    this.$cover.$loupe = this;

    if (this._observeObj) {
      this._observeObj.overlay = this.$overlay;
      this._observeObj.info = this.$info;
      this._observeObj.cover = this.$cover;
    }

    this.$handler = $(
      'div',
      {
        class: 'il__handler',
      },
      [this.$stage, this.$overlay, this.$cover],
    );

    this.$el = $(
      'div',
      {
        class: 'il',
      },
      [this.$handler, this.$info],
    );

    this._handleChange = this._handleChange.bind(this);
    this._handleResizeBody = this._handleResizeBody.bind(this);

    this._pinchView = new PinchView(this.$stage, {
      gesutureTarget: this.$handler,
      mode,
      maxScale,
      on: {
        change: this._handleChange,
        changeMinScale: this._on.changeMinScale,
        resizeBody: this._handleResizeBody,
        resizeViewport: this._on.resizeViewport,
        blockScale: this._on.blockScale,
        blockMove: this._on.blockMove,
        moveActivated: this._on.moveActivated,
        pointerMove: this._on.pointerMove,
      },
      observe,
    });
  }

  /**
   * イベントを通知する
   *
   * @param type - イベント種別
   * @param payload - ペイロード
   */
  emit<Type extends ImageLoupeEventType>(
    type: Type,
    payload: ImageLoupeEventPayloadMap[Type],
  ) {
    const handler = this._on[type];
    handler && handler(payload);
  }

  /**
   * 指定の要素にマウントする
   *
   * @param parent - マウント対象の要素
   */
  mount(parent: HTMLElement) {
    this._mountedEl = parent;
    parent.appendChild(this.$el);

    const { _lazyOptions } = this;
    if (!_lazyOptions) {
      this.load();
    } else {
      const intersectionObserver = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          const inview = entry.isIntersecting;
          if (inview) {
            this._disposeIntersectionObserver();
            this.load();
          }
        }
      }, _lazyOptions);

      intersectionObserver.observe(this.$el);

      this._intersectionObserver = intersectionObserver;
    }

    this.emit('mounted', this);
  }

  /**
   * 画像をロードする
   */
  load(src: string = this.src) {
    // すでに同じソースを読み込み済みだった場合はそのままreturnする
    if (this.isLoaded && this.src === src) return Promise.resolve();

    // 読み込み中、かつsrcが一致していた場合はそのPromiseインスタンスをreturnする
    if (this._loadPromise && this._loadPromise.src === src)
      return this._loadPromise;

    this._setSrc(src);
    this._setState('loading');

    const loader = this._imageLoader || loadImage;

    const promise = loader(src)
      .then(({ width, height }) => {
        if (!this._loadPromise || this._loadPromise.src !== src) {
          // プロミスインスタンスが存在しない（恐らく破棄されている）か、
          // 異なるsrcのインスタンスが生成されている時は何もしない
          return;
        }

        this._originalWidth = width;
        this._originalHeight = height;

        if (this._observeObj) {
          this._observeObj.originalWidth = width;
          this._observeObj.originalHeight = height;
        }

        const { style } = this.$el;
        style.setProperty('--image-src', `url(${src})`);
        style.setProperty('--original-width', `${width}px`);
        style.setProperty('--original-height', `${height}px`);

        delete this._loadPromise;

        this._setState('loaded');
        this.emit('load', this);
      })
      .catch((err) => {
        if (!this._loadPromise || this._loadPromise.src !== src) {
          // プロミスインスタンスが存在しない（恐らく破棄されている）か、
          // 異なるsrcのインスタンスが生成されている時は何もしない
          return;
        }
        delete this._loadPromise;
        this._setState('error');
        this.emit('error', err);
        throw err;
      }) as ImageLoupeLoadPromise;
    promise.src = src;

    this._loadPromise = promise;

    return promise;
  }

  /**
   * スケール率をインクリメントする
   */
  incrementScale(opts?: IncrementScaleOpts) {
    return this._pinchView.incrementScale(opts);
  }

  /**
   * スケール率をデクリメントする
   */
  decrementScale(opts?: IncrementScaleOpts) {
    return this._pinchView.decrementScale(opts);
  }

  /**
   * 指定の座標＆スケールに移動する
   */
  setPosition(opts?: SetPositionOpts) {
    return this._pinchView.setPosition(opts);
  }

  /**
   * マウントを解除する
   */
  unmount() {
    const { mountedEl } = this;
    if (!mountedEl) return;

    this.emit('beforeUnmount', this);

    mountedEl.removeChild(this.$el);
    this._mountedEl = null;
    delete (this.$cover as any).$loupe;
    delete (this.$info as any).$loupe;
    delete (this.$overlay as any).$loupe;
    this._disposeIntersectionObserver();
  }

  /**
   * マウントを解除してインスタンスを破棄する
   */
  destroy() {
    this.unmount();
    delete this._imageLoader;
    delete this._loadPromise;
  }

  /**
   * 画像ソースプロパティをセットする
   *
   * @param src - 画像ソース
   */
  private _setSrc(src: string) {
    if (this._src === src) return;
    this._src = src;

    // 一度でもソースが更新されたら初期座標とかの情報は破棄しておく
    delete this._initialPosition;

    if (this._observeObj) {
      this._observeObj.src = src;
    }
  }

  /**
   * 状態を設定する
   *
   * @param state - 画像ルーペの常態
   */
  private _setState(state: ImageLoupeState) {
    if (this._state === state) return;
    this._state = state;
    if (this._observeObj) {
      this._observeObj.state = state;
    }
    this.emit('changeState', state);
  }

  private _disposeIntersectionObserver() {
    if (this._intersectionObserver) {
      this._intersectionObserver.disconnect();
      delete this._intersectionObserver;
    }
  }

  /**
   * オーバーレイサイズの再計算を行う
   */
  private _recalcOverlay(view: PinchView = this._pinchView) {
    const { scale, x, y, bodyWidth, bodyHeight } = view;
    const overlayWidth = bodyWidth * scale;
    const overlayHeight = bodyHeight * scale;
    const overlayX = x;
    const overlayY = y;

    this._overlayWidth = overlayWidth;
    this._originalHeight = overlayHeight;
    this._overlayX = overlayX;
    this._overlayY = overlayY;

    const { _observeObj, $el } = this;

    const { style } = $el;

    style.setProperty('--overlay-x', `${overlayX}px`);
    style.setProperty('--overlay-y', `${overlayY}px`);
    style.setProperty('--overlay-width', `${overlayWidth}px`);
    style.setProperty('--overlay-height', `${overlayHeight}px`);

    if (_observeObj) {
      _observeObj.overlayWidth = overlayWidth;
      _observeObj.overlayHeight = overlayHeight;
      _observeObj.overlayX = overlayX;
      _observeObj.overlayY = overlayY;
    }
  }

  /**
   * ピンチ状態変更検知時のハンドラ
   *
   * オーバーレイ座標の更新を行う
   *
   * @param view - ピンチビューインスタンス
   */
  private _handleChange(view: PinchView) {
    this._recalcOverlay(view);
    const handler = this._on.change;
    handler && handler(view);
  }

  /**
   * 内方要素のリサイズハンドラ
   *
   * 初期スケール＆座標のチェック＆補正を行う
   *
   * @param view - ピンチビューインスタンス
   */
  private _handleResizeBody(view: PinchView) {
    const { bodyWidth, bodyHeight } = view;
    if (bodyWidth && bodyHeight) {
      const { _initialPosition } = this;
      if (_initialPosition) {
        delete this._initialPosition;
        this._pinchView.setPosition({
          ..._initialPosition,
          allowChangeEvent: true,
        });
      } else {
        // 初期座標情報が消化済みの時は、座標がよっている可能性があるので、センターに寄せる
        this._pinchView.setPosition({ x: 0.5, y: 0.5 });
      }
    }
    this._recalcOverlay();
    const handler = this._on.resizeBody;
    handler && handler(view);
  }
}
