import type { HVideoPlayerRef } from './HVideoPlayer';

/**
 * 全 `HVideoPlayer` の再生状況をコントロールするためのマネージャークラス
 */
export class VideoPlayerManager {
  static readonly videos: HVideoPlayerRef[] = [];

  static join(video: HVideoPlayerRef) {
    if (process.server || this.videos.includes(video)) return;

    this.videos.push(video);

    video.$on('play', (ev) => {
      if (!video.isAutoplay) {
        this.pause(video);
      }
    });
  }

  static pause(excludes?: HVideoPlayerRef | HVideoPlayerRef[]) {
    const _excludes =
      excludes && !Array.isArray(excludes) ? [excludes] : excludes;

    this.videos.forEach((video) => {
      if (
        video.isDisableAutoPause ||
        (_excludes && _excludes.includes(video))
      ) {
        return;
      }
      video.pause();
    });
  }

  static leave(video: HVideoPlayerRef) {
    const index = this.videos.indexOf(video);
    if (index !== -1) {
      this.videos.splice(index, 1);
    }
  }
}
