import { Config as WebFontConfig } from 'webfontloader';

export interface TypekitConfig
  extends Pick<
    WebFontConfig,
    | 'loading'
    | 'active'
    | 'inactive'
    | 'fontloading'
    | 'fontactive'
    | 'fontinactive'
  > {
  id: string;
  scriptTimeout?: number;
}

const loader = function (config: TypekitConfig | string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (typeof config === 'string') {
      config = { id: config };
    }

    let finished = false;
    let timeoutId: number | null = null;

    const html = document.documentElement;
    const { scriptTimeout } = config;

    if (scriptTimeout) {
      timeoutId = setTimeout(() => {
        if (!finished) {
          html.className =
            html.className.replace(/\btypekit-loading\b/g, '') +
            ' typekit-inactive';
          reject(new Error('typekit timeout'));
        }
      }, config.scriptTimeout);
    }

    const tipeKitScript = document.createElement('script');
    const myScript = document.getElementsByTagName('script')[0];
    html.className += ' typekit-loading';
    tipeKitScript.src = `https://use.typekit.net/${config.id}.js`;
    tipeKitScript.async = true;
    tipeKitScript.onload = () => {
      const readyState = (tipeKitScript as any).readyState as string;
      if (
        finished ||
        (readyState && readyState !== 'complete' && readyState !== 'loaded')
      )
        return;

      finished = true;
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      try {
        const promise = (window as any).Typekit.load(config) as Promise<void>;
        promise.then(resolve).catch(reject);
      } catch (e) {}
    };
    (myScript.parentNode as Node).insertBefore(tipeKitScript, myScript);
  });
};

export default loader;
