import Vue from 'vue';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';
import { startOfMonth, addMonths, format as dateFormat } from 'date-fns';
import { safeDateStrings, toDate, toInt } from '~/helpers';

export interface HCalendarSelectedModel {
  from?: Date;
  to?: Date;
}

export interface CalendarBaseMixinProps {
  value?: string[];
  month?: string | Date;

  /**
   * 開始日 e.g. 0 → 日曜日、1 → 月曜日
   * @see https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
   */
  startDay?: number | string;

  /**
   * 祝日の曜日設定
   */
  holidays?: number[];

  /**
   * 画面幅が狭い時に左右にレイアウトを広げるか
   */
  pullNarrow?: boolean;
}

export interface CalendarBaseMixinEmits {
  onInput: string[];
  onChangeMonth: string | Date;
}

@Component<CalendarBaseMixin>({
  name: 'calendar-model-mixin',
})
export class CalendarBaseMixin extends Vue implements CalendarBaseMixinProps {
  @Model('input', { type: Array, default: () => [] }) readonly value!: string[];
  @Prop({ type: [String, Date], default: () => new Date() }) readonly month!:
    | string
    | Date;

  @Prop({ type: [String, Number], default: 1 }) readonly startDay!:
    | number
    | string;

  @Prop({ type: Array, default: () => [0] }) readonly holidays!: number[];
  @Prop({ type: Boolean }) readonly pullNarrow!: boolean;

  protected internalMonth: string | Date = this.month;
  protected internalValue: string[] = safeDateStrings(this.value);

  get selectedDates() {
    return this.internalValue;
  }

  set selectedDates(selectedDates) {
    selectedDates = safeDateStrings(selectedDates);
    if (selectedDates.length > 1) {
      selectedDates = selectedDates.sort((a, b) => {
        const at = toDate(a).getTime();
        const bt = toDate(b).getTime();
        if (at < bt) return -1;
        if (at > bt) return 1;
        return 0;
      });
    }
    const newValues: string[] = [];
    let hasChanged = newValues.length !== this.internalValue.length;
    selectedDates.forEach((str, i) => {
      if (newValues[i] !== selectedDates[i]) {
        hasChanged = true;
        newValues[i] = str;
      }
    });
    if (hasChanged) {
      this.internalValue = newValues;
      this.$emit('input', newValues);
    }
  }

  get selectedModel(): HCalendarSelectedModel {
    const selectedModel: HCalendarSelectedModel = {};
    const [from, to] = this.selectedDates;
    if (from) {
      selectedModel.from = toDate(from);
    }
    if (to) {
      selectedModel.to = toDate(to);
    }
    return selectedModel;
  }

  get currentMonth() {
    return this.internalMonth;
  }

  set currentMonth(currentMonth) {
    if (this.internalMonth !== currentMonth) {
      this.internalMonth = currentMonth;
      this.$emit('changeMonth', currentMonth);
    }
  }

  shift(amount: number) {
    const { monthInstance } = this;
    const next = addMonths(monthInstance, amount);
    this.currentMonth = dateFormat(next, 'yyyy-MM-dd');
  }

  prev() {
    return this.shift(-1);
  }

  next() {
    return this.shift(1);
  }

  @Watch('month')
  protected monthChangeHandler() {
    this.internalMonth = this.month;
  }

  get monthInstance(): Date {
    return toDate(this.currentMonth);
  }

  get nextMonthInstance(): Date {
    return addMonths(this.monthInstance, 1);
  }

  get nextMonth(): string {
    return dateFormat(this.nextMonthInstance, 'yyyy-MM-dd');
  }

  get yearValue() {
    return this.monthInstance.getFullYear();
  }

  get monthValue() {
    return this.monthInstance.getMonth();
  }

  get startDate(): Date {
    return startOfMonth(this.monthInstance);
  }

  get nextMonthStartDate(): Date {
    return startOfMonth(this.nextMonthInstance);
  }

  get computedStartDay() {
    return toInt(this.startDay);
  }

  /**
   * 曜日のラベルリスト
   */
  get computedDayLabels() {
    const computedDayLabels: { label: string; day: number }[] = [];
    const dayLabels = this.$t('label.dayOfWeek');
    this.eachDays((day) => {
      computedDayLabels.push({
        label: dayLabels[day],
        day,
      });
    });
    return computedDayLabels;
  }

  get formatedMonthLabel(): string {
    return this.$t('label.yearMonth', {
      year: this.yearValue,
      month: this.$t('label.month')[this.monthValue],
    }) as string;
  }

  /**
   * ソートされた曜日順にコールバック列挙する
   */
  eachDays(cb: (number) => any) {
    const { computedStartDay: startDay } = this;

    for (let i = 0; i < 7; i++) {
      let day = startDay + i;
      if (day > 6) {
        day = day % 7;
      }
      cb(day);
    }
  }

  isHoliday(dayOfWeek: number) {
    return this.holidays.includes(dayOfWeek);
  }

  clear() {
    this.selectedDates = [];
  }

  @Watch('value')
  protected valueChangeHandler() {
    this.internalValue = safeDateStrings(this.value);
  }
}
