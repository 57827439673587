import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { HTabsRef } from './HTabs';

export interface HTabsSliderProps {}

export interface HTabsSliderEmits {}

export interface HTabsSliderScopedSlots {}

@Component<HTabsSliderRef>({
  name: 'h-tabs-slider',

  inject: ['tabs'],

  render(h) {
    return h('i', {
      staticClass: 'h-tabs-slider',
      style: this.styles,
    });
  },
})
export class HTabsSliderRef extends Vue implements HTabsSliderProps {
  readonly tabs!: HTabsRef;

  private left: number = 0;
  private width: number = 0;

  get isMini() {
    return this.tabs.isMini;
  }

  get styles() {
    return this.isMini
      ? {
          top: this.left + 'px',
          height: this.width + 'px',
        }
      : {
          left: this.left + 'px',
          width: this.width + 'px',
        };
  }

  get activeTag() {
    return this.tabs.activeTag;
  }

  @Watch('activeTag', { immediate: true })
  @Watch('isMini')
  updatePosition() {
    const { activeTag, isMini } = this;
    if (!activeTag) {
      this.left = 0;
      this.width = 0;
      return;
    }

    let left: number, width: number;
    const { $el } = activeTag;
    if (isMini) {
      left = $el.offsetTop;
      width = $el.offsetHeight;
    } else {
      left = $el.offsetLeft;
      width = $el.offsetWidth;
    }

    this.left = left;
    this.width = width;
    this.tabs.scrollToTab(activeTag, left, width);
  }
}

export const HTabsSlider = tsx
  .ofType<HTabsSliderProps, HTabsSliderEmits, HTabsSliderScopedSlots>()
  .convert(HTabsSliderRef);
