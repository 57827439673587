/**
 * 例外発生時に、リクエスト対象が限定されている場合の情報
 */
export interface GFErrorTarget {
  /**
   * リソース要求時のメソッド
   *
   * lowerCase
   */
  method?: string;

  /**
   * URLとかS3のキーパスとか、そういうの
   */
  path?: string;

  /**
   * リクエストパラメータ
   */
  params?: any;

  /**
   * リクエストボディ
   */
  data?: any;
}

/**
 * GFError を構成するためのオブジェクト情報
 */
export interface GFErrorSourceObject {
  status: number;
  name: string;
  message: string;
  stack?: string;
  /**
   * リクエスト対象情報
   */
  target?: GFErrorTarget;
}

/**
 * GFError を生成するためのソース
 *
 * - `string` エラーメッセージ
 * - `number` ステータスコード
 * - `Partial<GFErrorSourceObject>` エラー情報
 */
export type GFErrorSource = string | number | Partial<GFErrorSourceObject>;

/**
 * GFErrorのJSON情報
 */
export interface GFErrorJSON {
  _isGFError: true;
  name: string;
  message: string;
  stack?: string;
  status: number;

  /**
   * リクエスト対象情報
   */
  target?: GFErrorTarget;
}

/**
 * 指定した変数が GFErrorJSON であるかチェックする
 *
 * @param source - 検査したい変数
 * @returns
 */
export function isGFErrorJSON(source: unknown): source is GFErrorJSON {
  return (
    !!source &&
    typeof source === 'object' &&
    (source as GFErrorJSON)._isGFError === true
  );
}
