import './HGlobalFooter.scss';
import Vue from 'vue';
import * as tsx from 'vue-tsx-support';
import { Component } from 'vue-property-decorator';
import { HIcon, HAllBrandsNavigation, HIconLink } from '~/components';
import { calculateAnniversary } from '~/utils/date';

export interface HGlobalFooterProps {}
export interface HGlobalFooterEmits {}
export interface HGlobalFooterScopedSlots {}

/**
 * 重要リンク
 */
interface ImportantLink {
  label: string;
  href: string;
  target: string;
}

@Component<HGlobalFooterRef>({
  name: 'HGlobalFooter',
  render() {
    return (
      <footer staticClass="h-globalFooter">
        {/* ブランドナビゲーション */}
        <div staticClass="h-globalFooter__inner">
          <HAllBrandsNavigation staticClass="h-globalFooter__brands" />

          <div staticClass="h-globalFooter__buttonArea">
            <div staticClass="h-globalFooter__buttonArea__button">
              <a
                href={this.crossSearchUrl}
                staticClass="h-globalFooter__buttonArea__button--vacancySearch"
                target="_blank">
                {this.$i18n.t('name.roomFinder')}
              </a>
            </div>
          </div>

          {/* 重要リンク */}
          <div staticClass="h-globalFooter__importantLinkArea">
            {this.importantLinkMenus.map(({ label, href, target }) => (
              <HIconLink
                staticClass="h-globalFooter__importantLinkArea__item"
                key=""
                icon="arrow-right"
                href={href}
                target={target}
                rel="noopener"
                hiddenExternalIcon>
                {label}
              </HIconLink>
            ))}
          </div>

          <div staticClass="h-globalFooter__copyrightArea">
            <div
              staticClass="h-globalFooter__copyrightArea__logoWrapper"
              // logo_en だけ小さく見えるので個別でwidthを設定している
              style={this.useEnglishLangLogo ? { maxWidth: '180px' } : {}}>
              <a
                href={this.$t('url.globalTop') as string}
                target="_blank"
                staticClass="h-globalFooter__copyrightArea__logo">
                <img
                  staticClass="h-globalFooter__copyrightArea__logo--years"
                  src={this.yearsLogo}
                  alt={
                    this.$t('label.companyAnniversary', {
                      year: this.companyAnniversary,
                    }) as string
                  }
                />
                <img
                  staticClass="h-globalFooter__copyrightArea__logo--hr"
                  src={this.hrLogo}
                  alt={this.$i18n.t('name.hr') as string}
                />
              </a>
            </div>
            <p staticClass="h-globalFooter__copyrightArea__copyrightWrapper">
              <small staticClass="h-globalFooter__copyrightArea__copyright">
                &copy; Hoshino Resorts Inc. All Rights Reserved.
              </small>
            </p>
          </div>
        </div>
      </footer>
    );
  },
})
export class HGlobalFooterRef extends Vue implements HGlobalFooterProps {
  /** 星野リゾートロゴの画像URL */
  get hrLogo() {
    return `${this.$res.img(
      `/common/logo-hr-${this.$language.current}_wh.svg`,
    )}`;
  }

  /** 横断検索のURL */
  get crossSearchUrl() {
    return this.$language.crossSearchUrl();
  }

  /**
   * 英語ロゴを使ってるかどうか
   *
   * * logo_en だけ小さく見えるので個別でwidthを設定している
   */
  get useEnglishLangLogo() {
    return this.$language.current === 'en' || this.$language.current === 'ko';
  }

  /** 重要リンク情報 */
  get importantLinkMenus(): ImportantLink[] {
    return [
      {
        label: this.$t('label.pressCenter') as string,
        href: this.$t('url.pressCenter') as string,
        target: '_blank',
      },
      {
        label: this.$t('label.REITIntro') as string,
        href: this.$t('url.REIT') as string,
        target: '_blank',
      },
      {
        label: this.$t('label.recruitInfo') as string,
        href: this.$t('url.recruit') as string,
        target: '_blank',
      },
    ];
  }

  /**
   * 周年表示のロゴを取得
   * @memo きっとこれで周年表示が自動で切り替わってくれるはず...
   */
  get yearsLogo() {
    return this.$language.current === 'ja'
      ? `${this.$res.img(`/common/${this.companyAnniversary}-ja.svg`)}`
      : `${this.$res.img(`/common/${this.companyAnniversary}-en.svg`)}`;
  }

  /** 会社の設立年数を取得 */
  get companyAnniversary() {
    return calculateAnniversary(this.$env.renderingDateTime);
  }
}

export const HGlobalFooter = tsx
  .ofType<HGlobalFooterProps, HGlobalFooterEmits, HGlobalFooterScopedSlots>()
  .convert(HGlobalFooterRef);
