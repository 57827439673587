import Vue from 'vue';
import { Context, Plugin, NuxtAppOptions } from '@nuxt/types';
import Router, { Route, RawLocation, Location } from 'vue-router';
import { NavigationStack } from '~/store/navigation';
import { HDrawerMenuData } from '~/components/HDrawer/HDrawerMenu';
import { AVAILABLE_LANGUAGES } from '~/schemes';

declare module 'vue/types/vue' {
  export interface Vue {
    $navigation: NavigationService;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $navigation: NavigationService;
  }
}

declare module '@nuxt/types' {
  export interface NuxtAppOptions {
    $navigation: NavigationService;
  }

  export interface Context {
    $navigation: NavigationService;
  }
}

const LINK_SANITIZE_RE = /^https?:\/\/~/;

export const EXTERNAL_LINK_RE = /^https?:\/\//;

export const ASSET_LINK_RE = /.*\.(jpg|jpeg|gif|png|pdf)(\?.*)?/i;

const RAW_ALIAS_RE = new RegExp(
  `^/(${AVAILABLE_LANGUAGES.join('|')})/(hotels/([0-9a-zA-Z-_]+))?`,
);

const HOTEL_RE = /\/(hotels\/([0-9a-zA-Z-_]+))/;
// export interface NavigationServiceLocation {
//   name: string | null;

// }

export interface NavigationServiceState {
  current: Omit<Route, 'matched' | 'meta'>;
}

/**
 * URL解析結果
 *
 * * 任意のURL文字列に対して解析されたリンクVNode描画用のオプション値のオブジェクトインターフェース
 */
export interface URLParsedResult {
  /**
   * タグ名
   *
   * * `'nuxt-link' | 'a'`
   */
  TagName: any;

  /**
   * コンポーネントプロパティ
   *
   * * GF内部リンクの場合のみ、nuxt-linkに渡されるプロパティになる
   */
  props?: { [key: string]: any };

  /**
   * HTML要素属性
   *
   * * 外部リンクの場合のみ、aタグに設定される属性になる
   */
  attrs?: { [key: string]: any };
}

export class NavigationService {
  readonly context: Context;

  private _developRequestOrigin: string = '';
  private _state: NavigationServiceState;
  beforeRoute: Route | null = null;

  get developRequestOrigin() {
    return this._developRequestOrigin;
  }

  get origin() {
    const { $env } = this.context;
    return $env.isDevelop ? this.developRequestOrigin : $env.origin;
  }

  get router(): Router {
    return this.context.app.router as Router;
  }

  get stacks(): NavigationStack[] {
    return this.context.store.state.navigation.stacks;
  }

  get locals(): HDrawerMenuData[] {
    return this.context.store.getters['navigation/locals'];
  }

  get current() {
    return this._state.current;
  }

  // private _location: {
  //   name: string | null;
  //   path: string | null;
  //   hash: string | null;
  //   query:
  // };

  private setCurrent(current: NavigationServiceState['current']) {
    this._state.current = {
      name: current.name,
      path: current.path,
      hash: current.hash,
      query: {
        ...current.query,
      },
      params: {
        ...current.params,
      },
      fullPath: current.fullPath,
      redirectedFrom: current.redirectedFrom,
    };
  }

  constructor(context: Context) {
    this.context = context;
    const { route } = context;
    this._state = Vue.observable({
      current: {
        name: route.name,
        path: route.path,
        hash: route.hash,
        query: {
          ...route.query,
        },
        params: {
          ...route.params,
        },
        fullPath: route.fullPath,
        redirectedFrom: route.redirectedFrom,
      },
    });
    this.init();
  }

  private init() {
    if (process.server) {
      const { req, $env } = this.context;
      if ($env.isDevelop) {
        const host = req.headers.host;
        const proto = req.headers['x-forwarded-proto'];
        const origin = `${proto || 'http'}://${host}`;
        this._developRequestOrigin = origin;
      }
      return;
    }
    this._developRequestOrigin = location.origin;

    this.router.afterEach((to, from) => {
      this.setCurrent(to);
      this.beforeRoute = from;
    });
  }

  resolveHrefTo(
    href?: string,
  ): {
    _href?: string;
    href?: string;
    target?: string;
    to?: Location;
  } {
    const result: {
      _href?: string;
      href?: string;
      target?: string;
      to?: Location;
    } = {};
    if (href) {
      // 「http://~/dining/」 とかなってる事があるので消毒する
      href = href.replace(LINK_SANITIZE_RE, '~');

      if (href.startsWith('~')) {
        // カレントの言語＆施設スラッグで補正する
        let resolved = '';
        const matches = this.current.path.match(RAW_ALIAS_RE) || [];
        const lang = matches[1];
        // const hotel = hrefHotelMatch ? hrefHotelMatch[1] : matches[2];
        if (lang) {
          resolved += `/${lang}`;
        }

        const hrefHotelMatch = href.match(HOTEL_RE);
        let hotel = hrefHotelMatch && hrefHotelMatch[1];
        if (!hotel) {
          const _hotel = this.context.$hotel.current;
          if (_hotel) {
            hotel = _hotel.slug;
          }
        }
        if (hotel) {
          resolved += `/hotels/${hotel}`;
        }
        href = resolved + href.slice(1);
        result.to = { path: href };
        result._href = href;
      }
      if (ASSET_LINK_RE.test(href)) {
        result.href = this.context.$res.img(href);
        result.target = '_blank';
        result._href = result.href;
      } else if (EXTERNAL_LINK_RE.test(href)) {
        result.href = href;
        result.target = '_blank';
        result._href = href;
      } else {
        result.to = { path: href };
        result._href = href;
      }
    }
    return result;
  }

  /**
   * 指定のURLを解析して、リンク文字列解析結果を取得する
   *
   * @param url - リンクURL
   * @param overrideAttrs - 追加したいHTML属性
   * @returns URL解析結果
   */
  parseURL(
    url: string,
    overrideAttrs?: { [key: string]: any },
  ): URLParsedResult {
    const loc = this.resolveHrefTo(url);
    const isRouterLink = !!loc.to;
    const TagName: any = isRouterLink ? 'nuxt-link' : 'a';
    const props = isRouterLink ? loc : undefined;
    const attrs = !isRouterLink
      ? {
          href: url,
          target: '_blank',
          rel: 'noopener',
          ...overrideAttrs,
        }
      : overrideAttrs || undefined;
    return {
      TagName,
      props,
      attrs,
    };
  }

  isSameRoute(a: Route, b: Route): boolean {
    return a.fullPath === b.fullPath;
  }

  backOrGo(location: RawLocation) {
    if (!location) return this.router.back();
    const { beforeRoute } = this;
    if (!beforeRoute) {
      this.router.push(location);
      return;
    }
    const { resolved } = this.router.resolve(location);
    if (this.isSameRoute(resolved, beforeRoute)) {
      this.router.back();
    } else {
      this.router.push(location);
    }
  }

  addStack(stack: NavigationStack) {
    this.context.store.commit('navigation/ADD_STACK', stack);
  }

  removeStack(stack: NavigationStack) {
    this.context.store.commit('navigation/REMOVE_STACK', stack);
  }

  getHashByRawLocation(location?: RawLocation): string | undefined {
    if (!location) return;
    if (typeof location === 'string') {
      location = {
        path: location,
      };
    }
    let hash = location.hash;
    if (location.hash) {
      hash = location.hash;
    } else {
      const { path = '' } = location;
      hash = path.split('#')[1];
    }
    return hash || undefined;
  }

  /**
   * ページ内に指定のハッシュの要素があればそこにスクロールしてtrueを返却する
   */
  tryScrollToHash(hash: string) {
    const $el = document.getElementById(hash);
    if ($el) {
      const result = this.context.$window.toElement($el, {
        offset: -this.context.$ui.headerHeight,
      });
      /**
       * @todo
       * 遅延ロード画像などの影響で動的にスクロールゴール位置がずれる事があるので、
       * スクロール後に強制的に補正する
       * マウスホイールなどで、スクロールキャンセルした時のエスケープもしないといけないので
       * 追ってプラグイン側を修正する
       */
      result.promise.then(() => {
        const $el = document.getElementById(hash);
        if ($el) {
          this.context.$window.toElement($el, {
            offset: -this.context.$ui.headerHeight,
            duration: 0,
          });
        }
      });
      this.context.$ui.closeDrawer();
      return true;
    }
  }

  resolveRoutableAction(e: MouseEvent, location?: RawLocation) {
    if (!location) {
      location = (e.target && (e.target as any).href) || '';
    }

    // eslint-disable-next-line no-useless-return
    if (!location || e.ctrlKey || e.metaKey) return;

    e.preventDefault();

    const hash = this.getHashByRawLocation(location);

    if (hash && this.tryScrollToHash(hash)) {
      return;
    }
    // if (hash) {
    //   const $el = document.getElementById(hash);
    //   if ($el) {
    //     const result = this.context.$window.toElement($el, {
    //       offset: -this.context.$ui.headerHeight,
    //     });
    //     /**
    //      * @todo
    //      * 遅延ロード画像などの影響で動的にスクロールゴール位置がずれる事があるので、
    //      * スクロール後に強制的に補正する
    //      * マウスホイールなどで、スクロールキャンセルした時のエスケープもしないといけないので
    //      * 追ってプラグイン側を修正する
    //      */
    //     result.promise.then(() => {
    //       const $el = document.getElementById(hash);
    //       if ($el) {
    //         this.context.$window.toElement($el, {
    //           offset: -this.context.$ui.headerHeight,
    //           duration: 0,
    //         });
    //       }
    //     });
    //     this.context.$ui.closeDrawer();
    //     return;
    //   }
    // }

    const handler = location.replace ? 'replace' : 'push';
    this.router[handler](location);
  }
}

const plugin: Plugin = (context, inject) => {
  const service = new NavigationService(context);
  context.$navigation = service;
  inject('navigation', service);
};

export default plugin;
