import './HCarouselMedia.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MediaModuleProps, MediaModuleProperties } from './schemes';
import { createMediaModuleDerivedName } from './helpers';
import { HInfiniteCarousel, HInfiniteCarouselItem } from '~/components';
import { getMediaDerived } from '~/schemes';

export interface HCarouselMediaProps extends MediaModuleProps {}

export interface HCarouselMediaEmits {}

export interface HCarouselMediaScopedSlots {}

/**
 * カルーセルメディアが利用する派生メディア
 * *現状 1:1 のmd（768px）しか利用しない
 */
const DRIVED_NAME = createMediaModuleDerivedName('md', '1/1');

@Component<HCarouselMediaRef>({
  name: 'HCarouselMedia',

  render(h) {
    const { items } = this;

    if (!items.length) {
      return h();
    }

    return (
      <HInfiniteCarousel
        staticClass="h-carousel-media"
        items={items}
        scopedSlots={{
          item: ({ item }) => {
            const image = item.images[0];

            return (
              <div
                staticClass="h-carousel-media__item"
                style={{
                  backgroundImage: `url(${image.image})`,
                }}
              />
            );
          },
        }}
      />
    );
  },
})
export class HCarouselMediaRef extends Vue implements HCarouselMediaProps {
  @Prop({ type: Object, required: true })
  readonly value!: MediaModuleProperties;

  get items(): HInfiniteCarouselItem[] {
    return this.value.items.map((item) => {
      return {
        images: {
          image: getMediaDerived(DRIVED_NAME, item, ''),
        },
      };
    });
  }
}

export const HCarouselMedia = tsx
  .ofType<HCarouselMediaProps, HCarouselMediaEmits, HCarouselMediaScopedSlots>()
  .convert(HCarouselMediaRef);
