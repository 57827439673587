import './HCalendar.scss';

import * as tsx from 'vue-tsx-support';
import { VNode } from 'vue';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import {
  format as dateFormat,
  getDaysInMonth,
  isSameDay,
  isAfter,
  isBefore,
} from 'date-fns';
import {
  CalendarBaseMixin,
  CalendarBaseMixinProps,
  CalendarBaseMixinEmits,
} from './calendar-base-mixin';
import { HProgressCircular, HIcon } from '~/components';
import { safeDateString, toDate, isPromise } from '~/helpers';

export interface HCalendarBaseDay {
  _date: Date;
  dateString: string;
  year: number;
  month: number;
  day: number;
  dayOfWeek: number;
  isBuffer: boolean;
}

export interface HCalendarEvent<T = any> {
  date: string;
  data: T;
}

interface MergedDay<T = any> extends HCalendarBaseDay {
  events: HCalendarEvent<T>[];
}

export enum HCalendarDaySelectState {
  None = 'none',
  InRange = 'in',
  Start = 'start',
  End = 'end',
}

export interface HCalendarDay<T = any> extends MergedDay<T> {
  selectState: HCalendarDaySelectState;
  disabled: boolean;
}

export type HCalendarEventsLoader<T = any> = (
  vm: HCalendarRef,
) => HCalendarEvent<T>[] | Promise<HCalendarEvent<T>[]>;

export enum HCalendarEventsLoadState {
  Pending = 'pending',
  Loading = 'loading',
  Loaded = 'loaded',
  Failed = 'failed',
}

export const H_CALENDAR_BASE_FORMAT = 'yyyy-MM-dd';

export interface HCalendarProps extends CalendarBaseMixinProps {
  eventsLoader?: HCalendarEventsLoader<any>;
  loading?: boolean;
  hasNavigation?: boolean;
  multiple?: boolean;

  /**
   * ピック可能にするか
   */
  picker?: boolean;

  /**
   * 非アクティブにする日付
   */
  disableDates?: string[];
}

export interface HCalendarEmits extends CalendarBaseMixinEmits {
  onBeforeDestroy: HCalendarRef;
  onClickPrev: MouseEvent;
  onClickNext: MouseEvent;
}

export interface HCalendarScopedSlots {
  day?: HCalendarDay<any>;
}

@Component<HCalendarRef>({
  name: 'HCalendar',

  mounted() {
    this.loadEvents();
  },

  beforeDestroy() {
    this.$emit('beforeDestroy', this);
  },

  render(h) {
    return (
      <div staticClass="h-calendar" class={this.classes}>
        <div staticClass="h-calendar__title">
          <div staticClass="h-calendar__title__prepend">
            {this.hasNavigation && (
              <button
                type="button"
                staticClass="h-calendar__nav h-calendar__nav--prev"
                onClick={(ev) => {
                  this.$emit('clickPrev', ev);
                }}>
                <HIcon
                  staticClass="h-calendar__nav__icon"
                  name="keyboard-arrow-left"
                />
              </button>
            )}
          </div>
          <h3
            staticClass="h-calendar__title__inner"
            domPropsInnerHTML={this.formatedMonthLabel}
          />
          <div staticClass="h-calendar__title__append">
            {this.hasNavigation && (
              <button
                type="button"
                staticClass="h-calendar__nav h-calendar__nav--next"
                onClick={(ev) => {
                  this.$emit('clickNext', ev);
                }}>
                <HIcon
                  staticClass="h-calendar__nav__icon"
                  name="keyboard-arrow-right"
                />
              </button>
            )}
          </div>
        </div>
        <div staticClass="h-calendar__table">
          <div staticClass="h-calendar__table__header">
            <div staticClass="h-calendar__row">
              {this.computedDayLabels.map((label) => {
                return (
                  <div
                    key={label.day}
                    staticClass="h-calendar__cell h-calendar__header__cell"
                    class={{
                      'h-calendar__cell--holiday': this.isHoliday(label.day),
                    }}>
                    {label.label}
                  </div>
                );
              })}
            </div>
          </div>
          <div staticClass="h-calendar__body">
            <div staticClass="h-calendar__body__inner">
              {this.weeks.map((weekItem, weekIndex) => {
                return (
                  <div staticClass="h-calendar__row" key={weekIndex}>
                    {weekItem.map((dateItem) => {
                      const dateItemChildren: VNode[] = [];
                      const { isBuffer } = dateItem;
                      if (!isBuffer) {
                        dateItemChildren.push(
                          <span staticClass="h-calendar__item__date">
                            {String(dateItem.day)}
                          </span>,
                        );

                        if (!isBuffer && !this.isLoading) {
                          const { day: daySlot } = this.$scopedSlots;
                          if (daySlot) {
                            const $inject = (
                              <div staticClass="h-calendar__item__inject">
                                {daySlot(dateItem)}
                              </div>
                            );
                            dateItemChildren.push($inject);
                          }
                        }
                      }

                      const isClickable =
                        !isBuffer &&
                        this.picker &&
                        !dateItem.isBuffer &&
                        !dateItem.disabled;

                      const itemClasses: { [key: string]: boolean } = {
                        'h-calendar__item--loading': this.isLoading,
                        'h-calendar__item--buffer': dateItem.isBuffer,
                      };

                      if (!isBuffer) {
                        itemClasses[
                          `h-calendar__item--${dateItem.selectState}`
                        ] = true;
                        itemClasses['h-calendar__item--disabled'] =
                          dateItem.disabled;
                      }
                      return (
                        <div
                          key={dateItem.dateString}
                          staticClass="h-calendar__cell h-calendar__body__cell"
                          class={{
                            'h-calendar__cell--holiday': this.isHoliday(
                              dateItem.dayOfWeek,
                            ),
                          }}>
                          {h(
                            isClickable ? 'a' : 'div',
                            {
                              staticClass: 'h-calendar__item',
                              class: itemClasses,
                              attrs: isClickable
                                ? {
                                    href: 'javascript:void(0)',
                                  }
                                : undefined,
                              on: isClickable
                                ? {
                                    click: (e: MouseEvent) => {
                                      e.preventDefault();
                                      this.itemClickHandler(dateItem, e);
                                    },
                                  }
                                : undefined,
                            },
                            dateItemChildren,
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>

            {this.isLoading && (
              <HProgressCircular
                staticClass="h-calendar__loading"
                indeterminate
                width={2}
              />
            )}
          </div>
        </div>
      </div>
    );
  },
})
export class HCalendarRef<T = any>
  extends Mixins(CalendarBaseMixin)
  implements HCalendarProps {
  events: HCalendarEvent[] = [];
  @Prop({ type: Function }) readonly eventsLoader?: HCalendarEventsLoader<T>;
  @Prop({ type: Boolean }) readonly loading!: boolean;
  @Prop({ type: Boolean }) readonly hasNavigation!: boolean;
  @Prop({ type: Boolean }) readonly multiple!: boolean;

  /**
   * ピック可能にするか
   */
  @Prop({ type: Boolean }) picker!: boolean;

  /**
   * 非アクティブにする日付
   */
  @Prop({ type: Array, default: () => [] }) disableDates!: string[];

  private eventsLoadState: HCalendarEventsLoadState = (this as HCalendarRef)
    .eventsLoader
    ? HCalendarEventsLoadState.Pending
    : HCalendarEventsLoadState.Loaded;

  get isLoading() {
    return this.loading || this.eventsIsLoading;
  }

  get eventsIsPending() {
    return this.eventsLoadState === HCalendarEventsLoadState.Pending;
  }

  get eventsIsLoading() {
    return this.eventsLoadState === HCalendarEventsLoadState.Loading;
  }

  get eventsIsLoaded() {
    return this.eventsLoadState === HCalendarEventsLoadState.Loaded;
  }

  get eventsIsFailed() {
    return this.eventsLoadState === HCalendarEventsLoadState.Failed;
  }

  get classes() {
    return {
      'h-calendar--pull-narrow': this.pullNarrow,
      'h-calendar--loading': this.isLoading,
    };
  }

  /**
   * [this.startDay]から算出される最終曜日
   * e.g. 0 → 日曜日、1 → 月曜日
   * @see https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
   */
  get lastDay() {
    let lastDay = this.computedStartDay - 1;
    if (lastDay < 0) {
      lastDay = 6;
    }
    return lastDay;
  }

  /**
   * 最大日数
   */
  get daysInMonth() {
    return getDaysInMonth(this.monthInstance);
  }

  get baseWeeks(): HCalendarBaseDay[][] {
    const weeks: HCalendarBaseDay[][] = [];
    const { lastDay, daysInMonth, yearValue, monthValue } = this;

    let week: HCalendarBaseDay[] = [];
    weeks.push(week);

    for (let i = 0; i < daysInMonth; i++) {
      const dateValue = i + 1;
      const dayObject = new Date(yearValue, monthValue, dateValue);
      const dayValue = dayObject.getDay();
      const day: HCalendarBaseDay = {
        _date: dayObject,
        dateString: dateFormat(dayObject, H_CALENDAR_BASE_FORMAT),
        year: yearValue,
        month: monthValue,
        day: dateValue,
        dayOfWeek: dayValue,
        isBuffer: false,
      };
      week.push(day);
      if (dayValue === lastDay && i !== daysInMonth - 1) {
        week = [];
        weeks.push(week);
      }
    }

    // 最初の週で足りない日数を埋める
    const firstWeek = weeks[0];
    const firstDayItem = firstWeek[0];
    const overflowPrev = 7 - firstWeek.length;
    for (let i = 0; i < overflowPrev; i++) {
      let dateValue = firstDayItem.day - i - 1;
      const dayObject = new Date(yearValue, monthValue, dateValue);
      dateValue = dayObject.getDate();
      const day: HCalendarBaseDay = {
        _date: dayObject,
        dateString: dateFormat(dayObject, H_CALENDAR_BASE_FORMAT),
        year: dayObject.getFullYear(),
        month: dayObject.getMonth(),
        day: dayObject.getDate(),
        dayOfWeek: dayObject.getDay(),
        isBuffer: true,
      };
      firstWeek.unshift(day);
    }

    // 最後の週で足りない日数を埋める
    // ※週の数は5や6で変動するので固定にしない
    // if (weeks.length < 5) weeks.push([]);
    const lastWeek = weeks[weeks.length - 1];
    const lastWeekLength = lastWeek.length;
    const lastDayItem = lastWeek[lastWeekLength - 1];
    const overflowNext = 7 - lastWeekLength;
    for (let i = 0; i < overflowNext; i++) {
      let dateValue = lastDayItem.day + i + 1;
      const dayObject = new Date(yearValue, monthValue, dateValue);
      dateValue = dayObject.getDate();
      const day: HCalendarBaseDay = {
        _date: dayObject,
        dateString: dateFormat(dayObject, H_CALENDAR_BASE_FORMAT),
        year: dayObject.getFullYear(),
        month: dayObject.getMonth(),
        day: dayObject.getDate(),
        dayOfWeek: dayObject.getDay(),
        isBuffer: true,
      };
      lastWeek.push(day);
    }
    return weeks;
  }

  get eventsMergedWeeks(): MergedDay<T>[][] {
    return this.baseWeeks.map((baseWeek) => {
      return baseWeek.map((baseDay) => {
        return {
          ...baseDay,
          events: this.events.filter((e) => e.date === baseDay.dateString),
        };
      });
    });
  }

  get computedDisableDates(): Date[] {
    return this.disableDates.map((str) => toDate(str));
  }

  get weeks(): HCalendarDay<T>[][] {
    const { computedDisableDates } = this;
    return this.eventsMergedWeeks.map((mergedWeek) => {
      return mergedWeek.map((mergedDay) => {
        const { events } = mergedDay;
        const someEventDisabled = events.some(
          (e) =>
            e.data && typeof e.data === 'object' && (e.data as any).disabled,
        );

        return {
          ...mergedDay,
          selectState: this.checkSelected(mergedDay),
          disabled:
            someEventDisabled ||
            computedDisableDates.some((d) => isSameDay(d, mergedDay._date)),
        };
      });
    });
  }

  get allDays(): HCalendarDay[] {
    const days: HCalendarDay[] = [];
    this.weeks.forEach((week) => {
      week.forEach((day) => {
        days.push(day);
      });
    });
    return days;
  }

  get allDisabledDays(): HCalendarDay[] {
    return this.allDays.filter((d) => d.disabled);
  }

  getInDisableDays(from: string, to: string) {
    let fromTime = toDate(from).getTime();
    let toTime = toDate(to).getTime();
    if (fromTime > toTime) {
      [fromTime, toTime] = [toTime, fromTime];
    }
    return this.allDisabledDays.filter(({ _date }) => {
      return (
        isSameDay(fromTime, _date) ||
        (isAfter(_date, fromTime) && isBefore(_date, toTime))
      );
    });
  }

  pickDate(date: string): HCalendarDay[] | void {
    date = safeDateString(date);
    const { selectedDates } = this;
    let newValues: string[];
    const { length: selectedLength } = selectedDates;
    if (this.multiple) {
      if (selectedLength < 2) {
        if (selectedLength === 1 && selectedDates[0] === date) {
          newValues = [];
        } else {
          newValues = selectedDates.slice();
          newValues.push(date);
        }
      } else {
        newValues = [date];
      }

      if (newValues.length > 1) {
        const [from, to] = newValues;
        const disableDays = this.getInDisableDays(from, to);
        if (disableDays.length) {
          newValues = [date];
        }
      }
    } else {
      newValues = [date];
    }

    this.selectedDates = newValues;
  }

  /**
   * 選択状態を取得する
   */
  checkSelected(
    date: { _date: Date } | Date | string,
  ): HCalendarDaySelectState {
    let _date: Date;
    if (typeof date === 'string') {
      _date = toDate(date);
    } else if (date instanceof Date) {
      _date = date;
    } else {
      _date = date._date;
    }

    const { from, to } = this.selectedModel;
    if (from && isSameDay(_date, from)) {
      return HCalendarDaySelectState.Start;
    }
    if (to && isSameDay(_date, to)) {
      return HCalendarDaySelectState.End;
    }
    if (from && to && isAfter(_date, from) && isBefore(_date, to)) {
      return HCalendarDaySelectState.InRange;
    }
    return HCalendarDaySelectState.None;
  }

  private itemClickHandler(item: HCalendarDay<T>, e: MouseEvent) {
    if (this.isLoading) {
      return;
    }
    return this.pickDate(item.dateString);
  }

  resetEvents() {
    this.events = [];
    this.eventsLoadState = HCalendarEventsLoadState.Pending;
  }

  reload() {
    this.resetEvents();
    return this.loadEvents();
  }

  private async loadEvents() {
    if (this.eventsIsLoading) {
      return;
    }

    const { eventsLoader } = this;
    if (!eventsLoader) {
      this.eventsLoadState = HCalendarEventsLoadState.Loaded;
      return;
    }

    this.eventsLoadState = HCalendarEventsLoadState.Loading;

    try {
      let events = eventsLoader(this);
      if (isPromise(events)) {
        events = await events;
      }
      if (events && Array.isArray(events)) {
        this.events = events.map((e) => {
          return {
            ...e,
            date: e.date.replace(/\//g, '-'),
          };
        });
      } else {
        // ロードキャンセル用の対応
        this.events = [];
      }
      this.eventsLoadState = HCalendarEventsLoadState.Loaded;
    } catch (err) {
      this.eventsLoadState = HCalendarEventsLoadState.Failed;
      throw err;
    }
  }
}

export const HCalendar = tsx
  .ofType<HCalendarProps, HCalendarEmits, HCalendarScopedSlots>()
  .convert(HCalendarRef);
