import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HIcon, HExpandTransition } from '~/components';

export interface HExpandButtonProps {
  label?: string;
}

export interface HExpandButtonEmits {}

export interface HExpandButtonScopedSlots {}

@Component<HExpandButtonRef>({
  name: 'HExpandButton',
  render() {
    const { label } = this;

    const _label = label || (this.$t('label.details') as string);

    return (
      <div staticClass="h-expand-button">
        <button
          staticClass="h-expand-button__activator"
          class={{
            'h-expand-button__activator--active': this.isOpened,
          }}
          type="button"
          onClick={this.toggle}>
          <span
            staticClass="h-expand-button__activator__label"
            domPropsInnerHTML={_label}></span>
          <HIcon
            staticClass="h-expand-button__activator__icon"
            name={this.isOpened ? 'minus' : 'plus'}
          />
        </button>

        <HExpandTransition>
          <div v-show={this.isOpened} staticClass="h-expand-button__body">
            {this.$slots.default}
          </div>
        </HExpandTransition>
      </div>
    );
  },
})
export default class HExpandButtonRef
  extends Vue
  implements HExpandButtonProps {
  @Prop({ type: String }) label!: string;

  private isOpened: boolean = false;

  toggle() {
    this.isOpened = !this.isOpened;
  }
}

export const TypedHExpandButton = tsx
  .ofType<HExpandButtonProps, HExpandButtonEmits, HExpandButtonScopedSlots>()
  .convert(HExpandButtonRef);
