import './HSimpleLinks.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { NavigationItem } from '~/schemes';
import { HIconLink } from '~/components';
import { mergeVNodeData } from '~/helpers';

export const HSimpleLinks = tsx.component({
  name: 'HSimpleLinks',
  functional: true,

  props: {
    items: {
      type: Array as PropType<NavigationItem[]>,
      required: true,
    },
    /**
     * ページ内リンクにしたい時にtrueにする
     */
    page: Boolean,

    /**
     * 画面が狭い時だけ縦に並べたい時trueにする
     */
    narrowBlocks: Boolean,
  },

  render(h, { data, props }) {
    const { items, page, narrowBlocks } = props;

    return (
      <nav
        {...mergeVNodeData(data, {
          staticClass: 'h-simple-links',
          class: {
            'h-simple-links--page': page,
            'h-simple-links--narrow-blocks': narrowBlocks,
          },
        })}>
        <ul staticClass="h-simple-links__inner">
          {items.map((item) => (
            <li staticClass="h-simple-links__item" key={item.key}>
              <HIconLink
                staticClass="h-simple-links__item__link"
                props={item}
                icon={page ? 'arrow-down' : 'arrow-right'}
                internal
              />
            </li>
          ))}
        </ul>
      </nav>
    );
  },
});
