import './HHotelHeader.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { HotelInfo } from '~/schemes';
import { HHotelToBooking } from '~/components';
import { mergeVNodeData } from '~/helpers';

export const HHotelHeader = tsx.component({
  name: 'HHotelHeader',
  functional: true,

  props: {
    hotel: {
      type: Object as PropType<HotelInfo>,
      required: true,
    },
  },

  render(h, { data, props, parent }): VNode {
    const { hotel } = props;

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'h-hotel-header',
        })}>
        <div staticClass="h-hotel-header__logo">
          <nuxt-link
            staticClass="h-hotel-header__logo__link"
            exactActiveClass="h-hotel-header__logo__link--active"
            to={parent.$hotel.location()}>
            <img
              staticClass="h-hotel-header__logo__img"
              src={parent.$res.img(hotel.logo.inline)}
              alt={hotel.fullName}
            />
          </nuxt-link>
        </div>
      </div>
    );
  },
});
