import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import HotelViewRoot from '../../../-index';
import { HotelOption, Restaurant } from '~/schemes';
import {
  IconName,
  HCardProps,
  HBackdrop,
  HCardList,
  HIcon,
} from '~/components';
import { EXTERNAL_LINK_RE } from '~/plugins/navigation';
import { extractHtmlText } from '~/helpers';

interface MyCardOption extends HCardProps {
  hours: { name: string; value: string; inline: boolean } | null;
  defines: { key: string; name: string; contents: string[] }[];
  body: string | null;
}

export interface MyHotelDetailItemProps {
  active?: boolean;
  icon: IconName;
  label: string | TranslateResult;
  value?: string;
  eventId?: string;
  options?: HotelOption[];
  restaurants?: Restaurant[];
}

export interface MyHotelDetailItemEmits {
  onInput: Boolean;
}

export interface MyHotelDetailItemScopedSlots {
  label?: MyHotelDetailItemRef;
  value?: MyHotelDetailItemRef;
}

@Component<MyHotelDetailItemRef>({
  name: 'MyHotelDetailItem',
  inject: ['hotelViewRoot'],
  render() {
    const { $scopedSlots, hasOptions, label, value } = this;
    const { label: labelSlot, value: valueSlot } = $scopedSlots;
    return (
      <div staticClass="my-hotel-detail-item" class={this.classes}>
        <div staticClass="my-hotel-detail-item__header">
          <div staticClass="my-hotel-detail-item__image">
            <HIcon staticClass="my-hotel-detail-item__icon" name={this.icon} />
          </div>
          <h3 staticClass="my-hotel-detail-item__name">
            {labelSlot ? labelSlot(this) : !!label && label}
          </h3>
        </div>
        <div staticClass="my-hotel-detail-item__body">
          {hasOptions ? (
            <button
              staticClass="my-hotel-detail-item__toggle"
              type="button"
              onClick={this.toggleOptions}>
              <span staticClass="my-hotel-detail-item__toggle__label">
                <span staticClass="my-hotel-detail-item__toggle__label__count">
                  {this.computedOptions!.length}
                </span>
                <span staticClass="my-hotel-detail-item__toggle__label__text">
                  {this.$t('label.details')}
                </span>
              </span>
            </button>
          ) : valueSlot ? (
            valueSlot(this)
          ) : !hasOptions ? (
            this.$t('chore.notAvailable')
          ) : (
            value
          )}

          {hasOptions && (
            <HBackdrop
              v-model={this.isActive}
              hideClose
              scopedSlots={{
                default: (props) => (
                  <div staticClass="my-hotel-detail-item__options-wrapper">
                    <HCardList
                      staticClass="my-hotel-detail-item__options"
                      items={this.cardOptions!}
                      pointerSupport
                      onClick={() => {
                        props.close();
                      }}
                      scopedSlots={{
                        item: (item) => {
                          const children: VNode[] = [];
                          if (item.hours) {
                            children.push(
                              <dl
                                staticClass="my-hours"
                                class={{
                                  'my-hours--inline': item.hours.inline,
                                }}>
                                <dt staticClass="my-hours__name">
                                  {item.hours.name}
                                </dt>
                                <dd
                                  v-wysiwyg={item.hours.value}
                                  staticClass="my-hours__value"
                                />
                              </dl>,
                            );
                          }
                          if (item.defines.length > 0) {
                            children.push(
                              <table staticClass="my-defines">
                                <tbody>
                                  {item.defines.map((define) => (
                                    <tr key={define.key}>
                                      <th>{define.name}</th>
                                      <td>
                                        {define.contents.map(
                                          (content, index) => (
                                            <div
                                              staticClass="my-defines__word h-ilb"
                                              v-wysiwyg={content}
                                              key={index}
                                            />
                                          ),
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>,
                            );
                          }
                          if (item.body) {
                            children.push(<div v-wysiwyg={item.body} />);
                          }
                          return children;
                        },
                      }}
                    />
                  </div>
                ),
              }}
            />
          )}
        </div>
      </div>
    );
  },
})
export class MyHotelDetailItemRef
  extends Vue
  implements MyHotelDetailItemProps {
  readonly hotelViewRoot!: HotelViewRoot;

  @Model('input', { type: Boolean }) readonly active!: boolean;
  @Prop({ type: String, required: true }) readonly icon!: IconName;
  @Prop({ type: String, required: true }) readonly label!: string;
  @Prop({ type: String }) readonly value?: string;
  @Prop({ type: String }) readonly eventId?: string;
  @Prop({ type: Array }) readonly options?: HotelOption[];
  @Prop({ type: Array }) readonly restaurants?: Restaurant[];

  get hotel() {
    return this.hotelViewRoot.hotel;
  }

  get hasRestaurantSettings() {
    return !!this.hotel.restaurantSettings;
  }

  get computedOptions(): (HotelOption | Restaurant)[] | undefined {
    return this.options || this.restaurants;
  }

  get cardOptions(): MyCardOption[] | undefined {
    const { computedOptions } = this;
    if (computedOptions) {
      return computedOptions.map((_option) => {
        const defines: MyCardOption['defines'] = [];
        let body = '';
        let hours: MyCardOption['hours'] | null = null;
        let image: string | null = null;
        let action: MyCardOption['action'] = null;
        const { hours: _hours } = _option;
        if (_hours) {
          const is24 = extractHtmlText(_hours) === '24';
          const name = this.$t(
            `label.${is24 ? 'openHours' : 'hours'}`,
          ) as string;
          const value = is24
            ? (this.$t('label.twentyFourhours') as string)
            : _hours;

          hours = {
            name,
            value,
            inline: is24,
          };
        }

        const option = _option;
        const name = option.name;
        image = option.thumb;
        const { memo, externalLink } = option;
        if (memo) {
          body = memo;
        }
        if (externalLink) {
          const { url } = externalLink;
          let { text } = externalLink;
          if (!text) {
            text = this.$t('label.details') as string;
          }
          action = {
            url,
            text,
          };
        } else if (
          'id' in option &&
          this.hasRestaurantSettings &&
          /* 星のや、界はお食事ページに個々の詳細はないので、リンク表示しない */
          !this.$theme.is('kai') &&
          !this.$theme.is('hoshinoya')
        ) {
          /**
           * @todo: レストラン判定を「id」の存在でチェックしているけど
           * ちゃんと他オプションのIFに合わせて変える事！！
           */
          const { id } = option;
          action = {
            url: this.$hotel.location(`/dining/#item-${id}`).path as string,
            text: this.$t('label.details') as string,
          };
        }

        return {
          name,
          hours,
          image: image ? this.$res.img(image) : null,
          defines,
          body,
          action,
        };
      });
    }
  }

  get hasOptions() {
    const { computedOptions } = this;
    return !!computedOptions && computedOptions.length > 0;
  }

  private internalActive: boolean = this.active;

  get isActive() {
    return this.internalActive;
  }

  set isActive(isActive: boolean) {
    if (this.internalActive !== isActive) {
      this.internalActive = isActive;
      this.$emit('input', isActive);
    }
  }

  get classes() {
    return {
      'my-hotel-detail-item--active': this.isActive,
    };
  }

  @Watch('active')
  protected activeChangeHandler() {
    this.internalActive = this.active;
  }

  showOptions() {
    this.isActive = true;
    const { eventId } = this;
    eventId &&
      this.$gfev.push({
        category: this.$gfev.Category.Push,
        action: 'opendetail',
        id: eventId,
      });
  }

  closeOptions() {
    this.isActive = false;
  }

  toggleOptions() {
    return this.isActive ? this.closeOptions() : this.showOptions();
  }
}

export const MyHotelDetailItem = tsx
  .ofType<
    MyHotelDetailItemProps,
    MyHotelDetailItemEmits,
    MyHotelDetailItemScopedSlots
  >()
  .convert(MyHotelDetailItemRef);
