import './HKeyVisual.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as effectComponents from './effects';
import { KeyVisual } from '~/schemes';
import {
  HProgressSpinner,
  HVideoPlayer,
  HVideoPlayerRef,
  HPortalItem,
} from '~/components';
import { loadImageSize } from '~/helpers';

export interface HKeyVisualProps {
  value: KeyVisual;
  paused?: boolean;
  wait?: boolean;
  leadHTML?: boolean;
}

export interface HKeyVisualEmits {}

export interface HKeyVisualScopedSlots {}

@Component<HKeyVisualRef>({
  name: 'HKeyVisual',

  provide() {
    return {
      keyVisual: this,
    };
  },

  head() {
    const { coverType } = this;
    return {
      htmlAttrs: {
        'data-key-visual-cover-type': coverType || '',
      },
    };
  },

  watch: {
    wait(wait: boolean) {
      if (!wait) {
        const { video } = this.$refs;
        video && video.play();
      }
    },
  },

  mounted() {
    this.isMounted = true;
    this.loadImage();
  },

  render(h) {
    const portalImageChildren: VNode[] = [];
    const children: VNode[] = [];
    const portalChildren: VNode[] = [];
    const { image, videoInfo, styles, isPortal } = this;

    if (isPortal) {
      portalChildren.push(
        <div
          staticClass="h-key-visual__portal-image"
          class={this.portalImageClasses}
          style={styles}
        />,
      );
    }

    if (image && this.imageLoaded) {
      const { effects } = this.value;
      if (effects.length) {
        const bucket = isPortal ? portalImageChildren : children;
        const $effects = effects.map((effect) => {
          const Ctor = effectComponents[effect.type];
          if (!Ctor) throw new Error(`missing effect type at ${effect.type}`);
          return h(Ctor, {
            props: {
              value: effect.data,
            },
          });
        });
        bucket.push(<div staticClass="h-key-visual__effects">{$effects}</div>);
      }
    }

    if (videoInfo) {
      const player = (
        <HVideoPlayer
          staticClass="h-key-visual__video"
          class={this.videoClasses}
          src={videoInfo.url}
          poster={videoInfo.thumb}
          muted
          autoplay={!this.wait}
          fit
          backgroundMode
          progressBar={!!this.$theme.is('hoshinoya')}
          onCanplay={() => {
            this.videoThumbLoaded = true;
            this.videoLoaded = true;
            this.videoStateResolved = true;
          }}
          onPosterLoaded={() => {
            this.videoThumbLoaded = true;
            this.videoLoaded = true;
          }}
          fade
          onSuccessAutoplay={() => {
            this.videoStateResolved = true;
            this.maybyVideoAutoplayFailed = false;
          }}
          onMaybeAutoplayFailed={() => {
            this.videoStateResolved = true;
            this.maybyVideoAutoplayFailed = true;
          }}
          onAutoplayTimeout={() => {
            this.videoStateResolved = true;
            this.maybyVideoAutoplayFailed = true;
          }}
          ref="video"
        />
      );

      const bucket = isPortal ? portalChildren : children;

      bucket.push(
        player,
        <HProgressSpinner
          staticClass="h-key-visual__video__spinner"
          v-show={!this.videoLoaded}
        />,
      );
    }

    if (portalChildren.length) {
      children.unshift(
        <HPortalItem to="portal-app-cover-portal">
          <div
            staticClass="h-key-visual__cover-portal"
            class={[`h-key-visual__cover-portal--${this.coverType}`]}>
            {portalChildren}
          </div>
        </HPortalItem>,
      );
    }

    return (
      <div
        staticClass="h-key-visual"
        class={this.classes}
        style={isPortal ? undefined : styles}>
        {children}
      </div>
    );
  },
})
export class HKeyVisualRef extends Vue implements HKeyVisualProps {
  $refs!: {
    video: HVideoPlayerRef;
  };

  @Prop({ type: Object, required: true }) readonly value!: KeyVisual;
  @Prop(Boolean) readonly paused!: boolean;
  @Prop(Boolean) readonly wait!: boolean;
  @Prop(Boolean) readonly leadHTML!: boolean;

  private imageLoaded: boolean = false;
  private videoLoaded: boolean = false;
  private videoThumbLoaded: boolean = false;
  private isMounted: boolean = false;
  private videoStateResolved: boolean = false;
  private maybyVideoAutoplayFailed: boolean = false;

  /**
   * ページ固定のKVにするか
   *
   * * 現状、界のみ
   */
  get isPortal() {
    return this.$theme.is('kai');
  }

  get hasVideo() {
    return !!this.video;
  }

  get videoType(): 'cover' | 'normal' | undefined {
    const { video, isPortal } = this;
    if (!video) return;
    if (isPortal) return 'cover';
  }

  get coverType(): 'cover' | 'normal' | undefined {
    const { videoType, isPortal } = this;
    if (videoType) return videoType;
    if (isPortal) return 'cover';
  }

  get classes() {
    const {
      hasSlantingMask,
      cancelSlantingMask,
      hasVideo,
      videoThumbLoaded,
      videoLoaded,
      videoType,
      image,
      isPortal,
      isMounted,
      videoStateResolved,
      maybyVideoAutoplayFailed,
    } = this;

    const isTransparent = isPortal && !hasVideo;
    const withImage = hasVideo && !!image;

    const classes: { [key: string]: boolean } = {
      'h-key-visual--slanting-mask': hasSlantingMask,
      'h-key-visual--cancel-slanting-mask': cancelSlantingMask,
      'h-key-visual--video': hasVideo,
      'h-key-visual--video-thumb-loaded': videoThumbLoaded,
      'h-key-visual--video-loaded':
        videoLoaded && videoStateResolved && !maybyVideoAutoplayFailed,
      'h-key-visual--video-with-image': withImage,
      'h-key-visual--video-with-image-mounted': withImage && isMounted,
      'h-key-visual--transparent': isTransparent,
      'h-key-visual--transparent--ready': isTransparent && isMounted,
      'h-key-visual--wait': this.wait,
    };
    if (videoType) {
      classes[`h-key-visual--video-${videoType}`] = true;
    }
    return classes;
  }

  get portalImageClasses() {
    const { hasVideo, image, videoStateResolved } = this;
    const withImage = hasVideo && !!image;
    return {
      'h-key-visual__portal-image--fade': withImage,
      'h-key-visual__portal-image--fade-active':
        withImage && videoStateResolved,
    };
  }

  get videoClasses() {
    const { videoType } = this;
    const classes: { [key: string]: boolean } = {};
    if (videoType) {
      classes[`h-key-visual--video-${videoType}`] = true;
    }
    return {
      [`h-key-visual__video--${videoType}`]: true,
    };
  }

  get hasSlantingMask() {
    return this.$theme.current.name === 'beb';
  }

  get cancelSlantingMask() {
    return !this.leadHTML;
  }

  get image() {
    const { image, video } = this.value;

    // 動画がある場合は画像がなかったことにする
    if (video) return;
    return image && this.$res.img(image);
  }

  get video() {
    return this.value.video;
  }

  get mqDetected() {
    return this.isMounted && (this.$mq.match.wide || this.$mq.match.narrow);
  }

  get videoInfo() {
    const { video } = this;
    if (!video) return;
    if (!this.mqDetected) return;
    const { wide, square } = video;
    if (!wide && !square) return;

    const wideUrl = wide && wide.url;
    const wideThumb = wide && wide.thumb;
    const squareUrl = square && square.url;
    const squareThumb = square && square.thumb;

    let videoUrl: string | undefined;
    let videoThumb: string | undefined;

    if (this.$mq.match.wide) {
      videoUrl = wideUrl;
      videoThumb = wideThumb;
      if (!videoUrl) {
        videoUrl = squareUrl;
        videoThumb = squareThumb;
      }
    } else {
      videoUrl = squareUrl;
      videoThumb = squareThumb;
      if (!videoUrl) {
        videoUrl = wideUrl;
        videoThumb = wideThumb;
      }
    }

    if (!videoUrl) return;

    return {
      url: videoUrl,
      thumb: videoThumb,
    };
  }

  get position() {
    return this.value.position;
  }

  get baseStyles() {
    return {
      backgroundPosition: this.position,
    };
  }

  get styles() {
    const { image } = this;
    return {
      ...this.baseStyles,
      backgroundImage: image ? `url(${image})` : undefined,
    };
  }

  private async loadImage() {
    if (this.imageLoaded) return undefined;
    if (this.video) return;
    const { image } = this;
    try {
      if (image) {
        await loadImageSize(image);
      }
      this.imageLoaded = true;
    } catch (err) {
      this.$logger.warn('キービジュアルの読み込みに失敗しました。');
    }
  }
}

export const HKeyVisual = tsx
  .ofType<HKeyVisualProps, HKeyVisualEmits, HKeyVisualScopedSlots>()
  .convert(HKeyVisualRef);
