import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelDetail } from '~/schemes';
import { HSimpleEmbedMap } from '~/components/HSimpleMap';
import { HBtn } from '~/components';

export interface MyHotelAccessProps {
  hotel: HotelDetail;
}

export interface MyHotelAccessEmits {}

export interface MyHotelAccessScopedSlots {}

@Component<MyHotelAccessRef>({
  name: 'MyHotelAccess',
  render() {
    const { hotel, mapcode, guide, hasAccessContents } = this;
    return (
      <div staticClass="my-hotel-access">
        <HSimpleEmbedMap
          hotel={hotel}
          lazy
          staticClass="my-hotel-access__map"
        />
        <div staticClass="my-hotel-access__info">
          <p staticClass="my-hotel-access__info__hotel-name">
            {hotel.fullName}
          </p>
          <p staticClass="my-hotel-access__info__address">
            <a
              staticClass="my-hotel-access__info__address__link"
              href={hotel.mapUrl}
              target="_blank"
              rel="noopener"
              domPropsInnerHTML={this.$t('value.address', hotel) as string}></a>
          </p>
          {!!mapcode && (
            <p staticClass="my-hotel-access__info__map-code">
              Mapcode: {mapcode}
            </p>
          )}
          {!!guide && (
            <div v-wysiwyg={guide} staticClass="my-hotel-access__info__guide" />
          )}
        </div>

        {hasAccessContents && (
          <div staticClass="my-hotel-access__footer">
            <HBtn
              staticClass="my-hotel-access__footer__action"
              color={this.$theme.is('hoshinoya') ? 'skelton' : 'plain'}
              to={this.$hotel.location('/access', hotel)}>
              {this.$t('label.details')}
            </HBtn>
          </div>
        )}
      </div>
    );
  },
})
export default class MyHotelAccessRef
  extends Vue
  implements MyHotelAccessProps {
  @Prop({ type: Object, required: true }) readonly hotel!: HotelDetail;

  get mapcode() {
    return this.hotel.mapcode;
  }

  get hasAccessContents() {
    return this.hotel.hasAccessContents;
  }

  get accessSettings() {
    return this.hotel.accessSettings;
  }

  get guide() {
    const { accessSettings } = this;
    if (accessSettings && accessSettings.guide) {
      return accessSettings.guide;
    }
  }
}

export const TypedMyHotelAccess = tsx
  .ofType<MyHotelAccessProps, MyHotelAccessEmits, MyHotelAccessScopedSlots>()
  .convert(MyHotelAccessRef);
