import Vue from 'vue';
import { Store } from 'vuex/types';
import { Plugin } from '@nuxt/types';
import { UAParser } from 'ua-parser-js';

export interface UAInfo extends UAParser.IResult {
  lowerBrowserName: string | undefined;
  supported: boolean;
  useImageLazyLoad: boolean;
  useTypekit: boolean;
  isIE: boolean;
  isIOS: boolean;
  isSafari: boolean;

  /**
   * 旧（chromium前の）EDGE
   */
  isLegacyEdge: boolean;
  accept: {
    [key: string]: true;
  };
  webpSupported: boolean;
}

const ACCEPT_PARSE_RE = /[^,;]([a-z\d\-_]+\/[a-z\d\-_]+)/g;

declare module 'vue/types/vue' {
  export interface Vue {
    $ua: UAInfo;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $ua: UAInfo;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $ua: UAInfo;
  }
}

const DEFAULT_USER_AGENT =
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.39 Safari/537.36';

const plugin: Plugin = (context, inject) => {
  let _accept: string;
  if (process.server) {
    _accept = context.req.headers.accept || '';
    _accept = _accept.toLowerCase();
    context.store.commit('SET_ACCEPT', _accept);
  } else {
    _accept = context.store.state.accept;
  }
  const accept: {
    [key: string]: true;
  } = {};

  const acceptMatched = _accept.match(ACCEPT_PARSE_RE);
  if (acceptMatched) {
    acceptMatched.forEach((type) => {
      accept[type] = true;
    });
  }

  let userAgent = '';
  if (typeof context.req !== 'undefined') {
    userAgent = context.req.headers['user-agent'] || '';
  } else if (typeof navigator !== 'undefined') {
    userAgent = navigator.userAgent;
  } else {
    userAgent = DEFAULT_USER_AGENT;
  }
  // use default user-agent if user-agent header is not sent
  if (!userAgent) {
    userAgent = DEFAULT_USER_AGENT;
  }
  const ua = new UAParser(userAgent);
  const parsed = ua.getResult();
  const { name = '', major } = parsed.browser;
  let lowerBrowserName = (name || '').toLowerCase();
  const isIE = lowerBrowserName === 'ie';
  // eslint-disable-next-line unicorn/prefer-starts-ends-with
  const isSafari = /safari$/.test(lowerBrowserName);
  const isIOS = parsed.os.name === 'iOS';
  const browserMajorVersion =
    major === undefined ? undefined : parseInt(major, 10);
  const isLegacyEdge =
    lowerBrowserName === 'edge' &&
    browserMajorVersion !== undefined &&
    browserMajorVersion <= 18;
  if (isLegacyEdge) lowerBrowserName = 'edge-legacy';

  const info: UAInfo = {
    ...parsed,
    lowerBrowserName,
    isIE,
    isIOS,
    isSafari,
    isLegacyEdge,
    supported: !isIE || browserMajorVersion === 11,
    useImageLazyLoad: !isLegacyEdge,
    useTypekit: true,
    // useTypekit: !isLegacyEdge, // レガシーedgeはTypekitの中で利用されるMutationObserverが激重でフリーズするので使わない
    // useImageLazyLoad: !(
    //   parsed.os.name === 'Windows' && parsed.browser.name === 'Edge'
    // ),
    accept,
    webpSupported: accept['image/webp'],
  };

  context.$ua = info;
  inject('ua', info);
};

export default plugin;
