import './snow.scss';

import Vue, { VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { HKeyVisualRef } from '../HKeyVisual';
import SnowCanvas from './SnowCanvas';
import { KeyVisualEffectData } from '~/schemes';

@Component<SnowEffect>({
  name: 'h-snow-effect',

  inject: ['keyVisual'],

  mounted() {
    this.snowCanvas = new SnowCanvas();
    this.snowCanvas.mount(this.$refs.canvas);
    this.start();
  },

  beforeDestroy() {
    this.destroy();
  },

  render(h): VNode {
    return h(
      'div',
      {
        staticClass: 'h-snow-effect',
        class: this.classes,
        style: this.styles,
        directives: [
          {
            name: 'inview',
            value: {
              in: () => {
                this.setIsInview(true);
              },
              out: () => {
                this.setIsInview(false);
              },
            },
          },
        ],
      },
      [
        h('div', {
          staticClass: 'h-snow-effect__canvas',
          ref: 'canvas',
        }),
      ],
    );
  },
})
export default class SnowEffect extends Vue {
  readonly keyVisual!: HKeyVisualRef;

  $refs!: {
    canvas: HTMLElement;
  };

  @Prop({ type: Object, required: true }) value!: KeyVisualEffectData['Snow'];

  private snowCanvas: SnowCanvas | null = null;
  private internalIsInview: boolean = false;
  private delayTimerId: number | null = null;
  private isActive: boolean = false;

  get isInview() {
    return this.internalIsInview;
  }

  get delay() {
    return this.value.delay;
  }

  get duration() {
    return this.value.duration;
  }

  get styles() {
    return {
      transition: `opacity ${this.duration}ms`,
    };
  }

  get classes() {
    return {
      'h-snow-effect--active': this.isActive,
    };
  }

  get paused() {
    return this.keyVisual.paused;
  }

  get canPlay() {
    return this.isInview && !this.paused;
  }

  @Watch('canPlay')
  protected canPlayChangeHandler() {
    if (process.browser) {
      if (this.canPlay) {
        this.resume();
      } else {
        this.pause();
      }
    }
  }

  async start() {
    if (this.isActive) return;
    await this.kickDelay();
    if (this.snowCanvas && this.canPlay) {
      this.snowCanvas.run();
      this.isActive = true;
    }
  }

  private setIsInview(isInview: boolean) {
    if (this.internalIsInview !== isInview) {
      this.internalIsInview = isInview;
    }
  }

  kickDelay() {
    return new Promise((resolve) => {
      this.delayTimerId = window.setTimeout(() => {
        this.clearTimer();
        resolve(undefined);
      }, this.delay);
    });
  }

  clearTimer() {
    if (this.delayTimerId !== null) {
      clearTimeout(this.delayTimerId);
      this.delayTimerId = null;
    }
  }

  pause() {
    this.snowCanvas && this.snowCanvas.pause();
  }

  resume() {
    if (this.snowCanvas) {
      if (this.snowCanvas.active) {
        this.snowCanvas.resume();
      } else {
        this.snowCanvas.run();
      }
      this.isActive = true;
    }
  }

  destroy() {
    if (this.snowCanvas) {
      this.snowCanvas.destroy();
      this.snowCanvas = null;
    }
  }
}
