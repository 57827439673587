import { Context } from '@nuxt/types';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { State as RootState } from './';

import {
  ExchangeRate,
  ActivityCommons,
  RestaurantCommons,
  HotelRoomCommons,
  PaymentCommons,
  GalleryCommons,
  HotelBrandBasicInfo,
  HotelBasicInfo,
  SiteSettings,
} from '~/schemes';

export const COOKIE_DISPLAY_CURRENCY_KEY = 'H-DISPLAY-CURRENCY';

export interface State {
  currency: string;
  exchangeRates: ExchangeRate[];
  activity: ActivityCommons | null;
  restaurant: RestaurantCommons | null;
  room: HotelRoomCommons;
  payment: PaymentCommons;
  gallery: GalleryCommons;
  brands: HotelBrandBasicInfo[];
  hotels: HotelBasicInfo[];
  siteSettings: SiteSettings;
}

export const state = (): State => ({
  /**
   * @todo: 現在はJA固定。将来的には表示言語毎に基準通貨を設定したいとの事
   */
  currency: '',
  exchangeRates: [],
  activity: null,
  restaurant: null,
  room: null as any,
  payment: null as any,
  gallery: null as any,
  hotels: [],
  brands: [],
  siteSettings: null as any,
});

export const getters: GetterTree<State, RootState> = {
  /**
   * 現在の表示通貨設定
   *
   * 以下の通りの優先順で決定される
   *
   * 1. ストアに保持されている値
   * 2. 宿GETSの施設情報から取得された値
   * 3. 選択されている言語に対応する値
   * 4. JPY固定
   *
   * @see https://hr-dev.backlog.jp/view/ACCO_CMS-1541
   */
  currency(state, getters, rootState, rootGetters) {
    return (
      state.currency ||
      rootGetters['hotel/currentHotelDisplayCurrency'] ||
      rootGetters['language/currentInfo'].currency ||
      'JPY'
    );
  },

  /**
   * 特集ページの施設紹介モジュールの料金表示ルール
   * * 以下の通りの優先順で決定される
   *
   * 1. ストアに保持されている値
   * 2. 選択されている言語に対応する値
   * 3. JPY固定
   *
   * @memo https://hr-dev.backlog.jp/view/ACCO_CMS-1875
   */
  currencyHotelIntroduction(state, getters, rootState, rootGetters) {
    return (
      state.currency || rootGetters['language/currentInfo'].currency || 'JPY'
    );
  },

  exchangeRateAt(state) {
    return (currency): ExchangeRate | undefined => {
      const { exchangeRates } = state;
      return exchangeRates.find((r) => r.base === currency);
    };
  },

  currentExchangeRate(state, getters): ExchangeRate | undefined {
    return getters.exchangeRateAt(getters.currency);
  },
};

export const actions: ActionTree<State, RootState> = {
  async serverInit({ dispatch }, ctx: Context) {
    await Promise.all([
      dispatch('loadRoom'),
      dispatch('loadPayment'),
      dispatch('loadGallery'),
      dispatch('loadBrands'),
      dispatch('loadHotels'),
      dispatch('loadSiteSettings'),
    ]);
  },

  /**
   * 通過設定は、基本、ブラウザマウント後にロードする
   */
  async clientInit({ dispatch }, ctx: Context) {
    await Promise.all([dispatch('loadExchangeRates')]);
  },

  async loadExchangeRates({ commit, state, getters }) {
    if (state.exchangeRates.length) return;

    const {
      exchangeRateTableList,
    } = await this.$api.commons.getExchangeRates();
    commit('SET_EXCHANGE_RATES', exchangeRateTableList);
    const cookieValue = this.$cookies.get(COOKIE_DISPLAY_CURRENCY_KEY);
    if (cookieValue) {
      commit('SET_CURRENCY', cookieValue);
    }
  },

  async loadRoom({ commit }) {
    const room = await this.$api.commons.getRoomCommons();
    commit('SET_ROOM', room);
  },

  async loadPayment({ commit }) {
    const payment = await this.$api.commons.getPaymentCommons();
    commit('SET_PAYMENT', payment);
  },

  async loadGallery({ commit }) {
    const gallery = await this.$api.commons.getGalleryCommons();
    commit('SET_GALLERY', gallery);
  },

  // eslint-disable-next-line require-await
  async changeCurrency({ state, commit }, currency: string) {
    if (state.currency !== currency) {
      this.$cookies.set(COOKIE_DISPLAY_CURRENCY_KEY, currency, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });
      commit('SET_CURRENCY', currency);
      process.browser &&
        this.$gfev.push({
          category: this.$gfev.Category.Push,
          action: 'changecurrency',
          id: `currency-${currency}`,
        });
    }
  },

  async loadBrands({ commit }) {
    const brands = await this.$api.hotels.brandAll();
    commit('SET_BRANDS', brands);
  },

  async loadHotels({ commit }) {
    const hotels = await this.$api.hotels.all();
    commit('SET_HOTELS', hotels);
  },

  async loadSiteSettings({ commit }) {
    const siteSettings = await this.$dataBucket.get('/commons/site-settings');
    commit('SET_SITE_SETTINGS', siteSettings);
  },
};

export const mutations: MutationTree<State> = {
  SET_CURRENCY: (state, currency) => {
    state.currency = currency;
  },

  SET_EXCHANGE_RATES(state, exchangeRates: ExchangeRate[]) {
    state.exchangeRates = exchangeRates;
  },

  SET_ACTIVITY: (state, activity: ActivityCommons) => {
    state.activity = activity;
  },

  SET_RESTAURANT: (state, restaurant: RestaurantCommons) => {
    state.restaurant = restaurant;
  },

  SET_ROOM: (state, room: HotelRoomCommons) => {
    state.room = room;
  },

  SET_PAYMENT: (state, payment: PaymentCommons) => {
    state.payment = payment;
  },

  SET_GALLERY: (state, gallery: GalleryCommons) => {
    state.gallery = gallery;
  },

  SET_BRANDS: (state, brands: HotelBrandBasicInfo[]) => {
    state.brands = brands;
  },

  SET_HOTELS: (state, hotels: HotelBasicInfo[]) => {
    state.hotels = hotels;
  },

  /** CMSで設定したサイト設定を保存 */
  SET_SITE_SETTINGS: (state, siteSettings: SiteSettings) => {
    state.siteSettings = siteSettings;
  },
};
