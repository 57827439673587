import './HPeepMedia.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { loadImageSize, ImageDimension, cheepUid } from '~/helpers';
import { HMediaOverlayCaption, HProgressSpinner } from '~/components';
import { HotelFeatureItemImage } from '~/pages/_lang/hotels/_hotel_slug/index/-components/MyHotelFeature/MyHotelFeatureItem';

/**
 * 100pxを何秒でスライドするか
 */
const DEFAULT_PX_PER_SECONDS = 8;

/**
 * スライド再開する時に何秒待つか
 */
const DEFAULT_REPLAY_DELAY = 0;

/**
 * 何秒でフェードするか
 */
const DEFAULT_FADE_PER_SECONDS = 1;

/**
 * 何％以上隠れていたらアニメーションするか
 */
const DEFAULT_THRESHOLD_PER = 1 / 3;

export interface HPeepMediaProps {
  src: HotelFeatureItemImage;
  pxPerSeconds?: number;
  replayDelay?: number;
  fadePerSeconds?: number;
  thresholdPer?: number;
  lazy?: boolean;
  reverse?: boolean;
}

export interface HPeepMediaEmits {}

export interface HPeepMediaScopedSlots {}

export interface HPeepMediaComputedInfo {
  id: string;
  src: string;
  caption?: string;
  containerWidth: number;
  containerHeight: number;
  originalWidth: number;
  originalHeight: number;
  width: number;
  height: number;
  overflowedX: number;
  overflowedY: number;
  nodeStyle: {
    backgroundImage: string;
  };
  style?: string;
  duration: number;
}

//
// このコンポーネントは利用側で height をstyle指定しておかないとちゃんと動作しない
//
@Component<HPeepMediaRef>({
  name: 'HPeepMedia',

  render() {
    const { computedInfo } = this;
    return (
      <div
        staticClass="h-peep-media"
        class={this.classes}
        v-inview={
          this.inviewed
            ? false
            : {
                rootMargin: '200px 0px',
                in: () => {
                  this.inviewed = true;
                },
              }
        }
        v-resize={{
          debounce: 250,
          value: this.handleResize,
        }}>
        {computedInfo && [
          <style domPropsInnerHTML={computedInfo.style}></style>,
          <div
            key="loaded"
            id={computedInfo.id}
            staticClass="h-peep-media__node"
            style={computedInfo.nodeStyle}
          />,
          computedInfo.caption && (
            <HMediaOverlayCaption
              modelValue={{
                caption: computedInfo.caption,
                position:
                  this.$theme.is('aomoriya') || this.reverse ? 'right' : 'left',
              }}
            />
          ),
        ]}
        {!this.lazy && !computedInfo && (
          <div
            key="loading"
            staticClass="h-peep-media__node"
            style={{
              backgroundImage: this.inviewed ? `url(${this.src})` : undefined,
            }}
          />
        )}
        {this.lazy && this.isLoading && (
          <HProgressSpinner staticClass="h-peep-media__loading" />
        )}
      </div>
    );
  },
  watch: {
    src() {
      this.load();
    },
    inviewed: {
      immediate: true,
      handler(inviewed: boolean) {
        if (process.browser && inviewed) {
          this.load();
        }
      },
    },
    computedInfo(info: HPeepMediaComputedInfo | undefined) {
      if (!info) return;
      const duration = Math.floor(info.duration / 2);
      if (duration < 250) {
        this.animationReachedHalf = true;
        return;
      }
      this.detectAnimationReachedHalfTimer = window.setTimeout(() => {
        this.animationReachedHalf = true;
      }, duration);
    },
  },
  beforeDestroy() {
    this.clearDetectAnimationReachedHalfTimer();
  },
})
export class HPeepMediaRef extends Vue implements HPeepMediaProps {
  @Prop({ type: Object, required: true }) readonly src!: HotelFeatureItemImage;
  @Prop(Boolean) readonly lazy!: boolean;
  @Prop(Boolean) readonly reverse!: boolean;
  @Prop({ type: Number, default: DEFAULT_PX_PER_SECONDS })
  readonly pxPerSeconds!: number;

  @Prop({ type: Number, default: DEFAULT_REPLAY_DELAY })
  readonly replayDelay!: number;

  @Prop({ type: Number, default: DEFAULT_FADE_PER_SECONDS })
  readonly fadePerSeconds!: number;

  @Prop({ type: Number, default: DEFAULT_THRESHOLD_PER })
  readonly thresholdPer!: number;

  private inviewed: boolean = !this.lazy;
  private containerWidth: number = 0;
  private containerHeight: number = 0;
  private imageSize: (ImageDimension & { src: string }) | null = null;
  private loadImagePromise: {
    src: string;
    promise: Promise<void>;
  } | null = null;

  private detectAnimationReachedHalfTimer: number | null = null;
  private animationReachedHalf: boolean = false;

  private clearDetectAnimationReachedHalfTimer() {
    if (this.detectAnimationReachedHalfTimer !== null) {
      clearTimeout(this.detectAnimationReachedHalfTimer);
      this.detectAnimationReachedHalfTimer = null;
    }
  }

  private error: any = null;

  get classes() {
    return {
      'h-peep-media--reverse': this.reverse,
      'h-peep-media--force-show': !this.animationReachedHalf,
    };
  }

  get isLoading() {
    return !!this.loadImagePromise;
  }

  get computedInfo(): HPeepMediaComputedInfo | undefined {
    const { containerWidth, containerHeight, imageSize, reverse } = this;
    if (containerWidth === 0 || containerHeight === 0 || !imageSize) return;
    const { pxPerSeconds, replayDelay, fadePerSeconds, thresholdPer } = this;
    const { src, width: originalWidth, height: originalHeight } = imageSize;
    let height = containerHeight;
    let width = originalWidth * (height / originalHeight);
    if (width < containerWidth) {
      width = containerWidth;
      height *= containerWidth / width;
    }

    let overflowedX = width - containerWidth;
    let overflowedY = height - containerHeight;
    if (overflowedX < 0) overflowedX = 0;
    if (overflowedY < 0) overflowedY = 0;
    const id = `__HPeepMedia__${cheepUid()}`;

    const info: HPeepMediaComputedInfo = {
      id,
      src,
      caption: (this.src.meta && this.src.meta.caption) || '',
      containerWidth,
      containerHeight,
      originalWidth,
      originalHeight,
      width,
      height,
      overflowedX,
      overflowedY,
      nodeStyle: {
        backgroundImage: `url(${src})`,
      },
      duration: 0,
    };

    const overflowXPer = overflowedX / width;

    if (overflowXPer > thresholdPer) {
      const slideSeconds = (overflowedX / 100) * pxPerSeconds;
      const totalSeconds = slideSeconds + replayDelay;
      const delayPer = Math.round((replayDelay / totalSeconds) * 100);
      const fadePer = Math.round((fadePerSeconds / totalSeconds) * 100);
      const slideTotalPer = 100 - delayPer;
      const frames: { per: number; opacity: number; x: number }[] = [];
      const rawFadeMovePer = fadePer / slideTotalPer;
      const fadeMoveX = overflowedX * rawFadeMovePer;
      const fadeMovePer = Math.round(rawFadeMovePer * 100);

      info.duration = totalSeconds * 1000;

      frames.push({
        per: 0,
        opacity: 0,
        x: 0,
      });

      if (fadePer < 50) {
        frames.push({
          per: fadeMovePer,
          opacity: 1,
          x: fadeMoveX,
        });
      }

      frames.push({
        per: 50,
        opacity: 1,
        x: overflowedX / 2,
      });

      if (fadePer < 50) {
        frames.push({
          per: slideTotalPer - fadeMovePer,
          opacity: 1,
          x: overflowedX - fadeMoveX,
        });
      }

      frames.push({
        per: slideTotalPer,
        opacity: 0,
        x: overflowedX,
      });

      frames.push({
        per: 100,
        opacity: 0,
        x: overflowedX,
      });

      const prefixes = ['', '-webkit-'];
      const keyframes = frames
        .map(({ per, opacity, x }) => {
          const amount = reverse ? x - overflowedX : -x;
          return `
            ${per}% {
              opacity: ${opacity};
              background-position: ${amount}px center;
            }
          `;
        })
        .join('\n');
      const prefixedKeyframes = prefixes.map(
        (prefix) => `
        @${prefix}keyframes ${id}-animation {
          ${keyframes}
        }
      `,
      );
      const prefixedAnimations = prefixes.map(
        (prefix) => `
        ${prefix}animation: ${info.duration}ms linear 1s infinite  both running ${id}-animation;
      `,
      );

      info.style = `
        #${id} {
          opacity: 0;
          ${prefixedAnimations}
        }
        ${prefixedKeyframes}
      `;
    }

    return info;
  }

  get overflowedX() {
    const { computedInfo } = this;
    return computedInfo && computedInfo.overflowedX;
  }

  load() {
    const { src } = this;
    const { image } = src;
    if (this.imageSize && this.imageSize.src === image)
      return Promise.resolve();
    this.imageSize = null;
    if (!this.loadImagePromise || this.loadImagePromise.src !== image) {
      this.loadImagePromise = {
        src: image,
        promise: loadImageSize(image)
          .then((size) => {
            if (this.src !== src) {
              return;
            }
            this.imageSize = {
              src: image,
              ...size,
            };
            this.loadImagePromise = null;
          })
          .catch((_err) => {
            this.loadImagePromise = null;
            this.error = _err;
            throw _err;
          }),
      };
    }
    return this.loadImagePromise.promise;
  }

  private handleResize({ width, height }: { width: number; height: number }) {
    this.containerWidth = width;
    this.containerHeight = height;
  }
}

export const HPeepMedia = tsx
  .ofType<HPeepMediaProps, HPeepMediaEmits, HPeepMediaScopedSlots>()
  .convert(HPeepMediaRef);
