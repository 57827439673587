import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelRoomGallery } from '~/schemes';
import { HBtn } from '~/components';

export interface MyHotelGuestroomGalleryProps {
  gallery: HotelRoomGallery;
}

export interface MyHotelGuestroomGalleryEmits {}

export interface MyHotelGuestroomGalleryScopedSlots {}

@Component<MyHotelGuestroomGalleryRef>({
  name: 'MyHotelGuestroomGallery',
  render() {
    const { galleries } = this;
    return (
      <div
        staticClass="my-hotel-guestroom-gallery"
        v-inview={
          !this.interbalInviewed
            ? {
                in: () => {
                  this.interbalInviewed = true;
                },
              }
            : undefined
        }>
        {galleries && (
          <div staticClass="my-hotel-guestroom-gallery__rows">
            {galleries.map((row, rowIndex) => {
              const { url: urls } = row;
              const { size } = row;
              const itemClasses = `my-hotel-guestroom-gallery__item--div-${size}`;

              return (
                <div
                  staticClass="my-hotel-guestroom-gallery__row"
                  key={rowIndex}>
                  {urls.map((url, itemIndex) => (
                    <div
                      staticClass="my-hotel-guestroom-gallery__item"
                      class={itemClasses}
                      key={itemIndex}>
                      <div
                        staticClass="my-hotel-guestroom-gallery__item__node"
                        style={
                          this.interbalInviewed
                            ? {
                                backgroundImage: `url(${this.$res.img(url)})`,
                              }
                            : undefined
                        }
                      />
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  },
})
export class MyHotelGuestroomGalleryRef
  extends Vue
  implements MyHotelGuestroomGalleryProps {
  @Prop({ type: Object, required: true }) readonly gallery!: HotelRoomGallery;

  private interbalInviewed: boolean = false;

  get galleries() {
    const { galleries } = this.gallery;
    if (!galleries || !galleries.length) return;
    return galleries;
  }
}

export const MyHotelGuestroomGallery = tsx
  .ofType<
    MyHotelGuestroomGalleryProps,
    MyHotelGuestroomGalleryEmits,
    MyHotelGuestroomGalleryScopedSlots
  >()
  .convert(MyHotelGuestroomGalleryRef);
