import { LanguageDataMap, AvailableLanguage } from './language';
import {
  GeneralOption,
  extractGeneralOptionLS,
  GeneralLink,
  GeneralLinkLS,
  extractGeneralLinkLS,
} from './general';
import { GalleryImage } from './gallery';
import { DisplayFormat } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

/**
 * ゲストルーム : 水回り
 */
export enum WaterSectionType {
  /**
   * 露天風呂
   */
  OutdoorBath = '1',
  /**
   * ジェットバス
   */
  WhirlpoolBath = '2',
  /**
   * サウナ
   */
  Sauna = '3',
  /**
   * ダブルシンク
   */
  DoubleBathroomSink = '4',
  /**
   * バスタブ
   */
  Bathtub = '5',
  /**
   * シャワー
   */
  Shower = '6',
  /**
   * シャワーブース
   */
  ShowerBooth = '7',
  /**
   * バス・トイレ・洗面台別
   */
  BathroomSeparated = '8',
  /**
   * 洗面台
   */
  WashBasin = '9',
  /**
   * 洗浄機能付きトイレ
   */
  Washlet = '10',
  /**
   * パウダースペース
   */
  PowderSpace = '11',
}

export interface WaterSectionOption extends GeneralOption<WaterSectionType> {}

export interface WaterSectionOptionLS extends Omit<WaterSectionOption, 'name'> {
  name: LanguageDataMap<WaterSectionOption['name']>;
}

export function extractWaterSectionOptionLS(
  ls: WaterSectionOptionLS,
  lang: AvailableLanguage,
): WaterSectionOption {
  return extractGeneralOptionLS(ls, lang);
}

/**
 * ゲストルーム : 設備・アメニティ
 */
export enum RoomFacilityType {
  /**
   * Bluetoothプレーヤー
   */
  BluetoothSpeaker = '1',
  /**
   * 読書灯
   */
  ReadingLight = '2',
  /**
   * 加湿空気清浄機
   */
  HumidifyingAirPurifier = '3',
  /**
   * 空気清浄機
   */
  AirCleaner = '4',
  /**
   * 加湿器
   */
  Humidifier = '5',
  /**
   * TV
   */
  TV = '6',
  /**
   * 電気ケトル
   */
  ElectricKettle = '7',
  /**
   * 冷蔵庫
   */
  Refrigerator = '8',
  /**
   * ミニバー
   */
  MiniBar = '9',
  /**
   * 金庫
   */
  Safe = '10',
  /**
   * ロッカー
   */
  Locker = '11',
  /**
   * 完全遮光幕
   */
  BlackoutCurtains = '12',
  /**
   * 浴衣
   */
  Yukata = '13',
  /**
   * 作務衣（大人・子供）
   */
  Samue = '14',
  /**
   * パジャマ
   */
  Pajamas = '15',
  /**
   * サンダル
   */
  Slippers = '16',
  /**
   * 歯ブラシ
   */
  Toothbrush = '17',
  /**
   * ヘアブラシ
   */
  Hairbrush = '18',
  /**
   * バスアメニティ
   */
  BathAmenities = '19',
  /**
   * 化粧水
   */
  FaceLotion = '20',
  /**
   * カミソリ
   */
  Razor = '21',
  /**
   * 耳栓
   */
  Earplug = '22',
  /**
   * タオル
   */
  Towels = '23',
  /**
   * ドライヤー
   */
  Hairdryer = '24',
  /**
   * ハンガー
   */
  Hanger = '25',
  /**
   * ウォールハンガー
   */
  WallHanger = '26',
  /**
   * 風呂敷
   */
  Furoshiki = '27',
  /**
   * ソファ
   */
  Sofa = '28',
  /**
   * 湯かご
   */
  Yukago = '29',
}

export interface RoomFacilityOption extends GeneralOption<RoomFacilityType> {}

export interface RoomFacilityOptionLS extends Omit<RoomFacilityOption, 'name'> {
  name: LanguageDataMap<RoomFacilityOption['name']>;
}

export function extractRoomFacilityOptionLS(
  ls: RoomFacilityOptionLS,
  lang: AvailableLanguage,
): RoomFacilityOption {
  return extractGeneralOptionLS(ls, lang);
}

export interface HotelRoomCommons {
  waterSections: WaterSectionOption[];
  facilities: RoomFacilityOption[];
}

export interface HotelRoomBed {
  body: string;
  memo: string | null;
}

export interface HotelRoomBedLS extends Omit<HotelRoomBed, 'body' | 'memo'> {
  body: LanguageDataMap<string>;
  memo: LanguageDataMap<string | null> | null;
}

export function extractHotelRoomBedLS(
  ls: HotelRoomBedLS,
  lang: AvailableLanguage,
): HotelRoomBed {
  return {
    ...ls,
    body: ls.body[lang] as string,
    memo: (ls.memo && ls.memo[lang]) || null,
  };
}

export interface HotelRoomBook {
  id: string | null;
  externalUrl: string | null;
  text: string | null;
}

export interface HotelRoomBookLS
  extends Omit<HotelRoomBook, 'externalUrl' | 'text'> {
  externalUrl: LanguageDataMap<string | null> | null;
  text: LanguageDataMap<string | null> | null;
}

export function extractHotelRoomBookLS(
  ls: HotelRoomBookLS,
  lang: AvailableLanguage,
): HotelRoomBook {
  const { externalUrl, text } = ls;
  return {
    ...ls,
    externalUrl: (externalUrl && (externalUrl[lang] as string)) || null,
    text: (text && (text[lang] as string)) || null,
  };
}

export interface HotelRoom {
  /**
   * uid（宿getsの部屋ID or booksのidを連結でOK）
   */
  id: string;

  /**
   * 宿getsの部屋ID + リンク文言のリスト
   */
  books: HotelRoomBook[];

  /**
   * ギャラリーイメージ
   * 1つ目にメイン画像
   */
  images: GalleryImage[];

  name: string;
  description: string;

  /**
   * 客室定員 [min]〜[max]名
   */
  capacity: {
    min: number;
    max: number;
  };

  /**
   * サイズ [min]m²〜[max]m²
   */
  size: {
    min: number;
    max: number;
  };

  /**
   * ベッド種別
   * ※ プログラム的な識別子ではなくプレーンテキスト
   */
  bedType: string;

  /**
   * ベッド情報リスト
   */
  beds: HotelRoomBed[];

  /**
   * ベッド情報リスト注記
   */
  bedsMemo: string | null;

  /**
   * アクセシビリティ
   */
  accessibility: string | null;

  /**
   * Wifi
   */
  wifi: string | null;

  /**
   * 設備・アメニティ
   */
  facility: RoomFacilityType[];

  /**
   * 水周り
   */
  waterSection: WaterSectionType[];

  /**
   * こども添い寝
   */
  childLying: string | null;

  /**
   * その他
   */
  memo: string | null;

  /**
   * 追加コンテンツ
   */
  additionalContent: {
    url: string;
    text: string;
  } | null;
}

export interface HotelRoomLS
  extends Omit<
    HotelRoom,
    | 'books'
    | 'name'
    | 'description'
    | 'bedType'
    | 'beds'
    | 'bedsMemo'
    | 'accessibility'
    | 'wifi'
    | 'childLying'
    | 'memo'
    | 'additionalContent'
  > {
  books: HotelRoomBookLS[];
  name: LanguageDataMap<string>;
  description: LanguageDataMap<string>;
  bedType: LanguageDataMap<string>;
  beds: HotelRoomBedLS[];
  bedsMemo: LanguageDataMap<string | null> | null;
  accessibility: LanguageDataMap<string | null> | null;
  wifi: LanguageDataMap<string | null> | null;
  childLying: LanguageDataMap<string | null> | null;
  memo: LanguageDataMap<string | null> | null;
  additionalContent: LanguageDataMap<
    HotelRoom['additionalContent'] | null
  > | null;
}

export function extractHotelRoomLS(
  ls: HotelRoomLS,
  lang: AvailableLanguage,
): HotelRoom {
  const {
    books,
    bedsMemo,
    accessibility,
    wifi,
    childLying,
    memo,
    additionalContent,
  } = ls;

  return {
    ...ls,
    books: books.map((book) => extractHotelRoomBookLS(book, lang)),
    name: ls.name[lang] as string,
    description: ls.description[lang] as string,
    bedType: ls.bedType[lang] as string,
    beds: ls.beds.map((bed) => extractHotelRoomBedLS(bed, lang)),
    bedsMemo: (bedsMemo && bedsMemo[lang]) || null,
    accessibility: (accessibility && (accessibility[lang] as string)) || null,
    wifi: (wifi && (wifi[lang] as string)) || null,
    childLying: (childLying && (childLying[lang] as string)) || null,
    memo: (memo && (memo[lang] as string)) || null,
    additionalContent: (additionalContent && additionalContent[lang]) || null,
  };
}

export function extractHotelRoomLSList(
  rows: HotelRoomLS[],
  lang: AvailableLanguage,
): HotelRoom[] {
  return rows.map((row) => extractHotelRoomLS(row, lang));
}

export interface HotelRoomSettings {
  description?: string;
  link?: GeneralLink;
  displayFormat: DisplayFormat;
}

export interface HotelRoomGalleryGallery {
  size: 'lg' | 'md' | 'sm';
  url: string[];
}

export interface HotelRoomGallery {
  description?: string | null;
  galleries?: HotelRoomGalleryGallery[];
  link?: GeneralLink;
}

export interface HotelRoomGalleryLS
  extends Omit<HotelRoomGallery, 'description' | 'link'> {
  description?: LanguageDataMap<string | null>;
  link?: GeneralLinkLS;
}

export function extractHotelRoomGalleryLS(
  ls: HotelRoomGalleryLS,
  lang: AvailableLanguage,
): HotelRoomGallery {
  const { description, link } = ls;
  return {
    ...ls,
    description: description && description[lang],
    link: link && extractGeneralLinkLS(link, lang),
  };
}
