import './HTextField.scss';

import * as tsx from 'vue-tsx-support';
import { VNode } from 'vue';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import {
  HFormControl,
  HFormControlProps,
  HFormControlEmits,
  HFormControlScopedSlots,
} from './mixins/HFormControl';
import { ValidateTiming } from './mixins/HFormNode';
import { toHalfWidth } from '~/helpers';

export interface HTextFieldProps extends HFormControlProps {
  validateTiming?: ValidateTiming;
  type?: string;
  placeholder?: string;
  autocomplete?: string | boolean;
  labelForTop?: boolean;
  alignCenter?: boolean;
  trim?: boolean;
  halfWidth?: boolean;
  numeric?: boolean;
  creditCard?: boolean;
  postalCode?: boolean;
  dense?: boolean;
  maxlength?: string | number;
  singleSpace?: boolean;
}

export interface HTextFieldEmits extends HFormControlEmits {
  onChangecomposing: boolean;
  onCompositionstart: CompositionEvent;
  onCompositionend: CompositionEvent;
  onFocus: FocusEvent;
  onBlur: FocusEvent;
}

export interface HTextFieldScopedSlots extends HFormControlScopedSlots {}

@Component<HTextFieldRef>({
  name: 'HTextField',
  props: {
    validateTiming: {
      type: String,
      default: 'blur',
    },
    labelForTop: {
      type: Boolean,
      default: false,
    },
  },
})
export class HTextFieldRef
  extends Mixins<HFormControl>(HFormControl)
  implements HTextFieldProps {
  $refs!: {
    node: HTMLInputElement;
  };

  @Prop({ type: String, default: 'text' }) readonly type!: string;
  @Prop(String) readonly placeholder?: string;
  @Prop([String, Boolean]) readonly autocomplete?: string | boolean;
  @Prop(Boolean) readonly alignCenter!: boolean;
  @Prop(Boolean) readonly trim!: boolean;
  @Prop(Boolean) readonly halfWidth!: boolean;
  @Prop(Boolean) readonly numeric!: boolean;
  @Prop(Boolean) readonly creditCard!: boolean;
  @Prop(Boolean) readonly postalCode!: boolean;
  @Prop(Boolean) readonly dense!: boolean;
  @Prop([String, Number]) readonly maxlength?: string | number;
  @Prop(Boolean) readonly singleSpace!: boolean;

  private internalIsComposing: boolean = false;

  get isComposing() {
    return this.internalIsComposing;
  }

  get classes() {
    const classes: { [key: string]: boolean } = {
      'h-text-field--focused': this.focused,
      'h-text-field--has-value': this.hasValue,
      'h-text-field--has-box-label': this.hasBoxLabel,
      'h-text-field--has-error': !!this.hasError,
      'h-text-field--disabled': !!this.isDisabled,
      'h-text-field--align-center': this.alignCenter,
      'h-text-field--dense': this.dense,
    };
    return classes;
  }

  get computedAutocomplete(): string | undefined {
    const { autocomplete } = this;
    if (autocomplete === undefined) return;
    if (autocomplete) {
      return typeof autocomplete === 'string' ? autocomplete : 'on';
    }
    return 'off';
  }

  get hasValue() {
    return (this.value + '' || '').trim().length > 0;
  }

  get hasBoxLabel() {
    return !this.labelForTop && !!this.displayLabel;
  }

  protected focusNode() {
    const { node } = this.$refs;
    node && node.focus();
  }

  protected blurNode() {
    const { node } = this.$refs;
    node && node.blur();
  }

  private setIsComposing(isComposing: boolean) {
    if (this.internalIsComposing !== isComposing) {
      this.internalIsComposing = isComposing;
      this.$emit('changecomposing', isComposing);
    }
  }

  private getConvertedValue(value?: string | null, focused = false) {
    let converted = value + '';
    if (!focused && (this.trim || this.postalCode)) {
      converted = converted.trim();
    }
    if (this.singleSpace) {
      // converted = converted.replace(/[\s]+/g, ' ');
      converted = converted.replace(/[\s]+/g, (chars) => {
        return chars.slice(0, 1);
      });
    }
    if (!focused && (this.halfWidth || this.postalCode)) {
      converted = toHalfWidth(converted, this.postalCode);
    }
    if (this.numeric) {
      const halfed = toHalfWidth(converted);
      const tester = /\d/;
      converted = halfed
        .split('')
        .filter((c) => tester.test(c))
        .join('');
      // const numericMatch = halfed.match(//);
    }
    if (converted !== value) {
      return converted;
    }
  }

  private handleNodeInput(focused: boolean = true) {
    const { node } = this.$refs;
    if (node) {
      let { value } = node;
      const converted = this.getConvertedValue(value, focused);
      if (converted !== undefined) {
        value = converted;
        node.value = converted;
      }

      // 愚直だけど、クレカのフォーマットここでやる
      if (this.creditCard) {
        let cursor = node.selectionStart || 0;
        const matches = value.substring(0, cursor).match(/[^0-9]/g);
        if (matches) cursor -= matches.length;
        value = value.replace(/[^0-9]/g, '').substring(0, 16);
        let formatted = '';
        for (let i = 0, n = value.length; i < n; i++) {
          if (i && i % 4 === 0) {
            if (formatted.length <= cursor) cursor++;
            formatted += ' ';
          }
          formatted += value[i];
        }
        if (formatted !== node.value) {
          node.value = formatted;
          node.selectionEnd = cursor;
        }
      }

      this.value = node.value;
    }
  }

  protected beforeValidate(): void | Promise<void> {
    this.handleNodeInput(false);
  }

  protected genBodyChildren(): VNode[] {
    const { maxlength } = this;

    const attrs: { [key: string]: any } = {
      type: this.type,
      name: this.name,
      placeholder: this.placeholder,
      tabindex: '-1',
      disabled: this.isDisabled,
      readonly: this.isReadonly,
      autocomplete: this.computedAutocomplete,
      autofocus: this.autofocus,
    };

    if (maxlength) {
      attrs.maxlength = maxlength;
    }

    const $node = (
      <input
        staticClass="h-text-field__node"
        attrs={attrs}
        domProps={{
          value: this.value,
        }}
        onCompositionstart={(ev) => {
          this.setIsComposing(true);
          this.$emit('compositionstart', ev);
        }}
        onCompositionend={(ev) => {
          this.handleNodeInput();
          this.setIsComposing(false);
          this.$emit('compositionend', ev);
        }}
        onInput={(ev) => {
          this.handleNodeInput();
          this.validationValueHasChanged = true;
        }}
        onFocus={(ev) => {
          if (this.isDisabled || this.isReadonly) return;
          this.onNodeFocus();
          this.$emit('focus', ev);
        }}
        onBlur={(ev) => {
          const { node } = this.$refs;
          if (!node) return;
          const { value } = node;
          const converted = this.getConvertedValue(value);
          if (converted !== undefined) {
            node.value = converted;
            this.value = converted;
          }
          this.onNodeBlur();
          this.$emit('blur', ev);
        }}
        ref="node"
      />
    );

    const boxChildren: VNode[] = [$node];

    if (!this.labelForTop) {
      const { displayLabel } = this;
      if (displayLabel) {
        boxChildren.push(
          <label staticClass="h-text-field__label">{displayLabel}</label>,
        );
      }
    }

    return [
      <div staticClass="h-text-field" class={this.classes}>
        <div
          staticClass="h-text-field__box"
          tabindex={this.computedTabIndex || 0}
          onFocus={(ev) => {
            if (this.isDisabled || this.isReadonly) return;
            this.$refs.node.focus();
          }}
          onBlur={(ev) => {
            this.onNodeBlur();
            this.$emit('blur', ev);
          }}>
          {boxChildren}
        </div>
      </div>,
    ];
  }
}

export const HTextField = tsx
  .ofType<HTextFieldProps, HTextFieldEmits, HTextFieldScopedSlots>()
  .convert(HTextFieldRef);
