import './HIconLink.scss';

import * as tsx from 'vue-tsx-support';
import { VNode, PropType } from 'vue';
import { navigationItemProps } from '~/schemes';
import { createNavigationItemSettings } from '~/helpers';

import { HIcon, IconName } from '~/components';

/**
 * このコンポーネントを通常利用すると、外部リンクの時はラベルの右にアイコンが出ちゃうので
 * それが困る時は、internal オプションにtrueを渡してください
 */
export const HIconLink = tsx.component({
  name: 'HIconLink',
  functional: true,

  props: {
    ...navigationItemProps(),
    back: Boolean,
    internal: Boolean,
    icon: String as PropType<IconName>,
    hiddenExternalIcon: Boolean,
  },

  render(h, context) {
    const { tag, data, children, isOpen } = createNavigationItemSettings(
      context,
      'h-icon-link',
    );

    const { back, icon, label, internal, hiddenExternalIcon } = context.props;
    const name: IconName =
      icon || (back ? 'keyboard-arrow-left' : 'keyboard-arrow-right');

    const myChildren: VNode[] = [
      <HIcon
        staticClass={`h-icon-link__icon h-icon-link__icon--${name}`}
        name={name}
      />,
      <span staticClass="h-icon-link__label">
        <span staticClass="h-icon-link__label__text">
          {label == null ? children : label}
        </span>
        {!hiddenExternalIcon && !internal && isOpen && (
          <HIcon
            staticClass="h-icon-link__icon h-icon-link__icon--open"
            name="open-in-new"
          />
        )}
      </span>,
    ];

    return h(tag, data, myChildren);
  },
});
