import Vue from 'vue';
import { Context, Plugin } from '@nuxt/types';
import { RawLocation, Location } from 'vue-router';
import {
  AvailableLanguage,
  AVAILABLE_LANGUAGES,
  LanguageInfo,
  DynamicLanguageInfo,
  LANGUAGE_INFORMATION,
  LanguageRedirectResult,
  matchedAvairableLanguage,
  DEFAULT_LANGUAGE,
  AVAILABLE_LANGUAGE_RE,
} from '~/schemes';
import { trailingSlash } from '~/helpers';

declare module 'vue/types/vue' {
  export interface Vue {
    $language: LanguageService;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $language: LanguageService;
  }
}

export const COOKIE_DISPLAY_LANGUAGE = 'H-DISPLAY-LANGUAGE';

const LINK_RE = new RegExp(`^/(${AVAILABLE_LANGUAGES.join('|')})/`);

export class LanguageService {
  readonly context!: Context;

  get current(): AvailableLanguage {
    return this.context.store.state.language.current;
  }

  get info(): LanguageInfo {
    return this.context.store.getters['language/currentInfo'];
  }

  get detectedLanguage(): string | null {
    return this.context.store.getters['language/detectedLanguage'];
  }

  get detectedAvailableLanguage(): AvailableLanguage {
    return this.context.store.getters['language/detectedAvailableLanguage'];
  }

  get hotel() {
    return this.context.$hotel.current;
  }

  /**
   * @todo
   * ホテルだけでなく、ブランド別とかそういう軸でも可変になるので注意だよ
   */
  get currentInformation(): LanguageInfo[] {
    const { hotel } = this;
    if (!hotel) return LANGUAGE_INFORMATION;
    const { availableLanguages } = hotel;
    const informations: LanguageInfo[] = [];
    availableLanguages.forEach(({ id, external }) => {
      const hit = LANGUAGE_INFORMATION.find((L) => L.id === id);
      if (hit) {
        informations.push({
          ...hit,
          external,
        });
      }
    });
    return informations;
  }

  get dynamicInformations(): DynamicLanguageInfo[] {
    const { fullPath } = this.context.route;
    const dynamicInformations = this.currentInformation.map((info) => {
      let switchUrl: string;
      if (info.external) {
        switchUrl = info.external;
      } else {
        const { id } = info;
        switchUrl = fullPath;
        const match = switchUrl.match(AVAILABLE_LANGUAGE_RE);
        if (match && match[1]) {
          switchUrl = switchUrl.replace(AVAILABLE_LANGUAGE_RE, '/' + id);
        } else {
          switchUrl = `/${id}${switchUrl}`;
        }
      }
      return {
        ...info,
        switchUrl,
      };
    });
    dynamicInformations.sort((a, b) => {
      const ai = AVAILABLE_LANGUAGES.indexOf(a.id);
      const bi = AVAILABLE_LANGUAGES.indexOf(b.id);
      if (ai < bi) return -1;
      if (ai > bi) return 1;
      return 0;
    });
    return dynamicInformations;
  }

  constructor(context: Context) {
    this.context = context;
  }

  checkAndRedirect(
    availableLanguages: AvailableLanguage[],
    redirectTargets?: AvailableLanguage[],
    externals?: { [key: string]: string },
  ): LanguageRedirectResult {
    const { current } = this;
    if (availableLanguages.includes(current))
      return LanguageRedirectResult.Continued;

    if (!redirectTargets) {
      const { detectedLanguage } = this;
      if (detectedLanguage) {
        const hit = matchedAvairableLanguage(detectedLanguage);
        if (hit === 'ja' || !hit) {
          // >>> クライアント言語が日本語、もしくは存在しなけえれば日本語へ
          redirectTargets = ['ja'];
        } else {
          // >>> クライアント言語が存在して、日本語以外であれば英語へ
          redirectTargets = ['en'];
        }
      } else {
        redirectTargets = [DEFAULT_LANGUAGE];
      }
    }
    if (!redirectTargets) redirectTargets = ['ja'];
    let result: AvailableLanguage | undefined;
    for (let i = 0, l = redirectTargets.length; i < l; i++) {
      const l = redirectTargets[i];
      if (availableLanguages.includes(l)) {
        result = l;
        break;
      }
    }
    if (result) {
      let redirectTo: string;
      if (externals) {
        redirectTo = externals[result];
      } else {
        redirectTo = this.context.route.fullPath;
        const match = redirectTo.match(AVAILABLE_LANGUAGE_RE);
        if (match && match[1]) {
          redirectTo = redirectTo.replace(AVAILABLE_LANGUAGE_RE, '/' + result);
        } else {
          redirectTo = `/${result}${redirectTo}`;
        }
      }
      if (process.browser) {
        location.href = redirectTo;
      } else {
        this.context.redirect(302, redirectTo);
      }
      return LanguageRedirectResult.Redirectd;
    } else {
      return LanguageRedirectResult.Missing;
    }
  }

  getCookie(): AvailableLanguage | null {
    return this.context.app.$cookies.get(COOKIE_DISPLAY_LANGUAGE) || null;
  }

  saveCookie(lang: AvailableLanguage) {
    this.context.app.$cookies.set(COOKIE_DISPLAY_LANGUAGE, lang, {
      maxAge: 60 * 60 * 24 * 365,
      path: '/',
    });
  }

  switch(lang: AvailableLanguage) {
    const { currentInformation } = this;
    const hit = currentInformation.find(({ id }) => id === lang);
    if (!hit) return;

    this.saveCookie(lang);

    if (hit.external) {
      window.open(hit.external, '_blank');
      // location.replace(hit.external);
      return;
    }

    const { router } = this.context.app;
    if (!router) {
      throw new Error('missing router');
    }
    const routerBase: string = (router as any).options.base;
    if (!routerBase) {
      throw new Error('missing router base path');
    }

    const { store } = this.context;
    if (store.state.language.current !== lang) {
      const { pathname, search } = location;
      const re = new RegExp(`^${routerBase}(${AVAILABLE_LANGUAGES.join('|')})`);
      const redirectTo = (pathname + search).replace(re, '/' + lang);
      this.context.$ui.showLoadingCover();
      location.replace(redirectTo);
    }
  }

  link(source: RawLocation): Location {
    const location = typeof source === 'string' ? { path: source } : source;
    const { path } = location;
    if (!path || path.indexOf('/') !== 0) {
      return location;
    }
    const lang = this.context.store.state.language.current;
    if (path.match(LINK_RE)) {
      location.path = path.replace(LINK_RE, '/' + lang);
    } else {
      location.path = '/' + lang + path;
    }
    location.path = trailingSlash(location.path);
    return location;
  }

  crossSearchUrl(...paths: string[]): string {
    return this.context.store.getters['language/crossSearchUrl'](...paths);
  }
}

const plugin: Plugin = (context, inject) => {
  const service = new LanguageService(context);
  context.$language = service;
  inject('language', service);
};

export default plugin;
