import { Vue, Component } from 'vue-property-decorator';
import { mediaMatches } from '~/.dynamic/media-match';

interface MqServiceListener {
  mql: MediaQueryList;
  listener: () => void;
}

interface MqServiceState {
  [key: string]: boolean;
}

@Component({
  name: 'mq-service',
})
export default class MediaQueryService extends Vue {
  get mediaMatches() {
    return mediaMatches;
  }

  get match(): MqServiceState {
    const mq = { ...this.mqSource };
    return mq;
  }

  public condition(key: string): string {
    const setting = this.mediaMatches.find((m) => m.key === key);
    if (!setting) {
      throw new Error(`missing media match key at ${key}`);
    }
    return setting.condition;
  }

  public forceUpdateMq() {
    for (const key in this._mqListeners) {
      this.mqSource[key] = this._mqListeners[key].mql.matches;
    }
  }

  private _mqListeners!: { [key: string]: MqServiceListener };
  private mqSource: MqServiceState = {};

  protected setupMq() {
    this.mediaMatches.forEach((define) => {
      const { key, condition } = define;
      const mql = window.matchMedia(condition);
      const matches = mql.matches;
      const listener = () => {
        this.mqSource[key] = mql.matches;
      };
      mql.addListener(listener);

      this._mqListeners[key] = { mql, listener };
      this.$set(this.mqSource, key, matches);
    });
  }

  protected clearMq() {
    for (const key in this._mqListeners) {
      this.$delete(this.mqSource, key);
      this._mqListeners[key].mql.removeListener(
        this._mqListeners[key].listener,
      );
      (this._mqListeners[key] as any) = null;
      delete this._mqListeners[key];
    }
  }

  protected created() {
    this._mqListeners = {};
    if (typeof window !== 'undefined') {
      this.setupMq();
    }
  }

  protected beforeDestroy() {
    this.clearMq();
  }
}
