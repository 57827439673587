import './HCurrencyDialog.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { bodyScrollLock, clickOutside } from '@dadajam4/vue-stack';
import { HDialog, HListCurrencySelector, HPathIcon } from '~/components';

export interface HCurrencyDialogProps {
  value?: boolean;
}

export interface HCurrencyDialogEmits {
  onInput: boolean;
}

export interface HCurrencyDialogScopedSlots {}

/**
 * 通貨設定ダイアログ(SP用)
 */
@Component<HCurrencyDialogRef>({
  name: 'HCurrencyDialog',
  directives: {
    bodyScrollLock,
    clickOutside,
  },
  render() {
    return (
      <HDialog
        contentClass="h-currency-dialog"
        active={this.modalIsActive}
        backdrop
        onChange={(active) => {
          this.modalIsActive = active;
        }}
        scopedSlots={{
          header: () => [
            <button
              staticClass="h-currency-dialog__close"
              type="button"
              onClick={this.close}>
              <HPathIcon
                staticClass="h-currency-dialog__close__icon"
                name="close"
              />
            </button>,
          ],
        }}>
        <HListCurrencySelector narrowDump />
      </HDialog>
    );
  },
  watch: {
    value(value: boolean) {
      this.internalModalActive = value;
    },
  },
})
export class HCurrencyDialogRef extends Vue implements HCurrencyDialogProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;

  private internalModalActive: boolean = this.value;

  get modalIsActive() {
    return this.internalModalActive;
  }

  set modalIsActive(modalIsActive) {
    if (this.internalModalActive !== modalIsActive) {
      this.internalModalActive = modalIsActive;
      this.$emit('input', modalIsActive);
    }
  }

  show() {
    this.modalIsActive = true;
  }

  close() {
    this.modalIsActive = false;
  }
}

export const HCurrencyDialog = tsx
  .ofType<
    HCurrencyDialogProps,
    HCurrencyDialogEmits,
    HCurrencyDialogScopedSlots
  >()
  .convert(HCurrencyDialogRef);
