import './HBreadCrumbs.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Breadcrumb } from '~/schemes';
import { HLink, HIcon } from '~/components';

export interface HBreadCrumbsProps {
  breadcrumbs?: Breadcrumb[] | [];
}

export interface HBreadCrumbsEmits {}

export interface HBreadCrumbsScopedSlots {}

@Component<HBreadCrumbsRef>({
  name: 'HBreadCrumbs',
  render() {
    const { breadcrumbs } = this;

    return (
      <nav class="h-bread-crumbs">
        <ol>
          {breadcrumbs?.map((i, index) => {
            return (
              <li class="h-bread-crumbs__list" key={`breadCrumbsList-${index}`}>
                <HLink
                  key={`link-${index}`}
                  staticClass="h-bread-crumbs__link"
                  class={breadcrumbs.length - 1 === index ? 'disabled' : ''}
                  to={{ path: i.slug }}>
                  {i.title}
                </HLink>
                {index < breadcrumbs.length - 1 && (
                  <HIcon
                    key={`icon-${index}`}
                    staticClass="h-bread-crumbs__icon"
                    name="keyboard-arrow-right"
                  />
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    );
  },
})
export class HBreadCrumbsRef extends Vue implements HBreadCrumbsProps {
  @Prop({ type: Array }) readonly breadcrumbs!: Breadcrumb[];
}

/** パンくずリストコンポーネント */
export const HBreadCrumbs = tsx
  .ofType<HBreadCrumbsProps, HBreadCrumbsEmits, HBreadCrumbsScopedSlots>()
  .convert(HBreadCrumbsRef);
