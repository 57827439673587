import './HCuisineContentRestaurantInfo.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HRestaurantInformation } from '../HRestaurant';
import { HThemeDecoratedTitle } from '../HThemeDecoratedTitle';
import { HBtn } from '../HBtn';
import { RestaurantCommons, Restaurant } from '~/schemes';

export interface HCuisineContentRestaurantInfoProps {
  /** 食事共通設定 */
  commons: RestaurantCommons;

  /**
   * 食事処
   *
   * * リスト下部のインフォメーション表示のために使用する
   */
  restaurant: Restaurant;
}

export interface HCuisineContentRestaurantInfoEmits {}

export interface HCuisineContentRestaurantInfoScopedSlots {}

@Component<HCuisineContentRestaurantInfoRef>({
  name: 'HCuisineContentRestaurantInfo',

  render() {
    const { commons, restaurant, reservationLinkProps } = this;

    return (
      <div staticClass="h-cuisine-content-restaurant-info">
        <HThemeDecoratedTitle
          staticClass="h-cuisine-content-restaurant-info__title"
          size="md">
          {this.$t('label.moreInformation')}
        </HThemeDecoratedTitle>
        <HRestaurantInformation
          staticClass="h-cuisine-content-restaurant-info__table"
          value={restaurant}
          commons={commons}
        />
        {!!reservationLinkProps && (
          <div staticClass="h-cuisine-content-restaurant-info__footer">
            <HBtn
              staticClass="h-cuisine-content-restaurant-info__reservation-link"
              {...reservationLinkProps.parsed}
              color="primary-wrap">
              {reservationLinkProps.text}
            </HBtn>
          </div>
        )}
      </div>
    );
  },
})
export class HCuisineContentRestaurantInfoRef
  extends Vue
  implements HCuisineContentRestaurantInfoProps {
  @Prop({ type: Object, required: true }) readonly commons!: RestaurantCommons;
  @Prop({ type: Object, required: true }) readonly restaurant!: Restaurant;

  get reservationLinkProps() {
    const { reservationLink } = this.restaurant;
    if (!reservationLink) return;
    const reservationURL = reservationLink.url;
    if (!reservationURL) return;
    const parsed = this.$navigation.parseURL(reservationURL);
    return {
      text: reservationLink.text,
      parsed,
    };
  }
}

export const HCuisineContentRestaurantInfo = tsx
  .ofType<
    HCuisineContentRestaurantInfoProps,
    HCuisineContentRestaurantInfoEmits,
    HCuisineContentRestaurantInfoScopedSlots
  >()
  .convert(HCuisineContentRestaurantInfoRef);
