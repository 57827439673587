const baseSetting = {
  monthAndDay: {
    month: 'long',
    day: 'numeric',
  },
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  shortAndWeek: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  },
  week: {
    weekday: 'short',
  },
  print: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  simpleTime: {
    hour: 'numeric',
    minute: 'numeric',
  },
  ymd: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  ymdFull: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  },
  md: {
    month: 'short',
    day: 'numeric',
  },
  mdFull: {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  },
};

export default {
  en: {
    ...baseSetting,
  },
  ja: {
    ...baseSetting,
  },
  ko: {
    ...baseSetting,
  },
  'zh-Hans': {
    ...baseSetting,
  },
  'zh-Hant': {
    ...baseSetting,
  },
};
