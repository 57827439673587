import './HLoginDialog.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { bodyScrollLock, clickOutside } from '@dadajam4/vue-stack';
import { HDialog, HLoginForm } from '~/components';

export interface HLoginDialogProps {
  value?: boolean;
}

export interface HLoginDialogEmits {
  onInput: boolean;
}

export interface HLoginDialogScopedSlots {}

/**
 * ログインダイアログ
 */
@Component<HLoginDialogRef>({
  name: 'HLoginDialog',
  directives: {
    bodyScrollLock,
    clickOutside,
  },
  render() {
    return (
      <HDialog
        contentClass="h-login-dialog"
        active={this.modalIsActive}
        backdrop
        onChange={(active) => {
          this.modalIsActive = active;
        }}>
        <HLoginForm onLoggedIn={this.handleLoggedIn} />
      </HDialog>
    );
  },
  watch: {
    value(value: boolean) {
      this.internalModalActive = value;
    },
  },
})
export class HLoginDialogRef extends Vue implements HLoginDialogProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;

  private internalModalActive: boolean = this.value;

  get modalIsActive() {
    return this.internalModalActive;
  }

  set modalIsActive(modalIsActive) {
    if (this.internalModalActive !== modalIsActive) {
      this.internalModalActive = modalIsActive;
      this.$emit('input', modalIsActive);
    }
  }

  show() {
    this.modalIsActive = true;
  }

  close() {
    this.modalIsActive = false;
  }

  private handleLoggedIn() {
    this.close();
    this.$account.openServiceUrl();
  }
}

export const HLoginDialog = tsx
  .ofType<HLoginDialogProps, HLoginDialogEmits, HLoginDialogScopedSlots>()
  .convert(HLoginDialogRef);
