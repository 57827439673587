import './HFooterCopy.scss';

import * as tsx from 'vue-tsx-support';

export const HFooterCopy = tsx.component({
  name: 'HFooterCopy',
  functional: true,

  render(h, { data }) {
    const staticClasses = data.staticClass ? [data.staticClass] : [];
    staticClasses.push('h-footer-copy');

    const staticClass = staticClasses.join(' ').trim();

    return (
      <small
        {...{
          ...data,
          staticClass,
        }}>
        © Hoshino Resorts Inc.
      </small>
    );
  },
});
