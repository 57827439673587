import './HCuisineContentSetList.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HCuisineColumnSet } from './HCuisineColumnSet';
import { HCuisineContentSet } from './HCuisineContentSet';
import { HCuisineContentRestaurantInfo } from './HCuisineContentRestaurantInfo';
import { RestaurantCommons, HotelCuisineContents, Restaurant } from '~/schemes';

export interface HCuisineContentSetListProps {
  /** 食事共通設定 */
  commons: RestaurantCommons;

  /** 料理コンテンツセットのリスト */
  contents: HotelCuisineContents;

  /**
   * 食事処
   *
   * * リスト下部のインフォメーション表示のために使用する
   */
  restaurant?: Restaurant;
}

export interface HCuisineContentSetListEmits {}

export interface HCuisineContentSetListScopedSlots {}

@Component<HCuisineContentSetListRef>({
  name: 'HCuisineContentSetList',

  render() {
    const { contents, commons, restaurant } = this;

    // お食事コンテンツの偶数と奇数でヘッダーの位置を変えるため
    let isEvenContent = true;

    return (
      <div staticClass="h-cuisine-content-set-list">
        {contents.length > 0 && (
          <div staticClass="h-cuisine-content-set-list__items">
            {contents.map((data) => {
              const id = `group-${data.groupId}`;
              const staticClass = 'h-cuisine-content-set-list__item';

              if (data.type === 'column') {
                return (
                  <HCuisineColumnSet
                    key={id}
                    id={id}
                    staticClass={staticClass}
                    commons={commons}
                    class="h-cuisine-content-set-list__item--column"
                    data={data}
                  />
                );
              } else {
                const right = !isEvenContent;
                isEvenContent = !isEvenContent;
                return (
                  <HCuisineContentSet
                    key={id}
                    id={id}
                    staticClass={staticClass}
                    commons={commons}
                    data={data}
                    right={right}
                  />
                );
              }
            })}
          </div>
        )}

        {!this.$theme.is('hoshinoya') && (
          <HCuisineContentRestaurantInfo
            commons={commons}
            restaurant={restaurant!}
          />
        )}
      </div>
    );
  },
})
export class HCuisineContentSetListRef
  extends Vue
  implements HCuisineContentSetListProps {
  @Prop({ type: Object, required: true }) readonly commons!: RestaurantCommons;
  @Prop({ type: Array, required: true })
  readonly contents!: HotelCuisineContents;

  @Prop({ type: Object }) readonly restaurant?: Restaurant;
}

export const HCuisineContentSetList = tsx
  .ofType<
    HCuisineContentSetListProps,
    HCuisineContentSetListEmits,
    HCuisineContentSetListScopedSlots
  >()
  .convert(HCuisineContentSetListRef);
