/**
 * 次のアニメーションフレームを待つプロミスを返却する
 */
export function waitNextAnimaitonFrame() {
  return new Promise((resolve) => requestAnimationFrame(resolve));
}

/**
 * 指定のアニメーションフレーム数を消化するプロミスを返却する
 */
export async function waitAnimaitonFrame(count: number = 1) {
  for (let i = 0; i < count; i++) {
    await waitNextAnimaitonFrame();
  }
}
