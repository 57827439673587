/**
 * バルーンを表示する方向
 *
 * - `"top"` マーカーの上
 * - `"bottom"` マーカーの下
 */
export type BaloonVertialPosition = 'top' | 'bottom';

/**
 * バルーンの表示位置
 */
export interface BaloonDisplayPosition {
  /** 上下方向 */
  vertical: BaloonVertialPosition;

  /** 水平座標の調整 */
  x: number;
}

/** バルーンのチップ部分の高さ */
const BALOON_CHIP_HEIGHT = 10;

/** ビューポートに対してバルーンが確保したいマージン */
const BALOON_MARGIN = 16;

/** バルーンの水平座標をアジャストしすぎると、チップがバルーンからはみ出るので、それを補正するための幅 */
const BALOON_SAFE_CHIP_WIDTH = 10;

/**
 * バルーンの表示位置を計算する
 */
export function calcBaloonDisplayPosition(
  viewport: HTMLElement,
  markerIcon: HTMLElement,
  baloonWidth: number,
  baloonHeight: number,
): BaloonDisplayPosition {
  /** 縦位置 */
  let verticalPosition: BaloonVertialPosition;

  // ビューポートとマーカーアイコン要素の座標を取得する
  const viewportRect = viewport.getBoundingClientRect();
  const iconRect = markerIcon.getBoundingClientRect();

  // 上下の挿入可能サイズを計算する
  const topSpace =
    iconRect.top - viewportRect.top - BALOON_CHIP_HEIGHT - BALOON_MARGIN;
  const bottomSpace =
    viewportRect.bottom - iconRect.bottom - BALOON_CHIP_HEIGHT - BALOON_MARGIN;

  // 空いている場所で決定する。どちらにも空きがない場合は空きの多い方にする
  if (topSpace >= baloonHeight) {
    verticalPosition = 'top';
  } else if (bottomSpace >= baloonHeight) {
    verticalPosition = 'bottom';
  } else {
    verticalPosition = topSpace > bottomSpace ? 'top' : 'bottom';
  }

  // デフォルトのバルーンの左座標
  const originalBaloonLeft =
    iconRect.left + iconRect.width * 0.5 - baloonWidth * 0.5;
  let baloonLeft = originalBaloonLeft;
  let baloonRight = baloonLeft + baloonWidth;

  // 左がはみ出ていたら右に寄せる
  const leftOverflow = viewportRect.left - baloonLeft + BALOON_MARGIN;
  if (leftOverflow > 0) {
    baloonLeft += leftOverflow;
    baloonRight = baloonLeft + baloonWidth;
  }

  // 右がはみ出ていたら左に寄せる
  const rightOverflow = baloonRight - viewportRect.right + BALOON_MARGIN;
  if (rightOverflow > 0) {
    baloonLeft -= rightOverflow;
    baloonRight = baloonLeft + baloonWidth;
  }

  /** x座標の補正値 */
  let adjustmentX = baloonLeft - originalBaloonLeft;

  // バルーンのチップ部分がはみ出ないように少し補正する
  const halfWidth = baloonWidth * 0.5;
  const minX = halfWidth * -1 + BALOON_SAFE_CHIP_WIDTH;
  const maxX = halfWidth - BALOON_SAFE_CHIP_WIDTH;
  const leftSafeOverflow = minX - adjustmentX;
  if (leftSafeOverflow > 0) {
    adjustmentX += leftSafeOverflow;
  }
  const rightSafeOverflow = adjustmentX - maxX;
  if (rightSafeOverflow > 0) {
    adjustmentX -= rightSafeOverflow;
  }

  return {
    vertical: verticalPosition,
    x: adjustmentX,
  };
}
