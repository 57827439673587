import { VNodeChildren, PropType } from 'vue';
import { RawLocation } from 'vue-router';

export interface NavigationBaseProps {
  to?: RawLocation;
  href?: string;
  target?: string;
  rel?: string;
}

export interface NavigationItem extends NavigationBaseProps {
  label?: VNodeChildren;
  key: string;
  click?: (e: MouseEvent) => void;
}

export interface HNavigationItemProps
  extends Omit<NavigationItem, 'key' | 'click'> {
  label?: VNodeChildren;
  to?: RawLocation;
  href?: string;
  target?: string;
  rel?: string;
  event?: string;
  append?: boolean;
  exact?: boolean;
  replace?: boolean;
  activeClass?: string;
  exactActiveClass?: string;
  tabindex?: string | number;
  html?: boolean;
}

export function navigationItemProps() {
  return {
    label: {
      type: (null as unknown) as PropType<VNodeChildren>,
    },
    href: String,
    target: String,
    rel: String,
    to: {
      type: Object as PropType<RawLocation>,
    },
    event: String,
    append: Boolean,
    exact: Boolean,
    replace: Boolean,
    activeClass: String,
    exactActiveClass: String,
    html: Boolean,
  };
}

/**
 * @todo: 環境変数で環境別のホスト名を管理した方が良い
 */
export const EXTERNAL_RE = /^https?:\/\//;
