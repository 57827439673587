// @ts-nocheck
/* eslint-disable no-undef */
type Listenr<K extends keyof HTMLElementEventMap> = (
  this: HTMLAnchorElement,
  ev: HTMLElementEventMap[K],
) => any;

interface ListenrWithOptions<K extends keyof HTMLElementEventMap>
  extends AddEventListenerOptions {
  handler: Listenr<K>;
}

type CreateElementEventListenrs = {
  [K in keyof HTMLElementEventMap]?: Listenr<K> | ListenrWithOptions<K>;
};

type ElementChild = string | number | Element;

/**
 * HTML要素生成時のオプション
 */
export interface CreateElementOptions {
  class?: string;
  on?: CreateElementEventListenrs;
}

/**
 * HTML要素を作成する
 *
 * @param tagName - タグ名
 * @param options - オプション
 * @param children - 子要素
 * @returns HTML要素
 */
export function createElement<K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  options: CreateElementOptions = {},
  children?: ElementChild | ElementChild[],
) {
  const { class: className = '', on } = options;
  const el = document.createElement(tagName);
  el.className = className;
  on &&
    Object.entries(on).forEach(([eventName, _opts]) => {
      const {
        once,
        passive,
        signal,
        capture = false,
        handler,
      }: ListenrWithOptions<any> =
        typeof _opts === 'function' ? { handler: _opts } : _opts;
      el.addEventListener(eventName, handler, {
        once,
        passive,
        signal,
        capture,
      });
    });

  if (children) {
    const _children = Array.isArray(children) ? children : [children];
    _children.forEach((child) => {
      const _child =
        child instanceof Element
          ? child
          : document.createTextNode(String(child));
      el.appendChild(_child);
    });
  }
  return el;
}

/**
 * 画像をロードしてimg要素をreturnする
 *
 * @param src - 読み込む画像src
 * @returns img要素として解決されるプロミスインスタンス
 */
export function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = document.createElement('img');
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = src;
  });
}

/**
 * ランダムでチープなユニークIDを生成する
 *
 * @returns ID文字列
 */
export function createCheepId() {
  return `${Date.now()}:${Math.random()}`;
}
