import './HEmbedForm.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, EmbedFormModule } from '~/schemes';
import { HSyncFrame } from '~/components/HSyncFrame';

/** 埋め込みジュールのプロパティ */
export type EmbedFormModuleProperties = ResolvedLanugageData<
  EmbedFormModule['properties'],
  AvailableLanguage
>;
export interface HEmbedFormProps {
  value: EmbedFormModuleProperties;
}
export interface HEmbedFormEmits {}
export interface HEmbedFormScopedSlots {}

@Component<HEmbedFormRef>({
  name: 'HEmbedForm',

  render() {
    const { url } = this;

    return (
      <div class="v-embed-form-module">
        <HSyncFrame src={url}></HSyncFrame>
      </div>
    );
  },
})
export class HEmbedFormRef extends Vue implements HEmbedFormProps {
  @Prop({ type: Object, required: true })
  readonly value!: EmbedFormModuleProperties;

  get url() {
    return this.value.url;
  }
}

export const HEmbedForm = tsx
  .ofType<HEmbedFormProps, HEmbedFormEmits, HEmbedFormScopedSlots>()
  .convert(HEmbedFormRef);
