import './HLinkTable.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { HBtn } from '~/components';
import { mergeVNodeData } from '~/helpers';

export interface HLinkTableItem {
  text: string;
  url: string | null;
  label: string;
}

export interface HLinkTableProps {
  caption?: string;
  head?: string;
  links: HLinkTableItem[];
}

export const HLinkTable = tsx.component({
  name: 'HTimeTable',
  functional: true,

  props: {
    caption: String,
    head: String,
    links: {
      type: Array as PropType<HLinkTableItem[]>,
      required: true,
    },
  },

  render(h, { data, props }) {
    const { caption, head, links } = props;

    return (
      <table
        {...mergeVNodeData(data, {
          staticClass: 'h-link-table',
        })}>
        {!!caption && <caption>{caption}</caption>}
        <thead>
          <tr>
            <th colspan={2}>{head}</th>
          </tr>
        </thead>
        <tbody>
          {links.map((item, itemIndex) => (
            <tr key={itemIndex}>
              <td staticClass="h-link-table__text">{item.text}</td>
              <td staticClass="h-link-table__link">
                {!!item.url && (
                  <HBtn
                    staticClass="h-link-table__link__btn"
                    size="lg"
                    href={item.url}
                    target="_blank"
                    color="plain">
                    {item.label}
                  </HBtn>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  },
});
