import { HotelOption } from './hotel-option';
import { GeneralOption, Hyperlink } from './general';
import { Notification } from './notification';
import { MediaTransformation } from './media';

/**
 * カテゴリ
 */
export enum RestaurantCategory {
  /**
   * レストラン
   */
  Restaurant = '1',

  /**
   * カフェ / ラウンジ
   */
  CafeAndLounge = '2',
}

export interface RestaurantCategoryOption
  extends GeneralOption<RestaurantCategory> {}

/**
 * 提供時間
 */
export enum RestaurantServiceTime {
  /**
   * 朝
   */
  Morning = '1',

  /**
   * 昼
   */
  Noon = '2',

  /**
   * 夜
   */
  Night = '3',
}

export interface RestaurantServiceTimeOption
  extends GeneralOption<RestaurantServiceTime> {
  from: string;
  to: string;
}

/**
 * 提供スタイル
 */
export enum RestaurantStyle {
  /**
   * コース料理
   */
  Course = '1',

  /**
   * アラカルト
   */
  Alacarte = '2',

  /**
   * ビュッフェ料理
   */
  Buffet = '3',
}

/**
 * 食事処レイアウトの種別のリスト
 *
 * @see {@link HotelMealRestaurantLayoutType}
 */
export const HOTEL_MEAL_RESTAURANT_LAYOUT_TYPES = ['main', 'sub'] as const;

/**
 * 食事処レイアウトの種別
 *
 * @see {@link HOTEL_MEAL_RESTAURANT_LAYOUT_TYPES}
 */
export type RestaurantLayoutType = typeof HOTEL_MEAL_RESTAURANT_LAYOUT_TYPES[number];

export interface RestaurantStyleOption extends GeneralOption<RestaurantStyle> {}

export interface CuisineSpecOption extends GeneralOption {}

/**
 * 食事共通設定
 */
export interface RestaurantCommons {
  serviceTimeOptions: RestaurantServiceTimeOption[];
  categoryOptions: RestaurantCategoryOption[];
  styleOptions: RestaurantStyleOption[];
  specOptions: CuisineSpecOption[];
}

// "available" → 可
// "unavailable" → 不可
export const RestaurantAvailableTypes = ['available', 'unavailable'] as const;

export type RestaurantAvailableType = typeof RestaurantAvailableTypes[number];

export interface RestaurantAvailableInfo {
  avairable: RestaurantAvailableType;
  supplement: string | null;
}

/**
 * レストランの画像1つ分の情報
 */
export interface RestaurantImage {
  /** リゾナーレ実装前のメイン画像。今後使わなくなるが旧データのサポートして参照はする */
  main?: string;

  /** 700x467 */
  'md_4/3'?: string;

  /** 780x780 */
  'lg_1/1'?: string;
}

/**
 * 食事処
 */
export interface Restaurant extends HotelOption {
  /** エンティティID */
  _id: string;

  /** CMSでインクリメントされている連番ID */
  id: string;

  /**
   * 食事処名称を補足するキャプション
   *
   * @example `ジェラッテリア＆カフェ｜ピーマン通り`
   */
  subCaption?: string | null;

  /**
   * 食事処名称の冠飾り表記
   *
   * @example `Main Dining`
   */
  nameDecoration?: string | null;

  /**
   * 食事処名称の振り仮名
   *
   * @example `オットセッテ`
   */
  ruby?: string | null;

  /**
   * キャッチコピー
   *
   * @example `大地の贈り物が彩るイタリア料理`
   */
  catchCopy?: string | null;

  /**
   * メインビジュアル
   *
   * * OGP画像も兼ねる
   *
   * ### サイズ
   * - `"wide"` 2048x1156
   * - `"narrow"` 390x585
   * - `"ogp"` 1200x630
   *
   * @see {@link HOTEL_MEAL_RESTAURANT_MAIN_VISUAL_MEDIA_SPEC}
   */
  mainVisual: {
    /** 2048x1156 */
    wide: MediaTransformation | null;
    /** 390x585 */
    narrow: MediaTransformation | null;
    /** 1200x630 */
    ogp: MediaTransformation | null;
  };

  category: RestaurantCategory;
  serviceTimes: RestaurantServiceTime[];
  styles: RestaurantStyle[];
  childRestrictions: RestaurantAvailableInfo;
  petsAllowed: RestaurantAvailableInfo;
  averageBudget: string | null;
  reservationRequired: string | null;
  images: RestaurantImage[];
  description: string | null;
  notifications: Notification[];
  notice: string | null;

  /**
   * 予約用リンク
   *
   * @example `夕食時間を予約する`
   */
  reservationLink?: Hyperlink;

  /**
   * 専用の詳細ページがあるか
   */
  hasPage?: boolean;

  /**
   * メインの食事処かサブの食事処か
   */
  layoutType: RestaurantLayoutType;
}
