import { isAvailableLanguage, AvailableLanguage } from '~/schemes';

/** 5言語以外がブラウザ言語で設定された場合のデフォルトの言語 */
const DEFAULT_GLOBAL_LANGUAGE = 'en';

// bcp47の参考: https://so-zou.jp/web-app/tech/data/code/language.htm
/** 簡体字に対応するbcp47を定数アサーション定義 */
const simplifiedChineseCodes = ['zh-cn', 'zh-sg', 'zh-hans', 'zh'] as const;

/** simplifiedChineseCodesのユニオン型 */
type SimplifiedChinese = typeof simplifiedChineseCodes[number];

/** 繁体字に対応するbcp47を定数アサーション定義 */
const traditionalChineseCodes = ['zh_hant', 'zh_tw', 'zh_hk', 'zh_mo'] as const;

/** traditionalChineseCodesのユニオン型 */
type TraditionalChinese = typeof traditionalChineseCodes[number];

/**
 * 言語コードを任意の形に整形する
 * fullFormatがtrueに設定されていた場合、中国語がzh_cnやzh_twの表現で返される。
 * zhだけ取得して何某かの処理をしているところがあるかも知れないのでこの表現にしたが、
 * 不要であれば全てfullFormatの形式で返してもいいと思っている。
 * */
export function roundLanguage(
  language: string | null | undefined,
  fullFormat: boolean = false,
): string {
  if (!language) {
    return '';
  }

  if (fullFormat) {
    return language.toLocaleLowerCase().replace(/-/g, '_');
  }

  return language.split(',')[0].toLocaleLowerCase().substring(0, 2);
}

/**
 * GFの言語idに正規化する
 * @return {AvailableLanguage}
 */
export function normalizedGFLanguage(language: string): AvailableLanguage {
  language = roundLanguage(language, true);

  if (!isAvailableLanguage(language)) {
    return DEFAULT_GLOBAL_LANGUAGE;
  }

  return language as AvailableLanguage;
}

/**
 * navigator.languageで取得した言語コードをGFの言語IDに正規化し、リダイレクト先の言語IDを返す
 * @comment normalizedGFLanguageとの違いは、bcp47を細かくサポートしているかどうか
 */
export function normalizedGFRedirectLanguage(
  language: string,
): AvailableLanguage {
  // ここで-を_に変換している
  language = roundLanguage(language, true);

  if (language.startsWith('ja')) {
    return 'ja';
  }
  // 韓国語の正確な判定
  else if (language === 'ko' || language.startsWith('ko_')) {
    return 'ko';
  }
  // 繁体字の判定
  else if (isTraditionalChinese(language)) {
    return 'zh_tw';
  }
  // 簡体字の判定
  else if (isSimplifiedChinese(language)) {
    return 'zh_cn';
  }
  // デフォルトは英語に設定
  return DEFAULT_GLOBAL_LANGUAGE;
}

/** 引数languageがsimplifiedChineseCodesに含まれている言語コードに一致するものが存在すればtrueを返す */
function isSimplifiedChinese(language: string): language is SimplifiedChinese {
  return (simplifiedChineseCodes as readonly string[]).includes(language);
}

/** 引数languageがtraditionalChineseCodesに含まれている言語コードに一致するものが存在すればtrueを返す */
function isTraditionalChinese(
  language: string,
): language is TraditionalChinese {
  return (traditionalChineseCodes as readonly string[]).includes(language);
}
