import './HAttractiveIntroduction.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, AttractiveIntroductionModule } from '~/schemes';
import { HIconLink } from '~/components/HIcon';
import { mergeVNodeData } from '~/helpers';

export const HAttractiveIntroduction = tsx.component({
  name: 'HAttractiveIntroduction',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<
          AttractiveIntroductionModule['properties'],
          AvailableLanguage
        >
      >,
      required: true,
    },

    /**
     * コンテナクラスの付与を無効にする場合に指定する
     */
    disableContainer: Boolean,
  },

  render(h, { props, parent, data }): VNode {
    const { media, title, description, link } = props.value;
    const wideImageURL =
      media && media.derived && media.derived.wide && media.derived.wide.url;
    const narrowImageURL =
      media &&
      media.derived &&
      media.derived.narrow &&
      media.derived.narrow.url;

    const targetValue = link && link.blank ? '_blank' : '_self';

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'v-attractive-introduction-module',
          class: [!props.disableContainer && 'h-container h-container--sm'],
        })}>
        <div class="v-attractive-introduction-module__inner">
          {!!media && (
            <div class="v-attractive-introduction-module__media">
              <div
                class="v-attractive-introduction-module__media__container"
                style={{
                  '--sp-image': `url(${narrowImageURL})`,
                  '--pc-image': `url(${wideImageURL})`,
                }}>
                {/* 画像をクリッカブルにしている箇所 */}
                {!!(link && link.url) && (
                  <a
                    class="v-attractive-introduction-module__media__container__link"
                    href={link.url}
                    target={targetValue}></a>
                )}
              </div>
            </div>
          )}
          <div class="v-attractive-introduction-module__body">
            {/* タイトル */}
            {!!title && (
              <h3 class="v-attractive-introduction-module__row v-attractive-introduction-module__title">
                {title}
              </h3>
            )}
            {/* 紹介文 */}
            {!!description && (
              <div
                class="v-attractive-introduction-module__row v-attractive-introduction-module__description"
                v-wysiwyg={description}></div>
            )}
            {/* URL */}
            {!!(link && link.url) && (
              <HIconLink
                class="v-attractive-introduction-module__row v-attractive-introduction-module__link"
                icon="arrow-right"
                href={link.url}
                target={targetValue}
                internal={true}>
                {parent.$t('label.details')}
              </HIconLink>
            )}
          </div>
        </div>
      </div>
    );
  },
});
