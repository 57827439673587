import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { VacancySearchConditions, HotelUseSearch } from '~/schemes';

export interface VacancySearchConditionsMixin {
  vacancySearchConditions: VacancySearchConditions;
  useSearch?: Partial<HotelUseSearch>;
}

@Component({
  name: 'vacancy-search-conditions-mixin',
})
// eslint-disable-next-line no-redeclare
export class VacancySearchConditionsMixin extends Vue {
  get computedUseSearch() {
    return {
      adult: true,
      underTwelve: true,
      underSeven: true,
      underFour: true,
      ...this.useSearch,
    };
  }

  get useChildren() {
    const { computedUseSearch: use } = this;
    return use.underTwelve || use.underSeven || use.underFour;
  }

  get childrenCount(): number {
    const { vacancySearchConditions: conditions } = this;
    return (
      conditions.underTwelve + conditions.underSeven + conditions.underFour
    );
  }

  get conditionWords(): string[] {
    const {
      vacancySearchConditions: conditions,
      useChildren,
      childrenCount,
    } = this;
    const { adult } = conditions;
    let baseString = this.$tc('value.numOfAdults', adult);
    if (useChildren && childrenCount > 0) {
      baseString += ` / ${this.$tc('value.numOfChildren', childrenCount)}`;
    }
    const words: string[] = [baseString];
    return words;
  }
}
