import Vue, { VNodeData } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { mergeVNodeData } from '~/helpers';
import { Hyperlink } from '~/schemes';

export const CLICKABLE_TAGS = ['a', 'button', 'router-link', 'nuxt-link'];

export interface RoutableMixinProps {
  /** タグ名 */
  tag?: string;
  /** 非活性化 */
  disabled?: boolean;
  /** 読み込み中 */
  loading?: boolean;
  /** @see https://github.com/vuejs/vue-router */
  append?: boolean;
  /** @see https://github.com/vuejs/vue-router */
  exact?: boolean;
  /** @see https://github.com/vuejs/vue-router */
  replace?: boolean;
  /** @see https://github.com/vuejs/vue-router */
  activeClass?: string;
  /** @see https://github.com/vuejs/vue-router */
  exactActiveClass?: string;
  /** @see https://github.com/vuejs/vue-router */
  event?: string;
  /** @see https://github.com/vuejs/vue-router */
  to?: RawLocation;
  /** @see https://developer.mozilla.org/ja/docs/Web/HTML/Element/a */
  href?: string;
  /** @see https://developer.mozilla.org/ja/docs/Web/HTML/Element/a */
  target?: string;
  /** @see https://developer.mozilla.org/ja/docs/Web/HTML/Element/a */
  rel?: string;
  /** @see https://developer.mozilla.org/ja/docs/Web/HTML/Element/a */
  type?: string;
  /** @see https://developer.mozilla.org/ja/docs/Web/HTML/Element/button */
  value?: any;
  /** タブインデックス */
  tabindex?: string | number;
  /** ハイパーリンク設定 */
  link?: Hyperlink;
}

export interface RoutableMixinEmits {
  onBeforeEmitClick: MouseEvent;
  onClick: MouseEvent;
}

@Component({
  name: 'routable-mixin',
})
export class RoutableMixin extends Vue implements RoutableMixinProps {
  @Prop({ type: String, default: 'button' }) readonly tag!: string;
  @Prop({ type: Boolean }) readonly disabled!: boolean;
  @Prop({ type: Boolean }) readonly loading!: boolean;
  @Prop({ type: Boolean }) readonly append!: boolean;
  @Prop({ type: Boolean }) readonly exact!: boolean;
  @Prop({ type: Boolean }) readonly replace!: boolean;
  @Prop({ type: String }) readonly activeClass?: string;
  @Prop({ type: String }) readonly exactActiveClass?: string;
  @Prop({ type: String }) readonly event?: string;
  @Prop({ type: [String, Object] }) readonly to?: RawLocation;
  @Prop({ type: String }) readonly href?: string;
  @Prop({ type: String }) readonly target?: string;
  @Prop({ type: String }) readonly rel?: string;
  @Prop({ type: String }) readonly type?: string;
  @Prop() readonly value?: any;
  @Prop({ type: [String, Number] }) readonly tabindex?: string | number;
  @Prop(Object) readonly link?: Hyperlink;

  get computedHref() {
    const { href, link } = this;
    if (link) return link.url;
    return href;
  }

  get computedTarget() {
    const { target, link } = this;
    if (link) return link.blank ? '_blank' : undefined;
    return target;
  }

  get computedTag(): string {
    const { to, computedHref: href } = this;
    let { tag } = this;
    if (to) {
      tag = 'nuxt-link';
    } else if (href || !tag) {
      tag = 'a';
    }
    return tag;
  }

  get isDisabled() {
    return this.disabled;
  }

  get isLoading() {
    return this.loading;
  }

  get needClickBlock() {
    return this.isDisabled || this.isLoading;
  }

  get isClickableTag(): boolean {
    return CLICKABLE_TAGS.includes(this.computedTag);
  }

  protected generateRouteLink(
    baseVNodeData?: VNodeData,
  ): { tag: string; data: VNodeData } {
    const {
      computedTag: tag,
      $listeners,
      isDisabled: disabled,
      activeClass,
      exactActiveClass = activeClass,
      to,
      exact,
      append,
      replace,
      computedHref: href,
      computedTarget: target,
      type,
      tabindex,
      needClickBlock,
    } = this;

    const data: VNodeData = {
      attrs: {
        disabled,
        tabindex: needClickBlock ? '-1' : tabindex,
      },
      [to ? 'nativeOn' : 'on']: {
        ...$listeners,
        click: (e: MouseEvent) => {
          if (needClickBlock) {
            e.preventDefault();
            return;
          }
          this.$emit('beforeEmitClick', e);
          if (!e.defaultPrevented) {
            this.$emit('click', e);
          }
        },
      },
    };

    if (to) {
      data.props = {
        ...data.props,
        to,
        exact,
        activeClass,
        exactActiveClass,
        append,
        replace,
        event: this.event,
      };
    } else {
      if (tag === 'a' && href) {
        data.attrs!.href = href;
      }

      if (tag === 'button') {
        data.attrs!.type = type;
        data.attrs!.value = this.value;
      }
    }

    if (target) {
      data.attrs!.target = target;
      const { rel } = this;
      data.attrs!.rel = rel || target === '_blank' ? 'noopener' : undefined;
    }

    return {
      tag,
      data: baseVNodeData ? mergeVNodeData(baseVNodeData, data) : data,
    };
  }
}
