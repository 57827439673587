import Vue from 'vue';
import { Plugin, Context } from '@nuxt/types';
import { AccountProfile, AccountLoginRequest } from '~/schemes';

declare module 'vue/types/vue' {
  export interface Vue {
    $account: AccountService;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $account: AccountService;
  }
}

export class AccountService {
  ctx: Context;

  /**
   * 一度でもアカウント情報を読み込み済みの場合true
   *
   * これがtrueでない時は、meがnullでも本当はログインしている時がある
   */
  get initialized() {
    return this.ctx.store.state.account.initialized;
  }

  get me(): AccountProfile | null {
    return this.ctx.store.state.account.me;
  }

  get loggedIn(): boolean {
    return this.me != null;
  }

  get serviceUrl() {
    return `/${this.ctx.$language.info.ygetsKey}/account/service`;
  }

  constructor(ctx: Context) {
    this.ctx = ctx;

    // ルートチェンジとか、インターバルで状態のリフレッシュをした方がいいだろう
    // if (process.browser) {
    //   console.log(ctx.app.router);
    // }
  }

  async load() {
    const me = await this.ctx.$api.$get<AccountProfile>(`/member/profile`);
    this.ctx.store.commit('account/SET_ME', me);
  }

  async login(params: AccountLoginRequest) {
    const me = await this.ctx.$api.$post<AccountProfile>(
      `/member/login`,
      params,
    );
    this.ctx.store.commit('account/SET_ME', me);
    return me;
  }

  async logout() {
    await this.ctx.$api.post(`/member/logout`);
    this.ctx.store.commit('account/SET_ME', null);
  }

  openServiceUrl() {
    return window.open(this.serviceUrl, '_blank');
  }
}

const plugin: Plugin = (ctx, inject) => {
  const account = new AccountService(ctx);
  ctx.$account = account;
  inject('account', account);
};

export default plugin;
