import './h-progress-spinner.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component } from 'vue-property-decorator';

export interface HProgressSpinnerProps {}

export interface HProgressSpinnerEmits {}

export interface HProgressSpinnerScopedSlots {}

@Component<HProgressSpinnerRef>({
  name: 'h-progress-spinner',

  render(h) {
    const children: VNode[] = [];
    for (let i = 0; i < 12; i++) {
      children.push(
        h('span', {
          staticClass:
            'h-progress-spinner__path h-progress-spinner__path--' + (i + 1),
        }),
      );
    }
    return h(
      'span',
      {
        staticClass: 'h-progress-spinner',
      },
      children,
    );
  },
})
export class HProgressSpinnerRef extends Vue implements HProgressSpinnerProps {}

export const HProgressSpinner = tsx
  .ofType<
    HProgressSpinnerProps,
    HProgressSpinnerEmits,
    HProgressSpinnerScopedSlots
  >()
  .convert(HProgressSpinnerRef);
