import './HProfile.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, ProfileModule } from '~/schemes';
import { HImg } from '~/components/HImg';

export const HProfile = tsx.component({
  name: 'HProfile',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<ProfileModule['properties'], AvailableLanguage>
      >,
      required: true,
    },
  },

  render(h, { props }): VNode {
    const { image, name, title, description } = props.value;
    const imageURL = image && image.derived.main && image.derived.main.url;

    return (
      <div class="v-profile-module">
        <div class="v-profile-module-header">
          {imageURL && (
            <HImg
              staticClass="v-profile-module-header__image"
              src={imageURL}
              width={120}
              height={120}
              corner
            />
          )}
          <div class="v-profile-module-header-info">
            {/* 名前 */}
            <p class="v-profile-module-header-info__name">{name}</p>
            {/* 肩書き */}
            {title && (
              <p class="v-profile-module-header-info__title">{title}</p>
            )}
          </div>
        </div>
        {/* 説明 */}
        <div
          class="v-profile-module__description"
          domPropsInnerHTML={description}></div>
      </div>
    );
  },
});
