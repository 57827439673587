import './HCardList.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { HCard, HCardProps } from './HCard';
import { mergeVNodeData } from '~/helpers';

const LIST_TAGS = ['ul', 'ol'];

export const HCardList = tsx.component({
  name: 'HCardList',
  functional: true,
  props: {
    tag: {
      type: String,
    },
    items: {
      type: Array as PropType<HCardProps[]>,
      required: true,
    },
    pointerSupport: Boolean,
    onClick: (undefined as unknown) as PropType<(ev: MouseEvent) => any>,
  },

  render(h, { data, props, scopedSlots, listeners }) {
    const { tag = 'ul', items, pointerSupport } = props;
    const childTag = LIST_TAGS.includes(tag) ? 'li' : 'div';
    const { item: itemSlot } = scopedSlots;

    return h(
      'div',
      mergeVNodeData(data, {
        staticClass: 'h-card-list',
        class: {
          'h-card-list--pointer-support': pointerSupport,
        },
        on:
          pointerSupport && listeners.click
            ? {
                click: listeners.click,
              }
            : undefined,
      }),
      [
        h(
          tag,
          {
            staticClass: 'h-card-list__list',
          },
          items.map((item, itemIndex) => {
            return (
              <HCard
                key={itemIndex}
                staticClass="h-card-list__item"
                props={{
                  ...item,
                  tag: childTag,
                }}
                tag={childTag}
                on={
                  pointerSupport
                    ? {
                        click: (ev: MouseEvent) => {
                          ev.stopPropagation();
                        },
                      }
                    : undefined
                }>
                {itemSlot ? itemSlot(item) : undefined}
              </HCard>
            );
          }),
        ),
      ],
    );
  },
});
