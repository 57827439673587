import './HOption.scss';

import * as tsx from 'vue-tsx-support';
import { Component, Mixins } from 'vue-property-decorator';
import {
  HFormChoice,
  HFormChoiceProps,
  HFormChoiceEmits,
  HFormChoiceScopedSlots,
} from './mixins/HFormChoice';

export interface HOptionProps extends HFormChoiceProps {}

export interface HOptionEmits extends HFormChoiceEmits {}

export interface HOptionScopedSlots extends HFormChoiceScopedSlots {}

@Component<HOptionRef>({
  name: 'HOption',
  created() {
    if ((this.parentFormNode as any).value === this.value) {
      this.choiced = true;
    }
  },
  render() {
    return (
      <div
        staticClass="h-option"
        class={this.classes}
        tabindex={this.computedTabIndex || 0}
        onClick={(ev) => {
          // @todo: ちゃんと実装する
          (this.parentFormNode as any).selectOption(this);
        }}>
        <span staticClass="h-option__content">{this.$slots.default}</span>
      </div>
    );
  },
})
export class HOptionRef extends Mixins(HFormChoice) implements HOptionProps {
  get classes() {
    return {
      'h-option--selected': this.choiced,
    };
  }
}

export const HOption = tsx
  .ofType<HOptionProps, HOptionEmits, HOptionScopedSlots>()
  .convert(HOptionRef);
