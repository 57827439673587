import { Context } from '@nuxt/types';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { AppApi } from '../';

export interface RouteCacheInfo {
  /**
   * キャッシュのキー
   * これはリソース毎にユニークになるようにしてください
   */
  key: string;

  /**
   * キャッシュの有効期限（sec）
   * ローカルストレージ等ではなく、単純に変数（メモリ）キャッシュ
   */
  maxAge?: number;
}

export interface RouteCache<T = any> extends RouteCacheInfo {
  data: T;
  created: string;
}

export interface RouteCacheSetting {
  /**
   * インスタンスで最大何件のキャッシュを保持するか
   */
  max: number;
}

interface Resolver {
  resolve: Function;
  reject: Function;
}

type ResolverExecutor<T = any> = () => Promise<T>;

const ABSOLUTE_PATH_CHECK_RE = /^https?:\/\//;

function isAbsolutePath(path: string): boolean {
  return ABSOLUTE_PATH_CHECK_RE.test(path);
}

export default class AbstractRoute {
  readonly adapter: AppApi;
  readonly context: Context;
  readonly name: string = '';
  private resolversMap: { [key: string]: Resolver[] } = {};

  get caches(): RouteCache[] {
    return this.context.store.state.api.caches;
  }

  get basePath() {
    return '/' + this.name;
  }

  get currentLang() {
    return this.context.store.state.language.current;
  }

  constructor(adapter: AppApi, context: Context) {
    this.adapter = adapter;
    this.context = context;
  }

  isAxiosError(source: any): source is AxiosError {
    return axios.isAxiosError(source);
  }

  getErrorStatus(source: any): number | undefined {
    if (this.isAxiosError(source) && source.response) {
      return source.response.status;
    }
  }

  createPath(path: string) {
    if (isAbsolutePath(path)) {
      return path;
    }
    let created = this.basePath + '/' + path;
    const tmp = created.split('?');
    created = tmp[0].replace(/[/]+/g, '/');
    const query = tmp[1] ? '?' + tmp[1] : '';
    return created + query;
  }

  get<T = any, R = AxiosResponse<T>>(
    path: string,
    config?: AxiosRequestConfig,
  ) {
    return this.adapter.get<T, R>(this.createPath(path), config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    path: string,
    config?: AxiosRequestConfig,
  ) {
    return this.adapter.delete<T, R>(this.createPath(path), config);
  }

  head<T = any, R = AxiosResponse<T>>(
    path: string,
    config?: AxiosRequestConfig,
  ) {
    return this.adapter.head<T, R>(this.createPath(path), config);
  }

  post<T = any, R = AxiosResponse<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig,
  ) {
    return this.adapter.post<T, R>(this.createPath(path), data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig,
  ) {
    return this.adapter.put<T, R>(this.createPath(path), data, config);
  }

  patch<T = any, R = AxiosResponse<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig,
  ) {
    return this.adapter.patch<T, R>(this.createPath(path), data, config);
  }

  $get<T = any>(path: string, config?: AxiosRequestConfig) {
    return this.adapter.$get<T>(this.createPath(path), config);
  }

  $delete<T = any>(path: string, config?: AxiosRequestConfig) {
    return this.adapter.$delete<T>(this.createPath(path), config);
  }

  $head<T = any>(path: string, config?: AxiosRequestConfig) {
    return this.adapter.$head<T>(this.createPath(path), config);
  }

  $post<T = any>(path: string, data?: any, config?: AxiosRequestConfig) {
    return this.adapter.$post<T>(this.createPath(path), data, config);
  }

  $put<T = any>(path: string, data?: any, config?: AxiosRequestConfig) {
    return this.adapter.$put<T>(this.createPath(path), data, config);
  }

  $patch<T = any>(path: string, data?: any, config?: AxiosRequestConfig) {
    return this.adapter.$patch<T>(this.createPath(path), data, config);
  }

  private setCache<T = any>(cache: RouteCache<T>): void {
    return this.context.store.commit('api/SET_CACHE', cache);
  }

  private getCache<T = any>(key: string): T | undefined {
    const cache = this.caches.find((c) => c.key === key);
    if (!cache) return undefined;
    const { data, maxAge, created } = cache;
    if (maxAge !== undefined) {
      const createdTime = parseInt(created, 10);
      const diffSec = (Date.now() - createdTime) / 1000;
      if (diffSec > maxAge) {
        this.deleteCache(key);
        return undefined;
      }
    }
    return data;
  }

  private deleteCache(cache: string | RouteCache) {
    return this.context.store.commit('api/DELETE_CACHE', cache);
  }

  /**
   * 非同期処理における重複リクエストを解決し、必要に応じてキャッシュ機能を提供します
   * @param id リクエスト毎に一意となるID
   * @param executor 実際の非同期処理
   * @param cacheInfo キャッシュ設定 or キャッシュのキー
   */
  protected asyncResolver<T = any>(
    id: string,
    executor: ResolverExecutor<T>,
    cacheInfo?: string | RouteCacheInfo,
  ): Promise<T> {
    if (typeof cacheInfo === 'string') {
      cacheInfo = {
        key: cacheInfo,
      };
    }
    if (cacheInfo) {
      const cache = this.getCache<T>(cacheInfo.key);
      if (cache) return Promise.resolve(cache);
    }

    let isInitial = false;
    const promise = new Promise<T>((resolve, reject) => {
      let resolvers = this.resolversMap[id];
      if (!resolvers) {
        isInitial = true;
        resolvers = [];
        this.resolversMap[id] = resolvers;
      }
      resolvers.push({ resolve, reject });
    });

    if (isInitial) {
      const resolve = (type: 'resolve' | 'reject', payload?: any) => {
        const resolvers = this.resolversMap[id];
        if (resolvers) {
          resolvers.forEach((resolver) => {
            resolver[type](payload);
          });
          delete this.resolversMap[id];
        }
      };

      executor()
        .then((payload) => {
          if (cacheInfo) {
            const cache: RouteCache = {
              ...(cacheInfo as RouteCacheInfo),
              data: payload,
              created: String(Date.now()),
            };
            this.setCache(cache);
          }
          resolve('resolve', payload);
        })
        .catch((err) => {
          resolve('reject', err);
        });
    }
    return promise;
  }
}
