import './HAccordion.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, AccordionModule } from '~/schemes';
import { TypedHExpandButton } from '~/components/HExpandButton';

/** アコーディオンモジュール */
export const HAccordion = tsx.component({
  name: 'HAccordion',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<AccordionModule['properties'], AvailableLanguage>
      >,
      required: true,
    },
  },

  render(h, { props }): VNode {
    return (
      <div class={['h-container', `v-accordion-module`]}>
        <TypedHExpandButton
          label={props.value.label}
          class="v-accordion-module__label">
          <div
            v-Wysiwyg={props.value.text}
            class="v-accordion-module__text"></div>
        </TypedHExpandButton>
      </div>
    );
  },
});
