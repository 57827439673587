import './HCuisineColumn.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HCuisineSpecs } from './HCuisineSpecs';
import { CuisineColumn, RestaurantCommons } from '~/schemes';
import { HSimpleGallery, HExpandButton } from '~/components';

export interface HCuisineColumnProps {
  data: CuisineColumn;
  commons: RestaurantCommons;
}

export interface HCuisineColumnEmits {}

export interface HCuisineColumnScopedSlots {}

@Component<HCuisineColumnRef>({
  name: 'HCuisineColumn',

  render() {
    const { useVerticalWriting, specs, isHoshinoya } = this;
    const gallery = (
      <HSimpleGallery
        staticClass="h-cuisine-column__gallery"
        items={this.images}
      />
    );

    const specsDisplay =
      specs && specs.length > 0 ? (
        <HCuisineSpecs
          data={this.data}
          commons={this.commons}
          staticClass="h-cuisine-column__specs"
        />
      ) : null;

    return (
      <div
        staticClass="h-cuisine-column"
        class={{
          'h-cuisine-column--vertial': useVerticalWriting,
        }}>
        <div staticClass="h-cuisine-column__inner-wrapper">
          {/** 縦書きレイアウトじゃない時はギャラリーはPCで回り込ませたい */}
          {!useVerticalWriting && gallery}
          <div staticClass="h-cuisine-column__inner">
            <h3 staticClass="h-cuisine-column__title">
              <span staticClass="h-cuisine-column__title__text">
                {this.title}
              </span>
            </h3>
            <div staticClass="h-cuisine-column__body">
              {/** 縦書きレイアウトの時はギャラリーはタイトルや文書と一緒の列にいたい */}
              {useVerticalWriting && gallery}
              <div
                staticClass="h-cuisine-column__description h-theme-lead"
                v-wysiwyg={this.description}
              />
              {specsDisplay &&
                (isHoshinoya ? (
                  <HExpandButton staticClass="h-cuisine-content__expand-button">
                    {specsDisplay}
                  </HExpandButton>
                ) : (
                  specsDisplay
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  },
})
export class HCuisineColumnRef extends Vue implements HCuisineColumnProps {
  @Prop({ type: Object, required: true }) readonly commons!: RestaurantCommons;
  @Prop({ type: Object, required: true }) readonly data!: CuisineColumn;

  get id() {
    return this.data.id;
  }

  get title() {
    return this.data.title;
  }

  get description() {
    return this.data.description;
  }

  get images() {
    return this.data.images.map((image) => {
      return {
        ...image,
        url: this.$res.img(image.url),
      };
    });
  }

  /**
   * 縦書き用レイアウトにするか
   */
  get useVerticalWriting() {
    return this.$theme.is('kai');
  }

  get specs() {
    return this.data.specs;
  }

  get isHoshinoya() {
    return this.$theme.is('hoshinoya');
  }
}

export const HCuisineColumn = tsx
  .ofType<HCuisineColumnProps, HCuisineColumnEmits, HCuisineColumnScopedSlots>()
  .convert(HCuisineColumnRef);
