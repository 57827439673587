import { AvailableLanguage } from './language';
import type {
  LocationMaps as CMSLocationMaps,
  LocationMapsBehavior as CMSLocationMapsBehavior,
  LocationMap as CMSLocationMap,
  LocationMapSpot as CMSLocationMapSpot,
} from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes/language';
export type {
  LocationMapsPosition,
  LocationMapParent,
} from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

/**
 * ロケーションマップと動作設定
 */
export interface LocationMaps
  extends ResolvedLanugageData<CMSLocationMaps, AvailableLanguage> {}

/**
 * ロケーションマップの動作設定
 */
export interface LocationMapsBehavior
  extends ResolvedLanugageData<CMSLocationMapsBehavior, AvailableLanguage> {}

/**
 * ロケーションマップ
 */
export interface LocationMap
  extends ResolvedLanugageData<CMSLocationMap, AvailableLanguage> {}

/**
 * ロケーションマップのスポット
 *
 * - マップのマーカーになる
 */
export interface LocationMapSpot
  extends ResolvedLanugageData<CMSLocationMapSpot, AvailableLanguage> {}

/**
 * ロケーションマップの画像URLを抽出する
 *
 * @param map - マップオブジェクト
 * @returns マップ画像URL
 */
export function extractLocationMapImageURL(map: LocationMap) {
  const mapImageURL =
    map && map.image && map.image.derived.main && map.image.derived.main.url;
  return mapImageURL;
}
