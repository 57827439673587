import './HEmbed.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, EmbedModule } from '~/schemes';
import { HSyncFrame } from '~/components/HSyncFrame';

/** 埋め込みジュールのプロパティ */
export type EmbedModuleProperties = ResolvedLanugageData<
  EmbedModule['properties'],
  AvailableLanguage
>;
export interface HEmbedProps {
  value: EmbedModuleProperties;
}
export interface HEmbedEmits {}
export interface HEmbedScopedSlots {}

@Component<HEmbedRef>({
  name: 'HEmbed',

  render() {
    const { url, size } = this;

    return (
      <div class="v-embed-module">
        <HSyncFrame src={url} aspectRatio={size}></HSyncFrame>
      </div>
    );
  },
})
export class HEmbedRef extends Vue implements HEmbedProps {
  @Prop({ type: Object, required: true })
  readonly value!: EmbedModuleProperties;

  get url() {
    return this.value.url;
  }

  get size() {
    return this.value.size;
  }
}

export const HEmbed = tsx
  .ofType<HEmbedProps, HEmbedEmits, HEmbedScopedSlots>()
  .convert(HEmbedRef);
