import './HSimpleCarousel.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  HInfiniteCarousel,
  HInfiniteCarouselItem,
  HMediaOverlayCaption,
} from '~/components';

export interface HSimpleCarouselItem extends HInfiniteCarouselItem {}

export interface HSimpleCarouselProps {
  items: HSimpleCarouselItem[];
  paused?: boolean;
}

export interface HSimpleCarouselEmits {}

export interface HSimpleCarouselScopedSlots {}

@Component<HSimpleCarouselRef>({
  name: 'HSimpleCarousel',

  render() {
    const { items, paused } = this;
    return (
      <HInfiniteCarousel
        staticClass="h-simple-carousel"
        items={items}
        paused={paused}
        scopedSlots={{
          item: ({ item, carousel }) => {
            const firstImage = item.images[0];
            return (
              <div staticClass="h-simple-carousel__item">
                <div
                  staticClass="h-simple-carousel__item__img"
                  style={{
                    width:
                      carousel.getHeightRoundedSize(item.images[0].image)
                        .width + 'px',
                  }}
                  data-h-infinite-carousel-click={item.index}>
                  {firstImage.meta && firstImage.meta.caption && (
                    <HMediaOverlayCaption
                      modelValue={{
                        caption: firstImage.meta.caption,
                        position: 'left',
                      }}
                    />
                  )}
                  <div
                    staticClass="h-simple-carousel__item__img__node"
                    style={
                      carousel.isLoaded
                        ? {
                            backgroundImage: `url(${item.images[0].image})`,
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
            );
          },
        }}
      />
    );
  },
})
export class HSimpleCarouselRef extends Vue implements HSimpleCarouselProps {
  @Prop({ type: Array, required: true }) readonly items!: HSimpleCarouselItem[];
  @Prop({ type: Boolean }) readonly paused!: boolean;
}

export const HSimpleCarousel = tsx
  .ofType<
    HSimpleCarouselProps,
    HSimpleCarouselEmits,
    HSimpleCarouselScopedSlots
  >()
  .convert(HSimpleCarouselRef);
