import {
  KeyVisualModuleProperties,
  KeyVisualOptimizedProperties,
  MediaInfo,
} from './schemes';
import { ResponsiveBreakpoint, TextOrImageAsTextWithOption } from '~/schemes';

/**
 * 指定したメディア情報の「リストのリスト」の先頭から順に、1つでもデータが設定されているリストがあればそれをreturnする
 * リストのリスト
 * KVモジュールは、登録されているSPかPC用メディアのうち、片方が登録されていなければ登録されている方を利用するという要件があるので、それをサポートするためのヘルパ
 *
 * @TODO
 *   将来、動画登録をサポートするようになったら、このメソッド内の修正必要
 */
function choiceAnyMediaList(...listOfLists: MediaInfo[][]) {
  for (const list of listOfLists) {
    const firstMedia = list && list[0]; // ないとは思うけど、一応、listの存在自体もチェックしておく
    if (!firstMedia) continue;
    if (firstMedia.derived.main && firstMedia.derived.main.url) {
      // メイン画像があるかどうかまで念の為チェックする
      // @TODO 将来動画きたらこれ変わるはず
      return list;
    }
  }

  // 何もヒットしなければ先頭のリストをreturnする
  return listOfLists[0] || [];
}

/**
 * 指定したtitleの先頭から順に、imageとtextにデータが設定されているリストがあればそれをreturnする
 */

function choiceAnyTitle(...listOfLists: TextOrImageAsTextWithOption[]) {
  for (const list of listOfLists) {
    if (!list.image && !list.text) continue;
    return list;
  }
  // 何もヒットしなければ先頭のリストをreturnする
  return listOfLists[0];
}

/**
 * 指定のキービジュアルモジュールのプロパティからブレイクポイント別のデータを抽出する
 *
 * * メディアリスト (`media`) についてのみは、指定のブレイクポイントにデータがなかった場合はもう片方のブレイクポイントにデータを採用する（あれば）
 *
 * @param properties - キービジュアルモジュールのプロパティ
 * @param breakpoint - ブレイクポイント
 */
export function extractKeyVisualOptimizedProperties(
  properties: KeyVisualModuleProperties,
  breakpoint: ResponsiveBreakpoint,
): KeyVisualOptimizedProperties {
  const {
    isHeading,
    contentsAlign,
    title,
    titleColor,
    subTitle,
    media: _media = [],
    narrowAspectRatio,
    bgColorCssToken,
    alignSettings,
    flexibleContentsAlign,
  } = properties;

  /**
   * 指定のブレイクポイントと反対になるブレイクポイント
   */
  const alternateBreakpoint: ResponsiveBreakpoint =
    breakpoint === 'narrow' ? 'wide' : 'narrow';
  const media = choiceAnyMediaList(
    _media[breakpoint],
    _media[alternateBreakpoint],
  );

  const choicedTitle = choiceAnyTitle(
    title[breakpoint]!,
    title[alternateBreakpoint]!,
  );

  return {
    breakpoint,
    isHeading,
    contentsAlign,
    title: choicedTitle || {
      text: '',
      fontSize: 'md',
    },
    titleColor,
    subTitle: subTitle[breakpoint] || subTitle[alternateBreakpoint] || '',
    media,
    narrowAspectRatio: narrowAspectRatio || '1/1',
    bgColorCssToken,
    alignSettings,
    flexibleContentsAlign,
  };
}
