/* eslint-disable no-undef */
import { VNode, DirectiveOptions, VNodeDirective } from 'vue';
import {
  MouseOrTouchEvent,
  pointerActionsMap,
  PointerActions,
  getPointAndTypeByMouseOrTouch,
  isRightClickEvent,
  createAddEventListenerOptions,
  isTouchEvent,
} from '~/lib/pointer';

export type DragVNodeDirectiveValue =
  | DragDirectiveCallback
  | DragDirectiveValue
  | false
  | null;

export interface DragVNodeDirective extends VNodeDirective {
  value?: DragVNodeDirectiveValue;
}

export type DragDirectiveListenerOptions = boolean | AddEventListenerOptions;
export interface AllListenerOptions {
  start: DragDirectiveListenerOptions;
  move: DragDirectiveListenerOptions;
  end: DragDirectiveListenerOptions;
}

const isAllableListenerOptions = (
  source?: boolean | AddEventListenerOptions | Partial<AllListenerOptions>,
): source is Partial<AllListenerOptions> => {
  return (
    typeof source === 'object' &&
    !!((source as any).start || (source as any).move || (source as any).end)
  );
};

export type DragDirectiveCallback = (e: DragDirectivePayload) => any;
export interface DragDirectiveValue {
  onStart?: DragDirectiveCallback;
  onMove?: DragDirectiveCallback;
  onEnd?: DragDirectiveCallback;
  onDestroy?: Function;
  listenerOptions?: DragDirectiveListenerOptions | Partial<AllListenerOptions>;
  startCancelInterval?: number;
  guard?: (
    context: DragDirectiveContext,
    e: MouseOrTouchEvent,
  ) => boolean | void;
}

export interface DragDirectiveElement extends HTMLElement {
  __drag_context__: DragDirectiveContext;
}

export type DragDirectiveFunction = (
  el: DragDirectiveElement,
  binding: DragVNodeDirective,
  vnode: VNode,
  oldVnode: VNode,
) => void;

export interface DragDirectiveStates {
  startX: number;
  startY: number;
  lastX: number;
  lastY: number;
}

export type DragDirectiveEventType = 'start' | 'move' | 'end';

export interface DragDirectivePayload extends DragDirectiveStates {
  type: DragDirectiveEventType;
  target: DragDirectiveElement;
  originalEvent: MouseOrTouchEvent;
  isTouch: boolean;
  pageX: number;
  pageY: number;
  deltaX: number;
  deltaY: number;
  totalX: number;
  totalY: number;
}

export const valueSourceToValue = (
  source?: DragVNodeDirectiveValue,
): DragDirectiveValue | undefined => {
  if (!source) return;
  return typeof source === 'function' ? { onMove: source } : source;
};

export class DragDirectiveContext {
  el: DragDirectiveElement;
  private internalBindingValue?: DragDirectiveValue;
  private currentPointerActions?: PointerActions;
  private listenerOptions!: AllListenerOptions;
  private pointing: boolean = false;
  private startCancelTimerId: number | null = null;
  private startX: number = 0;
  private startY: number = 0;
  private lastX: number = 0;
  private lastY: number = 0;
  private _onStart?: (e: MouseOrTouchEvent) => void;
  private _onMove?: (e: MouseOrTouchEvent) => void;
  private _onEnd?: (e: MouseOrTouchEvent) => void;

  get bindingValue() {
    return this.internalBindingValue;
  }

  constructor(el: DragDirectiveElement, binding: DragVNodeDirective) {
    this.el = el;
    el.__drag_context__ = this;

    this.setBindingValue(binding.value);
    this.setupStartListener();
  }

  private setupStartListener() {
    const { el } = this;
    this._onStart = (e) => {
      this.onStart(e);
    };

    el.addEventListener(
      'touchstart',
      this._onStart,
      this.listenerOptions.start,
    );
    el.addEventListener('mousedown', this._onStart, this.listenerOptions.start);
  }

  setBindingValue(source?: DragVNodeDirectiveValue) {
    const bindingValue = valueSourceToValue(source);
    this.internalBindingValue = bindingValue;

    let listenerOptions = bindingValue
      ? bindingValue.listenerOptions
      : undefined;

    if (!isAllableListenerOptions(listenerOptions)) {
      listenerOptions = {
        start: listenerOptions,
        move: listenerOptions,
        end: listenerOptions,
      };
    }

    this.listenerOptions = {
      start: createAddEventListenerOptions(listenerOptions.start),
      move: createAddEventListenerOptions(listenerOptions.move),
      end: createAddEventListenerOptions(listenerOptions.end),
    };
  }

  private checkGuard(e: MouseOrTouchEvent) {
    const { bindingValue } = this;
    if (!bindingValue) return false;
    const { guard } = bindingValue;
    if (!guard) return true;
    return !guard || guard(this, e) !== false;
  }

  private clearStartCancelTimerId() {
    if (this.startCancelTimerId !== null) {
      clearTimeout(this.startCancelTimerId);
      this.startCancelTimerId = null;
    }
  }

  private onStart(e: MouseOrTouchEvent) {
    const { bindingValue } = this;

    if (
      !bindingValue ||
      this.pointing ||
      isRightClickEvent(e) ||
      this.startCancelTimerId !== null ||
      this.checkGuard(e) === false
    )
      return;

    if (bindingValue.startCancelInterval !== undefined) {
      this.startCancelTimerId = window.setTimeout(() => {
        this.clearStartCancelTimerId();
      }, bindingValue.startCancelInterval);
    }

    const { point, type } = getPointAndTypeByMouseOrTouch(e);
    const currentPointerActions = pointerActionsMap.get(type);
    if (this.currentPointerActions === currentPointerActions) {
      return;
    }

    this.currentPointerActions = currentPointerActions;
    this.pointing = true;
    const { pageX, pageY } = point;
    this.startX = pageX;
    this.startY = pageY;
    this.lastX = pageX;
    this.lastY = pageY;

    if (bindingValue.onStart) {
      const isTouch = isTouchEvent(e);
      const result = bindingValue.onStart({
        type: 'start',
        target: this.el,
        originalEvent: e,
        isTouch,
        pageX,
        pageY,
        startX: this.startX,
        startY: this.startY,
        lastX: this.lastX,
        lastY: this.lastY,
        deltaX: 0,
        deltaY: 0,
        totalX: 0,
        totalY: 0,
      });
      if (result === false || e.defaultPrevented) {
        this.removePointingListeners();
        this.pointing = false;
        return;
      }
    }

    this._onMove = (e) => {
      this.onMove(e);
    };

    this._onEnd = (e) => {
      this.onEnd(e);
    };

    document.addEventListener(
      currentPointerActions.move,
      this._onMove,
      this.listenerOptions.move,
    );

    document.addEventListener(
      currentPointerActions.end,
      this._onEnd,
      this.listenerOptions.end,
    );
  }

  private onMove(e: MouseOrTouchEvent) {
    const { bindingValue, pointing } = this;
    if (!bindingValue || !pointing) return;
    const { point } = getPointAndTypeByMouseOrTouch(e);
    const { pageX, pageY } = point;
    const { lastX, lastY } = this;
    this.lastX = pageX;
    this.lastY = pageY;

    if (bindingValue.onMove) {
      const isTouch = isTouchEvent(e);
      const { startX, startY } = this;
      const deltaX = pageX - lastX;
      const deltaY = pageY - lastY;
      const totalX = lastX - startX;
      const totalY = lastY - startY;
      bindingValue.onMove({
        type: 'move',
        target: this.el,
        originalEvent: e,
        isTouch,
        pageX,
        pageY,
        startX,
        startY,
        lastX: this.lastX,
        lastY: this.lastY,
        deltaX,
        deltaY,
        totalX,
        totalY,
      });
    }
  }

  private onEnd(e: MouseOrTouchEvent) {
    const { bindingValue, pointing } = this;
    if (!bindingValue || !pointing) return;

    this.removePointingListeners();
    this.pointing = false;
    if (bindingValue.onEnd) {
      const isTouch = isTouchEvent(e);
      const { startX, startY, lastX, lastY } = this;
      bindingValue.onEnd({
        type: 'end',
        target: this.el,
        originalEvent: e,
        isTouch,
        pageX: lastX,
        pageY: lastY,
        startX,
        startY,
        lastX,
        lastY,
        deltaX: 0,
        deltaY: 0,
        totalX: lastX - startX,
        totalY: lastY - startY,
      });
    }
  }

  private removePointingListeners() {
    const { currentPointerActions, _onMove, _onEnd } = this;
    if (currentPointerActions) {
      if (_onMove) {
        document.removeEventListener(
          currentPointerActions.move,
          _onMove,
          this.listenerOptions.move,
        );
        delete this._onMove;
      }

      if (_onEnd) {
        document.removeEventListener(
          currentPointerActions.end,
          _onEnd,
          this.listenerOptions.end,
        );
        delete this._onEnd;
      }

      delete this.currentPointerActions;
    }
  }

  private removeStartListener() {
    const { el, _onStart } = this;
    if (_onStart) {
      el.removeEventListener(
        'touchstart',
        _onStart,
        this.listenerOptions.start,
      );
      el.removeEventListener('mousedown', _onStart, this.listenerOptions.start);
      delete this._onStart;
    }
  }

  destroy() {
    const { el, bindingValue } = this;

    this.removeStartListener();
    this.removePointingListeners();
    this.clearStartCancelTimerId();
    delete (el as any).__drag_context__;
    bindingValue && bindingValue.onDestroy && bindingValue.onDestroy();
  }
}

const inserted: DragDirectiveFunction = (el, binding: DragVNodeDirective) => {
  // eslint-disable-next-line no-new
  new DragDirectiveContext(el, binding);
};

const unbind: DragDirectiveFunction = (el, binding: DragVNodeDirective) => {
  const context = el.__drag_context__;
  context && context.destroy();
};

const options = { inserted, unbind } as DirectiveOptions;

export default options;
