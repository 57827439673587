export interface ImageDimension {
  width: number;
  height: number;
}

export function loadImageSize(src: string): Promise<ImageDimension> {
  if (process.server) {
    return Promise.reject(
      new Error(
        `Images can only be loaded in a browser context. src at ${src}`,
      ),
    );
  }
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
    image.onerror = (err) => {
      reject(err);
    };
    image.src = src;
  });
}

export function imagePlaceholderURL(setting: {
  width?: number;
  height?: number;
  ext?: string;
  background?: string;
  color?: string;
  text?: string;
}) {
  const {
    width = 150,
    height = 150,
    ext = 'png',
    background,
    color,
    text,
  } = setting;
  const dirs: string[] = [
    'https://via.placeholder.com',
    `${width}x${height}.${ext}`,
  ];
  background && dirs.push(background);
  color && dirs.push(color);

  let url = dirs.join('/');
  if (text !== undefined) {
    url += '?text=' + text.replace(/\s/g, '+');
  }

  return url;
}
