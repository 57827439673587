import {
  MediaModuleDerivedSize,
  MediaModuleAspectRatio,
  MediaModuleDerivedName,
} from '~/schemes';

/**
 * 指定のサイズ＆アスペクト比に対応するメディアモジュールの自動変換生成サイズの名前を生成する
 * @param size - サイズ
 * @param aspectRatio - アスペクト比
 * @returns 自動変換生成サイズの名前
 */
export function createMediaModuleDerivedName<
  Size extends MediaModuleDerivedSize,
  AspectRatio extends MediaModuleAspectRatio
>(size: Size, aspectRatio: AspectRatio): MediaModuleDerivedName {
  return `${size}_${aspectRatio}` as any;
}
