import type { HBannerLinkRef } from './HBannerLink';
import { RoutableMixinProps, RoutableMixinEmits } from '~/mixins/routeable';
import { BannerLink, BannerLinkImageSize, AnyMediaSource } from '~/schemes';

export interface HBannerLinkProps extends RoutableMixinProps {
  /**
   * 画像src or 変換済みメディア
   *
   * * nullableな時は画像を表示しない
   */
  src?: AnyMediaSource | null;

  /**
   * タイトル
   */
  title?: string | null;

  /**
   * バナー装飾有無
   */
  excludeDecoration?: boolean;
}

export interface HBannerLinkEmits extends RoutableMixinEmits {}

export interface HBannerLinkScopedSlots {
  title: HBannerLinkRef;
}

/**
 * バナーリンクJSONをバナーリンクコンポーネントのプロパティに変換する
 *
 * @param bannerLink - バナーリンク
 * @param imageSize - 画像のサイズ
 */
export function toBannerLinkProps(
  bannerLink: BannerLink,
  imageSize: BannerLinkImageSize,
): HBannerLinkProps {
  const { title = '', image, link } = bannerLink;
  const derived = image && image.derived && image.derived[imageSize];

  const src: HBannerLinkProps['src'] = derived && derived.url;
  return {
    src,
    title,
    link,
  };
}
