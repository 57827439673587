import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HHtmlInjector } from '~/components/HHtmlInjector';

export interface HCustomHTMLProps {
  html?: string;
}

export interface HCustomHTMLEmits {}

export interface HCustomHTMLScopedSlots {}

/**
 * 特集ページのカスタムHTML表示用コンポーネント
 * 現在は既存コンポーネントのHHtmlInjectorを呼び出している
 * 将来的にカスタムHTML表示用の処理をする可能性があるため
 * MODULE_MAPPINGSの仕様に合わせ新規作成。
 * @see: ../../moduleMappings
 */
@Component<HCustomHTMLRef>({
  name: 'HCustomHTML',

  render(h) {
    // カスタムHTML入力値を正規化する
    return <HHtmlInjector html={this.$props.html} />;
  },
})
export class HCustomHTMLRef extends Vue implements HCustomHTMLProps {
  @Prop({ type: String }) readonly html!: string;
}

export const HCustomHTML = tsx
  .ofType<HCustomHTMLProps, HCustomHTMLEmits, HCustomHTMLScopedSlots>()
  .convert(HCustomHTMLRef);
