import './HHotelWithAttractionsList.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { HHotelWithAttractions } from './HHotelWithAttractions';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, HotelWithAttractionsListModule } from '~/schemes';
import { mergeVNodeData } from '~/helpers';

export const HHotelWithAttractionsList = tsx.component({
  name: 'HHotelWithAttractionsList',
  functional: true,
  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<
          HotelWithAttractionsListModule['properties'],
          AvailableLanguage
        >
      >,
      required: true,
    },
  },

  render(h, { props, parent, data }): VNode {
    const { size, children, denseAttractions } = props.value;
    if (!children.length) return undefined as any;

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'h-hotel-with-attractions-list h-container',
          class: [
            denseAttractions &&
              'h-hotel-with-attractions-list--dense-attractions',
            `h-hotel-with-attractions-list--${size}`,
            `h-sp-grid--${size}`,
          ],
        })}>
        <div staticClass="h-hotel-with-attractions-list__children">
          {children.map((child) => (
            <HHotelWithAttractions
              staticClass="h-hotel-with-attractions-list__child"
              value={child.properties}
              key={child.key}
            />
          ))}
        </div>
      </div>
    );
  },
});
