import AbstractRoute from './AbstractRoute';
import { CrossSearchHotelMaster } from '~/schemes';

export default class CrossSearchRoute extends AbstractRoute {
  /**
   * 横断検索マスタ取得
   * ja/enしかapi情報がないので、その多言語はenのapiを叩いている
   */
  all() {
    return this.currentLang === 'ja'
      ? this.getCrossSearch(this.currentLang)
      : this.getCrossSearch('en');
  }

  /** 横断検索マスタ取得API取得処理 */
  getCrossSearch(lang: string) {
    const key = `getCrossSearchInfo_${lang}`;
    return this.asyncResolver<CrossSearchHotelMaster | null>(
      key,
      () => {
        return this._getCrossSearchInfo(lang);
      },
      {
        key,
      },
    );
  }

  /** 横断検索マスタ取得APIを叩く処理 */
  private _getCrossSearchInfo(lang: string) {
    return this.$get<CrossSearchHotelMaster | null>(
      '/crosssearch/master/hotels',
      {
        params: {
          lang,
        },
        errorHandler: (err) => {
          if (this.getErrorStatus(err) === 501) {
            return Promise.resolve(null);
          }
        },
      },
    );
  }
}
