import './HMediaOverlayCaption.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { mergeVNodeData } from '~/helpers';

/** キャプション表示位置 */
export const MEDIA_CAPTION_POSITIONS = ['left', 'right'] as const;
export type MediaCaptionPosition = typeof MEDIA_CAPTION_POSITIONS[number];

export interface MediaCaptionSettings {
  caption: string;
  position?: MediaCaptionPosition;
}

/**
 * メディアに表示するキャプションコンポーネント
 * - 基本的にキャプションは右下に設定する
 * - 変更したい場合はpositionプロパティで指定する
 */
export const HMediaOverlayCaption = tsx.component({
  name: 'HMediaOverlayCaption',
  functional: true,
  props: {
    modelValue: {
      type: Object as PropType<MediaCaptionSettings>,
      required: true,
    },
  },
  render(h, { data, props }) {
    const { caption } = props.modelValue;
    const position = props.modelValue.position || 'right';

    return (
      <figcaption
        {...mergeVNodeData(data, {
          staticClass: 'v-media-overlay-caption',
          class: `v-media-overlay-caption--${position}`,
        })}>
        {caption}
      </figcaption>
    );
  },
});
