import './HGlobalBaloonDialog.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNodeChildren } from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { bodyScrollLock, clickOutside } from '@dadajam4/vue-stack';
import { HLanguageDialog, HLanguageDialogRef } from '../HLanguageDialog';
import {
  HBtn,
  HCurrencyDialog,
  HCurrencyDialogRef,
  HDialog,
  HIcon,
} from '~/components';

export interface HGlobalBaloonDialogProps {
  value?: boolean;
}

export interface HGlobalBaloonDialogEmits {
  onInput: boolean;
}

export interface HGlobalBaloonDialogScopedSlots {}

const MY_MODALS = ['currency', 'language'] as const;

type MyModalName = typeof MY_MODALS[number];

interface MyGlobalBaloonMenu {
  key: string;
  label: () => VNodeChildren;
  selector?: {
    value: () => VNodeChildren;
    modal: MyModalName;
    disabled?: boolean;
  };
  memo?: () => VNodeChildren;
}

/**
 * 言語/通貨設定ダイアログ(SP用)
 */
@Component<HGlobalBaloonDialogRef>({
  name: 'HGlobalBaloonDialog',
  directives: {
    bodyScrollLock,
    clickOutside,
  },
  render() {
    return (
      <HDialog
        contentClass="h-global-baloon-dialog"
        active={this.modalIsActive}
        backdrop
        onChange={(active) => {
          this.modalIsActive = active;
        }}>
        <div staticClass="h-global-baloon-dialog__global-menus">
          {this.globalBaloonMenus.map(({ key, label, memo, selector }) => (
            <div staticClass="h-global-baloon-dialog__global-menu" key={key}>
              <h4 staticClass="h-global-baloon-dialog__global-menu__label">
                {label()}
              </h4>
              {!!selector && (
                <div>
                  <HBtn
                    staticClass="h-global-baloon-dialog__global-menu__activator h-select__box"
                    size="lg"
                    tabindex={selector.disabled ? '-1' : '0'}
                    left
                    disabled={selector.disabled}
                    color="plain"
                    onClick={() => {
                      if (selector.modal) {
                        this.showModal(selector.modal);
                      }
                    }}>
                    <div staticClass="h-select__selections">
                      {selector.value()}
                    </div>
                    <HIcon
                      staticClass="h-select__icon"
                      name="keyboard-arrow-down"
                    />
                  </HBtn>
                </div>
              )}
              {!!memo && (
                <small staticClass="h-global-baloon-dialog__global-menu__memo">
                  {memo()}
                </small>
              )}
            </div>
          ))}
        </div>
        <HCurrencyDialog ref="currency"></HCurrencyDialog>
        <HLanguageDialog ref="language"></HLanguageDialog>
      </HDialog>
    );
  },
  watch: {
    value(value: boolean) {
      this.internalModalActive = value;
    },
  },
})
export class HGlobalBaloonDialogRef
  extends Vue
  implements HGlobalBaloonDialogProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;

  $refs!: {
    currency: HCurrencyDialogRef;
    language: HLanguageDialogRef;
  };

  private internalModalActive: boolean = this.value;

  get modalIsActive() {
    return this.internalModalActive;
  }

  set modalIsActive(modalIsActive) {
    if (this.internalModalActive !== modalIsActive) {
      this.internalModalActive = modalIsActive;
      this.$emit('input', modalIsActive);
    }
  }

  get languageInformations() {
    return this.$language.dynamicInformations;
  }

  get globalBaloonMenus() {
    const { $commons, $language } = this;

    const menus: MyGlobalBaloonMenu[] = [
      {
        key: 'language',
        label: () => '言語 / Language',
        selector: {
          value: () => $language.info.label,
          modal: 'language',
          disabled: this.languageInformations.length < 2,
        },
      },
      {
        key: 'currency',
        label: () => '表示通貨 / Currency',
        selector: {
          value: () => $commons.currency,
          modal: 'currency',
        },
        memo: () =>
          'レート換算は最新の取引を元に表示した、参考価格になります。',
      },
    ];
    return menus;
  }

  show() {
    this.modalIsActive = true;
  }

  close() {
    this.modalIsActive = false;
  }

  private showModal(name: MyModalName) {
    const { $refs } = this;
    if (!$refs) return;

    MY_MODALS.forEach((_name) => {
      const vm = this.$refs[_name];
      if (!vm) return;
      if (name === _name) {
        vm.show();
      } else {
        vm.close();
      }
    });
  }
}

export const HGlobalBaloonDialog = tsx
  .ofType<
    HGlobalBaloonDialogProps,
    HGlobalBaloonDialogEmits,
    HGlobalBaloonDialogScopedSlots
  >()
  .convert(HGlobalBaloonDialogRef);
