import { LanguageDataMap, AvailableLanguage } from './language';
import { MediaInfoMeta } from './media';

export interface GalleryCategory {
  id: string;
  name: string;
}

export interface GalleryCategoryLS {
  id: string;
  name: LanguageDataMap<GalleryCategory['name']>;
}

export function extractGalleryCategoryLS(
  ls: GalleryCategoryLS,
  lang: AvailableLanguage,
): GalleryCategory {
  return {
    ...ls,
    name: ls.name[lang] as string,
  };
}

export function extractGalleryCategoryLSList(
  rows: GalleryCategoryLS[],
  lang: AvailableLanguage,
): GalleryCategory[] {
  return rows.map((row) => extractGalleryCategoryLS(row, lang));
}

export interface GalleryImage {
  s: string;
  m: string;
  l: string;
  xl: string;
  meta?: MediaInfoMeta;
}

export interface Gallery extends GalleryImage {
  id: string;
  categoryId: string;
}

export interface MergedGalleryCategory extends GalleryCategory {
  galleries: Gallery[];
}

export function crateMergedGalleryCategories(
  categories: GalleryCategory[],
  galleries: Gallery[],
) {
  return categories
    .map((category) => {
      return {
        ...category,
        galleries: galleries.filter(
          (gallery) => gallery.categoryId === category.id,
        ),
      };
    })
    .filter((category) => category.galleries.length > 0);
}

export interface MockGalleryCategoryLS extends GalleryCategoryLS {
  length: number;
}

export interface GalleryCommons {
  galleryCategories: GalleryCategory[];
}
