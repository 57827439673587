import './HSimpleMedia.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MediaModuleProps, MediaModuleProperties } from './schemes';
import { createMediaModuleDerivedName } from './helpers';
import {
  MediaModuleSize,
  MediaModuleDerivedSize,
  MediaModuleDerivedName,
  getMediaDerived,
} from '~/schemes';
import { HSimpleGalleryItemInput, HSimpleGallery } from '~/components';
import { ResponsiveBreakpoint } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

export interface HSimpleMediaProps extends MediaModuleProps {}

export interface HSimpleMediaEmits {}

export interface HSimpleMediaScopedSlots {}

/**
 * ブレイクポイント別派生メディアサイズ名のマップ
 */
type MediaDerivedSizeBreakPointMap = Record<
  ResponsiveBreakpoint,
  MediaModuleDerivedSize
>;

/**
 * メディアサイズ毎のブレイクポイント別派生メディアサイズ名のマップ
 */
const MEDIA_SIZE_MAPPING: Record<
  MediaModuleSize,
  MediaDerivedSizeBreakPointMap
> = {
  xl: { wide: 'xl', narrow: 'md' },
  lg: { wide: 'lg', narrow: 'md' },
  md: { wide: 'md', narrow: 'sm' },
  sm: { wide: 'md', narrow: 'sm' },
};

/**
 * ブレイクポイント別派生メディア名のマップ
 */
type MediaDerivedNameBreakPointMap = Record<
  ResponsiveBreakpoint,
  MediaModuleDerivedName
>;

@Component<HSimpleMediaRef>({
  name: 'HSimpleMedia',

  render(h) {
    const { items, styles, size, enlargeable } = this;
    if (!items.length) {
      return h();
    }
    return (
      <div staticClass="h-simple-media" class={`h-simple-media--${size}`}>
        <HSimpleGallery
          staticClass="h-simple-media__gallery"
          style={styles}
          items={items}
          enlargeable={enlargeable}
        />
      </div>
    );
  },
})
export class HSimpleMediaRef extends Vue implements HSimpleMediaProps {
  @Prop({ type: Object, required: true })
  readonly value!: MediaModuleProperties;

  /** サイズ */
  get size() {
    return this.value.size;
  }

  /** アスペクト比 */
  get aspectRatio() {
    return this.value.aspectRatio;
  }

  /** 拡大表示可能か */
  get enlargeable() {
    return this.value.enlargeable;
  }

  /** ブレイクポイント別派生メディア名のマップ */
  get derivedNameMap(): MediaDerivedNameBreakPointMap {
    const { size, aspectRatio } = this;
    const map = MEDIA_SIZE_MAPPING[size];
    const result: MediaDerivedNameBreakPointMap = {} as any;
    Object.entries(map).forEach(([breakPoint, size]) => {
      result[breakPoint] = createMediaModuleDerivedName(size, aspectRatio);
    });
    return result;
  }

  /** ギャラリー要素に適用するスタイル */
  get styles() {
    const { aspectRatio } = this;
    return {
      aspectRatio,
    };
  }

  /**
   * 計算済みのメディアアイテムリスト
   *
   * * 計算している値（モーダルに渡すURLや、現在の設定＆ブレイクポイントに対応したメディアのURL）
   */
  get items(): HSimpleGalleryItemInput[] {
    const { wide, narrow } = this.derivedNameMap;
    const { items } = this.value;

    // 拡大モーダルのサムネとでかい画像は、xlとsmをそれぞれ使う
    const xlName = createMediaModuleDerivedName('xl', this.aspectRatio);
    const thumbName = createMediaModuleDerivedName('sm', this.aspectRatio);

    return items
      .map((item) => {
        const { key } = item;
        const url = getMediaDerived(wide, item, '');
        const narrowURL = getMediaDerived(narrow, item, url);
        const enlargeUrl = getMediaDerived(xlName, item, url);
        const thumbUrl = getMediaDerived(thumbName, item, url);
        return {
          key,
          url,
          narrowURL,
          enlargeUrl,
          thumbUrl,
        };
      })
      .filter(({ url, narrowURL }) => !!url && !!narrowURL);
  }
}

export const HSimpleMedia = tsx
  .ofType<HSimpleMediaProps, HSimpleMediaEmits, HSimpleMediaScopedSlots>()
  .convert(HSimpleMediaRef);
