import AbstractRoute from './AbstractRoute';
import {
  ExchangeRate,
  ActivityCommons,
  RestaurantCommons,
  HotelRoomCommons,
  PaymentCommons,
  GalleryCommons,
} from '~/schemes';

export interface GetExchangeRatesResult {
  exchangeRateTableList: ExchangeRate[];
}

export default class CommonsRoute extends AbstractRoute {
  /**
   * 為替情報取得(全データ)
   */
  getExchangeRates(): Promise<GetExchangeRatesResult> {
    return this.$get<GetExchangeRatesResult>('/exchange/all');
  }

  getActivityCommons(): Promise<ActivityCommons> {
    return this.context.$dataBucket.get('/commons/activity');
  }

  getRestaurantCommons(): Promise<RestaurantCommons> {
    return this.context.$dataBucket.get('/commons/restaurant');
  }

  getRoomCommons(): Promise<HotelRoomCommons> {
    return this.context.$dataBucket.get('/commons/room');
  }

  getPaymentCommons(): Promise<PaymentCommons> {
    return this.context.$dataBucket.get('/commons/payment');
  }

  getGalleryCommons(): Promise<GalleryCommons> {
    return this.context.$dataBucket.get('/commons/gallery');
  }
}
