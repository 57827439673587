import './HHotelToBookingAction.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { toInfo } from './utils';
import { HotelBasicInfo, HotelBrandBasicInfo } from '~/schemes';
import { mergeVNodeData } from '~/helpers';
import { HBtn } from '~/components';
import { GFEventCategory } from '~/plugins/gfev';

export const HHotelToBookingAction = tsx.component({
  name: 'HHotelToBookingAction',
  functional: true,

  props: {
    hotel: Object as PropType<HotelBasicInfo | undefined>,
    brand: Object as PropType<HotelBrandBasicInfo | undefined>,
    eventId: String,
  },

  render(h, { data, props, parent }): VNode {
    const { eventId } = props;

    const { to, hasRoomSearchLink, linkAttrs, externalBookingSite } = toInfo(
      parent,
      props,
    );

    if (!hasRoomSearchLink) return undefined as any;

    let evAction = '';
    if (process.browser) {
      if (to) {
        evAction =
          location.origin + parent.$router.resolve(to).resolved.fullPath;
      } else if (externalBookingSite) {
        evAction = externalBookingSite;
      }
    }

    const directives =
      (eventId && [
        {
          name: 'ev',
          value: {
            category: GFEventCategory.Link,
            action: evAction,
            eventId,
          },
        },
      ]) ||
      undefined;

    return (
      <HBtn
        {...(mergeVNodeData(data, {
          staticClass: 'h-hotel-to-booking-action',
          attrs: linkAttrs,
          directives,
        }) as any)}
        to={to}
        color="primary"
        stack={parent.$t('guide.bestRateShort') as string}>
        {parent.$t('name.vacancySearch')}
      </HBtn>
    );
  },
});
