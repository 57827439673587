import './HActivityListItem.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import html2text from '~/helpers/html2text';
import { createAgeRangeText, createPeriodText } from '~/helpers';
import {
  Activity,
  ActivityCommons,
  HotelBasicInfo,
  ActivityChargeCategory,
} from '~/schemes';
import { HImg, HBtn } from '~/components';

export interface HActivityListItemProps {
  commons: ActivityCommons;
  data: Activity;
  hotel: HotelBasicInfo;
}

export interface HActivityListItemEmits {}

export interface HActivityListItemScopedSlots {}

@Component<HActivityListItemRef>({
  name: 'h-activity-list-item',

  render() {
    const { to, data, description, thumb } = this;
    const { name } = data;

    return (
      <li staticClass="h-activity-list-item" class={this.classes}>
        <div staticClass="h-activity-list-item__inner">
          <nuxt-link staticClass="h-activity-list-item__thumb" to={to}>
            <HImg
              staticClass="h-activity-list-item__thumb__node"
              src={thumb}
              liquid
              loading
              inview
              width={470}
              height={314}
            />
          </nuxt-link>
          <div staticClass="h-activity-list-item__body">
            <h3 staticClass="h-activity-list-item__name">
              <nuxt-link staticClass="h-activity-list-item__name__link" to={to}>
                {name}
              </nuxt-link>
            </h3>
            {this.genTags()}
            {this.genPeriod()}
            <div
              v-wysiwyg={description}
              staticClass="h-activity-list-item__description"
            />
            <div staticClass="h-activity-list-item__actions">
              <HBtn
                staticClass="h-activity-list-item__actions__action"
                props={{ moveIn: true }}
                to={to}>
                {this.$t('label.details')}
              </HBtn>
            </div>
          </div>
        </div>
      </li>
    );
  },
})
export class HActivityListItemRef
  extends Vue
  implements HActivityListItemProps {
  @Prop({ type: Object, required: true }) readonly commons!: ActivityCommons;
  @Prop({ type: Object, required: true }) readonly data!: Activity;
  @Prop({ type: Object, required: true }) readonly hotel!: HotelBasicInfo;

  get pinned() {
    return this.data.pinned || false;
  }

  get thumb() {
    const thumb = this.$theme.is('kai') ? this.data.mainImage : this.data.thumb;
    return this.$res.img(thumb);
  }

  get to() {
    return this.$hotel.location(`/activities/${this.data.id}`, this.hotel);
  }

  get description() {
    const length = this.$language.current === 'en' ? 130 : 80;
    let description = this.data.description;
    if (this.$ua.isIE) {
      if (description.length >= length) {
        description = description.slice(0, length - 2) + '...';
      }
    }
    return description;
  }

  get reservationRequiredTag(): string | void {
    const { data, commons } = this;
    const { reservationRequired } = data;
    const hit = commons.reservationRequiredOptions.find(
      (r) => r.value === reservationRequired,
    );
    if (hit) {
      return hit.name;
    }
  }

  /**
   * 料金区分タグ
   *
   * @FIXME
   *
   * * この項目は「無料」のもののみ名前をひっぱる処理になっているが、CMSでマスタ管理機能を提供しているので、この処理は本来おかしい
   * * CMSで画面に出すか出さないか設定できるようにするか、有料・無料の区分をboolフラグのようにしないと概念としておかしい
   */
  get chargeCategoryTag(): string | void {
    const { data, commons } = this;
    const { chargeCategory } = data;
    const hit = commons.chargeCategoryOptions.find((r) => {
      const { value } = r;
      return (
        value === ActivityChargeCategory.NoCharge && value === chargeCategory
      );
    });
    if (hit) {
      return hit.name;
    }
  }

  get targetAgeTag(): string | void {
    const { data } = this;
    const { targetAge, targetAgeRange } = data;
    if (targetAgeRange) {
      return createAgeRangeText(this, targetAgeRange.from, targetAgeRange.to);
    }
    const hit = this.$commons
      .getActivityTargetAges()
      .find((r) => r.value === targetAge);
    if (hit) {
      return hit.name;
    }
  }

  get tags(): string[] {
    const { reservationRequiredTag, chargeCategoryTag, targetAgeTag } = this;
    const tags: string[] = [];
    reservationRequiredTag && tags.push(reservationRequiredTag);
    chargeCategoryTag && tags.push(chargeCategoryTag);
    targetAgeTag && tags.push(targetAgeTag);
    return tags;
  }

  get periodText(): string {
    const { period, periodText } = this.data;
    if (periodText) return periodText;
    const from = (period && period.from) || null;
    const to = (period && period.to) || null;
    return createPeriodText(this, from, to, false);
  }

  get periodMemo() {
    return this.data.periodMemo;
  }

  get classes() {
    return {
      'h-activity-list-item--pinned': this.pinned,
    };
  }

  private genPeriod() {
    const { periodText, periodMemo } = this;
    return (
      <dl staticClass="h-activity-list-item__period">
        <dt staticClass="h-activity-list-item__period__prefix">
          {`${this.$t('label.holdingPeriod')}：`}
        </dt>
        <dd staticClass="h-activity-list-item__period__text">
          <span staticClass="h-activity-list-item__period__text__main">
            {periodText}
          </span>
          {periodMemo ? (
            <span staticClass="h-activity-list-item__period__text__memo">
              {periodMemo}
            </span>
          ) : undefined}
        </dd>
      </dl>
    );
  }

  private genTags() {
    const { tags } = this;
    return (
      <ul staticClass="h-activity-list-item__tags">
        {tags.map((tag, tagIndex) => {
          return (
            <li key={tagIndex} staticClass="h-activity-list-item__tags__tag">
              {tag}
            </li>
          );
        })}
      </ul>
    );
  }
}

export const HActivityListItem = tsx
  .ofType<
    HActivityListItemProps,
    HActivityListItemEmits,
    HActivityListItemScopedSlots
  >()
  .convert(HActivityListItemRef);
