import {
  resolveStatusMessage,
  GFErrorSource,
  GFErrorSourceObject,
  GFErrorTarget,
  GFErrorJSON,
} from './schemes';
import { extractCustomErrorSource } from './resolvers';

/**
 * エラー構成情報を正規化する
 *
 * @param source - エラー構成情報
 */
function normalizeGFErrorSource(source: GFErrorSource): GFErrorSourceObject {
  let status = 500;
  let name: string = 'Error';
  let message: string = '';
  let stack: string | undefined;
  let target: GFErrorTarget | undefined;

  /**
   * axiosとかaws-sdkとかから抽出したカスタムエラー情報
   */
  const extracted = extractCustomErrorSource(source);

  if (extracted) {
    status = extracted.status;
    name = extracted.name;
    message = extracted.message;
    stack = extracted.stack;
    if (extracted.target) {
      target = { ...extracted.target };
    }
  } else if (typeof source === 'string') {
    message = source;
  } else if (typeof source === 'number') {
    status = source;
  } else if (source && typeof source === 'object') {
    if (source.name) name = source.name;
    if (source.status) status = source.status;
    if (source.message) message = source.message;
    if (source.stack) stack = source.stack;
    if (source.target) {
      target = { ...source.target };
    }
  }

  if (!message) {
    // この時点で最終的にメッセージ未確定の場合、ステータスコードに対応したメッセージを採用する
    message = resolveStatusMessage(status).message;
  }

  return {
    name,
    status,
    message,
    stack,
    target,
  };
}

/**
 * GlobalFormatアプリケーション全体の例外処理クラス
 *
 * フロント、サーバで発生する全てのエラーを取り扱う
 */
export class GFError extends Error {
  readonly _isGFError = true;

  /**
   * ステータスコード
   */
  status: number;

  /**
   * リクエスト対象情報
   */
  target?: GFErrorTarget;

  constructor(source: GFErrorSource) {
    super('');

    const { name, message, stack, status, target } = normalizeGFErrorSource(
      source,
    );
    this.name = name;
    this.message = message;
    this.status = status;

    if (stack) {
      this.stack = stack;
    }

    if (target) {
      this.target = {
        ...target,
      };
    }
  }

  toJSON(): GFErrorJSON {
    const json: GFErrorJSON = {
      _isGFError: true,
      name: this.name,
      message: this.message,
      stack: this.stack,
      status: this.status,
    };

    if (this.target) {
      json.target = {
        ...this.target,
      };
    }

    return json;
  }

  /**
   * エラー情報を元にGFErrorを生成する
   *
   * @param source - エラー情報
   */
  static create(source: GFErrorSource) {
    return new this(source);
  }

  /**
   * 不確定で謎な例外からGFErrorを生成する
   *
   * @param source - 例外
   */
  static from(source: any) {
    return new this(source);
  }
}

/**
 * 指定した変数が GFError のインスタンスであるかチェックする
 *
 * @param source - 検査したい変数
 */
export function isGFError(source: unknown): source is GFError {
  return source instanceof Error && (source as GFError)._isGFError === true;
}
