import './HLocationMapSpot.scss';

import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as tsx from 'vue-tsx-support';
import { HLocationMapSpotBaloon } from './HLocationMapSpotBaloon';
import { LocationMapSpot } from '~/schemes';

export interface HLocationMapSpotProps {
  /** ロケーションマップのスポット */
  value: LocationMapSpot;

  /** スポットがアクティブか */
  active?: boolean;
}

/**
 *
 */
export interface HLocationMapSpotEmits {
  onClickMarker: MouseEvent;
  onClickClose: MouseEvent;
}

/**
 *
 */
export interface HLocationMapSpotScopedSlots {}

@Component<HLocationMapSpotRef>({
  name: 'HLocationMapSpot',
  render(h) {
    return (
      <div
        ref="spot"
        staticClass="h-location-map-spot"
        class={{
          'h-location-map-spot--active': this.active,
        }}
        style={this.styles}>
        <div class="h-location-map-spot__marker" onClick={this.handleClickIcon}>
          <div class="h-location-map-spot__marker__icon" ref="markerIcon" />
        </div>
        <HLocationMapSpotBaloon
          class="h-location-map-spot__baloon"
          value={this.value}
          active={this.active}
          iconRef={this.getIcon}
          onClickClose={this.handleClickClose}
        />
      </div>
    );
  },
})
export class HLocationMapSpotRef extends Vue implements HLocationMapSpotProps {
  $refs!: {
    spot: HTMLElement;
    markerIcon: HTMLElement;
  };

  @Prop({ type: Object, required: true }) readonly value!: LocationMapSpot;
  @Prop(Boolean) readonly active!: boolean;

  get styles() {
    const { marker } = this.value;
    return {
      '--x': `${marker.x * 100}%`,
      '--y': `${marker.y * 100}%`,
    };
  }

  private getIcon() {
    return this.$refs.markerIcon;
  }

  private handleClickIcon(ev: MouseEvent) {
    this.$emit('clickMarker', ev);
  }

  private handleClickClose(ev: MouseEvent) {
    this.$emit('clickClose', ev);
  }
}

export const HLocationMapSpot = tsx
  .ofType<
    HLocationMapSpotProps,
    HLocationMapSpotEmits,
    HLocationMapSpotScopedSlots
  >()
  .convert(HLocationMapSpotRef);
