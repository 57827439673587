export function range(length: number, offset: number = 0): number[] {
  return Array.from(Array(length), (v, k) => k + offset);
}

export function ensureArray<T = unknown>(source?: T | T[]): T[] {
  if (source === undefined) {
    return [];
  }
  return Array.isArray(source) ? source : [source];
}

/**
 * 指定の配列に対して最大個数を考慮した上での新しい配列を返却する
 *
 * * 要素数に変化がない場合は、指定した配列がそのまま返却されます
 *
 * @param array - 任意の配列
 * @param maxlength - 最大要素数
 * @returns 最大要素数で制限された新しい配列
 */
export function limitArrayLength<T>(array: T[], maxlength: number): T[] {
  const newArray: T[] = [];
  if (array.length <= maxlength) return array;
  for (let i = 0; i < maxlength; i++) {
    newArray.push(array[i]);
  }
  return newArray;
}
