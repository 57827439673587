import './HRichTable.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

export interface HRichTableProps {
  html?: string;
}

export interface HRichTableEmits {}

export interface HRichTableScopedSlots {}

@Component<HRichTableRef>({
  name: 'HRichTable',

  render(h) {
    return (
      <div staticClass="h-container h-container--min v-rich-table-module-wrapper">
        <div v-wysiwyg={this.html} staticClass="v-rich-table-module"></div>
      </div>
    );
  },
})
export class HRichTableRef extends Vue implements HRichTableProps {
  @Prop({ type: String }) readonly html?: string;
}

export const HRichTable = tsx
  .ofType<HRichTableProps, HRichTableEmits, HRichTableScopedSlots>()
  .convert(HRichTableRef);
