import './HResponsiveMap.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { cheepUid } from '~/helpers';

export const RESPONSIVE_MAP_AREA_TYPES = ['rect', 'circle', 'poly'] as const;

export type HResponsiveMapAreaType = typeof RESPONSIVE_MAP_AREA_TYPES[number];

export type HResponsiveMapRectArea = {
  type: 'rect';
  coords: [number, number, number, number];
};

export type HResponsiveMapCircleArea = {
  type: 'circle';
  coords: [number, number, number];
};

export type HResponsiveMapPolyArea = {
  type: 'poly';
  coords: [number, number][];
};

export type HResponsiveMapArea = {
  name?: string;
  onClick?: (ev: MouseEvent) => any;
  href?: string;
  alt?: string;
  title?: string;
} & (
  | HResponsiveMapRectArea
  | HResponsiveMapCircleArea
  | HResponsiveMapPolyArea
);

interface ComputedArea extends Omit<HResponsiveMapArea, 'coords'> {
  type: HResponsiveMapAreaType;
  coords: string;
}

interface MapInfo {
  id: string;
  width: number;
  height: number;
  areas: ComputedArea[];
}

export interface HResponsiveMapProps {
  src: string;
  width: number;
  height: number;
  areas?: HResponsiveMapArea[];
  lazy?: boolean;
}

export interface HResponsiveMapEmits {}

export interface HResponsiveMapScopedSlots {}

const SPACER_GIF =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

@Component<HResponsiveMapRef>({
  name: 'HResponsiveMap',

  render() {
    const { mapInfo } = this;
    return (
      <div
        staticClass="h-responsive-map"
        v-inview={
          this.inviewed
            ? false
            : {
                in: () => {
                  this.inviewed = true;
                },
              }
        }
        v-resize={{
          debounce: 250,
          value: this.handleResize,
        }}>
        <div staticClass="h-responsive-map__bg" style={this.bgStyles}>
          {!!mapInfo && [
            <img
              staticClass="h-responsive-map__img"
              src={SPACER_GIF}
              width={mapInfo.width}
              height={mapInfo.height}
              alt=""
              usemap={`#${mapInfo.id}`}
            />,
            <map name={mapInfo.id}>
              {mapInfo.areas.map((area, index) => (
                <area
                  shape={area.type}
                  attrs={{
                    coords: area.coords,
                  }}
                  title={area.title}
                  alt={area.alt}
                  key={index}
                  href={area.href}
                  on={
                    area.onClick
                      ? {
                          click: area.onClick,
                        }
                      : {}
                  }
                />
              ))}
            </map>,
          ]}
        </div>
      </div>
    );
  },
})
export class HResponsiveMapRef extends Vue implements HResponsiveMapProps {
  @Prop({ type: String, required: true }) readonly src!: string;
  @Prop({ type: Number, required: true }) readonly width!: number;
  @Prop({ type: Number, required: true }) readonly height!: number;
  @Prop(Boolean) readonly lazy!: boolean;
  @Prop({ type: Array, default: () => [] })
  readonly areas!: HResponsiveMapArea[];

  private inviewed: boolean = !this.lazy;
  private containerWidth: number = 0;

  get heightPer() {
    return this.height / this.width;
  }

  get bgStyles() {
    return {
      backgroundImage: this.inviewed ? `url(${this.src})` : null,
      paddingTop: `${this.heightPer * 100}%`,
    };
  }

  get computedSize() {
    const { containerWidth } = this;
    if (!containerWidth) return;
    return {
      width: containerWidth,
      height: containerWidth * this.heightPer,
    };
  }

  get mapInfo(): MapInfo | undefined {
    const { computedSize } = this;
    if (!computedSize) return;

    const { width } = computedSize;
    return {
      id: `HResponsiveMap___${cheepUid()}`,
      ...computedSize,
      areas: this.areas.map((area) => {
        const { type, href, onClick, title, alt } = area;
        const coords = area.coords.slice().flat();
        return {
          type,
          href,
          title,
          alt,
          onClick,
          coords: coords.map((coord) => coord * width).join(','),
        };
      }),
    };
  }

  private handleResize(dimension: { width: number; height: number }) {
    this.containerWidth = dimension.width;
  }
}

export const HResponsiveMap = tsx
  .ofType<HResponsiveMapProps, HResponsiveMapEmits, HResponsiveMapScopedSlots>()
  .convert(HResponsiveMapRef);
