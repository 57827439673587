import Vue from 'vue';
import { Context, Plugin, NuxtAppOptions } from '@nuxt/types';
import { Location, RawLocation } from 'vue-router';
import type { CommonsService } from './commons';
import {
  HotelBasicInfo,
  HotelDetail,
  YgetsableHotelDetail,
  HotelBrandBasicInfo,
  HotelBrandDetail,
  HotelNotification,
  ReservationCenter,
  NavigationBaseProps,
  AvailableLanguage,
  YgHotelDetail,
} from '~/schemes';

declare module 'vue/types/vue' {
  export interface Vue {
    $hotel: HotelService;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $hotel: HotelService;
  }
}

declare module '@nuxt/types' {
  export interface NuxtAppOptions {
    $hotel: HotelService;
  }

  export interface Context {
    $hotel: HotelService;
  }
}

export class HotelService {
  readonly context: Context;

  /**
   * 宿GETSの施設詳細情報をロード中の場合、その宿GETS IDとPromiseインタンス
   */
  private loadYgetsDetailPromise: {
    ygetsId: string;
    promise: Promise<YgHotelDetail | null>;
  } | null = null;

  get current(): HotelDetail | null {
    return this.context.store.state.hotel.current;
  }

  get ygets() {
    return (this.current && this.current.ygets) || undefined;
  }

  get brands(): HotelBrandBasicInfo[] {
    return this.context.$commons.brands;
  }

  get all(): HotelBasicInfo[] {
    return this.context.$commons.hotels;
  }

  getById(id: string): HotelBasicInfo | undefined {
    return this.all.find((hotel) => hotel.id === id);
  }

  get currentNotifications(): HotelNotification[] {
    return this.context.store.getters['hotel/currentNotifications'];
  }

  get currentRoomsearchNotificationsNotifications(): HotelNotification[] {
    return this.context.store.getters[
      'hotel/currentRoomsearchNotificationsNotifications'
    ];
  }

  get allNotifications() {
    const notifications: (HotelNotification | HotelNotification[])[] = [
      ...this.currentRoomsearchNotificationsNotifications,
    ];
    const { currentNotifications } = this;
    if (currentNotifications.length) {
      notifications.unshift(currentNotifications);
    }
    return notifications;
  }

  get currentBrand(): HotelBrandDetail | undefined {
    return this.context.store.getters['hotel/currentBrand'];
  }

  get reservationCenterContext() {
    return this.current || this.currentBrand;
  }

  get currentReservationCenter(): ReservationCenter | null {
    const { reservationCenterContext } = this;
    return (
      (reservationCenterContext &&
        reservationCenterContext.reservationCenter) ||
      null
    );
  }

  get currentReservationCenterNotice(): string | null {
    const { reservationCenterContext } = this;
    return (
      (reservationCenterContext &&
        reservationCenterContext.reservationCenterNotice) ||
      null
    );
  }

  constructor(context: Context) {
    this.context = context;
  }

  getBySlug(slug: string) {
    const { current } = this;
    if (current && current.slug === slug) return Promise.resolve(current);
    return this.context.$api.hotels.getBySlug(slug);
  }

  activitiesWithKeywordsBySlug(slug: string) {
    return this.context.$api.hotels.activitiesWithKeywordsBySlug(slug);
  }

  activityBySlugAndId(slug: string, id: string) {
    return this.context.$api.hotels.activityBySlugAndId(slug, id);
  }

  accessInformationsBySlug(slug: string) {
    return this.context.$api.hotels.accessInformationsBySlug(slug);
  }

  /**
   * 施設の料理コンテンツを取得する
   *
   * @FIXME
   *
   * ## NOTES
   * * このメソッドは、現状中途半端な実装になっています
   * * リゾナーレの設計の際、料理構成は特定の食事処に紐づくことにしましたが、公開jsonのパスの定義上、施設は単一の料理構成しか公開できないことになっています
   * * したがって、このメソッドでは食事処IDをオプションとして受け取ってフィルタできることにしていますが、これはちょっと中途半端な実装状態です
   * * TO BEは、施設が料理構成自体を複数保有できるように、公開jsonパスを定義しなおすことです
   * * 食事関連のデータの階層を見直すTODOが残っているので、その時に対応し、既存データを移行する想定です
   *
   * @see https://hr-dev.backlog.jp/view/ACCO_CMS-1139
   * @see {@link ACCOAdapter.getHotelCuisines}
   *
   * @param slug - 施設スラッグ
   * @param restaurantId - 特定の食事処でフィルタしたい場合そのエンティティのID
   * @returns GF表示のためのコンテンツ配列オブジェクト
   */
  cuisinesBySlug(slug: string, restaurantId?: string) {
    return this.context.$api.hotels.cuisinesBySlug(slug, restaurantId);
  }

  setCurrent(hotel: HotelDetail | null) {
    this.context.store.commit('hotel/SET_CURRENT', hotel);
  }

  /**
   * 現在の施設詳細情報の宿gets施設マスタ（もしくはnull）を適用する
   *
   * * 宿GETS IDが食い違っていたりしたら処理はキャンセルする
   *
   * @param ygets - 宿GETS施設詳細情報 or null
   */
  setCurrentYgets(ygets: YgHotelDetail | null) {
    this.context.store.commit('hotel/SET_CURRENT_YGETS', ygets);
  }

  setCurrentBrand(brand: HotelBrandDetail | null) {
    this.context.store.commit('hotel/SET_CURRENT_BRAND', brand);
  }

  brandBasicBySlug(slug: string): HotelBrandBasicInfo | undefined {
    return this.brands.find((brand) => brand.slug === slug);
  }

  /**
   * 指定のブランドIDでブランド情報を取得します
   *
   * @param brandId - ブランドID
   */
  brandById(brandId: string): HotelBrandBasicInfo | undefined {
    return this.brands.find((brand) => brand.id === brandId);
  }

  /**
   * 指定のブランドIDに紐づく施設のリストを取得します。
   * 指定のブランドが存在しない場合は空のリストを返却します。
   * このメソッドの戻り値となる施設のリストは、CMSで設定されている並び順の通りになっています。
   *
   * @param brandId - ブランドID
   */
  hotelsByBrandId(brandId: string): HotelBasicInfo[] {
    const brand = this.brandById(brandId);
    if (!brand) return [];

    const hotels = this.all.filter((hotel) => hotel.brandId === brandId);

    const { linkedHotels = [] } = brand;

    const sortedHotels: HotelBasicInfo[] = [];

    // 1. ブランドの `linkedHotels` (ブランドに紐付けされた施設のリスト) の順番でまず整列する
    linkedHotels.forEach(({ slug }) => {
      const hit = hotels.find((hotel) => hotel.slug === slug);
      if (hit) {
        sortedHotels.push(hit);
        const index = hotels.indexOf(hit);
        hotels.splice(index, 1);
      }
    });

    // 2. `linkedHotels` に含まれていなかった施設を後続に整列する
    sortedHotels.push(...hotels);

    return sortedHotels;
  }

  brandDetailBySlug(slug: string): Promise<HotelBrandDetail | undefined> {
    return this.context.$api.hotels.brandDetailBySlug(slug);
  }

  hasFeature() {
    const { current } = this;
    return !!current && current.features.length > 0;
  }

  hasTodo() {
    const { current } = this;
    return !!current && (!!current.todo || !!current.todoBanner);
  }

  hasActivity() {
    const { current } = this;
    return !!current && !!current.activitySettings;
  }

  /**
   * 食事コンテンツがあるかどうか
   *
   * 食事設定の有無で判断する
   */
  hasDining() {
    const { current } = this;
    return !!current && !!current.restaurantSettings;
  }

  hasRoom() {
    const { current } = this;
    return !!current && (current.rooms.length > 0 || !!current.roomGallery);
  }

  hasLocation() {
    const { current } = this;
    return !!current && !!current.location;
  }

  location(
    source: RawLocation = '/',
    hotel?: HotelBasicInfo | string | null,
  ): Location {
    if (!hotel) {
      hotel = this.current;
      if (!hotel) {
        throw new Error('missing current hotel');
      }
    }
    if (typeof hotel !== 'string') {
      hotel = hotel.slug;
    }
    const location = typeof source === 'string' ? { path: source } : source;
    location.path =
      `/hotels/${hotel}/` + (location.path || '').replace(/^\//, '');
    return this.context.$language.link(location);
  }

  getYgetsOrThrow() {
    const { ygets } = this;
    if (!ygets) throw new Error('missing ygets info');
    return ygets;
  }

  /**
   * 宿GETSリンクを生成する際のオリジンを作る
   */
  createYgetsOrigin(hotel: YgetsableHotelDetail) {
    const { $env } = this.context;
    return hotel.ygets.useGlobalformatYgetsFlg
      ? ($env.origin || '/').replace(/\/$/, '')
      : $env.ygetsOrigin;
  }

  isReservationNeedLoginUrl(url?: string | null) {
    if (!url) return false;
    return /(^|hoshinoresorts\.com)\/reservations\//.test(url);
  }

  detectReservationNeedLoginGuide(url?: string | null) {
    if (this.isReservationNeedLoginUrl(url)) {
      return this.context.$i18n.t('guide.needReservationLogin') as string;
    }
  }

  /**
   * 宿GETSの施設毎の検索TOPのURLを生成する
   * ※GF版の場合は、ハッシュ「#」はつけない
   * https://booking.hoshinoresort.com/#/JA/hotels/[hotelId]/search?checkIn=2019%2F09%2F30&stay=2&a=2&b=1&c=1&d=1
   */
  createYgetsBookUrl(
    hotel: YgetsableHotelDetail,
    params: {
      checkIn?: string | number;
      stayLength?: string | number;
      adult?: string | number;
      underTwelve?: string | number;
      underSeven?: string | number;
      underFour?: string | number;
    } = {},
  ): string {
    const origin = this.createYgetsOrigin(hotel);
    const queries: string[] = [];
    const {
      checkIn,
      stayLength,
      adult,
      underTwelve,
      underSeven,
      underFour,
    } = params;

    checkIn != null && queries.push(`checkIn=${checkIn}`);
    stayLength != null && queries.push(`stay=${stayLength}`);
    adult != null && queries.push(`a=${adult}`);
    underTwelve != null && queries.push(`b=${underTwelve}`);
    underSeven != null && queries.push(`c=${underSeven}`);
    underFour != null && queries.push(`d=${underFour}`);

    const query = queries.length > 0 ? '?' + queries.join('&') : '';
    const lang = this.context.$language.info.ygetsKey;
    const hash = hotel.ygets.useGlobalformatYgetsFlg ? '' : '/#';
    return `${origin}${hash}/${lang}/hotels/${hotel.ygetsId}/search${query}`;
  }

  /**
   * YgetsIdを元にURLを生成
   */
  createYgetsURL(YgetsId: string): string {
    const { $env } = this.context;
    const lang = this.context.$language.info.ygetsKey;

    const origin = ($env.origin || '/').replace(/\/$/, '');
    return `${origin}/${lang}/hotels/${YgetsId}`;
  }

  /**
   * 宿GETSの予約確認画面のURLを生成する
   */
  createReservationUrl(hotel: YgetsableHotelDetail) {
    const { $language } = this.context;
    const origin = this.createYgetsOrigin(hotel);
    return `${origin}/reservations/#/${$language.info.ygetsKey}/hotels/${hotel.ygetsId}`;
  }

  /**
   * 宿GETSの部屋詳細URL
   * https://booking.hoshinoresort.com/rooms/[ygets言語]/[施設ID]/[部屋ID]
   */
  createYgetsRoomUrl(hotel: YgetsableHotelDetail, roomId: string): string {
    const { info } = this.context.$language;
    const origin = this.createYgetsOrigin(hotel);
    return `${origin}/rooms/${info.ygetsKey}/${hotel.ygetsId}/${roomId}`;
  }

  toNavigationProps(
    hotel: HotelBasicInfo,
    lang: AvailableLanguage = this.context.$language.current,
  ): NavigationBaseProps | undefined {
    const { availableLanguages } = hotel;
    const hit = availableLanguages.find((l) => l.id === lang);
    if (!hit) return;
    const { external } = hit;
    if (external) {
      return {
        href: external,
        target: '_blank',
        rel: 'noopener',
      };
    }
    return {
      to: {
        path: `/${lang}/hotels/${hotel.slug}/`,
      },
    };
  }

  /**
   * 現在の施設に紐づく宿GETSの施設マスタをロードして、グローバルストアに保持する
   *
   * ※ 基本的には、ブラウザJSでページがマウントされた以降に実行すること（空室検索ページみたいな、そもそもマスタが必須なページではSSRでロードしてOK）
   */
  loadCurrentHotelYgetsDetail(): Promise<YgHotelDetail | null | void> {
    // すでに宿GETSマスタがある場合は即時応答
    if (this.current && this.current.ygets)
      return Promise.resolve(this.current.ygets);

    /**
     * 宿GETS 施設ID（ロード要求時点での）
     */
    const ygetsId = this.current && this.current.ygetsId;

    // 宿GETS IDがない場合、undefined応答
    if (!ygetsId) {
      this.setCurrentYgets(null);
      return Promise.resolve();
    }

    let { loadYgetsDetailPromise } = this;

    if (!loadYgetsDetailPromise || loadYgetsDetailPromise.ygetsId !== ygetsId) {
      // まだPromiseが生成されていないか、施設IDが異なっていた時に、Promiseを生成する
      loadYgetsDetailPromise = {
        ygetsId,
        promise: this.context.$api.hotels
          .getYgetsInfo(ygetsId)
          .then((value) => {
            const { current } = this;

            if (!current || current.ygetsId !== ygetsId) {
              // 読み込み完了時点で、すでにこのインスタンスの施設データがなくなっていたか、宿GETS IDが食い違っている場合、何もしない
              return null;
            }

            // 施設情報にぶら下げる
            // ※これで施設ルートビューのインスタンスメンバの中身も、同じ参照なので更新される
            this.setCurrentYgets(value);
            this.loadYgetsDetailPromise = null;
            return value;
          })
          .catch((_err) => {
            // ロードに失敗してもログを吐くだけにしておく
            // なぜかanyにしないと、Nuxtのts-checkerにおこられる
            const err = (this.context as any).$error.from(_err);
            (this.context as any).$logger.error(err);
            this.loadYgetsDetailPromise = null;

            // 宿GETSマスタがとれなかった場合はログだけ吐いて何もしない
            return null;
          }),
      };
      this.loadYgetsDetailPromise = loadYgetsDetailPromise;
    }
    return loadYgetsDetailPromise.promise;
  }
}

const plugin: Plugin = (context, inject) => {
  const service = new HotelService(context);
  context.$hotel = service;
  inject('hotel', service);
};

export default plugin;
