import './HListLayoutMedia.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MediaModuleProps, MediaModuleProperties } from './schemes';
import { createMediaModuleDerivedName } from './helpers';
import { getMediaDerived } from '~/schemes';
import { HListLayoutGalleryItemInput, HListLayoutGallery } from '~/components';

export interface HListLayoutMediaProps extends MediaModuleProps {}

export interface HListLayoutMediaEmits {}

export interface HListLayoutMediaScopedSlots {}

@Component<HListLayoutMediaRef>({
  name: 'HListLayoutMedia',

  render(h) {
    const { items } = this;
    if (!items.length) {
      return h();
    }

    return (
      <div staticClass="h-list-layout-media" class="h-container">
        <HListLayoutGallery
          staticClass="h-list-layout-media__gallery"
          items={items}
        />
      </div>
    );
  },
})
export class HListLayoutMediaRef extends Vue implements HListLayoutMediaProps {
  @Prop({ type: Object, required: true })
  readonly value!: MediaModuleProperties;

  /** アスペクト比 */
  get aspectRatio() {
    return this.value.aspectRatio;
  }

  get items(): HListLayoutGalleryItemInput[] {
    return this.value.items.map((item) => {
      return {
        images: getMediaDerived(
          createMediaModuleDerivedName('md', this.aspectRatio),
          item,
          '',
        ),
        aspectRatio: this.aspectRatio,
      };
    });
  }
}

/** 特集ページのメディア>ギャラリーの表示に関するメディアコンポーネント */
export const HListLayoutMedia = tsx
  .ofType<
    HListLayoutMediaProps,
    HListLayoutMediaEmits,
    HListLayoutMediaScopedSlots
  >()
  .convert(HListLayoutMediaRef);
