import './HInfiniteCarousel.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { VNode, NormalizedScopedSlot, VNodeDirective } from 'vue/types/vnode';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { HProgressSpinner } from '~/components';
import drag, {
  DragVNodeDirective,
  DragDirectivePayload,
} from '~/directives/drag';
import { ensureArray, toInt, loadImageSize } from '~/helpers';
import { HotelFeatureItemImage } from '~/pages/_lang/hotels/_hotel_slug/index/-components/MyHotelFeature/MyHotelFeatureItem';

const ITEM_CLICK_THRESHOLD = 30;
const DEFAULT_TOUCH_THRESHOLD = 50;

interface Dimension {
  width: number;
  height: number;
}

export interface HInfiniteCarouselItem {
  [key: string]: any;
  images?: HotelFeatureItemImage | HotelFeatureItemImage[];
}

export interface HInfiniteCarouselComputedItem {
  [key: string]: any;
  index: number;
  images: HotelFeatureItemImage[];
}

export interface HInfiniteCarouselProps {
  items: HInfiniteCarouselItem[];
  paused?: boolean;
  center?: boolean;
  touchThreshold?: string | number;
  useCSS?: boolean;
  pxBySecond?: string | number;
}

export interface HInfiniteCarouselEmits {
  onClickKey: string;
}

export interface HInfiniteCarouselScopedSlots {
  item: { item: HInfiniteCarouselComputedItem; carousel: HInfiniteCarouselRef };
}

@Component<HInfiniteCarouselRef>({
  name: 'h-infinite-carousel',
  directives: {
    drag,
  },

  created() {
    this._movedX = 0;
  },

  mounted() {
    this.isMounted = true;
  },

  beforeDestroy() {
    this.isDestroyed = true;
    this.cancelScrollTick();
  },

  render() {
    const { $scopedSlots, computedItems: items } = this;
    const itemsCount = items.length;
    const { item: itemSlot } = $scopedSlots;
    if (!itemSlot) {
      throw new Error(`missing item slot`);
    }

    const $items = items.map((item, index) => {
      return this.createItem(
        itemSlot,
        item,
        index,
        index,
        !this.loaded,
        'sources',
      );
    });

    const slidesChildren: VNode[] = [];
    if (this.dimensionIsReady) {
      const { groupCount } = this;
      for (let groupIndex = 0; groupIndex < groupCount; groupIndex++) {
        for (let itemIndex = 0; itemIndex < itemsCount; itemIndex++) {
          const item = items[itemIndex];
          const key = `${groupIndex}-${itemIndex}`;
          const $slide = this.createItem(itemSlot, item, itemIndex, key);
          slidesChildren.push($slide);
        }
      }
    }

    const dragDirective: DragVNodeDirective | undefined = this.dimensionIsReady
      ? {
          name: 'drag',
          value: {
            onStart: this.pointStartHandler,
            onMove: this.pointMoveHandler,
            onEnd: this.pointEndHandler,
            onDestroy: this.pointDestroyHandler,
          },
        }
      : undefined;

    const directives: VNodeDirective[] = [
      {
        name: 'inview',
        value: {
          in: () => {
            this.onInview();
          },
          out: () => {
            this.onOutview();
          },
        },
      },
    ];

    if (!this.isPaused) {
      directives.push({
        name: 'resize',
        value: (dim: Dimension) => {
          this.internalContainerDimension = dim;
          this.containerDimensionIsReady = true;
        },
      });
    }

    return (
      <div
        staticClass="h-infinite-carousel"
        class={this.classes}
        {...{ directives }}>
        <div
          staticClass="h-infinite-carousel__container h-infinite-carousel__source"
          ref="source"
          {...{
            directives: this.isPaused
              ? undefined
              : [
                  {
                    name: 'resize',
                    value: (dim: Dimension) => {
                      this.internalSourceDimension = dim;
                      this.sourceDimensionIsReady = dim.width !== 0;
                    },
                  },
                ],
          }}>
          {$items}
        </div>
        <div staticClass="h-infinite-carousel__scroller">
          <div
            staticClass="h-infinite-carousel__container h-infinite-carousel__slides"
            ref="slides"
            {...{
              directives: dragDirective ? [dragDirective] : undefined,
            }}>
            {slidesChildren}
          </div>
        </div>
      </div>
    );
  },
})
export class HInfiniteCarouselRef
  extends Vue
  implements HInfiniteCarouselProps {
  $refs!: {
    source: HTMLElement;
    slides: HTMLElement;
    sources: HTMLElement[];
  };

  @Prop({ type: Array, required: true })
  readonly items!: HInfiniteCarouselItem[];

  @Prop({ type: Boolean }) readonly paused!: boolean;
  @Prop({ type: Boolean }) readonly center!: boolean;
  @Prop({ type: [String, Number], default: DEFAULT_TOUCH_THRESHOLD })
  readonly touchThreshold!: string | number;

  @Prop({ type: Boolean, default: true }) readonly useCSS!: boolean;
  @Prop({ type: [String, Number], default: 20 }) readonly pxBySecond!:
    | string
    | number;

  private isMounted: boolean = false;
  private isDestroyed: boolean = false;
  private internalSourceDimension: Dimension = { width: 0, height: 0 };
  private internalContainerDimension: Dimension = { width: 0, height: 0 };
  private sourceDimensionIsReady: boolean = false;
  private containerDimensionIsReady: boolean = false;
  private sizeCaches: { [key: string]: Dimension } = {};
  private isPointing: boolean = false;
  private _movedX: number = 0;
  private internalPaused: boolean = this.paused;
  private internalOutviewPaused: boolean = false;
  private cumulativeMovedX: number = 0;
  private cumulativeMovedY: number = 0;
  private _tickCancelId?: number;
  private loading = false;
  loaded = false;
  private isInview: boolean = false;

  get classes() {
    return {
      'h-infinite-carousel--ready': this.dimensionIsReady,
    };
  }

  get isPaused() {
    return this.internalPaused || this.internalOutviewPaused;
  }

  get computedItems(): HInfiniteCarouselComputedItem[] {
    return this.items.map((item, index) => {
      const images = ensureArray(item.images);
      return {
        ...item,
        index,
        images,
      };
    });
  }

  get sourceDimension() {
    return this.internalSourceDimension;
  }

  get containerDimension() {
    return this.internalContainerDimension;
  }

  get dimensionIsReady() {
    return (
      this.isMounted &&
      this.sourceDimensionIsReady &&
      this.containerDimensionIsReady &&
      this.loaded
    );
  }

  get groupCount(): number {
    if (process.server) {
      return 0;
    }
    const { sourceDimension } = this;

    /**
     * @memo: スクリーン幅の2倍をとりあえずバッファ範囲にしておく
     */
    const containerWidth = Math.max(screen.width, screen.height) * 2;
    const sourceWidth = sourceDimension.width;

    /**
     * 親コンポーネントがvue-routerでdeactivateされている時など、DOMサイズが算出できない時があり、
     * 0で割り算クラッシュする事がある
     */
    if (sourceWidth === 0) {
      return 0;
    }

    // コンテナの幅の左右＋x2個分は必須サイズとする
    const needWidth = containerWidth * 3;

    // 小数点切り上げで何個必要か計算
    let groupCount = Math.ceil(needWidth / sourceWidth);

    // 偶数だったら奇数になるよう1つ繰り上げとく
    if (groupCount % 2 === 0) {
      groupCount++;
    }
    return groupCount;
  }

  get slidesWidth() {
    return this.groupCount * this.sourceDimension.width;
  }

  get isLoaded() {
    return this.loaded;
  }

  get computedTouchThreshold() {
    return toInt(this.touchThreshold);
  }

  get computedPxBySecond() {
    return toInt(this.pxBySecond);
  }

  getImageSize(image: string): Dimension | null {
    return this.sizeCaches[image] || null;
  }

  getHeightRoundedSize(image: string): Dimension {
    const { containerDimension } = this;
    const { height: containerHeight } = containerDimension;
    const size = this.getImageSize(image);
    if (!size) {
      return {
        width: containerHeight,
        height: containerHeight,
      };
    }
    const { width, height } = size;
    let diff = height / containerHeight;
    if (isNaN(diff)) {
      diff = 1;
    }
    return {
      width: width / diff,
      height: containerHeight,
    };
  }

  private onInview() {
    this.isInview = true;
    this.loadAllLazyItems();
  }

  private onOutview() {
    this.isInview = false;
  }

  private pause() {
    this.internalPaused = true;
  }

  private resume() {
    this.internalPaused = false;
  }

  private outviewPause() {
    this.internalOutviewPaused = true;
  }

  private outviewResume() {
    this.internalOutviewPaused = false;
  }

  private pointStartHandler(e: DragDirectivePayload) {
    this.judgeThreshold(e);
  }

  private judgeThreshold(e: DragDirectivePayload) {
    if (!this.isPointing) {
      const threshold = e.isTouch ? this.computedTouchThreshold : 0;
      if (Math.abs(e.totalX) >= threshold) {
        this.isPointing = true;
        this.cumulativeMovedX = 0;
        this.cumulativeMovedY = 0;
        this.pause();
      }
    }
    return this.isPointing;
  }

  private pointMoveHandler(e: DragDirectivePayload) {
    if (!this.judgeThreshold(e)) return;
    const { slides } = this.$refs;
    this.cumulativeMovedX = this.cumulativeMovedX + e.deltaX;
    this.cumulativeMovedY = this.cumulativeMovedY + e.deltaY;
    slides.style.transform = `translateX(${e.totalX}px)`;
  }

  private pointEndHandler(e: DragDirectivePayload) {
    if (!this.isPointing) return;
    const { cumulativeMovedX, cumulativeMovedY } = this;
    if (
      this.$listeners.clickKey &&
      Math.abs(cumulativeMovedX) <= ITEM_CLICK_THRESHOLD &&
      Math.abs(cumulativeMovedY) <= ITEM_CLICK_THRESHOLD
    ) {
      let parent: HTMLElement | null = e.originalEvent
        .target as HTMLElement | null;

      for (let i = 0; i < 5; i++) {
        if (!parent) break;
        if (parent === this.$refs.slides) break;

        const clickKey = parent.getAttribute('data-h-infinite-carousel-click');

        if (clickKey) {
          this.$emit('clickKey', clickKey);
          break;
        }
        parent = parent.parentNode as HTMLElement | null;
      }
    }

    const { slides } = this.$refs;
    slides.style.transform = '';
    this.shiftX(e.totalX);
    this.isPointing = false;
    !this.paused && this.resume();
  }

  private pointDestroyHandler(e: DragDirectivePayload) {
    const { slides } = this.$refs;
    if (!slides) {
      return undefined;
    }
    slides.style.transform = '';
    this.isPointing = false;
    this.resume();
  }

  private createItem(
    slot: NormalizedScopedSlot,
    item: HInfiniteCarouselComputedItem,
    index: number,
    key: number | string,
    loading?: boolean,
    ref?: string,
  ): VNode {
    const children: VNode[] = [
      <div staticClass="h-infinite-carousel__item__body">
        {slot({ item, carousel: this })}
      </div>,
    ];

    if (loading && this.loading) {
      children.push(
        <HProgressSpinner staticClass="h-infinite-carousel__item__loading" />,
      );
    }

    return (
      <div
        staticClass="h-infinite-carousel__item"
        data-h-infinite-carousel__item-index={index}
        key={key}
        ref={ref}
        refInFor={!!ref}>
        {children}
      </div>
    );
  }

  private async loadImageSize(src: string): Promise<Dimension> {
    const cached = this.sizeCaches[src];
    if (cached) return cached;
    const size = await loadImageSize(src);
    this.$set(this.sizeCaches, src, size);
    return size;
  }

  private updateOffset() {
    const { sourceDimension, center, $refs } = this;
    const { slides } = $refs;
    const sourceWidth = sourceDimension.width;
    let offset = ((this.groupCount - 1) / 2) * sourceWidth;
    if (center) {
      const { containerDimension } = this;
      const containerHalfWidth = containerDimension.width / 2;
      const firstChild = $refs.sources[0];
      const halfFirstChildWidth = firstChild.getBoundingClientRect().width / 2;
      offset -= containerHalfWidth - halfFirstChildWidth;
    }
    slides.style.marginLeft = -offset + 'px';
  }

  private shiftX(amount: number) {
    let newX = this._movedX + amount;
    const i = Math.abs(newX);
    const rounded = i % this.sourceDimension.width;
    newX = rounded * (newX < 0 ? -1 : 1);
    this._movedX = newX;

    const { slides } = this.$refs;
    slides.style.left = Math.round(newX) + 'px';
  }

  private async loadAllLazyItems() {
    if (this.loaded || this.loading) {
      return;
    }
    this.loading = true;
    try {
      /**
       * @memo
       * ルート変更時速攻でロードしようとすると、
       * ロードが速攻完了してしまった際に、重たい初期化処理が走ってしまい、
       * typekitのヘビーなフォント捜査処理とバッティングしメインスレッドがブロックされるので、
       * 暫定的にウェイトで凌ぐ
       */
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (this.isDestroyed) {
        this.loading = false;
        return;
      }

      await Promise.all(
        this.items.map((item) => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            const images = ensureArray(item.images);
            if (images.length === 0) {
              return resolve(undefined);
            }
            try {
              await Promise.all(
                images.map(({ image }) => this.loadImageSize(image)),
              );
              resolve(undefined);
            } catch (err) {
              reject(err);
            }
          });
        }),
      );
      await new Promise((resolve) => {
        this.$nextTick(() => {
          this.loaded = true;
          this.loading = false;
          resolve(undefined);
        });
      });
    } catch (err) {
      this.loading = false;
      this.$logger.error(err);
    }
  }

  @Watch('slidesWidth')
  protected slidesWidthChangeHandler(current: number, before: number) {
    if (typeof before !== 'number' || isNaN(before)) {
      return;
    }
    this.updateOffset();
  }

  @Watch('isInview')
  protected isInviewChangeHandler() {
    if (this.isInview) {
      this.outviewResume();
    } else {
      this.outviewPause();
    }
  }

  @Watch('dimensionIsReady', { immediate: true })
  protected dimensionIsReadyChangeHandler() {
    if (this.dimensionIsReady) {
      this.launch();
    }
  }

  @Watch('center', { immediate: true })
  protected centerChangeHandler() {
    if (this.dimensionIsReady) {
      this.updateOffset();
    }
  }

  @Watch('paused', { immediate: true })
  protected pausedChangeHandler() {
    this.internalPaused = this.paused;
  }

  @Watch('isPaused')
  protected isPausedChangeHandler() {
    if (this.isPaused) {
      this.cancelScrollTick();
    } else {
      this.renderingIdleTick(() => {
        this.scrollTick();
      });
    }
  }

  private renderingIdleTick(cb: Function) {
    requestAnimationFrame(() => {
      setTimeout(cb, 0);
    });
  }

  private launch() {
    this.updateOffset();
    this.scrollTick();
  }

  private cancelScrollTick() {
    if (this._tickCancelId !== undefined) {
      cancelAnimationFrame(this._tickCancelId);
      delete this._tickCancelId;
    }
    this.resolveCSSTick();
  }

  private cssTickHandler?: (ev: TransitionEvent) => void;
  private removeCSSTickHandler() {
    const { slides } = this.$refs;
    const { cssTickHandler } = this;
    if (slides && cssTickHandler) {
      slides.removeEventListener('transitionend', cssTickHandler, false);
    }
    delete this.cssTickHandler;
  }

  private resolveCSSTick(x?: number) {
    const { slides } = this.$refs;
    if (slides) {
      if (x === undefined) {
        x = getComputedTranslateXY(slides)[0];
      }
      this.shiftX(x);
      slides.style.transition = '';
      slides.style.transform = '';
    }
    this.removeCSSTickHandler();
  }

  private scrollTick() {
    let lastTime: number = performance.now();
    const tick = this.useCSS
      ? () => {
          const { slides } = this.$refs;

          /**
           * 必用に応じてpropで変更できるようにする
           * 今は基本的に横長の方が多いのでこれが多分一番マッチ
           */
          const tickSize = this.containerDimension.height;
          const per = tickSize / this.computedPxBySecond;
          const ms = Math.round(1000 * per);
          slides.style.transition = `transform ${ms}ms linear`;
          slides.style.transform = `translateX(${-tickSize}px)`;
          this.removeCSSTickHandler();
          this.cssTickHandler = (ev: TransitionEvent) => {
            if (ev.target !== slides || ev.propertyName !== 'transform') return;
            this.resolveCSSTick(-tickSize);

            /**
             * @memo
             * 実行遅延させないと次回tickの transition duration が効かない
             */
            this.renderingIdleTick(tick);
          };
          slides.addEventListener('transitionend', this.cssTickHandler, false);
        }
      : () => {
          this.cancelScrollTick();
          this._tickCancelId = requestAnimationFrame(() => {
            this.cancelScrollTick();
            if (this.isPointing || this.isPaused) {
              return undefined;
            }
            const currentTime = performance.now();
            const diff = currentTime - lastTime;
            lastTime = currentTime;
            this.shiftX(-diff * 0.02);
            tick();
          });
        };
    tick();
  }
}

function getComputedTranslateXY(el: HTMLElement): [number, number] {
  const style = getComputedStyle(el);
  const transform: string =
    style.transform || style.webkitTransform || (style as any).mozTransform;
  if (!transform) return [0, 0];
  let mat = transform.match(/^matrix3d\((.+)\)$/);
  if (mat) throw new Error('matrix3d...');
  mat = transform.match(/^matrix\((.+)\)$/);
  if (!mat) return [0, 0];
  const tmp = mat[1].split(', ');
  const x = parseFloat(tmp[4]);
  const y = parseFloat(tmp[5]);
  return [x, y];
}

export const HInfiniteCarousel = tsx
  .ofType<
    HInfiniteCarouselProps,
    HInfiniteCarouselEmits,
    HInfiniteCarouselScopedSlots
  >()
  .convert(HInfiniteCarouselRef);
