import './HEmbedMap.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HHtmlInjector } from '~/components/HHtmlInjector';

export interface HEmbedMapProps {
  html?: string;
}

export interface HEmbedMapEmits {}

export interface HEmbedMapScopedSlots {}

@Component<HEmbedMapRef>({
  name: 'HEmbedMap',

  render(h) {
    // マップ入力値を正規化する
    return (
      <HHtmlInjector html={this.$props.html} staticClass="v-embed-map-module" />
    );
  },
})
export class HEmbedMapRef extends Vue implements HEmbedMapProps {
  @Prop({ type: String }) readonly html!: string;
}

export const HEmbedMap = tsx
  .ofType<HEmbedMapProps, HEmbedMapEmits, HEmbedMapScopedSlots>()
  .convert(HEmbedMapRef);
