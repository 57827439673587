import './HTimeline.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { HTimelineItem, HTimelineItemProps } from './HTimelineItem';
import { mergeVNodeData } from '~/helpers';

// export interface HTimelineProps {
//   items: HTimelineItemProps[];
//   continue: boolean;
// }

export const HTimeline = tsx.component({
  name: 'HTimeline',
  functional: true,
  props: {
    items: {
      type: Array as PropType<HTimelineItemProps[]>,
      required: true,
    },
    continue: Boolean,
    stack: Boolean,
  },
  render(h, { data, props, scopedSlots }) {
    const { items, continue: hasContinue, stack } = props;
    const { length } = items;

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'h-timeline',
          class: {
            'h-timeline--stack': stack,
          },
        })}>
        {items.map((item, itemIndex) => (
          <HTimelineItem
            key={itemIndex}
            staticClass="h-timeline__item"
            props={{
              ...item,
              continue: hasContinue && itemIndex === length - 1,
            }}
            scopedSlots={{
              default: scopedSlots.item,
            }}
          />
        ))}
      </div>
    );
  },
});
