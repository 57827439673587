import './HHotelToBookingAir.scss';

import * as tsx from 'vue-tsx-support';
import { VNode } from 'vue';
import { mergeVNodeData } from '~/helpers';
import { HBtn, HBtnProps } from '~/components';

export const HHotelToBookingAir = tsx.component<HBtnProps, {}>({
  name: 'HHotelToBookingAir',
  functional: true,
  render(h, { data, props, parent, listeners }): VNode {
    return (
      <HBtn
        {...(mergeVNodeData(data, {
          staticClass: 'h-hotel-to-booking-air',
        }) as any)}
        props={{
          ...props,
          target: props.href ? '_blank' : undefined,
        }}
        on={listeners}
        stackIcon="airplane-outline"
        prependStack
        color="primary-wrap">
        {parent.$t('label.dynamicPacage.airMin')}
      </HBtn>
    );
  },
});
