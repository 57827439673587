import './HLink.scss';

import * as tsx from 'vue-tsx-support';
import { VNode } from 'vue';
import { navigationItemProps } from '~/schemes';
import { createNavigationItemSettings } from '~/helpers';

/**
 * リンク専用コンポーネント
 * アイコン等は一切付きません
 */
export const HLink = tsx.component({
  name: 'HLink',
  functional: true,

  props: {
    ...navigationItemProps(),
  },

  render(h, context) {
    const { tag, data, children } = createNavigationItemSettings(
      context,
      'h-link',
    );

    const { label } = context.props;

    const myChildren: VNode[] = [
      <span staticClass="h-link__label">
        <span staticClass="h-link__label__text">
          {label == null ? children : label}
        </span>
      </span>,
    ];

    return h(tag, data, myChildren);
  },
});
