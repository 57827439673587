import './HVacancyCalendar.scss';

import * as tsx from 'vue-tsx-support';
import { VNode } from 'vue';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
  format as dateFormat,
  startOfMonth,
  isAfter as dateIsAfter,
  isBefore as dateIsBefore,
  isSameDay,
  isSameMonth,
  addDays,
} from 'date-fns';
import {
  CalendarBaseMixin,
  CalendarBaseMixinProps,
  CalendarBaseMixinEmits,
} from './calendar-base-mixin';
import {
  HCalendar,
  HCalendarRef,
  HCalendarDay,
  HCalendarEvent,
} from './HCalendar';
import { YgetsableHotelDetail, VacancyInfo } from '~/schemes';
import { GetMonthlyVacancyCalendarParams } from '~/plugins/api/routes/hotels';
import { HPathIcon, PathIconName, HPrice, HIcon } from '~/components';
import { toDate } from '~/helpers';

export interface HVacancyCalendarBaseConditions {
  adult?: string | number;
  underTwelve?: string | number;
  underSeven?: string | number;
  underFour?: string | number;
  stayLength?: string | number;
}

type MyDateState = 'green' | 'warn' | 'none' | 'close';

interface MyEventData {
  price: number;
  state: MyDateState;
  disabled: boolean;
}

type MyDayInfo = HCalendarDay<MyEventData>;

interface MyCache {
  tag: string;
  vacancies: VacancyInfo[];
}

interface MyResolver {
  promise: Promise<VacancyInfo[] | undefined>;
  tag: string;
  cancel: Function;
  canceled: boolean;
}

export interface HVacancyCalendarProps extends CalendarBaseMixinProps {
  min?: string;
  hotel: YgetsableHotelDetail;
  maxCache?: number;
  conditions: HVacancyCalendarBaseConditions;
}

export interface HVacancyCalendarEmits extends CalendarBaseMixinEmits {}

export interface HVacancyCalendarScopedSlots {}

@Component<HVacancyCalendarRef>({
  name: 'HVacancyCalendar',
  render() {
    const {
      prev,
      next,
      classes,
      months,
      pullNarrow,
      eventsLoader,
      selectedDates,
      baseRate,
      canPrev,
    } = this;
    return (
      <div staticClass="h-vacancy-calendar" class={classes}>
        <div staticClass="h-vacancy-calendar__months">
          {/* スマホのみ */}
          <button
            staticClass="h-vacancy-calendar__months--prev"
            disabled={!canPrev}
            class={{
              'h-vacancy-calendar__months--prev--disabled': !canPrev,
            }}
            onClick={() => {
              prev();
            }}>
            <HIcon
              staticClass="h-vacancy-calendar__months__prev__icon"
              class={{
                'h-vacancy-calendar__months--prev--disabled__icon': !canPrev,
              }}
              name="keyboard-arrow-up"
            />
          </button>

          {months.map((month, monthIndex) => {
            const isPrev = monthIndex === 0;
            const isNext = !isPrev;

            return (
              <HCalendar
                staticClass="h-vacancy-calendar__month"
                class={{
                  'h-vacancy-calendar__month--prev': isPrev,
                  'h-vacancy-calendar__month--next': isNext,
                }}
                key={month}
                ref="calendars"
                refInFor
                month={month}
                pullNarrow={pullNarrow}
                eventsLoader={eventsLoader}
                hasNavigation
                value={selectedDates}
                picker
                onBeforeDestroy={this.onMonthBeforeDestroy}
                onInput={(e) => {
                  this.selectedDates = e;
                }}
                onClickPrev={(ev) => {
                  prev();
                }}
                onClickNext={(ev) => {
                  next();
                }}
                scopedSlots={{
                  day: (day) => {
                    const { selectState } = day;
                    const ev = day.events[0];
                    if (!ev) {
                      return undefined;
                    }
                    const children: VNode[] = [];
                    const { price, state, disabled } = ev.data;

                    let iconName: PathIconName;
                    if (state === 'green') {
                      iconName = 'calendar-green';
                    } else if (state === 'warn') {
                      iconName = 'calendar-warn';
                    } else if (state === 'none') {
                      iconName = 'calendar-empty';
                    } else if (state === 'close') {
                      iconName = 'calendar-closed';
                    } else {
                      throw new Error(`missing vacancy state icon by ${state}`);
                    }

                    children.push(
                      <HPathIcon
                        staticClass="h-vacancy-calendar__date__icon"
                        name={iconName}
                      />,
                    );

                    if (!disabled) {
                      children.push(
                        <HPrice
                          staticClass="h-vacancy-calendar__date__price"
                          class={{
                            'h-vacancy-calendar__date__price--active': [
                              'in',
                              'start',
                              'end',
                            ].includes(selectState),
                          }}
                          base={baseRate}
                          includeAmount
                          kilo>
                          {price}
                        </HPrice>,
                      );
                    }

                    return (
                      <div staticClass="h-vacancy-calendar__date">
                        {children}
                      </div>
                    );
                  },
                }}
              />
            );
          })}
          {/* スマホのみ */}
          <button
            class="h-vacancy-calendar__months--next"
            onClick={() => {
              next();
            }}>
            <HIcon
              staticClass="h-vacancy-calendar__months__next__icon"
              name="keyboard-arrow-down"
            />
          </button>
        </div>
      </div>
    );
  },
})
export class HVacancyCalendarRef
  extends Mixins(CalendarBaseMixin)
  implements HVacancyCalendarProps {
  $refs!: {
    calendars: HCalendarRef[];
  };

  @Prop({ type: String }) readonly min?: string;
  @Prop({ type: Object, required: true }) readonly hotel!: YgetsableHotelDetail;
  @Prop({ type: Number, default: 24 }) readonly maxCache!: number;
  @Prop({ type: Object, required: true })
  readonly conditions!: HVacancyCalendarBaseConditions;

  private caches: MyCache[] = [];
  private resolvers: MyResolver[] = [];

  get months() {
    return [
      dateFormat(this.startDate, 'yyyy-MM-dd'),
      dateFormat(this.nextMonthStartDate, 'yyyy-MM-dd'),
    ];
  }

  get hotelId() {
    return this.hotel.ygets.hotelId;
  }

  get useSearch() {
    return this.hotel.ygets.useSearch;
  }

  get computedBaseConditions(): Omit<
    GetMonthlyVacancyCalendarParams,
    'monthlyDate'
  > {
    const { conditions, hotelId, useSearch } = this;
    const computedBaseConditions: {
      hotelId: string;
      adult?: string;
      underTwelve?: string;
      underSeven?: string;
      underFour?: string;
      stayLength?: string;
    } = {
      hotelId,
      adult: String(conditions.adult),
      underTwelve: String(conditions.underTwelve),
      underSeven: String(conditions.underSeven),
      underFour: String(conditions.underFour),
      stayLength: String(conditions.stayLength),
    };
    if (!useSearch.adult) delete computedBaseConditions.adult;
    if (!useSearch.underTwelve) delete computedBaseConditions.underTwelve;
    if (!useSearch.underSeven) delete computedBaseConditions.underSeven;
    if (!useSearch.underFour) delete computedBaseConditions.underFour;

    return computedBaseConditions;
  }

  get baseRate() {
    return this.hotel.ygets.baseRate;
  }

  get calendarThreashold() {
    return this.hotel.ygets.calendarThreashold;
  }

  get from() {
    const { min } = this;
    const _min = min ? toDate(min) : new Date();
    return dateFormat(startOfMonth(_min), 'yyyy/MM/dd');
  }

  get computedFrom(): Date | null {
    return this.from ? toDate(this.from) : null;
  }

  /**
   * canNext は現状必要ないので必要になったら実装する
   * その際は、SP、PCで一度に表示する月数が異なる場合があるので考慮して実装する
   */
  get canPrev() {
    const { computedFrom: from } = this;
    return (
      !from ||
      (!isSameMonth(this.monthInstance, from) &&
        dateIsAfter(this.monthInstance, from))
    );
  }

  get classes() {
    return {
      'h-vacancy-calendar--can-prev': this.canPrev,
      'h-vacancy-calendar--can-not-prev': !this.canPrev,
    };
  }

  private getVacancies(vm: HCalendarRef, delay: number = 500): MyResolver {
    const monthlyDate = dateFormat(vm.monthInstance, 'yyyy/MM/dd');
    const tag = JSON.stringify({
      date: monthlyDate,
      ...this.computedBaseConditions,
    });
    const resolver: MyResolver = {
      cancel: () => {},
      canceled: false,
      tag,
      promise: null as any,
    };

    resolver.promise = new Promise((resolve, reject) => {
      const catched = this.getCache(monthlyDate);
      if (catched) {
        return resolve(catched.vacancies);
      }

      let timerId: number | null = null;

      resolver.cancel = () => {
        if (timerId !== null) {
          clearTimeout(timerId);
          timerId = null;
        }
        resolver.canceled = true;
        resolve(undefined);
      };

      timerId = window.setTimeout(async () => {
        if (timerId !== null) {
          clearTimeout(timerId);
          timerId = null;
        }

        if (resolver.canceled) {
          return resolve(undefined);
        }

        try {
          const result = await this.$api.hotels.getMonthlyVacancyCalendar({
            ...this.computedBaseConditions,
            monthlyDate,
          });
          const { vacancyList: vacancies } = result;
          const cache: MyCache = {
            tag,
            vacancies,
          };
          this.pushCache(cache);
          resolve(vacancies);
        } catch (_err) {
          const err = this.$error.from(_err);
          this.$alert({
            content: [<div domPropsInnerHTML={err.message} />],
          });
          this.$logger.error(err);
          reject(err);
        }
      }, delay);
    });

    this.resolvers.push(resolver);
    return resolver;
  }

  private pushCache(cache: MyCache) {
    this.caches.push(cache);
    if (this.caches.length > this.maxCache) {
      this.caches.shift();
    }
  }

  private getCache(date: string) {
    const tag = JSON.stringify({
      date,
      ...this.computedBaseConditions,
    });
    return this.caches.find((c) => c.tag === tag);
  }

  private async eventsLoader(vm: HCalendarRef) {
    const vacancies = await this.getVacancies(vm).promise;
    if (!vacancies) {
      return [];
    }
    return vacancies.map((vacancy) => {
      let state: MyDateState = 'green';

      if (vacancy.closedDay) {
        state = 'close';
      } else if (vacancy.vacancy <= 0) {
        state = 'none';
      } else if (vacancy.vacancy <= this.calendarThreashold) {
        state = 'warn';
      }

      const disabled = state === 'none' || state === 'close';
      const data: MyEventData = {
        price: vacancy.charge.searchChargeDetail.unitCharge,
        state,
        disabled,
      };

      return {
        date: vacancy.date,
        data,
      };
    });
  }

  private removeResolver(vm: HCalendarRef) {
    const monthlyDate = dateFormat(vm.monthInstance, 'yyyy/MM/dd');
    const tag = JSON.stringify({
      date: monthlyDate,
      ...this.computedBaseConditions,
    });
    const resolver = this.resolvers.find((r) => r.tag === tag);
    if (resolver) {
      resolver.cancel();
      const index = this.resolvers.indexOf(resolver);
      this.resolvers.splice(index, 1);
    }
  }

  protected onMonthBeforeDestroy(vm: HCalendarRef) {
    this.removeResolver(vm);
  }

  private removeAllResolvers() {
    const resolvers = this.resolvers.slice();
    for (let i = 0, l = resolvers.length; i < l; i++) {
      const resolver = resolvers[i];
      resolver.cancel();
      const index = this.resolvers.findIndex((r) => r === resolver);
      if (index !== -1) {
        this.resolvers.splice(index, 1);
      }
    }
  }

  getAllEvents(): HCalendarEvent<MyEventData>[] {
    const events: HCalendarEvent<MyEventData>[] = [];
    const { calendars } = this.$refs;
    calendars &&
      calendars.forEach((calendar) => {
        calendar.events.forEach((e) => {
          if (!events.find((t) => t.date === e.date)) {
            events.push(e);
          }
        });
      });
    return events;
  }

  /** 選択した日付と選択した宿泊数の合計金額 */
  get totalLowestPrice(): number | null {
    const { from } = this.selectedModel;
    if (!from) return null;

    const events = this.getAllEvents();
    const { stayLength } = this.conditions;

    // リストの中から、選択した日付と同じ日付があったらそのオブジェクトを抜き出してあたらしい定数にする
    const selectedDate = events.find((ev) => {
      const evDate = toDate(ev.date);
      return isSameDay(evDate, from);
    });

    // 指定された期間のデータを保存
    const selectedRangeDateInfo: HCalendarEvent<MyEventData>[] = [];

    if (!selectedDate) return null;
    const date: Date = new Date(selectedDate.date as string);

    // stayLength分の日付を取得
    for (let i = 0; i < (stayLength as number); i++) {
      const _selectedRangeDateInfo = events.find((ev) => {
        const evDate = toDate(ev.date);
        return isSameDay(evDate, addDays(date, i));
      });
      /** 選択した宿泊滞在期間の中に価格が存在しない場合はフッターに価格を表示しない */
      if (!_selectedRangeDateInfo) return null;
      selectedRangeDateInfo.push(_selectedRangeDateInfo);
    }

    // 合計金額算出
    let totalPrice: number = 0;
    for (const item of selectedRangeDateInfo) {
      const { data } = item;
      if (data.state === 'none') {
        return null;
      }
      totalPrice += data.price;
    }

    return totalPrice;
  }

  get allDayInfo(): MyEventData[] {
    const allDayInfo: MyEventData[] = [];
    const { from, to } = this.selectedModel;
    if (from) {
      const events = this.getAllEvents();
      events.forEach((ev) => {
        const evDate = toDate(ev.date);
        if (
          isSameDay(evDate, from) ||
          (to &&
            ((dateIsAfter(evDate, from) && dateIsBefore(evDate, to)) ||
              isSameDay(evDate, to)))
        ) {
          allDayInfo.push(ev.data);
        }
      });
    }
    return allDayInfo;
  }

  @Watch('computedBaseConditions', { deep: true })
  protected conditionsChangeHandler() {
    this.removeAllResolvers();
    const { calendars } = this.$refs;
    calendars &&
      calendars.forEach((calendar) => {
        calendar.reload();
      });
  }
}

export const HVacancyCalendar = tsx
  .ofType<
    HVacancyCalendarProps,
    HVacancyCalendarEmits,
    HVacancyCalendarScopedSlots
  >()
  .convert(HVacancyCalendarRef);
