import {
  TransitionConfig,
  TransitionSettings,
  TransitionState,
  TransitionChangeHandler,
} from './schemes';
import { resolveTransitionOptions } from './helpers';

export class Transition {
  /** トランジション設定 */
  private _settings: TransitionSettings;

  /** トランジション状態 */
  private _state: TransitionState = 'idling';

  /**
   * 現在のトランジションの開始時間 （ms）
   *
   * * 一時停止から再開された際に、この時間は再計算されるので、厳密に開始時間とはなりません
   */
  private _startTime = 0;

  /** トランジションの現在時間（ms） */
  private _currentTime = 0;

  /** 現在の進捗  0 - 1 */
  private _progress = 0;

  /** イージング計算済みの現在の進捗  0 - 1 */
  private _computedProgress = 0;

  /** トランジション状態更新時のハンドラ */
  private _onChange?: TransitionChangeHandler;

  /** イージング関数 */
  get easing() {
    return this._settings.easing;
  }

  /** トランジション時間（ms） */
  get duration() {
    return this._settings.duration;
  }

  /** トランジション状態 */
  get state() {
    return this._state;
  }

  /** トランジションの現在時間（ms） */
  get currentTime() {
    return this._currentTime;
  }

  /** 現在の進捗  0 - 1 */
  get progress() {
    return this._progress;
  }

  /** イージング計算済みの現在の進捗  0 - 1 */
  get computedProgress() {
    return this._computedProgress;
  }

  /** アイドリング中 */
  get isIdling() {
    return this._state === 'idling';
  }

  /** 遷移中 */
  get isRunning() {
    return this._state === 'running';
  }

  /** 一時停止中 */
  get isPaused() {
    return this._state === 'paused';
  }

  /** 破棄済み */
  get isAborted() {
    return this._state === 'aborted';
  }

  /** 遷移完了 */
  get isDone() {
    return this._progress === 1;
  }

  constructor(options?: TransitionConfig) {
    this._settings = resolveTransitionOptions(options);
    this._onChange = options && options.onChange;
    this._step = this._step.bind(this);
  }

  /**
   * 現在時間をセットする
   *
   * @param currentTime - 現在時間
   */
  setCurrentTime(currentTime: number, withEmit = true) {
    const { duration } = this;

    if (currentTime < 0) {
      currentTime = 0;
    } else if (currentTime > duration) {
      currentTime = duration;
    }

    if (this._currentTime === currentTime) return;

    this._progress = Math.min(currentTime / duration, 1);
    this._computedProgress = this.easing(this._progress);
    withEmit && this._emit();
  }

  /**
   * 時間をリセットする
   *
   * @param currentTime - トランジションの経過時間（ms）
   */
  private _resetTimes(currentTime = 0) {
    const startTime = performance.now();
    this._startTime = startTime;
    this.setCurrentTime(currentTime, false);
  }

  /**
   * トランジションを開始する
   *
   * @param currentTime - 途中から開始する場合の、トランジションの経過時間（ms）
   */
  start(currentTime = 0) {
    if (this.isRunning || this.isAborted) return;

    this._resetTimes(currentTime);

    this._setState('running');
    window.requestAnimationFrame(this._step);
  }

  /**
   * トランジションを一時停止する
   */
  pause() {
    if (!this.isRunning || this.isAborted) return;
    this._setState('paused');
  }

  /**
   * トランジションを再開する
   */
  resume() {
    if (!this.isPaused || this.isAborted) return;
    return this.start(this._currentTime);
  }

  /**
   * トランジションを破棄する
   */
  abort() {
    this._setState('aborted');
  }

  /**
   * トランジション時間のアニメーションフレームハンドラ
   * @param timestamp
   */
  private _step(timestamp: number) {
    if (!this.isRunning) return;

    const currentTime = timestamp - this._startTime;

    this.setCurrentTime(currentTime);

    if (currentTime < this.duration) {
      window.requestAnimationFrame(this._step);
    }
  }

  /**
   * 現在の状態をハンドラに通知する
   */
  private _emit() {
    this._onChange && this._onChange(this);
  }

  /**
   * 状態をセットする
   * @param state - トランジション状態
   */
  private _setState(state: TransitionState) {
    if (this._state === state) return;
    this._state = state;
    this._emit();
  }
}
