const PREVIEW_JUDGE_RE = /preview\.([a-z\d]+-)?hr-acco-cms\.hoshinoresorts\.systems/;

/**
 * サーバへのリクエストがプレビュー用のリクエストかどうかを判定する
 */
export function isPreviewHost(host: string, isDev: boolean) {
  if (host == null) return false;

  if (isDev) {
    // @MEMO 完全にここ依存で、もし向こうな設定が変更されたらちゃんと動作しないので注意
    // https://github.com/HoshinoResort/acco-cms/blob/4f5bc651158db97cc937aa59b6b5b590ce2eea3e/.env.default#L8
    return host === 'localhost:3100';
  }

  // @example
  // https://preview.test-hr-acco-cms.hoshinoresorts.systems/ja/hotels/kaikinugawa/
  return PREVIEW_JUDGE_RE.test(host);
}
