import './HHtmlInjector.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

const stylesMatchRE = /<[\s\t\r\n]*style[\s\t\r\n]*>([\s\S]*?)<[\s\t\r\n]*\/[\s\t\r\n]*style[\s\t\r\n]*>/gi;
const styleMatchRE = /<[\s\t\r\n]*style[\s\t\r\n]*>([\s\S]*?)<[\s\t\r\n]*\/[\s\t\r\n]*style[\s\t\r\n]*>/i;
const cssCommentRE = /\/\*[^*]*\*+([^/*][^*]*\*+)*\//g;
const formulasRE = /\{\{(.+?)\}\}/g;
const formulaRE = /\{\{(.+?)\}\}/;
const cssScopeSelectorRe = /(^|\s)(:host)(\s|\{)/g;

export interface HHtmlInjectorParsedInfo {
  styles: string[];
  html: string;
}

export interface HHtmlInjectorProps {
  html?: string;
}

export interface HHtmlInjectorEmits {}

export interface HHtmlInjectorScopedSlots {}

@Component<HHtmlInjectorRef>({
  name: 'HHtmlInjector',

  head() {
    return {
      style: this.headStyle,
    };
  },

  render(h) {
    const { parsedInfo } = this;
    return h('div', {
      staticClass: 'h-html-injector',
      attrs: {
        [this.cssScope]: '',
      },
      domProps: {
        innerHTML: (parsedInfo && parsedInfo.html) || '',
      },
    });
  },

  mounted() {
    // タグライン内にテーマアクセントテキストが含まれているかもしれないのでエフェクトを適用しておく
    this.$theme.applyEffect(this);
  },
  beforeDestroy() {
    this.$theme.removeEffect(this);
  },
})
export class HHtmlInjectorRef extends Vue implements HHtmlInjectorProps {
  @Prop({ type: String }) readonly html!: string;

  get uid(): number {
    return (this as any)._uid;
  }

  get cssScope(): string {
    return `h-html-injector-${this.uid}`;
  }

  get headStyle() {
    const { parsedInfo } = this;
    if (!parsedInfo || parsedInfo.styles.length === 0) {
      // undefinedを返却してしまうと、
      // 返却前のCSS設定が全てなかったことになってしまうので
      // 空配列を返却
      return [];
    }
    return parsedInfo.styles.map((style) => {
      return { cssText: style, type: 'text/css' };
    });
  }

  get parsedInfo(): HHtmlInjectorParsedInfo | undefined {
    let { html } = this;
    if (!html) {
      return undefined;
    }

    const formulaMatchs = html.match(formulasRE);
    formulaMatchs &&
      formulaMatchs.forEach((formulaMatch) => {
        const match = formulaMatch.match(formulaRE);
        let formula = match && match[1];
        if (!formula) return;

        formula = `return (${formula.trim().replace(/;$/, '')})`;

        // eslint-disable-next-line no-new-func
        const func = new Function(formula);
        const result = func.call(this);
        html = html.replace(formulaMatch, result);
      });

    const cssTexts: string[] = [];

    const stylesMatch = html.match(stylesMatchRE);
    if (stylesMatch) {
      const { cssScope } = this;
      stylesMatch.forEach((style) => {
        const match = style.match(styleMatchRE);
        if (match && match[1]) {
          const cssText = ((match && match[1]) || '')
            .replace(cssCommentRE, '')
            .replace(cssScopeSelectorRe, `$1[${cssScope}]$3`);
          cssTexts.push(cssText.trim());
          html = html.replace(match[0], '');
        }
      });
    }

    return {
      html: html.trim(),
      styles: cssTexts.filter((css) => !!css),
    };
  }
}

export const HHtmlInjector = tsx
  .ofType<HHtmlInjectorProps, HHtmlInjectorEmits, HHtmlInjectorScopedSlots>()
  .convert(HHtmlInjectorRef);
