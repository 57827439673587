import './HSimpleFilterForm.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HBtn } from '../HBtn';
import { HPathIcon } from '../HPathIcon';
import { HForm } from '~/components';

export interface HSimpleFilterFormProps {
  /** フィルタ済み件数 */
  count: number;
}

export interface HSimpleFilterFormEmits {
  /** リセットボタンクリック時 */
  onClickReset: MouseEvent;
}

export interface HSimpleFilterFormSlotParams {
  /**
   * 横並びのセル上にフォーム要素をレンダリングするヘルパ
   * @param nodes - フォーム要素のリスト
   */
  renderCells(nodes: VNode[]): VNode;
}

export interface HSimpleFilterFormScopedSlots {
  default?: HSimpleFilterFormSlotParams;
}

@Component<HSimpleFilterFormRef>({
  name: 'HSimpleFilterForm',
  render() {
    const { count, $scopedSlots, $listeners } = this;

    /** リセットボタンクリックハンドラが設定されているか */
    const hasClickReset = !!$listeners.clickReset;

    /** bodyの子要素を取得する */
    const defaultSlot = $scopedSlots.default;
    const children =
      defaultSlot && defaultSlot({ renderCells: this.renderCells });

    return (
      <HForm staticClass="h-simple-filter-form">
        <div staticClass="h-simple-filter-form__inner">
          <div staticClass="h-simple-filter-form__body">{children}</div>
          <div staticClass="h-simple-filter-form__footer">
            <span
              staticClass="h-simple-filter-form__count"
              domPropsInnerHTML={`<span class="h-simple-filter-form__count__value">${this.$tc(
                'value.resultCount',
                count,
              )}</span>`}
            />

            {/** リセットボタン用ハンドラがある場合にのみ、リセットボタンを設置 */}
            {!!hasClickReset && (
              <HBtn
                staticClass="h-simple-filter-form__reset"
                onClick={this.hanldeClickReset}
                size="sm">
                <HPathIcon
                  staticClass="h-simple-filter-form__reset__icon"
                  name="x-mark"
                />
                <span staticClass="h-simple-filter-form__reset__label">
                  {this.$t('action.reset')}
                </span>
              </HBtn>
            )}
          </div>
        </div>
      </HForm>
    );
  },
})
export class HSimpleFilterFormRef
  extends Vue
  implements HSimpleFilterFormProps {
  @Prop({ type: Number, required: true }) readonly count!: number;

  /**
   * 横並びのセル上にフォーム要素をレンダリングするヘルパ
   * @param nodes - フォーム要素のリスト
   *
   * @see {@link HSimpleFilterFormSlotParams.renderCells}
   */
  renderCells(nodes: VNode[]): VNode {
    return this.$createElement(
      'div',
      {
        staticClass: 'h-simple-filter-form__cells',
      },
      nodes.map((node, index) =>
        this.$createElement(
          'div',
          {
            key: node.key || index,
            staticClass: 'h-simple-filter-form__cells__child',
          },
          [node],
        ),
      ),
    );
  }

  /** リセットボタンクリック時のハンドラ */
  private hanldeClickReset(ev: MouseEvent) {
    this.$emit('clickReset', ev);
  }
}

export const HSimpleFilterForm = tsx
  .ofType<
    HSimpleFilterFormProps,
    HSimpleFilterFormEmits,
    HSimpleFilterFormScopedSlots
  >()
  .convert(HSimpleFilterFormRef);
