import bezier from 'bezier-easing';
import { easings } from './easings';
import {
  EasingFunction,
  EasingSource,
  DEFAULT_EASING,
  TransitionOptions,
  TransitionSettings,
  DEFAULT_DURATION,
} from './schemes';

/**
 * イージング設定をイージング関数に正規化する
 *
 * @param source - イージング設定
 * @returns イージング関数
 */
export function resolveEasingSource(
  source: EasingSource = DEFAULT_EASING,
): EasingFunction {
  if (typeof source === 'function') return source;
  const values = typeof source === 'string' ? easings[source] : source;
  return bezier.apply(bezier, values);
}

/**
 * トランジションオプションをトランジション設定に正規化する
 *
 * @param source - トランジションオプション
 * @returns トランジション設定
 */
export function resolveTransitionOptions(
  options: TransitionOptions = {},
): TransitionSettings {
  const { easing, duration = DEFAULT_DURATION } = options;
  return {
    easing: resolveEasingSource(easing),
    duration,
  };
}
