import './HSimpleGalleryItem.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HMediaOverlayCaption } from '../HMediaOverlayCaption';
import type { HSimpleGalleryRef } from './HSimpleGallery';
import { loadImageSize } from '~/helpers';
import { MediaInfoMeta } from '~/schemes';

export interface HSimpleGalleryItemProps {
  /**
   * アイテムリスト内でのリスト内インデックス
   */
  index: number;

  /** 画像URL */
  url: string;

  /**
   * モバイルブレイクポイント時専用の画像を利用したい時のみ設定する
   */
  narrowURL?: string;

  /** メタ情報 */
  meta?: MediaInfoMeta;

  /**
   * 親コンポーネントが保持するコンテンツが偶数番目から示すフラグ
   */
  isEven?: boolean;
}

/**
 * ロード状態
 *
 * - `"pending"` まだロードが開始していない
 * - `"loading"` ロード中
 * - `"loaded"` ロード完了
 * - `"error"` ロード失敗
 */
export type HSimpleGalleryItemState =
  | 'pending'
  | 'loading'
  | 'loaded'
  | 'error';

export interface HSimpleGalleryItemEmits {
  onChangeState: HSimpleGalleryItemState;
}

export interface HSimpleGalleryItemScopedSlots {}

@Component<HSimpleGalleryItemRef>({
  name: 'HSimpleGalleryItem',
  inject: ['gallery'],
  render() {
    return (
      <div
        staticClass="h-simple-gallery-item"
        class={[
          `h-simple-gallery-item--${this.state}`,
          {
            'h-simple-gallery-item--active': this.isActive,
          },
        ]}
        style={this.styles}>
        {this.meta && this.meta.caption && (
          <HMediaOverlayCaption
            modelValue={{
              caption: this.meta.caption,
              position: this.isEven ? 'right' : 'left',
            }}
          />
        )}
        <div staticClass="h-simple-gallery-item__node" />
      </div>
    );
  },
})
export class HSimpleGalleryItemRef
  extends Vue
  implements HSimpleGalleryItemProps {
  readonly gallery!: HSimpleGalleryRef;

  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop({ type: String, required: true }) readonly url!: string;
  @Prop(String) readonly narrowURL?: string;
  @Prop({ type: Object }) readonly meta?: MediaInfoMeta;
  @Prop({ type: Boolean }) readonly isEven?: boolean;

  /**
   * ロード状態
   *
   * @see {@link HSimpleGalleryItemState}
   */
  private internalState: HSimpleGalleryItemState = 'pending';

  /** 画像ロードプロミスインスタンス */
  private _loadPromise?: Promise<void>;

  /**
   * ロード状態
   *
   * @see {@link HSimpleGalleryItemState}
   */
  get state() {
    return this.internalState;
  }

  /**
   * 現在アクティブ（選択されている）か
   */
  get isActive() {
    return this.gallery.currentIndex === this.index;
  }

  /**
   * ブレイクポイントのマッチ状況を考慮した現在アクティブな画像のURL
   */
  get activeURL() {
    const { url, narrowURL } = this;
    if (!narrowURL) return url;
    return this.$mq.match.narrow ? narrowURL : url;
  }

  /**
   * ホスト要素に適用するスタイル
   */
  get styles() {
    const { isLoaded, url, narrowURL } = this;
    if (!isLoaded) return;

    return {
      '--wide-image': `url(${url})`,
      '--narrow-image': `url(${narrowURL || url})`,
    };
  }

  /** まだロードが開始していないか */
  get isPending() {
    return this.state === 'pending';
  }

  /** ロード中か */
  get isLoading() {
    return this.state === 'loading';
  }

  /** ロードが完了しているか */
  get isLoaded() {
    return this.state === 'loaded';
  }

  /** ロードが失敗しているか */
  get isError() {
    return this.state === 'error';
  }

  /**
   * アクティブなURLをロードする
   *
   * * ロード中やロード済みの場合はそのプロミスインスタンスを再利用する
   */
  load() {
    if (this._loadPromise) return this._loadPromise;
    this.setState('loading');
    this._loadPromise = loadImageSize(this.activeURL)
      .then(() => {
        this.setState('loaded');
      })
      .catch((err) => {
        delete this._loadPromise;
        this.setState('error');
        throw err;
      });
    return this._loadPromise;
  }

  /**
   * ロード状態を設定する
   * @param state - ロード状態
   */
  private setState(state: HSimpleGalleryItemState) {
    if (this.internalState === state) return;
    this.internalState = state;
    this.$emit('changeState', state);
  }
}

export const HSimpleGalleryItem = tsx
  .ofType<
    HSimpleGalleryItemProps,
    HSimpleGalleryItemEmits,
    HSimpleGalleryItemScopedSlots
  >()
  .convert(HSimpleGalleryItemRef);
