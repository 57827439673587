import { defineThemeEffect } from './schemes';

/**
 * テーマアクセントテキスト要素を抽出するためのセレクタ
 */
const TARGET_SELECTOR =
  '.h-theme-accent-text, [style*="var(--theme-accent-color)"]';

/**
 * リゾナーレ用のアクセントテキストのアニメーションを処理するためのエフェクトクラス
 */
class RisonareEffect {
  /** エフェクト適用された要素 */
  readonly el: Element;

  constructor(el: Element) {
    this.el = el;
    const observer = RisonareEffect.getObserver();
    observer.observe(el);
    RisonareEffect.instances.push(this);
  }

  /**
   * 交差状態の変更を検知した時のハンドラ
   */
  onIntersectionChange(entry: IntersectionObserverEntry) {
    entry.isIntersecting && this.boot();
  }

  /**
   * アニメーションを起動して、インスタンスを終了する
   */
  boot() {
    this.el.classList.add('h-theme-accent-text--booted');
    this.remove();
  }

  /**
   * インスタンスを終了して、インスタンスリストから抜ける
   */
  remove() {
    const { el } = this;
    if (!el) return;

    delete (this as any).el;

    const observer = RisonareEffect.getObserver();
    observer.unobserve(el);

    RisonareEffect.instances = RisonareEffect.instances.filter(
      (effect) => effect !== this,
    );
  }

  /**
   * 画面交差を検知するためのオブザーバーインスタンス
   *
   * * 全てのエフェクトインスタンスで共有する
   */
  static _observer?: IntersectionObserver;

  /**
   * エフェクトが適用されているインスタンスのリスト
   */
  static instances: RisonareEffect[] = [];

  /**
   * 交差検知用オブザーバのインスタンスを取得する
   *
   * * まだインスタンスが未生成の場合、一度だけ作成する
   */
  static getObserver() {
    if (!this._observer) {
      this._observer = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            const effect = RisonareEffect.getInstance(entry.target);
            effect && effect.onIntersectionChange(entry);
          }
        },
        {
          root: null,
          rootMargin: '-10% 0px',
          threshold: 0,
        },
      );
    }
    return this._observer;
  }

  /**
   * 指定の要素に対応するエフェクトインスタンスを取得する
   * @param target - 検査したい要素
   * @returns 見つかった場合エフェクトのインスタンス
   */
  static getInstance(target: Element) {
    return this.instances.find((effect) => effect.el === target);
  }

  /**
   * 指定の要素にエフェクトを適用する
   * @param el - 適用したい要素
   * @returns RisonareEffectインスタンス
   */
  static apply(el: Element) {
    return new RisonareEffect(el);
  }

  /**
   * 指定の要素からエフェクトを解放する
   * @param el - 解放したい要素
   */
  static remove(el: Element) {
    const effect = RisonareEffect.getInstance(el);
    effect && effect.remove();
  }
}

/**
 * リゾナーレのテーマアクセントテキストをキランとさせるためのエフェクト
 *
 * @see https://hr-dev.backlog.jp/view/ACCO_CMS-1049
 */
export const accentText = defineThemeEffect({
  apply: (target, service) => {
    // リゾナーレじゃなければ何もしない
    if (!service.is('risonare')) return;

    //
    const texts = target.querySelectorAll(TARGET_SELECTOR);
    if (!texts.length) return;

    texts.forEach((text) => {
      RisonareEffect.apply(text);
    });
  },
  remove: (target, service) => {
    const texts = target.querySelectorAll(TARGET_SELECTOR);
    if (!texts.length) return;

    texts.forEach((text) => {
      RisonareEffect.remove(text);
    });
  },
});
