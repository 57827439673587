import './HFormControl.scss';
import { CreateElement, VNode, VNodeChildren } from 'vue';
import { Component, Prop, Model, Mixins, Watch } from 'vue-property-decorator';
import { createErrorMessageNode } from '../utils';
import {
  HFormValue,
  HFormValueProps,
  HFormValueEmits,
  HFormValueScopedSlots,
} from './HFormValue';

export interface HFormControlProps extends HFormValueProps {
  inputValue?: any;
  multiple?: boolean;
  center?: boolean;
  hint?: string;
  hintToLabel?: boolean;
  hintToLabelOverflow?: boolean;
  hideDetails?: boolean;
  ensureDetailsHeight?: boolean;
  labelForTop?: boolean;
}

export interface HFormControlEmits extends HFormValueEmits {
  onInput: any;
}

export interface HFormControlScopedSlots extends HFormValueScopedSlots {
  label?: HFormControl;
  hint?: HFormControl;
}

@Component<HFormControl>({
  name: 'HFormControl',
  render() {
    const { $scopedSlots, labelForTop, hint, hintToLabel, firstError } = this;
    let { displayLabel } = this;
    if (!labelForTop) displayLabel = undefined;
    const { hint: hintSlot } = $scopedSlots;

    let displayHint: VNodeChildren | undefined;

    if (hintSlot) {
      displayHint = hintSlot(this);
    } else if (hint) {
      displayHint = hint;
    }

    const children: VNode[] = [];
    if (displayLabel || (displayHint && hintToLabel)) {
      const labelChildren: VNode[] = [
        <label
          staticClass="h-form-control__label__inner"
          onClick={(ev) => {
            if (!this.isDisabled) {
              this.focus();
            }
          }}>
          {displayLabel}
        </label>,
      ];
      if (displayHint && hintToLabel) {
        labelChildren.push(
          <span staticClass="h-form-control__label__hint">
            <i domPropsInnerHTML={'&nbsp'} />
            <span
              staticClass="h-form-control__label__hint__inner"
              class={{
                'h-form-control__label__hint__inner--overflow': this
                  .hintToLabelOverflow,
              }}>
              {displayHint}
            </span>
          </span>,
        );
      }

      children.push(
        <div staticClass="h-form-control__label">{labelChildren}</div>,
      );
    }
    children.push(
      <div staticClass="h-form-control__body">{this.genBodyChildren()}</div>,
    );

    const messages: VNode[] = [];
    const errorNode = createErrorMessageNode(this, firstError);
    if (errorNode) {
      messages.push(errorNode);
    } else if (displayHint && !hintToLabel) {
      messages.push(
        <div staticClass="h-form-control__message h-form-control__message--hint">
          {displayHint}
        </div>,
      );
    }

    if (!this.hideDetails) {
      children.push(
        <div staticClass="h-form-control__messages">{messages}</div>,
      );
    }

    return (
      <div staticClass="h-form-control" class={this.controlClasses}>
        {children}
      </div>
    );
  },
})
export class HFormControl
  extends Mixins(HFormValue)
  implements HFormControlProps {
  @Model('input') readonly inputValue!: any;
  @Prop({ type: Boolean }) readonly multiple!: boolean;
  @Prop({ type: Boolean }) readonly center!: boolean;
  @Prop({ type: String }) readonly hint?: string;
  @Prop({ type: Boolean }) readonly hintToLabel!: boolean;
  @Prop({ type: Boolean }) readonly hintToLabelOverflow!: boolean;
  @Prop({ type: Boolean }) readonly hideDetails!: boolean;
  @Prop({ type: Boolean }) readonly ensureDetailsHeight!: boolean;
  @Prop({ type: Boolean, default: true }) readonly labelForTop!: boolean;

  protected internalValue: any = this.inputValue;

  get value() {
    return this.internalValue;
  }

  set value(value: any) {
    if (this.internalValue !== value) {
      this.internalValue = value;
      this.$emit('input', value);
      this.touched = true;
    }
  }

  @Watch('inputValue', { immediate: true })
  protected onChangeInputValueHandler(inputValue: any) {
    this.internalValue = inputValue;
  }

  get controlClasses() {
    return {
      'h-form-control--focused': this.focused,
      'h-form-control--center': this.center,
      'h-form-control--has-error': this.hasError,
      'h-form-control--disabled': this.isDisabled,
      'h-form-control--noaction': !this.canOperation,
      'h-form-control--ensure-details-height': this.ensureDetailsHeight,
    };
  }

  get displayLabel(): VNodeChildren | undefined {
    const { label } = this;
    const { label: labelSlot } = this.$scopedSlots;
    let displayLabel: VNodeChildren | undefined;
    if (labelSlot) {
      displayLabel = labelSlot(this);
    } else if (label) {
      displayLabel = label;
    }
    return displayLabel;
  }

  protected _getValue() {
    return this.value;
  }

  protected genBodyChildren(): VNode[] | undefined {
    return undefined;
  }
}
