import './HRestaurantList.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { HRestaurantListItem } from './HRestaurantListItem';
import {
  Restaurant,
  RestaurantCommons,
  HotelBasicInfo,
  RestaurantLayoutType,
} from '~/schemes';
import { mergeVNodeData } from '~/helpers';

export interface HRestaurantListProps {
  commons: RestaurantCommons;
  items: Restaurant[];
  hotel: HotelBasicInfo;
  dumpId: boolean;
  layoutType: RestaurantLayoutType;
}

export const HRestaurantList = tsx.component({
  name: 'HRestaurantList',
  functional: true,
  props: {
    commons: {
      type: Object as PropType<RestaurantCommons>,
      required: true,
    },
    items: {
      type: Array as PropType<Restaurant[]>,
      required: true,
    },
    hotel: {
      type: Object as PropType<HotelBasicInfo>,
      required: true,
    },
    dumpId: {
      type: Boolean,
    },
    layoutType: {
      type: String as PropType<RestaurantLayoutType>,
    },
  },
  render(h, { data, props }) {
    const { commons, items, hotel, dumpId, layoutType } = props;

    return (
      <ul
        {...mergeVNodeData(data, {
          staticClass: 'h-restaurant-list',
        })}>
        {items.map<VNode>((item) => {
          const id = dumpId ? `item-${item.id}` : undefined;
          return (
            <HRestaurantListItem
              key={item.id}
              staticClass="h-restaurant-list__item"
              id={id}
              commons={commons}
              data={item}
              hotel={hotel}
              layoutType={layoutType}
            />
          );
        })}
      </ul>
    );
  },
});
