import { safeJSONStringify } from './helpers/json';

/**
 * ログレベルのリスト
 */
export const LOG_LEVELS = ['debug', 'info', 'warn', 'error'] as const;

/**
 * ログレベル
 */
export type LogLevel = typeof LOG_LEVELS[number];

/**
 * ロガーの設定
 */
export interface LoggerSettings {
  /**
   * ログ出力の際の閾値
   *
   * これが "info" に設定されていた場合に、 "debug" ログは出力がキャンセルされる
   */
  threshold: LogLevel;

  /**
   * ログ出力をプレーンstringに変換するか
   */
  plain?: boolean;
}

/**
 * ログレベルとConsole APIのメソッドとのマッピング
 */
const LOG_TYPE_MAP: Record<LogLevel, 'log' | 'warn' | 'error'> = {
  debug: 'log',
  info: 'log',
  warn: 'warn',
  error: 'error',
};

/**
 * ロガーを生成する
 *
 * @param settings - ロガー設定
 * @returns
 */
export function createLogger(settings: LoggerSettings) {
  const { threshold, plain } = settings;
  const thresholdIndex = LOG_LEVELS.indexOf(threshold);

  function logger(level: LogLevel, ...messages: any[]) {
    const index = LOG_LEVELS.indexOf(level);
    if (index < thresholdIndex) return;

    if (plain) {
      // plain設定がされていた時は、stringに変換して出力する
      const json = {
        level,
        args: messages,
      };

      let payload: any;

      try {
        payload = safeJSONStringify(json);
      } catch (_err) {
        // JSON文字列変換に失敗したら、そのまま標準出力へ
        payload = json;
      }

      // eslint-disable-next-line no-console
      console.log(payload);
      return;
    }

    const type = LOG_TYPE_MAP[level];

    // eslint-disable-next-line no-console
    console[type](...messages);
  }

  /**
   * デバッグログ
   *
   * @param messages - メッセージ
   * @returns
   */
  logger.debug = function debug(...messages: any[]) {
    return logger('debug', ...messages);
  };

  /**
   * 情報ログ
   *
   * @param messages - メッセージ
   * @returns
   */
  logger.info = function info(...messages: any[]) {
    return logger('info', ...messages);
  };

  /**
   * 警告ログ
   *
   * @param messages - メッセージ
   * @returns
   */
  logger.warn = function warn(...messages: any[]) {
    return logger('warn', ...messages);
  };

  /**
   * エラーログ
   *
   * @param messages - メッセージ
   * @returns
   */
  logger.error = function error(...messages: any[]) {
    return logger('error', ...messages);
  };

  return logger;
}

/**
 * ロガー
 */
export type Logger = ReturnType<typeof createLogger>;
