import Vue from 'vue';
import { Context, Plugin, NuxtAppOptions } from '@nuxt/types';
import {
  ThemeEffectTarget,
  resolveThemeEffectTarget,
  themeEffects,
  setThemeEffectApplyed,
  isThemeEffectApplyed,
} from './effects';
import { GFTheme } from '~/schemes';
import { removeHTMLTag } from '~/helpers';

declare module 'vue/types/vue' {
  export interface Vue {
    $theme: ThemeService;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $theme: ThemeService;
  }
}

declare module '@nuxt/types' {
  export interface NuxtAppOptions {
    $theme: ThemeService;
  }

  export interface Context {
    $theme: ThemeService;
  }
}

export class ThemeService {
  readonly context: Context;

  get current(): GFTheme {
    return this.context.store.getters.theme;
  }

  constructor(context: Context) {
    this.context = context;
  }

  /**
   * 指定の要素にテーマ個別のエフェクトを適用する
   *
   * @param target - テーマエフェクトを適用する対象
   */
  applyEffect(target: ThemeEffectTarget) {
    const el = resolveThemeEffectTarget(target);
    if (!el || isThemeEffectApplyed(el)) return;

    for (const effect of themeEffects) {
      effect.apply(el, this);
    }

    setThemeEffectApplyed(el, true);
  }

  /**
   * 指定の要素からテーマ個別のエフェクト処理を解放する
   *
   * @param target - テーマエフェクトを解放する対象
   */
  removeEffect(target: ThemeEffectTarget) {
    const el = resolveThemeEffectTarget(target);

    if (!el || !isThemeEffectApplyed(el)) return;

    for (const effect of themeEffects) {
      if (effect.remove) {
        effect.remove(el, this);
      }
    }

    setThemeEffectApplyed(el, false);
  }

  is(theme: string) {
    return this.current.name === theme;
  }

  /**
   * 指定のセクションキーに対応するセクションを取得する
   *
   * @param sectionKey - セクションキー
   * @returns テーマセクションオブジェクト
   */
  getSection(sectionKey: string) {
    return this.current.sections.find((section) => section.key === sectionKey);
  }

  /**
   * 指定のセクションキーに対応するセクションラベルを取得する
   *
   * @param sectionKey - セクションキー
   * @param defaultValue - セクションが見つからなかった場合のデフォルト値（未指定の場合空文字列）
   * @returns ラベル文字列（HTML）
   */
  getSectionLabel<D = string>(
    sectionKey: string,
    defaultValue: D = ('' as unknown) as D,
  ): string | D {
    const section = this.getSection(sectionKey);
    return (section && section.label) || defaultValue;
  }

  /**
   * 指定のセクションキーに対応するセクションの長いラベルを取得する
   *
   * @param sectionKey - セクションキー
   * @param defaultValue - セクションが見つからなかった場合のデフォルト値（未指定の場合空文字列）
   * @returns 長いラベル文字列（HTML）
   */
  getSectionLongLabel<D = string>(
    sectionKey: string,
    defaultValue: D = ('' as unknown) as D,
  ): string | D {
    const section = this.getSection(sectionKey);
    if (!section) return defaultValue;
    const { longLabel, label } = section;
    return label || longLabel || defaultValue;
  }

  /**
   * 指定のセクションキーに対応するセクションの長いラベルからHTMLタグを除去した文字列を取得する
   *
   * @param sectionKey - セクションキー
   * @param defaultValue - セクションが見つからなかった場合のデフォルト値（未指定の場合空文字列）
   * @returns 長いラベル文字列（HTML文字除去済み）
   */
  getSectionLongLabelWithoutHTML<D = string>(
    sectionKey: string,
    defaultValue: D = ('' as unknown) as D,
  ): string | D {
    const str = this.getSectionLongLabel(sectionKey, defaultValue);
    return typeof str === 'string' ? removeHTMLTag(str) : str;
  }
}

const plugin: Plugin = (context, inject) => {
  const service = new ThemeService(context);
  context.$theme = service;
  inject('theme', service);
};

export default plugin;
