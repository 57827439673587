import { GFErrorSourceObject } from '../schemes';
import { extractAxiosErrorResponse } from './axios';
import { extractAWSErrorResponse } from './aws';

const resolvers = [extractAxiosErrorResponse, extractAWSErrorResponse] as const;

/**
 * 例外情報からaxiosとかaws-sdk的なそういうやつらのエラー情報を（あれば）抽出する
 *
 * @param source - 例外
 * @returns
 */
export function extractCustomErrorSource(
  source: unknown,
): GFErrorSourceObject | undefined {
  for (const resolver of resolvers) {
    const extracted = resolver(source);
    if (extracted) return extracted;
  }
}
