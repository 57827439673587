import { differenceInCalendarYears } from 'date-fns';

/** 星野リゾートの設立年月日（日本時間を指定） */
const HR_ESTABLISHMENT_DATE = new Date('1914-01-01T00:00:00+09:00');

/**
 * 周年計算
 * @param currentDate レンダリング年月日
 * @returns 周年
 */
export function calculateAnniversary(currentDate: string): number {
  /** レンダリング年月日 */
  const current = new Date(currentDate);

  const years = differenceInCalendarYears(current, HR_ESTABLISHMENT_DATE);

  return years;
}
