import './HTimelineItem.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ScopedSlotChildren } from 'vue/types/vnode';
import { HBtn } from '../HBtn';
import { HImg } from '../HImg';
import { HClockIcon } from '../HClockIcon';
import { HExpandTransition } from '../transitions';
import { HIcon, HIconLink } from '../HIcon';

export interface HTimelineItemProps {
  /** 時間 */
  time?: string | null | Date;
  title?: string | null;
  description?: string | null;
  image?: string | null;
  link?: {
    url: string;
    text: string;
  } | null;
  continue?: boolean;
}

export interface HTimelineItemEmits {}

export interface HTimelineItemScopedSlots {}

@Component<HTimelineItemRef>({
  name: 'HTimelineItem',
  render(h) {
    const { time, title, description, image, link } = this;
    const children: VNode[] = [];

    const bodyChildren: VNode[] = [
      <div staticClass="h-timeline-item__wrapper" onClick={this.open}>
        <div staticClass="h-timeline-item__image-wrapper">
          {/* 画像があれば表示し、なければグレーの四角形を表示 */}
          {image ? (
            <HImg
              class={[
                'h-timeline-item__image',
                { 'h-timeline-item__image--close': !this.isOpened },
              ]}
              src={image}
            />
          ) : (
            <div
              class={[
                'h-timeline-item__empty-image',
                { 'h-timeline-item__empty-image--close': !this.isOpened },
              ]}></div>
          )}
          <HBtn
            staticClass="h-timeline-item__button"
            color="glass"
            size="sm"
            v-show={!this.isOpened}>
            {title}
            <HIcon
              staticClass="h-timeline-item__icon"
              name="keyboard-arrow-down"
            />
          </HBtn>
        </div>
        {/* 時刻の登録は必須入力だが念の為チェックしている */}
        {time && (
          <div staticClass="h-timeline-item__time-display">
            <HClockIcon staticClass="h-timeline-item__time" time={time} />
          </div>
        )}
      </div>,
    ];

    const defaultSlot = this.$scopedSlots.default;
    let infoChildren: (VNode | ScopedSlotChildren | string)[];
    if (description) {
      infoChildren = [
        h('div', {
          staticClass: 'h-timeline-item__info__content h-theme-lead',
          directives: [
            {
              name: 'wysiwyg',
              value: description,
            },
          ],
        }),
      ];
    } else if (defaultSlot) {
      infoChildren = [defaultSlot(this)];
    } else {
      infoChildren = [];
    }

    if (link) {
      infoChildren.push(
        <HIconLink
          staticClass="h-timeline-item__link"
          icon="arrow-right"
          props={{ ...this.$navigation.resolveHrefTo(link.url) }}
          hiddenExternalIcon>
          {link.text}
        </HIconLink>,
      );
    }

    children.push(
      <div staticClass="h-timeline-item__inner">
        <div staticClass="h-timeline-item__body">
          {bodyChildren}
          <HExpandTransition>
            <div staticClass="h-timeline-item__info" v-show={this.isOpened}>
              {infoChildren}
            </div>
          </HExpandTransition>
        </div>
      </div>,
    );

    return h(
      'div',
      {
        staticClass: 'h-timeline-item',
        class: {
          'h-timeline-item--continue': this.continue,
        },
      },
      children,
    );
  },
})
export class HTimelineItemRef extends Vue implements HTimelineItemProps {
  // 親からどんな型が渡ってくるか記述する
  @Prop([String, Object, Date]) readonly time?: HTimelineItemProps['time'];
  @Prop([String, Object]) readonly title?: HTimelineItemProps['title'];
  @Prop([String, Object])
  readonly description?: HTimelineItemProps['description'];

  @Prop([String, Object]) readonly image?: HTimelineItemProps['image'];
  @Prop(Object) readonly link?: HTimelineItemProps['link'];
  @Prop(Boolean) readonly continue?: HTimelineItemProps['continue'];

  private isOpened: boolean = false;

  open() {
    this.isOpened = true;
  }
}

export const HTimelineItem = tsx
  .ofType<HTimelineItemProps, HTimelineItemEmits, HTimelineItemScopedSlots>()
  .convert(HTimelineItemRef);
