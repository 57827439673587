import './HActivityList.scss';

import * as tsx from 'vue-tsx-support';
import { VNode, PropType } from 'vue';
import { HActivityListItem } from './HActivityListItem';
import { Activity, ActivityCommons, HotelBasicInfo } from '~/schemes';
import { mergeVNodeData } from '~/helpers';

export interface HActivityListProps {
  commons: ActivityCommons;
  items: Activity[];
  hotel: HotelBasicInfo;
}

export const HActivityList = tsx.component({
  name: 'HActivityList',
  functional: true,
  props: {
    commons: {
      type: Object as PropType<ActivityCommons>,
      required: true,
    },
    items: {
      type: Array as PropType<Activity[]>,
      required: true,
    },
    hotel: {
      type: Object as PropType<HotelBasicInfo>,
      required: true,
    },
  },
  render(h, { data, props }) {
    const { commons, items, hotel } = props;

    return (
      <ul
        {...mergeVNodeData(data, {
          staticClass: 'h-activity-list',
        })}>
        {items.map<VNode>((item) => (
          <HActivityListItem
            key={item.id}
            staticClass="h-activity-list__item"
            commons={commons}
            data={item}
            hotel={hotel}
          />
        ))}
      </ul>
    );
  },
});
