import { Context } from '@nuxt/types';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { State as RootState } from './';
import { HotelDetail, HotelNotification, HotelBrandDetail } from '~/schemes';

export interface State {
  current: HotelDetail | null;
  currentBrand: HotelBrandDetail | null;
}

export const state = (): State => ({
  current: null,
  currentBrand: null,
});

export const getters: GetterTree<State, RootState> = {
  current(state): HotelDetail | null {
    return state.current;
  },
  /**
   * 現在カレント設定されている施設のデフォルトの表示通貨設定
   *
   * GFでは、施設ページ以外を含む全ページ共通で表示通貨設定を持っているが、施設ページ滞在時には、
   * 宿GETSから情報が取れた場合、表示通貨設定のデフォルトとしてその値を利用する
   *
   * @see {@link https://github.com/HoshinoResort/yg3front/blob/4585a8155c55179370ee93ae0e6cfd663541ee6d/src/pc/coffee/hoshinoya.app.coffee#L774-L779 宿GETSの仕様}
   */
  currentHotelDisplayCurrency(state): string | undefined {
    const currentYgets = state.current && state.current.ygets;
    if (!currentYgets) return;

    const { baseRate, defaultRate } = currentYgets;
    return defaultRate || baseRate;
  },
  currentBrand(state): HotelBrandDetail | null {
    return state.currentBrand;
  },
  currentNotifications(state): HotelNotification[] {
    return (state.current && state.current.notifications) || [];
  },
  currentRoomsearchNotificationsNotifications(state): HotelNotification[] {
    return (state.current && state.current.roomsearchNotifications) || [];
  },
};

export const actions: ActionTree<State, RootState> = {};

export const mutations: MutationTree<State> = {
  /**
   * カレントの施設をセットする
   */
  SET_CURRENT(state, hotel: HotelDetail | null) {
    // すでに同じIDの施設がセット済みの場合は何もしない
    if (hotel && state.current && hotel.id === state.current.id) return;
    state.current = hotel;
  },
  /**
   * カレントの施設の宿GETSマスタをセットする
   */
  SET_CURRENT_YGETS(state, ygets: HotelDetail['ygets']) {
    const { current } = state;

    // セット済みの施設がない時は何もしない
    if (!current) return;

    // 宿GETSマスタがnullの時は、nullを代入して即終了
    if (!ygets) {
      current.ygets = null;
      current._ygetsDetected = true;
      return;
    }

    // 既存の施設情報に宿GETS IDがなければ何もしない
    if (!current.ygetsId) return;

    // 宿GETS IDが食い違っていれば何もしない
    if (ygets.hotelId !== current.ygetsId) return;

    current.ygets = ygets;
    current._ygetsDetected = true;
  },
  SET_CURRENT_BRAND(state, brand: HotelBrandDetail | null) {
    state.currentBrand = brand;
  },
};
