import './HCoverModal.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';
import { bodyScrollLock } from '@dadajam4/vue-stack';
import { HIcon } from '~/components';

export type HCoverModalClosseGuard = (modal: HCoverModalRef) => boolean;

export interface HCoverModalProps {
  value?: boolean;
  closeGuard?: HCoverModalClosseGuard;
  hiddenClose?: boolean;
}

export interface HCoverModalEmits {
  onInput: boolean;
}

export interface HCoverModalScopedSlots {}

@Component<HCoverModalRef>({
  name: 'HCoverModal',
  directives: {
    bodyScrollLock,
  },

  render() {
    return (
      <transition name="fade">
        {this.isActive && (
          <div staticClass="h-cover-modal">
            <div staticClass="h-cover-modal__inner" v-body-scroll-lock={true}>
              {this.$slots.default}
            </div>
            <transition name="fade">
              <button
                type="button"
                staticClass="h-cover-modal__close"
                v-show={!this.hiddenClose}
                onClick={(ev) => {
                  ev.stopPropagation();
                  if (this.closeGuard && this.closeGuard(this) === false) {
                    return;
                  }
                  this.close();
                }}>
                <HIcon name="close" />
              </button>
            </transition>
          </div>
        )}
      </transition>
    );
  },
})
export class HCoverModalRef extends Vue implements HCoverModalProps {
  @Model('input', { type: Boolean }) value!: boolean;
  @Prop({ type: Function }) closeGuard?: HCoverModalClosseGuard;
  @Prop({ type: Boolean }) hiddenClose!: boolean;

  private internalValue: boolean = this.value;

  get isActive() {
    return this.internalValue;
  }

  set isActive(isActive) {
    if (this.internalValue !== isActive) {
      this.internalValue = isActive;
      this.$emit('input', isActive);
    }
  }

  @Watch('value')
  protected valueChangeHandler() {
    this.internalValue = this.value;
  }

  show() {
    this.isActive = true;
  }

  close() {
    this.isActive = false;
  }
}

export const HCoverModal = tsx
  .ofType<HCoverModalProps, HCoverModalEmits, HCoverModalScopedSlots>()
  .convert(HCoverModalRef);
