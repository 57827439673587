import './HSyncFrame.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

const EMIT_SIGNAL = 'iframe-emitter';

const EMIT_TYPES = {
  DOCUMENT_HEIGHT_CHANGED: 'document-height-changed',
};

const NUMERIC_RE = /^[\d.]+$/;

const normalizePixelValue = (value: string | number): string => {
  value = String(value);
  return NUMERIC_RE.test(value) ? `${value}px` : value;
};

let _scopeSource = 0;

export interface HSyncFrameProps {
  /**
   * iframeのsrc属性
   */
  src?: string;
  /**
   * iframeのtitle属性
   */
  title?: string;
  /**
   * 同期が完了するまでの高さ
   *
   * @default 150
   */
  height?: number | string;
  /**
   * 同期が完了するまでのアスペクト比
   */
  aspectRatio?: string;
}

export interface HSyncFrameEmits {}

export interface HSyncFrameScopedSlots {}

interface ComputedAttrs {
  src?: string;
  styles?: Record<string, string>;
}

@Component<HSyncFrameRef>({
  name: 'HSyncFrame',
  mounted() {
    window.addEventListener('message', this.handleMessage);
    this.scope = String(++_scopeSource);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage);
  },
  watch: {
    src() {
      this.reset();
    },
  },
  render() {
    const { src, styles } = this.attrs;

    return (
      <div
        staticClass="h-sync-frame"
        class={{
          'h-sync-frame--initialized': this.initialized,
        }}
        style={styles}>
        {!!src && (
          <iframe
            class="h-sync-frame__node"
            src={src}
            ref="node"
            title={this.title}
            scrolling="auto"
            style={styles}
            allowtransparency
          />
        )}
      </div>
    );
  },
})
export class HSyncFrameRef extends Vue implements HSyncFrameProps {
  $refs!: {
    node: HTMLIFrameElement;
  };

  readonly $el!: HTMLElement;
  @Prop(String) src?: string;
  @Prop(String) title?: string;
  @Prop({
    type: [String, Number],
    default: 150,
  })
  height?: string | number;

  @Prop(String) aspectRatio?: string;

  private initialized: boolean = false;
  private scope: string | null = null;
  private currentHeight: number = 0;

  private reset() {
    this.initialized = false;
    this.currentHeight = 0;
  }

  get computedSrc() {
    const { src, scope } = this;
    if (!src || !scope) return;

    const { origin, pathname, search, hash } = new URL(src, location.origin);
    const queryPrefix = search ? '&' : '?';
    const query = `${queryPrefix}__iscope__=${scope}`;
    return `${origin}${pathname}${search}${query}${hash}`;
  }

  get attrs(): ComputedAttrs {
    const { computedSrc: src, initialized, currentHeight } = this;
    const attrs: ComputedAttrs = {
      src,
    };
    if (initialized) {
      attrs.styles = {
        height: normalizePixelValue(currentHeight),
      };
    } else {
      const { height, aspectRatio } = this;
      attrs.styles = {};
      if (height) {
        attrs.styles.height = normalizePixelValue(height);
      }
      if (aspectRatio) {
        attrs.styles.aspectRatio = aspectRatio;
      }
    }

    return attrs;
  }

  private handleMessage(ev: MessageEvent) {
    const { data } = ev;
    if (
      !data ||
      typeof data !== 'object' ||
      data.signal !== EMIT_SIGNAL ||
      data.scope !== this.scope
    ) {
      return;
    }
    if (data.type === EMIT_TYPES.DOCUMENT_HEIGHT_CHANGED) {
      const { height } = data;
      if (typeof height === 'number') {
        this.currentHeight = height;
        this.initialized = true;
      }
    }
  }
}

export const HSyncFrame = tsx
  .ofType<HSyncFrameProps, HSyncFrameEmits, HSyncFrameScopedSlots>()
  .convert(HSyncFrameRef);
