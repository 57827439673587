import * as tsx from 'vue-tsx-support';
import { FunctionalComponentOptions } from 'vue';
import {
  HListSelector,
  HListSelectorProps,
  HListSelectorEmits,
  HListSelectorScopedSlots,
  HListSelectorItem,
} from './HListSelector';

export interface HListLanguageSelectorProps
  extends Omit<
    HListSelectorProps,
    'items' | 'inputValue' | 'title' | 'guide'
  > {}

export interface HListLanguageSelectorEmits
  extends Omit<HListSelectorEmits, 'onInput'> {}

export interface HListLanguageSelectorScopedSlots
  extends HListSelectorScopedSlots {}

export const HListLanguageSelectorSelectorOptions: FunctionalComponentOptions<HListLanguageSelectorProps> = {
  name: 'HListLanguageSelector',
  functional: true,
  render(h, { props, listeners, scopedSlots, parent, data }) {
    const { $language } = parent;
    const { dynamicInformations, current } = $language;
    const disabled = dynamicInformations.length < 2;

    // const hotelRate =
    //   $hotel.current && $hotel.current.ygets && $hotel.current.ygets.baseRate;
    const inputValue = current;
    const items: HListSelectorItem[] = dynamicInformations.map(
      ({ id, label }) => {
        // const baseRate = $commons.exchangeRateAt(base);
        return {
          value: id,
          label, // globalLabel,
        };
      },
    );

    return (
      <HListSelector
        {...{
          staticClass: 'h-list-language-selector',
          class: [data.staticClass, data.class],
          props: {
            ...props,
            title: '言語 / Language',
            items,
            inputValue,
            disabled,
            static: true,
          },
          on: {
            ...listeners,
            // input: (value) => {
            //   $language.switch(value);
            //   // $commons.changeCurrency(value);
            // },
            clickItem(item: HListSelectorItem) {
              $language.switch(item.value);
            },
          },
          scopedSlots,
        }}
      />
    );
  },
};

export const HListLanguageSelector = tsx
  .ofType<
    HListLanguageSelectorProps,
    HListLanguageSelectorEmits,
    HListLanguageSelectorScopedSlots
  >()
  .convert(HListLanguageSelectorSelectorOptions as any);
