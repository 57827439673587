import { BrandNameDisplaySettings } from './schemes';

/**
 * ロゴとテキストを一緒に利用する星のややリゾナーレの設定
 */
const LOGO_WITH_TEXT_SETTINGS: BrandNameDisplaySettings = {
  logo: ({ lang, res, slug }) => {
    return lang === 'ja'
      ? res.img(`/brands/${slug}/logo_withName.svg`)
      : res.img(`/brands/${slug}/logo_wh.svg`);
  },
  logoWithText: ({ lang }) => lang !== 'ja',
};

/**
 * 白ロゴをシンプルに利用する設定
 */
const LOGO_WHITE_SETTINGS: BrandNameDisplaySettings = {
  logo: ({ res, slug }) => res.img(`/brands/${slug}/logo_wh.svg`),
};

/**
 * ブランドスラッグごとのブランド名表示設定のマップ
 *
 * @see BrandNameDisplaySettings
 */
export const BRAND_NAME_DISPLAY_SETTINGS_MAP: Record<
  string,
  BrandNameDisplaySettings
> = {
  hoshinoya: LOGO_WITH_TEXT_SETTINGS,
  risonare: LOGO_WITH_TEXT_SETTINGS,
  kai: LOGO_WHITE_SETTINGS,
  omo: LOGO_WHITE_SETTINGS,
  beb: LOGO_WHITE_SETTINGS,
  uniquehotels: {
    text: {
      ja: ({ brandName }) =>
        brandName.replace('そのほかの', 'そのほかの<br />'),
    },
  },
  // 日帰り施設は普通にテキスト表示するだけなので設定不要
  // daytrip: {},
};
