import { Config as WebFontConfig } from 'webfontloader';

/**
 * ISO 639言語コード（＋方言コードがある場合、アンダースコア連結で小文字接続）
 * @see: https://www.asahi-net.or.jp/~ax2s-kmtn/ref/iso639.html
 */
export type AvailableLanguage =
  // 日本語
  | 'ja'
  // 英語
  | 'en'
  // 簡体字
  | 'zh_cn'
  // 繁体字
  | 'zh_tw'
  // 韓国語
  | 'ko';

export const DEFAULT_LANGUAGE: AvailableLanguage = 'ja';

export const AVAILABLE_LANGUAGES: AvailableLanguage[] = [
  'ja',
  'en',
  'zh_cn',
  'zh_tw',
  'ko',
];

export const AVAILABLE_LANGUAGE_RE = new RegExp(
  `/(${AVAILABLE_LANGUAGES.join('|')})`,
);

export enum LanguageRedirectResult {
  Redirectd = 'redirectd',
  Continued = 'continued',
  Missing = 'Missing',
}

export const isAvailableLanguage = (
  source: any,
): source is AvailableLanguage => {
  return AVAILABLE_LANGUAGES.includes(source);
};

export const getLanguageByPath = (
  path: string,
): AvailableLanguage | undefined => {
  const match = path.match(
    new RegExp(`^/(${AVAILABLE_LANGUAGES.join('|')})(/|$)`),
  );
  return match ? (match[1] as AvailableLanguage) : undefined;
};

export interface LanguageInfo {
  id: AvailableLanguage;
  label: string;
  globalLabel: string;

  /**
   * html要素のlang属性に利用
   * @see: https://ja.wikipedia.org/wiki/IETF%E8%A8%80%E8%AA%9E%E3%82%BF%E3%82%B0
   */
  attr: string;

  /**
   * Intl.dateTimeFormatのlocaleに利用
   * @see: https://ja.wikipedia.org/wiki/IETF%E8%A8%80%E8%AA%9E%E3%82%BF%E3%82%B0
   */
  bcp47: string;
  font: WebFontConfig | null;
  /**
   * adobeのID
   *
   * @MEMO jaとen以外の言語は共通で使用されているが、必要があれば言語ごとに設定すること
   * @see: https://fonts.adobe.com/my_fonts#web_projects-section
   * */
  typekit: string | null;
  ygetsKey: string;
  external?: string | null;
  currency: string;
  /**
   * タイムデザイン連携用の言語キー
   */
  tb: string;
}

export interface DynamicLanguageInfo extends LanguageInfo {
  switchUrl: string;
}

export interface LanguageDataMap<T = any> {
  /**
   * 日本語
   */
  ja?: T;

  /**
   * 英語
   */
  en?: T;

  /**
   * 简体中文
   */
  zh_cn?: T;

  /**
   * 繁體中文
   */
  zh_tw?: T;

  /**
   * 韓国語
   */
  ko?: T;
}

/**
 * adobeのフォント管理webプロジェクト設定id
 * @MEMO https://fonts.adobe.com/my_fonts#web_projects-section
 *
 */
const adobeGlobalFormatJaId: string = 'lhv8ayq';
const adobeGlobalFormatEnId: string = 'hzs2klv';
const adobeGlobalFormatId: string = 'puo5bsz';

export const languageInfoMap = new Map<AvailableLanguage, LanguageInfo>();
languageInfoMap.set('ja', {
  id: 'ja',
  label: '日本語',
  globalLabel: '日本語 / Japanese',
  attr: 'ja',
  bcp47: 'ja',
  font: {
    custom: {
      families: ['Noto Sans JP', 'Dancing Script', 'Noto Serif JP'],
      urls: [
        'https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500|Dancing+Script:wght@400;700|Noto+Serif+JP:400,600&display=swap',
      ],
    },
  },
  typekit: adobeGlobalFormatJaId,
  ygetsKey: 'JA',
  currency: 'JPY',
  tb: 'ja',
});
languageInfoMap.set('en', {
  id: 'en',
  label: 'English',
  globalLabel: 'English',
  attr: 'en',
  bcp47: 'en',
  font: {
    custom: {
      families: ['Noto Sans', 'Shadows Into Light', 'Dancing Script'],
      urls: [
        'https://fonts.googleapis.com/css?family=Noto+Sans:300,400,500|Shadows+Into+Light|Dancing+Script:wght@400;700&display=swap',
      ],
    },
  },
  typekit: adobeGlobalFormatEnId,
  ygetsKey: 'EN',
  currency: 'USD',
  tb: 'en',
});
languageInfoMap.set('zh_cn', {
  id: 'zh_cn',
  label: '简体中文',
  globalLabel: '简体中文 / Simplified Chinese',
  attr: 'zh-cmn-Hans',
  bcp47: 'zh-Hans',
  font: {
    custom: {
      families: [
        'Noto Sans SC',
        'Noto Serif SC',
        'Shadows Into Light',
        'Dancing Script',
      ],
      urls: [
        'https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500|Noto+Serif+SC:500|Shadows+Into+Light|Dancing+Script:wght@400;700&display=swap',
      ],
    },
  },
  typekit: adobeGlobalFormatId,
  ygetsKey: 'ZH',
  currency: 'CNY',
  tb: 'zh-cn',
});
languageInfoMap.set('zh_tw', {
  id: 'zh_tw',
  label: '繁體中文',
  globalLabel: '繁體中文 / Traditional Chinese',
  attr: 'zh-cmn-Hant',
  bcp47: 'zh-Hant',
  font: {
    custom: {
      families: [
        'Noto Sans TC',
        'Noto Serif TC',
        'Shadows Into Light',
        'Dancing Script',
      ],
      urls: [
        'https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,300,400,500|Noto+Serif+TC:500|Shadows+Into+Light|Dancing+Script:wght@400;700&display=swap',
      ],
    },
  },
  typekit: adobeGlobalFormatId,
  ygetsKey: 'CH',
  currency: 'TWD',
  tb: 'zh-tw',
});
languageInfoMap.set('ko', {
  id: 'ko',
  label: '한국어',
  globalLabel: '한국어 / Korean',
  attr: 'ko',
  bcp47: 'ko',
  font: {
    custom: {
      families: [
        'Noto Sans KR',
        'Noto Serif KR',
        'Stylish',
        'Shadows Into Light',
        'Dancing Script',
      ],
      urls: [
        'https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500|Noto+Serif+KR:100,300,400,500|Stylish|Shadows+Into+Light|Dancing+Script:wght@400;700&display=swap',
      ],
    },
  },
  /** 一旦英語と同じフォントの設定にしているが必要に応じて変更すること */
  typekit: adobeGlobalFormatEnId,
  ygetsKey: 'KO',
  currency: 'KRW',
  tb: 'ko',
});

export const LANGUAGE_INFORMATION: LanguageInfo[] = AVAILABLE_LANGUAGES.map(
  (lang) => {
    const info = languageInfoMap.get(lang);
    if (!info) {
      throw new Error(`missing languagge info at ${lang}`);
    }
    return info;
  },
);

export function matchedAvairableLanguage(
  source: string,
): AvailableLanguage | undefined {
  const hit = AVAILABLE_LANGUAGES.find((l) => {
    const al = source.toLocaleLowerCase();
    const bl = l.toLocaleLowerCase();
    if (al === bl) return true;
    const alm = al.match(/[a-z]+/);
    const blm = al.match(/[a-z]+/);
    if (alm && blm && alm[0] === blm[0]) return true;
    return false;
  });
  if (hit) return hit;
}
