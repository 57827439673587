/* eslint-disable no-undef */
import { DirectiveFunction, DirectiveOptions } from 'vue/types';

export type MutationDirectiveHandler = (records: MutationRecord[]) => any;

export interface MutationDirectiveValues {
  debounce: number;
  debounceTimerId?: number | null;
  options: MutationObserverInit;
  handler: MutationDirectiveHandler;
}

interface MutatableElementOnMutation {
  bindingValues: MutationDirectiveValues;
  handler: MutationDirectiveHandler;
}

interface MutatableElement extends HTMLElement {
  _onMutation?: MutatableElementOnMutation;
  _mutationObserver?: MutationObserver;
}

const IS_BROWSER = typeof document !== 'undefined';

const getDefaults = () => {
  return {
    debounce: 0,
    debounceTimerId: null,
    options: {},
  };
};

const setupValues = (
  values: MutationDirectiveHandler | MutationDirectiveValues,
): MutationDirectiveValues => {
  const defaults = getDefaults();
  if (typeof values === 'function') {
    return {
      ...defaults,
      handler: values,
    };
  }

  return {
    ...defaults,
    ...values,
  };
};

const inserted: DirectiveFunction = function inserted(
  el: MutatableElement,
  binding,
) {
  if (!IS_BROWSER || !binding.value) {
    return;
  }
  const bindingValues = setupValues(binding.value);

  const { handler: _handler } = bindingValues;
  const handler =
    bindingValues.debounce === 0
      ? (records: MutationRecord[]) => _handler(records)
      : (records: MutationRecord[]) => {
          if (bindingValues.debounceTimerId !== null) {
            clearTimeout(bindingValues.debounceTimerId);
          }
          bindingValues.debounceTimerId = window.setTimeout(
            () => _handler(records),
            bindingValues.debounce,
          );
        };

  el._onMutation = {
    bindingValues,
    handler,
  };

  el._mutationObserver = new MutationObserver((records) => {
    handler(records);
  });
  el._mutationObserver.observe(el, bindingValues.options);
};

const unbind: DirectiveFunction = function unbind(el: MutatableElement) {
  if (!IS_BROWSER) {
    return;
  }
  const { _onMutation } = <any>el;
  if (!_onMutation) {
    return;
  }
  const { bindingValues } = <MutatableElementOnMutation>el._onMutation;

  if (bindingValues.debounceTimerId !== null) {
    clearTimeout(bindingValues.debounceTimerId);
  }

  if (el._mutationObserver) {
    el._mutationObserver.disconnect();
    delete el._mutationObserver;
  }

  delete el._onMutation;
};

export default {
  name: 'mutation',
  inserted,
  unbind,
} as DirectiveOptions;
