import { LanguageDataMap, AvailableLanguage } from './language';
import { GeneralOption, extractGeneralOptionLS } from './general';

/** @TODO cms側でマスタ設定としているがGF側でenumの定義がされており,
マスタ設定で項目を増やされると正しく表示されなくなってしまう
マスタ設定を考慮して反映箇所を修正する必要がありそう
一旦無理矢理型を信頼しちゃうことにする */
export enum CreditCardType {
  Visa = '1',
  Mastercard = '2',
  JCB = '3',
  AmericanExpress = '4',
  /**
   * 銀聯 UnionPay
   */
  UnionPay = '5',
  DinersClub = '6',
}

/** @TODO 一回見ないふりするけど、CreditCardType同様どうにかしなきゃいけない問題 */
export enum PaymentType {
  IcCard = '1',
  Nanaco = '2',
  Waon = '3',
  RakutenEdy = '4',
  QuickPay = '5',
  QrPayment = '6',
  Alipay = '7',
  WeChatPay = '8',
  AuPay = '9',
  MerPay = '10',
  RakutenPay = '11',
  PayPay = '12',
  dPayment = '13',
}

export interface CreditCardOption extends GeneralOption<CreditCardType> {}
export interface PaymentOption extends GeneralOption<PaymentType> {}

export interface CreditCardOptionLS extends Omit<CreditCardOption, 'name'> {
  name: LanguageDataMap<CreditCardOption['name']>;
}

export interface PaymentOptionLS extends Omit<PaymentOption, 'name'> {
  name: LanguageDataMap<PaymentOption['name']>;
}

export function extractCreditCardOptionLS(
  ls: CreditCardOptionLS,
  lang: AvailableLanguage,
): CreditCardOption {
  return extractGeneralOptionLS(ls, lang);
}

export function extractPaymentOptionLS(
  ls: PaymentOptionLS,
  lang: AvailableLanguage,
): PaymentOption {
  return extractGeneralOptionLS(ls, lang);
}

export interface PaymentCommons {
  creditCards: CreditCardOption[];
  payments: PaymentOption[];
}
