import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

export interface VPortalItemProps {
  to: string;
}

export interface VPortalItemEmits {}

export interface VPortalItemScopedSlots {}

@Component<VPortalItemRef>({
  name: 'VPortalItem',

  render() {
    const defaultSlot = this.$slots.default;
    this.rendered = defaultSlot ? (
      <div staticClass="v-portal-item" key={this.portalItemId}>
        {defaultSlot}
      </div>
    ) : null;
    return (undefined as unknown) as any;
  },
})
export class VPortalItemRef extends Vue implements VPortalItemProps {
  @Prop({ type: String, required: true }) readonly to!: string;

  portalItemId: number = null as any;
  rendered: VNode | null = null;

  protected created() {
    this.$portal.pushItem(this);
  }

  protected beforeDestroy() {
    this.$portal.removeItem(this);
  }
}

export const VPortalItem = tsx
  .ofType<VPortalItemProps, VPortalItemEmits, VPortalItemScopedSlots>()
  .convert(VPortalItemRef);
