import {
  DirectiveFunction,
  DirectiveOptions,
  DirectiveBinding,
} from 'vue/types/options';
import { PointerTracker, PointerTrackerOptions } from '~/libs/pointer-tracker';
export type {
  DragCallback as PointerDicrectiveDragCallback,
  DragPayload as PointerDicrectiveDragPayload,
} from '~/libs/pointer-tracker';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PointerDicrectiveOptions extends PointerTrackerOptions {}

export type RawPointerTrackerOptions = boolean | PointerDicrectiveOptions;

/**
 * ポインタートラッカーディレクティブのバインド値オブジェクト
 */
export type PointerDirectiveBindingValue = RawPointerTrackerOptions;

export interface PointerDirectiveBinding extends DirectiveBinding {
  value?: PointerDirectiveBindingValue;
}

const POINTER_TRACKER_SYMBOL = Symbol('POINTER_TRACKER_SYMBOL');

/**
 * ポインタートラッカーディレクティブの処理対象要素
 */
export interface PointerDirectiveElement extends HTMLElement {
  [POINTER_TRACKER_SYMBOL]?: PointerTracker;
}

/**
 * 指定の要素のポインタートラッカーインスタンスを破棄する
 *
 * * インスタンスがマウントされていない時は何もしない
 *
 * @param el - 破棄対象の要素
 */
const disposePointerTracker = (el: PointerDirectiveElement) => {
  const pointerTracker = el[POINTER_TRACKER_SYMBOL];
  if (pointerTracker) {
    pointerTracker.stop();
    delete el[POINTER_TRACKER_SYMBOL];
  }
};

/**
 * 指定の要素にポインタートラッカーインスタンスをマウントする
 *
 * * すでにマウント済みの際はそのインスタンスをリターンする
 *
 * @param el - マウントする要素
 * @param settings - ポインタートラッカーの初期化設定
 * @returns ポインタートラッカーインスタンス
 */
const applyPointerTracker = (
  el: PointerDirectiveElement,
  settings?: PointerDirectiveBindingValue,
) => {
  const currentPointerTracker = el[POINTER_TRACKER_SYMBOL];
  if (settings === true) {
    settings = {};
  }
  if (!settings) {
    return disposePointerTracker(el);
  }

  if (currentPointerTracker) {
    return currentPointerTracker;
  }

  const pointerTracker = new PointerTracker(el, settings);
  el[POINTER_TRACKER_SYMBOL] = pointerTracker;
  return pointerTracker;
};

const apply: DirectiveFunction = (
  el: PointerDirectiveElement,
  binding: PointerDirectiveBinding,
) => {
  applyPointerTracker(el, binding.value);
};

const unbind: DirectiveFunction = (
  el: PointerDirectiveElement,
  binding: PointerDirectiveBinding,
) => {
  disposePointerTracker(el);
};

export default {
  name: 'pointer',
  inserted: apply,
  update: apply,
  unbind,
} as DirectiveOptions;
