import './h-progress-linear.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

export interface HProgressLinearProps {
  active?: boolean;
  indeterminate?: boolean;
  query?: boolean;
  backgroundColor?: string | null;
  backgroundOpacity?: number | string | null;
  bufferValue?: number | string;
  color?: string;
  height?: number | string;
  value?: number | string;
}

export interface HProgressLinearEmits {}

export interface HProgressLinearScopedSlots {}

@Component<HProgressLinearRef>({
  name: 'h-progress-linear',
})
export class HProgressLinearRef extends Vue implements HProgressLinearProps {
  @Prop({ type: Boolean, default: true }) readonly active!: boolean;
  @Prop({ type: Boolean }) readonly indeterminate!: boolean;
  @Prop({ type: Boolean }) readonly query!: boolean;
  @Prop({ type: String, default: null }) readonly backgroundColor!:
    | string
    | null;

  @Prop({ type: [Number, String], default: null }) readonly backgroundOpacity!:
    | number
    | string
    | null;

  @Prop({ type: [Number, String], default: 100 }) readonly bufferValue!:
    | number
    | string;

  @Prop({ type: String, default: 'currentColor' }) readonly color!: string;
  @Prop({ type: [Number, String], default: 7 }) readonly height!:
    | number
    | string;

  @Prop({ type: [Number, String], default: 0 }) readonly value!:
    | number
    | string;

  get backgroundStyle(): object {
    const backgroundOpacity =
      this.backgroundOpacity == null
        ? this.backgroundColor
          ? 1
          : 0.3
        : parseFloat(this.backgroundOpacity as string);

    return {
      height: this.active ? convertToUnit(this.height) : 0,
      opacity: backgroundOpacity,
      width: `${this.normalizedBufer}%`,
    };
  }

  get effectiveWidth(): number {
    if (!this.normalizedBufer) {
      return 0;
    }

    return (+this.normalizedValue * 100) / +this.normalizedBufer;
  }

  get normalizedBufer(): number {
    if (this.bufferValue < 0) {
      return 0;
    }

    if (this.bufferValue > 100) {
      return 100;
    }

    return parseFloat(this.bufferValue as string);
  }

  get normalizedValue(): number {
    if (this.value < 0) {
      return 0;
    }

    if (this.value > 100) {
      return 100;
    }

    return parseFloat(this.value as string);
  }

  get styles(): object {
    const styles: Record<string, any> = {};

    if (!this.active) {
      styles.height = 0;
    }

    if (!this.indeterminate && this.normalizedBufer !== 100) {
      styles.width = `${this.normalizedBufer}%`;
    }

    return styles;
  }

  private genDeterminate(h: CreateElement): VNode {
    return h('div', {
      ref: 'front',
      staticClass: `h-progress-linear__bar__determinate`,
      style: {
        width: `${this.effectiveWidth}%`,
        backgroundColor: this.color,
      },
    });
  }

  private genBar(h: CreateElement, name: string): VNode {
    return h('div', {
      staticClass: 'h-progress-linear__bar__indeterminate',
      class: {
        [name]: true,
      },
      style: {
        backgroundColor: this.color,
      },
    });
  }

  private genIndeterminate(h: CreateElement): VNode {
    return h(
      'div',
      {
        ref: 'front',
        staticClass: 'h-progress-linear__bar__indeterminate',
        class: {
          'h-progress-linear__bar__indeterminate--active': this.active,
        },
      },
      [this.genBar(h, 'long'), this.genBar(h, 'short')],
    );
  }

  protected render(h: CreateElement): VNode {
    const fade = h(
      'transition',
      {
        props: {
          name: 'vv-stack-fade',
        },
      },
      this.indeterminate ? [this.genIndeterminate(h)] : [],
    );
    const slide = h(
      'transition',
      {
        props: {
          name: 'vv-stack-slide-x',
        },
      },
      this.indeterminate ? [] : [this.genDeterminate(h)],
    );

    const bar = h(
      'div',
      {
        staticClass: 'h-progress-linear__bar',
        style: this.styles,
      },
      [fade, slide],
    );

    const background = h('div', {
      staticClass: 'h-progress-linear__background',
      style: {
        ...this.backgroundStyle,
        backgroundColor: this.backgroundColor || this.color,
      },
    });

    const content =
      this.$slots.default &&
      h(
        'div',
        {
          staticClass: 'h-progress-linear__content',
        },
        this.$slots.default,
      );

    return h(
      'div',
      {
        staticClass: 'h-progress-linear',
        attrs: {
          role: 'progressbar',
          'aria-valuemin': 0,
          'aria-valuemax': this.normalizedBufer,
          'aria-valuenow': this.indeterminate
            ? undefined
            : this.normalizedValue,
        },
        class: {
          'h-progress-linear--query': this.query,
        },
        style: {
          height: convertToUnit(this.height),
        },
        on: this.$listeners,
      },
      [background, bar, content],
    );
  }
}

function convertToUnit(
  str: string | number | null | undefined,
  unit = 'px',
): string | undefined {
  if (str == null || str === '') {
    return undefined;
  } else if (isNaN(+str!)) {
    return String(str);
  } else {
    return `${Number(str)}${unit}`;
  }
}

export const HProgressLinear = tsx
  .ofType<
    HProgressLinearProps,
    HProgressLinearEmits,
    HProgressLinearScopedSlots
  >()
  .convert(HProgressLinearRef);
