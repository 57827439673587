import { VNode } from 'vue';
import {
  DirectiveFunction,
  DirectiveOptions,
  DirectiveBinding,
} from 'vue/types/options';
import VisibilityManager from '@dadajam4/visibility';

const IS_BROWSER = typeof document !== 'undefined';

export type InviewDirectiveHandler = (entry: IntersectionObserverEntry) => any;

export interface InviewDirectiveSettings {
  root?: string | Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}

export interface InviewDirectiveValues extends InviewDirectiveSettings {
  change?: InviewDirectiveHandler;
  in?: InviewDirectiveHandler;
  out?: InviewDirectiveHandler;
}

export type InviewDirectiveValue =
  | InviewDirectiveHandler
  | InviewDirectiveValues
  | false
  | null;

export interface InviewDirectiveBinding extends DirectiveBinding {
  name: 'inview';
  value?: InviewDirectiveValue;
  oldValue?: InviewDirectiveValue;
}

export interface InviewDirectiveContext {
  values: InviewDirectiveValues;
  ob: IntersectionObserver;
  destroy: Function;
  reload: Function;
}

export interface InviewDirectiveElement extends HTMLElement {
  __inview_ctx__: InviewDirectiveContext;
}

export type InviewDirectiveFunction = (
  el: InviewDirectiveElement,
  binding: InviewDirectiveBinding,
  vnode: VNode,
  oldVnode: VNode,
) => void;

const contexts: InviewDirectiveContext[] = [];

VisibilityManager.change((state) => {
  if (state === 'visible') {
    contexts.forEach((context) => context.reload());
  }
});

const setupValues = (
  value?: InviewDirectiveValue,
): InviewDirectiveValues | false => {
  if (!value) {
    return false;
  }
  const values: InviewDirectiveValues =
    typeof value === 'function'
      ? {
          change: value,
        }
      : {
          ...value,
        };
  return values;
};

const setupContext: InviewDirectiveFunction = function setupContext(
  el,
  binding,
) {
  if (!IS_BROWSER) {
    return;
  }

  const { value } = binding;
  const bindingValues = setupValues(value);
  const { __inview_ctx__: ctx } = el;
  let needDestroy = !bindingValues;
  if (ctx && bindingValues) {
    needDestroy =
      ctx.values.root !== bindingValues.root ||
      ctx.values.rootMargin !== bindingValues.rootMargin ||
      ctx.values.threshold !== bindingValues.threshold;
  }

  if (ctx) {
    if (needDestroy) {
      ctx.destroy();
    } else {
      return;
    }
  }

  if (!bindingValues) {
    return;
  }

  const root =
    typeof bindingValues.root === 'string'
      ? document.querySelector(bindingValues.root)
      : bindingValues.root;

  const ob = new IntersectionObserver(
    (entries) => {
      for (const entry of entries) {
        const inview = entry.isIntersecting;
        if (bindingValues.change) {
          bindingValues.change(entry);
        }
        if (inview && bindingValues.in) {
          bindingValues.in(entry);
        }
        if (!inview && bindingValues.out) {
          bindingValues.out(entry);
        }
      }
    },
    {
      root,
      rootMargin: bindingValues.rootMargin,
      threshold: bindingValues.threshold,
    },
  );

  const _ctx = {
    values: bindingValues,
    ob,
    reload: () => {
      if (!el.__inview_ctx__) return;
      const { ob } = el.__inview_ctx__;
      ob.unobserve(el);
      ob.observe(el);
    },
    destroy: () => {
      if (!el.__inview_ctx__) {
        return;
      }
      el.__inview_ctx__.ob.disconnect();
      delete (el as any).__inview_ctx__;
      const index = contexts.indexOf(_ctx);
      if (index !== -1) {
        contexts.splice(index, 1);
      }
    },
  };

  el.__inview_ctx__ = _ctx;
  ob.observe(el);
  contexts.push(_ctx);
};

const inserted: InviewDirectiveFunction = function inserted(
  el,
  binding,
  vnode,
  oldVnode,
) {
  setupContext(el, binding, vnode, oldVnode);
};

const componentUpdated: InviewDirectiveFunction = function componentUpdated(
  el,
  binding,
  vnode,
  oldVnode,
) {
  setupContext(el, binding, vnode, oldVnode);
};

const unbind: InviewDirectiveFunction = function unbind(el) {
  if (!IS_BROWSER) {
    return;
  }
  const { __inview_ctx__: ctx } = el;
  ctx && ctx.destroy();
};

export default {
  name: 'inview',
  inserted: inserted as DirectiveFunction,
  componentUpdated: componentUpdated as DirectiveFunction,
  unbind: unbind as DirectiveFunction,
} as DirectiveOptions;
