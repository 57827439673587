import './HImg.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode, VNodeDirective } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HProgressSpinner } from '~/components';

export enum HImgLoadState {
  Pending = 'pending',
  Loading = 'loading',
  Loaded = 'loaded',
  Failed = 'failed',
}

interface Resolver {
  resolve: Function;
  reject: Function;
}

export interface HImgProps {
  tag?: string;
  src: string;
  inview?:
    | boolean
    // eslint-disable-next-line no-undef
    | IntersectionObserverInit;
  manual?: boolean;
  alt?: string;
  width?: string | number;
  height?: string | number;
  fitHeight?: boolean;
  liquid?: boolean;
  loading?: boolean;
  corner?: boolean;
  nodrag?: boolean;
}

export interface HImgEmits {
  onLoad: Event;
  onChangeState: HImgLoadState;
}

export interface HImgScopedSlots {}

@Component<HImgRef>({
  name: 'HImg',

  render(h) {
    const { tag, inview, liquid, loading } = this;

    const children: VNode[] = [];
    if (liquid) {
      const liquidNodeChild = this.isLoading
        ? h(HProgressSpinner, {
            staticClass: 'h-img__liquid-node__loading',
          })
        : this.alt;
      children.push(
        h(
          'span',
          {
            staticClass: 'h-img__liquid-node',
            style: this.liquidNodeStyles,
          },
          [liquidNodeChild],
        ),
      );
    } else {
      if (loading) {
        this.$logger.warn('[h-img]: loading works only liquid mode');
      }
      if (this.internalSrcReady) {
        children.push(
          h('img', {
            staticClass: 'h-img__node',
            attrs: {
              src: this.src,
              width: this.width,
              height: this.height,
              alt: this.alt,
            },
          }),
        );
      }
    }

    let directives: VNodeDirective[] | undefined;

    if (this.$ua.useImageLazyLoad && inview && !this.inviewTriggered) {
      const inviewOptions = {
        rootMargin: '40px 0px',
        in: () => {
          this.inviewTriggered = true;
          this.load();
        },
      };
      if (typeof inview === 'object') {
        Object.assign(inviewOptions, inview);
      }
      directives = [
        {
          name: 'inview',
          value: inviewOptions,
        },
      ];
    }

    return h(
      tag,
      {
        staticClass: 'h-img',
        class: this.classes,
        style: this.styles,
        directives,
      },
      children,
    );
  },

  created() {
    if (
      this.src &&
      !this.$isServer &&
      (!this.useImageLazyLoad || (!this.inview && !this.manual))
    ) {
      this.load();
    }
  },
})
export class HImgRef extends Vue implements HImgProps {
  @Prop({ type: String, default: 'div' }) readonly tag!: string;
  @Prop({ type: String, required: true }) readonly src!: string;
  @Prop({ type: [Boolean, Object] }) readonly inview!:
    | boolean
    // eslint-disable-next-line no-undef
    | IntersectionObserverInit;

  @Prop({ type: Boolean }) readonly manual!: boolean;
  @Prop({ type: String, default: '' }) readonly alt!: string;
  @Prop({ type: [String, Number] }) readonly width!: string | number;
  @Prop({ type: [String, Number] }) readonly height!: string | number;
  @Prop({ type: Boolean }) readonly fitHeight!: boolean;
  @Prop({ type: Boolean }) readonly liquid!: boolean;
  @Prop({ type: Boolean }) readonly loading!: boolean;
  @Prop({ type: Boolean }) readonly corner!: boolean;
  @Prop({ type: Boolean }) readonly nodrag!: boolean;

  private internalState: HImgLoadState = HImgLoadState.Pending;
  private internalResolvers: Resolver[] = [];
  private internalSrcReady: boolean = !this.inview && !this.manual;
  private inviewTriggered: boolean = false;

  get state() {
    return this.internalState;
  }

  get isPending() {
    return this.state === HImgLoadState.Pending;
  }

  get isLoading() {
    return this.state === HImgLoadState.Loading;
  }

  get isLoaded() {
    return this.state === HImgLoadState.Loaded;
  }

  get isFailed() {
    return this.state === HImgLoadState.Failed;
  }

  get styles() {
    const styles: { [key: string]: string } = {};
    const { width, height, liquid } = this;
    if (!liquid) {
      if (width !== undefined) {
        styles.width = `${width}px`;
      }
      if (height !== undefined) {
        styles.height = `${height}px`;
      }
    }
    return styles;
  }

  get liquidNodeStyles() {
    const styles: { [key: string]: string } = {
      backgroundImage: `url(${this.src})`,
    };
    const { width, height } = this;
    if (width && height) {
      styles.paddingTop = `${((height as number) / (width as number)) * 100}%`;
    }
    return styles;
  }

  get classes() {
    return {
      'h-img--fit-height': this.fitHeight,
      'h-img--liquid': this.liquid,
      'h-img--corner': this.corner,
      'h-img--nodrag': this.nodrag,
    };
  }

  get useImageLazyLoad() {
    return this.$ua.useImageLazyLoad;
  }

  load(): Promise<void> {
    if (this.isLoaded || !this.src) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const resolver: Resolver = { resolve, reject };
      this.internalResolvers.push(resolver);

      if (this.isLoading) {
        return;
      }

      this.setState(HImgLoadState.Loading);

      const image = new Image();

      image.onload = (e) => {
        this.internalSrcReady = true;
        this.setState(HImgLoadState.Loaded);
        this.resolveResolvers('resolve');
        this.$emit('load', e);
      };

      image.onerror = (err) => {
        this.$logger.warn(`[HImg] image load failed. ${this.src}`);
        this.setState(HImgLoadState.Failed);
        this.resolveResolvers('reject', err);
      };

      image.src = this.src;
    });
  }

  private resolveResolvers(type: 'resolve' | 'reject', payload?: any) {
    this.internalResolvers.forEach((resolver) => {
      resolver[type](payload);
    });
    this.internalResolvers = [];
  }

  private setState(state: HImgLoadState) {
    if (this.internalState !== state) {
      this.internalState = state;
      this.$emit('changeState', state);
    }
  }
}

export const HImg = tsx
  .ofType<HImgProps, HImgEmits, HImgScopedSlots>()
  .convert(HImgRef);
