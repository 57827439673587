import type { PinchView } from './pinch-view';
import { TransitionOptions } from '~/libs/transition';

export interface PinchViewTransitionOptions extends TransitionOptions {
  onDone?: () => any;
}

/**
 * トランジションオプション or トランジションの有無
 */
export type RawTransitionOptions = PinchViewTransitionOptions | boolean;

/**
 * トランジションオプションを正規化する
 *
 * @param raw - トランジションオプション or トランジションの有無
 */
export function resolveRawTransitionOptions(
  raw?: RawTransitionOptions,
): PinchViewTransitionOptions | undefined {
  if (raw === true) raw = {};
  if (!raw) return;
  return raw;
}

/**
 * @internal
 */
export interface ChangeOptions {
  /** 値が現在の値と異なる場合、'change' イベントを発生させるかどうか */
  allowChangeEvent?: boolean;

  /** トランジションオプション or トランジションの有無 */
  transition?: RawTransitionOptions;
}

/**
 * @internal
 */
export interface ApplyChangeOpts extends ChangeOptions {
  panX?: number;
  panY?: number;
  scaleDiff?: number;
  originX?: number;
  originY?: number;
}

/**
 * ピンチビューの変形（位置）情報
 */
export interface PinchViewTransform {
  /** スケール率 */
  scale: number;

  /** x座標 */
  x: number;

  /** y座標 */
  y: number;
}

/**
 * ピンチビュー位置情報
 *
 * 中心座標や、オブジェクトの位置情報として利用する
 */
export interface PinchViewPosition {
  /** x座標（内方要素に対する相対％ 0〜1） */
  x: number;

  /** y座標（内方要素に対する相対％ 0〜1） */
  y: number;
}

/**
 * ピンチビュー位置とスケール情報
 *
 * 現在見ている中心位置とスケール率の情報として利用する
 */
export interface PinchViewPositionAndScale extends PinchViewPosition {
  /** スケール率 */
  scale: number;
}

/**
 * 変形オプション
 */
export interface SetTransformOpts
  extends ChangeOptions,
    Partial<PinchViewTransform> {}

/**
 * 中心座標設定オプション
 */
export interface SetPositionOpts
  extends ChangeOptions,
    Partial<PinchViewPositionAndScale> {}

/**
 * 変形の原点の相対元要素指定
 *
 * - `"container"` コンテナ
 * - `"content"` コンテンツ
 */
export type ScaleRelativeToValues = 'container' | 'content';

/**
 * 動作モード
 */
export const MODE = {
  /**
   * ギャラリー
   *
   * モーダル内ギャラリーのように、コンテンツスクロールの考慮不要で、中身の要素を自由に拡大・縮小・スクロールしたい時に利用します。
   *
   * * スケール操作について
   *   * 最小スケール：1、ビューポートからはみ出る場合は、長辺がビューポートに収まるスケール
   *   * 最大スケール：規定値
   *   * マウス：メタキー（ctrl or cmd）＋ホイール。メタキーを押さずにホイール操作するとスクロール
   *   * タッチパッド：ピンチ操作 or メタキー（ctrl or cmd）＋スワイプ。メタキーを押さずにスワイプ操作するとスクロール
   *   * タッチスクリーン：ピンチ操作
   * * スクロール操作について
   *   * スクロール可能範囲：内包要素が外部境界を超えない範囲
   *   * マウス：ホイール or スクロールバー操作
   *   * タッチパッド：スワイプ
   *   * タッチスクリーン：スワイプ
   */
  Gallery: 'gallery',

  /**
   * 埋め込み
   *
   * ページ内Googleマップのように、通常コンテンツ内に埋め込まれ、コンテンツスクロールとの相互操作に考慮が必要な時に利用します。
   *
   * * スケール操作について
   *   * 最小スケール：短辺がビューポートに収まる形でクロップされる状態のスケール
   *   * 最大スケール：規定値
   *   * マウス：メタキー（ctrl or cmd）＋ホイール。メタキーを押さずにホイール操作するとガイド表示
   *   * タッチパッド：ピンチ操作 or メタキー（ctrl or cmd）＋スワイプ。メタキーを押さずにスワイプ操作するとガイド表示
   *   * タッチスクリーン：ピンチ操作
   * * スクロール操作について
   *   * スクロール可能範囲：内包要素の境界がビューポートの境界から離れない範囲
   *   * マウス：D&D
   *   * タッチパッド：D&D
   *   * タッチスクリーン：2本指スワイプ。1本指スワイプするとガイド表示
   */
  Embed: 'embed',
} as const;

export type Mode = typeof MODE[keyof typeof MODE];

/**
 * デフォルトのモード
 */
export const DEFAULT_MODE = MODE.Embed;

/**
 * スケールを段階インクリメントする際の増加率（0〜1）
 *
 * * 今見えている領域に対しての割合
 * * 0.5を指定した場合、今見えている領域の50%がビューポート全体に広がる
 * * 0.25を指定した場合、今見えている領域の75%がビューポート全体に広がる
 */
export const DEFAULT_SCALE_INCREMENT_AMOUNT = 0.5;

/**
 * スケールオプション
 */
export interface ScaleToOpts extends ChangeOptions {
  /** 変形の原点。数値（px）、または "50%" のようなパーセントの文字列。 */
  originX?: number | string;

  /** 変形の原点。数値（px）、または "50%" のようなパーセントの文字列。 */
  originY?: number | string;

  /** 変形の原点の相対元要素指定 */
  relativeTo?: ScaleRelativeToValues;
}

/**
 * スケールインクリメント（デクリメント）オプション
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IncrementScaleOpts extends ChangeOptions {}

/**
 * ピンチビューのイベントペイロードマッピング
 */
export interface PinchViewEventPayloadMap {
  /** 座標やスケールに変化が発生した時 */
  change: PinchView;

  /** 最小スケールが変化した時 */
  changeMinScale: PinchView;

  /** ビューポートのサイズが変化した時 */
  resizeViewport: PinchView;

  /** 内方要素のサイズが変化した時 */
  resizeBody: PinchView;

  /** スケール操作をブロックした時 */
  blockScale: PinchView;

  /** タッチデバイスでの移動操作をブロックした時 */
  blockMove: PinchView;

  /** タッチデバイスでの移動操作が開始した時 */
  moveActivated: PinchView;

  /** ポインター移動による変形時のハンドラ */
  pointerMove: ApplyChangeOpts;
}

/**
 * ピンチビューのイベントハンドラーマッピング
 */
export type PinchViewEventHandlerMap = {
  [EventName in keyof PinchViewEventPayloadMap]?: (
    ev: PinchViewEventPayloadMap[EventName],
  ) => any;
};

/**
 * 初期最小スケール
 *
 * * 初期化後にモードによって計算されるまで利用される
 */
export const INITIAL_MIN_SCALE = 0.01;

/**
 * デフォルトの最小スケール
 */
export const DEFAULT_MAX_SCALE = 1;

/**
 * ピンチビューにおける状態購読オブジェクト
 */
export interface PinchViewObservableObject extends PinchViewTransform {
  /** 最小スケール */
  minScale: number;
  /** 最大スケール */
  maxScale: number;
  /** ビューポート要素 */
  viewport: HTMLElement | null;
  /** ビューポートの幅 */
  viewportWidth: number;
  /** ビューポートの高さ */
  viewportHeight: number;
  /** 内方要素の幅 */
  bodyWidth: number;
  /** 内方要素の高さ */
  bodyHeight: number;
  /** ビューポートの中心が内方要素の幅に対してどの位置を表示しているかの相対値（0 - 1） */
  centerX: number;
  /** ビューポートの中心が内方要素の高さに対してどの位置を表示しているかの相対値（0 - 1） */
  centerY: number;
}

/** 状態購読オブジェクトをマージする */
function mergeObservable(
  base: PinchViewObservableObject,
  overrides?: Partial<PinchViewObservableObject>,
) {
  if (!overrides) return base;

  Object.keys(base).forEach((key) => {
    if (key in overrides) {
      (base as any)[key] = (overrides as any)[key];
    }
  });

  return base;
}

/**
 * ピンチビューにおける状態購読オブジェクトのソースオブジェクトを生成する
 *
 * @returns ピンチビューにおける状態購読オブジェクトのソース
 */
export function createPinchViewObservableSource(
  overrides?: Partial<PinchViewObservableObject>,
): PinchViewObservableObject {
  return mergeObservable(
    {
      scale: 1,
      x: 0,
      y: 0,
      minScale: INITIAL_MIN_SCALE,
      maxScale: DEFAULT_MAX_SCALE,
      viewport: null,
      viewportWidth: 0,
      viewportHeight: 0,
      bodyWidth: 0,
      bodyHeight: 0,
      centerX: 0,
      centerY: 0,
    },
    overrides,
  );
}

/**
 * ピンチビューオプション
 */
export interface PinchViewOptions {
  /** ビューポート要素 */
  viewport?: HTMLElement;

  /**
   * ジェスチャーターゲット要素
   *
   * * viewport指定の要素とは異なる要素でジェスチャーをハンドルしたい場合に指定する
   */
  gesutureTarget?: HTMLElement;

  /** 動作モード */
  mode?: Mode;

  /** イベントハンドラーマップ */
  on?: PinchViewEventHandlerMap;

  /**
   * 最大スケール
   *
   * @default DEFAULT_MAX_SCALE
   */
  maxScale?: number;

  /**
   * スケールを段階インクリメントする際の増加率（0〜1）
   *
   * * 今見えている領域に対しての割合
   * * 0.5を指定した場合、今見えている領域の50%がビューポート全体に広がる
   * * 0.25を指定した場合、今見えている領域の75%がビューポート全体に広がる
   *
   * @default DEFAULT_SCALE_INCREMENT_AMOUNT
   */
  scaleIncrementAmount?: number;

  /**
   * 状態購読オブジェクト
   *
   * * 任意のオブジェクトで値の購読を行うことが可能です
   * * 主にReactやVueなどのリアクティブオブジェクトを指定することでUIフレームワークとシームレスに連携が可能です
   */
  observe?: PinchViewObservableObject;
}
