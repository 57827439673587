import { AsyncCombineRequestBucket } from './bucket';
import { AsyncFn } from './schemes';

/**
 * 指定の非同期処理を、「処理連結版」メソッドに格上げする。
 * これによって、「同一の引数による処理」が連続して実行される際に、1つの処理に束ねる
 *
 * @param func - 非同期処理本体
 * @param thisObj - 処理のthisにバインドしたいオブジェクト
 * @returns - 「処理連結版」メソッドに格上げされた処理
 */
export function asyncCombine<Fn extends AsyncFn>(func: Fn, thisObj?: any): Fn {
  const bucket = new AsyncCombineRequestBucket();

  if (thisObj) {
    func = func.bind(thisObj) as Fn;
  }

  const proc: Fn = ((...args) => {
    return new Promise((resolve, reject) => {
      const req = bucket.getByArgs(args, func);
      req.push({ resolve, reject }, true);
    });
  }) as Fn;

  return proc;
}

/**
 * 指定の非同期処理を、「処理連結版」メソッドに格上げする。
 * これによって、「同一の引数による処理」が連続して実行される際に、1つの処理に束ねる
 *
 * @see {asyncCombine}
 */
export function AsyncCombine<Fn extends AsyncFn>(): MethodDecorator {
  return (target, propertyKey, descriptor) => {
    let proc: Fn | undefined;
    const func = (descriptor.value as unknown) as Fn;

    (descriptor as any).value = function (...args: any[]) {
      if (!proc) {
        proc = asyncCombine(func.bind(this)) as Fn;
      }
      return proc(...args);
    };
  };
}
