import { Middleware } from '@nuxt/types';

const TRAILING_SLASH_RE = /.*\/$/;

const trailingSlashMiddleware: Middleware = (ctx) => {
  const { redirect, route } = ctx;
  const { path, fullPath } = route;
  if (!TRAILING_SLASH_RE.test(path)) {
    const newPath = path + '/';
    const redirectTo = fullPath.replace(path, newPath);
    const { isTest } = ctx.$env;
    const statusCode = isTest ? 302 : 301;
    if (isTest) {
      ctx.$logger.warn(
        `[trailingSlashMiddleware] redirect for slashed path. Route transitions other than the trailing slash are not allowed.`,
      );
      // eslint-disable-next-line no-console
      console.trace();
    }
    return redirect(statusCode, redirectTo);
  }
};

export default trailingSlashMiddleware;
