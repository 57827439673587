import './HLocationMapsViewer.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HBtn } from '../HBtn';
import {
  HLocationMap,
  HLocationMapRef,
  LocationMapTransitionVector,
  LocationMapInitialPosition,
  LocationMapModel,
} from './HLocationMap';
import { ImageLoader } from './loader';
import {
  LocationMaps,
  LocationMap,
  extractLocationMapImageURL,
} from '~/schemes';
import {
  InviewDirectiveSettings,
  InviewDirectiveValues,
} from '~/directives/inview';
import { IncrementScaleOpts } from '~/libs/pinch-view';

export interface HLocationMapsViewerProps {
  /** ロケーションマップと動作設定 */
  value: LocationMaps;

  /**
   * 遅延初期化設定
   *
   * @default true
   *
   * * false を指定した場合コンポーネントマウント後に即時に初期化する
   */
  lazy?: boolean | InviewDirectiveSettings;
}

export interface HLocationMapsViewerEmits {
  /** マップが変更された時 */
  onChangeMap: LocationMap;

  /** マップの読み込みに失敗した時 */
  onError: any;
}

export interface HLocationMapsViewerScopedSlots {}

/**
 * 初期マップ＆座標
 */
interface InitialPosition {
  /**
   * 初期マップ
   *
   * * nullの場合はマップが存在しない時
   */
  map: string | null;

  /** 初期座標 */
  position: LocationMapInitialPosition | null;
}

interface SwitchMapRequest {
  resolve: (map: HLocationMapRef | undefined) => void;
  reject: (err: any) => void;
  promise(): Promise<HLocationMapRef | undefined>;
  map: LocationMap;
}

/**
 * マップ切り替えボタン1つ分のインターフェース
 */
interface MapSelectorItem extends LocationMap {
  disabled?: boolean;
  loading?: boolean;
  onClick: (ev: MouseEvent) => any;
}

/**
 * 「次のマップ候補」を利用するか
 *
 * これはマップのUIがどんなんが良いかわからなくて、新しいパターンを作ってみたけど、こっちも使いそうでよくわからないからフラグでスイッチできるようにしておく
 * @see https://hr-dev.backlog.jp/view/ACCO_CMS-1107
 */
const USE_NEXT_CANDIDATE_MAP = false;

@Component<HLocationMapsViewerRef>({
  name: 'HLocationMapsViewer',
  created() {
    this.loader = new ImageLoader();
    this.initInitialPosition();

    const initialMapKey = this.initialPosition.map;
    initialMapKey && this.setCurrentMapKey(initialMapKey);
  },
  mounted() {
    if (!this.lazySettings) {
      this.init();
    }
  },
  beforeDestroy() {
    this.cancelSwitchMapRequest();
  },
  render(h) {
    const {
      lazySettings,
      currentMap,
      internalSwitchMapRequest,
      nextCandidateMap,
      maps,
    } = this;

    const nextMap = internalSwitchMapRequest && internalSwitchMapRequest.map;

    return (
      <div staticClass="h-location-maps-viewer" v-inview={lazySettings}>
        <div staticClass="h-location-maps-viewer__placeholder" />
        <div staticClass="h-location-maps-viewer__map-group">
          {!!currentMap && (
            <HLocationMap
              ref="map"
              staticClass="h-location-maps-viewer__map"
              key={`${currentMap.key}`}
              value={currentMap}
              initialPosition={this.getInitialPositionByMapKey(currentMap.key)}
              onUpdate={this.handleUpdateMapModel}
            />
          )}
          {!!nextMap && (
            <HLocationMap
              staticClass="h-location-maps-viewer__map h-location-maps-viewer__map--next"
              key={`${nextMap.key}`}
              value={nextMap}
              initialPosition={this.getInitialPositionByMapKey(nextMap.key)}
              onLoad={this.handleLoadNextMap}
              onError={this.handleLoadErrorNextMap}
            />
          )}
        </div>

        <div staticClass="h-location-maps-viewer__controls">
          {!USE_NEXT_CANDIDATE_MAP && maps.length > 1 && (
            <div staticClass="h-location-maps-viewer__map-selector">
              {this.mapItems.map((map) => (
                <HBtn
                  key={map.key}
                  staticClass="h-location-maps-viewer__map-selector__map"
                  size="sm"
                  loading={map.loading}
                  disabled={map.disabled}
                  onClick={map.onClick}>
                  {map.name}
                </HBtn>
              ))}
            </div>
          )}
          {USE_NEXT_CANDIDATE_MAP && !!nextCandidateMap && (
            <HBtn
              staticClass="h-location-maps-viewer__next-candidate"
              size="sm"
              loading={this.isSwitching}
              disabled={this.isInitializing} // スイッチ中は見た目を変えたくないのでdisabledにしない。クリックハンドラ内でブロックする
              onClick={this.handleClickNextCandidate}>
              {nextCandidateMap.name}
            </HBtn>
          )}
          <div staticClass="h-location-maps-viewer__scale-controls">
            <button
              class="h-location-maps-viewer__scale-control"
              type="button"
              title={this.$t('locationMaps.zoomIn') as string}
              value="1"
              disabled={!this.canPinchOut}
              onClick={() => {
                this.incrementScale();
              }}
            />
            <button
              class="h-location-maps-viewer__scale-control"
              type="button"
              title={this.$t('locationMaps.zoomOut') as string}
              value="-1"
              disabled={!this.canPinchIn}
              onClick={() => {
                this.decrementScale();
              }}
            />
          </div>
        </div>
      </div>
    );
  },
})
export class HLocationMapsViewerRef
  extends Vue
  implements HLocationMapsViewerProps {
  $refs!: {
    map: HLocationMapRef;
  };

  @Prop({ type: Object, required: true }) readonly value!: LocationMaps;
  @Prop([Boolean, Object]) readonly lazy?: boolean | InviewDirectiveSettings;

  /** 初期化を開始済みか */
  private booted = false;

  /** 初期化実行中か */
  private initializing = false;

  /** 現在のマップキー */
  private internalCurrentMapKey = '';

  /** 次のマップへのスイッチリクエスト */
  private internalSwitchMapRequest: SwitchMapRequest | null = null;

  /** 画像ローダー */
  private loader!: ImageLoader;

  /** マップの初期表示設定 */
  private initialPosition: InitialPosition = {
    map: null,
    position: null,
  };

  private currentMapModel: LocationMapModel | null = null;

  /** カレントのマップモデル更新時のハンドラ */
  private handleUpdateMapModel(model: LocationMapModel) {
    this.currentMapModel = model;
  }

  /** 拡大可能か */
  get canPinchOut() {
    if (this.isDisabled) return false;
    const model = this.currentMapModel;
    if (!model) return false;
    const { scale, maxScale } = model;
    return scale < maxScale;
  }

  /** 縮小可能か */
  get canPinchIn() {
    if (this.isDisabled) return false;
    const model = this.currentMapModel;
    if (!model) return false;
    const { scale, minScale } = model;
    return scale > minScale;
  }

  /** 遅延初期化設定 */
  get lazySettings(): InviewDirectiveValues | undefined {
    const { booted, lazy = true } = this;
    if (booted || !lazy) return;

    const settings = lazy === true ? {} : lazy;
    return {
      ...settings,
      in: this.handleInview,
    };
  }

  /** 初期化中か */
  get isInitializing() {
    return this.initializing;
  }

  /** マップを切り替え中か */
  get isSwitching() {
    return !!this.internalSwitchMapRequest;
    // return this.switchingImageIsLoading;
  }

  /** マップ全体の操作が無効化されているか */
  get isDisabled() {
    return this.isInitializing || this.isSwitching;
  }

  /** マップの動作設定 */
  get behavior() {
    return this.value.behavior;
  }

  /** 親を持っていない一番先頭のマップ */
  get shallowestMap(): LocationMap {
    const parents = this.maps.filter(
      (map) => !map.parent || !this.isAvairableMapKey(map.parent.key),
    );
    return parents[0];
  }

  /** マップのリスト */
  get maps() {
    return this.value.maps;
  }

  /**
   * マップセレクターのアイテムリスト
   */
  get mapItems(): MapSelectorItem[] {
    const { internalSwitchMapRequest, currentMapKey } = this;

    return this.maps.map((map) => {
      return {
        ...map,

        // 現在選択中の場合非活性にする
        disabled: map.key === currentMapKey,

        // このマップを読み込み中の時はぐるぐるする
        loading:
          !!internalSwitchMapRequest &&
          internalSwitchMapRequest.map.key === map.key,
        onClick: (ev) => {
          // いずれかのマップに切り替え中の時は要求をキャンセルする
          if (this.isSwitching) return;

          this.switchMap(map.key);
        },
      };
    });
  }

  /** 有効なマップキーのリスト */
  get avairableMapKeys() {
    return this.maps.map((map) => map.key);
  }

  /** 現在のマップキー */
  get currentMapKey() {
    return this.internalCurrentMapKey;
  }

  /** 現在のマップ */
  get currentMap() {
    const map = this.getMapByKey(this.currentMapKey);
    return map;
  }

  /**
   * 現在のマップに対しての次の候補マップ
   *
   * * 親がいなく、子供が一個以上いる場合 -> 子供リストの先頭のマップ
   * * 親がいる場合 -> 親マップ
   * * 親も子供もいない場合 -> 自身のリスト内の兄弟マップ
   */
  get nextCandidateMap(): LocationMap | undefined {
    const { currentMap } = this;
    if (!currentMap) return;
    const children = this.getMapChildren(currentMap);
    const { parent } = currentMap;

    // 親がいなく、子供が一個以上いる場合 -> 子供リストの先頭のマップ
    if (!parent && children.length) {
      return children[0];
    }

    // 親がいる場合 -> 親マップ
    const parentMap = parent && this.getMapByKey(parent.key);
    if (parentMap) {
      return parentMap;
    }

    // 親も子供もいない場合 -> 自身のリスト内の兄弟マップ
    const siblings = this.getMapSiblings(currentMap, true);
    return siblings[0];
  }

  /**
   * 現在選択されているかもしれないマップのガイドを非表示にする
   */
  closeGuide() {
    const { map } = this.$refs;
    map && map.closeGuide();
  }

  /**
   * 指定のマップキーが有効なマップキーかチェックする
   *
   * @param mapKey - マップキー
   * @returns 有効な場合true
   */
  isAvairableMapKey(mapKey: string) {
    return this.avairableMapKeys.includes(mapKey);
  }

  /**
   * 指定のキーに対応するマップを取得する
   *
   * @param mapKey - マップキー
   * @returns マップオブジェクト
   */
  getMapByKey(mapKey: string) {
    return this.maps.find((map) => map.key === mapKey);
  }

  /**
   * 現在マウントされているマップコンポーネントのインスタンスを取得する
   */
  getCurrentMapInstance() {
    return this.$refs.map;
  }

  /**
   * 指定のキーに対応する初期ポジションを取得する
   *
   * @param mapKey - マップキー
   */
  getInitialPositionByMapKey(mapKey: string) {
    const { map, position } = this.initialPosition;
    if (position && map === mapKey) return position;
  }

  /**
   * 指定のマップキーに切り替える
   *
   * * マップを操作が無効状態、有効なキーでない、もしくはすでにセットされている場合は何もしない
   *
   * @param mapKey - マップキー
   */
  switchMap(mapKey: string) {
    const { internalSwitchMapRequest } = this;
    if (
      internalSwitchMapRequest &&
      internalSwitchMapRequest.map.key === mapKey
    ) {
      return internalSwitchMapRequest.promise();
    }

    if (
      this.isDisabled ||
      !this.isAvairableMapKey(mapKey) ||
      this.currentMapKey === mapKey
    ) {
      return Promise.resolve();
    }

    const nextMap = this.getMapByKey(mapKey);
    if (!nextMap) {
      return Promise.resolve();
    }
    this.cancelSwitchMapRequest();
    this.closeGuide();

    const promise = new Promise<HLocationMapRef | undefined>(
      (resolve, reject) => {
        // this.nextMap
        this.internalSwitchMapRequest = {
          map: nextMap,
          resolve,
          reject,
          promise: () => promise,
        };
      },
    );

    return promise;
  }

  /**
   * ロケーションマップのスイッチリクエストをキャンセルする
   */
  private cancelSwitchMapRequest() {
    const { internalSwitchMapRequest } = this;
    if (!internalSwitchMapRequest) return;
    this.internalSwitchMapRequest = null;
    internalSwitchMapRequest.resolve(undefined);
  }

  /**
   * スケール率をインクリメントする
   * @param opts - スケールインクリメント（デクリメント）オプション
   */
  incrementScale(opts?: IncrementScaleOpts) {
    const { map } = this.$refs;
    if (!map) return;
    this.closeGuide();
    map.incrementScale({
      transition: true,
      allowChangeEvent: true,
      ...opts,
    });
  }

  /**
   * スケール率をデクリメントする
   * @param opts - スケールインクリメント（デクリメント）オプション
   */
  decrementScale(opts?: IncrementScaleOpts) {
    const { map } = this.$refs;
    if (!map) return;
    this.closeGuide();
    map.decrementScale({
      allowChangeEvent: true,
      transition: true,
      ...opts,
    });
  }

  /**
   * ロケーションマップ読み込み完了時のハンドラ
   *
   * @param nextMap - 次のマップコンポーネントのインスタンス
   */
  private async handleLoadNextMap(nextMap: HLocationMapRef) {
    const { internalSwitchMapRequest, currentMap } = this;
    if (!internalSwitchMapRequest) return;

    const { map } = this.$refs;

    /** トランジション用プロミスインスタンスのリスト */
    const promises: Promise<void>[] = [];

    /** トランジション方向 */
    const vec: LocationMapTransitionVector = currentMap
      ? this.calcSwitchTransition(
          currentMap.key,
          internalSwitchMapRequest.map.key,
        )
      : 'same';

    if (map) {
      promises.push(map.transitionTo(vec, 'leave'));
    }
    promises.push(nextMap.transitionTo(vec, 'enter'));

    // トランジションの完了を待つ
    await Promise.all(promises);

    // カレントのキーを更新する
    // * これによりコンポーネントインスタンスが更新されるが、マップのキーで管理しているのでkeep-alive的な効果が発生し再レンダリングが抑制されている
    this.setCurrentMapKey(internalSwitchMapRequest.map.key);

    this.internalSwitchMapRequest = null;

    // 呼び出しプロミスを解決しておく
    internalSwitchMapRequest.resolve(nextMap);

    // 新しい方でモデルを更新しておく
    nextMap.model && this.handleUpdateMapModel(nextMap.model);

    // レンダリングフェーズ後に初期位置情報を削除しておく
    // ※ 初期位置を利用するのは初期表示の時だけで切り替え後には利用しないから
    this.$nextTick(() => {
      this.initialPosition.position = null;
    });
  }

  /**
   * ロケーションマップの読み込みエラー時のハンドラ
   *
   * @param err - エラー
   */
  private handleLoadErrorNextMap(err: any) {
    const { internalSwitchMapRequest } = this;
    if (!internalSwitchMapRequest) return;
    this.internalSwitchMapRequest = null;
    internalSwitchMapRequest.reject(err);
  }

  /**
   * 指定のマップの子マップを全て取得する
   *
   * @param mapOrKey - 親マップ、またはそのキー
   * @returns 子マップのリスト
   */
  private getMapChildren(mapOrKey: string | LocationMap) {
    const mapKey = typeof mapOrKey === 'string' ? mapOrKey : mapOrKey.key;
    return this.maps.filter(({ parent }) => !!parent && parent.key === mapKey);
  }

  /**
   * 指定のマップの兄弟マップ（親が一緒）を全て取得する
   *
   * @param mapOrKey - 対象のマップ、またはそのキー
   * @param excludeSelf - 自身を取得リストから除外するか
   * @returns 兄弟マップのリスト
   */
  private getMapSiblings(
    mapOrKey: string | LocationMap,
    excludeSelf?: boolean,
  ): LocationMap[] {
    const map =
      typeof mapOrKey === 'string' ? this.getMapByKey(mapOrKey) : mapOrKey;
    if (!map) return [];
    const { parent } = map;
    const parentKey = (parent && parent.key) || undefined;
    return this.maps.filter((target) => {
      if (excludeSelf && target.key === map.key) return false;
      const targetParent = target.parent;
      const targetParentKey = (targetParent && targetParent.key) || undefined;
      return parentKey === targetParentKey;
    });
  }

  /**
   * 指定の2つのマップの親マップが同一のマップかチェックする
   *
   * @param a - マップオブジェクト
   * @param b - マップオブジェクト
   * @returns 同一である場合true
   */
  private isSameParent(a: LocationMap, b: LocationMap) {
    const aParentKey = (a.parent && a.parent.key) || null;
    const bParentKey = (b.parent && b.parent.key) || null;
    return aParentKey === bParentKey;
  }

  /**
   * 指定のマップが、指定のコンテナマップの内方マップであるかチェックする
   *
   * @param target - 含まれていることを期待しているマップ
   * @param container - コンテナであることを期待しているマップ
   */
  private mapIsContainedFor(target: LocationMap, container: LocationMap) {
    let isContained = false;
    let { parent } = target;
    while (parent) {
      const parentMap = this.getMapByKey(parent.key);
      if (!parentMap) {
        break;
      }
      if (parentMap.key === container.key) {
        isContained = true;
        break;
      }
      parent = parentMap.parent;
    }
    return isContained;
  }

  /**
   * 2つのマップ間の遷移方向を計算する
   *
   * @param beforeMapKey - 前のマップ
   * @param nextMapKey - 次のマップ
   * @returns 遷移方向
   */
  private calcSwitchTransition(
    beforeMapKey: string,
    nextMapKey: string,
  ): LocationMapTransitionVector {
    const beforeMap = this.getMapByKey(beforeMapKey);
    if (!beforeMap) {
      return 'same';
    }

    const nextMap = this.getMapByKey(nextMapKey);
    if (!nextMap) {
      return 'same';
    }

    // 前のマップと次のマップの親が同じか
    if (this.isSameParent(beforeMap, nextMap)) {
      return 'same';
    }

    // 前のマップが次のマップに含まれているか（次のマップは前のマップの親か祖先）
    if (this.mapIsContainedFor(beforeMap, nextMap)) {
      return 'out';
    }

    // 次のマップが前のマップに含まれているか（次のマップは前のマップの子供か子孫）
    if (this.mapIsContainedFor(nextMap, beforeMap)) {
      return 'in';
    }

    return 'same';
  }

  /**
   * 現在のマップキーをセットする
   *
   * * 有効なキーでない、もしくはすでにセットされている場合は何もしない
   *
   * @param mapKey - マップキー
   */
  private setCurrentMapKey(mapKey: string) {
    const { currentMapKey } = this;
    if (!this.isAvairableMapKey(mapKey) || currentMapKey === mapKey) {
      return;
    }

    this.internalCurrentMapKey = mapKey;
    const { currentMap } = this;
    currentMap && this.$emit('changeMap', currentMap);
  }

  /**
   * 次の候補クリック時のハンドラ
   *
   * @param ev - マウスイベント
   */
  private handleClickNextCandidate(ev: MouseEvent) {
    const { nextCandidateMap } = this;
    if (!nextCandidateMap) return;
    this.switchMap(nextCandidateMap.key);
  }

  /**
   * 初期位置情報を初期化する
   *
   * * createdフックの中でのみ呼び出される
   */
  private initInitialPosition() {
    const { initialPosition } = this.behavior;

    // 初期マップ設定があり、かつ有効なマップキーである場合はそのまま設定しておく
    if (initialPosition.map && this.isAvairableMapKey(initialPosition.map)) {
      this.initialPosition = {
        map: initialPosition.map,
        position: {
          scale: initialPosition.scale,
          x: initialPosition.x,
          y: initialPosition.y,
        },
      };
      return;
    }

    // 浅いマップを探して見つかれば初期マップに設定しておく
    const firstMap = this.shallowestMap || this.maps[0];
    const firstMapKey = firstMap ? firstMap.key : null;

    if (firstMapKey) {
      this.initialPosition = {
        map: firstMapKey,
        position: null,
      };
    }
  }

  /**
   * 初期化を行う
   *
   * * mountedフックか遅延初期化タイミング検知時にのみ呼び出される
   */
  private async init() {
    if (this.booted) return;

    this.booted = true;

    const { currentMap } = this;
    const mapImageURL = currentMap && extractLocationMapImageURL(currentMap);
    if (!mapImageURL) return;

    this.initializing = true;

    try {
      await this.loader.load(mapImageURL);
      this.initializing = false;
    } catch (err) {
      this.initializing = false;
      this.$emit('error', err);
      throw err;
    }
  }

  /**
   * 要素とビューポート交差時のハンドラ
   *
   * * 遅延設定が行われている時にのみ呼び出される
   */
  private handleInview() {
    this.init();
  }
}

export const HLocationMapsViewer = tsx
  .ofType<
    HLocationMapsViewerProps,
    HLocationMapsViewerEmits,
    HLocationMapsViewerScopedSlots
  >()
  .convert(HLocationMapsViewerRef);
