import './HThemeDecoratedTitle.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { DecorationSize, HThemeDecoration } from '../HThemeDecoration';
import { mergeVNodeData } from '~/helpers';

/**
 * 飾り付きのタイトル
 */
export const HThemeDecoratedTitle = tsx.component({
  name: 'HThemeDecoratedTitle',
  functional: true,
  props: {
    /**
     * デコレーションと文字セットでサイズ変更される
     */
    size: {
      type: String as PropType<DecorationSize>,
      default: 'md',
    },
    /**
     * タイトルの下にサブタイトルのような文字が来る場合に使用
     */
    subTitle: {
      type: String,
    },
    /**
     * タグ名を指定したい場合に利用する
     *
     * * デフォルトは `"div"`
     */
    tag: {
      type: String,
      default: 'div',
    },
  },
  render(h, { props, children, data, parent }) {
    const { size, subTitle, tag: TagName } = props;

    return (
      <TagName
        {...mergeVNodeData(data, {
          staticClass: 'h-theme-decorated-title',
          class: `h-theme-decorated-title--${size}`,
        })}>
        {children}
        <span staticClass="h-theme-decorated-title__sub-title">{subTitle}</span>
        {parent.$theme.is('risonare') && (
          <HThemeDecoration
            staticClass="h-theme-decorated-title__deco"
            size={size}
          />
        )}
      </TagName>
    );
  },
});
