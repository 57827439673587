import './HDialog.scss';

import * as tsx from 'vue-tsx-support';
import { Component, Mixins } from 'vue-property-decorator';
import {
  VStackDialog,
  VStackDialogProps,
  VStackDialogEmits,
  VStackDialogScopedSlots,
} from '@dadajam4/vue-stack';

export interface HDialogProps extends VStackDialogProps {}

export interface HDialogEmits extends VStackDialogEmits {}

export interface HDialogScopedSlots extends VStackDialogScopedSlots {}

@Component<HDialogRef>({
  name: 'HDialog',
})
export class HDialogRef extends Mixins(VStackDialog) {}

export const HDialog = tsx
  .ofType<HDialogProps, HDialogEmits, HDialogScopedSlots>()
  .convert(HDialogRef);
