import './-sorry.scss';

import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';
import { HFooterCopy } from '~/components';

@Component<HSorryPage>({
  name: 'h-sorry-page',
  render() {
    return (
      <div staticClass="h-sorry-page">
        <div staticClass="h-sorry-page__main">
          <p>
            大変申し訳ございませんが、お使いのブラウザではこのページをご利用いただけません。
            <br />
            以下のブラウザで本サイトを利用可能です。
          </p>

          <h2>動作環境</h2>
          <dl>
            <dt>Windows</dt>
            <dd>
              <ul>
                <li>
                  <a
                    href="https://support.microsoft.com/ja-jp/help/17621/internet-explorer-downloads"
                    target="_blank"
                    rel="noopener">
                    Microsoft Internet Explorer 11
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.microsoft.com/ja-jp/windows/microsoft-edge"
                    target="_blank"
                    rel="noopener">
                    Microsoft Edge
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.google.co.jp/chrome"
                    target="_blank"
                    rel="noopener">
                    Chrome
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.mozilla.org/ja/firefox/new/"
                    target="_blank"
                    rel="noopener">
                    Firefox
                  </a>
                </li>
              </ul>
            </dd>
            <dt>macOS</dt>
            <dd>
              <ul>
                <li>
                  <a
                    href="https://www.apple.com/jp/safari/"
                    target="_blank"
                    rel="noopener">
                    Safari
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.google.co.jp/chrome"
                    target="_blank"
                    rel="noopener">
                    Chrome
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.mozilla.org/ja/firefox/new/"
                    target="_blank"
                    rel="noopener">
                    Firefox
                  </a>
                </li>
              </ul>
            </dd>
            <dt>Android</dt>
            <dd>
              <ul>
                <li>
                  <a
                    href="https://www.google.co.jp/chrome"
                    target="_blank"
                    rel="noopener">
                    Chrome
                  </a>
                </li>
              </ul>
            </dd>
            <dt>iOS</dt>
            <dd>
              <ul>
                <li>
                  <a
                    href="https://www.apple.com/jp/safari/"
                    target="_blank"
                    rel="noopener">
                    Safari
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.google.co.jp/chrome"
                    target="_blank"
                    rel="noopener">
                    Chrome
                  </a>
                </li>
              </ul>
            </dd>
          </dl>

          <p style="margin-top: 40px">
            星野リゾート公式サイト
            <br />
            <a href="https://hoshinoresorts.com/jp/">
              https://hoshinoresorts.com/jp/
            </a>
          </p>
        </div>
        <div staticClass="h-sorry-page__footer">
          <HFooterCopy />
        </div>
      </div>
    );
  },
})
export default class HSorryPage extends Vue {}
