import Vue from 'vue';
import { Context, Plugin } from '@nuxt/types';
import { Logger, createLogger } from '../logger';

declare module 'vue/types/vue' {
  export interface Vue {
    $logger: Logger;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $logger: Logger;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $logger: Logger;
  }
}

const plugin: Plugin = (context, inject) => {
  const logger = createLogger({
    threshold: context.$env.isProduction ? 'info' : 'debug',
    plain: process.server && !context.$env.isDevelop,
  });
  context.$logger = logger;
  inject('logger', logger);
};

export default plugin;
