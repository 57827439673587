import './HAttractiveIntroductionList.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { HHeading } from '../HHeading';
import { SpecialPageModuleFilter } from '../../composables/module-filter/filter';
import { HAttractiveIntroduction } from './HAttractiveIntroduction';
import {
  HBtn,
  HCheckboxGroup,
  HExpandTransition,
  HIcon,
  HSpecialPageViewRef,
} from '~/components';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import {
  AvailableLanguage,
  AttractiveIntroductionListModule,
  SearchTagCategoryForGF,
} from '~/schemes';

type ResolvedAttractiveIntroductionListModule = ResolvedLanugageData<
  AttractiveIntroductionListModule['properties'],
  AvailableLanguage
>;

export interface HAttractiveIntroductionListProps {
  value: ResolvedAttractiveIntroductionListModule;

  /**
   * コンテナクラスの付与を無効にする場合に指定する
   */
  disableContainer?: boolean;
}

export interface HAttractiveIntroductionListEmits {}

export interface HAttractiveIntroductionListScopedSlots {}

/**
 * アクティビティリストをフィルタするための条件オを入力するフォーム
 *
 * * `<HActivitiesFilterProvider />` の中でのみ動作するように実装されています
 */
@Component<HAttractiveIntroductionListRef>({
  name: 'HAttractiveIntroductionList',
  inject: ['specialPageViewRoot'],
  created() {
    this.filter = new SpecialPageModuleFilter(
      {
        properties: this.value,
        childKey: 'children',
        searchTagCategories: this.searchTagCategories,
      },
      this,
    );
  },
  render() {
    const { heading, size } = this.value;
    const { categories, selectedTags, filteredChildren } = this;

    return (
      <div
        staticClass="v-attractive-introduction-list-module"
        class={[
          `v-attractive-introduction-list-module--${size}`,
          `h-sp-grid--${size}`,
          !this.disableContainer && 'h-container',
        ]}>
        {!!heading && (
          <HHeading
            value={heading.properties}
            staticClass="v-attractive-introduction-list-module__title"
            disableContainer
          />
        )}

        {/* 絞り込み条件 */}
        {!!categories.length && (
          <section staticClass="v-attractive-introduction-list-module-filter">
            <div staticClass="v-attractive-introduction-list-module-filter__control">
              <button
                staticClass="v-attractive-introduction-list-module-filter__activator"
                class={
                  this.isOpened &&
                  'v-attractive-introduction-list-module-filter__activator--active'
                }
                type="button"
                onClick={this.toggle}>
                <span staticClass="v-attractive-introduction-list-module-filter__activator__label">
                  {this.filterControlHeading}
                </span>
                <HIcon
                  staticClass="v-attractive-introduction-list-module-filter__activator__icon"
                  class={
                    this.isOpened &&
                    'v-attractive-introduction-list-module-filter__activator--icon-active'
                  }
                  name="keyboard-arrow-down"
                />
              </button>
            </div>
            <HExpandTransition>
              <div v-show={this.isOpened}>
                <div staticClass="v-attractive-introduction-list-module-filter__search-items">
                  {/* 絞り込みチェックボックス */}
                  {categories.map((category) => {
                    return (
                      <div>
                        <legend staticClass="v-attractive-introduction-list-module-filter__search-items__label">
                          {category.name}
                        </legend>
                        <HCheckboxGroup
                          key={category._id}
                          v-model={category.selectedSlugs}
                          items={category.tags.map(({ label, slug }) => {
                            return {
                              label,
                              value: slug,
                            };
                          })}
                          size="sm"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </HExpandTransition>
            {this.isOpened && (
              <div>
                {/* 絞り込みタグ表示エリア */}
                <div>
                  <h5
                    staticClass="v-attractive-introduction-list-module-filter-title"
                    class={
                      !selectedTags.length &&
                      'v-attractive-introduction-list-module-filter-title--disabled'
                    }>
                    {this.$t('label.filters')}
                  </h5>
                  <div staticClass="v-attractive-introduction-list-module-filter-search-condition">
                    <div staticClass="v-attractive-introduction-list-module-filter-search-condition__tags">
                      {this.categories.flatMap((category) => {
                        return category.selectedTags.map(
                          ({ _id, slug, label }) => {
                            return (
                              <HBtn
                                key={_id}
                                staticClass="v-attractive-introduction-list-module-filter-search-condition__tag"
                                size="sm"
                                onClick={() =>
                                  (category.selectedSlugs = category.selectedSlugs.filter(
                                    (selectedSlug) => selectedSlug !== slug,
                                  ))
                                }>
                                {label}
                                <HIcon name="close-circle" right />
                              </HBtn>
                            );
                          },
                        );
                      })}
                    </div>
                    <HBtn
                      staticClass="v-attractive-introduction-list-module-filter-search-condition__clear"
                      size="sm"
                      disabled={!selectedTags.length}
                      onClick={() => this.filter.clear()}>
                      Clear All
                      <HIcon name="close-circle" right />
                    </HBtn>
                  </div>
                </div>
                {/* 検索結果数表示エリア */}
                <p staticClass="v-attractive-introduction-list-module-filter-result">
                  {this.$t('label.searchResult')}：
                  <span staticClass="v-attractive-introduction-list-module-filter-result__count">
                    {filteredChildren.length}
                  </span>
                </p>
              </div>
            )}
          </section>
        )}

        <div staticClass="v-attractive-introduction-list-module__children">
          {filteredChildren.map(({ key, properties }) => (
            <HAttractiveIntroduction
              staticClass="v-attractive-introduction-list-module__child"
              value={properties}
              key={key}
              disableContainer
            />
          ))}
        </div>
      </div>
    );
  },
})
export class HAttractiveIntroductionListRef
  extends Vue
  implements HAttractiveIntroductionListProps {
  readonly specialPageViewRoot?: HSpecialPageViewRef;
  @Prop(Object) readonly value!: ResolvedAttractiveIntroductionListModule;

  /** コンテナクラスの付与を無効にする場合に指定する */
  @Prop(Boolean) readonly disableContainer!: boolean;

  /**
   * 絞り込みチェクボックスを表示/非表示を制御する
   * - true: 表示 false: 非表示
   */
  private isOpened: boolean = false;

  /** 絞り込み全般処理 */
  private filter!: SpecialPageModuleFilter<
    ResolvedAttractiveIntroductionListModule,
    'children'
  >;

  /** 見出し */
  get filterControlHeading() {
    return this.filter.controlHeading;
  }

  /** 絞り込みチェクボックスに表示する検索カテゴリリスト */
  get categories() {
    return this.filter.categories;
  }

  /** 選択された検索タグリスト */
  get selectedTags() {
    return this.categories.flatMap((category) =>
      category.selectedTags.map((tag) => tag.label),
    );
  }

  /** 画面表示対象の子モジュール(施設紹介) */
  get filteredChildren() {
    return this.filter.filteredChildren;
  }

  /** 検索タグカテゴリマスタ */
  get searchTagCategories(): SearchTagCategoryForGF[] {
    return this.specialPageViewRoot?.searchTagCategories || [];
  }

  /** 絞り込みチェクボックスの表示切り替え */
  toggle() {
    this.isOpened = !this.isOpened;
  }
}

export const HAttractiveIntroductionList = tsx
  .ofType<
    HAttractiveIntroductionListProps,
    HAttractiveIntroductionListEmits,
    HAttractiveIntroductionListScopedSlots
  >()
  .convert(HAttractiveIntroductionListRef);
