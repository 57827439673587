import * as tsx from 'vue-tsx-support';
import { Component, Mixins, Model, Prop, Watch } from 'vue-property-decorator';
import {
  HFormNode,
  HFormNodeProps,
  HFormNodeEmits,
  HFormNodeScopedSlots,
} from './mixins/HFormNode';

export interface HFormGroupInvalidItem {
  id: number;
}

export interface HFormGroupProps extends HFormNodeProps {
  value?: boolean;
  tag?: string;
}

export interface HFormGroupEmits extends HFormNodeEmits {
  onInput: boolean;
}

export interface HFormGroupScopedSlots extends HFormNodeScopedSlots {}

@Component<HFormGroupRef>({
  name: 'HFormGroup',
  provide() {
    return {
      formGroup: this,
    };
  },
  render() {
    const { tag: TagName } = this;
    return <TagName staticClass="h-form-group">{this.$slots.default}</TagName>;
  },
})
export class HFormGroupRef
  extends Mixins(HFormNode)
  implements HFormGroupProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;
  @Prop({ type: String, default: 'div' }) readonly tag!: string;

  protected readonly groupNodes: HFormNode[] = [];
  protected invalidNodes: HFormNode[] = [];

  get invalidItems() {
    return this.invalidNodes.map((vm) => {
      return {
        id: vm.formNodeId,
      };
    });
  }

  get invalid() {
    return this.invalidItems.length > 0;
  }

  get valid() {
    return !this.invalid;
  }

  async validateAll() {
    await Promise.all(this.groupNodes.map((node) => node.validateSelf()));
    return this.valid;
  }

  @Watch('valid', { immediate: true })
  protected validChangeHandler() {
    this.$emit('input', this.valid);
  }

  private pushInvalidNode(node: HFormNode) {
    if (!this.invalidNodes.includes(node)) {
      this.invalidNodes.push(node);
    }
  }

  private removeInvalidNode(node: HFormNode) {
    const index = this.invalidNodes.indexOf(node);
    if (index !== -1) {
      this.invalidNodes.splice(index, 1);
    }
  }

  /** @private */
  updateInvalidNodesByNode(node: HFormNode) {
    if (node.hasMyError && !this.invalidNodes.includes(node)) {
      this.pushInvalidNode(node);
    } else {
      this.removeInvalidNode(node);
    }
  }

  /** @private */
  joinGroupFromNode(node: HFormNode) {
    if (!this.groupNodes.includes(node)) {
      this.groupNodes.push(node);
    }
    this.updateInvalidNodesByNode(node);
  }

  /** @private */
  leaveGroupFromNode(node: HFormNode) {
    const index = this.groupNodes.indexOf(node);
    if (index !== -1) {
      this.groupNodes.splice(index, 1);
    }
    this.removeInvalidNode(node);
  }
}

export const HFormGroup = tsx
  .ofType<HFormGroupProps, HFormGroupEmits, HFormGroupScopedSlots>()
  .convert(HFormGroupRef);
