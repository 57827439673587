import './HRoomDetail.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode, VNodeChildren } from 'vue';
import { Component, Model, Prop } from 'vue-property-decorator';
import { HBtn, HIcon, IconName, HExpandButton } from '~/components';
import {
  HotelDetail,
  HotelRoom,
  RoomFacilityType,
  WaterSectionType,
  isYgetsableHotelDetail,
} from '~/schemes';
import { dumpGeneralOptions2Text } from '~/helpers';

interface MyItem {
  key: string;
  icon: IconName;
  body: VNodeChildren;
  wrap?: boolean;
}

interface MyLabelItem extends MyItem {
  label: string;
}

export interface HRoomDetailProps {
  value?: boolean;
  hotel: HotelDetail;
  room: HotelRoom;
}

export interface HRoomDetailEmits {
  onInput: boolean;
}

export interface HRoomDetailScopedSlots {}

@Component<HRoomDetailRef>({
  name: 'HRoomDetail',

  render(h) {
    const { splitedItems } = this;
    const bodyChildren: VNode[] = splitedItems.map((items, itemsIndex) => {
      const children: VNode[] = items.map((item) => {
        const { body } = item;
        const [bodyDirectives, bodyChildren] =
          typeof body === 'string'
            ? [
                [
                  {
                    name: 'wysiwyg',
                    value: body,
                  },
                ],
                undefined,
              ]
            : [[], body];

        return (
          <li staticClass="h-room-detail__body__item" key={item.key}>
            <HIcon
              staticClass="h-room-detail__body__item__icon"
              name={item.icon}
            />
            <div staticClass="h-room-detail__body__item__detail">
              <h4 staticClass="h-room-detail__body__item__label">
                {item.label}
              </h4>
              {h(
                'div',
                {
                  staticClass: 'h-room-detail__body__item__body',
                  directives: bodyDirectives,
                },
                bodyChildren,
              )}
            </div>
          </li>
        );
      });
      return h(
        'ul',
        {
          staticClass: 'h-room-detail__body__rows',
          key: itemsIndex,
        },
        children,
      );
    });

    const { books } = this.room;
    const isMultiple = books.length > 1;
    const $bookBtns = books
      .filter(({ id, externalUrl }) => {
        return !!id || !!externalUrl;
      })
      .map(({ id, externalUrl, text }) => {
        const key = (id || externalUrl) as string;
        const children = isMultiple
          ? [
              <div
                staticClass="h-room-detail__footer__action__words"
                v-wysiwyg={text}
              />,
            ]
          : (this.$t('action.gotoReserveThisRoom') as string);

        let href: string;
        if (isYgetsableHotelDetail(this.hotel) && !!id) {
          href = this.$hotel.createYgetsRoomUrl(this.hotel, id);
        } else {
          href = externalUrl as string;
        }
        return (
          <HBtn
            key={key}
            staticClass="h-room-detail__footer__action"
            class={{
              'h-room-detail__footer__action--multi': isMultiple,
            }}
            color="primary-wrap"
            width="lg"
            href={href}
            target="_blank">
            {children}
          </HBtn>
        );
      });

    return (
      <div staticClass="h-room-detail">
        <HExpandButton>
          <div staticClass="h-room-detail__box" ref="box">
            <div staticClass="h-room-detail__body">{bodyChildren}</div>
            <div staticClass="h-room-detail__footer">
              <div
                staticClass="h-room-detail__footer__actions"
                class={{
                  'h-room-detail__footer__actions--multi': isMultiple,
                }}>
                {$bookBtns}
              </div>
            </div>
          </div>
        </HExpandButton>
      </div>
    );
  },
})
export class HRoomDetailRef extends Vue implements HRoomDetailProps {
  $el!: HTMLElement;

  $refs!: {
    box: HTMLElement;
  };

  @Model('input', { type: Boolean }) readonly value!: boolean;
  @Prop({ type: Object, required: true }) readonly hotel!: HotelDetail;
  @Prop({ type: Object, required: true }) readonly room!: HotelRoom;

  get labelItems(): MyLabelItem[] {
    const { room } = this;
    const items: MyLabelItem[] = [];

    const { beds, bedsMemo } = room;

    if (beds.length > 0) {
      const bodyChildren: VNode[] = [
        <ul staticClass="h-plain-list">
          {beds.map(({ body, memo }, index) => {
            return (
              <li staticClass="h-room-detail__bed" key={index}>
                {body}
                {!!memo && (
                  <small staticClass="h-small h-small--inline">{memo}</small>
                )}
              </li>
            );
          })}
        </ul>,
      ];
      if (bedsMemo) {
        bodyChildren.push(
          <small staticClass="h-small h-small--block">{bedsMemo}</small>,
        );
      }
      items.push({
        key: 'bed',
        icon: 'bed',
        label: this.$t('label.beds') as string,
        body: bodyChildren,
      });
    }

    if (room.wifi) {
      items.push({
        key: 'wifi',
        icon: 'wifi',
        label: this.$t('label.wifi') as string,
        body: room.wifi,
      });
    }

    if (room.facility && room.facility.length) {
      items.push({
        key: 'facility',
        icon: 'amenity',
        label: this.$t('label.facilitiesAndAmenities') as string,
        body: dumpGeneralOptions2Text<RoomFacilityType>(
          this.$commons.room.facilities,
          room.facility,
          this,
        ),
      });
    }

    if (room.waterSection && room.waterSection.length) {
      items.push({
        key: 'waterSection',
        icon: 'shower',
        label: this.$t('label.waterSection') as string,
        body: dumpGeneralOptions2Text<WaterSectionType>(
          this.$commons.room.waterSections,
          room.waterSection,
          this,
        ),
      });
    }

    items.push({
      key: 'childLying',
      icon: 'baby',
      label: this.$t('label.childrenAndBeds') as string,
      body: room.childLying || '-',
    });

    if (room.accessibility) {
      items.push({
        key: 'accessibility',
        icon: 'accessibility',
        label: this.$t('label.accessibility') as string,
        body: room.accessibility,
      });
    }

    if (room.memo) {
      items.push({
        key: 'memo',
        icon: 'ellipsis-h-circle-outline',
        label: this.$t('label.generalMemo') as string,
        body: room.memo,
      });
    }
    return items;
  }

  get splitedItems(): MyLabelItem[][] {
    const { labelItems } = this;
    const breakPoint = Math.round(labelItems.length / 2);
    const splitedItems: MyLabelItem[][] = [[]];
    let rowIndex = 0;
    labelItems.forEach((item, index) => {
      if (index === breakPoint) {
        rowIndex++;
      }
      let row = splitedItems[rowIndex];
      if (!row) {
        row = [];
        splitedItems.push(row);
      }
      row.push(item);
    });
    return splitedItems;
  }
}

export const HRoomDetail = tsx
  .ofType<HRoomDetailProps, HRoomDetailEmits, HRoomDetailScopedSlots>()
  .convert(HRoomDetailRef);
