import './HSimpleMap.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as styles from './styles';
// eslint-disable-next-line import/no-webpack-loader-syntax
import SVG_TEMPLATE from '!!raw-loader!./pin.svg';
import {
  loadGoogleMapsApi,
  GoogleMapsApi,
  PlacesService,
  PlaceResult,
  PlacesServiceStatus,
  MapTypeStyle,
} from '~/services/gmap';
import { HProgressCircular } from '~/components';

export type HSimpleMapMarkerOptions = ConstructorParameters<
  GoogleMapsApi['Marker']
>[0];

export type HSimpleMapStyle = 'default' | 'grayscale';

export const HSimpleMapStyles: Partial<
  Record<HSimpleMapStyle, MapTypeStyle[]>
> = {
  grayscale: styles.grayscale,
};
// export type HSimpleMapGrayscaleProp = boolean | 'all' | 'map';

export interface HSimpleMapMarkerInfo {
  name?: string;
  body?: string;
}

export interface HSimpleMapProps {
  query: string | string[];
  embed?: string | null;
  zoom?: number;
  mapStyle?: HSimpleMapStyle;
  // grayscale?: HSimpleMapGrayscaleProp;
  lazy?: boolean;
  markerInfo?: string | HSimpleMapMarkerInfo;
}

const PIN_COLOR_MAP: {
  [key: string]: string;
} = {
  default: '#D95040',
  kai: '#E13F34',
};

export interface HSimpleMapEmits {}

export interface HSimpleMapScopedSlots {}

export type HSimpleMapInitializeState =
  | 'pending'
  | 'initializing'
  | 'initialized'
  | 'error';

@Component<HSimpleMapRef>({
  name: 'HSimpleMap',
  mounted() {
    if (!this.lazy) {
      this.init();
    }
  },
  beforeDestroy() {
    this.destroy();
  },
  render() {
    const { embedUrl } = this;

    return (
      <div
        staticClass="h-simple-map"
        class={this.classes}
        v-inview={
          this.lazy &&
          !this.inviewTriggered && {
            in: () => {
              if (!this.inviewTriggered) {
                this.inviewTriggered = true;
                this.init();
              }
            },
          }
        }>
        <div staticClass="h-simple-map__map" ref="map" />
        {!!embedUrl && (
          <iframe
            staticClass="h-simple-map__embed"
            src={embedUrl}
            allowfullscreen
            loading="lazy"
          />
        )}
        {this.initializing && (
          <HProgressCircular
            staticClass="h-simple-map__loading"
            indeterminate
            width="2"
            size="48"
          />
        )}
      </div>
    );
  },
})
export class HSimpleMapRef
  extends Vue
  implements
    Omit<HSimpleMapProps, 'videoId' | 'url' | 'quality' | 'texttrack'> {
  $refs!: {
    map: HTMLDivElement;
  };

  @Prop({ type: [String, Array], required: true }) readonly query!:
    | string
    | string[];

  @Prop(String) embed?: string;

  @Prop({ type: Number, default: 12 }) readonly zoom!: number;
  // @Prop({ type: [Boolean, String] })
  // readonly grayscale?: HSimpleMapGrayscaleProp;
  @Prop({ type: String, default: 'default' })
  readonly mapStyle!: HSimpleMapStyle;

  @Prop({ type: [String, Object] }) readonly markerInfo?:
    | string
    | HSimpleMapMarkerInfo;

  @Prop(Boolean) readonly lazy!: boolean;

  private internalState: HSimpleMapInitializeState = 'pending';
  private _api: GoogleMapsApi | null = null;
  private _map: InstanceType<GoogleMapsApi['Map']> | null = null;
  private _placesService: PlacesService | null = null;
  private internalMarkers: InstanceType<GoogleMapsApi['Marker']>[] = [];
  private isDestroyed: boolean = false;
  private inviewTriggered: boolean = false;

  get embedUrl() {
    const { embed } = this;
    if (!embed) return;
    if (embed.startsWith('http')) return embed;
    const srcMatch = embed.match(/\ssrc="(.*?)"/);
    return (srcMatch && srcMatch[1]) || undefined;
  }

  get initializeState() {
    return this.internalState;
  }

  get initialized() {
    return this.initializeState === 'initialized';
  }

  get initializing() {
    return this.initializeState === 'initializing';
  }

  get computedMarkerInfo(): HSimpleMapMarkerInfo | undefined {
    const { markerInfo } = this;
    if (!markerInfo) return;
    return typeof markerInfo === 'string' ? { body: markerInfo } : markerInfo;
  }

  // get computedGrayscale() {
  //   const { grayscale } = this;
  //   if (!grayscale) return false;
  //   if (grayscale === true) return 'all';
  //   return grayscale;
  // }

  get classes() {
    return {
      // 'h-simple-map--grayscale': this.computedGrayscale === 'all',
      [`h-simple-map--${this.mapStyle}`]: true,
      'h-simple-map--initializing': this.initializing,
    };
  }

  get queries() {
    const { query } = this;
    return Array.isArray(query) ? query : [query];
  }

  private _setInitializeState(state: HSimpleMapInitializeState) {
    if (this.internalState !== state) {
      this.internalState = state;
    }
  }

  get api() {
    const { _api } = this;
    if (!_api) throw new Error('missing google map api instance.');
    return _api;
  }

  get map() {
    const { _map } = this;
    if (!_map) throw new Error('missing google map object.');
    return _map;
  }

  get placesService() {
    const { _placesService } = this;
    if (!_placesService)
      throw new Error('missing google places service instance.');
    return _placesService;
  }

  private _infoWindowNode?: HTMLElement;

  private resolveInfoWindowNode() {
    if (this._infoWindowNode) return this._infoWindowNode;
    const { computedMarkerInfo } = this;
    if (computedMarkerInfo) {
      const $window = document.createElement('div');
      $window.className = 'h-simple-map__iw';
      (['name', 'body'] as const).forEach((key) => {
        const value = computedMarkerInfo[key];
        if (value) {
          const $row = document.createElement('div');
          $row.className = `h-simple-map__iw__${key}`;
          $row.innerHTML = value;
          $window.appendChild($row);
        }
      });
      this._infoWindowNode = $window;
    }
    return this._infoWindowNode;
  }

  addMarker(opts?: HSimpleMapMarkerOptions) {
    const { api, map } = this;
    const marker = new api.Marker({
      ...opts,
      icon: {
        url: getDataURLFromSVG(SVG_TEMPLATE, this.$theme.current.name),
        scaledSize: new api.Size(27, 43),
      },
      map,
    });
    this.internalMarkers.push(marker);
    // const $window = document.createElement('div');
    // $window.className = 'h-simple-map__iw';
    // $window.innerHTML = `
    //   <div class="h-simple-map__iw">
    //   <div class="h-simple-map__iw__name">界 加賀</div>
    //   <div class="h-simple-map__iw__body">922-0242 石川県加賀市山代温泉18-47</div>
    //   </div>
    // `;
    const infoWindowNode = this.resolveInfoWindowNode();
    if (infoWindowNode) {
      const infoWindow = new api.InfoWindow({
        content: infoWindowNode,
        pixelOffset: new api.Size(0, -8),
        maxWidth: 250,
      });

      marker.addListener('click', (ev) => {
        infoWindow.open(map, marker);
        setTimeout(() => {
          const p1 = infoWindowNode.parentElement;
          const p2 = p1 && p1.parentElement;
          if (p2) {
            p2.classList.add('h-simple-map__iw-wrapper');
          }
        });
      });
    }
    return marker;
  }

  findPlaceFromQuery(query: string, fields: string[] = ['name', 'geometry']) {
    const { placesService } = this;
    return new Promise<{
      results: PlaceResult[];
      status: PlacesServiceStatus;
    }>((resolve, reject) => {
      placesService.findPlaceFromQuery(
        {
          query,
          fields,
        },
        (results, status) => {
          resolve({ results, status });
        },
      );
    });
  }

  async init() {
    if (this.initialized) return;

    if (this.embedUrl) {
      this._setInitializeState('initialized');
      return;
    }

    this._setInitializeState('initializing');

    try {
      const api = await loadGoogleMapsApi({
        language: this.$language.info.attr,
      });
      if (this.isDestroyed) return;
      const map = new api.Map(this.$refs.map, {
        zoom: this.zoom,
        disableDefaultUI: true,
        zoomControl: true,
        keyboardShortcuts: false,
        styles: HSimpleMapStyles[this.mapStyle],
        // styles:
        //   this.computedGrayscale === 'map'
        //     ? [
        //         {
        //           stylers: [
        //             {
        //               saturation: -100,
        //             },
        //           ],
        //         },
        //       ]
        //     : [],
      });
      const service = new api.places.PlacesService(map);

      this._api = api;
      this._map = map;
      this._placesService = service;

      const { queries } = this;
      const nextStatuses = [
        api.places.PlacesServiceStatus.NOT_FOUND,
        api.places.PlacesServiceStatus.ZERO_RESULTS,
      ];

      const onFindError = (
        message = 'googlemap PlacesService.findPlaceFromQuery() failed.',
        status?: PlacesServiceStatus,
      ): never => {
        throw new Error(message + (status ? ` status: ${status}` : ''));
      };

      let results: PlaceResult[] | undefined;

      for (const query of queries) {
        const { results: _results, status } = await this.findPlaceFromQuery(
          query,
        );
        if (status === api.places.PlacesServiceStatus.OK) {
          results = _results;
          break;
        }
        if (!nextStatuses.includes(status)) {
          onFindError();
          break;
        }
      }
      if (this.isDestroyed) return;

      if (!results) return onFindError();

      const first = results[0];
      if (!first) {
        return onFindError();
      }
      const { geometry } = first;
      if (!geometry) {
        return onFindError();
      }

      results.forEach((result) => {
        const location = result.geometry!.location!;
        this.addMarker({
          title: result.name,
          position: location,
        });
      });
      const { location } = geometry;
      map.setCenter(location);

      this._setInitializeState('initialized');
    } catch (err) {
      this._setInitializeState('error');
      this.$logger.error(err);
    }
  }

  destroy() {
    this._api = null;
    this._map = null;
    this._placesService = null;
    this.internalMarkers = [];
    this.isDestroyed = true;
  }
}

export const HSimpleMap = tsx
  .ofType<HSimpleMapProps, HSimpleMapEmits, HSimpleMapScopedSlots>()
  .convert(HSimpleMapRef);

function getDataURLFromSVG(svgString: string, fillColor?: string) {
  let color = fillColor ? PIN_COLOR_MAP[fillColor] : undefined;
  if (!color) {
    color = PIN_COLOR_MAP.default;
  }
  if (color) {
    svgString = svgString.replace('#E85E4F', color);
  }

  return (
    'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)))
  );
}
