import './HHotelIntroductionPlan.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { HotelIntroductionPlanProperties } from './schemes';
import { mergeVNodeData } from '~/helpers';
import { HPrice, HBtn, HIcon } from '~/components';

export const HHotelIntroductionPlan = tsx.component({
  name: 'HHotelIntroductionPlan',
  functional: true,
  props: {
    value: {
      type: Object as PropType<HotelIntroductionPlanProperties>,
      required: true,
    },

    /**
     * コンテナクラスの付与を無効にする場合に指定する
     */
    disableContainer: Boolean,
  },
  render(h, { props, data, parent }): VNode {
    const {
      priceConditions,
      link,
      lowestPrice,
      name,
      originalLowestPrice,
      applyLocaleCurrencyFormat,
    } = props.value;

    const displayName =
      name || (parent.$t('label.exampleAccommodationFee') as string);
    const { nights, guests, mealType, perGuest } = priceConditions;

    /**
     * プランの宿泊条件を表示用に調整するメソッド
     *
     * @returns [nights]泊[nights + 1]日（[guests]名・[mealType]）1泊[perGuest]名)
     */
    const priceConditionsLabel = () => {
      const priceConditions: string[] = [];
      /**
       * '[nights]泊[nights + 1]日',
       */
      nights &&
        priceConditions.push(
          parent.$t('value.nightsAndDays', {
            nights,
            days: nights + 1,
          }) as string,
        );

      /**
       * （[guests]名・[mealType]）
       */
      const details: string[] = [];
      guests &&
        details.push(
          parent.$t('value.guestsRange', {
            guestsRange: guests,
          }) as string,
        );
      mealType && details.push(parent.$t(`plan.mealType`)[mealType]);

      nights && priceConditions.push(`(${details.join('・')})`);

      return priceConditions;
    };

    /**
     * 1泊[perGuest]名
     */
    const nightAndGuestsLabel =
      perGuest &&
      parent.$t('value.nightAndGuests', {
        perGuest,
      });
    /**
     * @TODO
     * CMS側で多通過登録できるようになったら、JPYじゃなくなる
     */
    const BASE_CURRENCY = 'JPY';

    /** CMSでapplyLocaleCurrencyFormatが有効にされた場合施設紹介モジュールでの表示通貨設定を有効にする */
    const hotelIntroductionDisplayCurrency = applyLocaleCurrencyFormat
      ? parent.$commons.currencyHotelIntroduction
      : undefined;

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'h-hotel-introduction-plan',
        })}>
        <div staticClass="h-hotel-introduction-plan__header">
          <span staticClass="h-hotel-introduction-plan__header__icon">
            <HIcon name="bed-fill" />
          </span>
          <h4 staticClass="h-hotel-introduction-plan__header__name">
            {displayName}
          </h4>
          <div staticClass="h-hotel-introduction-plan__header__conditions">
            {priceConditionsLabel()}
          </div>
        </div>
        <div staticClass="h-hotel-introduction-plan__price">
          <div staticClass="h-hotel-introduction-plan__price__group">
            {nightAndGuestsLabel !== null && (
              <span staticClass="h-hotel-introduction-plan__price__per-guest">
                {nightAndGuestsLabel}
              </span>
            )}
            {originalLowestPrice.amount !== null && (
              <div staticClass="h-hotel-introduction-plan__price__prev">
                <HPrice
                  staticClass="h-hotel-introduction-plan__price__prev__amount"
                  base={BASE_CURRENCY}
                  to={hotelIntroductionDisplayCurrency}>
                  {originalLowestPrice.amount + '~'}
                </HPrice>
              </div>
            )}
          </div>
          {lowestPrice.amount !== null && (
            <div staticClass="h-hotel-introduction-plan__price__current">
              {originalLowestPrice.amount !== null && (
                <HIcon
                  staticClass="h-hotel-introduction-plan__price__divider-icon"
                  name="pop-arrow-right"
                />
              )}
              <HPrice
                staticClass="h-hotel-introduction-plan__price__current__amount"
                base={BASE_CURRENCY}
                to={hotelIntroductionDisplayCurrency}>
                {lowestPrice.amount + '~'}
              </HPrice>
            </div>
          )}
        </div>
        {(() => {
          if (!link || !link.url) return;
          const { href, target, to } = parent.$navigation.resolveHrefTo(
            link.url,
          );

          return (
            <div staticClass="h-hotel-introduction-plan__actions">
              <HBtn
                staticClass="h-hotel-introduction-plan__action"
                color="primary"
                block
                href={href}
                to={to}
                target={target}>
                {parent.$t('action.reserve')}
              </HBtn>
            </div>
          );
        })()}
      </div>
    );
  },
});
