import './HTimeTable.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { mergeVNodeData } from '~/helpers';

export interface HTimeTableMinute {
  time: number | string;
  text?: string;
}

export interface HTimeTableHour {
  time: number | string;
  minutes: (number | string | HTimeTableMinute)[];
}

export interface HTimeTableLink {
  text?: string | null;
  url: string;
}

export interface HTimeTableProps {
  caption?: string;
  head?: string;
  hours: HTimeTableHour[];
}

export const HTimeTable = tsx.component({
  name: 'HTimeTable',
  functional: true,

  props: {
    caption: String,
    head: String,
    hours: {
      type: Array as PropType<HTimeTableHour[]>,
      required: true,
    },
  },

  render(h, { data, props, parent }): VNode {
    const { caption, head, hours } = props;

    return (
      <table
        {...mergeVNodeData(data, {
          staticClass: 'h-time-table',
        })}>
        {!!caption && (
          <caption staticClass="h-time-table__caption">{caption}</caption>
        )}
        <thead>
          <tr>
            <th />
            <th>{head}</th>
          </tr>
        </thead>
        <tbody>
          {hours.map(({ time, minutes }, hourIndex) => {
            const $child =
              minutes.length > 0 ? (
                <ul staticClass="h-time-table__minutes">
                  {minutes.map((minute, minuteIndex) => {
                    if (typeof minute !== 'object') {
                      minute = {
                        time: minute,
                      } as HTimeTableMinute;
                    }
                    const { time, text } = minute as HTimeTableMinute;
                    return (
                      <li
                        staticClass="h-time-table__minutes__minute"
                        key={minuteIndex}>
                        <span staticClass="h-time-table__minutes__minute__value">
                          {String(time).padStart(2, '0')}
                        </span>
                        {!!text && (
                          <small staticClass="h-time-table__minutes__minute__text">
                            {text}
                          </small>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                '-'
              );
            return (
              <tr key={hourIndex}>
                <th>{time}</th>
                <td>{$child}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  },
});
