import './HCuisineContentSet.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HCuisineContent } from './HCuisineContent';
import { RestaurantCommons, CuisineContentSet } from '~/schemes';
import { HPrecautionList, HThemeDecoration } from '~/components';

export interface HCuisineContentSetProps {
  commons: RestaurantCommons;
  data: CuisineContentSet;

  /**
   * ヘッダーを右寄せで表示する場合true
   * しかし、界テーマでしか使われない
   */
  right?: boolean;
}

export interface HCuisineContentSetEmits {}

export interface HCuisineContentSetScopedSlots {}

@Component<HCuisineContentSetRef>({
  name: 'HCuisineContentSet',

  render() {
    const { title, serviceTimes, precautions, contents } = this;
    return (
      <div
        staticClass="h-cuisine-content-set"
        class={this.classes}
        v-inview={
          this.inviewed
            ? false
            : {
                in: () => {
                  this.inviewed = true;
                },
                rootMargin: '-80px',
              }
        }>
        <div staticClass="h-cuisine-content-set__inner">
          {(!!title || !!serviceTimes || precautions.length > 0) && (
            <div staticClass="h-cuisine-content-set__header">
              <i staticClass="h-cuisine-content-set__header__deco" />
              {!!title && (
                <h2 staticClass="h-cuisine-content-set__header__title">
                  {title}
                </h2>
              )}
              {!!serviceTimes && (
                <dl staticClass="h-cuisine-content-set__header__times">
                  <dt staticClass="h-cuisine-content-set__header__times__label">
                    {this.$t('label.restaurantHours')}
                  </dt>
                  <dd staticClass="h-cuisine-content-set__header__times__value">
                    {serviceTimes}
                  </dd>
                </dl>
              )}
              {precautions.length > 0 && (
                <HPrecautionList
                  staticClass="h-cuisine-content-set__header__precautions"
                  items={precautions}
                />
              )}

              <HThemeDecoration
                staticClass="h-cuisine-content-set__header__bottom-deco"
                size="md"
              />
            </div>
          )}
          <div staticClass="h-cuisine-content-set__contents">
            {contents.map(({ data }, index) => (
              <HCuisineContent
                staticClass="h-cuisine-content-set__content"
                commons={this.commons}
                data={data}
                special={this.specialLayout}
                index={index}
                key={data.id}
                id={`content-${data.id}`}
              />
            ))}
          </div>
        </div>
      </div>
    );
  },
})
export class HCuisineContentSetRef
  extends Vue
  implements HCuisineContentSetProps {
  @Prop({ type: Object, required: true }) readonly commons!: RestaurantCommons;
  @Prop({ type: Object, required: true }) readonly data!: CuisineContentSet;
  @Prop(Boolean) readonly right!: boolean;

  private inviewed: boolean = false;

  get title() {
    return this.data.title;
  }

  get specialLayout() {
    return this.data.specialLayout;
  }

  get serviceTimes() {
    return this.data.serviceTimes;
  }

  get precautions() {
    return this.data.precautions;
  }

  get contents() {
    return this.data.contents;
  }

  get classes() {
    return {
      'h-cuisine-content-set--special': this.specialLayout,
      'h-cuisine-content-set--right': this.$theme.is('kai') && this.right,
      'h-cuisine-content-set--inviewed': this.inviewed,
    };
  }
}

export const HCuisineContentSet = tsx
  .ofType<
    HCuisineContentSetProps,
    HCuisineContentSetEmits,
    HCuisineContentSetScopedSlots
  >()
  .convert(HCuisineContentSetRef);
