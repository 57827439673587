import './HCuisineColumnSet.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HCuisineColumn } from './HCuisineColumn';
import { CuisineColumnSet, RestaurantCommons } from '~/schemes';

export interface HCuisineColumnSetProps {
  data: CuisineColumnSet;
  commons: RestaurantCommons;
}

export interface HCuisineColumnSetEmits {}

export interface HCuisineColumnSetScopedSlots {}

@Component<HCuisineColumnSetRef>({
  name: 'HCuisineColumnSet',

  render() {
    return (
      <div staticClass="h-cuisine-column-set">
        <HCuisineColumn
          staticClass="h-cuisine-column-set__content"
          data={this.content}
          commons={this.commons}
          id={`content-${this.content.id}`}
        />
      </div>
    );
  },
})
export class HCuisineColumnSetRef
  extends Vue
  implements HCuisineColumnSetProps {
  @Prop({ type: Object, required: true }) readonly commons!: RestaurantCommons;
  @Prop({ type: Object, required: true }) readonly data!: CuisineColumnSet;

  get content() {
    return this.data.content;
  }
}

export const HCuisineColumnSet = tsx
  .ofType<
    HCuisineColumnSetProps,
    HCuisineColumnSetEmits,
    HCuisineColumnSetScopedSlots
  >()
  .convert(HCuisineColumnSetRef);
