import type { AWSError } from 'aws-sdk';
import { GFErrorSourceObject } from '../schemes';

export function isAWSErrorLike(source: any): source is AWSError {
  if (!source || typeof source !== 'object') return false;
  return (
    typeof source.code === 'string' &&
    typeof source.message === 'string' &&
    (typeof source.statusCode === 'undefined' ||
      typeof source.statusCode === 'number')
  );
}

/**
 * 指定した例外がAWS SDKのエラーだった場合、エラー情報を抽出する
 *
 * @param source - 例外
 * @returns
 */
export function extractAWSErrorResponse(
  source: unknown,
): GFErrorSourceObject | undefined {
  if (!isAWSErrorLike(source)) return;

  const name: string = source.name;
  const message: string = source.message;
  const stack: string | undefined = source.stack;
  const status = source.statusCode || 500;

  return {
    name,
    status,
    message,
    stack,
  };
}
