import * as tsx from 'vue-tsx-support';
import './HKeyVisualModulePart.scss';
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { KeyVisualOptimizedProperties } from './schemes';
import { nl2br } from '~/helpers';
import { HVideoPlayer } from '~/components/HVideoPlayer';

export interface HKeyVisualModulePartProps {
  /**
   * キービジュアルのモジュールのブレイクポイント別のデータ
   */
  properties: KeyVisualOptimizedProperties;
}

export interface HKeyVisualModulePartEmits {}

export interface HKeyVisualModulePartScopedSlots {}

/**
 * 全ての改行文字にマッチする正規表現インスタンス
 */
const ALL_NEW_LINE_RE = /\n/g;

/**
 * キービジュアルモジュールにおけるブレイクポイント毎のパーツ部分
 */
@Component<HKeyVisualModulePartRef>({
  name: 'HKeyVisualModulePart',
  render() {
    const resourceType = this.firstMedia?.resource_type;

    if (resourceType === 'video')
      return (
        <div
          staticClass="h-key-visual-module-part"
          class={`h-key-visual-module-part--${this.breakpoint}`}
          style={{
            aspectRatio: `${this.narrowAspectRatio}`,
            background: `var(${this.properties.bgColorCssToken})`,
          }}>
          <HVideoPlayer
            staticClass="h-key-visual-module-part__video"
            src={this.firstMedia?.url}
            autoplay
            loop
            backgroundMode
            suspended={false}
            fit
          />
          <div
            staticClass="h-key-visual-module-part__contents"
            class={this.contentsClasses}>
            {this.getMainTitle()}
            {!this.isBgColor() && this.getSubTitle()}
          </div>
        </div>
      );
    return (
      <div
        staticClass="h-key-visual-module-part"
        class={[
          `h-key-visual-module-part--${this.breakpoint}`,
          { 'h-key-visual-module-part--no-image': !this.firstMedia },
        ]}
        style={[this.imageStyle, this.flexibleContentsAlignStyle]}>
        <div
          staticClass="h-key-visual-module-part__contents"
          class={this.contentsClasses}>
          {this.getMainTitle()}
          {!this.isBgColor() && this.getSubTitle()}
        </div>
      </div>
    );
  },
})
export class HKeyVisualModulePartRef
  extends Vue
  implements HKeyVisualModulePartProps {
  /**
   * 言語抽出されたキービジュアルモジュールプロパティ
   */
  @Prop({ type: Object, required: true })
  readonly properties!: KeyVisualOptimizedProperties;

  /** sp版の画像のアスペクト比 */
  get narrowAspectRatio() {
    return this.properties.narrowAspectRatio;
  }

  /**
   * ブレイクポイント
   */
  get breakpoint() {
    return this.properties.breakpoint;
  }

  /**
   * メディアリストの先頭のやつ
   */
  get firstMedia() {
    const media = this.properties.media || {};
    const first = media[0];

    // 長くてごめんなさい。へルパ使おうと思ったけど、さぼった
    return first && first.derived && first.derived.main
      ? first.derived.main
      : undefined;
  }

  /** スタイル */
  get imageStyle() {
    const { firstMedia } = this;
    const { narrowAspectRatio } = this.properties;

    return {
      backgroundImage: firstMedia ? `url(${firstMedia.url})` : undefined,
      aspectRatio: narrowAspectRatio ? `${narrowAspectRatio}` : undefined,
      backgroundColor: `var(${this.properties.bgColorCssToken})`,
    };
  }

  get flexibleContentsAlignStyle() {
    const { flexibleContentsAlign } = this.properties;

    /**
     *
     * @param breakpoint カスタム変数名に準ずる
     * @param align cssTypeがtop,bottomならvertical、left,rightならhorizontal
     * @param cssType カスタム変数名に準ずる
     * @returns cssに入れる値
     */
    const cssValue = (
      breakpoint: 'wide' | 'narrow',
      align: 'vertical' | 'horizontal',
      cssType: 'top' | 'bottom' | 'left' | 'right',
    ) => {
      if (flexibleContentsAlign[align][breakpoint].type !== cssType)
        return 'auto';
      return `${flexibleContentsAlign[align][breakpoint].value}%`;
    };

    const subTitleAlign = (breakpoint: 'wide' | 'narrow') => {
      if (flexibleContentsAlign.horizontal[breakpoint].type === 'right') {
        return 'right';
      }
      return 'left';
    };

    return {
      '--wide-top': cssValue('wide', 'vertical', 'top'),
      '--wide-right': cssValue('wide', 'horizontal', 'right'),
      '--wide-bottom': cssValue('wide', 'vertical', 'bottom'),
      '--wide-left': cssValue('wide', 'horizontal', 'left'),
      '--narrow-top': cssValue('narrow', 'vertical', 'top'),
      '--narrow-right': cssValue('narrow', 'horizontal', 'right'),
      '--narrow-bottom': cssValue('narrow', 'vertical', 'bottom'),
      '--narrow-left': cssValue('narrow', 'horizontal', 'left'),
      '--wide-subtitle-align': subTitleAlign('wide'),
      '--narrow-subtitle-align': subTitleAlign('narrow'),
    };
  }

  /**
   * コンテンツ部分（タイトルやサブタイトル）のクラス
   */
  get contentsClasses() {
    const { contentsAlign, titleColor, alignSettings } = this.properties;

    const contentsAlignCssClass = {
      contentsAlign: [
        `h-key-visual-module-part__contents--horizontal-${contentsAlign.horizontal}`,
        `h-key-visual-module-part__contents--vertical-${contentsAlign.vertical}`,
      ],
      flexibleContentsAlign: ['h-key-visual-module-part__contents--flexible'],
    };

    return [
      `h-key-visual-module-part__contents--${titleColor}`,
      contentsAlignCssClass[alignSettings],
    ];
  }

  /**
   * タイトルに利用するタグ名
   *
   * * CMSで「大見出し設定(isHeading)」がされていたらページタイトル（h1）とする
   */
  get titleTagName(): 'h1' | 'div' {
    return this.properties.isHeading ? 'h1' : 'div';
  }

  /**
   * メインタイトルのVNodeを取得する
   *
   * * 画像が指定されていた場合、画像タグになる
   */
  private getMainTitle(): VNode | undefined {
    const { title } = this.properties;

    if (!title) return;
    const { image, text } = title;
    const titleImage = image && image.derived && image.derived.main;

    // 画像登録があれば画像表示してテキストをaltに使う。テキストのみだったらテキスト表示
    const displayTitleSlot = titleImage ? (
      <img
        key="image"
        staticClass="h-key-visual-module-part__contents__title__img"
        src={titleImage.url}
        style={{ width: `${Math.floor(titleImage.width / 2)}px` }}
        alt={text.replace(ALL_NEW_LINE_RE, ' ')}
      />
    ) : (
      <div>
        <span key="text" domPropsInnerHTML={nl2br(text)}></span>
        {this.isBgColor() && this.getSubTitle()}
      </div>
    );

    // タイトルを表示するときのclass設定
    const titleClasses = `
      h-key-visual-module-part__contents__title
      h-key-visual-module-part__contents__title--${title.fontSize}`;

    const TitleTagName = this.titleTagName;
    return <TitleTagName class={titleClasses}>{displayTitleSlot}</TitleTagName>;
  }

  /**
   * サブタイトルのVNodeを取得する
   */
  private getSubTitle(): VNode | undefined {
    const { subTitle } = this.properties;

    if (!subTitle) return;
    return (
      <div
        staticClass="h-key-visual-module-part__contents__sub-title"
        domPropsInnerHTML={nl2br(subTitle)}
      />
    );
  }

  private isBgColor(): boolean {
    const { titleColor } = this.properties;
    return titleColor === 'withBgColor';
  }
}

export const HKeyVisualModulePart = tsx
  .ofType<
    HKeyVisualModulePartProps,
    HKeyVisualModulePartEmits,
    HKeyVisualModulePartScopedSlots
  >()
  .convert(HKeyVisualModulePartRef);
