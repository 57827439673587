import './HHotelGalleriesModal.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';
import { HHotelGalleries } from './HHotelGalleries';
import {
  HHotelGalleriesCarousel,
  HHotelGalleriesCarouselItem,
} from './HHotelGalleriesCarousel';
import {
  Gallery,
  GalleryCategory,
  HotelPhoto,
  HotelPhotoGroup,
  crateMergedGalleryCategories,
} from '~/schemes';
import { HCoverModal, HIcon } from '~/components';
import HotelViewRoot from '~/pages/_lang/hotels/_hotel_slug/-index';

export interface HHotelGalleriesModalProps {
  value?: boolean;
  galleryCategories?: GalleryCategory[];
  galleries?: Gallery[];
  loop?: boolean;
  showcase?: boolean;
}

export interface HHotelGalleriesModalEmits {
  onInput: boolean;
}

export interface HHotelGalleriesModalScopedSlots {}

@Component<HHotelGalleriesModalRef>({
  name: 'HHotelGalleriesModal',
  inject: ['hotelViewRoot'],

  mounted() {
    this._routeGuard = this.$router.beforeEach((to, from, next) => {
      if (this.isActive) {
        if (this.carouselIsActive) {
          this.closeCarousel();
          if (this.carouselCategoryId) {
            this.close();
          }
        } else {
          this.close();
        }
        next(false);
      } else {
        next();
      }
    });
  },

  beforeDestroy() {
    if (this._routeGuard) {
      this._routeGuard();
      delete this._routeGuard;
    }
  },

  render() {
    const { galleryCategories, galleries } = this;

    return (
      <HCoverModal
        staticClass="h-hotel-galleries-modal"
        v-model={this.isActive}
        hiddenClose={this.carouselIsActive}>
        <div staticClass="h-hotel-galleries-modal__content">
          {this.showcase && (
            <HHotelGalleries
              staticClass="h-hotel-galleries-modal__galleries"
              galleryCategories={galleryCategories}
              galleries={galleries}
              onClickGallery={(gallery) => {
                this.showCarousel(gallery.id);
              }}
            />
          )}
          <transition name="fade">
            {this.carouselIsActive ? (
              <div
                staticClass="h-hotel-galleries-modal__carousel-wrapper"
                onClick={(ev) => {
                  ev.stopPropagation();
                }}>
                <HHotelGalleriesCarousel
                  staticClass="h-hotel-galleries-modal__carousel"
                  initialSlide={this.initialSlide}
                  items={this.carouselItems}
                  loop={this.loop && this.carouselLoop}
                />
                <button
                  staticClass="h-hotel-galleries-modal__carousel__close"
                  type="button"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.closeCarousel();
                    if (this.carouselCategoryId) {
                      this.close();
                    }
                  }}>
                  <HIcon name="close" />
                </button>
              </div>
            ) : (
              []
            )}
          </transition>
        </div>
      </HCoverModal>
    );
  },
})
export class HHotelGalleriesModalRef
  extends Vue
  implements HHotelGalleriesModalProps {
  readonly hotelViewRoot?: HotelViewRoot;
  @Model('input', { type: Boolean }) readonly value!: boolean;
  @Prop({ type: Array, default: () => [] })
  readonly galleryCategories!: GalleryCategory[];

  @Prop({ type: Array, default: () => [] }) readonly galleries!: Gallery[];
  @Prop({ type: Boolean, default: true }) readonly loop!: boolean;
  @Prop({ type: Boolean }) readonly showcase!: boolean;

  private internalValue: boolean = this.value;
  private carouselIsActive: boolean = false;
  private carouselTriggerId: string | null = null;
  private carouselCategoryId: string | null = null;
  private carouselLoop: boolean = true;
  private _routeGuard?: Function;

  get hotel() {
    return this.hotelViewRoot?.hotel;
  }

  get initialSlide() {
    const { carouselTriggerId, carouselItems } = this;
    if (carouselTriggerId === null) {
      return 0;
    }
    const index = carouselItems.findIndex((c) => c.id === carouselTriggerId);
    return index === -1 ? 0 : index;
  }

  showCarousel(
    galleryId: string | null = null,
    categoryId: string | null = null,
    loop: boolean = true,
  ) {
    if (!this.isActive) {
      this.show();
    }
    this.carouselTriggerId = galleryId;
    this.carouselCategoryId = categoryId;
    this.carouselLoop = loop;
    this.carouselIsActive = true;
  }

  closeCarousel() {
    this.carouselIsActive = false;
  }

  get computedCategories() {
    return crateMergedGalleryCategories(this.galleryCategories, this.galleries);
  }

  get filteredCategories() {
    const { computedCategories, carouselCategoryId } = this;
    if (!carouselCategoryId) {
      return computedCategories;
    }
    return computedCategories.filter((c) => c.id === carouselCategoryId);
  }

  get carouselItems(): HHotelGalleriesCarouselItem[] {
    const items: HHotelGalleriesCarouselItem[] = [];
    this.filteredCategories.forEach((category) => {
      const { galleries } = category;
      const categoryName = category.name;
      const galleryCount = galleries.length;
      galleries.forEach((gallery, galleryIndex) => {
        let caption = categoryName;
        if (galleryCount > 1) {
          caption = `${caption} (${galleryIndex + 1} / ${galleryCount})`;
        }
        items.push({
          id: gallery.id,
          caption,
          main: this.$res.img(gallery.xl),
          thumb: this.$res.img(gallery.s),
          video: this.getVideoUrl(gallery.id),
          meta: gallery.meta,
        });
      });
    });
    return items;
  }

  get isActive() {
    return this.internalValue;
  }

  set isActive(isActive) {
    if (this.internalValue !== isActive) {
      this.internalValue = isActive;
      this.$emit('input', isActive);
    }
  }

  @Watch('value')
  protected valueChangeHandler() {
    this.internalValue = this.value;
  }

  @Watch('internalValue', { immediate: true })
  protected internalValueChangeHandler() {
    if (this.internalValue && !this.showcase) {
      this.carouselIsActive = true;
    }
  }

  @Watch('carouselIsActive', { immediate: true })
  protected carouselIsActiveChangeHandler() {
    if (!this.carouselIsActive && !this.showcase) {
      this.isActive = false;
    }
  }

  show() {
    this.isActive = true;
  }

  close() {
    this.isActive = false;
  }

  private getVideoUrl(galleryId: string): string | undefined {
    if (!this.hotel) return undefined;
    const hotelPhotos = this.hotel.photoGroups.reduce(
      (photo: HotelPhoto[], group: HotelPhotoGroup) => photo.concat(group),
      [],
    );
    return (
      hotelPhotos.find((photo) => photo.galleryId === galleryId)?.videoUrl || ''
    );
  }
}

export const HHotelGalleriesModal = tsx
  .ofType<
    HHotelGalleriesModalProps,
    HHotelGalleriesModalEmits,
    HHotelGalleriesModalScopedSlots
  >()
  .convert(HHotelGalleriesModalRef);
