/* eslint-disable import/no-duplicates */
import Vue from 'vue';
import type HlsType from 'hls.js';
import type {
  HlsConfig,
  ErrorData as HlsErrorData,
  ManifestParsedData,
} from 'hls.js';
import type { HVideoPlayerRef } from './HVideoPlayer';

export type { HlsConfig, ErrorData as HlsErrorData } from 'hls.js';
export type Hls = HlsType;
export type HlsStatic = typeof HlsType;

let _scriptLoadPromise: Promise<HlsStatic> | undefined;
let _canPlayMaybeHls: boolean | undefined;

/**
 * hls.jsのscriptを非同期読み込みします
 */
export function loadHls() {
  if (!_scriptLoadPromise) {
    _scriptLoadPromise = new Promise<HlsStatic>((resolve, reject) => {
      import(/* webpackChunkName: "hlsjs" */ 'hls.js/dist/hls.min.js')
        .then((res) => {
          _scriptLoadPromise = undefined;
          resolve(res.default as HlsStatic);
        })
        .catch((err) => {
          _scriptLoadPromise = undefined;
          reject(err);
        });
    });
  }
  return _scriptLoadPromise;
}

/**
 * @see: https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
 */
export const VIDEO_EVENTS = [
  'abort',
  'canplay',
  'canplaythrough',
  'durationchange',
  'emptied',
  'ended',
  'error',
  'interruptbegin',
  'interruptend',
  'loadeddata',
  'loadedmetadata',
  'loadstart',
  'pause',
  'play',
  'playing',
  'progress',
  'ratechange',
  'seeked',
  'seeking',
  'stalled',
  'suspend',
  'timeupdate',
  'volumechange',
  'waiting',
] as const;

/**
 * 実行環境（ブラウザ）がhlsをネイティブ再生可能かチェックします
 * SSR環境では常に `false` になります
 */
export function canPlayNativeHls() {
  if (typeof document === 'undefined') return;
  if (_canPlayMaybeHls === undefined) {
    const video = document.createElement('video');
    _canPlayMaybeHls =
      video.canPlayType('application/vnd.apple.mpegurl') !== '';
  }
  return _canPlayMaybeHls;
}

/**
 * [HlsController] の読み込み状況
 */
export type HlsControllerLoadState =
  | 'pending' // 開始していない
  | 'attaching' // アタッチ中
  | 'loading' // ソース読み込み中
  | 'loaded' // ソース読み込み完了
  | 'detaching' // ディタッチ中
  | 'detached' // ディタッチ完了
  | 'error'; // エラー

/**
 * [HlsController] の内部状態
 */
export interface HlsControllerState {
  loadState: HlsControllerLoadState;
  error: HlsErrorData | null;
}

/**
 * [HlsController] のオプション
 */
export interface HlsControllerOptions extends Partial<HlsConfig> {}

/**
 * HLSコントローラーを生成します
 */
export async function createHlsController(
  player: HVideoPlayerRef,
  src: string,
  options: HlsControllerOptions = {},
) {
  const Hls = await loadHls();
  const Events = Hls.Events;
  const hls = new Hls(options);
  const state = Vue.observable<HlsControllerState>({
    loadState: 'pending',
    error: null,
  });

  const is = (loadState: HlsControllerLoadState) => {
    return state.loadState === loadState;
  };

  const setError = (error: HlsErrorData) => {
    state.error = error;
    player.$emit('hlsError', error);
  };

  let detachRequested: boolean = false;
  let attachPromise: Promise<void> | undefined;
  let detachPromise: Promise<void> | undefined;

  hls.on(Events.ERROR, (ev, error) => {
    state.error = error;
  });

  const load = () => {
    return new Promise<void>((resolve, reject) => {
      state.loadState = 'loading';
      const handleError = (ev: string, error: HlsErrorData) => {
        if (detachRequested) return;
        state.loadState = 'error';
        hls.off(Events.MANIFEST_PARSED, handleParsed);
        hls.off(Events.ERROR, handleError);
        setError(error);
        reject(error);
      };

      const handleParsed = (ev: string, data: ManifestParsedData) => {
        if (detachRequested) return;
        hls.off(Events.MANIFEST_PARSED, handleParsed);
        hls.off(Events.ERROR, handleError);
        state.loadState = 'loaded';

        hls.on(Events.ERROR, (ev, error) => {
          setError(error);
        });

        resolve();
        // if (this.autoplay) {
        //   const { node } = this.$refs;
        //   if (node) {
        //     const nodePayload = node.play();
        //     if (isPromise(nodePayload)) {
        //       nodePayload.then(resolve).catch(reject);
        //     } else {
        //       resolve(undefined);
        //     }
        //   }
        // }
      };

      hls.on(Events.MANIFEST_PARSED, handleParsed);
      hls.on(Events.ERROR, handleError);
      hls.loadSource(src);
    });
  };

  const attach = () => {
    if (!attachPromise) {
      attachPromise = new Promise<void>((resolve, reject) => {
        const node = player.getNode(true);
        const handleError = (ev: string, error: HlsErrorData) => {
          if (detachRequested) return;
          state.loadState = 'error';
          hls.off(Events.MEDIA_ATTACHED, handleAttached);
          hls.off(Events.MEDIA_ATTACHING, handleAttaching);
          hls.off(Events.ERROR, handleError);
          setError(error);
          reject(error);
        };

        const handleAttaching = () => {
          if (detachRequested) return;
          state.loadState = 'attaching';
          hls.off(Events.MEDIA_ATTACHING, handleAttaching);
        };

        const handleAttached = () => {
          if (detachRequested) return;
          hls.off(Events.MEDIA_ATTACHED, handleAttached);
          hls.off(Events.MEDIA_ATTACHING, handleAttaching);
          hls.off(Events.ERROR, handleError);
          resolve(load());
        };

        hls.on(Events.MEDIA_ATTACHED, handleAttached);
        hls.on(Events.MEDIA_ATTACHING, handleAttaching);
        hls.on(Events.ERROR, handleError);
        // hls.on(Events.FRAG_CHANGED, (ev, data) => {
        //   if (detachRequested) return;
        //   // this.setCurrentFrag(data.frag);
        // });
        hls.attachMedia(node);
      });
    }
    return attachPromise;
  };

  const detach = () => {
    detachRequested = true;

    if (!detachPromise) {
      detachPromise = new Promise<void>((resolve, reject) => {
        const node = player.getNode();
        if (!node) return resolve();

        const handleDetached = () => {
          state.loadState = 'detached';
          hls.off(Events.MEDIA_DETACHED, handleDetached);
          hls.off(Events.MEDIA_DETACHING, handleDetaching);
          hls.off(Events.ERROR, handleError);
          hls.destroy();
          resolve();
        };

        const handleDetaching = () => {
          state.loadState = 'detaching';
          hls.off(Events.MEDIA_DETACHING, handleDetaching);
        };

        const handleError = (ev: string, error: HlsErrorData) => {
          state.loadState = 'error';
          hls.off(Events.MEDIA_DETACHED, handleDetached);
          hls.off(Events.MEDIA_DETACHING, handleDetaching);
          hls.off(Events.ERROR, handleError);
          setError(error);
          reject(error);
        };

        hls.on(Events.MEDIA_DETACHED, handleDetached);
        hls.on(Events.MEDIA_DETACHING, handleDetaching);
        hls.on(Events.ERROR, handleError);
        hls.detachMedia();
      });
    }

    return detachPromise;
  };

  return {
    hls,
    src,
    Events,
    Hls,
    is,
    attach,
    detach,
    options,
  };
}

type UnwrapPromise<
  Fn extends (...args: any[]) => any
> = ReturnType<Fn> extends Promise<infer T> ? T : never;

export type HlsController = UnwrapPromise<typeof createHlsController>;
