// import { LanguageDataMap, AvailableLanguage } from './language';

/**
 * 宿GETSのIF定義のうち必要なものだけ定義
 */

export interface HotelUseSearch {
  /**
   * 大人人数
   */
  adult: boolean;

  /**
   * 11歳以下人数
   */
  underTwelve: boolean;

  /**
   * 6歳以下人数
   */
  underSeven: boolean;

  /**
   * 3歳以下人数
   */
  underFour: boolean;
}

/**
 * TBバナー掲示場所
 * ※しかし、GF版宿GETSではこのパラメータは無視（デザインが異なるので）
 */
export enum TBBannerPosition {
  /**
   * 週間/月間カレンダー下
   */
  CalendarBottom = 0,

  /**
   * 部屋詳細/プラン詳細モーダル内
   */
  DetailModal = 1,
}

/**
 * TB連動先
 */
export enum TBConnectionTarget {
  /**
   * 国内
   */
  Internal = 1,

  /**
   * アウトバウンド
   */
  Outbound = 2,

  /**
   * インバウンド
   */
  Inbound = 3,
}

/**
 * TB連動設定
 */
export interface TBConnectionSettings {
  /**
   * TB連動フラグ
   * true：TB連動あり、false：TB連動なし
   */
  connectionFlag: boolean;

  /**
   * TBバナー掲示場所
   * ※しかし、GF版宿GETSではこのパラメータは無視（デザインが異なるので）
   */
  bannerPosition: TBBannerPosition;

  /**
   * 連動先
   */
  target: TBConnectionTarget;

  /**
   * 連動先URL
   * (例) https://www.tour-list.com/TL
   */
  targetUrl: string;

  /**
   * TB施設ID
   * (例) OIT57
   */
  hotelId: string;
}

/** 宿GETSの施設詳細情報 */
export interface YgHotelDetail {
  /**
   * 施設id
   */
  hotelId: string;

  /**
   * この施設でPaidyが使えるかどうか
   */
  paidyEnableFlg?: boolean;

  /**
   * 最大宿泊可能数
   */
  maxStayLength: number;

  /**
   * 指定可能最大人数
   */
  maxReservationNumber: number;

  /**
   * 人区分
   * @memo
   *   このプロパティ重要＆落とし穴になりやすい
   *   プロパティ名が underFour とかでも
   *   このレスポンスが6歳以下、、とかだったりする
   */
  customerType: {
    /**
     * 大人
     */
    adult: string;

    /**
     * 11歳以下
     */
    underTwelve: string;

    /**
     * 6歳以下
     */
    underSeven: string;

    /**
     * 3歳以下
     */
    underFour: string;
  };

  /**
   * 検索可能区分
   */
  useSearch: HotelUseSearch;

  /**
   * 空き状況しきい値(カレンダ)
   */
  calendarThreashold: number;

  /**
   * 基準通貨
   */
  baseRate: string;

  /**
   * 初期表示通貨
   * JPY
   */
  defaultRate?: string;

  /**
   * 泊数初期値
   */
  hotelNightDefault: number;

  /**
   * 泊数の下限
   */
  hotelNightMin: number;

  /**
   * 泊数の上限
   */
  hotelNightMax: number;

  /**
   * 人数の初期値(大人)
   */
  adultNumDefault: number;

  /**
   * 人数の初期値(11歳以下)
   */
  childNumDefault: number;

  /**
   * 人数の初期値(6歳以下)
   */
  infantNumDefault: number;

  /**
   * 人数の初期値(3歳以下)
   */
  babyNumDefault: number;

  /**
   * 人数の下限(大人)
   */
  adultNumMin: number;

  /**
   * 人数の下限(11歳以下)
   */
  childNumMin: number;

  /**
   * 人数の下限(6歳以下)
   */
  infantNumMin: number;

  /**
   * 人数の下限(3歳以下)
   */
  babyNumMin: number;

  /**
   * 人数の上限(大人)
   */
  adultNumMax: number;

  /**
   * 人数の上限(11歳以下)
   */
  childNumMax: number;

  /**
   * 人数の上限(6歳以下)
   */
  infantNumMax: number;

  /**
   * 人数の上限(3歳以下)
   */
  babyNumMax: number;

  /**
   * グローバルフォーマット対応
   * GF施設サイトの存在有無ではなく、GF版宿getsに対応しているか否かのフラグ
   */
  useGlobalformatYgetsFlg: boolean;

  /**
   * GTT利用フラグ
   */
  gttEnableFlg: boolean;

  /**
   * GTT開始日付
   * yyyy/MM/dd
   */
  gttPeriodBegin?: string;

  /**
   * GTT終了日付
   * yyyy/MM/dd
   */
  gttPeriodEnd?: string;

  /**
   * TB連動設定
   */
  tbConnectionSettings: TBConnectionSettings;

  /**
   * 検索日の初期値(日付)
   *
   * yyyy/MM/dd
   */
  searchDateDefault?: string;
}
