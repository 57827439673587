import './HSimplePagination.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Swiper from 'swiper';
import type { SwiperOptions } from 'swiper';

export interface HSimplePaginationItem {
  image: string;
}

export interface HSimplePaginationProps {
  items: HSimplePaginationItem[];
}

export interface HSimplePaginationEmits {}

export interface HSimplePaginationScopedSlots {}

interface SwiperElement extends HTMLElement {
  swiper: Swiper;
}
/**
 * ページネーションスライド
 */
@Component<HSimplePaginationRef>({
  name: 'HSimplePagination',

  beforeDestroy() {
    const { mainSwiper } = this;
    mainSwiper && mainSwiper.destroy(true, true);
  },

  render() {
    const { items } = this;
    return (
      <div staticClass="h-simple-pagination">
        <div
          staticClass="swiper-container"
          key="main"
          ref="main"
          {...{
            directives: [
              {
                name: 'swiper',
                arg: 'mainSwiper',
                value: this.mainOptions,
              },
            ],
          }}>
          <div staticClass="swiper-wrapper">
            {items.map((item) => (
              <div staticClass="swiper-slide">
                <img
                  staticClass="h-simple-pagination__img swiper-lazy"
                  data-src={this.$res.img(item.image)}
                />
                <div class="swiper-lazy-preloader"></div>
              </div>
            ))}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    );
  },
})
export class HSimplePaginationRef
  extends Vue
  implements HSimplePaginationProps {
  $refs!: {
    main: SwiperElement;
  };

  mainSwiper?: Swiper;

  @Prop({ type: Array, required: true })
  readonly items!: HSimplePaginationItem[];

  get mainOptions(): SwiperOptions {
    return {
      // 遅延読込
      lazy: true,
      // ページネーション（ドット）
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      },
      // スライド間の余白
      spaceBetween: 8,
    };
  }
}

export const HSimplePagination = tsx
  .ofType<
    HSimplePaginationProps,
    HSimplePaginationEmits,
    HSimplePaginationScopedSlots
  >()
  .convert(HSimplePaginationRef);
