import { PropType } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, MediaModule } from '~/schemes';

/**
 * メディアモジュールのプロパティ
 */
export type MediaModuleProperties = ResolvedLanugageData<
  MediaModule['properties'],
  AvailableLanguage
>;

/**
 * メディアモジュールのコンポーネントプロパティ
 */
export interface MediaModuleProps {
  /** メディアモジュールのプロパティ */
  value: MediaModuleProperties;
}

/**
 * Vueコンポーネントで利用可能なメディアモジュールのPropsオプションを生成する
 */
export function createMediaModulePropsOptions() {
  return {
    value: {
      type: Object as PropType<MediaModuleProperties>,
      required: true,
    },
  };
}
