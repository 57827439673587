import './HContentSwitcher.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';

export interface HContentSwitcherSeeTopOptions {
  offset?: number;
  duration?: number;
}

export interface HContentSwitcherProps {
  value?: string;
  order?: string[];
  autotop?: boolean | HContentSwitcherSeeTopOptions;
}

export interface HContentSwitcherEmits {
  onInput: string;
}

export interface HContentSwitcherScopedSlots {
  [key: string]: HContentSwitcherRef;
}

@Component<HContentSwitcherRef>({
  name: 'HContentSwitcher',

  render() {
    const { $scopedSlots, current } = this;
    const currentSlot = $scopedSlots[current];

    return (
      <div staticClass="h-content-switcher">
        <div staticClass="h-content-switcher__anchor" ref="anchor" />
        <transition
          name={this.transition}
          onBeforeLeave={(el) => {
            this.$el.style.height = (el as HTMLElement).offsetHeight + 'px';
          }}
          onEnter={(el) => {
            this.$el.style.height = (el as HTMLElement).offsetHeight + 'px';
          }}
          onAfterEnter={(el) => {
            this.$el.style.height = '';
          }}
          onEnterCancelled={(el) => {
            this.$el.style.height = '';
          }}
          onLeaveCancelled={(el) => {
            this.$el.style.height = '';
          }}>
          {!!currentSlot && (
            <div staticClass="h-content-switcher__content" key={current}>
              {currentSlot(this)}
            </div>
          )}
        </transition>
      </div>
    );
  },
})
export class HContentSwitcherRef extends Vue implements HContentSwitcherProps {
  $refs!: {
    anchor: HTMLElement;
  };

  readonly $el!: HTMLElement;

  @Model('input', { type: String }) readonly value?: string;
  @Prop({ type: Array, default: () => [] }) readonly order!: string[];
  @Prop({ type: [Boolean, Object] }) readonly autotop?:
    | boolean
    | HContentSwitcherSeeTopOptions;

  private internalValue: string = '';
  private transition: string = '';

  get current() {
    return this.internalValue;
  }

  set current(current: string) {
    if (this.internalValue !== current) {
      this.internalValue = current;
      this.$emit('input', current);
    }
  }

  get autotopSettings(): HContentSwitcherSeeTopOptions | undefined {
    let { autotop: settings } = this;
    if (settings === true) settings = {};
    if (settings) {
      return {
        ...settings,
      };
    }
  }

  seeTop(options?: HContentSwitcherSeeTopOptions) {
    const { anchor } = this.$refs;
    if (anchor) {
      this.$ui.seeElementTop(anchor, options);
    }
  }

  @Watch('value', { immediate: true })
  protected valueChangeHandler(
    value: HContentSwitcherRef['value'],
    beforeValue: HContentSwitcherRef['value'],
  ) {
    if (value != null) {
      this.internalValue = value;

      if (beforeValue != null) {
        const index = this.order.indexOf(value);
        const beforeIndex = this.order.indexOf(beforeValue);

        if (index !== -1 && beforeIndex !== -1) {
          let transition = '';
          if (index > beforeIndex) transition = 'h-content-switcher-next';
          if (index < beforeIndex) transition = 'h-content-switcher-prev';
          this.transition = transition;
        }
      }
    }
  }

  @Watch('current')
  protected currentChangeHandler(
    value: HContentSwitcherRef['current'],
    beforeValue: HContentSwitcherRef['current'],
  ) {
    if (this.autotopSettings && value != null) {
      this.seeTop(this.autotopSettings);
    }
  }
}

export const HContentSwitcher = tsx
  .ofType<
    HContentSwitcherProps,
    HContentSwitcherEmits,
    HContentSwitcherScopedSlots
  >()
  .convert(HContentSwitcherRef);
