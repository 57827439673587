/* eslint-disable no-undef */
import './HImageLoupe.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HImageLoupeTeleport } from './HImageLoupeTeleport';
import {
  ImageLoupeEventHandlerMap,
  ImageLoupeState,
  ImageLoupe,
  ImageLoupeObservableObject,
  createImageLoupeObservableSource,
  ImageLoupeInitialPosition,
  ApplyChangeOpts,
} from '~/libs/image-loupe';
import {
  Mode,
  PinchView,
  SetPositionOpts,
  IncrementScaleOpts,
} from '~/libs/pinch-view';
import { waitAnimaitonFrame } from '~/helpers';
export type {
  ImageLoupeInitialPosition,
  ImageLoupeState,
  ImageLoupeObservableObject,
  ApplyChangeOpts,
} from '~/libs/image-loupe';

/**
 * 画像ルーペモデル
 */
export interface ImageLoupeModel
  extends Omit<
    ImageLoupeObservableObject,
    'viewport' | 'overlay' | 'cover' | 'info'
  > {}

export interface HImageLoupeProps {
  /** 画像URL */
  src: string;

  /** 動作モード */
  mode?: Mode;

  /**
   * 初期変形（位置）情報
   *
   * * この情報は初期化設定で指定されたsrcが一度でも変更された場合破棄されます。
   * * この指定がない、もしくは破棄された以降の画像変更時には `mode` によって指定された初期化処理に準じたスケール＆位置情報が設定されます
   */
  initialPosition?: ImageLoupeInitialPosition;

  /** 最大スケール */
  maxScale?: number;

  /** 遅延ロードをする場合 */
  lazy?: boolean | IntersectionObserverInit;
}

export interface HImageLoupeEmits {
  /** 座標やスケールに変化が発生した時 */
  onChange: PinchView;

  /** 最小スケールが変化した時 */
  onChangeMinScale: PinchView;

  /** ビューポートのサイズが変化した時 */
  onResizeViewport: PinchView;

  /** 内方要素のサイズが変化した時 */
  onResizeBody: PinchView;

  /** スケール操作をブロックした時 */
  onBlockScale: PinchView;

  /** タッチデバイスでの移動操作をブロックした時 */
  onBlockMove: PinchView;

  /** タッチデバイスでの移動操作が開始した時 */
  onMoveActivated: PinchView;

  /** ポインター移動による変形時のハンドラ */
  onPointerMove: ApplyChangeOpts;

  /** 初期化が完了した時 */
  onInit: ImageLoupeModel;

  /** 常態が変わった時 */
  onChangeState: ImageLoupeState;

  /**
   * 画像のロードが完了し、ビューポートと内方要素の両方のサイズ更新が完了した時
   *
   * * これは画像src切り替え毎に呼び出されます
   */
  onLoad: HImageLoupeRef;

  /** 読み込みエラーが発生した時 */
  onError: any;

  /** モデルバリューに変化が発生した時 */
  onUpdate: ImageLoupeModel;

  /** 画像をクリックした時 */
  onClickImage: MouseEvent;
}

export interface HImageLoupeScopedSlots {
  /**
   * インフォメーション要素
   *
   * * 全ての要素の上に重なる要素
   * * ビューポートに対して常に相対的に100%の座標になっている
   * * インフォーメーションウィンドウなどに利用する
   */
  info?: ImageLoupe;

  /**
   * 内包要素に対するオーバーレイ要素
   *
   * * 地図のように、スケールされたベース部分の上に、レイヤとしてピンなどを重ねたい時に利用する
   */
  overlay?: ImageLoupe;

  /**
   * カバー要素
   *
   * * 内方要素と、インフォメーション要素の間に挿入されている要素
   * * ガイドメッセージの表示などに利用する
   */
  cover?: ImageLoupe;
}

@Component<HImageLoupeRef>({
  name: 'HImageLoupe',
  created() {
    this.handlers = {
      change: (pinchView) => this.$emit('change', pinchView),
      changeMinScale: (pinchView) => this.$emit('changeMinScale', pinchView),
      resizeViewport: (pinchView) => this.$emit('resizeViewport', pinchView),
      resizeBody: (pinchView) => this.$emit('resizeBody', pinchView),
      blockScale: (pinchView) => this.$emit('blockScale', pinchView),
      blockMove: (pinchView) => this.$emit('blockMove', pinchView),
      moveActivated: (pinchView) => this.$emit('moveActivated', pinchView),
      pointerMove: (ev) => this.$emit('pointerMove', ev),
      mounted: (loupe) => {
        this._loupeInstance = loupe;
        this.$emit('init', this.model);
      },
      changeState: (state) => this.$emit('changeState', state),
      // @FIXME image-loupeは現状、サイズ未検出状態でロードイベントをemitするのでupdateハンドラで処理する
      // load: this.handleLoad,
      error: (error) => this.$emit('error', error),
      clickImage: (ev) => this.$emit('clickImage', ev),
    };
  },
  watch: {
    src() {
      this.loadDetected = false;
    },
    model: {
      handler(model: ImageLoupeModel) {
        if (!this.loadDetected) {
          const {
            viewportWidth,
            viewportHeight,
            bodyWidth,
            bodyHeight,
          } = model;
          if (viewportWidth && viewportHeight && bodyWidth && bodyHeight) {
            this.loadDetected = true;

            // サイズ検出完了後、2フレーム待ってみる
            // 数字はなんとなく。やりたいのはレンダリングの完了の検出
            waitAnimaitonFrame(2).then(() => {
              this.$emit('load', this);
            });
          }
        }
        this.$emit('update', model);
      },
      deep: true,
    },
  },
  render(h) {
    return (
      <div staticClass="h-image-loupe">
        <div
          ref="stage"
          staticClass="h-image-loupe__stage"
          v-image-loupe={{
            src: this.src,
            lazy: this.lazy,
            initialPosition: this.initialPosition,
            on: this.handlers,
            observe: this.observable,
          }}
        />
        {this.renderSlot('info')}
        {this.renderSlot('overlay')}
        {this.renderSlot('cover')}
      </div>
    );
  },
})
export class HImageLoupeRef extends Vue implements HImageLoupeProps {
  $refs!: {
    stage: HTMLElement;
  };

  @Prop({ type: String, required: true }) readonly src!: string;
  @Prop(String) readonly mode?: Mode;
  @Prop(Object) readonly initialPosition?: ImageLoupeInitialPosition;
  @Prop(Number) readonly maxScale?: number;
  @Prop({ type: [Boolean, Object], default: true }) readonly lazy!:
    | boolean
    | IntersectionObserverInit;

  /** 現在の画像srcに対してサイズの検出が完了しているか */
  private loadDetected: boolean = false;

  /** 画像ルーペインスタンス */
  _loupeInstance?: ImageLoupe;

  /** 画像ルーペにおける状態購読オブジェクト */
  private observable: ImageLoupeObservableObject = (() =>
    createImageLoupeObservableSource({ src: this.src }))();

  /** 画像ルーペのイベントハンドラのマッピング */
  handlers!: ImageLoupeEventHandlerMap;

  /** 画像ルーペモデル */
  get model(): ImageLoupeModel {
    const {
      scale,
      x,
      y,
      minScale,
      maxScale,
      viewportWidth,
      viewportHeight,
      bodyWidth,
      bodyHeight,
      centerX,
      centerY,
      src,
      state,
      originalWidth,
      originalHeight,
      overlayX,
      overlayY,
      overlayWidth,
      overlayHeight,
    } = this.observable;
    return {
      scale,
      x,
      y,
      minScale,
      maxScale,
      viewportWidth,
      viewportHeight,
      bodyWidth,
      bodyHeight,
      centerX,
      centerY,
      src,
      state,
      originalWidth,
      originalHeight,
      overlayX,
      overlayY,
      overlayWidth,
      overlayHeight,
    };
  }

  /**
   * 指定の座標＆スケールに移動する
   */
  setPosition(opts?: SetPositionOpts) {
    const { _loupeInstance } = this;
    _loupeInstance &&
      _loupeInstance.setPosition({
        ...opts,
        allowChangeEvent: true,
      });
  }

  /**
   * スケール率をインクリメントする
   * @param opts - スケールインクリメント（デクリメント）オプション
   */
  incrementScale(opts?: IncrementScaleOpts) {
    const { _loupeInstance } = this;
    if (!_loupeInstance) return;

    _loupeInstance.incrementScale({
      allowChangeEvent: true,
      ...opts,
    });
  }

  /**
   * スケール率をデクリメントする
   * @param opts - スケールインクリメント（デクリメント）オプション
   */
  decrementScale(opts?: IncrementScaleOpts) {
    const { _loupeInstance } = this;
    if (!_loupeInstance) return;

    _loupeInstance.decrementScale({
      allowChangeEvent: true,
      ...opts,
    });
  }

  /**
   * ステージ要素を取得する
   *
   * @returns ステージ要素
   */
  getStageElement(): HTMLElement | undefined {
    return this.$refs.stage;
  }

  private renderSlot(
    slotName: keyof HImageLoupeScopedSlots,
  ): VNode | undefined {
    const slot = this.$scopedSlots[slotName];
    const el = this.observable[slotName];
    if (!slot || !el) return;

    return (
      <HImageLoupeTeleport key={slotName} to={el}>
        {slot(el.$loupe)}
      </HImageLoupeTeleport>
    );
  }
}

export const HImageLoupe = tsx
  .ofType<HImageLoupeProps, HImageLoupeEmits, HImageLoupeScopedSlots>()
  .convert(HImageLoupeRef);
