import './HSimpleTable.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { formatedTitle } from './helpers';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, SimpleTableModule } from '~/schemes';

export const HSimpleTable = tsx.component({
  name: 'HSimpleTable',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<SimpleTableModule['properties'], AvailableLanguage>
      >,
      required: true,
    },

    /**
     * コンテナクラスの付与を無効にする場合に指定する
     */
    disableContainer: Boolean,
  },

  render(h, { props, data, parent }): VNode {
    const tableHeader = props.value.header;
    const rows = props.value.body.rows;
    const lang = parent.$language.current;

    return (
      <div staticClass="h-container h-container--min">
        <table class="v-simple-table-module">
          {tableHeader && (
            <caption class="v-simple-table-module__caption">
              {tableHeader}
            </caption>
          )}
          <tbody class="v-simple-table-module__table-body">
            {rows.map((row) => {
              return (
                <tr key={row.key}>
                  <th class="v-simple-table-module__row-title">
                    {formatedTitle(row, lang)}
                  </th>
                  <td
                    class="v-simple-table-module__row-content"
                    v-wysiwyg={row.content}></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  },
});
