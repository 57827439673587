import './HHeadModal.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNodeChildren } from 'vue';
import { Component, Model, Prop } from 'vue-property-decorator';
import { bodyScrollLock, clickOutside } from '@dadajam4/vue-stack';
import { HPathIcon } from '~/components';
import HDefaultLayout from '~/layouts/default';

export interface HHeadModalProps {
  value?: boolean;
  title?: VNodeChildren | ((modal: HHeadModalRef) => VNodeChildren);
  dense?: boolean;
}

export interface HHeadModalEmits {
  onInput: boolean;
}

export interface HHeadModalScopedSlots {
  default?: HHeadModalRef;
}

@Component<HHeadModalRef>({
  name: 'HHeadModal',
  inject: ['layout'],
  directives: {
    bodyScrollLock,
    clickOutside,
  },
  render() {
    const { default: defaultSlot } = this.$scopedSlots;
    let { title } = this;
    if (typeof title === 'function') {
      title = title(this);
    }
    const directives = [
      {
        name: 'body-scroll-lock',
        value: true,
      },
    ];

    const directiveOptions = { directives };
    const { wide } = this.$mq.match;

    return (
      <transition name="fade">
        {this.isActive && (
          <div
            staticClass="h-head-modal"
            class={this.classes}
            style={this.styles}
            {...(wide ? directiveOptions : undefined)}>
            <div staticClass="h-head-modal__scroller">
              <div staticClass="h-head-modal__centerer">
                <div staticClass="h-head-modal__content-wrapper">
                  <div
                    staticClass="h-head-modal__content"
                    {...{
                      directives: [
                        {
                          name: 'click-outside',
                          value: (ev: MouseEvent) => {
                            this.close();
                          },
                          args: {
                            include: () => {
                              return [this.$vstack.$el];
                            },
                            closeConditional: () => {
                              return true;
                            },
                          },
                        },
                      ],
                    }}>
                    <div staticClass="h-head-modal__header">
                      {!!title && (
                        <h3 staticClass="h-head-modal__header__title">
                          {title}
                        </h3>
                      )}
                      <button
                        staticClass="h-head-modal__header__close"
                        type="button"
                        onClick={this.close}>
                        <HPathIcon
                          staticClass="h-head-modal__header__close__icon"
                          name="close"
                        />
                      </button>
                    </div>
                    <div
                      staticClass="h-head-modal__body"
                      {...(!wide ? directiveOptions : undefined)}>
                      {!!defaultSlot && defaultSlot(this)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </transition>
    );
  },
  watch: {
    value(value) {
      this.internalValue = value;
    },
    internalValue(internalValue) {
      if (internalValue) {
        this.overlayRequest();
      } else {
        this.releaseOverlayRequest();
      }
    },
  },
  mounted() {
    this.setupEscHandler();
  },
  beforeDestroy() {
    this.releaseOverlayRequest();
    this.releaseEscHandler();
  },
})
export class HHeadModalRef extends Vue implements HHeadModalProps {
  readonly layout!: HDefaultLayout;

  @Model('input', Boolean) readonly value!: boolean;
  @Prop() readonly title?:
    | VNodeChildren
    | ((modal: HHeadModalRef) => VNodeChildren);

  @Prop(Boolean) readonly dense!: boolean;

  private internalValue: boolean = this.value;
  private escHandler?: (ev: KeyboardEvent) => any;

  private setupEscHandler() {
    this.escHandler = (ev) => {
      if (ev.key === 'Escape' && !this.$vstack.getFront()) {
        this.close();
      }
    };
    document.addEventListener('keydown', this.escHandler, true);
  }

  private releaseEscHandler() {
    if (this.escHandler) {
      document.removeEventListener('keydown', this.escHandler, true);
      delete this.escHandler;
    }
  }

  get isActive() {
    return this.internalValue;
  }

  set isActive(isActive) {
    if (this.internalValue !== isActive) {
      this.internalValue = isActive;
      this.$emit('input', isActive);
    }
  }

  get styles() {
    if (!this.$mq.match.wide) return;
    return {
      top: this.$ui.headerHeight + 'px',
    };
  }

  get classes() {
    return {
      'h-head-modal--dense': this.dense,
    };
  }

  show() {
    this.isActive = true;
  }

  close() {
    this.isActive = false;
  }

  private overlayRequest() {
    return this.layout.overlayRequest(this);
  }

  private releaseOverlayRequest() {
    return this.layout.releaseOverlayRequest(this);
  }
}

export const HHeadModal = tsx
  .ofType<HHeadModalProps, HHeadModalEmits, HHeadModalScopedSlots>()
  .convert(HHeadModalRef);
