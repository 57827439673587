import './HHotelFooter.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelDetail, NavigationItem } from '~/schemes';
import { HNavigationItem } from '~/components';

export interface HHotelFooterProps {
  hotel: HotelDetail;
  navigations?: NavigationItem[];
  tag?: string;
}

export interface HHotelFooterEmits {}

export interface HHotelFooterScopedSlots {}

@Component<HHotelFooterRef>({
  name: 'HHotelFooter',

  render() {
    const TagName = this.tag as 'div';
    return (
      <TagName
        staticClass="h-hotel-footer"
        v-inview={
          this.inviewed
            ? false
            : {
                in: () => {
                  this.inviewed = true;
                },
              }
        }>
        <div staticClass="h-hotel-footer__inner">
          {/* {this.languageInformations.length > 1 && (
            <nav staticClass="h-hotel-footer__languages">
              {this.languageInformations.map(
                ({ id, switchUrl, external, label }) => {
                  const rel = external ? 'noopeneer' : undefined;
                  return (
                    <a
                      staticClass="h-hotel-footer__languages__link"
                      class={{
                        'h-hotel-footer__languages__link--active':
                          id === this.$language.current,
                      }}
                      href={switchUrl}
                      target={external ? '_blank' : '_self'}
                      rel={rel}
                      onClick={(ev) => {
                        ev.preventDefault();
                        this.$language.switch(id);
                      }}>
                      {label}
                    </a>
                  );
                },
              )}
            </nav>
          )} */}
          <div staticClass="h-hotel-footer__section h-hotel-footer__main">
            <div staticClass="h-hotel-footer__logo">
              <img
                staticClass="h-hotel-footer__logo__node"
                src={this.$res.img(this.hotel.logo.stackInvert)}
                alt={this.hotel.name}
              />
            </div>
            <div staticClass="h-hotel-footer__info">
              <h3 staticClass="h-hotel-footer__name">{this.hotel.fullName}</h3>
              <p
                staticClass="h-hotel-footer__address"
                domPropsInnerHTML={
                  this.$t('value.address', this.hotel) as string
                }></p>
            </div>
          </div>
          {this.hasNavigation && (
            <nav staticClass="h-hotel-footer__section h-hotel-footer__navi">
              {this.navigations.map((nav) => (
                <HNavigationItem
                  staticClass="h-hotel-footer__navi__item"
                  props={{
                    ...nav,
                    html: true,
                  }}
                  v-ev={{
                    category: this.$gfev.Category.Move,
                    action: `to-${nav.key}`,
                    id: `footernavi_to-${nav.key}`,
                  }}
                  key={nav.key}
                />
              ))}
            </nav>
          )}
        </div>
      </TagName>
    );
  },
})
export class HHotelFooterRef extends Vue implements HHotelFooterProps {
  @Prop({ type: Object, required: true }) readonly hotel!: HotelDetail;
  @Prop({ type: Array, default: () => [] })
  readonly navigations!: NavigationItem[];

  @Prop({ type: String, default: 'footer' }) readonly tag!: string;

  private inviewed: boolean = false;

  get languageInformations() {
    return this.$language.dynamicInformations;
  }

  get hasNavigation() {
    return false;
    // return this.navigations.length > 0;
  }
}

export const HHotelFooter = tsx
  .ofType<HHotelFooterProps, HHotelFooterEmits, HHotelFooterScopedSlots>()
  .convert(HHotelFooterRef);
