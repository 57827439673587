import './HTabs.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';
import { scrollBy, ScrollResult } from '@dadajam4/scroller';
import { HTabRef } from './HTab';
import { HTabsSlider, HTabsSliderRef } from './HTabsSlider';
import { getVNodeComponentName } from '~/helpers';

export const HTabsMiniHeight = 62;
export const HTabsMiniPadding = 32;

export function computeHTabsMiniOffset(length: number) {
  return length * HTabsMiniHeight + HTabsMiniPadding * 2;
}

export interface HTabsProps {
  value?: string;
  sm?: boolean;
  sticky?: boolean | 'narrow' | 'wide';

  /**
   * trueにするとwide幅の時だけタブが画面の右隅に寄る
   */
  wideMini?: boolean;
  autoScrollOffset?: number;
}

export interface HTabsEmits {}

export interface HTabsScopedSlots {}

@Component<HTabsRef>({
  name: 'h-tabs',

  provide() {
    return {
      tabs: this,
    };
  },

  created() {
    this._valueIsReady = this.value != null && this.value !== '';
  },

  mounted() {
    this.isMounted = true;
  },

  beforeDestroy() {
    this.cancelScroll();
  },

  render() {
    const { default: defaultSlot } = this.$slots;
    const children: VNode[] = [];

    defaultSlot &&
      defaultSlot.forEach((vn) => {
        const name = getVNodeComponentName(vn);
        if (name === 'h-tab') {
          children.push(vn);
          if (!this._valueIsReady) {
            const id =
              vn.componentOptions &&
              vn.componentOptions.propsData &&
              vn.componentOptions &&
              (vn.componentOptions.propsData as any).id;

            if (typeof id === 'string') {
              this.current = id;
              this._valueIsReady = true;
            }
          }
        }
      });

    if (this._valueIsReady && this.isMounted) {
      children.push(<HTabsSlider ref="slider" />);
    }

    return (
      <div
        staticClass="h-tabs"
        class={this.classes}
        style={this.styles}
        {...{
          directives: [
            {
              name: 'resize',
              value: {
                debounce: 250,
                value: (dimension: { width: number; height: number }) => {
                  this.handleResize(dimension);
                  this.$refs.slider.updatePosition();
                },
              },
            },
            {
              name: 'mutation',
              value: {
                debounce: 50,
                options: {
                  childList: true,
                  attributes: true,
                  characterData: true,
                  subtree: true,
                },
                handler: () => {
                  this.$refs.slider.updatePosition();
                },
              },
            },
          ],
        }}>
        <div staticClass="h-tabs__scroller" ref="scroller">
          <div staticClass="h-tabs__content">
            <i staticClass="h-tabs__content__spacer" />
            {...children}
            <i staticClass="h-tabs__content__spacer" />
          </div>
        </div>
      </div>
    );
  },
})
export class HTabsRef extends Vue implements HTabsProps {
  @Model('input', { type: String }) readonly value?: string;
  @Prop({ type: Boolean }) readonly sm!: boolean;
  @Prop([Boolean, String]) readonly sticky!: boolean | 'narrow' | 'wide';

  /**
   * trueにするとwide幅の時だけタブが画面の右隅に寄る
   */
  @Prop({ type: Boolean }) readonly wideMini!: boolean;
  @Prop({ type: Number, default: 20 }) readonly autoScrollOffset!: number;

  $refs!: {
    scroller: HTMLElement;
    slider: HTabsSliderRef;
  };

  private internalValue: string = '';
  private _valueIsReady: boolean = false;
  private isMounted: boolean = false;
  private tabs: HTabRef[] = [];
  private _scrollResult?: ScrollResult;
  private internalWidth: number = 0;
  private internalHeight: number = 0;

  get current() {
    return this.internalValue;
  }

  get isMini() {
    return this.wideMini && this.$mq.match.wide;
  }

  set current(current: string) {
    if (this.internalValue !== current) {
      this.internalValue = current;
      this.$emit('input', current);
    }
  }

  get activeTag(): HTabRef | undefined {
    return this.findTab(this.current);
  }

  get classes() {
    const { sticky } = this;
    const classes: { [key: string]: boolean } = {
      'h-tabs--sm': this.sm,
      'h-tabs--wide-mini': this.wideMini,
    };
    if (sticky) {
      const suffix = sticky === true ? '' : `-${sticky}`;
      classes[`h-tabs--sticky${suffix}`] = true;
    }
    return classes;
  }

  get headerHeight() {
    return this.$ui.headerHeight;
  }

  get styles() {
    if (this.sticky) {
      const { headerHeight } = this;
      if (headerHeight) {
        return {
          top: headerHeight + 'px',
        };
      }
    }
  }

  to(id: string) {
    this.current = id;
  }

  findTab(id: string) {
    return this.tabs.find((tab) => tab.id === id);
  }

  /** @private  */
  addTab(tab: HTabRef) {
    const t = this.tabs.find((t) => t === tab);
    if (!t) {
      this.tabs.push(tab);
    }
  }

  /** @private  */
  removeTab(tab: HTabRef) {
    const t = this.tabs.find((t) => t === tab);
    if (t) {
      const index = this.tabs.indexOf(t);
      this.tabs.splice(index, 1);
    }
  }

  scrollToActiveTab() {
    const { activeTag } = this;
    activeTag && this.scrollToTab(activeTag);
  }

  cancelScroll() {
    if (this._scrollResult) {
      this._scrollResult.cancel();
      delete this._scrollResult;
    }
  }

  scrollToTab(tab: HTabRef, tabLeft?: number, tabWidth?: number) {
    const { autoScrollOffset, isMini } = this;
    const { scroller } = this.$refs;
    const { $el } = tab;
    if (tabLeft === undefined) {
      tabLeft = isMini ? $el.offsetTop : $el.offsetLeft;
    }
    if (tabWidth === undefined) {
      tabWidth = isMini ? $el.offsetHeight : $el.offsetWidth;
    }

    const tabRight = tabLeft + tabWidth;
    let hiddenLeft: number = 0;
    let hiddenRight: number = 0;

    const { scrollLeft, offsetWidth: scrollerWidth } = scroller;
    hiddenLeft = Math.max(scrollLeft - tabLeft, 0);
    hiddenRight = Math.max(tabRight - scrollerWidth - scrollLeft, 0);

    if (hiddenLeft > 0) hiddenLeft += autoScrollOffset;
    if (hiddenRight > 0) hiddenRight += autoScrollOffset;

    let scrollAmount: number = 0;
    if (hiddenRight > 0) {
      scrollAmount = hiddenRight;
    }
    if (hiddenLeft > 0) {
      scrollAmount = -hiddenLeft;
    }

    if (Math.abs(scrollAmount) > 0) {
      this.cancelScroll();
      scrollBy(scrollAmount, 0, {
        container: scroller,
        duration: 150,
      });
    }
  }

  @Watch('value', { immediate: true })
  protected valueChangeHandler(value: HTabsRef['value']) {
    if (value != null) {
      this.internalValue = value;
    }
  }

  private handleResize(dimension: { width: number; height: number }) {
    this.internalWidth = dimension.width;
    this.internalHeight = dimension.height;
  }
}

export const HTabs = tsx
  .ofType<HTabsProps, HTabsEmits, HTabsScopedSlots>()
  .convert(HTabsRef);
