// import '@dadajam4/vue-stack/dist/vue-stack.css';
import * as tsx from 'vue-tsx-support';

import Vue from 'vue';
import { Context, Plugin } from '@nuxt/types';
import VueStackPlugin, { VueStackOptions } from '@dadajam4/vue-stack';
import { Device } from '@nuxtjs/device/lib/types';

import routerSync from './router';
import envService from './env';
import loggerService from './logger';
import errorService from './error';
import uaService from './ua';
import dataBucketService from './data';
import gfAPI from './gf-api';
import axiosService from './axios';
import apiService from './api';
import resourceService from './resource';
import windowService from './window';
import portalService from './portal';
import mediaQueryService from './media-query';
import languageService from './language';
import i18nPlugin from './i18n';
import GFEventServicePlugin from './gfev';
import commonsService from './commons';
import themeService from './theme';
import uiService from './ui';
import navigationService from './navigation';
import accountService from './account';
import hotelService from './hotel';

import * as directives from '~/directives';

declare module '@nuxt/types' {
  interface Context extends Device {}
}

declare module 'vue/types/vue' {
  interface Vue {
    $device: Device;
  }
}

// Turn off productionTip
Vue.config.productionTip = false;

Vue.use<VueStackOptions>(VueStackPlugin, {
  defaultTheme: 'light',
  usePrefersColorScheme: false,
  themes: {
    light: {
      backdrop: 'rgba(0, 0, 0, 0.5)',
      contexts: {
        primary: {
          base: '#00A0CB',
          text: '#fff',
        },
        gray: {
          base: '#999',
          text: '#fff',
        },
      },
    },
  },
  strings: {
    ok: (vm) => {
      return [vm.$t('action.ok') as string];
    },
    cancel: (vm) => {
      return [vm.$t('action.cancel') as string];
    },
  },
  dialog: {
    minWidth: 275,
    maxWidth: 375,
    header: (vm) => {
      return [
        vm.$createElement('i', {
          staticClass: 'v-stack-dialog__header__icon',
        }),
      ];
    },
  },
  dialogActions: {
    cancel: {
      outline: false,
      color: 'gray',
    },
  },
});

Object.keys(directives).forEach((key) => {
  const Directive = (directives as any)[key];
  let name: string = key;
  if (
    Directive &&
    typeof Directive === 'object' &&
    typeof Directive.name === 'string' &&
    Directive.name
  ) {
    name = Directive.name;
  }
  name && Vue.directive(name, Directive);
});

const plugin: Plugin = async (ctx, inject) => {
  routerSync(ctx, inject);
  envService(ctx, inject);
  loggerService(ctx, inject);
  errorService(ctx, inject);
  uaService(ctx, inject);
  axiosService(ctx, inject);
  dataBucketService(ctx, inject);
  gfAPI(ctx, inject);
  apiService(ctx, inject);
  resourceService(ctx, inject);
  windowService(ctx, inject);
  portalService(ctx, inject);
  mediaQueryService(ctx, inject);
  languageService(ctx, inject);
  i18nPlugin(ctx, inject);
  GFEventServicePlugin(ctx, inject);
  commonsService(ctx, inject);
  themeService(ctx, inject);
  uiService(ctx, inject);
  navigationService(ctx, inject);
  accountService(ctx, inject);
  hotelService(ctx, inject);

  if (process.browser) {
    await ctx.store.dispatch('nuxtClientInit', ctx);
  }
};

export default plugin;

// CMSのデバイスエミュレータ起動された時に、スクロールバーをごにょごにょするための処理
if (process.browser && location.search.includes('gf-device-emulated')) {
  document.body.classList.add('h-transparent-scroller');
}
