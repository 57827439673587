import * as tsx from 'vue-tsx-support';
import VueRouter, { Location, Route } from 'vue-router';
import { VNodeData, RenderContext, VNodeChildren } from 'vue';
import { VNode, FunctionalComponentOptions } from 'vue/types';
import { Vue } from 'vue/types/vue';
import { isSameYear } from 'date-fns';
import { toDate } from './';
import {
  GeneralOption,
  EXTERNAL_RE,
  HNavigationItemProps,
  HotelDetail,
  HotelFacility,
  RestaurantCategory,
} from '~/schemes';

export type VueRouteQuery = Location['query'];

export const mergeQuery = (...queries: VueRouteQuery[]): VueRouteQuery => {
  const query: VueRouteQuery = {};
  queries.forEach((row) => {
    for (const key in row) {
      const value = row[key];
      query[key] = value;
    }
  });
  return query;
};

export interface MergedVNodeData extends VNodeData {
  class: (string | object)[];
  style: (string | object)[];
  on?: { [key: string]: Function[] };
  nativeOn?: { [key: string]: Function[] };
}

const mergeVNodeDataSimpleKeys: ['key', 'slot', 'ref', 'keepAlive'] = [
  'key',
  'slot',
  'ref',
  'keepAlive',
];

const mergeVNodeDataAssignKeys: [
  'scopedSlots',
  'staticStyle',
  'props',
  'attrs',
  'domProps',
  'hook',
] = ['scopedSlots', 'staticStyle', 'props', 'attrs', 'domProps', 'hook'];

const mergeVNodeDataListenersKeys: ['on', 'nativeOn'] = ['on', 'nativeOn'];

const mergeVNodeDataArrayKeys: ['class', 'style'] = ['class', 'style'];

export const mergeVNodeData = (
  ...sources: (VNodeData | undefined)[]
): MergedVNodeData => {
  const data: MergedVNodeData = {
    class: [],
    style: [],
  };

  const staticClasses: string[] = [];

  sources.forEach((source) => {
    if (!source) {
      return;
    }
    source.staticClass && staticClasses.push(source.staticClass);

    mergeVNodeDataSimpleKeys.forEach((key) => {
      if (source[key] !== undefined) {
        data.key = source.key;
      }
    });

    mergeVNodeDataAssignKeys.forEach((key) => {
      if (source[key]) {
        data[key] = {
          ...(data[key] as any),
          ...source[key],
        };
      }
    });

    mergeVNodeDataListenersKeys.forEach((key) => {
      const lsteners = source[key];
      if (lsteners) {
        data[key] = data[key] || {};
        Object.keys(lsteners).forEach((event) => {
          const functions = lsteners[event];
          data[key]![event] = data[key]![event] || [];
          data[key]![event] = [
            ...data[key]![event],
            ...(Array.isArray(functions) ? functions : [functions]),
          ];
        });
      }
    });

    mergeVNodeDataArrayKeys.forEach((key) => {
      const listSource = source[key];
      if (listSource) {
        data[key] = [
          ...data[key],
          ...(Array.isArray(listSource) ? listSource : [listSource]),
        ];
      }
    });

    if (source.directives) {
      data.directives = data.directives || [];
      data.directives = [...data.directives, ...source.directives];
    }
  });
  const staticClass = staticClasses.join(' ').replace(/\s+/g, ' ').trim();
  if (staticClass) {
    data.staticClass = staticClass;
  }

  return data;
};

export const getQuerySingleValue = (
  query: Route['query'],
  key: string,
  defaultValue: string | undefined = undefined,
): string | undefined => {
  if (!query) {
    return defaultValue;
  }
  const target = query[key];
  if (typeof target === 'string') {
    return target;
  }
  if (target === undefined) {
    return defaultValue;
  }
  const firstValue = target[0];
  return firstValue == null ? defaultValue : firstValue;
};

export function createJavaScriptTransition(
  name: string,
  functions: Record<string, any>,
  mode = 'in-out',
) {
  return tsx.component({
    name,

    functional: true,

    props: {
      mode: {
        type: String,
        default: mode,
      },
    },

    render(h, context): VNode {
      const data = {
        props: {
          ...context.props,
          name,
        },
        on: functions,
      };

      return h('transition', data, context.children);
    },
  });
}

export function replaceQuery(router: VueRouter, query: Route['query']) {
  const { currentRoute } = router;
  const { query: currentQuery } = currentRoute;
  const newQuery = {
    ...currentQuery,
    ...query,
  };
  for (const key in query) {
    if (query[key] == null || query[key] === '') {
      delete newQuery[key];
    }
  }
  const newLocation = {
    ...currentRoute,
    query: newQuery,
  };
  const currentKeys = Object.keys(currentQuery);
  const newKeys = Object.keys(newQuery);
  let hasChanged = currentKeys.length !== newKeys.length;
  if (!hasChanged) {
    for (const key of newKeys) {
      if (currentQuery[key] !== newQuery[key]) {
        hasChanged = true;
        break;
      }
    }
  }

  /** @TODO ちゃんとする */
  hasChanged && router.replace(newLocation as any);
}

export function getVNodeComponentName(vn: VNode): string | undefined {
  const { componentOptions } = vn;
  if (!componentOptions) return undefined;
  const { tag, Ctor } = componentOptions;
  if (Ctor && (Ctor as any).options && (Ctor as any).options.name) {
    return (Ctor as any).options.name as string;
  }
  if (typeof tag === 'string') return tag;
  if (Ctor && typeof Ctor.name === 'string') return Ctor.name;
}

export function getWeekLabel(vm: Vue, week: number): string {
  return vm.$t(`label.dayOfWeek.${week}`) as string;
}

/**
 * 2019年6月1日（土）
 * 2019年6月1日（土）～8月31日（土）
 * ～8月31日（土）
 * @TODO
 *  Node13以降はintlが full-icuで提供されるので、
 *  安定板（14）が出たらそちらへ乗り換える
 */
export function createPeriodText(
  vm: Vue,
  from?: string | null,
  to?: string | null,
  full: boolean = true,
): string {
  if (!from && !to) return vm.$t('label.allYear') as string;
  const suffix = full ? 'Full' : '';
  const { bcp47 } = vm.$language.info;
  const _from = from ? toDate(from) : undefined;
  const _to = to ? toDate(to) : undefined;
  const tmp: string[] = [];
  if (_from) {
    tmp.push(vm.$d(_from, `ymd${suffix}`, bcp47));
  } else {
    tmp.push('');
  }
  if (_to) {
    const key =
      _from && isSameYear(_from, _to) ? `md${suffix}` : `ymd${suffix}`;
    tmp.push(vm.$d(_to, key, bcp47));
  }
  return tmp.join(vm.$t('chore.marginDash') as string).trim();
}

export function createAgeRangeText(
  vm: Vue,
  from?: number | null,
  to?: number | null,
): string {
  if (!from && !to) throw new Error('missng age range');
  let i18nKey = 'value.yearsRange';
  if (!from) {
    i18nKey = 'value.yearsLessThan';
  } else if (!to) {
    i18nKey = 'value.yearsGreaterThan';
  }
  return vm.$t(i18nKey, { from, to }) as string;
}

/** Objのnameプロパティで配列を作る処理 */
export function dumpGeneralOptions2Texts<V = string>(
  options: GeneralOption[],
  values: V[],
) {
  const texts: string[] = [];
  options.forEach(({ value, name }) => {
    if (values.includes(value)) {
      texts.push(name);
    }
  });
  return texts;
}

/** 配列を渡ってきたvmOrSeparatorまたはi18nで管理している文字列で区切る処理 */
export function dumpGeneralOptions2Text<V = string>(
  options: GeneralOption[],
  values: V[],
  vmOrSeparator: Vue | string,
) {
  const texts = dumpGeneralOptions2Texts<V>(options, values);
  const separator =
    typeof vmOrSeparator === 'string'
      ? vmOrSeparator
      : (vmOrSeparator.$t('chore.wordSparator') as string);
  return texts.join(separator);
}

export function createNavigationItemSettings(
  context: RenderContext<HNavigationItemProps>,
  className: string = 'h-navigation-item',
): {
  tag: string;
  data: VNodeData;
  children: VNodeChildren;
  isRouter: boolean;
  isAnchor: boolean;
  isButton: boolean;
  isOpen: boolean;
} {
  const { data, children, props, listeners, parent } = context;
  const {
    label,
    href,
    rel,
    to,
    append,
    exact,
    replace,
    activeClass,
    exactActiveClass,
    html,
  } = props;

  let { target } = props;

  if (target === undefined && href) {
    if (EXTERNAL_RE.test(href)) {
      target = '_blank';
    }
  }

  const isOpen = !!target && target !== '_self';

  let tag: string;
  if (to) {
    tag = 'nuxt-link';
  } else if (href) {
    tag = 'a';
  } else {
    tag = 'button';
  }
  const isRouter: boolean = tag === 'nuxt-link';
  const isAnchor: boolean = tag === 'a';
  const isButton: boolean = tag === 'button';

  let myData: VNodeData;
  if (to) {
    myData = {
      attrs: {
        ...data.attrs,
        target,
      },
      props: {
        to,
        exact,
        activeClass,
        exactActiveClass,
        append,
        replace,
        event: '',
      },
      nativeOn: {
        click: (e: MouseEvent) => {
          parent.$navigation.resolveRoutableAction(e, to);
        },
      },
    };
  } else if (href) {
    const hash = parent.$navigation.getHashByRawLocation(href);
    myData = {
      attrs: {
        ...data.attrs,
        href,
        target,
        rel: rel || (target === '_blank' ? 'noopener' : undefined),
      },
      on: {
        click: (e: MouseEvent) => {
          if (!hash) return;
          if (parent.$navigation.tryScrollToHash(hash)) {
            e.preventDefault();
          }
        },
      },
    };
  } else {
    myData = {
      attrs: {
        ...data.attrs,
        type: (data.attrs && data.attrs.type) || 'button',
      },
      on: listeners,
    };
  }

  const myChildren = label || children;

  const staticClasses = data.staticClass ? [data.staticClass] : [];
  staticClasses.push(className);
  myData.staticClass = staticClasses.join(' ').trim();
  if (html && typeof myChildren === 'string') {
    myData.domProps = {
      ...myData.domProps,
      innerHTML: myChildren,
    };
  }

  return {
    tag,
    data: {
      ...data,
      ...myData,
    },
    children: html ? undefined : myChildren,
    isRouter,
    isAnchor,
    isButton,
    isOpen,
  };
}

export const extractHotelFacilities = (
  hotel: Pick<
    HotelDetail,
    | 'restaurants'
    | 'spa'
    | 'kidsRoom'
    | 'pool'
    | 'onsen'
    | 'publicBath'
    | 'conferenceRoom'
  >,
  vm: Vue,
): HotelFacility[] => {
  const restaurants = hotel.restaurants.filter(
    (r) => r.category === RestaurantCategory.Restaurant,
  );
  const cafeAndLounges = hotel.restaurants.filter(
    (r) => r.category === RestaurantCategory.CafeAndLounge,
  );

  const facilities: HotelFacility[] = [
    {
      id: 'restaurant',
      name: vm.$t('label.restaurant') as string,
      active: restaurants.length > 0,
    },
    {
      id: 'cafe-and-lounge',
      name: vm.$t('label.cafeAndLounge') as string,
      active: cafeAndLounges.length > 0,
    },
    {
      id: 'spa',
      name: vm.$t('label.spaAndMassage') as string,
      active: hotel.spa.length > 0,
    },
    {
      id: 'kids-room',
      name: vm.$t('label.playroom') as string,
      active: hotel.kidsRoom.length > 0,
    },
    {
      id: 'swimming-pool',
      name: vm.$t('label.pool') as string,
      active: hotel.pool.length > 0,
    },
    {
      id: 'onsen',
      name: vm.$t('label.onsen') as string,
      active: hotel.onsen.length > 0,
    },
    {
      id: 'publicBath',
      name: vm.$t('label.publicBath') as string,
      active: hotel.publicBath.length > 0,
    },
    {
      id: 'conference-room',
      name: vm.$t('label.meetingRoom') as string,
      active: hotel.conferenceRoom.type !== 'not-exist',
    },
  ];

  return facilities;
};

export function translatedTimeRequiredText(
  vm: Vue,
  _minutes: number,
  approximately?: boolean,
): string {
  const hours = Math.floor(_minutes / 60);
  const isAnHour = hours === 1;
  let minutes = _minutes - hours * 60;
  let i18nKey: string;

  if (minutes === 0) {
    i18nKey = isAnHour ? 'anHour' : 'hours';
  } else if (_minutes < 100) {
    i18nKey = 'minutes';
    minutes = _minutes;
  } else {
    i18nKey = isAnHour ? 'anHourAndMinutes' : 'hoursAndMinutes';
  }
  let times = vm.$t(`value.timeRequired.${i18nKey}`, {
    hours,
    minutes,
  }) as string;
  if (approximately) {
    times = `${vm.$t('chore.approximatelyPrefix')}${times}`;
  }
  return times;
}
