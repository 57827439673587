import { DirectiveFunction, DirectiveOptions, VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

export interface DblClickDirectiveBindingValues {
  click?: (e: MouseEvent) => void;
  dblclick?: (e: MouseEvent) => void;
  judgeTime?: number;
  prevent?: boolean;
  stop?: boolean;
}

export interface DblClickDirectiveBinding extends DirectiveBinding {
  value: ((e: MouseEvent) => void) | DblClickDirectiveBindingValues;
}

export interface DblClickDirectiveContext {
  timerId: number | null;
  clickCount: number;
  onclick: (e: MouseEvent) => void;
  values: DblClickDirectiveBindingValues;
  clearTimer: Function;
  destroy: Function;
  isDestroyed: boolean;
}

export interface DblClickElement extends HTMLElement {
  _dbl_click_directive_context_: DblClickDirectiveContext;
}

export type DblClickDirectiveFunction = (
  el: DblClickElement,
  binding: DblClickDirectiveBinding,
  vnode: VNode,
  oldVnode: VNode,
) => void;

const inserted: DblClickDirectiveFunction = function inserted(el, binding) {
  if (!process.browser) {
    return;
  }
  const { value } = binding;
  if (!value) {
    return;
  }

  const bindingValue =
    typeof value === 'function'
      ? {
          dblclick: value,
        }
      : value;

  if (bindingValue.prevent === undefined) {
    bindingValue.prevent = true;
  }

  const context: DblClickDirectiveContext = {
    timerId: null,
    clickCount: 0,
    isDestroyed: false,
    onclick: (e) => {
      if (context.isDestroyed) {
        return;
      }

      if (bindingValue.prevent) {
        e.preventDefault();
      }
      if (bindingValue.stop) {
        e.stopPropagation();
      }

      context.clickCount++;
      if (context.clickCount > 1) {
        context.clickCount = 0;
        context.clearTimer();
        bindingValue.dblclick && bindingValue.dblclick(e);
      } else {
        context.timerId = window.setTimeout(() => {
          if (context.isDestroyed) {
            return;
          }
          context.clearTimer();
          context.clickCount = 0;
          bindingValue.click && bindingValue.click(e);
        }, bindingValue.judgeTime || 350);
      }
    },
    values: bindingValue,
    clearTimer: () => {
      if (context.timerId !== null) {
        clearTimeout(context.timerId);
        context.timerId = null;
      }
    },
    destroy: () => {
      if (context.isDestroyed) {
        return;
      }
      context.clearTimer();
      el.removeEventListener('click', context.onclick, false);
      delete (context as any).onclick;
      delete (context as any).values;
      delete (context as any).clearTimer;
      delete (context as any).destroy;
      delete (el as any)._dbl_click_directive_context_;
      context.isDestroyed = true;
    },
  };

  el._dbl_click_directive_context_ = context;
  el.addEventListener('click', context.onclick, false);
};

const unbind: DblClickDirectiveFunction = function unbind(el) {
  if (!process.browser) {
    return;
  }
  el._dbl_click_directive_context_ &&
    el._dbl_click_directive_context_.destroy();
};

export default {
  name: 'dblclick',
  inserted: inserted as DirectiveFunction,
  unbind: unbind as DirectiveFunction,
} as DirectiveOptions;
