import './HSimpleMap.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HSimpleMap, HSimpleMapMarkerInfo } from './HSimpleMap';
import { HotelInfo } from '~/schemes';

export interface HSimpleEmbedMapProps {
  embedMap?: string | null;
  lazy?: boolean;
  hotel?: HotelInfo | null;
}

export interface HSimpleEmbedMapEmits {}

export interface HSimpleEmbedMapSlots {}

/** 埋め込みマップコンポーネント */
@Component<HSimpleEmbedMapRef>({
  name: 'HSimpleEmbedMap',
  render() {
    const queries: string[] = [];
    const { embedMap, lazy, hotel } = this;

    return (
      <HSimpleMap
        query={queries}
        lazy={lazy}
        embed={hotel ? hotel.embedMap : embedMap}
        mapStyle="grayscale"
        markerInfo={this.hotelMarkerInfo}
      />
    );
  },
})
export class HSimpleEmbedMapRef extends Vue implements HSimpleEmbedMapProps {
  @Prop({ type: String }) readonly embedMap?: string | null;
  @Prop({ type: Boolean }) readonly lazy!: boolean;
  @Prop({ type: Object }) readonly hotel!: HotelInfo;

  get hotelMarkerInfo(): HSimpleMapMarkerInfo | undefined {
    const { hotel } = this;
    return hotel
      ? {
          name: hotel.name,
          body: this.$t('value.address', hotel) as string,
        }
      : undefined;
  }
}

export const HSimpleEmbedMap = tsx
  .ofType<HSimpleEmbedMapProps, HSimpleEmbedMapEmits, HSimpleEmbedMapSlots>()
  .convert(HSimpleEmbedMapRef);
