import './HText.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, TextModule } from '~/schemes';

export const HText = tsx.component({
  name: 'HText',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<TextModule['properties'], AvailableLanguage>
      >,
      required: true,
    },
  },

  render(h, { props }): VNode {
    return (
      <div
        v-wysiwyg={props.value.text}
        class={[
          'h-container',
          `v-text-module`,
          `v-text-module--${props.value.align}`,
          // @memo フォントサイズの指定は今実装していないので、サイズ調整ツール等を導入することになったら使用する
          // `v-text-module--${props.value.size}`,
        ]}></div>
    );
  },
});
