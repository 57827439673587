import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAsp2sqCBEUk8rearm4x_TchU2aFjIO8Dk',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    installComponents: true, // ここを true に設定
  },
});
