import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, SimpleTableRow } from '~/schemes';
import { simpleTableTitles } from '~/server-middleware/data-server/adapter/ACCOAdapter/mocks/simpleTable';

/**
 * タイトル（項目名）をマスタデータと手入力とを出し分けるメソッド
 */
export function formatedTitle(
  row: ResolvedLanugageData<SimpleTableRow, AvailableLanguage>,
  lang: AvailableLanguage,
): string {
  // 手入力の場合はそれをそのままreturnする
  if (!row.selectedTitle) {
    return row.title || '';
  }
  // マスタデータのvalueと一致するものを探す
  const formatedTitle = simpleTableTitles.find((simpleTableTitle) => {
    return row.selectedTitle === simpleTableTitle.value;
  });

  return (formatedTitle && formatedTitle.name[lang]) || '';
}
