import './HListGallery.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNodeData } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import {
  HListGalleryItem,
  HListGalleryItemRef,
  HListGalleryItemProps,
} from './HListGalleryItem';
import { mergeVNodeData } from '~/helpers';

export interface HListGalleryItemInput
  extends Omit<HListGalleryItemProps, 'index'> {
  /** サムネイルレベルの画像URL */
  thumbUrl?: string;
  /** 画像のアスペクト比。現場Listは4:5のみ */
  aspectRatio?: string;
}

export interface HListGalleryProps {
  items: HListGalleryItemInput[];
}

export interface HListGalleryEmits {}

export interface HListGalleryScopedSlots {}

const MAX_IMAGES_LENGTH = 4;

@Component<HListGalleryRef>({
  name: 'HListGallery',
  provide() {
    return {
      gallery: this,
    };
  },
  render() {
    const data: VNodeData = {};
    const { computedItems } = this;
    const items = computedItems.slice(0, MAX_IMAGES_LENGTH);
    /** 指定画像数によってcssの出し分けを行う */
    const itemLength = items.length;

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: `h-list-gallery--${itemLength}`,
        })}>
        <div staticClass="h-list-gallery__items">
          {items.map((item) => (
            <HListGalleryItem
              ref="items"
              refInFor
              images={item.images}
              aspectRatio={item.aspectRatio}
            />
          ))}
        </div>
      </div>
    );
  },
})
export class HListGalleryRef extends Vue implements HListGalleryProps {
  $refs!: {
    items: HListGalleryItemRef[];
  };

  @Prop({ type: Array, required: true })
  readonly items!: HListGalleryItemInput[];

  get computedItems(): HListGalleryItemInput[] {
    return this.items.map((item) => {
      const { images, aspectRatio } = item;
      return {
        images,
        aspectRatio,
      };
    });
  }
}

export const HListGallery = tsx
  .ofType<HListGalleryProps, HListGalleryEmits, HListGalleryScopedSlots>()
  .convert(HListGalleryRef);
