import './HSelect.scss';

import * as tsx from 'vue-tsx-support';
import { VNode } from 'vue';
import { ScopedSlot } from 'vue/types/vnode';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import {
  HFormControl,
  HFormControlProps,
  HFormControlEmits,
  HFormControlScopedSlots,
} from './mixins/HFormControl';
import { HOption, HOptionRef } from './HOption';
import {
  HBtn,
  HBtnRef,
  HBtnSize,
  HBtnColor,
  HIcon,
  HMenu,
  HMenuRef,
} from '~/components';

export interface HSelectItem {
  label: string | ScopedSlot;
  value: any;
  hidden?: boolean;
}

export interface HSelectProps extends HFormControlProps {
  items: HSelectItem[];
  size?: HBtnSize;
  backdrop?: boolean;
  color?: HBtnColor;
  errorColor?: HBtnColor;
  block?: boolean;
}

export interface HSelectEmits extends HFormControlEmits {
  focus: FocusEvent;
  blur: FocusEvent;
}

export interface HSelectScopedSlots extends HFormControlScopedSlots {}

@Component<HSelectRef>({
  name: 'HSelect',
})
export class HSelectRef extends Mixins(HFormControl) implements HSelectProps {
  $refs!: {
    btn: HBtnRef;
    menu: HMenuRef;
  };

  @Prop({ type: Array, required: true }) readonly items!: HSelectItem[];
  @Prop({ type: String }) readonly size?: HBtnSize;
  @Prop({ type: Boolean }) readonly backdrop!: boolean;
  @Prop({ type: Boolean }) readonly block!: boolean;
  @Prop({ type: String, default: 'plain' }) readonly color!: HBtnColor;
  @Prop({ type: String, default: 'error' }) readonly errorColor!: HBtnColor;

  get classes() {
    return {
      'h-select--has-error': this.hasError,
    };
  }

  showMenu() {
    const { menu } = this.$refs;
    if (!menu || this.isReadonly || this.isDisabled) {
      return;
    }
    return menu.show(this.$refs.btn.$el);
  }

  closeMenu() {
    const { menu } = this.$refs;
    if (!menu) return;
    return menu.close();
  }

  selectOption(option: HOptionRef) {
    this.childFormNodes.forEach((node) => {
      if ((node as any)._uid === (option as any)._uid) {
        (node as HOptionRef).choiced = true;
      } else {
        (node as HOptionRef).choiced = false;
      }
    });
    this.value = option.value;
    this.validationValueHasChanged = true;
    this.closeMenu();
  }

  protected focusNode() {
    const { btn } = this.$refs;
    if (!btn || !btn.$el) return;
    btn.$el.focus();
  }

  protected blurNode() {
    const { btn } = this.$refs;
    if (!btn || !btn.$el) return;
    btn.$el.blur();
  }

  get computedItems() {
    return this.items.map((item) => {
      const { label } = item;
      const displayLabel = typeof label === 'function' ? label(this) : label;
      return {
        ...item,
        displayLabel,
      };
    });
  }

  get selectedItem() {
    const { value } = this;
    return this.computedItems.find((item) => item.value === value);
  }

  get computedColor() {
    return this.hasError ? this.errorColor : this.color;
  }

  protected genBodyChildren(): VNode[] {
    const { computedItems: items, selectedItem } = this;

    // @todo
    // ちゃんと実装する
    const selections: VNode[] = [];
    if (selectedItem) {
      selections.push(
        <div staticClass="h-select__selection" key={selectedItem.value}>
          {selectedItem.displayLabel}
        </div>,
      );
    }

    if (selections.length === 0) {
      selections.push(
        <div
          staticClass="h-select__selection h-select__selection--empty"
          key="__empty__"
          domPropsInnerHTML={'&nbsp;'}
        />,
      );
    }

    return [
      <div staticClass="h-select" class={this.classes}>
        <HBtn
          staticClass="h-select__box"
          tabindex={this.computedTabIndex || 0}
          left
          block={this.block}
          disabled={this.isDisabled}
          size={this.size}
          color={this.computedColor}
          type="button"
          onClick={(ev) => {
            this.showMenu();
          }}
          onFocus={(ev) => {
            this.showMenu();
            this.onNodeFocus();
            this.$emit('focus', ev);
          }}
          onBlur={(ev) => {
            this.closeMenu();
            this.onNodeBlur();
            this.$emit('blur', ev);
          }}
          ref="btn">
          <HMenu
            contentClass="h-select__menu"
            distance={0}
            alwaysRender
            width="fit"
            backdrop={this.backdrop}
            ref="menu">
            <div staticClass="h-select__menu__inner">
              {items
                .filter((item) => !item.hidden)
                .map((item) => (
                  <HOption value={item.value}>{item.displayLabel}</HOption>
                ))}
            </div>
          </HMenu>
          <div staticClass="h-select__selections">{selections}</div>
          <HIcon staticClass="h-select__icon" name="keyboard-arrow-down" />
        </HBtn>
      </div>,
    ];
  }
}

export const HSelect = tsx
  .ofType<HSelectProps, HSelectEmits, HSelectScopedSlots>()
  .convert(HSelectRef);
