import './HKeyVisualModule.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  KeyVisualModuleProperties,
  KEY_VISUAL_MODULE_BREAKPOINTS,
  KeyVisualOptimizedProperties,
} from './schemes';
import { extractKeyVisualOptimizedProperties } from './helpers';
import { HKeyVisualModulePart } from './HKeyVisualModulePart';

export interface HKeyVisualModuleProps {
  /** キービジュアルモジュールのプロパティ */
  properties: KeyVisualModuleProperties;
}

export interface HKeyVisualModuleEmits {}

export interface HKeyVisualModuleScopedSlots {}

/**
 * キービジュアルモジュール
 *
 * * このモジュールは内部でブレイクポイント別に2つの子コンポーネントをレンダリングする。これは実質それらのラッパー
 */
@Component<HKeyVisualModuleRef>({
  name: 'HKeyVisualModule',
  render() {
    return (
      <div class="h-key-visual-module">
        {this.optimizedProperties.map((properties) => {
          return (
            <HKeyVisualModulePart
              key={properties.breakpoint}
              properties={properties}
            />
          );
        })}
      </div>
    );
  },
})
export class HKeyVisualModuleRef extends Vue implements HKeyVisualModuleProps {
  /**
   * 言語抽出されたキービジュアルモジュールプロパティ
   */
  @Prop({ type: Object, required: true })
  readonly properties!: KeyVisualModuleProperties;

  /**
   * ブレイクポイント毎のデータのリスト
   */
  get optimizedProperties(): KeyVisualOptimizedProperties[] {
    const { properties } = this;

    return KEY_VISUAL_MODULE_BREAKPOINTS.map((breakpoint) =>
      extractKeyVisualOptimizedProperties(properties, breakpoint),
    );
  }
}

export const HKeyVisualModule = tsx
  .ofType<
    HKeyVisualModuleProps,
    HKeyVisualModuleEmits,
    HKeyVisualModuleScopedSlots
  >()
  .convert(HKeyVisualModuleRef);
