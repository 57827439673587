import './HLinkButtonList.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { HLinkButton } from './HLinkButton';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, LinkButtonListModule } from '~/schemes';
import { mergeVNodeData } from '~/helpers';

export const HLinkButtonList = tsx.component({
  name: 'HLinkButtonList',
  functional: true,
  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<
          LinkButtonListModule['properties'],
          AvailableLanguage
        >
      >,
      required: true,
    },
    /** コンテナクラスの付与を無効にする場合に指定する */
    disableContainer: Boolean,
  },

  render(h, { props, data }): VNode {
    const { children, size } = props.value;

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'v-link-button-list-module',
          class: [
            !props.disableContainer && 'h-container',
            `v-link-button-list-module--${size}`,
          ],
        })}>
        <div class="v-link-button-list-module__children">
          {children.map((child) => (
            <HLinkButton
              staticClass="v-link-button-list-module__child"
              value={child.properties}
              key={child.moduleId}
              disableContainer
            />
          ))}
        </div>
      </div>
    );
  },
});
