import { parseISO, startOfToday } from 'date-fns';

const TMP_TIMEZONE_MATCH_RE = /(\+\d{2}:\d{2}|Z)$/;

export function safeDateString(source: string): string {
  source = source.replace(/\//g, '-');
  if (TMP_TIMEZONE_MATCH_RE.test(source)) {
    if (!source.includes('T')) source += 'T00:00:00';
    source += '+09:00';
  }
  return source;
}

export function safeDateStrings(strings: string[]): string[] {
  return strings.map((s) => safeDateString(s));
}

export function stringToDate(source: string): Date {
  return parseISO(safeDateString(source));
}

export function toDateOrNumber(source: Date | string | number): Date | number {
  if (typeof source === 'string') {
    return stringToDate(source);
  }
  return source;
}

export function toDate(source: Date | string | number): Date {
  if (typeof source === 'number') {
    return new Date(source);
  } else if (typeof source === 'string') {
    return stringToDate(source);
  } else {
    return source;
  }
}

export const today = startOfToday;

/**
 * yyyy/MM/dd hh:MM:ss
 */
export function createSimpleDateTimeString(dt: Date = new Date()) {
  function pad(v: number, length = 2) {
    return String(v).padStart(length, '0');
  }

  const year = dt.getUTCFullYear();
  const month = pad(dt.getUTCMonth() + 1);
  const date = pad(dt.getUTCDate());
  const hours = pad(dt.getUTCHours());
  const minutes = pad(dt.getUTCMinutes());
  const seconds = pad(dt.getUTCSeconds());
  return `${year}/${month}/${date} ${hours}:${minutes}:${seconds}`;
}

/**
 * こういうの「23:10」を、「11:10 PM」こういう風にする
 * 1) 12時間制＋半角＋AM／PM
 * 2)1桁の時間：0:00 AM（最初の０は空白にする）
 *   2桁の時間：00:00 PM
 * 昼の12時は12:00 PM 夜の12時は 12:00 AM と表示。
 * @see: https://en.wikipedia.org/wiki/12-hour_clock
 */
export function hour12(source: string | number | Date) {
  let hours: number;
  let minutes: number;
  if (typeof source !== 'string') {
    const dt = toDate(source);
    hours = dt.getHours();
    minutes = dt.getMinutes();
  } else {
    const [_h, _m] = source.split(':');
    hours = Number(_h);
    minutes = Number(_m);
    if (isNaN(hours) || isNaN(minutes)) {
      return '';
    }
  }
  const allMinutes = hours * 60 + minutes;
  const isPM = Math.ceil((allMinutes + 1) / 720) % 2 === 0;
  const suffix = isPM ? 'PM' : 'AM';
  hours = hours % 12;
  if (hours === 0) hours = 12;
  return `${hours}:${String(minutes).padStart(2, '0')} ${suffix}`;
}
