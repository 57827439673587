import './HBtn.scss';

import * as tsx from 'vue-tsx-support';
import { VNode, VNodeChildren } from 'vue';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { HProgressCircular } from '../HProgressCircular';
import {
  RoutableMixin,
  RoutableMixinProps,
  RoutableMixinEmits,
} from '~/mixins/routeable';
import { HIcon, IconName } from '~/components/HIcon';
import { HFormNode } from '~/components/HForm/mixins/HFormNode';

export const HBtnColors = [
  'default',
  'plain',
  'plain-touch',
  'primary',
  'primary-wrap',
  'glass',
  'skelton',
  'darken',
  'default-recx',
] as const;

export const HBtnSizes = ['sm', 'md', 'lg'] as const;

export const HBtnWidths = ['sm', 'md', 'lg'] as const;

export type HBtnColor = typeof HBtnColors[number];

export type HBtnSize = typeof HBtnSizes[number];

export type HBtnWidth = typeof HBtnWidths[number];

export interface HBtnProps extends RoutableMixinProps {
  color?: HBtnColor;
  size?: HBtnSize;
  width?: HBtnWidth;
  active?: boolean;
  block?: boolean;
  depressed?: boolean;
  stack?: string;
  stackFontDefault?: boolean;
  stackIcon?: IconName | null;
  prependStack?: boolean;
  left?: boolean;
  toggleIcon?: boolean;
  breadcrumb?: boolean;
  bigIcon?: IconName | null;
  bigIconLiquid?: boolean;
  /**
   * trueにすると以下が自動で付与される
   * 左アイコン: 「→」
   * 色: skelton
   * 高さ: 42px
   * 左右余白: 16px
   * ※ボタンのサイズ定義がばらけて本当はいやだ。。。
   */
  moveIn?: boolean;
}

export interface HBtnEmits extends RoutableMixinEmits {
  onChangeActive: boolean;
  onTabfocus: FocusEvent;
  onFocus: FocusEvent;
  onBlur: FocusEvent;
}

export interface HBtnScopedSlots {
  stack: HBtnRef;
}

@Component<HBtnRef>({
  name: 'HBtn',
  inject: {
    parentFormNode: {
      from: 'formNode',
      default: null,
    },
  },
  render(h) {
    const {
      isLoading: loading,
      $slots,
      $scopedSlots,
      stackIcon,
      computedStack,
    } = this;

    const { tag, data } = this.generateRouteLink({
      staticClass: 'h-btn',
      class: this.classes,
      on: {
        focus: (e: FocusEvent) => {
          this.tabFocused = false;
          this.$emit('focus', e);
        },
        blur: (e: FocusEvent) => {
          this.tabFocused = false;
          this.$emit('blur', e);
        },
        keyup: (e: KeyboardEvent) => {
          if (e.which === 9) {
            this.tabFocused = true;
          }
        },
      },
    });

    let stack: VNodeChildren;
    if (stackIcon) {
      stack = [<HIcon staticClass="h-btn__stack-icon" name={stackIcon} />];
    } else if (computedStack) {
      stack = computedStack;
    } else if ($scopedSlots.stack) {
      stack = $scopedSlots.stack(this);
    }

    let contentChildren: VNodeChildren;
    if (stack) {
      contentChildren = [
        <span
          staticClass="h-btn__stack-main"
          class={{
            'h-btn__stack-main--default': this.stackFontDefault,
          }}>
          {$slots.default}
        </span>,
      ];
      const $sub = <span staticClass="h-btn__stack-sub">{stack}</span>;

      if (this.prependStack) {
        contentChildren.unshift($sub);
      } else {
        contentChildren.push($sub);
      }
    } else {
      contentChildren = $slots.default;
    }

    const btnChildren: VNode[] = [
      <span staticClass="h-btn__content">{contentChildren}</span>,
    ];
    if (loading) {
      btnChildren.push(
        <HProgressCircular
          staticClass="h-btn__loading"
          indeterminate
          size={this.loadingSize}
          width={2}
        />,
      );
    } else {
      if (this.bigIcon) {
        btnChildren.unshift(
          <HIcon staticClass="h-btn__big-icon" name={this.bigIcon} />,
        );
      }

      if (this.toggleIcon) {
        btnChildren.push(
          <HIcon staticClass="h-btn__toggle-icon" name="keyboard-arrow-down" />,
        );
      }
      if (this.moveIn) {
        btnChildren.unshift(
          <HIcon staticClass="h-btn__move-in-icon" name="arrow-right" />,
        );
      }

      if (this.breadcrumb) {
        btnChildren.push(
          <HIcon staticClass="h-btn__breadcrumb" name="triangle-right" />,
        );
      }
    }

    return h(tag, data, btnChildren);
  },
})
export class HBtnRef extends Mixins(RoutableMixin) implements HBtnProps {
  readonly $el!: HTMLAnchorElement | HTMLButtonElement;
  readonly parentFormNode!: HFormNode | null;

  @Prop({ type: String, default: 'default' }) readonly color!: HBtnColor;
  @Prop({ type: String }) readonly size?: HBtnSize;
  @Prop({ type: String }) readonly width?: HBtnWidth;
  @Prop({ type: Boolean }) readonly active!: boolean;
  @Prop({ type: Boolean }) readonly block!: boolean;
  @Prop({ type: Boolean }) readonly depressed!: boolean;
  @Prop({ type: String }) readonly stack?: string;
  @Prop({ type: Boolean }) readonly stackFontDefault!: boolean;
  @Prop({ type: String }) readonly stackIcon?: IconName | null;
  @Prop({ type: Boolean }) readonly prependStack?: boolean;
  @Prop({ type: Boolean }) readonly left!: boolean;
  @Prop({ type: Boolean }) readonly toggleIcon!: boolean;
  @Prop({ type: Boolean }) readonly breadcrumb!: boolean;
  @Prop({ type: String }) readonly bigIcon?: IconName | null;
  @Prop({ type: Boolean }) readonly bigIconLiquid!: boolean;
  @Prop({ type: Boolean }) readonly moveIn!: boolean;

  private internalActive: boolean = this.active;
  private internalTabFocused: boolean = false;

  get parentIsDisabled() {
    const { parentFormNode } = this;
    return parentFormNode ? parentFormNode.isDisabled : false;
  }

  get isDisabled() {
    return this.disabled || this.parentIsDisabled;
  }

  get isActive() {
    return this.internalActive;
  }

  set isActive(isActive: boolean) {
    this.setIsActive(isActive);
  }

  setIsActive(isActive: boolean, withEmit = true) {
    if (this.internalActive !== isActive) {
      this.internalActive = isActive;
      withEmit && this.$emit('changeActive', isActive);
    }
  }

  get tabFocused() {
    return this.internalTabFocused;
  }

  set tabFocused(tabFocused) {
    if (this.internalTabFocused !== tabFocused) {
      this.internalTabFocused = tabFocused;
      this.$emit('tabfocus');
    }
  }

  get computedStack() {
    return (this.stack || '').trim() || undefined;
  }

  get classes() {
    let { color } = this;
    const { size, width, moveIn } = this;

    const classes: { [key: string]: boolean } = {
      'h-btn--noaction': !this.isClickableTag || this.needClickBlock,
      'h-btn--disabled': this.isDisabled,
      'h-btn--loading': this.isLoading,
      'h-btn--active': this.isActive,
      'h-btn--block': this.block,
      'h-btn--depressed': this.depressed,
      'h-btn--tabfocused': this.tabFocused,
      'h-btn--stack': !!this.computedStack || !!this.stackIcon,
      'h-btn--stack-icon': !!this.stackIcon,
      'h-btn--align-left': this.left,
      'h-btn--has-toggle-icon': this.toggleIcon,
      'h-btn--has-breadcrumb': !!this.breadcrumb,
      'h-btn--big-icon-liquid': this.bigIconLiquid,
      'h-btn--move-in': moveIn,
    };

    if (moveIn && color === 'default') {
      color = 'skelton';
    }

    if (color) {
      classes[`h-btn--${color}`] = true;
    }
    if (size) {
      classes[`h-btn--${size}`] = true;
    }
    if (width) {
      classes[`h-btn--width-${width}`] = true;
    }
    return classes;
  }

  get loadingSize() {
    let size = 24;
    const { size: _size } = this;
    if (_size === 'sm') {
      size = 18;
    } else if (_size === 'lg') {
      size = 30;
    }
    return size;
  }

  focus() {
    return this.$el.focus();
  }

  blur() {
    return this.$el.blur();
  }
}

export const HBtn = tsx
  .ofType<HBtnProps, HBtnEmits, HBtnScopedSlots>()
  .convert(HBtnRef);
