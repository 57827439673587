import './HIcon.scss';

import * as tsx from 'vue-tsx-support';
import { PropType } from 'vue';
import { IconNames, IconName } from '~/.dynamic/icons';
export { IconNames, IconName } from '~/.dynamic/icons';

const HIconRaw = tsx.component({
  name: 'HIcon',
  functional: true,
  props: {
    name: {
      type: String as PropType<IconName>,
      required: true,
    },
    left: Boolean,
    right: Boolean,
    center: Boolean,
  },

  render(h, { data, children, props }) {
    const { name, left, right, center } = props;
    const myChildren =
      (children && [<span staticClass="h-icon__label">{children}</span>]) ||
      undefined;

    const staticClasses = data.staticClass ? [data.staticClass] : [];
    staticClasses.push('h-icon', `h-icon-${name}`);
    left && staticClasses.push('h-icon--left');
    right && staticClasses.push('h-icon--right');
    (center || myChildren) && staticClasses.push('h-icon--center');

    const staticClass = staticClasses.join(' ').trim();

    return <i {...{ ...data, staticClass }}>{myChildren}</i>;
  },
});

type HIconComponent = typeof HIconRaw & {
  names: typeof IconNames;
};

export const HIcon = HIconRaw as HIconComponent;

HIcon.names = IconNames;
