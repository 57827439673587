import aspida from '@aspida/axios';
import { Vue } from 'vue/types/vue';
import { Context, Plugin } from '@nuxt/types';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import api from './client/$api';
import { isPreviewHost } from '~/utils/preview';

declare module 'vue/types/vue' {
  export interface Vue {
    $gfAPI: GlobalFormatAPI;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $gfAPI: GlobalFormatAPI;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $gfAPI: GlobalFormatAPI;
  }
}

function initGlobalFormatAPI(ctx: Context) {
  let previewToken: string | undefined;
  let host: string;
  if (process.server) {
    host = ctx.req.headers.host || '';
    ctx.store.commit('SET_HOST', host);
  } else {
    host = ctx.store.state.host;
  }

  const isPreview = isPreviewHost(host, ctx.$env.isDevelop);
  if (isPreview) {
    if (process.server) {
      previewToken = process.env.GF_API_ALB_AUTH_TOKEN;
      ctx.store.commit('api/SET_PREVIEW_TOKEN', previewToken);
    } else {
      previewToken = ctx.store.state.api.previewToken;
    }
  }

  let baseURL = '/.gf-api';

  if (process.server) {
    let { origin } = ctx.$env;

    if (isPreview) {
      baseURL = `${ctx.$env.gfAPIOrigin}${baseURL}`;
    } else {
      if (!origin || origin === '/') {
        origin = 'http://localhost:3000';
      }
      baseURL = `${origin}${baseURL}`;
    }
  }

  const axios = ctx.$axios.create({
    baseURL,
    withCredentials: true,
    cacheBuster: false,
  }) as NuxtAxiosInstance;

  if (process.server) {
    const https = require('https');
    axios.defaults.httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    });
  }

  axios.interceptors.request.use((config) => {
    const headers = {
      ...config.headers,
    };

    if (previewToken) {
      headers.ALB_AUTH_TOKEN = previewToken;
    }

    return {
      ...config,
      params: {
        lang: ctx.$language.current,
        preview: isPreview,
        ...config.params,
      },
      headers,
    };
  });

  const client = api(
    aspida(axios, {
      baseURL,
    }),
  );
  return client;
}

type GlobalFormatAPI = ReturnType<typeof initGlobalFormatAPI>;

// eslint-disable-next-line require-await
const plugin: Plugin = async (ctx, inject) => {
  const api = await initGlobalFormatAPI(ctx);

  ctx.$gfAPI = api;
  inject('gfAPI', api);
};

export default plugin;
