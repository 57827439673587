import './HLoginForm.scss';

import * as tsx from 'vue-tsx-support';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  HFormRef,
  HFormProps,
  HFormEmits,
  HFormScopedSlots,
  HTextField,
  HCheckbox,
} from './';
import { HBtn } from '~/components';
import { email } from '~/validators';
import { AccountProfile } from '~/schemes';

interface MyModel {
  mail: string;
  password: string;
  rememberMe: boolean;
}

const createDefaultModel = (): MyModel => {
  return {
    mail: '',
    password: '',
    rememberMe: false,
  };
};

export interface HLoginFormProps extends HFormProps {
  loginText?: string;
  useHeader?: boolean;
  useRegister?: boolean;
}

export interface HLoginFormEmits extends HFormEmits {
  onLoggedIn: AccountProfile;
  onError: any;
}

export interface HLoginFormScopedSlots extends HFormScopedSlots {}

@Component<HLoginFormRef>({
  name: 'HLoginForm',
})
export default class HLoginFormRef
  extends Mixins(HFormRef)
  implements HLoginFormProps {
  @Prop(String) readonly loginText?: string;
  @Prop({ type: Boolean, default: true }) readonly useHeader?: boolean;
  @Prop({ type: Boolean, default: true }) readonly useRegister?: boolean;

  protected staticClass = 'h-login-form';

  model: MyModel = createDefaultModel();

  private internalSubmiting: boolean = false;

  get submiting() {
    return this.internalSubmiting;
  }

  get customDisabled() {
    return this.submiting || this.$account.loggedIn;
  }

  get isSomeEmpty() {
    return this.model.mail.length === 0 || this.model.password.length === 0;
  }

  get computedLoginText() {
    const { loginText = this.$t('action.login') as string } = this;
    return loginText;
  }

  protected async submitHandler(ev: Event) {
    ev.preventDefault();
    if (this.submiting) return;
    if (!(await this.validateAll())) return;
    this.internalSubmiting = true;
    try {
      const me = await this.$account.login(this.model);
      this.internalSubmiting = false;
      this.$emit('loggedIn', me);
    } catch (_err) {
      this.internalSubmiting = false;
      const err = this.$error.from(_err);
      this.$alert({
        content: [<div domPropsInnerHTML={err.message} />],
      });
      this.$emit('error', err);
    }
  }

  protected genChildren() {
    const { model } = this;

    return [
      <div staticClass="h-login-form__inner">
        {this.useHeader && (
          <h3 staticClass="h-login-form__header">
            {this.$t('guide.loginToHRAccount')}
          </h3>
        )}
        <HTextField
          key="mail"
          staticClass="h-login-form__text-field"
          type="email"
          name="email"
          label={this.$t('label.email') as string}
          v-model={model.mail}
          required
          autocomplete
          autofocus
          validateTiming="change"
          rules={[email]}
          scopedSlots={{
            error: ({ name }) => {
              return {
                required: this.$t('validates.email.required') as string,
                email: this.$t('validates.email.pattern') as string,
              }[name];
            },
          }}
        />
        <HTextField
          key="password"
          staticClass="h-login-form__text-field"
          type="password"
          name="password"
          label={this.$t('label.password') as string}
          v-model={model.password}
          required
          autocomplete
          validateTiming="change"
          scopedSlots={{
            error: ({ name }) => {
              return {
                required: this.$t('validates.password.required') as string,
              }[name];
            },
          }}
        />
        <p staticClass="h-login-form__forget-password">
          <a
            staticClass="h-login-form__forget-password__link"
            href={`/forget/#/${this.$language.info.ygetsKey}`}
            target="forget-password">
            {this.$t('guide.passwordReminder')}
          </a>
        </p>
        <HBtn
          staticClass="h-login-form__submit"
          color="primary"
          size="lg"
          block
          type="submit"
          disabled={this.isDisabled || this.invalid || this.isSomeEmpty}
          loading={this.submiting}>
          {this.computedLoginText}
        </HBtn>
        <HCheckbox
          staticClass="h-login-form__remember-me"
          size="sm"
          v-model={model.rememberMe}>
          {this.$t('action.loginRememberMe')}
        </HCheckbox>
        {this.useRegister && (
          <div staticClass="h-login-form__register">
            <HBtn
              staticClass="h-login-form__register__link"
              href={`/register/#/${this.$language.info.ygetsKey}`}
              target="_blank"
              color="primary-wrap"
              disabled={false}
              block
              size="sm">
              {this.$t('action.accountRegister')}
            </HBtn>
          </div>
        )}
      </div>,
    ];
  }
}

export const HLoginForm = tsx
  .ofType<HLoginFormProps, HLoginFormEmits, HLoginFormScopedSlots>()
  .convert(HLoginFormRef);
