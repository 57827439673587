import './HHotelIntroductionList.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HSpecialPageViewRef } from '../../HSpecialPageView';
import { SpecialPageModuleFilter } from '../../composables/module-filter/filter';
import { HHotelIntroduction } from './HHotelIntroduction';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import {
  AvailableLanguage,
  HotelIntroductionListModule,
  SearchTagCategoryForGF,
} from '~/schemes';
import { HIcon } from '~/components/HIcon';
import { HExpandTransition } from '~/components/transitions';
import { HCheckboxGroup } from '~/components/HForm';
import { HBtn } from '~/components/HBtn';

type ResolvedHotelIntroductionListModule = ResolvedLanugageData<
  HotelIntroductionListModule['properties'],
  AvailableLanguage
>;

export interface HHotelIntroductionListProps {
  value: ResolvedHotelIntroductionListModule;
}

export interface HHotelIntroductionListEmits {}

export interface HHotelIntroductionListScopedSlots {}

@Component<HHotelIntroductionListRef>({
  name: 'HHotelIntroductionList',
  inject: ['specialPageViewRoot'],
  created() {
    this.filter = new SpecialPageModuleFilter(
      {
        properties: this.value,
        childKey: 'hotels',
        searchTagCategories: this.searchTagCategories,
      },
      this,
    );
  },
  render() {
    const { size } = this.value;
    const { categories, selectedTags, filteredChildren } = this;

    return (
      <div
        staticClass="h-hotel-introduction-list-module h-container"
        class={[
          `h-hotel-introduction-list-module--${size}`,
          `h-sp-grid--${size}`,
        ]}>
        {/* 絞り込み条件 */}
        {!!categories.length && (
          <section staticClass="h-hotel-introduction-list-module-filter">
            <div staticClass="h-hotel-introduction-list-module-filter__control">
              <button
                staticClass="h-hotel-introduction-list-module-filter__activator"
                class={
                  this.isOpened &&
                  'h-hotel-introduction-list-module-filter__activator--active'
                }
                type="button"
                onClick={this.toggle}>
                <span staticClass="h-hotel-introduction-list-module-filter__activator__label">
                  {this.filterControlHeading}
                </span>
                <HIcon
                  staticClass="h-hotel-introduction-list-module-filter__activator__icon"
                  class={
                    this.isOpened &&
                    'h-hotel-introduction-list-module-filter__activator--icon-active'
                  }
                  name="keyboard-arrow-down"
                />
              </button>
            </div>
            <HExpandTransition>
              <div v-show={this.isOpened}>
                <div staticClass="h-hotel-introduction-list-module-filter__search-items">
                  {/* 絞り込みチェックボックス */}
                  {categories.map((category) => {
                    return (
                      <div>
                        <legend staticClass="h-hotel-introduction-list-module__search-items__label">
                          {category.name}
                        </legend>
                        <HCheckboxGroup
                          key={category._id}
                          v-model={category.selectedSlugs}
                          items={category.tags.map(({ label, slug }) => {
                            return {
                              label,
                              value: slug,
                            };
                          })}
                          size="sm"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </HExpandTransition>
            {this.isOpened && (
              <div>
                {/* 絞り込みタグ表示エリア */}
                <div>
                  <h5
                    staticClass="h-hotel-introduction-list-module-filter-title"
                    class={
                      !selectedTags.length &&
                      'h-hotel-introduction-list-module-filter-title--disabled'
                    }>
                    {this.$t('label.filters')}
                  </h5>
                  <div staticClass="h-hotel-introduction-list-module-filter-search-condition">
                    <div staticClass="h-hotel-introduction-list-module-filter-search-condition__tags">
                      {this.categories.flatMap((category) => {
                        return category.selectedTags.map(
                          ({ _id, slug, label }) => {
                            return (
                              <HBtn
                                key={_id}
                                staticClass="h-hotel-introduction-list-module-filter-search-condition__tag"
                                size="sm"
                                onClick={() =>
                                  (category.selectedSlugs = category.selectedSlugs.filter(
                                    (selectedSlug) => selectedSlug !== slug,
                                  ))
                                }>
                                {label}
                                <HIcon name="close-circle" right />
                              </HBtn>
                            );
                          },
                        );
                      })}
                    </div>
                    <HBtn
                      staticClass="h-hotel-introduction-list-module-filter-search-condition__clear"
                      size="sm"
                      disabled={!selectedTags.length}
                      onClick={() => this.filter.clear()}>
                      Clear All
                      <HIcon name="close-circle" right />
                    </HBtn>
                  </div>
                </div>
                {/* 検索結果数表示エリア */}
                <p staticClass="h-hotel-introduction-list-module-filter-result">
                  {this.$t('label.searchResult')}：
                  <span staticClass="h-hotel-introduction-list-module-filter-result__count">
                    {filteredChildren.length}
                  </span>
                </p>
              </div>
            )}
          </section>
        )}
        <div staticClass="h-hotel-introduction-list-module__children">
          {filteredChildren.map((child) => (
            <HHotelIntroduction
              staticClass="h-hotel-introduction-list-module__child"
              value={child.properties}
              size={size}
              disableContainer
              key={child.key}
            />
          ))}
        </div>
      </div>
    );
  },
})
export class HHotelIntroductionListRef
  extends Vue
  implements HHotelIntroductionListProps {
  readonly specialPageViewRoot?: HSpecialPageViewRef;
  @Prop(Object) readonly value!: ResolvedHotelIntroductionListModule;

  /**
   * 絞り込みチェクボックスを表示/非表示を制御する
   * - true: 表示 false: 非表示
   */
  private isOpened: boolean = false;

  /** 絞り込み全般処理 */
  private filter!: SpecialPageModuleFilter<
    ResolvedHotelIntroductionListModule,
    'hotels'
  >;

  /** 見出し */
  get filterControlHeading() {
    return this.filter.controlHeading;
  }

  /** 絞り込みチェクボックスに表示する検索カテゴリリスト */
  get categories() {
    return this.filter.categories;
  }

  /** 選択された検索タグリスト */
  get selectedTags() {
    return this.categories.flatMap((category) =>
      category.selectedTags.map((tag) => tag.label),
    );
  }

  /** 画面表示対象の子モジュール(施設紹介) */
  get filteredChildren() {
    return this.filter.filteredChildren;
  }

  /** 検索タグカテゴリマスタ */
  get searchTagCategories(): SearchTagCategoryForGF[] {
    return this.specialPageViewRoot?.searchTagCategories || [];
  }

  /** 絞り込みチェクボックスの表示切り替え */
  toggle() {
    this.isOpened = !this.isOpened;
  }
}

export const HHotelIntroductionList = tsx
  .ofType<
    HHotelIntroductionListProps,
    HHotelIntroductionListEmits,
    HHotelIntroductionListScopedSlots
  >()
  .convert(HHotelIntroductionListRef);
