import './HLanguageDialog.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { bodyScrollLock, clickOutside } from '@dadajam4/vue-stack';
import { HDialog, HListLanguageSelector, HPathIcon } from '~/components';

export interface HLanguageDialogProps {
  value?: boolean;
}

export interface HLanguageDialogEmits {
  onInput: boolean;
}

export interface HLanguageDialogScopedSlots {}

/**
 * 言語設定ダイアログ(SP用)
 */
@Component<HLanguageDialogRef>({
  name: 'HLanguageDialog',
  directives: {
    bodyScrollLock,
    clickOutside,
  },
  render() {
    return (
      <HDialog
        contentClass="h-language-dialog"
        active={this.modalIsActive}
        backdrop
        onChange={(active) => {
          this.modalIsActive = active;
        }}
        scopedSlots={{
          header: () => [
            <button
              staticClass="h-language-dialog__close"
              type="button"
              onClick={this.close}>
              <HPathIcon
                staticClass="h-language-dialog__close__icon"
                name="close"
              />
            </button>,
          ],
        }}>
        <HListLanguageSelector narrowDump />
      </HDialog>
    );
  },
  watch: {
    value(value: boolean) {
      this.internalModalActive = value;
    },
  },
})
export class HLanguageDialogRef extends Vue implements HLanguageDialogProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;

  private internalModalActive: boolean = this.value;

  get modalIsActive() {
    return this.internalModalActive;
  }

  set modalIsActive(modalIsActive) {
    if (this.internalModalActive !== modalIsActive) {
      this.internalModalActive = modalIsActive;
      this.$emit('input', modalIsActive);
    }
  }

  show() {
    this.modalIsActive = true;
  }

  close() {
    this.modalIsActive = false;
  }
}

export const HLanguageDialog = tsx
  .ofType<
    HLanguageDialogProps,
    HLanguageDialogEmits,
    HLanguageDialogScopedSlots
  >()
  .convert(HLanguageDialogRef);
