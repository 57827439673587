import './HListSelector.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNodeChildren } from 'vue';
import { Component, Model, Prop } from 'vue-property-decorator';

export type RawHListSelectorSlot =
  | string
  | ((selector: HListSelectorRef) => VNodeChildren);

export interface HListSelectorItem {
  key?: string;
  label: RawHListSelectorSlot;
  sub?: RawHListSelectorSlot;
  value: any;
  disabled?: boolean;
  static?: boolean;
}

export interface HListSelectorProps {
  items: HListSelectorItem[];
  inputValue?: any;
  wide?: boolean;
  title?: RawHListSelectorSlot;
  guide?: RawHListSelectorSlot;

  /**
   * trueにしたら、モバイル幅の時に縦にずらーーーっと並ぶリストになる
   */
  narrowDump?: boolean;
}

export interface HListSelectorEmits {
  onInput: any;
  onClickItem: HListSelectorItem;
}

export interface HListSelectorScopedSlots {}

@Component<HListSelectorRef>({
  name: 'HListSelector',
  render() {
    const { title, guide, items, value: currentValue } = this;

    return (
      <div staticClass="h-list-selector" class={this.classes}>
        {!!title && (
          <h3 staticClass="h-list-selector__title">
            <span staticClass="h-list-selector__title__text">
              {this.resolveSlot(title)}
            </span>
            {!!guide && (
              <small staticClass="h-list-selector__title__guide">
                {this.resolveSlot(guide)}
              </small>
            )}
          </h3>
        )}
        <div staticClass="h-list-selector__items">
          {items.map((item) => {
            const { key: _key, label, sub, value, disabled } = item;
            const key = _key == null ? value : _key;
            const isActive = value === currentValue;
            return (
              <button
                staticClass="h-list-selector__item"
                class={isActive && `h-list-selector__item--active`}
                type="button"
                disabled={disabled}
                key={key}
                onClick={(ev) => {
                  this.$emit('clickItem', item);
                  if (this.static) {
                    return;
                  }
                  this.select(value);
                }}>
                <span staticClass="h-list-selector__item__label">
                  {this.resolveSlot(label)}
                </span>
                {!!sub && (
                  <small staticClass="h-list-selector__item__sub">
                    {this.resolveSlot(sub)}
                  </small>
                )}
              </button>
            );
          })}
        </div>
      </div>
    );
  },
  watch: {
    inputValue(inputValue) {
      this.internalValue = inputValue;
    },
  },
})
export class HListSelectorRef extends Vue implements HListSelectorProps {
  @Model('input') readonly inputValue!: any;
  @Prop({ type: Array, required: true }) readonly items!: HListSelectorItem[];
  @Prop(Boolean) readonly wide!: boolean;
  @Prop([String, Function]) readonly title!: RawHListSelectorSlot;
  @Prop([String, Function]) readonly guide!: RawHListSelectorSlot;
  @Prop(Boolean) readonly narrowDump!: boolean;
  @Prop(Boolean) readonly static!: boolean;

  private internalValue: any = this.inputValue;

  get value() {
    return this.internalValue;
  }

  set value(value) {
    if (this.internalValue !== value) {
      this.internalValue = value;
      this.$emit('input', value);
    }
  }

  get classes() {
    return {
      'h-list-selector--wide': this.wide,
      'h-list-selector--narrow-dump': this.narrowDump,
    };
  }

  private resolveSlot(rawSlot: RawHListSelectorSlot) {
    if (typeof rawSlot === 'function') {
      return rawSlot(this);
    }
    return rawSlot;
  }

  select(value: any) {
    this.value = value;
  }
}

export const HListSelector = tsx
  .ofType<HListSelectorProps, HListSelectorEmits, HListSelectorScopedSlots>()
  .convert(HListSelectorRef);
