import './HBrandHeader.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { HotelBrandBasicInfo } from '~/schemes';
import { HHotelToBooking } from '~/components';
import { mergeVNodeData } from '~/helpers';

export const HBrandHeader = tsx.component({
  name: 'HBrandHeader',
  functional: true,

  props: {
    brand: {
      type: Object as PropType<HotelBrandBasicInfo>,
      required: true,
    },
  },

  render(h, { data, props, parent }): VNode {
    const { brand } = props;

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'h-brand-header',
        })}>
        <div staticClass="h-brand-header__logo">
          <nuxt-link
            staticClass="h-brand-header__logo__link"
            exactActiveClass="h-brand-header__logo__link--active"
            to={parent.$language.link(`/brands/${brand.slug}/`)}>
            <img
              staticClass="h-brand-header__logo__img"
              src={parent.$res.img(
                `/brands/${brand.slug}/logo-header-${parent.$language.current}.svg`,
              )}
              alt={brand.name}
            />
          </nuxt-link>
        </div>
      </div>
    );
  },
});
