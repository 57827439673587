import type {
  MediaInfo,
  MediaTransformation,
} from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

export type {
  MediaInfo,
  MediaDerived,
  MediaTransformation,
  MediaType,
  MediaInfoMeta,
} from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

/**
 * 変換済みメディア or メディアのsrc属性に設定できるstring値
 */
export type AnyMediaSource = string | MediaTransformation;

/**
 * 変換済みメディアかstringを、stringに正規化する
 */
export function normalizeAnyMediaSource(source: AnyMediaSource) {
  return typeof source === 'string' ? source : source.url;
}

/**
 * 指定のメディア情報から、指定の変換名でメディア変換派生情報を取得する
 *
 * * ブランクな情報やURLの場合、デフォルト値を返却する
 *
 * @param name - 変換名
 * @param media - メディア情報
 * @param defaultValue - 派生情報が存在しなかった場合のデフォルト値
 * @returns 変換派生情報、もしくはデフォルト値
 */
export function getMediaTransformation<D = undefined>(
  name: string,
  media: MediaInfo | null | undefined,
  defaultValue?: D,
): MediaTransformation | D {
  if (!media || typeof media !== 'object') return defaultValue as D;
  const { derived } = media;
  if (!derived) return defaultValue as D;
  const hit = derived[name];
  return hit && typeof hit === 'object' ? hit : (defaultValue as D);
}

/**
 * 指定のメディア情報から、指定の変換名でメディアURLを取得する
 *
 * * ブランクな情報やURLの場合、デフォルト値を返却する
 *
 * @param name - 変換名
 * @param media - メディア情報
 * @param defaultValue - URLが存在しなかった場合のデフォルト値
 * @returns URL、もしくはデフォルト値
 */
export function getMediaDerived<D = undefined>(
  name: string,
  media: MediaInfo | null | undefined,
  defaultValue?: D,
): string | D {
  const transformation = getMediaTransformation(name, media);
  const url = transformation && transformation.url;
  return url || (defaultValue as D);
}

/**
 * 指定のメディア情報リストから、指定の変換名でメディアURLのリストを抽出する
 *
 * * ブランクな情報やURLは除去される
 *
 * @param name - 変換名
 * @param medias - メディア情報リスト
 * @returns - メディアURLのリスト
 */
export function extractMediaDerived(
  name: string,
  medias: MediaInfo[] | null | undefined,
): string[] {
  const results: string[] = [];

  medias &&
    medias.forEach((media) => {
      const url = getMediaDerived(name, media);
      if (url) {
        results.push(url);
      }
    });

  return results;
}
