import { VNode } from 'vue';
import {
  DirectiveFunction,
  DirectiveOptions,
  DirectiveBinding,
} from 'vue/types/options';

export interface AnchorableDirectiveValue {
  target?: string;
  offset?: number;
  active?: boolean;
  onJump?: Function;
}

export interface AnchorableDirectiveBinding extends DirectiveBinding {
  value?: AnchorableDirectiveValue | string | boolean | Function;
}

export interface AnchorableDirectiveContext {
  click: (e: MouseEvent) => void;
  target: string;
  offset: number;
  active?: boolean;
  destroy: Function;
  onJump?: Function;
}

export interface AnchorableElement extends HTMLElement {
  _anchorable_directive_context_: AnchorableDirectiveContext;
}

const setupContext: AnchorableDirectiveFunction = function setupContext(
  el: AnchorableElement,
  binding: AnchorableDirectiveBinding,
  vnode: VNode,
) {
  let target: string = '';
  let offset: number = 0;
  let active: boolean = true;
  let onJump: Function | undefined;
  const href = (el as any).href || '';
  const match = href.match(/#(.*)$/);
  if (match) {
    target = match[1];
  }

  if (binding.value) {
    let bindingValue: AnchorableDirectiveValue;
    if (typeof binding.value === 'string') {
      bindingValue = {
        target: binding.value,
      };
    } else if (typeof binding.value === 'boolean') {
      bindingValue = {
        active: binding.value,
      };
    } else if (typeof binding.value === 'function') {
      bindingValue = {
        onJump: binding.value,
      };
    } else {
      bindingValue = {
        ...binding.value,
      };
    }

    if (bindingValue.target !== undefined) {
      target = bindingValue.target;
    }
    if (bindingValue.offset !== undefined) {
      offset = bindingValue.offset;
    }
    if (bindingValue.active === false) {
      active = false;
    }
    onJump = bindingValue.onJump;
  }
  if (!el._anchorable_directive_context_) {
    el._anchorable_directive_context_ = {
      click: (e: MouseEvent) => {
        const { context: vm } = vnode;
        if (!vm) {
          return;
        }

        const directiveContext = el._anchorable_directive_context_;
        const { target, offset, active } = directiveContext;
        if (!target || !active) {
          return;
        }

        const $el = document.getElementById(target);
        if (!$el) {
          return;
        }

        e.preventDefault();

        const payload = vm.$window.toElement($el, {
          offset: -vm.$ui.headerHeight + offset,
        });
        if (directiveContext.onJump) {
          directiveContext.onJump(payload);
        }
      },
      target,
      offset,
      active,
      onJump,
      destroy: () => {
        if (!el._anchorable_directive_context_) {
          return undefined;
        }
        el.removeEventListener(
          'click',
          el._anchorable_directive_context_.click,
          false,
        );
        delete (el as any)._anchorable_directive_context_;
      },
    };
    el.addEventListener(
      'click',
      el._anchorable_directive_context_.click,
      false,
    );
  } else {
    const ctx = el._anchorable_directive_context_;
    ctx.target = target;
    ctx.offset = offset;
    ctx.active = active;
    ctx.onJump = onJump;
  }
};

export type AnchorableDirectiveFunction = (
  el: AnchorableElement,
  binding: AnchorableDirectiveBinding,
  vnode: VNode,
  oldVnode: VNode,
) => void;

const inserted: AnchorableDirectiveFunction = function inserted(
  el,
  binding,
  vnode,
  oldVnode,
) {
  setupContext(el, binding, vnode, oldVnode);
};

const componentUpdated: AnchorableDirectiveFunction = function componentUpdated(
  el,
  binding,
  vnode,
  oldVnode,
) {
  setupContext(el, binding, vnode, oldVnode);
};

const unbind: AnchorableDirectiveFunction = function unbind(el) {
  const { _anchorable_directive_context_: context } = el;
  context && context.destroy();
};

export default {
  name: 'anchor',
  inserted: inserted as DirectiveFunction,
  componentUpdated: componentUpdated as DirectiveFunction,
  unbind: unbind as DirectiveFunction,
} as DirectiveOptions;
