import { Middleware } from '@nuxt/types';
import { AVAILABLE_LANGUAGE_RE } from '~/schemes';

export const REDIRECT_SKIP_REFERRERS = [
  // mailor
  'mail.google.com',
  'mail.yahoo.co.jp',
  'mail.live.com',
  'outlook.live.com',

  // sns
  'twitter.com',
  't.co',
  'facebook.com',
  'instagram.com',
  'pinterest.com',
];

export const REDIRECT_SKIP_REFERRERS_RE = new RegExp(
  `(${REDIRECT_SKIP_REFERRERS.join('|')})`,
);

const languageMiddleware: Middleware = (ctx) => {
  if (process.browser) return;

  const { redirect, route, $language, req } = ctx;
  const cookieLang = $language.getCookie();
  if (!cookieLang) {
    const { referer } = req.headers;
    if (!referer || !REDIRECT_SKIP_REFERRERS_RE.test(referer)) {
      const { fullPath } = route;
      const matched = fullPath.match(AVAILABLE_LANGUAGE_RE);
      const matchedLang = matched && matched[1];
      const switchLang = $language.detectedAvailableLanguage;
      const redirectTo = matchedLang
        ? fullPath.replace(matchedLang, switchLang)
        : `/${switchLang}${fullPath}`;
      if (redirectTo !== fullPath) {
        redirect(redirectTo);
      }
    }
  }
};

export default languageMiddleware;
