import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { KvModuleAspectRatio } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';
import {
  KeyVisualModule,
  ResponsiveBreakpoint,
  AvailableLanguage,
} from '~/schemes';
export { MediaInfo } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

/**
 * キービジュアルモジュールのプロパティ
 */
export interface KeyVisualModuleProperties
  extends ResolvedLanugageData<
    KeyVisualModule['properties'],
    AvailableLanguage
  > {}

/**
 * キービジュアルのモジュールのブレイクポイント別のデータ
 */
export interface KeyVisualOptimizedProperties
  extends Pick<
    KeyVisualModuleProperties,
    | 'bgColorCssToken'
    | 'isHeading'
    | 'titleColor'
    | 'contentsAlign'
    | 'alignSettings'
    | 'flexibleContentsAlign'
  > {
  /**
   * ブレイクポイント
   */
  breakpoint: ResponsiveBreakpoint;

  /**
   * タイトル（とその代替画像とか）
   */
  title: KeyVisualModuleProperties['title'][ResponsiveBreakpoint];

  /**
   * サブタイトル
   */
  subTitle: KeyVisualModuleProperties['subTitle'][ResponsiveBreakpoint];

  /**
   * メディアリスト
   */
  media: KeyVisualModuleProperties['media'][ResponsiveBreakpoint];

  /**
   * sp画像のアスペクト比
   */
  narrowAspectRatio: KvModuleAspectRatio;
}

/**
 * キービジュアルモジュールが対応するブレイクポイントのリスト
 *
 * ※ このモジュールだけに限らないんじゃない？ってのはある。とりあえず定数が欲しいのでここに書いた
 */
export const KEY_VISUAL_MODULE_BREAKPOINTS: ResponsiveBreakpoint[] = [
  'narrow',
  'wide',
];
