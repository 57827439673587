import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { State as RootState } from './';
import { RouteCache } from '~/plugins/api/routes/AbstractRoute';

/**
 * 最大キャッシュレコード数
 */
export const MAX_CACHE_COUNT = 100;

export interface State {
  caches: RouteCache[];
  /**
   * プレビューデータ取得要求時にALBを通過するためのトークン
   * プレビュー環境以外のみで設定される
   */
  previewToken: string | null;
}

export const state = (): State => ({
  caches: [],
  previewToken: null,
});

export const getters: GetterTree<State, RootState> = {};

export const actions: ActionTree<State, RootState> = {};

export const mutations: MutationTree<State> = {
  SET_CACHE(state, cache: RouteCache) {
    const { caches } = state;
    const cureent = caches.find((c) => c.key === cache.key);
    if (cureent) {
      const index = caches.indexOf(cureent);
      caches.splice(index, 1);
    }
    caches.push(cache);
    const count = caches.length;
    const overflow = count - MAX_CACHE_COUNT;
    if (overflow > 0) {
      caches.splice(0, overflow);
    }
  },
  DELETE_CACHE(state, cache: string | RouteCache) {
    const { caches } = state;
    if (typeof cache === 'string') {
      cache = caches.find((c) => c.key === cache) as RouteCache;
    }
    if (cache) {
      const index = caches.indexOf(cache);
      caches.splice(index, 1);
    }
  },
  SET_PREVIEW_TOKEN(state, token: string) {
    state.previewToken = token;
  },
};
