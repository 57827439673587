if (process.browser) {
  if ('scrollRestoration' in window.history) {
    // ページ初期表示時のハッシュのスクロールジャンプがなくなるので、
    // window load後にのみ設定する
    // window.history.scrollRestoration = 'manual';

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto';
    });

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual';

      // ページ内アンカーリンクが設定されている場合強制的にその位置へスクロールする
      // @FIXME
      //    ※ ページ表示後に手動スクロールを検知したらこの処理を序外しないと不親切
      //    ※ ネイティブのスクロール制御動作がガタつくので修正したい
      const { hash } = location;
      if (!hash) return;
      const el = document.querySelector(hash);
      if (!el) return;

      const OFFSET_MARGIN = 20;
      const header = document.querySelector('.h-header');
      const fixedNavi = document.querySelector('.my-hotel-local-navi--fixed');
      const headerHeight = (header && header.offsetHeight) || 0;
      const fixedNaviHeight = (fixedNavi && fixedNavi.offsetHeight) || 0;
      const offsetTop = headerHeight + fixedNaviHeight;
      const elementTop = el.offsetTop;
      window.scrollTo(window.scrollX, elementTop - offsetTop - OFFSET_MARGIN);
    });
  }
}

function getMatchedComponents(route, matches = false, prop = 'components') {
  return Array.prototype.concat.apply(
    [],
    route.matched.map((m, index) => {
      return Object.keys(m[prop]).map((key) => {
        matches && matches.push(index);
        return m[prop][key];
      });
    }),
  );
}

const scrollBehavior = (to, from, savedPosition) => {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false; // if no children detected and scrollToTop is not explicitly disabled
  let handler;

  const Pages = getMatchedComponents(to);

  if (!savedPosition) {
    const CustomPage = Pages.find(
      (Page) => !!Page.options.scrollBehaviorPosition,
    );
    if (CustomPage) {
      handler = CustomPage.options.scrollBehaviorPosition;
    }
  }

  if (
    Pages.length < 2 &&
    Pages.every(function (Page) {
      return Page.options.scrollToTop !== false;
    })
  ) {
    // scroll to the top of the page
    position = {
      x: 0,
      y: 0,
    };
  } else if (
    Pages.some(function (Page) {
      return Page.options.scrollToTop;
    })
  ) {
    // if one of the children has scrollToTop option set to true
    position = {
      x: 0,
      y: 0,
    };
  } // savedPosition is only available for popstate navigations (back button)

  if (savedPosition) {
    position = savedPosition;
  }

  const nuxt = window.$nuxt; // triggerScroll is only fired when a new component is loaded

  if (to.path === from.path && to.hash !== from.hash) {
    nuxt.$nextTick(function () {
      return nuxt.$emit('triggerScroll');
    });
  }

  return new Promise(function (resolve) {
    // wait for the out transition to complete (if necessary)
    nuxt.$once('triggerScroll', function () {
      if (savedPosition) {
        const currentHref = location.href;
        setTimeout(() => {
          if (currentHref === location.href) {
            nuxt.$window.to(savedPosition, { duration: 0 });
          }
        }, 100);
        resolve(position);
        return;
      }

      if (handler) {
        position = handler;
        if (typeof position === 'function') {
          position = position(nuxt, to, from);
        }
        if (typeof position === 'number') {
          position = { x: 0, y: position };
        }
      } else if (to.hash) {
        let hash = to.hash; // CSS.escape() is not supported with IE and Edge.

        if (
          typeof window.CSS !== 'undefined' &&
          typeof window.CSS.escape !== 'undefined'
        ) {
          hash = '#' + window.CSS.escape(hash.substr(1));
        }

        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            const currentHref = location.href;

            position = {
              selector: hash,
              offset: {
                x: 0,
                y: -(nuxt.$ua.isLegacyEdge ? 80 : nuxt.$ui.headerHeight) - 30,
              },
            };

            setTimeout(() => {
              if (currentHref === location.href) {
                const el = document.querySelector(hash);
                if (el) {
                  nuxt.$window.toElement(el, {
                    offset:
                      -(nuxt.$ua.isLegacyEdge ? 80 : nuxt.$ui.headerHeight) -
                      30,
                    duration: 0,
                  });
                }
              }
            }, 250);
          }
        } catch (e) {
          nuxt.$logger.warn(
            'Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).',
          );
        }
      }
      resolve(position);
    });
  });
};

export default scrollBehavior;
