import { loadImage } from '~/libs/image-loupe/helpers';

/**
 * 画像ローダーのリクエスト
 *
 * * 連続ロードに対しては毎回同じプロミスインスタンスを返却する
 * * ロード失敗した時はプロミスインスタンスを破棄する
 */
export class ImageLoaderRequest {
  readonly src: string;
  private _promise?: Promise<HTMLImageElement>;

  constructor(src: string) {
    this.src = src;
  }

  load() {
    if (this._promise) {
      return this._promise;
    }

    this._promise = loadImage(this.src).catch((err) => {
      delete this._promise;
      throw err;
    });

    return this._promise;
  }
}

/**
 * 画像ローダー
 *
 * * キャッシュ機能付き
 */
export class ImageLoader {
  private _requests = new Map<string, ImageLoaderRequest>();

  load(src: string) {
    let req = this._requests.get(src);
    if (!req) {
      req = new ImageLoaderRequest(src);
    }
    return req.load();
  }

  clear() {
    this._requests.clear();
  }
}
