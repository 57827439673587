import { Plugin } from '@nuxt/types';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { AxiosError } from 'axios';
import { isPromise } from '~/helpers';

/**
 * Promiseを返却すればエラーをスキップして正常応答解決する
 */
export type AxiosErrorHandler = (err: AxiosError) => Promise<any> | undefined;

declare module 'axios' {
  interface AxiosRequestConfig {
    baseErrorHandler?: AxiosErrorHandler;
    errorHandler?: AxiosErrorHandler;
  }
}

declare module 'axios' {
  interface AxiosRequestConfig {
    /**
     * キャッシュバスターの有無
     */
    cacheBuster?: boolean | string;
  }
}

declare module 'vue/types/vue' {
  export interface Vue {
    $axios: NuxtAxiosInstance;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $axios: NuxtAxiosInstance;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $axios: NuxtAxiosInstance;
  }
}

/**
 * キャッシュバスターで付与するクエリ名称
 */
const CACHE_BUSTER_QUERY_NAME = '_';

export function cacheBuster(axios: NuxtAxiosInstance) {
  axios.onRequest((config) => {
    const { cacheBuster = true } = config;
    if (
      cacheBuster &&
      config.method &&
      config.method.toLowerCase() === 'get' &&
      config.params[CACHE_BUSTER_QUERY_NAME] == null
    ) {
      config.params = {
        ...config.params,
        [CACHE_BUSTER_QUERY_NAME]:
          typeof cacheBuster === 'boolean' ? Date.now() : cacheBuster,
      };
    }
  });
}

export function injector(instance: NuxtAxiosInstance) {
  cacheBuster(instance);
}

function handleError(
  err: AxiosError,
  ...handlers: (AxiosErrorHandler | undefined)[]
): Promise<any> | undefined {
  for (const handler of handlers) {
    if (typeof handler !== 'function') continue;
    const result = handler(err);
    if (isPromise(result)) {
      return result;
    }
  }
}

const plugin: Plugin = (ctx) => {
  const { $axios } = ctx;

  const _originCreate = $axios.create;
  $axios.create = (config) => {
    const baseErrorHandler = config && config.baseErrorHandler;

    const instance = _originCreate(config) as NuxtAxiosInstance;

    if (process.server && !ctx.$env.isProduction && !ctx.$env.isDevelop) {
      instance.onRequest((config) => {
        const { method, baseURL, url, params } = config;
        const _params = {
          ...params,
        };
        delete _params._;

        ctx.$logger.debug('[axios] request', {
          method,
          baseURL,
          url,
          params: _params,
        });
      });
    }

    instance.onError((err) => {
      const config = err && err.config;
      const handled = handleError(
        err,
        baseErrorHandler,
        (config && err.config.errorHandler) || undefined,
      );

      if (handled) {
        return handled;
      }

      if (!config) {
        if (process.server) {
          ctx.$logger.error(`[axios] request error`, err);
        }
        return Promise.reject(err);
      }

      const { response } = err;
      const {
        params: _params,
        url,
        baseURL,
        method,
        // headers,
      } = config;
      let params: string = 'N/A';
      const status = (response && response.status) || 'N/A';

      try {
        const tmp = {
          ..._params,
        };
        delete tmp._;

        params = {
          ...tmp,
        };
        // eslint-disable-next-line no-empty
      } catch (err) {}

      if (process.server) {
        ctx.$logger.error(`[axios] request error`, {
          baseURL,
          url,
          method,
          params,
          // headers,
          status,
        });
      }

      return Promise.reject(err);
    });

    injector(instance);
    return instance;
  };

  injector($axios);
};

export default plugin;
