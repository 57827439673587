import { Context, Plugin } from '@nuxt/types';
import Vue from 'vue';

import WebFont, { Config as WebFontConfig } from 'webfontloader';
import typekitLoader from './typekit';

interface Resolver {
  resolve: Function;
  reject: Function;
}

export type WebFontServiceTag = number | string;

enum StackState {
  Pending = 'pending',
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

interface Stack {
  tag: WebFontServiceTag;
  state: StackState;
  resolvers: Resolver[];
}

export class WebFontService {
  // static SETTINGS = SETTINGS;
  // static loadeNames: FontNames[] = [];

  static readonly stacks: Stack[] = [];

  private static getStack(tag: WebFontServiceTag): Stack | undefined {
    return this.stacks.find(({ tag: t }) => t === tag);
  }

  private static getOrCreateStack(tag: WebFontServiceTag): Stack {
    let stack = this.getStack(tag);
    if (!stack) {
      stack = {
        tag,
        state: StackState.Pending,
        resolvers: [],
      };
      this.stacks.push(stack);
    }
    return stack;
  }

  static resolveStack(tag: WebFontServiceTag, type: keyof Resolver) {
    const stack = this.getStack(tag);
    if (stack) {
      stack.resolvers.forEach((resolver) => {
        resolver[type]();
      });
      stack.resolvers.length = 0;
    }
  }

  static load(tag: WebFontServiceTag, config: WebFontConfig) {
    return new Promise((resolve, reject) => {
      const stack = this.getOrCreateStack(tag);
      if (stack.state === StackState.Loaded) {
        return resolve(undefined);
      }

      stack.resolvers.push({ resolve, reject });

      if (stack.state !== StackState.Loading) {
        WebFont.load({
          ...config,
          active: () => {
            stack.state = StackState.Loaded;
            this.resolveStack(tag, 'resolve');
          },
          inactive: () => {
            stack.state = StackState.Error;
            this.resolveStack(tag, 'reject');
          },
          fontactive: (familyName) => {
            const convertFamilyName = familyName
              .replace(/\s+/g, '-')
              .toLowerCase();
            ((window as any).$nuxt as Vue).$ui.pushActivatedFont(
              convertFamilyName,
            );
          },
        });
      }
    });
  }
}

export type WebFontServiceStatic = typeof WebFontService;

declare module 'vue/types/vue' {
  interface Vue {
    $font: WebFontServiceStatic;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $font: WebFontServiceStatic;
  }
}

const plugin: Plugin = (ctx, inject) => {
  ctx.$font = WebFontService;
  inject('font', WebFontService);

  window.setTimeout(() => {
    const { font, typekit } = ctx.$language.info;
    if (font) {
      const _font = {
        ...font,
      };

      /**
       * @MEMO IEが素直にNotoを読み込めない
       * 日本語以外のsubset名がすでにAPI変更でわからない事と、日本以外はそもそもIE自体のシェアが少ないので、日本語だけ対応する
       * https://tumuguito.com/archives/978.html
       */
      if (ctx.$ua.isIE || ctx.$ua.isLegacyEdge) {
        if (_font.custom) {
          _font.custom = {
            ..._font.custom,
          };
          if (_font.custom.urls) {
            _font.custom.urls = _font.custom.urls.map((url) => {
              if (url.includes('Noto+Sans+JP')) {
                url = `${url}&subset=japanese`;
              }
              return url;
            });
          }
        }
      }
      WebFontService.load('core', _font);
    }
    typekit &&
      ctx.$ua.useTypekit &&
      typekitLoader({
        id: typekit,
        fontactive: (familyName) => {
          ((window as any).$nuxt as Vue).$ui.pushActivatedFont(familyName);
        },
      });
  }, 0);
};

export default plugin;
