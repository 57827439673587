
import Vue from 'vue';
import { Component, Prop } from 'nuxt-property-decorator';
import { AVAILABLE_LANGUAGES, UnAuthorized } from '~/schemes';

const BUILTIN_STATUSES = [401, 404, 500, 503, 504];

const LANG_MATCH_RE = new RegExp(`^/?(${AVAILABLE_LANGUAGES.join('|')})/`);

@Component<HErrorView>({
  name: 'HErrorView',
  layout: 'default',
  head() {
    return {
      title: this.description,
    };
  },
  mounted() {
    /** これをしないとエラー画像がブラウザを読み込んだ後チラチラ変わってしまう */
    if (process.browser) {
      this.isMounted = true;
    }
  },
  created() {
    const langMatch = this.$route.path.match(LANG_MATCH_RE);
    this.$i18n.locale = (langMatch && langMatch[1]) || 'en';
  },
  render() {
    const { statusCode, description, stack } = this;
    return (
      <div staticClass="h-error-view">
        <div staticClass="h-error-view__error">
          <div
            class="h-error-view__image"
            style={{
              backgroundImage: `url(${this.image})`,
            }}>
            <div staticClass="h-error-view__status-code">{statusCode}</div>
          </div>
          <h1 staticClass="h-error-view__title">{description}</h1>
          <div staticClass="h-error-view__links">
            <a
              staticClass="h-error-view__link"
              href={this.$t('error.homeUrl') as string}>
              {this.$t('error.toHome')}
            </a>
          </div>
        </div>
        {!!stack && !this.stackClosed && (
          <div staticClass="h-error-view__stack">
            {stack}
            <button
              type="button"
              staticClass="h-error-view__stack__close"
              onClick={() => {
                this.stackClosed = true;
              }}>
              x
            </button>
          </div>
        )}
      </div>
    );
  },
})
export default class HErrorView extends Vue {
  @Prop() readonly error!: any;

  stackClosed: boolean = false;
  private isMounted: boolean = false;

  get image() {
    if (this.isMounted) {
      const images = ['bear', 'goat', 'dog', 'cow', 'woodpecker'];
      const randomIndex = Math.floor(Math.random() * images.length);
      const selectedImage = images[randomIndex];
      return this.$res.img(`common/sorry-images/${selectedImage}.png`);
    }
    return '';
  }

  get computedError() {
    return this.$error.deserializeGFErrorByNuxtError(this.error);
  }

  get statusCode() {
    return this.computedError.status;
  }

  get messageStatus() {
    const { statusCode } = this;
    return BUILTIN_STATUSES.includes(statusCode) ? statusCode : 500;
  }

  /** エラーメッセージ取得 */
  get description() {
    const { message } = this.error;
    if (message === UnAuthorized) {
      return this.$t(`error.descriptions.${message}`) as string;
    }
    return (
      (this.$t(`error.descriptions.${this.messageStatus}`) as string) || ''
    );
  }

  get stack() {
    if (this.$env.isProduction) return;
    return this.computedError.stack;
  }
}
