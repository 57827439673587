import AbstractRoute from './AbstractRoute';
import {
  HotelDetail,
  YgHotelDetail,
  VacancyInfo,
  Activity,
  HotelBrandBasicInfo,
  HotelBrandDetail,
  AccessInfo,
  HotelCuisineContentsResponse,
  ActivitiesRequestResult,
} from '~/schemes';
import type { ACCOAdapter } from '~/server-middleware/data-server/adapter/ACCOAdapter';

/**
 * 月間カレンダー取得
 */
export interface GetMonthlyVacancyCalendarParams {
  hotelId: string;
  planId?: string;
  roomId?: string;

  /**
   * 対象日付
   * ※ YYYY/MM までが検索対象日付であり、
   *    /DD の部分は何日でも結果は不変
   */
  monthlyDate: string;
  stayLength?: string;
  adult?: string;
  underTwelve?: string;
  underSeven?: string;
  underFour?: string;
  bufferDays?: string;
}

export default class HotelsRoute extends AbstractRoute {
  all() {
    return this.context.$dataBucket.getHotels();
  }

  getBySlug(slug: string) {
    // すでに同じスラッグの詳細情報があるなら、APIからの取得はキャンセルする
    const { current } = this.context.$hotel;
    if (current && current.slug === slug) {
      return Promise.resolve(current);
    }
    return this._getBySlug(slug);
  }

  /**
   * 指定のスラッグの施設詳細情報を取得する
   *
   * * 宿GETSマスタは強制的にnullを設定する ※ クライアントでロードする
   *
   * @param slug - 施設スラッグ
   * @returns 施設詳細情報 or undefined
   */
  private async _getBySlug(slug: string): Promise<HotelDetail | undefined> {
    slug = slug.trim();
    const hotel = await this.context.$dataBucket.getHotelBySlug(slug);
    if (hotel) {
      return {
        ...hotel,
        ygets: null,
      };
    }
  }

  getYgetsInfo(hotelId: string) {
    const key = `getYgetsInfo_${hotelId}`;
    return this.asyncResolver<YgHotelDetail | null>(
      key,
      () => {
        return this._getYgetsInfo(hotelId);
      },
      {
        key,
        // maxAge: 30,
      },
    );
  }

  private _getYgetsInfo(hotelId: string) {
    return this.$get<YgHotelDetail | null>('/hotels/detail', {
      params: {
        hotelId,
      },
      errorHandler: (err) => {
        if (this.getErrorStatus(err) === 501) {
          return Promise.resolve(null);
        }
      },
    });
  }

  activitiesBySlug(slug: string): Promise<Activity[]> {
    return this.context.$dataBucket.get<Activity[]>(
      `/hotels/${slug}/activities`,
    );
  }

  /**
   * アクティビティ一覧＆検索表示用キーワード一覧を取得する
   *
   * @param slug - ホテルのスラッグ
   */
  activitiesWithKeywordsBySlug(slug: string): Promise<ActivitiesRequestResult> {
    return this.context.$dataBucket.get<ActivitiesRequestResult>(
      `/hotels/${slug}/activities-with-keywords`,
    );
  }

  activityBySlugAndId(slug: string, id: string): Promise<Activity> {
    return this.context.$dataBucket.get<Activity>(
      `/hotels/${slug}/activities/${id}`,
    );
  }

  accessInformationsBySlug(
    slug: string,
  ): Promise<{ informations: AccessInfo[] }> {
    return this.context.$dataBucket.get<{ informations: AccessInfo[] }>(
      `/hotels/${slug}/access`,
    );
  }

  /**
   * 施設の料理コンテンツを取得する
   *
   * @FIXME
   *
   * ## NOTES
   * * このメソッドは、現状中途半端な実装になっています
   * * リゾナーレの設計の際、料理構成は特定の食事処に紐づくことにしましたが、公開jsonのパスの定義上、施設は単一の料理構成しか公開できないことになっています
   * * したがって、このメソッドでは食事処IDをオプションとして受け取ってフィルタできることにしていますが、これはちょっと中途半端な実装状態です
   * * TO BEは、施設が料理構成自体を複数保有できるように、公開jsonパスを定義しなおすことです
   * * 食事関連のデータの階層を見直すTODOが残っているので、その時に対応し、既存データを移行する想定です
   *
   * @see https://hr-dev.backlog.jp/view/ACCO_CMS-1139
   * @see {@link ACCOAdapter.getHotelCuisines}
   *
   * @param slug - 施設スラッグ
   * @param restaurantId - 特定の食事処でフィルタしたい場合そのエンティティのID
   * @returns GF表示のためのコンテンツ配列オブジェクト
   */
  cuisinesBySlug(slug: string, restaurantId?: string) {
    return this.context.$dataBucket.get<HotelCuisineContentsResponse>(
      `/hotels/${slug}/cuisines`,
      restaurantId
        ? {
            params: { restaurantId },
          }
        : undefined,
    );
  }

  brandAll(): Promise<HotelBrandBasicInfo[]> {
    return this.context.$dataBucket.get<HotelBrandBasicInfo[]>('/brands');
  }

  brandDetailBySlug(brandSlug: string) {
    return this.context.$dataBucket.get<HotelBrandDetail>(
      `/brands/${brandSlug}/detail`,
    );
  }

  /**
   * 宿GETS金額付き月間カレンダー取得
   */
  getMonthlyVacancyCalendar(params: GetMonthlyVacancyCalendarParams) {
    return this.$get<{ vacancyList: VacancyInfo[] }>(
      '/rooms/vacancies/monthly',
      {
        params,
        headers: {
          cache: false,
        },
      },
    );
  }
}
