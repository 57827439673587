/**
 * ポインター系イベントが保有する座標情報
 */
interface Point {
  clientX: number;
  clientY: number;
}

/**
 * 2点間のポインターの距離を取得する
 *
 * @param a - ポインター座標
 * @param b - ポインター座標
 * @returns 距離（px）
 */
export function getDistance(a: Point, b?: Point): number {
  if (!b) return 0;
  return Math.sqrt((b.clientX - a.clientX) ** 2 + (b.clientY - a.clientY) ** 2);
}

/**
 * 2点間のポインターの中心座標を取得する
 *
 * @param a - ポインター座標
 * @param b - ポインター座標
 * @returns 中心座標
 */
export function getMidpoint(a: Point, b?: Point): Point {
  if (!b) return a;

  return {
    clientX: (a.clientX + b.clientX) / 2,
    clientY: (a.clientY + b.clientY) / 2,
  };
}

/**
 * ルーズな数値（numberとか"50%"みたいな文字）を数値にして取得する
 *
 * @param value - ルーズな数値（numberとか"50%"みたいな文字）
 * @param max - パーセンテージ指定だった場合の母数
 */
export function getAbsoluteValue(value: string | number, max: number): number {
  if (typeof value === 'number') return value;

  if (value.trimEnd().endsWith('%')) {
    return (max * parseFloat(value)) / 100;
  }
  return parseFloat(value);
}

// DOMMatrix/DOMPoint を使いたいけど、ブラウザのサポートが十分じゃない。
// なのでいろんなブラウザがサポートしているSVG matrixを使う。
let cachedSvg: SVGSVGElement;

function getSVG(): SVGSVGElement {
  return (
    cachedSvg ||
    (cachedSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg'))
  );
}

/**
 * SVGマトリクスを生成する
 * @returns SVGマトリクス
 */
export function createMatrix(): SVGMatrix {
  return getSVG().createSVGMatrix();
}

/**
 * DOMPointオブジェクトを生成する
 * @returns DOMPointオブジェクト
 */
export function createPoint(): SVGPoint {
  return getSVG().createSVGPoint();
}
