import * as tsx from 'vue-tsx-support';
import { Component, Mixins, Prop } from 'vue-property-decorator';

import { VNode } from 'vue';
import {
  HFormControl,
  HFormControlProps,
  HFormControlEmits,
  HFormControlScopedSlots,
} from './mixins/HFormControl';
import { HCheckbox, HCheckboxSize } from './HCheckbox';

export interface HCheckboxGroupItem {
  label: string;
  value: any;
}

export interface HCheckboxGroupProps extends HFormControlProps {
  items: HCheckboxGroupItem[];
  size?: HCheckboxSize;
  inputValue?: HCheckboxGroupItem['value'][];
}

export interface HCheckboxGroupEmits extends HFormControlEmits {}

export interface HCheckboxGroupScopedSlots extends HFormControlScopedSlots {}

@Component<HCheckboxGroupRef>({
  name: 'HCheckboxGroup',
})
export class HCheckboxGroupRef
  extends Mixins(HFormControl)
  implements HCheckboxGroupProps {
  @Prop(Array) readonly items!: HCheckboxGroupItem[];
  @Prop(String) readonly size?: HCheckboxSize;

  /**
   * @override
   */
  protected onChangeInputValueHandler(inputValue: any) {
    this.internalValue = inputValue ? inputValue.slice() : [];
  }

  /**
   * 内部モデル
   */
  protected internalValue: HCheckboxGroupItem['value'][] = this.inputValue
    ? this.inputValue.slice()
    : [];

  /**
   * 指定した値が選択されているかどうかチェックする
   *
   * @param value - 選択されているかチェックしたい値
   */
  isSelectedValue(value: HCheckboxGroupItem['value']): boolean {
    return this.internalValue.includes(value);
  }

  /**
   * 指定した値を選択状態にする
   *
   * * HCheckboxGroup内部のHCheckboxのoninputのイベントハンドラとしても使用する
   *
   * @param value - 選択状態にしたい値
   */
  selectValue(value: HCheckboxGroupItem['value']): void {
    if (this.isSelectedValue(value)) return;
    this.internalValue.push(value);
    this.emitValues();
  }

  /**
   * 指定した値を選択解除状態にする
   *
   * * HCheckboxGroup内部のHCheckboxのoninputのイベントハンドラとしても使用する
   *
   * @param value - 選択解除したい値
   */
  removeValue(value: HCheckboxGroupItem['value']): void {
    if (!this.isSelectedValue(value)) return;
    this.internalValue = this.internalValue.filter((item) => item !== value);
    this.emitValues();
  }

  /**
   * 自身の内部状態を通知して、バリデーションが動作する準備をする
   *
   * * 基本的にselectValueとremoveValueでしか呼ばれない
   */
  private emitValues() {
    this.$emit('input', this.internalValue);
    this.touched = true;
  }

  /**
   * 内包するチェックボックスのonInputイベントから呼び出されるハンドラ
   *
   * 変更が発生したHCheckboxのチェック状態を自身（HCheckboxGroup）の内部モデルに同期する
   * @param value - 変更が発生したチェックボックスの値
   * @param checked - チェックされているかどうか
   */
  private handleChildOnInput(
    value: HCheckboxGroupItem['value'],
    checked: boolean,
  ): void {
    checked ? this.selectValue(value) : this.removeValue(value);
  }

  protected genBodyChildren(): VNode[] {
    const { items, size } = this;
    return [
      <div staticClass="h-checkbox-group">
        {items.map((item, index) => (
          <HCheckbox
            value={item.value}
            inputValue={this.isSelectedValue(item.value)}
            onInput={(checked) => {
              this.handleChildOnInput(item.value, checked);
            }}
            key={index}
            size={size}>
            {item.label}
          </HCheckbox>
        ))}
      </div>,
    ];
  }
}

export const HCheckboxGroup = tsx
  .ofType<HCheckboxGroupProps, HCheckboxGroupEmits, HCheckboxGroupScopedSlots>()
  .convert(HCheckboxGroupRef);
