import { Loader, LoaderOptions, google } from 'google-maps';

const Key = 'AIzaSyAsp2sqCBEUk8rearm4x_TchU2aFjIO8Dk';

export type GoogleMapsApi = typeof google['maps'];

export type PlacesService = InstanceType<
  google['maps']['places']['PlacesService']
>;

export type MapTypeStyle = google.maps.MapTypeStyle;

export type FindPlaceFromQuery = PlacesService['findPlaceFromQuery'];

export type FindPlaceFromQueryCallback = Parameters<FindPlaceFromQuery>[1];

export type PlaceResult = Parameters<FindPlaceFromQueryCallback>[0][number];

export type PlacesServiceStatus = Parameters<FindPlaceFromQueryCallback>[1];

let loadPromise: Promise<GoogleMapsApi> | null = null;
let api: GoogleMapsApi | null = null;

export function loadGoogleMapsApi(options?: Omit<LoaderOptions, 'libraries'>) {
  if (api) {
    return Promise.resolve(api);
  }
  if (!loadPromise) {
    const loader = new Loader(Key, {
      ...options,
      libraries: ['places'],
    });
    loadPromise = loader.load().then((ggl) => {
      api = ggl.maps;
      loadPromise = null;
      return api;
    });
  }
  return loadPromise;
}
