import './fade.scss';

import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HKeyVisualRef } from '../HKeyVisual';
import { KeyVisualEffectData } from '~/schemes';
import { loadImageSize } from '~/helpers';

@Component<FadeEffect>({
  name: 'h-fade-effect',

  inject: ['keyVisual'],

  mounted() {
    this.start();
  },

  beforeDestroy() {
    this.clearTimer();
  },

  render(h): VNode {
    return h('div', {
      staticClass: 'h-fade-effect',
      class: this.classes,
      style: this.styles,
    });
  },
})
export default class FadeEffect extends Vue {
  readonly keyVisual!: HKeyVisualRef;

  @Prop({ type: Object, required: true }) value!: KeyVisualEffectData['Fade'];

  private delayTimerId: number | null = null;
  private isActive: boolean = false;

  async start() {
    if (this.isActive) return;
    await Promise.all([this.kickDelay(), this.loadImage()]);
    this.isActive = true;
  }

  kickDelay() {
    return new Promise((resolve) => {
      this.delayTimerId = window.setTimeout(() => {
        this.clearTimer();
        resolve(undefined);
      }, this.delay);
    });
  }

  loadImage() {
    return loadImageSize(this.image);
  }

  clearTimer() {
    if (this.delayTimerId !== null) {
      clearTimeout(this.delayTimerId);
      this.delayTimerId = null;
    }
  }

  get image() {
    return this.$res.img(this.value.image);
  }

  get delay() {
    return this.value.delay;
  }

  get duration() {
    return this.value.duration;
  }

  get styles() {
    return {
      ...this.keyVisual.baseStyles,
      backgroundImage: `url(${this.image})`,
      transition: `opacity ${this.duration}ms`,
    };
  }

  get classes() {
    return {
      'h-fade-effect--active': this.isActive,
    };
  }
}
