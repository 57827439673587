/* eslint-disable no-irregular-whitespace */
/**
 * wysiwygの入力値補助を行う際に、「ブロックタグ」として認識するタグのリスト
 */
const BLOCK_TAGS = ['p', 'div', 'ul', 'ol', 'li'] as const;

/**
 * ブロックタグの終了タグ以降に「空白やタブや改行だけ」が連続して、またすぐに終了タグが来るケースがあって、
 * それの不要な空白部分にマッチするための正規表現インスタンス
 *
 *
 * `</p>　　</p>` とかそういうの
 */
const trimCloseBlockTagRe = new RegExp(
  `</(${BLOCK_TAGS.join('|')})>([\\n\\r\\t\\s]+)</(${BLOCK_TAGS.join('|')})>`,
  'g',
);

// 冒頭の空のブロックタグ（p or div）の開始連続にマッチする正規表現
const emptyBlockStartRe = /^([\s\r\n\t]*<(p|div)>[\s\r\n\t]*<\/(p|div)>[\s\r\n\t]*)+/;

// 終端の空のブロックタグ（p or div）の開始連続にマッチする正規表現
const emptyBlockEndRe = /([\s\r\n\t　]*<(p|div)>[\s\r\n\t　]*<\/(p|div)>[\s\r\n\t　]*)+$/;

/**
 * 空白や改行やBOMとかそういうのをテキストからトリムする
 * ※なんでこんなことやってるんだか忘れた。。
 */
const trimRubyRe = /^[ \r\n\t\uFEFF\xA0]+|[ \t\r\n\uFEFF\xA0]+$/g;

// ↓2つと `trimRubyRe` の利用目的の違いを忘れた。。なんか、nbspとかがやってくる時があるのは覚えてる。
const wysiwygPrevTrimRe = /^(\n|\x20|&nbsp;)+/;
const wysiwygAfterTrimRe = /(\n|\x20|&nbsp;)+$/;

/**
 * 全てのHTMLブラケットにマッチする正規表現インスタンス
 */
const TAG_MATCH_RE = /(<([^>]+)>)/gi;

/**
 * HTML文字列から全てのHTMLブラケットを除去する
 */
function removeHTMLBrackets(html: string) {
  return html.replace(TAG_MATCH_RE, '');
}

/**
 * 不可視なHTML文字列を空文字列に変換する。
 *
 * Wysiwygエディタの入力状況によっては、HTMLとして視認できる要素はないものの、ブランクなpタグ等が値として存在してしまっている事がある。
 * その入力を空の入力値に補正するためのメソッド。
 *
 * - リッチテキストに一度入力して値を削除した時 `"<p></p>"`
 * - 連続で改行だけの入力がある時 `"<p></p><p></p><p></p>"`
 * - 空白のみの入力の場合 `"<p>　</p>"`
 *
 * ※ 本当は前後のそういう「不可視な要素」をトリムしていき、その結果空っぽになるのが期待の動きになるとは思うので、余力がある時にそれをやる。
 */
export function cleanupInvisibilityHTML(value: string): string {
  // HTML文字列から全てのブラケットを削除した上で、trim()した結果が空文字であれば、きっと不可視だったよねそれは、という判断
  if (removeHTMLBrackets(value).trim() === '') return '';
  return value;
}

/**
 * ブロックタグの終了タグ以降に「空白やタブや改行だけ」が連続して、またすぐに終了タグが来るケースがあって、
 * それの不要な空白部分を削除する
 *
 * `</p>　　</p>` とかそういうのを `</p></p>` にする。
 */
function trimCloseBlockTag(html: string): string {
  return html.replace(trimCloseBlockTagRe, '</$1></$3>');
}

/**
 * html前後の不要な空白や改行コードみたいなのをトリムする
 *
 * ※GFからの移植。中の処理がなんか多段になってて、なんでこういうことになっているのかは忘れた。。
 */
function trimRuby(html: string) {
  return html
    .replace(wysiwygPrevTrimRe, '')
    .replace(wysiwygAfterTrimRe, '')
    .replace(trimRubyRe, '');
}

/**
 * コンテンツの冒頭＆終端の空っぽなブロックタグを除去する
 */
function trimEmptyBlocks(html: string): string {
  html = html.replace(emptyBlockStartRe, '');
  html = html.replace(emptyBlockEndRe, '');
  return html;
}

/**
 * HTMLの前後や、途中にある不要な空白（っぽい）文字列を除去する。
 */
export function trimHTML(html: string): string {
  html = cleanupInvisibilityHTML(html);
  if (!html.length) {
    return html;
  }
  html = trimRuby(html);
  html = trimEmptyBlocks(html);
  html = trimCloseBlockTag(html);
  return html;
}
