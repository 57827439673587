import './HDynamicAirPackage.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop, Model } from 'vue-property-decorator';
import { addDays, format as formatDate } from 'date-fns';
import { HBtn, HBtnProps, HDialog, HHotelToBookingAir } from '~/components';
// import { extractCustomerTypeToAdultsAndChildren } from '~/types';
import {
  TBConnectionTarget,
  VacancySearchConditions,
  TBConnectionSettings,
} from '~/schemes';
import { toDate } from '~/helpers';

/**
 * 国外版のTB連動の際のシステムコード
 */
const TB_EXT_SYSTEM_CODE = 'YDG';

/**
 * 国外版のTB連動の際のスタイルコード
 */
const TB_STYLE_CODE = 'yadogetsdialog';

/**
 * 国内版のTB連動の際の extLinkId
 */
const TB_EXT_LINK_ID = 8;

type MyConnectionInfo =
  | {
      type: 'page';
      props: HBtnProps;
      on?: undefined;
    }
  | {
      type: 'popup';
      url?: string;
      props?: HBtnProps;
      on: {
        click: (ev: MouseEvent) => void;
      };
    };
type MyConnectionType = MyConnectionInfo['type'];

function extractCustomerTypeToAdultsAndChildren(
  peoples: VacancySearchConditions,
) {
  let children = 0;
  children += peoples.underFour || 0;
  children += peoples.underSeven || 0;
  children += peoples.underTwelve || 0;
  return {
    adults: peoples.adult,
    children,
  };
}

export interface HDynamicAirPackageSettings extends VacancySearchConditions {
  // hotel: YgetsableHotelDetail;
  tb: TBConnectionSettings;
  checkIn?: string;
}

export interface HDynamicAirPackageProps {
  value?: boolean;
  settings?: HDynamicAirPackageSettings | string;
}

export interface HDynamicAirPackageEmits {
  onInput: boolean;
}

export interface HDynamicAirPackageScopedSlots {
  activator: MyConnectionInfo;
}

@Component<HDynamicAirPackageRef>({
  name: 'HDynamicAirPackage',
  render() {
    const { connectionInfo } = this;
    if (!connectionInfo) return undefined as any;

    const { activator: activatorSlot } = this.$scopedSlots;

    const activator = activatorSlot ? (
      activatorSlot(connectionInfo)
    ) : (
      <HHotelToBookingAir props={connectionInfo.props} on={connectionInfo.on} />
    );

    const children = [activator];

    if (connectionInfo.type === 'popup') {
      children.push(
        <HDialog
          contentClass="h-dynamic-air-package__dialog"
          active={this.modalIsActive}
          backdrop
          onChange={(active) => {
            this.modalIsActive = active;
          }}
          scopedSlots={{
            header: () => [
              <span
                domPropsInnerHTML={this.$t('guide.tourBuilderPopup') as string}
              />,
            ],
          }}>
          <div staticClass="h-dynamic-air-package__iframe-wrapper">
            <iframe
              staticClass="h-dynamic-air-package__iframe"
              src={connectionInfo.url}
            />
          </div>
        </HDialog>,
      );
    }

    return <div staticClass="h-dynamic-air-package">{children}</div>;
  },
  watch: {
    value(value: boolean) {
      this.internalModalActive = value;
    },
  },
})
export class HDynamicAirPackageRef
  extends Vue
  implements HDynamicAirPackageProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;

  @Prop([Object, String]) readonly settings?:
    | HDynamicAirPackageSettings
    | string;

  private internalModalActive: boolean = this.value;

  get modalIsActive() {
    return this.internalModalActive;
  }

  set modalIsActive(modalIsActive) {
    if (this.internalModalActive !== modalIsActive) {
      this.internalModalActive = modalIsActive;
      this.$emit('input', modalIsActive);
    }
  }

  /**
   * TB側の連動対象言語
   */
  get tbLang() {
    return this.$language.info.tb;
  }

  /**
   * 連動先情報
   */
  get connectionInfo(): MyConnectionInfo | undefined {
    const { settings } = this;
    if (!settings) return;

    let url: string | undefined;
    let type: MyConnectionType | undefined;

    if (typeof settings === 'object') {
      const { checkIn: _checkIn, tb, stayLength } = settings;
      if (!tb.connectionFlag) return;

      if (_checkIn) {
        const checkInDate = toDate(_checkIn);
        const checkIn = formatDate(checkInDate, 'yyyy/MM/dd');
        const checkOut = formatDate(
          addDays(checkInDate, stayLength),
          'yyyy/MM/dd',
        );
        const {
          adults: adultCount,
          children: childrenCount,
        } = extractCustomerTypeToAdultsAndChildren(settings);
        const { target, targetUrl, hotelId: extHotelId } = tb;
        url = targetUrl;
        let params!: {
          [key: string]:
            | (string | number | null | undefined)
            | (string | number | null | undefined)[];
        };

        if (target === TBConnectionTarget.Internal) {
          type = 'page';
          params = {
            extLinkId: TB_EXT_LINK_ID,
            extHotelId,
            dpPerson: adultCount,
            cIn: checkIn,
            cOut: checkOut,
            depAirDate: checkIn,
            arrAirDate: checkOut,
          };
        } else if (
          target === TBConnectionTarget.Outbound ||
          target === TBConnectionTarget.Inbound
        ) {
          type = 'popup';
          params = {
            ExtSystemCode: TB_EXT_SYSTEM_CODE,
            ExtHotelCode: tb.hotelId,
            Adult: adultCount,
            CheckInDate: checkIn,
            CheckOutDate: checkOut,
            GoDepDate: checkIn,
            RtnDepDate: checkOut,
            Language: this.tbLang,
            StyleCode: TB_STYLE_CODE,
            Children: [],
          };

          const children: string[] = [];
          for (let i = 0; i < childrenCount; i++) {
            children.push('-1');
          }
          params.Children = children;
        }
        if (!type) return;

        if (params) {
          const rows: string[] = [];
          params &&
            Object.keys(params).forEach((key) => {
              let values = params[key];
              if (values == null) return;
              if (!Array.isArray(values)) values = [values];
              values.forEach((value) => {
                if (values == null) return;
                rows.push(`${key}=${encodeURIComponent(String(value))}`);
              });
            });
          const search = rows.join('&');
          url += `?${search}`;
        }
      } else {
        type = 'page';
      }
    } else {
      url = settings;
      type = 'page';
    }

    if (type === 'popup') {
      return {
        type,
        url,
        props: {
          disabled: !url,
        },
        on: {
          click: () => {
            this.modalIsActive = true;
          },
        },
      };
    } else {
      return {
        type,
        props: {
          href: url,
          target: '_blank',
          disabled: !url,
        },
      };
    }
  }

  // get isModal() {
  //   const { connectionInfo } = this;
  //   return !!connectionInfo && connectionInfo.type === 'popup';
  // }
}

export const HDynamicAirPackage = tsx
  .ofType<
    HDynamicAirPackageProps,
    HDynamicAirPackageEmits,
    HDynamicAirPackageScopedSlots
  >()
  .convert(HDynamicAirPackageRef);
