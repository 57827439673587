import { VNode } from 'vue';
import {
  DirectiveFunction,
  DirectiveOptions,
  DirectiveBinding,
} from 'vue/types/options';
import { GFEvent } from '~/plugins/gfev';

const IS_BROWSER = typeof document !== 'undefined';

export interface GFEVDirectiveValue extends GFEvent {}

export interface GFEVDirectiveBinding extends DirectiveBinding {
  name: 'ev';
  value?: GFEVDirectiveValue;
  oldValue?: GFEVDirectiveValue;
}

export interface GFEVDirectiveContext {
  value: GFEVDirectiveValue;
  handler: (ev: MouseEvent) => void;
  removeHandler: Function;
  destroy: Function;
}

export interface GFEVDirectiveElement extends HTMLElement {
  __gfev_ctx__: GFEVDirectiveContext;
}

export type GFEVDirectiveFunction = (
  el: GFEVDirectiveElement,
  binding: GFEVDirectiveBinding,
  vnode: VNode,
  oldVnode: VNode,
) => void;

const setupContext: GFEVDirectiveFunction = function setupContext(
  el,
  binding,
  vnode,
) {
  if (!IS_BROWSER) {
    return;
  }

  const { value } = binding;
  const { __gfev_ctx__: ctx } = el;

  if (ctx) {
    if (value) {
      if (value !== ctx.value) {
        ctx.value = value;
      }
    } else {
      ctx.destroy();
    }
  } else if (value) {
    el.__gfev_ctx__ = {
      value,
      handler: (ev) => {
        // if (ev.defaultPrevented) {
        //   return;
        // }
        const { context: vm } = vnode;
        if (vm) {
          vm.$gfev.push(value);
        }
      },
      removeHandler() {
        if (el.__gfev_ctx__.handler) {
          el.removeEventListener('click', el.__gfev_ctx__.handler, true);
        }
      },
      destroy: () => {
        if (!el.__gfev_ctx__) {
          return;
        }
        el.__gfev_ctx__.removeHandler();
        delete (el as any).__gfev_ctx__;
      },
    };
    el.addEventListener('click', el.__gfev_ctx__.handler, true);
  }
};

const inserted: GFEVDirectiveFunction = function inserted(
  el,
  binding,
  vnode,
  oldVnode,
) {
  setupContext(el, binding, vnode, oldVnode);
};

const componentUpdated: GFEVDirectiveFunction = function componentUpdated(
  el,
  binding,
  vnode,
  oldVnode,
) {
  setupContext(el, binding, vnode, oldVnode);
};

const unbind: GFEVDirectiveFunction = function unbind(el) {
  if (!IS_BROWSER) {
    return;
  }
  const { __gfev_ctx__: ctx } = el;
  ctx && ctx.destroy();
};

export default {
  name: 'ev',
  inserted: inserted as DirectiveFunction,
  componentUpdated: componentUpdated as DirectiveFunction,
  unbind: unbind as DirectiveFunction,
} as DirectiveOptions;
