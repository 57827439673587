import './HListGalleryItem.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import type { HListGalleryRef } from './HListGallery';

export interface HListGalleryItemProps {
  /** 画像のURL */
  images?: string | string[];
  /** 画像のアスペクト比。現状Listは4:5のみ */
  aspectRatio?: string;
}

export interface HListGalleryItemEmits {}

export interface HListGalleryItemScopedSlots {}

@Component<HListGalleryItemRef>({
  name: 'HListGalleryItem',
  inject: ['gallery'],
  render() {
    return (
      <div style={this.styles} staticClass="h-list-gallery-item">
        <div staticClass="h-list-gallery-item__node" />
      </div>
    );
  },
})
export class HListGalleryItemRef extends Vue implements HListGalleryItemProps {
  readonly gallery!: HListGalleryRef;
  @Prop({ type: String, required: true }) readonly images!: string;
  @Prop(String) readonly aspectRatio?: string;

  get styles() {
    const { images, aspectRatio } = this;
    return {
      '--image': `url(${images})`,
      '--aspect-ratio': `${aspectRatio}`,
    };
  }
}

export const HListGalleryItem = tsx
  .ofType<
    HListGalleryItemProps,
    HListGalleryItemEmits,
    HListGalleryItemScopedSlots
  >()
  .convert(HListGalleryItemRef);
