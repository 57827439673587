import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { HFormGroupRef } from '../HFormGroup';
import HFormErrorCapture from '../HFormErrorCapture';
import {
  Validator,
  ValidationErrors,
  ValidationResult,
  required as requiredValidator,
} from '~/validators';
import { isPromise } from '~/helpers';

export type HFormNodeErrors = ValidationErrors[];

export type ValidateTiming = 'always' | 'touch' | 'blur' | 'change' | 'manual';

type ValidateResolver = (result: ValidationResult) => void;

export interface HFormNodeErrorInfo {
  name: string;
  source: ValidationErrors;
  nodeName?: string;
}

export interface HFormNodeProps {
  name?: string;
  autofocus?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  error?: boolean;
  validateTiming?: ValidateTiming;
  rules?: Validator | Validator[];

  errorMessages?: string | string[];
}

export interface HFormNodeEmits {}

export interface HFormNodeScopedSlots {
  error: HFormNodeErrorInfo;
}

@Component<HFormNode>({
  name: 'HFormNode',
  inject: {
    parentFormNode: {
      from: 'formNode',
      default: null,
    },
    parentFormGroup: {
      from: 'formGroup',
      default: null,
    },
    parentFormErrorCapture: {
      from: 'formErrorCapture',
      default: null,
    },
  },
  provide() {
    return {
      formNode: this,
    };
  },
  created() {
    const { parentFormNode, parentFormGroup, parentFormErrorCapture } = this;
    parentFormNode && parentFormNode.joinFromNode(this);
    parentFormGroup && parentFormGroup.joinGroupFromNode(this);
    parentFormErrorCapture && parentFormErrorCapture.join(this);
  },
  beforeDestroy() {
    this.clearValidateResolvers();
    const { parentFormNode, parentFormGroup, parentFormErrorCapture } = this;
    parentFormNode && parentFormNode.leaveFromNode(this);
    parentFormGroup && parentFormGroup.leaveGroupFromNode(this);
    parentFormErrorCapture && parentFormErrorCapture.leave(this);
    this.isDestroyed = true;
  },
})
export class HFormNode extends Vue implements HFormNodeProps {
  readonly parentFormNode!: HFormNode | null;
  readonly parentFormGroup!: HFormGroupRef | null;
  readonly parentFormErrorCapture!: HFormErrorCapture | null;

  @Prop({ type: String }) readonly name?: string;
  @Prop({ type: Boolean }) readonly autofocus!: boolean;
  @Prop({ type: Boolean }) readonly disabled!: boolean;
  @Prop({ type: Boolean }) readonly readonly!: boolean;
  @Prop({ type: Boolean }) readonly required!: boolean;
  @Prop({ type: Boolean }) readonly error!: boolean;
  @Prop({ type: String, default: 'touch' })
  readonly validateTiming!: ValidateTiming;

  @Prop({ type: [Array, Function], default: () => [] }) readonly rules!:
    | Validator
    | Validator[];

  @Prop({ type: [String, Array] }) readonly errorMessages?: string | string[];

  protected childFormNodes: HFormNode[] = [];
  protected validationErrors: HFormNodeErrors = [];
  private validateResolvers: ValidateResolver[] = [];
  protected validateProp: string = 'value';
  private lastValidateValueChanged: boolean = true;
  private validating: boolean = false;
  private validateRequestId: number = 0;
  private isDestroyed: boolean = false;
  private internalTouched: boolean = false;
  shouldValidate: boolean = false;
  validationValueHasChanged: boolean = false;

  get formNodeId(): number {
    return (this as any)._uid;
  }

  get computedErrorMessages(): string[] {
    const { errorMessages = [] } = this;
    return Array.isArray(errorMessages) ? errorMessages : [errorMessages];
  }

  get errors(): (ValidationErrors | string)[] {
    return [...this.computedErrorMessages, ...this.validationErrors];
  }

  get firstError(): ValidationErrors | string | undefined {
    return this.errors[0];
  }

  get errorCount() {
    const baseCount = this.error ? 1 : 0;
    return this.errors.length + baseCount;
  }

  get hasMyError() {
    return this.errorCount > 0;
  }

  get hasError() {
    return (
      this.hasMyError || (this.parentFormNode && this.parentFormNode.hasMyError)
    );
  }

  get customDisabled() {
    return false;
  }

  get isDisabled() {
    return (
      this.customDisabled ||
      this.disabled ||
      (this.parentFormNode && this.parentFormNode.isDisabled)
    );
  }

  get isReadonly() {
    return (
      this.readonly || (this.parentFormNode && this.parentFormNode.isReadonly)
    );
  }

  get canOperation() {
    return !this.isDisabled && !this.isReadonly;
  }

  get touched() {
    return this.internalTouched;
  }

  set touched(touched) {
    if (this.internalTouched !== touched) {
      this.internalTouched = touched;
      if (touched && this.validateTimingIsTouch) {
        this.shouldValidate = true;
        this.validateSelf();
      }
    }
  }

  get validateTimingIsAlways() {
    return this.validateTiming === 'always';
  }

  get validateTimingIsTouch() {
    return this.validateTiming === 'touch';
  }

  get validateTimingIsBlur() {
    return this.validateTiming === 'blur';
  }

  get validateTimingIsChange() {
    return this.validateTiming === 'change';
  }

  get validateTimingIsManual() {
    return this.validateTiming === 'manual';
  }

  get validationValue(): any {
    return this[this.validateProp];
  }

  get computedRules(): Validator[] {
    const { rules: _rules, required } = this;
    const rules = Array.isArray(_rules) ? [..._rules] : [_rules];
    if (required) {
      rules.unshift(requiredValidator);
    }
    return rules;
  }

  @Watch('validateTiming', { immediate: true })
  protected validateTimingChangeHandler() {
    if (
      this.validateTimingIsAlways ||
      (this.touched && this.validateTimingIsTouch)
    ) {
      this.shouldValidate = true;
      this.validateSelf();
    }
  }

  @Watch('validationValueHasChanged', { immediate: true })
  protected validationValueHasChangedChangeHandler(
    validationValueHasChanged: boolean,
  ) {
    if (validationValueHasChanged && this.validateTimingIsChange) {
      this.shouldValidate = true;
      this.validateSelf();
    }
  }

  @Watch('validationValue', { immediate: true })
  protected validationValueChangeHandler(value: any) {
    this.lastValidateValueChanged = true;
    if (this.shouldValidate) {
      this.validateSelf();
    }
  }

  @Watch('hasMyError', { immediate: true })
  protected hasMyErrorChangeHandler(hasMyError: boolean) {
    this.parentFormGroup && this.parentFormGroup.updateInvalidNodesByNode(this);
  }

  protected beforeValidate(): void | Promise<void> {}

  validateSelf(): Promise<ValidationResult> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const beforeValidated = this.beforeValidate();
      if (isPromise(beforeValidated)) {
        await beforeValidated;
      }

      if (!this.lastValidateValueChanged && !this.validating) {
        resolve(this.validationErrors);
        return;
      }

      this.validateResolvers.push(resolve);

      if (!this.lastValidateValueChanged) {
        return;
      }

      this.validateRequestId = this.validateRequestId + 1;
      const requestId = this.validateRequestId;
      this.validating = true;

      const { computedRules } = this;
      const result: HFormNodeErrors = [];
      for (const fn of computedRules) {
        if (requestId !== this.validateRequestId) {
          return;
        }
        try {
          let rowResult = fn(this.validationValue);
          if (isPromise<ValidationResult>(rowResult)) {
            rowResult = await rowResult;
          }
          if (this.isDestroyed) {
            break;
          }
          if (rowResult) {
            result.push(rowResult);
          }
        } catch (err: any) {
          const errName: string | void = err && err.name;
          result.push({
            [errName || 'exception']: err || 'exception',
          });
        }
      }
      if (this.isDestroyed) {
        result.length = 0;
      }
      if (requestId !== this.validateRequestId) {
        return;
      }
      this.validationErrors.splice(0, this.validationErrors.length, ...result);
      this.lastValidateValueChanged = false;
      this.validating = false;
      this.resolveValidateResolvers();
    });
  }

  private resolveValidateResolvers() {
    this.validateResolvers.forEach((resolver) =>
      resolver(this.validationErrors),
    );
    this.clearValidateResolvers();
  }

  private clearValidateResolvers() {
    this.validateResolvers = [];
  }

  /** @private */
  joinFromNode(node: HFormNode) {
    if (!this.childFormNodes.includes(node)) {
      this.childFormNodes.push(node);
    }
  }

  /** @private */
  leaveFromNode(node: HFormNode) {
    const index = this.childFormNodes.indexOf(node);
    if (index !== -1) {
      this.childFormNodes.splice(index, 1);
    }
  }
}
