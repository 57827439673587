import './HRestaurantInformation.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNodeChildren } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Restaurant, RestaurantCommons } from '~/schemes';
import { extractHtmlText } from '~/helpers';

export interface HRestaurantInformationProps {
  /** 食事処共通設定 */
  commons: RestaurantCommons;

  /** 食事処 */
  value: Restaurant;
}

export interface HRestaurantInformationEmits {}

export interface HRestaurantInformationScopedSlots {}

@Component<HRestaurantInformationRef>({
  name: 'HRestaurantInformation',

  render(h) {
    const { hours, styleTexts, childRestrictions, petsAllowed } = this;
    const { averageBudget, reservationRequired } = this.value;
    const _childRestrictions = this.createSupplementContent(childRestrictions);
    const _petsAllowed = this.createSupplementContent(petsAllowed);

    return (
      <table staticClass="h-restaurant-information">
        <tbody>
          {hours && hours.value ? (
            <tr>
              <th>{hours.name}</th>
              <td v-wysiwyg={hours.value} />
            </tr>
          ) : undefined}

          <tr>
            <th>{this.$t('label.diningStyle')}</th>
            <td>
              {styleTexts.length > 0
                ? styleTexts.join(this.$t('chore.wordSparator') as string)
                : '-'}
            </td>
          </tr>
          <tr>
            <th>{this.$t('label.kids')}</th>
            <td>{_childRestrictions}</td>
          </tr>
          <tr>
            <th>{this.$t('label.pets')}</th>
            <td>{_petsAllowed}</td>
          </tr>
          <tr>
            <th>{this.$t('label.averageBudget')}</th>
            <td v-wysiwyg={averageBudget || '-'} />
          </tr>
          <tr>
            <th>{this.$t('label.reservation')}</th>
            {h(
              'td',
              {
                directives: reservationRequired
                  ? [
                      {
                        name: 'wysiwyg',
                        value: reservationRequired,
                      },
                    ]
                  : [],
              },
              reservationRequired
                ? undefined
                : (this.$t('label.noReservationRequired') as string),
            )}
          </tr>
        </tbody>
      </table>
    );
  },
})
export class HRestaurantInformationRef
  extends Vue
  implements HRestaurantInformationProps {
  @Prop({ type: Object, required: true }) readonly commons!: RestaurantCommons;
  @Prop({ type: Object, required: true }) readonly value!: Restaurant;

  /** 営業時間 */
  get hours() {
    const { hours } = this.value;
    if (hours) {
      const is24 = extractHtmlText(hours) === '24';
      const name = this.$t(`label.${is24 ? 'openHours' : 'hours'}`) as string;
      const value = is24 ? (this.$t('label.twentyFourhours') as string) : hours;

      return { name, value };
    }
  }

  /** 営業スタイル */
  get styleTexts(): string[] {
    const { styles } = this.value;
    const { styleOptions } = this.commons;
    const texts: string[] = [];
    styles.forEach((value) => {
      const option = styleOptions.find((o) => o.value === value);
      if (option) {
        texts.push(option.name);
      }
    });
    return texts;
  }

  /** 子供の受け入れ */
  get childRestrictions() {
    const { avairable, supplement } = this.value.childRestrictions;
    let text: string;
    if (avairable === 'available') {
      text = this.$t('chore.allowed') as string;
    } else if (avairable === 'unavailable') {
      text = this.$t('chore.notAvailable') as string;
    } else {
      text = '';
    }
    return {
      text,
      supplement,
    };
  }

  /** ペットの受け入れ */
  get petsAllowed() {
    const { avairable, supplement } = this.value.petsAllowed;
    let text: string;
    if (avairable === 'available') {
      text = this.$t('chore.allowed') as string;
    } else if (avairable === 'unavailable') {
      text = this.$t('chore.notAllowed') as string;
    } else {
      text = '';
    }
    return {
      text,
      supplement,
    };
  }

  /**
   * 補助テキストがある系の表示を生成する
   *
   * @param settings - 内容
   * @returns VNodeChildren
   */
  private createSupplementContent(settings: {
    /** テキスト */
    text?: string | null;
    /** 補助テキスト */
    supplement?: string | null;
  }) {
    const h = this.$createElement;
    const { text, supplement } = settings;
    const children: VNodeChildren = [];
    if (!text && !supplement) return children;
    if (!supplement) {
      children.push(text);
      return children;
    }
    children.push(
      h(
        'div',
        {
          staticClass: 'h-restaurant-information__wrap',
        },
        [
          h(
            'div',
            {
              staticClass: 'h-restaurant-information__wrap__text',
            },
            text,
          ),
          h('div', {
            staticClass: 'h-restaurant-information__wrap__supplement',
            directives: [
              {
                name: 'wysiwyg',
                value: supplement,
              },
            ],
          }),
        ],
      ),
    );
    return children;
  }
}

export const HRestaurantInformation = tsx
  .ofType<
    HRestaurantInformationProps,
    HRestaurantInformationEmits,
    HRestaurantInformationScopedSlots
  >()
  .convert(HRestaurantInformationRef);
