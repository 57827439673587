import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

export interface HImageLoupeTeleportProps {
  to: string | HTMLElement;
}

export interface HImageLoupeTeleportEmits {}

export interface HImageLoupeTeleportScopedSlots {}

@Component<HImageLoupeTeleportRef>({
  name: 'HImageLoupeTeleport',
  mounted() {
    const { to, $el } = this;
    const { firstChild } = $el;
    if (firstChild instanceof HTMLElement) {
      this.detachedElement = firstChild;
      to.appendChild(firstChild);
    }
  },
  beforeDestroy() {
    const { detachedElement } = this;
    if (detachedElement) {
      this.to.removeChild(detachedElement);
    }
  },
  render(h) {
    return h(
      'div',
      {
        key: 'h-image-loupe-teleport',
        staticClass: 'h-image-loupe-teleport',
        style: {
          display: 'none',
        },
      },
      this.$slots.default,
    );
  },
})
export class HImageLoupeTeleportRef
  extends Vue
  implements HImageLoupeTeleportProps {
  @Prop({ required: true }) readonly to!: HTMLElement;

  detachedElement?: HTMLElement;
}

export const HImageLoupeTeleport = tsx
  .ofType<
    HImageLoupeTeleportProps,
    HImageLoupeTeleportEmits,
    HImageLoupeTeleportScopedSlots
  >()
  .convert(HImageLoupeTeleportRef);
