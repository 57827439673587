import type { Player } from '@vimeo/player';

export type {
  Error,
  PasswordError,
  PrivacyError,
  InvalidTrackLanguageError,
  InvalidTrackError,
  UnsupportedError,
  ContrastError,
  InvalidCuePoint,
  RangeError,
  TypeError,
  EventName,
  VimeoTimeRange,
  VimeoVideoQuality,
  Player,
  VimeoCuePoint,
  VimeoCuePointData,
  VimeoTextTrack,
  Options,
  VimeoPromise,
} from '@vimeo/player';

export type PlayerStatic = typeof Player;

let loadPlayerPromise: Promise<PlayerStatic> | null = null;

export function loadPlayer(): Promise<PlayerStatic> {
  if (!loadPlayerPromise) {
    loadPlayerPromise = import('@vimeo/player').then((res) => {
      return res.default;
    });
  }
  return loadPlayerPromise;
}
